import React from 'react'
import PropTypes from 'prop-types'

import { Content } from '../../../../components/list/contentItem'
import SortableContentItem from '../../../../components/list/contentItemSortable'

import '../../../../components/listItems/standard.css'
import Label from '../../../../components/ui/label'
import DataBite from '../../../../components/ui/databite'

// import ListConstants from '../constants'
import { getAudienceDisplayName } from '../../shared/utils'

// import Constants from '../../../../core/constants'

export default class ListContent extends React.Component {

	static propTypes = {
		item: PropTypes.object,

		isPlaceholder: PropTypes.bool,
		findItem: PropTypes.func,
		moveItem: PropTypes.func,
		handleOutsideDrop: PropTypes.func,
		handleItemManipulation: PropTypes.func,
	}

	render() {
		const {
			item: itemData, // NEW
			selected,

			// DND
			isPlaceholder,
			findItem,
			moveItem,
			handleOutsideDrop,
			handleItemManipulation,
			onDragStateChanged,
		} = this.props;

		const {
			name,
			displayName, // Borttaget av HEL, vi kör name istället tillsvidare
			version,
			audience,
			status: itemStatus,
			parentSectionId,
			service,
		} = itemData;

		let status = null;
		if (itemStatus?.toLowerCase() === "hidden") {
			status = <Label status="disabled">Hidden</Label>;
		}

		let serviceLabel = null;
		if (!parentSectionId) {
			serviceLabel = <Label status="undefined">{service.displayName}</Label>;
		}

		const title = name;

		return (
			<SortableContentItem
				data={itemData}
				handleType="icon"
				selected={selected}

				// DND
				isPlaceholder={isPlaceholder}
				findItem={findItem}
				moveItem={moveItem}
				onItemDropOutside={handleOutsideDrop}
				insertCard={handleItemManipulation}
				onDragStateChanged={onDragStateChanged}
			>
				<div className="c6-icon-container">
					{/* {getIcon(category, autoGenerated)} */}
				</div>
				<Content>
					<h1>{title}</h1>
					{status}{serviceLabel}
				</Content>
				{parentSectionId !== 0 && (
					<Content>
						{getAudience(version, audience)}
					</Content>
				)}
			</SortableContentItem>
		);
  	}
}

// HELPERS
function getAudience(version, audience) {
	let targets = "";
	if (version && version.id !== 0) {
		targets = version.name;
	}

	if (targets && audience !== "everyone") {
		targets += ": ";
	}

	if (audience !== "everyone") {
		targets += getAudienceDisplayName(audience);
	}

	return targets
		? <DataBite icon="account_circle">{targets}</DataBite>
		: null;
}
import React from 'react'

import App from '../../../../components/app'
import Dashboard from '../../../../components/dashboard/dashboard'
import { StandardInfo } from '../../../../components/comet'

const SHORTCUTS = [
	{
		displayName: "Services",
		key: "vod.admin-services",
		links: [
			{
				key: "services",
				url: "/vod/admin/services",
				ui: "React",
				description: "",
				displayName: "> API services",
			},
			{
				key: "services-classic",
				url: "/vod/admin/services-classic",
				ui: "React",
				description: "",
				displayName: "> Classic services",
			},
		],
	},
];

function AdminDashboardApp(props) {
	return (
		<App name="c6-vod-admin" layout="grid">
			<Dashboard title="VOD administration" data={SHORTCUTS} />
			<StandardInfo location={props.location} />
		</App>
	);
}

export default AdminDashboardApp;
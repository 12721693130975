import React from 'react'
import { browserHistory } from 'browserHistory'

import App from '../../../components/app'
import { StandardInfo } from '../../../components/comet'
import withDnDContext from '../../../core/services/withDnDContext'

import Store from '../store'
import Actions from '../actions'

import Lists from './lists/app'
import Discovery from './discovery/app'

import appConfig from 'config'
import { displayAlert } from '../../../core/services/alert'
import { getServiceConfiguration } from '../shared/utils'

import './app.css'

const DATASTORE = "lists";

const DEFAULT_DISCOVERY_TYPE = "recent-regular";
const TYPES = [
	{ key: "recent-regular", text: "Curated", searchPlaceholder: "curated", description: "Recently modified selections." },
	{ key: "cometauto", text: "Generated", searchPlaceholder: "generated", description: "Selections populated automatically by Comet." },
	{ key: "programs", text: "Programs", searchPlaceholder: "programs", description: "Programs with upcoming premiere." },
	{ key: "broadcasts", text: "Broadcasts", searchPlaceholder: "broadcasts", description: "Broadcasts" },
];

class ListsApp extends React.Component {
	
	state = {
		...Store.getState()
	};

	// Lifecycle
	componentDidMount() {
		Store.listen(this.onChange);
		const { lists, groups, discovery } = this.state.list;
		const serviceConfiguration = getServiceConfiguration(this.props.params, this.props.location, this.state.item.selectionsConfiguration);

		this.groupId = parseInt(this.props.location.query.groupId, 10) || -1;
		this.serviceId = serviceConfiguration.serviceId;

		// If we have a groupId, set lists.filters.sectionId and _selectedGroup
		// and fetch lists
		if (this.groupId !== -1) {
			Actions.setListGroup(this.groupId); // updates lists.filters.sectionId
			if (groups.items.length) {
				Actions.updateSelectedGroup(this.groupId, groups.items); // Updates groups._selectedGroup
			}

			// Fetch lists and assets
			Actions.fetchItems(DATASTORE, lists.filters, { clearList: true });
			Actions.fetchItems("sectionAssets", { sectionId: this.groupId });
		}

		// Always fetch areas
		Actions.fetchItems("areas", { serviceId: this.serviceId });

		// Always set discovery Filters
		Actions.discoveryFilter(discovery.filters, DEFAULT_DISCOVERY_TYPE, serviceConfiguration.discoveryPlatform); // HACK: Maybe we should use seperate state.list stores for discovery (lists app) and discovery (items app)??

		// Only fetch groups once since they're not changing so often
		if (this.groupId === -1 || groups.items.length === 0 || this.state.reloadGroupsBecauseModified) {
			this.fetchGroups();
		}

		if (appConfig.features.selectionsMovedToHeroComet) {
			displayAlert(
				"warning",
				null,
				null,
				null,
				60000,
				`Administration of the press service has been moved to <a href="https://tv4.junecomet.com">tv4.junecomet.com</a>`
			);
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		const { lists, groups } = this.state.list;
		const nextGroupId = nextProps.location.query.groupId;
		const nextServiceId = nextProps.params?.serviceId ?? 1;

		// Set correct groupId in query if it's missing (due to redirects)
		if (this.groupId !== -1 && !nextGroupId && nextServiceId === this.serviceId) {
			const route = {
				pathname: `/selections/service/${this.serviceId}`,
				query: { groupId: this.groupId }
			};
			browserHistory.replace(route);
			return;
		}

		// Update filters if query.groupId changed
		if (nextGroupId !== this.props.location.query.groupId) {
			this.groupId = parseInt(nextGroupId, 10) || -1;

			Actions.setListGroup(this.groupId); // updates lists.filters.sectionId
			if (this.groupId >= 0) {
				Actions.fetchItems(DATASTORE, lists.filters, { clearList: true });
				Actions.fetchItems("sectionAssets", { sectionId: this.groupId });
			}
		}

		Actions.updateSelectedGroup.defer(this.groupId, groups.items); // Updates groups._selectedGroup

		// Update groups and areas if serviceId changed (will trigger a fetch for lists)
		if (nextServiceId !== this.serviceId) {
			this.serviceId = nextServiceId;
			this.fetchGroups();
			Actions.fetchItems("areas", { serviceId: this.serviceId });
		}
	}

	fetchGroups = () => {
		const filters = { ...this.state.list.groups.filters, serviceId: this.serviceId };
		Actions.fetchItems("groups", filters, {
			callback: items => {
				const groupId = this.groupId !== -1
					? this.groupId
					: items[0]?.id;

				Actions.updateSelectedGroup.defer(groupId, items); // Updates groups._selectedGroup
				const route = {
					pathname: `/selections/service/${this.serviceId}`,
					query: { groupId }
				};
				browserHistory.replace(route);
			}
		});
	}

	componentWillUnmount() {
		Actions.unmount();
		Store.unlisten(this.onChange);
	}

	onChange = (state) => {
		this.setState(state);
	}

	handleOutsideDrop = (id, originalIndex) => {
		this.listsRef.handleOutsideDrop(id, originalIndex);
	}

	render() {
		const { lists, groups, discovery, discoveryEpisodes, areas, sectionAssets } = this.state.list;
		const isLoading = lists.isLoading || groups.isLoading || discovery.isLoading || areas.isLoading;
		const serviceConfiguration = getServiceConfiguration(this.props.params, this.props.location, this.state.item.selectionsConfiguration);
		return (
			<App name="c6-selections-ott" layout="grid area-right" isLoading={isLoading}>
				<Lists
					ref={r => this.listsRef = r}
					list={lists}
					groups={groups}
					areas={areas}
					selectionsConfiguration={this.state.item.selectionsConfiguration}
					sectionAssets={sectionAssets}
					serviceId={this.serviceId}
				/>

				<Discovery
					types={TYPES}
					list={discovery}
					episodesList={discoveryEpisodes}
					onItemDropOutside={this.handleOutsideDrop}
					discoveryPlatform={serviceConfiguration.discoveryPlatform}
				/>

				<StandardInfo location={this.props.location} />
			</App>
		);
	}
}

export default withDnDContext(ListsApp);
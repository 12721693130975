import * as VodCoreAPI from '../../../apis/vodcore'
import { useQuery } from '@tanstack/react-query'

const useVodCorePackage = (packageId: string | number) => {
    return useQuery(
        ["vodcore", "package", { id: packageId }],
        () => VodCoreAPI.fetchPackage({ id: packageId }),
        { enabled: packageId !== null }
    );
};

export default useVodCorePackage;
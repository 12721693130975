import { useQuery } from '@tanstack/react-query'
import { getUser, getUserAccessLevelForModule } from '../../../core/services/auth'

// @ts-ignore
import appConfig from 'config'

type CMSFilter = {
	title?: string;
	type: "search" | "switch" | "pick" | "dropdown" | "text";
	options?: any[];
}
type CMSFilters = {
	[key: string]: CMSFilter;
}
type CMSApp = {
	key: string;
	displayName: string;
	module?: string;
	editorEntity?: string;
	filters?: CMSFilters;
	filterDefaults?: any;
}
type CMSBlock = {
	displayName: string;
	key: string;
	apps?: CMSApp[];
}
type CMSConfig = {
	blocks?: CMSBlock[];
}

export function useCMSConfig(): CMSConfig {
	const { data: config } = useQuery<CMSConfig>(
		["cms-config"],
		// () => Promise.resolve(defaultConfig),
		() => new Promise(async resolve => {
			await timeout(300);

			const user = getUser();
			const isSysadmin = user.username === "sysadmin";
			const hasAdminRights = getUserAccessLevelForModule("eventplanner") === "admin";
			if (isSysadmin || hasAdminRights) {
				resolve(defaultConfig);
			} else {
				resolve({
					blocks: [
						{
							displayName: "Events",
							key: "eventplanner.events",
							apps: [
								{ key: "series", displayName: "Series" },
								{ key: "seasons", displayName: "Seasons" },
							],
						},
					],
				});
			}
		}),
		{ staleTime: 1000 * 60 * 10 },
	);

	return config;
}

const timeout = (t: number) => new Promise(resolve => {
	setTimeout(resolve, t);
});

const defaultConfig: CMSConfig = {
	blocks: [
		{
			displayName: "Events",
			key: "eventplanner.events",
			apps: [
				{
					key: "series",
					displayName: "Series",
				},
				{
					key: "seasons",
					displayName: "Seasons",
				},
				{
					key: "genres",
					displayName: "Genres",
					editorEntity: "genreeditor",
				},
				{
					key: "channels",
					displayName: "Channels",
				},
				{
					key: "encoders",
					displayName: "Encoders",
					editorEntity: "encodereditor",
					// filters: {
					// 	searchText: {
					// 		type: "search",
					// 	},
					// 	test: {
					// 		title: "Test",
					// 		type: "switch",
					// 		options: [
					// 			{ key: "", text: "Any" },
					// 			{ key: "a", text: "A" },
					// 			{ key: "b", text: "B" },
					// 		]
					// 	},
					// },
					// filterDefaults: {
					// 	searchText: "",
					// 	test: "",
					// },
				},
				{
					key: "vimondcategories",
					displayName: "Vimond categories",
					editorEntity: "vimondcategoryeditor",
				},
				{
					key: "arenas",
					displayName: "Arenas",
					editorEntity: "arenaeditor",
				},
				{
					key: "countrys",
					displayName: "Countries",
					filters: {
						searchText: {
							type: "search",
						},
					},
					filterDefaults: {
						searchText: "",
					},
				},
				{
					key: "rights",
					displayName: "Rights",
				},
				{
					key: "subscriptions",
					displayName: "Subscriptions",
				},
				{
					key: "sources",
					displayName: "Sources",
				},
				{
					key: "consumers",
					displayName: "Consumers",
				}
			],
		},
		{
			displayName: "Broadcasts",
			key: "eventplanner.admin",
			apps: [
				{
					key: "rightgroups",
					displayName: "Right groups",
					module: "broadcast",
					editorEntity: "rightgroupeditor",
				},
				{
					key: "rights",
					displayName: "Rights",
					module: "broadcast",
					editorEntity: "righteditor",
				},
				...(appConfig.features.eventplannerContentConsumers ? [{ key: "contentconsumers", displayName: "Consumers", module: "broadcast", editorEntity: "contentconsumers" }] : []),
			],
		},
		{
			displayName: "Templates",
			key: "eventplanner.cms-template",
			apps: [
				{
					key: "template",
					displayName: "Test templates",
				},
			]
		}
	],
};
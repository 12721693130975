import React 				 	from 'react'
import { Route, IndexRoute } 	from 'react-router'

import Module        		 	from '../app'

import PackagesClassic       	from './packages-classic/app'
import DeliveriesClassic       	from './deliveries-classic/app'
import SeriesClassic			from './series-classic/app'
import Packages       			from './packages/app'
import Deliveries       		from './deliveries/app'
import Series				 	from './series/app'

import AdminDashboard				from './admin/dashboard/app'
import AdminServicesClassic			from './admin/services-classic/app'
import AdminServiceEditorClassic	from './admin/serviceeditor-classic/app'
import AdminServices				from './admin/services/app'
import AdminServiceEditor			from './admin/serviceeditor/app'

export default class VodModule {
	static routes() {
		return (
			<Route path="vod" component={Module} module="vod">
				<Route path="admin" app="admin">
					<IndexRoute component={AdminDashboard} />
					<Route path="services-classic">
						<IndexRoute component={AdminServicesClassic} returnTo="/vod/admin" />
						<Route path=":id/edit" component={AdminServiceEditorClassic} returnTo="/vod/admin/services-classic" />
						<Route path="create" component={AdminServiceEditorClassic} returnTo="/vod/admin/services-classic" />
					</Route>
					<Route path="services">
						<IndexRoute component={AdminServices} returnTo="/vod/admin" />
						<Route path=":id/edit" component={AdminServiceEditor} returnTo="/vod/admin/services" />
						<Route path="create" component={AdminServiceEditor} returnTo="/vod/admin/services" />
					</Route>
				</Route>

				<Route path="packages" app="packages">
					<IndexRoute component={PackagesClassic} />
					<Route path="series/:id" component={SeriesClassic} />
					<Route path=":id/deliveries" component={DeliveriesClassic} />
				</Route>

				<Route path="operators" app="operators" serviceId={null} serviceName={null}>
					<IndexRoute component={PackagesClassic} />
					<Route path="series/:id" component={SeriesClassic} />
					<Route path=":id/deliveries" component={DeliveriesClassic} />
				</Route>

				<Route path="tv4" app="tv4" serviceId={1} serviceName="TV4 Play">
					<IndexRoute component={PackagesClassic} />
					<Route path="series/:id" component={SeriesClassic} returnTo="/vod/tv4" />
					<Route path=":id/deliveries" component={DeliveriesClassic} returnTo="/vod/tv4" />
				</Route>

				<Route path="tv4-new" app="tv4-new" serviceId={3} serviceName="TV4 Play">
					<IndexRoute component={Packages} />
					<Route path="series/:id" component={Series} returnTo="/vod/tv4-new" />
					<Route path=":id/deliveries" component={Deliveries} returnTo="/vod/tv4-new" />
				</Route>

				<Route path="packages-new" app="packages-new">
					<IndexRoute component={Packages} />
					<Route path="series/:id" component={Series} returnTo="/vod/packages-new" />
					<Route path=":id/deliveries" component={Deliveries} returnTo="/vod/packages-new" />
				</Route>

				<Route path="dai" app="dai" serviceId="2,8,9" serviceName="DAI">
					<IndexRoute component={PackagesClassic} />
					<Route path="series/:id" component={SeriesClassic} returnTo="/vod/dai" />
					<Route path=":id/deliveries" component={DeliveriesClassic} returnTo="/vod/dai" />
				</Route>

				<Route path="kavi" app="kavi" serviceId={10} serviceName="Kavi">
					<IndexRoute component={PackagesClassic} />
					<Route path="series/:id" component={SeriesClassic} returnTo="/vod/kavi" />
					<Route path=":id/deliveries" component={DeliveriesClassic} returnTo="/vod/kavi" />
				</Route>
			</Route>
		);
	}
}
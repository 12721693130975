import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { decoratorUIActions } from '../../../core/decorators/uiActions'
import itemActions from './listItemActions'

import { ItemGroup } from '../../../components/list/listItems'
import Empty from '../../../components/list/empty'

import Item from './listItem'

@decoratorUIActions(itemActions)
export default class List extends Component {

	static propTypes = {
		items: PropTypes.array.isRequired,
		isLoading: PropTypes.bool.isRequired,
		textEmpty: PropTypes.string,
	}

	render() {
		const {
			items,
			isLoading,
			textEmpty = "Sorry, could not find any items.",
		} = this.props;

		const className = !(items && items.length) ? "center c6-color-light" : "";

		const filteredItems = items ? items.filter(item => !item._isHidden) : [];
		const itemGroup = filteredItems.length
			? this.renderItems(filteredItems)
			: <Empty isLoading={isLoading}>{textEmpty}</Empty>;

		return <div className={className}>{itemGroup}</div>;
  	}

	renderItems(items) {
		const modules = items.filter(m => !m.parentModuleId);

		return modules.map(module => {
			const subModules = items.filter(sm => sm.parentModuleId === module.id);
			return (
				<ItemGroup key={`gr${module.id}`}>
					<Item
						key={module.id}
						id={module.id}
						{...module}
					/>
					{subModules.map(subModule => (
						<Item
							key={subModule.id}
							id={subModule.id}
							className="c6-subitem"
							{...subModule}
						/>
					))}
				</ItemGroup>
			)
		});
	}
}
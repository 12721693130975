import React from 'react'

import { Filter, Left, Right } from '../../../../components/filter'
import Search from '../../../../components/ui/controls/search'
import Switch from '../../../../components/ui/controls/switch'

export default ({ title, onSearch, onlyMine, onFilter }) => (
	<Filter>
		<Left>
			<h1 style={{ width: "unset" }}>{title}</h1>
			<div style={{ display: "inline-block", marginLeft: "20px" }}>
				<Switch
					name="onlyMine"
					onChange={onFilter}
					states={[
						{ key: "true", text: "My albums", description: "" },
						{ key: "false", text: "All albums", description: "" },
					]}
					currentState={onlyMine}
				/>
			</div>
			<div>
				
			</div>
		</Left>
		<Right>
			<Search onChange={onSearch} />
		</Right>
	</Filter>
);
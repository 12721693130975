import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import ContentItem, { Content } from '../../../components/list/contentItem'
import Label from '../../../components/ui/label'
import { getTitleAndIcon } from '../deliveries-classic/listItem'
import SubItem from './subItem'
import { renderValidations } from '../shared/listItem'

import Const from '../../../core/constants'

import '../../../components/listItems/standard.css'

const DeliveryItem = (props) => {
	const {
		// id,
		changeType,
		created,
		handled,
		status,
		// statusDescription,
		assets,
		package: deliveryPackage,
		metadata,
		validations,

		deliveryItems,
		showDeliveryItems,
	} = props;

	const handledMoment = handled ? moment(handled) : null;
	const isoHandled = handledMoment?.format(Const.DATE_TIME_FORMAT);
	const createdMoment = moment(created);
	const isoCreated = createdMoment.format(Const.DATE_TIME_FORMAT);

	const { title, icon } = getTitleAndIcon(changeType, status);
	const isDelivery = status === "Complete";

	return (
		<React.Fragment>
			{/* HACK: Remove hover/click styles since we can't hide/disable the CONTENT action */}
			<ContentItem data={props} extraClasses={isDelivery ? "status-queued" : "no-click"}>
				<div className={`c6-icon-container large-icon ${icon}`}></div>
				<Content>
					<h1>{title}</h1>
					<p className={isoHandled ? "handled" : ""}>
						{isoHandled && <time title={`Event was handled ${isoHandled} (created ${isoCreated})`} dateTime={isoHandled}>{handledMoment.format("D MMM HH:mm")}</time>}
						{!isoHandled && <time title={`Event is not yet handled. Event was created ${isoCreated}`} dateTime={isoCreated}>Not yet handled.</time>}
					</p>
				</Content>
				<Content className="right">
					{renderValidations(validations, assets, deliveryPackage.service, metadata)}
				</Content>
			</ContentItem>
			{showDeliveryItems && <SubItem deliveryItems={deliveryItems} />}
		</React.Fragment>
	);
};

export default DeliveryItem;
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import groupBy from 'lodash/groupBy'

import { decoratorUIActions } from '../../../core/decorators/uiActions'
import itemActions from './listItemActions'

import { ItemGroup } from '../../../components/list/listItems'
import Empty from '../../../components/list/empty'

import Item from './listItem'

@decoratorUIActions(itemActions)
export default class List extends Component {

	static propTypes = {
		items: PropTypes.array.isRequired,
		isLoading: PropTypes.bool.isRequired,
		textEmpty: PropTypes.string,
	}

	render() {
		const {
			items,
			isLoading,
			textEmpty = "Sorry, could not find any items.",
		} = this.props;

		const className = !(items && items.length) ? "center c6-color-light" : "";

		const filteredItems = items ? items.filter(item => !item._isHidden) : [];

		return (
			<div className={className}>
				{!!filteredItems.length && this.renderGroups({ ...this.props, items: filteredItems })}
				{!filteredItems.length && <Empty isLoading={isLoading}>{textEmpty}</Empty>}
			</div>
		);
	}

	renderGroups({ items, searchText }) {
		if (searchText) {
			return (
				<ItemGroup title={`Items matching "${searchText}"`}>
					{this.renderItems(items)}
				</ItemGroup>
			);
		}

		return this.renderItems(items);
		// const groupedItems = groupItems(items);

		// return Object.keys(groupedItems).map(groupKey => (
		// 	<ItemGroup key={groupKey} title={groupKey}>
		// 		{this.renderItems(groupedItems[groupKey])}
		// 	</ItemGroup>
		// ));
	}

	renderItems(items) {
		return items.map(item => (
			<Item
				key={item.id}
				id={item.id}
				{...item}
			/>
		));
	}
}

// HELPERS
function groupItems(items) {
	const groupedItems = groupBy(items, item => {
		// Group by email domain
		const result = /@.+\..+$/gmi.exec(item.email);
		return result[0];
	});

	return groupedItems;
}
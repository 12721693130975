import React from 'react'
import moment from 'moment'
import { localeCompareSortNumeric } from '../../../core/services/sorting'

export function getSearchPayload({ filters = {} }, _serviceId) {
	const { filter, searchText, ...rest } = filters;
	const { searchPackagesAcrossAllServices, status, ...filterRest } = filter;

	let serviceId = filter.serviceId;
	let serviceIds = null;
	if (searchPackagesAcrossAllServices && searchText?.length) {
		serviceId = null;
	} else if (typeof _serviceId === "string" && _serviceId.includes(",")) {
		serviceIds = _serviceId;
		serviceId = null;
	} else if (_serviceId !== undefined) {
		serviceId = _serviceId;
	}
	const payload = {
		...rest,
		...filterRest,
		premiere: /^\d{4}-\d{2}-\d{2}$/.test(filter.premiere) ? filter.premiere : null,
		filter: filter.premiere === "lastsent" && "hasBeenSent" || filter.premiere === "current" && "activeWindow" || null,
		searchText,
		serviceId,
		serviceIds,
		status,
	};

	if (status === "attention") {
		delete payload.premiere;
		delete payload.filter;
		delete payload.searchText;
		delete payload.status;
		payload.validationStatus = "failed,warning";
	}

	return payload;
}

export function getStatus() {
	return [
		{
			key: "",
			text: "Any",
			description: "Any package status.",
		},
		{
			key: "attention",
			text: "Needs attention",
			description: "",
		},
		{
			key: "new,queued",
			text: "Waiting",
			description: "Packages waiting for metadata, assets or to be delivered.",
		},
		// {
		// 	key: "blocked",
		// 	text: "Blocked",
		// 	description: "Packages that have been blocked from export.",
		// },
		{
			key: "delivered",
			text: "Partially delivered",
			description: "Parts of the package are on their way to the service.",
		},
		{
			key: "published",
			text: "Ready",
			description: "Packages that have been delivered to the service.",
		},
		{
			key: "unpublished,canceled",
			text: "Removed",
			description: "Packages that have been removed from the service.",
		},
		// {
		// 	key: "canceled",
		// 	text: "Canceled",
		// 	description: "Canceled packages.",
		// }
	]
}

export function getPremiere() {
	const premieres = [
		{
			key: "lastsent",
			text: "Recent activity",
			description: "Shows packages ordered by when they were last sent.",
		},
		{
			key: "past",
			text: "Recently expired",
			description: "Program publishing window has already happened.",
		},
		{
			key: "current",
			text: "Current",
			description: "Program publishing window is currently active.",
		},
		{
			key: "upcoming",
			text: "Coming premieres",
			description: "Program has publishing window sometime in the future.",
		}
	];

	let month = moment().startOf("month");
	for(let i = 0; i <= 2; i++) {
		premieres.push({
			key: month.format("YYYY-MM-DD"),
			text: month.format("MMMM"),
			description: "Program premieres in " + month.format("MMMM"),
		})
		month = month.add(1, "months").startOf("month");
	}
	return premieres;
}

export function getLinkUrl(service, metadata) {
	const programGroup = "all/"; //service && service.contentOwner ? `${service.contentOwner}/` : "all/";
	return metadata.season ? `/metadata/programs/${programGroup}season/${metadata.season.id}` : `/metadata/programs/${programGroup}${metadata.id}`;
}

export function getLinkUrlAndHash(service, metadata) {
	return {
		pathname: getLinkUrl(service, metadata),
		hash: `#p${metadata.id}`,
	};
}


export const sortServicesAndOperators = localeCompareSortNumeric(item => item.displayName || item.name);
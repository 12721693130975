import React from 'react'
import { Route, IndexRoute } from 'react-router'

import Module from '../app'

import EventsApp from './events/app'
import EventsMCRApp from './events-mcr/app'
import EventsMetadataApp from './events-metadata/app'
import EntertainmentApp from './entertainment/app'
import EntertainmentMetadataApp from './entertainment-metadata/app'
import ResourcesApp from './resources/app'
import ScheduleApp from './schedule/app'
import TemplateApp from './template/app'

import AdminDashboardApp from './admin/dashboard/app'
import AdminArenasApp from './admin/arenas/app'
import AdminArenasEditor from './admin/arenaeditor/app'
import AdminParticipantsApp from './admin/participants/app'
import AdminParticipantEditor from './admin/participanteditor/app'
import AdminLeaguesApp from './admin/leagues/app'
import AdminSportsApp from './admin/sports/app'
import AdminSportEditor from './admin/sporteditor/app'
import AdminSeriesApp from './admin/series/app'
import AdminSeriesEditor from './admin/serieseditor/app'
import AdminCMSSeriesApp from './admin/cms-series/app'
import AdminSeasonsApp from './admin/seasons/app'
import AdminDynamicApp from './admin/dynamic/app'
import AdminDynamicEditor from './admin/dynamiceditor/app'
import AdminConsumersEditor from './admin/contentconsumers/app'

import appConfig from 'config'

export default class EventPlannerModule {

	static routes() {
		if (appConfig.features.eventplannerDisabledTempForHERO) {
			return (
				<Route
					path="eventplanner"
				>
					<Route
						path="*"
						component={() => <p style={{ textAlign: "center", margin: "40px 0" }}>Event planner has moved to <a href="https://tv4.junecomet.com/admin">tv4.junecomet.com</a></p>}
					/>
				</Route>
			);
		}

		return (
			<Route path="eventplanner" component={Module} module="eventplanner">
				<Route path="events(/:provider)" component={EventsApp} />
				<Route path="events-mcr" component={EventsMCRApp} />
				<Route path="events-metadata(/:provider)" component={EventsMetadataApp} />
				<Route path="entertainment" component={EntertainmentApp} />
				<Route path="entertainment-metadata" component={EntertainmentMetadataApp} />
				<Route path="resources(/:provider)" component={ResourcesApp} />
				<Route path="schedule" component={ScheduleApp} />
				<Route path="template" component={TemplateApp} />
				<Route path="seasons" component={AdminSeasonsApp} />

				<Route path="admin" app="admin">
					{getAdminRoutes()}
				</Route>

				<Route path="admin-live" app="admin">
					{getAdminRoutes("live")}
				</Route>

				<Route path="admin-linear" app="admin">
					{getAdminRoutes("linear")}
				</Route>
			</Route>
		);
	}
}

const getAdminRoutes = (adminSuffix = "") => {
	const adminPart = adminSuffix ? `admin-${adminSuffix}` : "admin";
	return (
		<>
			<IndexRoute component={AdminDashboardApp} />

			{/* Remove arenas for now since it's not used */}
			{/* If we add it again, make sure to change the path so that it does not mess with Admin>Events>Arenas */}
			{/* <Route path="arenas" component={AdminArenasApp} returnTo={`/eventplanner/${adminPart}`} />
			<Route path="arenas/create" component={AdminArenasEditor} modal={true} returnTo={`/eventplanner/${adminPart}/arenas" />
			<Route path="arenas/:id/edit" component={AdminArenasEditor} modal={true} returnTo={`/eventplanner/${adminPart}/arenas" /> */}

			<Route path="participants" component={AdminParticipantsApp} returnTo={`/eventplanner/${adminPart}`} />
			<Route path="participants/create" component={AdminParticipantEditor} modal={true} returnTo={`/eventplanner/${adminPart}/participants`} />
			<Route path="participants/:id/edit" component={AdminParticipantEditor} modal={true} returnTo={`/eventplanner/${adminPart}/participants`} />
			<Route path="leagues" component={AdminLeaguesApp} returnTo={`/eventplanner/${adminPart}`} />
			<Route path="leagues/create" component={AdminSeriesEditor} modal={true} returnTo={`/eventplanner/${adminPart}/leagues`} />
			<Route path="leagues/:id/edit" component={AdminSeriesEditor} modal={true} returnTo={`/eventplanner/${adminPart}/leagues`} />
			<Route path="sports" component={AdminSportsApp} returnTo={`/eventplanner/${adminPart}`} />
			<Route path="sports/create" component={AdminSportEditor} modal={true} returnTo={`/eventplanner/${adminPart}/sports`} />
			<Route path="sports/:id/edit" component={AdminSportEditor} modal={true} returnTo={`/eventplanner/${adminPart}/sports`} />
			<Route path="series-sport" component={AdminSeriesApp} returnTo={`/eventplanner/${adminPart}`} />
			<Route path="series-sport/create" component={AdminSeriesEditor} modal={true} returnTo={`/eventplanner/${adminPart}/series-sport`} />
			<Route path="series-sport/:id/edit" component={AdminSeriesEditor} modal={true} returnTo={`/eventplanner/${adminPart}/series-sport`} />

			<Route path="series" component={AdminCMSSeriesApp} returnTo={`/eventplanner/${adminPart}`} />
			<Route path="seasons" component={AdminSeasonsApp} returnTo={`/eventplanner/${adminPart}`} />

			<Route path="contentconsumers" component={AdminConsumersEditor} returnTo={`/eventplanner/${adminPart}`} />

			<Route path="template" component={TemplateApp} returnTo={`/eventplanner/${adminPart}`} />

			<Route path=":appKey" component={AdminDynamicApp} returnTo={`/eventplanner/${adminPart}`} />
			<Route path=":appKey/create" component={AdminDynamicEditor} modal={true} />
			<Route path=":appKey/:id/edit" component={AdminDynamicEditor} modal={true} />
		</>
	);
};
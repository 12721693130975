import React from 'react'

import Image from './components/image'
import Checkbox from './components/checkbox'
import CheckboxContainer from './components/checkboxContainer'
import CheckboxContainerMultipleDropdowns from './components/checkboxContainerMultipleDropdowns'
import Label from './components/label'
import TextboxNew from './components/textbox_new'
import Header from './components/header'
import DateTimePicker from './components/datetimepicker'
import DurationPicker from './components/durationPicker'
import Dropdown from './components/dropdown'
import Menu from './components/menu'
import AssetContainer from './components/assetContainer'
import AssetSearch from './components/assetSearch'
import Container from './components/container'
import Link from './components/link'
import List from './components/list'
import Comments from './components/comments'
import Status from './components/status'
import LinkedComponents from './components/linkedComponents'
import TabbedContainer from './components/tabbedContainer'

import { getClassName } from './utils'
import { VisibilityContext } from './visibilityContext'

import '../listItems/standard.css'
import './component.css'

export default React.memo((props) => {
	const {
		templateItem,
		onChange,
		_onChangeWithoutAddedPath,
		path = [],
		otherProps = {},
		itemData = {},
		matchCustomComponent = null,
		parentReadOnly = undefined,
	} = props;

	const visibilityContext = React.useContext(VisibilityContext);
	const onChangeWithoutAddedPath = _onChangeWithoutAddedPath ?? onChange;
	const { controller, componentType, actionName, actionText } = templateItem;
	const value = templateItem.value ?? templateItem.defaultValue;
	const className = React.useMemo(() => getClassName({ item: templateItem, value, parentReadOnly }), [templateItem]);

	const onChangeWithPath = React.useCallback(
		async (newValue, replaceObjectFields, refreshAllItemsAfterUpdate) => {
			await onChangeWithoutAddedPath({
				data: newValue,
				path: actionName ? [] : [...path, templateItem.name],
				actionName,
				actionText,
				replaceObjectFields,
				refreshAllItemsAfterUpdate,
			});
		},
		[path]
	);

	const defaultHidden = templateItem.options?.defaultHidden ?? false;
	const visible = visibilityContext?.[templateItem.name]
		?? visibilityContext?._all
		?? visibilityContext?.[`${templateItem.name}_${itemData?.id}`]
		?? !defaultHidden;
	if (!visible) {
		return null;
	}

	if (typeof matchCustomComponent === "function") {
		const component = matchCustomComponent({
			...props,
			className,
			getTooltipEl,
		});
		if (component) {
			return component;
		}
	}

	const controllerType = controller ?? componentType;
	if (controllerType === "metadata") {
		return <span key={templateItem.name} style={{ display: "none" }}>{templateItem.name}</span>;
	}

	const component = ComponentMap[controllerType];
	if (component) {
		return React.createElement(component, {
			...templateItem,
			value,
			className,
			path,
			onChange: onChangeWithPath,
			_onChangeWithoutAddedPath: onChangeWithoutAddedPath,
			otherProps,
			itemData,
			matchCustomComponent,
			getTooltipEl,
			readOnly: templateItem.readOnly ?? parentReadOnly,
		});
	}

	return <div key={templateItem.name} style={{ color: "red" }}>{templateItem.name}</div>;
});

function getTooltipEl(tooltip) {
	if (tooltip) {
		return <div className="c6-cms-tooltip icon-info_outline" title={tooltip}></div>;
	}

	return null;
}

const ComponentMap = {
	"assetContainer": AssetContainer,
	"assetSearch": AssetSearch,
	"checkbox": Checkbox,
	"checkboxContainer": CheckboxContainer,
	"checkboxContainerMultipleDropdowns": CheckboxContainerMultipleDropdowns,
	"comments": Comments,
	"container": Container,
	"dropdown": Dropdown,
	"dateTimePicker": DateTimePicker,
	"durationPicker": DurationPicker,
	"header": Header,
	"image": Image,
	"label": Label,
	"linkedComponents": LinkedComponents,
	"list": List,
	"menu": Menu,
	"modal": Link,
	"link": Link,
	"status": Status,
	"tabbedContainer": TabbedContainer,
	"textbox": TextboxNew,
	"textArea": TextboxNew,
	"timePicker": DateTimePicker,
}
import React, { Component } from 'react'
import withDnDContext from '../../core/services/withDnDContext'

import { getRouteName } from '../../components/comet'

import App from '../../components/app'
import { Info } from '../../components/comet'

import Actions from './actions'
import Store from './store'

import TopSearch from './shared/topsearch'
import FloatActions from './shared/floatactions'

import './app.css'

const RECENT_KEY = "star-recentSearches"

class StarHome extends Component {
    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);

        const { params, location } = this.props;
        this.searchText = params.searchText || "";
        this.typeFilter = location.query.type || "";
        this.tagsFilter = location.query.tags || "";
        this.searchRef = null;

        this.state = {
            ...Store.getState(),
        };
    }

    componentDidMount() {
        Store.listen(this.onChange);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { params, location } = nextProps;
        this.searchText = params.searchText || "";
        this.typeFilter = location.query.type || "";
        this.tagsFilter = location.query.tags || "";
    }

    componentWillUnmount() {
        Actions.unmount();
        Store.unlisten(this.onChange);
    }

    onChange(state) {
        this.setState(state);
    }

    render() {
        const { searchItems, recentActivity, albums, selected } = this.state.list;

        const isLoading = searchItems.isLoading || recentActivity.isLoading || albums.isLoading;

        const selectedCategories = this.typeFilter.split(",");
        const selectedTags = this.tagsFilter.split(",");
        const recentSearches = localStorage.getItem(RECENT_KEY) || "";

        const selectedVisible = [...this.props.routes].reverse()[0].path === "selected";

        return (
            <App name="c6-star" isLoading={isLoading}>
                <TopSearch
                    searchText={this.searchText || ""}
                    recentSearches={recentSearches.split("|")}
                    selectedCategories={selectedCategories}
                    selectedTags={selectedTags}
                />
                <FloatActions selectedItems={selected.items} selectedVisible={selectedVisible}/>
                { this.props.children }
                <Info>
					{getInfo(this.props.location, searchItems, selectedVisible, selected)}
				</Info>
            </App>
        );
    }
}

export default withDnDContext(StarHome);

// HELPERS
function getInfo(location, list, showSelected, selected) {
    const itemList = showSelected ? selected.items : list.items;

    let items = "";
    if(showSelected && itemList.length) {
        const itemName = itemList.length > 1 ? "items" : "item";
        items = `- ${itemList.length} selected ${itemName}`;
    }
    else if(itemList.length) {
        items = `- displaying ${itemList.length} of ${list.numberOfItems}`;
    }

	return `${getRouteName(location)} ${items}`;
}

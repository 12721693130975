import React from 'react'

import Item from './listItem'
import itemActions from './listItemActions'

import * as StarAPI from '../../../../apis/star'
import useList from '../../../../core/hooks/useList'
import GenericListApp from '../../../../components/genericApp/listApp'
import { decoratorUIActions } from '../../../../core/decorators/uiActions'

import './app.css'

const MODULE = "star";
const DATASTORE = "admin-filetypes";
const TEXT_HEADING = "Media formats";
const TEXT_BACK_BUTTON = "Star administration";
const TEXT_EMPTY = null;//"No services? It's about time to create some then!";
const TEXT_CREATE = "Create media format";
const ICON_CREATE = "add";
const PATHNAME_CREATE = "star/admin/filetypes/create";

function ListApp(props) {

	const FILTER_DEFAULTS = {};
	const filterConfig = {};

	const listState = useList({
		listKey: DATASTORE,
		fetchData: StarAPI.fetchFiletypes,
		filterDefaults: FILTER_DEFAULTS,
	});

	return (
		<GenericListApp
			{...props}
			module={MODULE}
			datastore={DATASTORE}
			state={listState}
			textHeading={TEXT_HEADING}
			textBackButton={TEXT_BACK_BUTTON}
			textEmpty={TEXT_EMPTY}
			textCreate={TEXT_CREATE}
			iconCreate={ICON_CREATE}
			filterConfig={filterConfig}
			pathnameCreate={PATHNAME_CREATE}
			collapseFiltersDefault={false}
		>
			<Item />
		</GenericListApp>
	);
}

@decoratorUIActions(itemActions)
export default class FileTypesApp extends React.Component {
	render() {
		return (
			<ListApp
				{...this.props}
			/>
		);
	}
}

import { CONTENT, INLINE, CLICK_OPT, CLICK_SHIFT } from '../../../components/actions'
import Actions from '../actions'

const SELECTED_STORE = "selectedTrashed";
export default {
	targetComponent: "Item",
	actions: [
		{
			// title: "Select item",
			type: INLINE,
			icon: "star_border",
			method: data => Actions.selectInStore(data, SELECTED_STORE),
			hidden: (data, sourceProps, location, params, itemProps) => itemProps.selected,
		},
		{
			// title: "Unselect item",
			type: INLINE,
			icon: "star",
			method: data => Actions.unselectInStore(data, SELECTED_STORE),
			hidden: (data, sourceProps, location, params, itemProps) => !itemProps.selected,
		},
		{
			type: CLICK_OPT,
			method: data => Actions.toggleSelectInStore(data, SELECTED_STORE),
		},
		{
			type: CLICK_SHIFT,
			method: data => {
				const storeName = "trashed";
				Actions.toggleMultipleSelect({ item: data, storeName, selectedStore: SELECTED_STORE });
			},
		},
	]
};
import React from 'react'
import { useInfiniteQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import debounce from 'lodash/debounce'
import cloneDeep from 'lodash/cloneDeep'
import appConfig from 'config'

import { getEntityWithUpdatedValue, createPatch, splitPath } from '../cms/utils'
import * as Alert from '../../core/services/alert'
import { DEFAULT_QUERY_CLIENT } from '../../core/constants'
import { useSessionStorage } from './useStorage'

export default function useList({
	listKey,
	api,
	entity,
	module,
	fetchData,
	fetchSingle,
	updateData,
	patchData,
	runAction,
	updateDataExtraParams,
	filterDefaults,
	filterConfig,
	transformFilters,
	onFilterChange,
	saveListFiltersInStorage = true,
	getEnabled,
}) {
	const filters = getFilters({ listKey, filterDefaults, filterConfig, onFilterChange, saveListFiltersInStorage, _entity: entity });
	const queryKey = getQueryKey(listKey, filters.values, filterConfig);
	const latestFetchPayload = React.useRef({});
	const latestFetchTimestamp = React.useRef(0);
	const query = useInfiniteQuery({
		queryKey: queryKey,
		queryFn: ({ pageParam = 0 }) => {
			let payload = {
				...filters.values,
				pageIndex: pageParam,
			};
			if (typeof transformFilters === "function") {
				payload = transformFilters(payload);
			}

			latestFetchPayload.current = payload;
			latestFetchTimestamp.current = Date.now();
			return fetchData(payload);
		},
		getNextPageParam: (lastPage) => getNextPageIndex(lastPage), // will be passed to queryFn as pageParam
		enabled: typeof getEnabled === "function" ? getEnabled({ filters }) : true,
	});
	const { isLoading, isFetching, fetchStatus } = query;
	const numberOfItems = query.data?.pages?.[0]?.numberOfItems;

	const [isMutationLoading, setIsMutationLoading] = React.useState(false);
	const queryClient = useQueryClient();
	const refreshItem = getRefreshItemFn({ queryKey, api, entity, module, fetchSingle, queryClient });
	const onChange = getOnChangeFn({
		queryKey,
		entity: entity ?? filters.values._entity,
		module,
		fetchSingle,
		updateData,
		patchData,
		runAction,
		updateDataExtraParams,
		setIsMutationLoading,
		queryClient,
		refreshItem,
	});

	return {
		...query,
		// From Docs: isLoading is only true if the query is in a "hard" loading state. This means there is no cached data and the query is currently fetching, eg isFetching === true
		isLoading: isLoading || isFetching || isMutationLoading || fetchStatus === "fetching",
		filters,
		onChange,
		refreshItem,
		latestFetchPayload,
		latestFetchTimestamp,
		numberOfItems,
	};
}

function getFilters({ listKey, filterDefaults, filterConfig = {}, onFilterChange, saveListFiltersInStorage, _entity }) {
	const defaultValues = {
		pageSize: 50,
		...filterDefaults,
		_entity,
	};

	const [values, setFilters] = useFilterState(defaultValues, listKey, saveListFiltersInStorage);
	const reset = React.useCallback(
		() => setFilters(defaultValues),
		[setFilters, defaultValues]
	);
	const updateMethods = React.useMemo(
		() => {
			return Object.keys(filterDefaults).reduce((methods, name) => {
				const updateMethod = (newValue) => {
					setFilters(currentValues => {
						const newValues = { ...currentValues, [name]: newValue };
						return newValues;
					});
					if (typeof filterConfig[name]?.onChange === "function") {
						filterConfig[name].onChange(newValue);
					}
					if (typeof onFilterChange === "function") {
						onFilterChange();
					}
				};
				let debounceMs = 0;
				if (typeof filterConfig[name]?.debounce === "number") {
					debounceMs = filterConfig[name]?.debounce;
				} else if (filterConfig[name]?.debounce || name === "searchText" || name === "search") {
					debounceMs = 500;
				}

				return {
					...methods,
					[name]: debounceMs > 0 ? debounce(updateMethod, debounceMs, { leading: false, trailing: true }) : updateMethod,
				};
			}, {});
		},
		[_entity]
	);
	return { values, setFilters, reset, updateMethods };
}

function getNextPageIndex(lastPage) {
	const pageIndex = lastPage.pageIndex;
	const next = lastPage.links?.find(l => l.rel === "next");

	const nextPageIndex = pageIndex + 1;
	const hasMore = !!next;
	return hasMore ? nextPageIndex : undefined;
}

function getOnChangeFn({ queryKey, updateData, patchData, runAction, updateDataExtraParams = {}, setIsMutationLoading, queryClient, refreshItem }) {
	if (!updateData && !patchData) {
		return async () => {};
	}

	const { mutateAsync } = useMutation(
		// Arguments comes from call to mutateAsync() below
		async ({ id, data, path, originalItem, updatedItem, actionName, actionText, replaceObjectFields }) => {
			
			try {
				if (actionName && typeof(runAction) !== "function") {
					console.error(`Can not run action "${actionName}" because no "runAction" function was provided to useList()`);
				} else if (actionName) {
					const payload = constructPayload(data, path);
					await runAction({ id, payload, ...updateDataExtraParams }, actionName);
					if (actionText) {
						Alert.displayAlert("success", actionText);
					}
				} else if (patchData) {
					const updatedEntity = updatedItem ?? getEntityWithUpdatedValue(originalItem, splitPath(path), data);
					const patch = createPatch(originalItem, updatedEntity, replaceObjectFields);
					await patchData({ id, patch, ...updateDataExtraParams });
				} else {
					const payload = constructPayload(data, path);
					await updateData({ id, payload, ...updateDataExtraParams });
				}
			} catch (e) {
				console.error(e);
				Alert.displayAlert("error", e.exceptionMessage);
				throw e;
			}
		},
		{
			// Optimistically update the cache value on mutate,
			// but store the old value and return it so that it's available in case of an error
			// Arguments comes from call to mutateAsync()
			onMutate: ({
				id,
				data,
				path,
				originalItem,
				actionName,
			}) => {
				const previousValue = queryClient.getQueryData(queryKey);

				// (Don't optimistically update item if this is an action since we can't predict what the updated item will look like)
				if (actionName) {
					return previousValue; // returned value is passed to onError
				}

				// originalItem seems to not update properly, for some reason, so we try to find the item in the getQueryData
				const previousItem = findItemInPagedData(previousValue, id) ?? originalItem;
				const optimisticallyUpdatedItem = getEntityWithUpdatedValue(previousItem, path, data);
				updateItemInPagedCache(queryClient, queryKey, id, optimisticallyUpdatedItem);

				return previousValue; // returned value is passed to onError
			},

			// On failure, roll back to the previous value (previousValue is returned from onMutate)
			onError: (err, variables, previousValue) => {
				queryClient.setQueryData(queryKey, previousValue);
			},

			// After success or failure, refetch the query
			onSettled: async (data, err, variables) => {
				const {
					id,
					refreshAllItemsAfterUpdate = false,
				} = variables;

				if (refreshAllItemsAfterUpdate) {
					queryClient.invalidateQueries(queryKey);
				} else {
					refreshItem({ id });
				}
			},
		}
	);

	// return async ({ id, data, path, originalItem, actionName, actionText, replaceObjectFields, refreshAllItemsAfterUpdate }) => {
	return async (args) => {
		setIsMutationLoading(true);
		try {
			await mutateAsync(args);
			console.log("Mutation was successful!");
		} catch (ex) {
			console.log("Mutation was NOT successful :(");
			console.error(ex);
		}
		setIsMutationLoading(false);
	};
}

function getRefreshItemFn({ queryKey, api, entity, module, fetchSingle, queryClient }) {
	return async ({ id }) => {
		try {
			const newItemFromAPI = await fetchSingle({
				api,
				_entity: entity,
				_module: module,
				id,
			});
			updateItemInPagedCache(queryClient, queryKey, id, newItemFromAPI);
		} catch (ex) {
			console.log("Error refreshing item");
			console.error(ex);
		}
	}
}


export function updateItemInMatchingQueries({
	client = DEFAULT_QUERY_CLIENT,
	store,
	item: itemObj,
	items: itemsArray = [],
	isDelete,
}) {
	try {
		const items = itemObj
			? [itemObj]
			: itemsArray;

		// HACK?: Use findAll so that queryKey matches all queryKeys for the store
		// (setQueryData matches queryKey "strictly")
		const queries = client.queryCache.findAll({ queryKey: [store] });
		queries.forEach(query => {
			items.forEach(item => {
				updateItemInPagedCache(client, query.queryKey, item.id, item, isDelete);
			});
		});
	} catch (e) {
		// Catch errors so that errors that occur when updating the cache won't crash our lists
		console.error("Something went wrong when updating item in query cache:", e);
	}
}

function constructPayload(data, path = []) {
	if (typeof path === "string") {
		path = path.split(".");
	}
	const [current, ...rest] = path;
	if (current) {
		const [next, ...restSkipNext] = rest;

		// If "next" is a number, it is the index of an array, which means that "current" needs to be an array
		if (typeof next === "number") {
			const arr = [];
			arr[next] = constructPayload(data, restSkipNext);
			return {
				[current]: arr,
			};
		}
		
		return {
			[current]: constructPayload(data, rest),
		};
	}

	return data;
}

function findItemInPagedData(data, id) {
	let itemIndex = -1;
	const pageIndex = data?.pages?.findIndex(d => {
		itemIndex = d.items.findIndex(i => i.id === id);
		return itemIndex >= 0;
	});

	if (pageIndex >= 0 && itemIndex >= 0) {
		return data.pages[pageIndex].items[itemIndex];
	}

	return null;
}

function updateItemInPagedCache(client, queryKey, id, item, isDelete) {
	client.setQueryData(queryKey, oldData => {
		if (!oldData.pages) {
			return oldData;
		}
		
		const newData = cloneDeep(oldData);

		let itemIndex = -1;
		const pageIndex = newData.pages.findIndex(d => {
			itemIndex = d.items.findIndex(i => i.id === id);
			return itemIndex >= 0;
		});

		// Update or delete existing
		if (pageIndex >= 0 && itemIndex >= 0) {
			if (isDelete) {
				newData.pages[pageIndex].items.splice(itemIndex, 1);
			} else {
				newData.pages[pageIndex].items[itemIndex] = item;
			}
		}

		// Append new
		else if (!isDelete) {
			newData.pages[newData.pages.length - 1].items.push(item);
		}

		return newData;
	});
}

function getQueryKey(listKey, filterValues, filterConfig) {
	const values = { ...filterValues };
	if (filterConfig) {
		Object.keys(filterValues).forEach(key => {
			// Filters used only by the frontend should not be added to the queryKey
			// in order to prevent updates in the query when changing their values
			if (filterConfig[key]?.onlyUI) {
				delete values[key];
			}
		});
	}
	return [...(Array.isArray(listKey) ? listKey : [listKey]), values];
}

function useFilterState(defaultValues, listKey, saveListFiltersInStorage) {
	const [valuesState, setFiltersState] = React.useState(defaultValues);
	React.useEffect(() => {
		setFiltersState(defaultValues);
	}
	, [defaultValues._entity]);
	const [valuesStorage, setFiltersStorage] = useSessionStorage(`c6-listfilter-${listKey}`, defaultValues);
	if (saveListFiltersInStorage && appConfig.features.saveListFiltersInStorage) {
		return [valuesStorage, setFiltersStorage];
	}

	return [valuesState, setFiltersState];
}
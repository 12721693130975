import React from 'react'
import PropTypes from 'prop-types'
import styles from './todos.css'

const Todos = ({label = "", children}) => {
	return <ol className="c6-todos icon-playlist_add_check" data-label={label ? `${label}:` : ""}>{children}</ol>;
};
Todos.propTypes = {
	children: PropTypes.array
}

const Todo = ({status, children}) => {
	const classNames = `c6-todo status-${status}`
	return <li className={classNames}>{children}</li>;
};
Todo.propTypes = {
	label: PropTypes.string,
	status: PropTypes.string,
}

export {Todos, Todo};
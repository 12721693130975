import { browserHistory } from 'browserHistory'
import { CONTENT } from '../../../components/actions'


import Actions from '../actions'

export default {
	targetComponent: "Item",
	actions: [
		{
			title: "Show contract licenses",
			method: (data, _sourceProps, location) => {
				const contractsPathPart = location.pathname.split("/").find(p => p.includes("contracts"));
				browserHistory.push(`/acq/${contractsPathPart}/${data.id}/licenses`);
			},
			type: CONTENT,
		},
	]
};

// HELPERS
function getSeasonId(sourceProps) {
	const season = sourceProps && sourceProps.items && sourceProps.items.filter(i => i.type === "Season");
	return season.length ? season[0].id : null;
}
import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import Checkbox from '@mui/material/Checkbox'

import Const from '../../../core/constants'

import { Item, ItemGroup } from '../../../components/list/listItems'
import Empty from '../../../components/list/empty'

const BroadcastList = ({ items, isLoading, onSelectItem, hasSelectedItems, day }) => {
	const noResult = !(items && items.length);

	return noResult
		? <Empty v2={true} isLoading={isLoading}>Sorry, could not find any changes.</Empty>
		: (
			<ItemGroup title={day ? moment(day).format(Const.PERIOD_FORMAT) : null} containerClassName="changes-list">
				{items.map(item => {
					const disabled = item.status === "Confirmed";
					const onSelect = !disabled && onSelectItem ? () => onSelectItem(item.id) : null;
					const hideUnselectedClass = hasSelectedItems && !item.selected ? "clipboard-hide" : "";
					return (
						<Item
							key={item.id}
							id={item.id}
							disabled={disabled}
							small
							onSelect={onSelect}
							sel={item.selected}
						>
							<div className={`flex flex-dyn-1 change ${item.status.toLowerCase()} ${hideUnselectedClass}`}>
								<div className="checkbox-container clipboard-hide">
									{onSelect && (
										<Checkbox
											checked={!!item.selected}
											sx={{ padding: 0 }}
										/>
									)}
								</div>
								<div className="c6-content">
									<h1>
										<span className="status clipboard-hide">{item.status !== "Confirmed" && (item.status === "Update" ? `${moment(item.confirmedStart).format("HH:mm")}` : item.status)}</span>
										<span className="arrow clipboard-hide">&#x25B6;</span>
										<span className="time">{moment(item.start).format("HH:mm")} </span>
										{item.program.title}
									</h1>
								</div>
								<div className="c6-content clipboard-hide">
									<h1>
										<span key="s" className="episode-season-indicator">{item.program.seasonNumber && item.program.seasonNumber != 0 ? "S" : ""}</span>
										<span key="sn" className="season-number">{item.program.seasonNumber && item.program.seasonNumber != 0 ? item.program.seasonNumber : ""}</span>

										<span key="e" className="episode-season-indicator">{item.program.episodeNumber && item.program.episodeNumber != 0 ? "E" : ""}</span>
										<span key="en" className="episode-number">{item.program.episodeNumber && item.program.episodeNumber != 0 ? item.program.episodeNumber : ""}</span>
									</h1>
								</div>
							</div>
						</Item>
					);
				})}
			</ItemGroup>
		);
};

BroadcastList.propTypes = {
	items: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.number,
	})).isRequired,
	isLoading: PropTypes.bool.isRequired,
};

export default BroadcastList;

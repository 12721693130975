import { browserHistory } from 'browserHistory'

import { CONTENT, MENU } from '../../../../components/actions'
import { updateItemInMatchingQueries } from '../../../../core/hooks/useList'

import * as VodCoreAPI from '../../../../apis/vodcore'

export default {
	targetComponent: "Content",
	actions: [
		{
			title: "Edit",
			type: CONTENT,
			method: (data, sourceProps) => {
				browserHistory.push({
					pathname: `/vod/admin/services/${data.actionData.id}/edit`,
					state: {
						modal: true,
						returnTo: `${sourceProps.location.pathname}${sourceProps.location.search}`,
					},
				});
			},
			hidden: (data, sourceProps) => false,
			disabled: (data, sourceProps) => false,
		},
		{
			title: "Edit",
			type: MENU,
			icon: "create",
			method: (data, sourceProps) => {
				browserHistory.push({
					pathname: `/vod/admin/services/${data.actionData.id}/edit`,
					state: {
						modal: true,
						returnTo: `${sourceProps.location.pathname}${sourceProps.location.search}`,
					},
				});
			},
			hidden: (data, sourceProps) => false,
			disabled: (data, sourceProps) => false,
		},
		{
			title: "Enable",
			type: MENU,
			icon: "add_circle",
			method: async (data) => {
				const updatedItem = await VodCoreAPI.createOrUpdateService({
					...data.actionData,
					status: "Enabled",
				});
				updateItemInMatchingQueries({
					store: "admin-services",
					item: updatedItem,
				});
				
			},
			hidden: (data, sourceProps) => data.actionData.status === "Enabled",
			disabled: (data, sourceProps) => false,
		},
		{
			title: "Disable",
			type: MENU,
			icon: "remove_circle",
			method: async (data) => {
				const updatedItem = await VodCoreAPI.createOrUpdateService({
					...data.actionData,
					status: "Disabled",
				});
				updateItemInMatchingQueries({
					store: "admin-services",
					item: updatedItem,
				});
				
			},
			hidden: (data, sourceProps) => data.actionData.status !== "Enabled",
			disabled: (data, sourceProps) => false,
		},
		{
			title: "Show packages",
			type: MENU,
			icon: "archive",
			method: (data) => {
				browserHistory.push({
					pathname: `/vod/packages-new`,
					query: {
						serviceId: data.id,
					},
				});
			},
			hidden: (data, sourceProps) => false,
			disabled: (data, sourceProps) => false,
		}
	]
}
import         React from 'react'

import { Secondary } from '../../../../components/ui/main'
import         Empty from '../../../../components/list/empty'

import        Filter from './filter'
import          List from './list'

import       Actions from '../../actions'

import './app.css'

export default class DiscoveryApp extends React.Component {

	handleShowEpisodes = (itemId) => {
		if (itemId !== -1) {
			const { filters } = this.props.episodesList;
			Actions.discoveryFetchEpisodes(filters, itemId, this.props.discoveryPlatform);
		}
		else {
			Actions.discoveryClearEpisodes();
		}
	}

	render() {
		const { list, episodesList, onItemDropOutside, types } = this.props;
		const { _showEpisodes } = episodesList;

		const isLoading = list.isLoading || episodesList.isLoading;

		return (
			<div className="c6-subapp c6-layout-grid c6-selections-discovery">
				<Filter
					types={types}
					list={list}
					discoveryPlatform={this.props.discoveryPlatform}
				/>

				<Secondary>
					{_showEpisodes && <button className="back-to-results" onClick={this.handleShowEpisodes.bind(this, -1)}>◀ Back to results</button>}
					<List
						items={_showEpisodes ? episodesList.items : list.items}
						onItemDropOutside={onItemDropOutside}
						onShowEpisodes={this.handleShowEpisodes}
						filters={list.filters}
					>
						<Empty v2={true} title="No items found" isLoading={isLoading}>
							No items match your filter.
						</Empty>
					</List>
				</Secondary>
			</div>
		);
	}
};
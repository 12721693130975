import React from 'react'
import { withRouter } from 'react-router'

import Button from '../../../components/ui/controls/button'
import { hasStarEditAccess, downloadAssets } from './utils'

import Actions from '../actions'

import './floatactions.css'

@withRouter
export default class FloatActions extends React.Component {

	state = {
		isDownloading: false,
	}
	_mounted = false

	componentDidMount() {
		this._mounted = true;
	}

	componentWillUnmount() {
		this._mounted = false;
	}

	render() {
		const {
			selectedItems,
			selectedVisible,
			location,
			children,
			container,
			album,
			mySpaceItems,
			onAdd,
			onRemove,
			onMoveFolders,
		} = this.props;

		const count = selectedItems.length;

		const canEdit = hasStarEditAccess();

		const selectedTitle = selectedVisible ? `Back to previous` : `View basket (${count})`;
		const albumHoverTitle = !canEdit ? "You need to have at least editor access to add to or remove from albums." : null;
		const showAddToAlbum = !location.pathname.includes("container");
		const isAlbumRoute = /\/albums\/\d+$/i.test(location.pathname);
		const isContainerRoute = /\/container\/\d+\/edit/i.test(location.pathname);

		const actions = children || (
			<React.Fragment>
				<div className="buttons">
					<Button
						type="star"
						title={selectedTitle}
						onClick={e => { Actions.toggleShowSelected(selectedVisible, location, e); }}
						noBackground
					/>
					{showAddToAlbum && (
						<Button
							type="photo_album"
							title="Add to album"
							onClick={() => {
								Actions.openDialog("add", {
									navigateToAlbumOnAdd: location.pathname.includes("selected"),
								});
							}}
							hoverTitle={albumHoverTitle}
							disabled={!canEdit}
							noBackground
						/>
					)}
					<Button
						type="download"
						hoverTitle="Download all selected images"
						title={this.state.isDownloading ? "Downloading..." : "Download"}
						onClick={async () => {
							this.setState({ isDownloading: true });
							await downloadAssets(selectedItems, "reference");
							if (this._mounted) {
								this.setState({ isDownloading: false });
							}
						}}
						disabled={this.state.isDownloading}
						noBackground
					/>
					<Button
						type="delete"
						title="Trash"
						onClick={() => Actions.trashItems(selectedItems, undefined, isContainerRoute) }
						confirm="Confirm"
						dangerous={true}
						noBackground
					/>
					<Button
						type="cancel"
						title="Empty basket"
						onClick={Actions.clearSelected}
						noBackground
					/>
				</div>
				{(isAlbumRoute || isContainerRoute) && (
					<div className="separator"></div>
				)}

				{isAlbumRoute && getAlbumSpecificButtons(canEdit, selectedItems, album, mySpaceItems, onAdd, onRemove)}
				{isContainerRoute && getContainerSpecificButtons(canEdit, selectedItems, container, onAdd, onRemove, onMoveFolders)}
			</React.Fragment>
		);

		return (
			<div className="c6-float-actions">
				<div className={count > 0 ? "show" : "hide"}>
					{actions}
				</div>
			</div>
		);
	}
}

function getAlbumSpecificButtons(canEdit, selectedItems, album, mySpaceItems, onAdd, onRemove) {
	const isMySpace = album.id === 0;
	if (isMySpace) {
		return null;
	}
	
	const assets = isMySpace ? mySpaceItems : album.assets;
	const hasSelectedFromCurrentAlbum = assets && assets.some(a => selectedItems.some(si => si.id == a.id));
	const hasSelectedFromOutsideCurrentAlbum = assets && selectedItems.some(si => !assets.some(a => a.id == si.id));

	return (
		<div className="buttons">
			<Button
				type="add_circle_outline"
				title="Add to this album"
				onClick={onAdd}
				// hoverTitle={albumHoverTitle}
				disabled={!canEdit || !hasSelectedFromOutsideCurrentAlbum || isMySpace}
				noBackground
			/>
			<Button
				type="clear"
				title="Remove from this album"
				onClick={onRemove}
				// hoverTitle={albumHoverTitle}
				disabled={!canEdit || !hasSelectedFromCurrentAlbum}
				noBackground
			/>
		</div>
	);
}

function getContainerSpecificButtons(canEdit, selectedItems, container, onAdd, onRemove, onMoveFolders) {
	const hasSelectedFromCurrentContainer = container && container.assets && container.assets.some(a => selectedItems.some(si => si.id == a.id));
	const hasSelectedFromOutsideCurrentContainer = container && container.assets && selectedItems.some(si => !container.assets.some(a => a.id == si.id));
	const hasSelectedContainer = selectedItems.some(si => si.type === "Container");

	return (
		<div className="buttons">
			<Button
				type="create_new_folder"
				title="Move selected folders here"
				onClick={onMoveFolders}
				// hoverTitle={albumHoverTitle}
				disabled={!canEdit || !hasSelectedContainer}
				noBackground
			/>
			<Button
				type="add_circle_outline"
				title="Add to this folder"
				onClick={onAdd}
				// hoverTitle={albumHoverTitle}
				disabled={!canEdit || !hasSelectedFromOutsideCurrentContainer}
				noBackground
			/>
			<Button
				type="clear"
				title="Remove from this folder"
				onClick={onRemove}
				// hoverTitle={albumHoverTitle}
				disabled={!canEdit || !hasSelectedFromCurrentContainer}
				noBackground
			/>
		</div>
	);
}
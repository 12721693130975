import React from 'react'
import moment from 'moment'

export function extractMetadata(item, showEpisodes) {

	// C70 Discovery items will have a json property instead of a data property
	if(item.json) {
		return C70extractMetadata(item, showEpisodes);
	}

	const referenceId = parseInt(item.contentType.referenceId, 10) || -1;

	const activeFrom = moment(item.activeFrom);

	let licenseDescription = "";
	if (["LiveSport", "SportClipHighlights", "SportClipSummary"].includes(item.contentType.name)) {
		licenseDescription = moment(item.date).format("HH:mm dddd D MMMM");
	} else if (item.contentType.name == "SingleProgram" || item.contentType.name == "SeasonProgram" || item.contentType.name == "EpisodeProgram") {
		const future = activeFrom.diff(moment()) > 0;
		licenseDescription = activeFrom.calendar(null, {
			sameDay: '[Premieres today] (D MMMM YYYY)',
			nextDay: '[Premieres tomorrow] (D MMMM YYYY)',
			nextWeek: '[Premieres on] dddd (D MMMM YYYY)',
			lastDay: '[Premiered yesterday] (D MMMM YYYY)',
			lastWeek: '[Premiered last] dddd (D MMMM YYYY)',
			sameElse: future ? '[Premieres] D MMMM YYYY' : '[Premiered] D MMMM YYYY'
		});
	}

	const description = `${item.description || ""} ${licenseDescription}`.trim();

	let action = null;
	if (showEpisodes != null && typeof showEpisodes == "function" && item.contentType.name == "SeasonProgram") {
		action = {
			title: "Episodes",
			onClick: () => showEpisodes(item.id)
		};
	}

	let episodeName = null;
	if (item.contentType.name == "EpisodeProgram" && item.data) {
		try {
			const data = JSON.parse(item.data);
			if(data.metadata) {
				const metadata = JSON.parse(data.metadata);
				episodeName = `${item.displayName}. ${metadata.desc}`
			}
		} catch(e) {
			console.warn("Failed to parse JSON data: ", item.data);
			console.warn(e);
		}
	}

	let isGame = false;
	if (["LiveSport", "SportClipHighlights", "SportClipSummary"].includes(item.contentType.name) && item.data) {
		try {
			const data = JSON.parse(item.data);
			if (data.category && data.category == "Game") {
				isGame = true;
			}
		} catch(e) {
			console.warn("Failed to parse JSON data: ", item.data);
			console.warn(e);
		}
	}

	return {
		id: `discovery-${item.id}`,
		referenceId,
		image: {
			referenceId,
			contentType: item.contentType.name,
		},
		description,
		toolTip: `Reference Id: ${referenceId}`,
		action,
		episodeName,
		isGame
	}
}

export function C70extractMetadata(item, showEpisodes) {
	const {
		id,
		reference,
		type,
		activeDate,
		validFrom,
		validUntil,
		class: itemClass,
	} = item;
	
	const referenceId = parseInt(reference, 10) || -1; // || dataId?

	const isSport = ["Sport"].includes(itemClass);

	let data = {};
	try {
		data = JSON.parse(item.json);
	} catch (e) {
		console.warn("Failed to parse JSON data: ", item.json);
		console.warn(e);
		data = {};
	}

	let action = null;
	if (showEpisodes != null && typeof showEpisodes == "function" && type === "SeasonProgram") {
		action = {
			title: "View episodes",
			onClick: () => showEpisodes(id)
		};
	}

	let episodeName = null;
	if (type === "EpisodeProgram" && data.metadata) {
		try {
			const metadata = JSON.parse(data.metadata);
			episodeName = `${item.displayName}. ${metadata.desc}`
		} catch(e) {
			console.warn("Failed to parse metadata: ", data.metadata);
			console.warn(e);
		}
	}

	const isGame = isSport && data.category == "Game";

	let imageGuid, epgTitle;
	if (data.programVersions) {
		const assets = (data.programVersions[0] || {}).assets || [];
		const image = assets.find(a => a.type === "image" && a.category === "Main");
		if (image) {
			imageGuid = image.id;
		}

		// epgTitle = data.programVersions[0]?.epgTitle ?? "";
	}

	return {
		id: `discovery-${id}`,
		referenceId,
		image: {
			// referenceId,
			guid: imageGuid,
			contentType: item.subType,
		},
		tooltip: `${data.internalName ?? ""} (${referenceId})`,
		action,
		episodeName,
		epgTitle,
		isGame,
		broadcast: data?.broadcast,
	}
}
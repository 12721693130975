import React from 'react'

import Editor, { parseUi } from '../../../../components/editor/index'
import { hasAccessToPath } from '../../../../core/services/auth'

import schema from './schema'
import uiSchema from './uiSchema'

const FileTypeEditor = props => {
	return (
		<Editor
			layout="grid"
			api="star"
			entity="filetype"
			getSchema={getSchema}
			getUiSchema={getUiSchema}
			hasEditAccess={hasAccessToPath(props.routes, "admin")}
			enableEditorNavigation={true}
			{...props}
		/>
	);
};

export default FileTypeEditor;

function getSchema(model, isNew, location, route, params) {
	return schema;
}

function getUiSchema(model, isNew, location, route, params) {
	return uiSchema;
}
import React from 'react'

import App from '../../../components/app'
import { StandardInfo } from '../../../components/comet'
import Main from '../../../components/ui/main'
import Header from '../../../components/headers/header'
import Button from '../../../components/ui/controls/button'

import List from './list'

import Actions from '../actions'
import Store from '../store'

const MODULE = "shield"
const PARENT_ENTITY =  "service"
const PARENT_TITLE = "Services"
const ENTITY =  "resource"
const TEXT_HEADING = "Resources"
const TEXT_EMPTY = "No resources? It's about time to create some then!"
const TEXT_CREATE = "Create resource"
const ICON_CREATE = "add"

export default class ListApp extends React.Component {

	constructor(props) {
		super(props);

		this.onChange = this.onChange.bind(this);
		this.handleSetProperty = this.handleSetProperty.bind(this);

		this.serviceId = this.props.params.serviceId;

		this.state = {
			...Store.getState(),
		};
	}

	componentDidMount() {
		Store.listen(this.onChange);
		Actions.fetchItems(`${ENTITY}s`, { serviceId: this.serviceId });
		Actions.fetchItem(PARENT_ENTITY, { id: this.serviceId });
	}

	componentWillUnmount() {
		Actions.unmount();
		Store.unlisten(this.onChange);
	}

	onChange(state) {
		this.setState(state);
	}

	handleSetProperty(data, entity, propertyName, e) {

		const property = {
			[propertyName]: e.target.value,
		};

		Actions.updateItem(ENTITY, data, property);
	}

	handleCreateNew() {
		Actions.create({
			// pathname: `/${MODULE}/${PARENT_ENTITY}s/${this.serviceId}/${ENTITY}s/create`,
			pathname: `/shield/admin/resources/create`,
			search: `?serviceId=${this.serviceId}`,
			returnTo: this.props.location.pathname,
		});
	}

	render() {
		const { item } = this.state;
		const heading = getHeading(item);
	    return (
			<App
				name={`c6-${MODULE}-${ENTITY}s`}
				layout="grid"
				isLoading={this.state.isLoading}>
				<Header title={heading} backToTitle={PARENT_TITLE}>
					<Button
						type={ICON_CREATE}
						title={TEXT_CREATE}
						onClick={this.handleCreateNew.bind(this)} />
				</Header>
				<Main>
					<List
						items={this.state[`${ENTITY}s`]}
						isLoading={this.state.isLoading}
						textEmpty={TEXT_EMPTY}

						handleSetProperty={this.handleSetProperty}
					/>
				</Main>
				<StandardInfo location={this.props.location} />
			</App>
		);
	}
}

// HELPERS
function getHeading(item) {
		const data = item[PARENT_ENTITY];
		const heading = data && data.displayName || "Loading";
		return `${heading} / ${TEXT_HEADING}`;
}
import * as CMSAPI from '../../../apis/cms'
import { useQuery } from '@tanstack/react-query'

const useCMSRegularSeries = () => {
    const filters = {
        _entity: "serieslist",
        _customFilter: "(eventType == 'regular' or eventType == null)", // We currently list inactive series below active series
        // _customFilter: "(eventType == 'regular' or eventType == null) and (hasActiveEvents == null or hasActiveEvents == true)",
        eventType: "regular",
        pageSize: 500,
    };

    return useQuery(
        ["cms", "series", filters],
        () => CMSAPI.fetchCMSEntities(filters),
    );
};

export default useCMSRegularSeries;
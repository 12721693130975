import React from 'react'

import * as CMSAPI from '../../apis/cms'
import { getUserAccessLevelForModule } from '../../core/services/auth'

export const ItemActionsContext = React.createContext();

export const useItemActionsState = ({
    extraActions = [],
    _entity,
    refetch,
    setModalData,
    collection,
    module = "eventplanner",
}) => {
    return React.useMemo(
        () => {
            const actions = [];

            const accessLevel = getUserAccessLevelForModule(module);
            const isAdmin = accessLevel === "admin";
            if (isAdmin) {
                actions.push({
                    key: "delete",
                    onClick: async (itemData) => {
                        await CMSAPI.deleteCMSEntity({ _entity, id: itemData.id });
                        refetch();
                    },
                    text: "Delete",
                    confirm: true,
                    confirmText: "Are you sure you want to delete this item?",
                });
            }

            actions.push({
                key: "audit",
                // icon: "access_time",
                onClick: (itemData) => {
                    setModalData({
                        modalName: "audit",
                        itemData: itemData,
                        id: itemData.id,
                        collection: collection,
                    });
                },
                text: "Audit trail",
            });

            return [
                ...actions,
                ...extraActions
            ];
        },
        [refetch, collection]
    );
};
import React from 'react'

import ContentItem, { Content } from '../../../components/list/contentItem'
import InlineHeading from '../../../components/ui/inlineHeading'
import Label from '../../../components/ui/label'
import LazyLoad from '../../../components/lazyload'

import '../../../components/listItems/standard.css'

const Source = (props) => {
	const {
		itemData,
	} = props;

	const {
		id,
		name,
		enabled,
		language,
		rank,
		// reference,
		// homePage,
		// maxScore,
	} = itemData;

	// If Reviews is used in other Comet installations, we need to move the image base URL to appConfig
	const logoUrl = `https://discoverycometstorage.z6.web.core.windows.net/images/${id}/${id}_logo_h100px.png`;

	return (
		<ContentItem key={id} data={props}>
			<LazyLoad offsetVertical={400} className="c6-preview">
				<img
					src={logoUrl}
					decoding="async"
				/>
			</LazyLoad>
			<Content>
				<h1>{name}</h1>
				<p>
					<Label status={enabled ? "undefined" : "inactive"}>{enabled ? "Active" : "Inactive"}</Label>
					<span style={{ marginLeft: "5px" }}>Rank: {rank}</span>
				</p>
			</Content>
			<Content>
				<InlineHeading title="Language">{language}</InlineHeading>
			</Content>
		</ContentItem>
	);
};

export default Source;
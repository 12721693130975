import React from 'react'
import moment from 'moment'
import InfiniteScroll from 'react-infinite-scroller'

import { CustomDraggable } from '../../../components/calendar'
import ContentItem, { Content } from '../../../components/list/contentItem'
import Preview from '../../../components/assets/preview'
import { renderLicenseType, shouldDisplayRegionForLicenses } from '../utils'
import { decoratorUIActions } from '../../../core/decorators/uiActions'
import contentActions from './programPickerListItemActions'

import Const from '../../../core/constants'

import './programpicker.css'

@decoratorUIActions(contentActions)
export default class ProgramPicker extends React.Component {

	render() {
		const {
			programs,
			episodes,
			unfilteredProgramsStats,
			unfilteredPrograms,
			isViewingUnfilteredPrograms,
			onViewUnfilteredProgramsClick,
			loadMore,
			hasMore,
			filters,
			isViewingEpisodes,
		} = this.props;

		const unfilteredProgramsAvailable = unfilteredProgramsStats.numberOfItems != 0;
		let list = programs;
		if (isViewingUnfilteredPrograms) {
			list = unfilteredPrograms;
		} else if (isViewingEpisodes) {
			list = episodes;
		}

		let content;
		if (list.isLoading && !list.items.length) {
			content = <h3>Loading {isViewingEpisodes ? "episodes" : "programs"}...</h3>;
		} else if (isViewingEpisodes && !list.items.length) {
			content = <h3>This season has no episodes.</h3>;
		} else if (!list.items.length && filters.searchText?.length) {
			content = unfilteredProgramsAvailable && !unfilteredProgramsStats.isLoading
				? <h3>No match for search ({unfilteredProgramsStats.numberOfItems} available if you <span className="c6-link" onClick={e => onViewUnfilteredProgramsClick(e)}>search all content</span>)</h3>
				: <h3>No licenses match your search.</h3>;
		} else if (!list.items.length) {
			content = <h3>No licenses start this month.</h3>;
		} else if (list.items.length) {
			content = (
				<InfiniteScroll
					loadMore={loadMore}
					hasMore={hasMore}
					loader={<div className="infinite-loader" key="infinite-loader">Loading...</div>}
					useWindow={false}
					threshold={700}
					initialLoad={false}
				>
					{list.items.map(program => (
						<Program
							{...program}
							key={program.id}
							onViewEpisodesClick={this.props.onViewEpisodesClick}
							readonly={this.props.readonly}
							disableDrag={this.props.disableDrag}
						/>
					))}
				</InfiniteScroll>
			);
		}

		return (
			<div className="c6-program-picker scroll-track-bg">
				{(isViewingEpisodes || isViewingUnfilteredPrograms) && <button className="back-to-results" onClick={this.props.onBackToResultsClick}>◀ Back to results</button>}
				{content}
			</div>
		);
	}
}


const Program = (props) => {
	const {
		programGuid,
		name,
		productionYear,
		typeName,
		referenceId,
		onViewEpisodesClick,
		licenses,
	} = props;

	// const now = moment();

	const displayRegion = shouldDisplayRegionForLicenses(licenses);
	const sortedLicenses = licenses?.sort((a, b) => {
		return moment(a.validFrom).diff(moment(b.validFrom));
	});

	return (
		<ContentItem data={props}>
			<div className="program-info">
				<div className="preview-wrapper">
					{typeName === "Season" && (
						<div className="c6-label c6-label-large c6-status-active">Season</div>
					)}
					<Preview programId={programGuid} />
				</div>
				<Content>
					<h1>
						<span>
							{name}
							{productionYear && <span>({productionYear})</span>}
						</span>
						{typeName === "Season" && <a className="c6-link" onClick={e => onViewEpisodesClick(e, referenceId/*, rights, type*/)}>View episodes</a>}
					</h1>
				</Content>
			</div>
			{licenses?.length > 0 && (
				<div className="program-licenses">
					<Content>
						{sortedLicenses.map(license => {
							const {
								validFrom,
								validUntil,
								type = {},
								style,
								priority,
								hasUnplannedEpisodes,
								rights = [],
								exposures = [],
								id: licenseId,
							} = license;

							// const isExpired = moment(validUntil).isBefore(now);
							// if (isExpired) {
							// 	return null;
							// }

							const { start, end, isScheduled, isExpired } = getPeriod(validFrom, validUntil, exposures, typeName, hasUnplannedEpisodes);
							const notScheduledIcon = !isScheduled && !isExpired && <span className="not-scheduled-icon icon-new_releases"></span>;
							const tooltip = !isScheduled && !isExpired ? "License is not planned" : null;
							return (
								<CustomDraggable
									key={license.id}
									elementProps={{
										className: `c6-planner-program-license ${!isScheduled ? "not-scheduled" : ""} licenseId-${licenseId}`,
										title: tooltip,
									}}
									data={{
										...license,
										title: name,
										constraint: { startTime: start, endTime: end, start, end }, // All these are needed for properly setting the constraints for FullCalendar
										start, // All these are needed for properly setting the constraints for FullCalendar
										end, // All these are needed for properly setting the constraints for FullCalendar
										className: `${style}`,
									}}
									disableDrag={props.readonly || props.disableDrag}
								>
									<div className="hidden-program-info-show-when-dragging">
										<span>
											{name}
											{productionYear && <span>({productionYear})</span>}
										</span>
									</div>
									<div>{renderLicenseType(type, style, priority, rights, undefined, displayRegion)}{renderStartEnd(start, end, isExpired)}{notScheduledIcon}</div>
								</CustomDraggable>
							);
						})}
					</Content>
				</div>
			)}
		</ContentItem>
	);
};


function getPeriod(licenseStart, licenseEnd, exposures = [], typeName, hasUnplannedEpisodes) {
	const now = moment();

	let start = licenseStart;
	let end = licenseEnd;
	let isScheduled = false;
	let isExpired = false;

	if (typeName === "Season" && !hasUnplannedEpisodes) {
		isScheduled = true;
	} else {
		isScheduled = exposures.some(e => e.changedEnd || (e.end && e.dirty));
	}

	isExpired = moment(end).isBefore(now);

	const activeExposures = exposures.filter(e => {
		const eStart = e.changedStart && moment(e.changedStart);
		const eEnd = e.changedEnd && moment(e.changedEnd);
		return eStart?.isBefore(now) && eEnd?.isAfter(now);
	});
	const upcomingExposures = exposures.filter(e => {
		const eStart = e.changedStart && moment(e.changedStart);
		return eStart?.isAfter(now);
	}).sort((a, b) => moment(a.changedStart).diff(moment(b.changedStart)));
	const expiredExposures = exposures.filter(e => {
		const eEnd = e.changedEnd && moment(e.changedEnd);
		return eEnd?.isBefore(now);
	}).sort((a, b) => moment(b.changedEnd).diff(moment(a.changedEnd)));

	if (activeExposures.length > 1) {
		// If there are multiple active exposures, take start & end from the one that ends last
		const exposureWithLargestEnd = activeExposures.reduce((acc, curr) => {
			const currEnd = moment(curr.changedEnd);
			if (!acc || currEnd.isAfter(acc.changedEnd)) {
				return curr;
			}
			return acc;
		}, null);
		start = exposureWithLargestEnd.changedStart;
		end = exposureWithLargestEnd.changedEnd;
		isScheduled = true;
	} else if (activeExposures.length === 1) {
		// If there is only one active exposure, take start & end from that one
		start = activeExposures[0].changedStart;
		end = activeExposures[0].changedEnd;
		isScheduled = true;
	} else if (upcomingExposures.length > 0) {
		// Take start & end from the first upcoming exposure
		start = upcomingExposures[0].changedStart;
		end = upcomingExposures[0].changedEnd;
	} else if (expiredExposures.length > 0) {
		// Take start & end from the last expired exposure
		start = expiredExposures[0].changedStart;
		end = expiredExposures[0].changedEnd;
	}

	return {
		start,
		end,
		isScheduled,
		isExpired,
	};
}

function renderStartEnd(start, end, isExpired) {
	return (
		<span 
			className={`${isExpired ? "expired" : ""}`}
		>
			{start && <time dateTime={start}>{moment(start).format(Const.PERIOD_FORMAT)}</time>}
			{end && <time dateTime={end}>{moment(end).format(Const.PERIOD_FORMAT)}</time>}
		</span>
	);
}
import React from 'react'
import PropTypes from 'prop-types'

import Item from './listItem'

export default class List extends React.Component {

	static propTypes = {
		isLoading: PropTypes.bool,
		items: PropTypes.array.isRequired
	}

	render() {
		const { items, isLoading, list } = this.props;

        return (
            <div>
                {this.renderItems(items, list)}
            </div>
        );
  	}

	renderItems(items, list) {
		return items.map(item => (
			<Item
				key={item.id}
				data={item}
				list={list}
				{...item}
			/>
		));
	}
}
import React from 'react'

// import Item from './item'
import Item from '../shared/listItem'
import itemActions from '../packages/listItemActions'

import * as VodCoreAPI from '../../../apis/vodcore'
import useList from '../../../core/hooks/useList'
import GenericListApp from '../../../components/genericApp/listApp'
import { decoratorUIActions } from '../../../core/decorators/uiActions'

const MODULE = "vod";
const DATASTORE = "packages-series";
// const TEXT_HEADING = "Packages";
const TEXT_BACK_BUTTON = "Packages";
const TEXT_EMPTY = null;//"No services? It's about time to create some then!";
const TEXT_CREATE = null;//"Create service";
const ICON_CREATE = null;//"add";
const PATHNAME_CREATE = null;//"vod/admin/services/create";

function ListApp(props) {

	const FILTER_DEFAULTS = {
		seriesReference: props.params.id,
		pageSize: 50,
		serviceId: props.location.query.serviceId,
		orderBy: "name",
	};

	const filterConfig = {

	};

	const listState = useList({
		listKey: DATASTORE,
		fetchData: VodCoreAPI.fetchPackages,
		filterDefaults: FILTER_DEFAULTS,
	});

	const seriesTitle = listState.data?.pages[0].items?.[0]?.metadata?.series.originalTitle ?? "";
	const serviceName = listState.data?.pages[0].items?.[0]?.service?.name ?? "";

	return (
		<GenericListApp
			{...props}
			className="c6-vod-packages"
			module={MODULE}
			datastore={DATASTORE}
			state={listState}
			textHeading={`${seriesTitle ? `"${seriesTitle}"` : ""} ${serviceName && seriesTitle ? `(${serviceName})` : ""}`}
			textBackButton={TEXT_BACK_BUTTON}
			textEmpty={TEXT_EMPTY}
			textCreate={TEXT_CREATE}
			iconCreate={ICON_CREATE}
			filterConfig={filterConfig}
			pathnameCreate={PATHNAME_CREATE}
			collapseFiltersDefault={false}
			virtualize={false}
		>
			<Item
				showSeriesLink={false}
			/>
		</GenericListApp>
	);
}

@decoratorUIActions(itemActions)
export default class SeriesApp extends React.Component {
	render() {
		return (
			<ListApp
				{...this.props}
			/>
		);
	}
}
import { CONTENT, MENU } from '../../../components/actions'
import EditorNavigationController from '../../../core/ui/editorNavigationController'

import Actions from '../actions'

const ENTITY = "service";

export default {
	targetComponent: "Content",
	actions: [
		{
			title: "Edit resources",
			// method: Actions.go("/shield/admin/services/{id}/resources"),
			method: Actions.showServiceResources,
			type: CONTENT,
		},
		{
			title: "Edit",
			icon: "create",
			method: (data, sourceProps, location) => {
				Actions.edit({
					id: data.id,
					pathname: `/shield/admin/${ENTITY}s/${data.id}/edit`,
					returnTo: `${location.pathname}${location.search}`,
				});

				EditorNavigationController.setCurrentItem(data);
			},
			type: MENU,
		},
		{
			title: "Enable",
			icon: "add_circle",
			method: data => { Actions.enableItem(ENTITY, data); },
			type: MENU,
			hidden: data => data.enabled,
		},
		{
			title: "Disable",
			confirm: true,
			icon: "remove_circle",
			method: data => { Actions.disableItem(ENTITY, data); },
			type: MENU,
			hidden: data => !data.enabled,
		},
		{
			title: "Delete",
			confirm: true,
			dangerous: true,
			icon: "delete",
			method: data => { Actions.removeItem(ENTITY, data); },
			type: MENU,
			hidden: data => data.enabled,
		},
	]
};
import { CONTENT } from '../../../components/actions'

export default {
	targetComponent: "Content",
	actions: [
		{
			title: "Select program",
			method: (program, sourceProps) => {
                if (sourceProps.onProgramClick) {
                    sourceProps.onProgramClick(program);
                }
            },
			type: CONTENT,
		},
	]
};
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Const from '../../../core/constants'

import Status from '../../../components/ui/status'
import { Content } from '../../../components/list/contentItem'
import ContentItem from '../../../components/list/contentItem'
import InlineHeading from '../../../components/ui/inlineHeading'
import Period from '../../../components/ui/period'

import Store from '../store'

const sortByLandCode = ["SE", "NO", "DK", "FI"]

export default class Events extends Component {

	static propTypes = {
		id: PropTypes.number,
		programVersions: PropTypes.string
	}

	constructor(props) {
		super(props);

		this.state = {
			...Store.getState(),
		}
	}

	onChange = (state) => {
		this.setState(state);
	}

	exposureUpdate = (event) => {
		const result = JSON.parse(event.data)
		const regionD = result.regionData

		return (
			<ContentItem className={event.text !== "Exposure updated" && "hidden"}>
				{event.text === "Exposure updated" &&
					<Content className="asset-update">
						<div className="general-information">
							<InlineHeading title={event.text}>&nbsp;</InlineHeading>
							<InlineHeading title={event.createdBy}>&nbsp;</InlineHeading>
						</div>
						<div>
							<Period start={result.licenseStart} end={result.licenseEnd} format={Const.DATE_TIME_FORMAT} />
							{sortByLandCode.map((region) => (
								<button key={region} className={JSON.parse(regionD).some(r => r.displayName === region) ? `regionButtons-included` : `regionButtons-excluded`} name={region} title="Region" style={{ fontSize: "9px", height: "18px", width: "29px", fontWeight: "bold" }}>{region}</button>
							))}
						</div>
						<div className="last-changed">
							<InlineHeading title="Last Changed"><Period start={result.modified} format={Const.DATE_TIME_FORMAT} /></InlineHeading>
						</div>
					</Content>
				}
			</ContentItem>
		)
	}

	assetUpdate = (event) => {
		const result = JSON.parse(event.data)
		console.log(result)
		return (
			<ContentItem className={event.text !== "Asset updated" && "hidden"}>
				{event.text === "Asset updated" &&
					<Content className="asset-update">
						<div className="general-information">
							<InlineHeading title={event.text}>&nbsp;</InlineHeading>
							<InlineHeading title={event.createdBy}>&nbsp;</InlineHeading>
						</div>
						<div className="asset-button">
							<span style={{ marginLeft: "49%" }}>{renderCountry({ result })}</span>
							<Status
								label={renderAssetType({ result })}
								status={renderAssetStatus({ result })}
							>
							</Status>
						</div>
						<div className="last-changed">
							<InlineHeading title="Last Changed"><Period start={result.modified} format={Const.DATE_TIME_FORMAT} /></InlineHeading>
						</div>
					</Content>
				}
			</ContentItem>
		)
	}

	programUpdate = (event) => {
		const result = JSON.parse(event.data)

		return (
			<ContentItem className={event.text !== "Program version updated" && "hidden"}>
				{event.text === "Program version updated" &&
					<Content className="asset-update">
						<div className="general-information">
							<InlineHeading title={event.text}>&nbsp;</InlineHeading>
							<InlineHeading title={event.createdBy}>&nbsp;</InlineHeading>
							<Period start={event.occuredOn} format={Const.DATE_TIME_FORMAT} />
						</div>
						<div>
							<p>{renderDistributionStatus({ result })}</p>
						</div>
					</Content>
				}
			</ContentItem>
		)
	}

	distributionDataUpdate = (event) => {
		const result = JSON.parse(event.data)

		return (
			<ContentItem className={event.text !== "Program version updated" && "hidden"}>
				{event.text === "Distribution data updated" &&
					<Content className="asset-update" >
						<div className="general-information">
							<InlineHeading title={event.text}>&nbsp;</InlineHeading>
							<InlineHeading title={event.createdBy}>&nbsp;</InlineHeading>
							<Period start={event.occuredOn} format={Const.DATE_TIME_FORMAT} />
						</div>
						<div>
							<p>{renderDistributionStatus({ result })}</p>
						</div>
					</Content>
				}
			</ContentItem>
		)
	}


	render() {
		const { id } = this.props;

		const items = typeof this.state.list === "undefined" ? 0 : this.state.list.events.items;

		return (
			<Content>
				{items.map((item, i) =>
					<div key={i}>
						<div>
							<p style={{ marginLeft: "25px", fontSize: "20px" }}>{item.type} | {item.name}</p>
						</div>
						{item.events.map((event, j) =>
							<ContentItem className="programs" key={j}>
									{this.exposureUpdate(event)}

									{this.assetUpdate(event)}

									{this.programUpdate(event)}
									
									{this.distributionDataUpdate(event)}
							</ContentItem>
						)}
					</div>
				)}
			</Content>
		);

	}
}

function renderAssetType({ result }) {
	switch (result.assetType) {
		case 1:
			return "Video";
		case 2:
			return "Audio";
		case 3:
			return "Subtitle";
		case 4:
			return "Trailer";
	}
}

function renderAssetStatus({ result }) {
	switch (result.assetStatus) {
		case 1:
			return "Waiting";
		case 3:
			return "Available";
		case 4:
			return "Ok";
		case 5:
			return "Ignored";
		case 6:
			return "Failed";
	}
}

function renderCountry({ result }) {
	switch (result.languageId) {
		case 1:
			return "SW";
		case 2:
			return "NO";
		case 3:
			return "DK";
		case 4:
			return "FI";
	}
}

function renderDistributionStatus({ result }) {
	switch (result.distributionStatus) {
		case 1:
			return "Normal";
		case 2:
			return "On Hold";
		case 3:
			return "Recalled";
	}
}
import React from 'react'
import moment from 'moment'
import MuiSwitch from '@mui/material/Switch'
import FormControlLabel from '@mui/material/FormControlLabel'

import Switch from '../../../components/ui/controls/switch'
import Search from '../../../components/ui/controls/search'

import './programpickerheader.css'

export default class ProgramPickerHeader extends React.Component {

	render() {
		const { filters, handleFilter, date } = this.props;
		
		return (
			<div className="c6-program-picker-header">
				<div className="row">
					<div>
						<Switch
							name="_premiereFilter"
							onChange={handleFilter.bind(this, "filter")}
							currentState={filters.filter._premiereFilter}
							states={getFilterStates(date)}
							unselected={filters.searchText.length > 0}
						/>
					</div>
					<div>
						<Search
							onChange={handleFilter.bind(this, "search")}
							placeholder="Search"
							searchText={filters.searchText}
						/>
					</div>
				</div>
				<div className="row">
					<div>
						<FormControlLabel
							control={
								<MuiSwitch
									checked={filters.filter.onlyunplanned}
									onChange={() => handleFilter("filter", { target: { name: "onlyunplanned", value: !filters.filter.onlyunplanned } })}
								/>
							}
							label="Only unplanned"
							labelPlacement="end"
							title="Only list unplanned licenses."
							sx={{ marginLeft: "0" }}
						/>
						<FormControlLabel
							control={
								<MuiSwitch
									checked={filters.filter._expandSeasons}
									onChange={() => handleFilter("filter", { target: { name: "_expandSeasons", value: !filters.filter._expandSeasons } })}
								/>
							}
							label="Show episodes"
							labelPlacement="end"
							title="List episodes immediately instead of grouping them by season."
							sx={{ marginLeft: "0" }}
						/>
					</div>
				</div>
			</div>
		);
	}
}

function getFilterStates(date) {
	const upcomingText = moment(date).format("MMMM YYYY");
	const freshText = moment(date).subtract(1, "month").format("MMMM YYYY")
	return [
		{ key: "upcoming", text: upcomingText, description: "Programs that premiere the current month." },
		{ key: "fresh", text: freshText, description: "Programs that premiered the previous month." },
	];
}
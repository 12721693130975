import React from 'react'

import Item from './item'
import itemActions from './listItemActions'

import * as VodCoreAPI from '../../../apis/vodcore'
import useList from '../../../core/hooks/useList'
import GenericListApp from '../../../components/genericApp/listApp'
import { decoratorUIActions } from '../../../core/decorators/uiActions'

import './deliveries.css'
import useVodCorePackage from '../../../core/queries/vodcore/useVodCorePackage'

const MODULE = "vod";
const DATASTORE = "deliveries";
const TEXT_HEADING = "Events";
const TEXT_BACK_BUTTON = "Packages";//"Administration";
const TEXT_EMPTY = null;//"No services? It's about time to create some then!";
const TEXT_CREATE = null;//"Create service";
const ICON_CREATE = null;//"add";
const PATHNAME_CREATE = null;//"vod/admin/services/create";

function ListApp(props) {

	const packageId = props.params.id;
	const { data: parentPackage } = useVodCorePackage(packageId);

	const FILTER_DEFAULTS = {
		packageId,
		orderBy: "createdDescending",
	};

	const filterConfig = {
	};

	const listState = useList({
		listKey: DATASTORE,
		fetchData: VodCoreAPI.fetchDeliveries,
		filterDefaults: FILTER_DEFAULTS,
	});

	return (
		<GenericListApp
			{...props}
			className="c6-vod-packages"
			module={MODULE}
			datastore={DATASTORE}
			state={listState}
			textHeading={parentPackage ? `${parentPackage.name || ""} / ${TEXT_HEADING}` : `${TEXT_HEADING}`}
			textBackButton={TEXT_BACK_BUTTON}
			textEmpty={TEXT_EMPTY}
			textCreate={TEXT_CREATE}
			iconCreate={ICON_CREATE}
			filterConfig={filterConfig}
			pathnameCreate={PATHNAME_CREATE}
			collapseFiltersDefault={false}
			virtualize={false}
		>
			<Item />
		</GenericListApp>
	);
}

@decoratorUIActions(itemActions)
export default class DeliveriesApp extends React.Component {
	render() {
		return (
			<ListApp
				{...this.props}
			/>
		);
	}
}
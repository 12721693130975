import { browserHistory } from 'browserHistory'

import alt from '../../core/services/alt'

import * as VodData from '../../apis/vod'

import { handleRequestFailed } from '../../core/services/errorhandling'

import Const from '../../core/constants'

const ONLY_SEARCH_WHEN_SEARCHTEXT_IS_AT_LEAST = 2;

class Actions {

	/* **************** Actions **************** */
	search(searchText) {
		return searchText;
	}

	filter(filterValue, filterName) {
		return (dispatch) => {
			dispatch({[filterName]: filterValue});
		};
	}

	block(id) {
		return (dispatch) => {
			dispatch(id);

			VodData.blockPackage(id)
				.then(this.refreshListItem, (error, request) => { this.requestFailed({ error, request }); });
		};
	}

	unblock(id) {
		return (dispatch) => {
			dispatch(id);

			VodData.unblockPackage(id)
				.then(this.refreshListItem, (error, request) => { this.requestFailed({ error, request }); });
		};
	}

	unpublish(id) {
		return (dispatch) => {
			dispatch(id);

			VodData.unpublishPackage(id)
				.then(this.refreshListItem, (error, request) => { this.requestFailed({ error, request }); });
		};
	}

	republish(id) {
		return (dispatch) => {
			dispatch(id);

			VodData.republishPackage(id)
				.then(this.refreshListItem, (error, request) => { this.requestFailed({ error, request }); });
		};
	}

	resend(id) {
		return (dispatch) => {
			dispatch(id);

			VodData.resendPackage(id)
				.then(this.refreshListItem, (error, request) => { this.requestFailed({ error, request }); });
		};
	}

	update(id) {
		return (dispatch) => {
			dispatch(id);

			VodData.updatePackage(id)
				.then(this.refreshListItem, (error, request) => { this.requestFailed({ error, request }); });
		};
	}

	showDeliveries({id}, newTab = false) {
		const packagesPath = window.location.pathname.includes("-new") ? "packages-new" : "packages";
		const url = `/vod/${packagesPath}/${id}/deliveries`;
		if (newTab) {
			window.open(url, "_blank");
		} else {
			browserHistory.push(url);
		}
		return id;
	}

	toggleDeliveryItems(delivery) {
		return dispatch => {
			dispatch({ deliveryId: delivery.id });

			if (!Array.isArray(delivery.deliveryItems)) {
				const payload = {
					deliveryId: delivery.id,
				};
				VodData.fetchDeliveryItems(payload)
					.then(response => {
						console.log("response.items", response.items);
						this.deliveryItemsLoaded({ ...response, deliveryId: delivery.id });
					}, this.requestFailed);
			}
		};
	}

	deliveryItemsLoaded(payload) {
		return payload;
	}

	/* **************** Items **************** */
	fetchItems(entity, payload, targetStore = null) {
		return (dispatch) => {
			const multiple = true;
			const command = getCommand("fetch", entity, multiple);
			const store = targetStore || getStore(entity, multiple);
			dispatch({ entity: store, payload });
			const requestTime = Date.now();

			// HACK?: Return nothing when searchtext is less than 2 chars
			const { searchText } = payload;
			if(searchText && searchText.length < ONLY_SEARCH_WHEN_SEARCHTEXT_IS_AT_LEAST) {
				this.itemsUpdated(store, [], false, 0, null, requestTime);
			}
			else {
				VodData[command](payload)
					.then(response => {
						const { pageIndex, numberOfItems, items, links } = response;
						const appendToExistingItems = pageIndex > 0;

						const nextPageUrl = getNextPageUrl(links);
						this.itemsUpdated(store, items, appendToExistingItems, numberOfItems, nextPageUrl, requestTime);

					}, this.requestFailed);
			}
		};
	}
	pageItems(entity, url) {
		return (dispatch) => {
			dispatch({ entity, url });
			const multiple = true;
			const store = getStore(entity, multiple);

			VodData.fetchUrl(url)
				.then(response => {
					const { pageIndex, numberOfItems, items, links } = response;
					const appendToExistingItems = pageIndex > 0;

					const nextPageUrl = getNextPageUrl(links);
					this.itemsUpdated(store, items, appendToExistingItems, numberOfItems, nextPageUrl);

				}, this.requestFailed);
		};
	}
	itemsUpdated(datastore, items, appendToExistingItems, numberOfItems, nextPageUrl, requestTime) {
		return {
			datastore,
			items,
			appendToExistingItems,
			numberOfItems,
			nextPageUrl,
			requestTime,
		};
	}

	/* **************** Item **************** */
	fetchItem(entity, payload) {
		return (dispatch) => {
			dispatch({ entity, payload });

			const command = getCommand("fetch", entity);
			VodData[command](payload)
				.then(model => {
					this.itemLoaded({ entity, model });
				}, this.requestFailed);
		};
	}
	itemLoaded(payload) { return payload; }

	/* **************** Misc **************** */
	refreshListItem(item) {
		return item;
	}

	unmount() { return true; }

	requestFailed(error) {
		handleRequestFailed(error);
		return true;
	}

	/* **************** Custom **************** */
	fetchSeriesPackages(entity, payload) {
		return dispatch => {
			dispatch({ entity, payload });
			const requestTime = Date.now();

			VodData.fetchPackages(payload)
				.then(response => {
					const { pageIndex, numberOfItems, items, links } = response;
					const appendToExistingItems = pageIndex > 0;

					const nextPageUrl = getNextPageUrl(links);
					this.itemsUpdated(entity, items, appendToExistingItems, numberOfItems, nextPageUrl, requestTime);
				}, this.requestFailed);
		};
	}
}

export default alt.createActions(Actions);

// Helpers
function getCommand(command, entity, multiple = false) {
	if(typeof(entity) === "object") {
		const extra = multiple ? "s" : "";

		if(entity.parentEntity) {
			return command + entity.parentEntity.substr(0, 1).toUpperCase() + entity.parentEntity.substr(1) + entity.entity.substr(0, 1).toUpperCase() + entity.entity.substr(1) + extra;
		}
		entity = `${entity.entity}${extra}`;
	}
	return command + entity.substr(0, 1).toUpperCase() + entity.substr(1);
}

function getStore(entity, multiple = false) {
	if(typeof(entity) === "object") {
		const extra = multiple ? "s" : "";
		return `${entity.entity}${extra}`;
	}
	return entity;
}

function getNextPageUrl(links = []) {
	const nextLink = links.find(l => l.rel === "next" || l.Rel === "next");

	return nextLink
		? nextLink.href || nextLink.Href
		: null;
}
import { CONTENT, INLINE, CLICK_OPT, CLICK_SHIFT } from '../../../components/actions'
import Actions from '../actions'

export default {
	targetComponent: "Item",
	actions: [
		{
			type: CONTENT,
			title: "Display item",
			// method: Actions.edit,
			method: (data, sourceProps, location, params, itemProps, e, listState) => {
				const list = listState.list.searchItems.items;
				Actions.edit(data, sourceProps, location, params, itemProps, e, list);
			},
		},
		{
			// title: "Select item",
			type: INLINE,
			icon: "star_border",
			method: Actions.select,
			hidden: (data, sourceProps, location, params, itemProps) => itemProps.selected,
		},
		{
			// title: "Unselect item",
			type: INLINE,
			icon: "star",
			method: Actions.unselect,
			hidden: (data, sourceProps, location, params, itemProps) => !itemProps.selected,
		},
		{
			type: CLICK_OPT,
			method: Actions.toggleSelect,
		},
		{
			type: CLICK_SHIFT,
			method: data => {
				const storeName = "searchItems";
				Actions.toggleMultipleSelect({ item: data, storeName });
			},
		},
	]
};

// HELPERS
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import Label from '../../../components/ui/label'
import ContentItem, { Content } from '../../../components/list/contentItem'

export default class ModuleContent extends PureComponent {

	static propTypes = {
		enabled: PropTypes.bool,
		displayName: PropTypes.string,
		name: PropTypes.string,
		description: PropTypes.string,
		path: PropTypes.string,
		type: PropTypes.string,
		services: PropTypes.array,
		className: PropTypes.string,
	}

	render() {
		const {
			enabled,
			displayName,
			name,
			description,
			path,
			type,
			services,
			className = "",
		} = this.props;

		const hasServices = type.indexOf("C5") === -1 ? services.length : true;

	    return (
			<ContentItem data={this.props} extraClasses={className}>
				<Content>
					<h1>{displayName} {getLabel(enabled)} {getLabel(hasServices, "No active services")} {getLabel(type.indexOf("C5") === -1, type.replace("C5", "C5 "), "default")}</h1>
					<p>{name}</p>
				</Content>
				<Content>
					{description}
				</Content>
				<Content>
					<code>{path}</code>
				</Content>
			</ContentItem>
		);
  	}
}

// HELPERS
function getLabel(enabled, text="Disabled", status="expired") {
	return !enabled
		? <Label status={status}>{text}</Label>
		: null ;
}
import React, { Component } from 'react'
import groupBy from "lodash/groupBy"

import { decoratorUIActions } from '../../../../core/decorators/uiActions'

import { ItemGroup } from '../../../../components/list/listItems'

import Item from './listItem'
import itemActions from './listItemActions'

@decoratorUIActions(itemActions)
export default class List extends Component {

	render() {
		const {
			items,
			children,
		} = this.props;

		return items && items.length
			? this.renderGroups(items)
			: <div style={{ height: "100%" }}>{ children }</div>;
  	}

	renderGroups = (items) => {
		const groupedItems = groupBy(items, "serviceId");
		return Object.keys(groupedItems).map(groupKey => {
			const serviceDisplayName = groupedItems[groupKey][0].serviceDisplayName;
			return (
				<ItemGroup
					key={groupKey}
					title={`Areas in service: ${serviceDisplayName}`}
				>
					{this.renderItems(groupedItems[groupKey])}
				</ItemGroup>
			);
		});
	}

	renderItems = (items) => {
		return items.map(item => {
			return (
				<Item
					key={item.id}
					id={item.id}
					item={item}
				/>
			);
		});
	}
}
import * as PlannerAPI from '../../../apis/planner'
import { useQuery } from '@tanstack/react-query'

interface args {
    filter?: object;
    enabled?: boolean;
}

const usePlannerLicenseTypes = ({ filter = {}, enabled = true }: args = {}) => {
    const filters = {
        active: true,
        pageSize: 1000,
        orderBy: "name",
        filter: "userselectable",
        ...filter,
    };
    return useQuery(
        ["planner", "licenseTypes", filters],
        () => PlannerAPI.fetchLicenseTypes(filters),
        { enabled }
    );
};

export default usePlannerLicenseTypes;

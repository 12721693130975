import { CONTENT, INLINE, CLICK_OPT } from '../../../../components/actions'
import Actions from '../../actions'

export default {
	targetComponent: "Item",
	actions: [
		{
			type: CONTENT,
			title: "Add to album",
			method: (album, sourceProps, location, params, itemProps) => {
				const { selectedItems } = itemProps;
				Actions.addSelectedToAlbum(album, selectedItems, location);
			},
		},
	]
};
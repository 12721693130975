import React from 'react'

import Item from './item'
import itemActions from './listItemActions'

import * as SportAPI from '../../../../apis/sport'
import useList from '../../../../core/hooks/useList'
import GenericListApp from '../../../../components/genericApp/listApp'
import { decoratorUIActions } from '../../../../core/decorators/uiActions'
import useEditorNavigation from '../../../../core/hooks/useEditorNavigation'
import useSportLeague from '../../../../core/queries/sport/useSportLeague'

const MODULE = "eventplanner"
const DATASTORE = "arenas"
const TEXT_HEADING = "Arenas"
const TEXT_BACK_BUTTON = "Administration"
const TEXT_EMPTY = "No arenas? It's about time to create some then!"
const TEXT_CREATE = "Create arena"
const ICON_CREATE = "add"
const PATHNAME_CREATE = "eventplanner/admin/arenas/create"

const FILTER_CONFIG = {
	searchText: {
		type: "search",
	},
};

function ListApp(props) {

	const seriesId = props.location?.query?.seriesId >= 0 ? props.location.query.seriesId : null;
	const { data: league } = useSportLeague(seriesId);

	const filterDefaults = {
		searchText: "",
		seriesId: seriesId,
	};

	const listState = useList({
		listKey: DATASTORE,
		fetchData: SportAPI.fetchArenas,
		filterDefaults: filterDefaults,
	});

	useEditorNavigation(listState);

	return (
		<GenericListApp
			{...props}
			module={MODULE}
			datastore={DATASTORE}
			state={listState}
			textHeading={league ? `${TEXT_HEADING} in series "${league.data?.title}"` : TEXT_HEADING}
			textBackButton={TEXT_BACK_BUTTON}
			textEmpty={TEXT_EMPTY}
			textCreate={TEXT_CREATE}
			iconCreate={ICON_CREATE}
			filterConfig={league ? {} : FILTER_CONFIG}
			pathnameCreate={PATHNAME_CREATE}
			collapseFiltersDefault={false}
			showInfoBar={true}
		>
			<Item />
		</GenericListApp>
	);
}

@decoratorUIActions(itemActions)
export default class ArenasApp extends React.Component {
	render() {
		return (
			<ListApp
				{...this.props}
			/>
		);
	}
}
import React from 'react'
// import keydown from 'react-keydown'
import debounce from 'lodash/debounce'
// import groupBy from 'lodash/groupBy'
// import forEach from 'lodash/forEach'

import App from '../../../components/app'
import { StandardInfo } from '../../../components/comet'
import Main from '../../../components/ui/main'
import { Filter, Left, Right } from '../../../components/filter'
import Button from '../../../components/ui/controls/button'
import Search from '../../../components/ui/controls/search'
import Switch from '../../../components/ui/controls/switch'
import DropDown from '../../../components/ui/controls/dropdown'

import List from './list'

import Actions from '../actions'
import Store from '../store'
import EditorNavigationController from '../../../core/ui/editorNavigationController'

const MODULE = "shield"
const DATASTORE = "logins"
// const TEXT_HEADING = "Users"
const TEXT_EMPTY = "No users? It's about time to create some then!"
const TEXT_CREATE = "Create user"
const TEXT_CREATE_APIKEY = "Create API key"
const ICON_CREATE = "add"

const TYPE_STATES = [
	{ key: "", text: "Any", description: "Show all users, including API keys." },
	{ key: "user", text: "User", description: "Show regular users." },
	{ key: "apikey", text: "API key", description: "Show API keys." },
];

export default class ListApp extends React.Component {

	constructor(props) {
		super(props);

		this.debouncedSearch = debounce(this.handleSearch, 300);
		this.debouncedFilter = debounce(this.handleFilter, 300);

		this.typeFilterFromRoute = props.routes?.find(r => r.typeFilter)?.typeFilter;
		this.state = {
			...Store.getState(),
			searchText: "",
			type: this.typeFilterFromRoute ?? "user",
			moduleIds: []
		};
	}

	componentDidMount() {
		Store.listen(this.onChange);
		Actions.fetchItems(DATASTORE, { type: this.state.type });
		Actions.fetchItems("modules", { enabled: true, excludeChildModules: true });
	}

	// componentWillReceiveProps({ keydown }) {
	// 	if (keydown.event) {
	// 		console.log( keydown.event.which );
	// 		this.onSearch(keydown.event);
	// 	}
	// }

	componentDidUpdate() {
		const typeFilterFromRoute = this.props.routes?.find(r => r.typeFilter)?.typeFilter;
		if (typeFilterFromRoute !== this.typeFilterFromRoute) {
			this.typeFilterFromRoute = typeFilterFromRoute;
			this.setState({ type: typeFilterFromRoute ?? "user" });
			Actions.fetchItems(DATASTORE, { type: typeFilterFromRoute ?? "user" });
		}
	}

	componentWillUnmount() {
		Actions.unmount();
		Store.unlisten(this.onChange);
	}

	onChange = (state) => {
		this.setState(state);
		EditorNavigationController.setItems(this.state[DATASTORE]);
	}

	onSearch = (e) => {
		e.persist();
		this.debouncedSearch(e);
	}

	handleSearch = (event) => {
		const { value: searchText } = event.target;
		Actions.fetchItems(DATASTORE, {
			searchText,
			type: this.state.type,
			moduleIds: this.state.moduleIds.join(","),
		});
		this.setState({ searchText });
	}

	onFilter = (e) => {
		e.persist();
		this.debouncedFilter(e);
	}

	handleFilter = (event) => {
		const { name, value } = event.target;
		Actions.fetchItems(DATASTORE, {
			searchText: this.state.searchText,
			type: this.state.type,
			moduleIds: this.state.moduleIds.join(","),
			[name]: value,
		});
		this.setState({ [name]: value });
	}

	onModuleFilter = (e) => {
		const newValue = e.target.value;
		let moduleIds = [...this.state.moduleIds];
		if (newValue === "") {
			moduleIds = [];
		} else if (this.state.moduleIds.includes(newValue)) {
			moduleIds = this.state.moduleIds.filter(v => v !== newValue);
		} else {
			moduleIds.push(newValue);
		}

		Actions.fetchItems(DATASTORE, {
			searchText: this.state.searchText,
			type: this.state.type,
			moduleIds: moduleIds.join(","),
		});
		this.setState({ moduleIds });
	}

	handleCreateNew = () => {
		Actions.create({
			pathname: `/${MODULE}/${DATASTORE}/create`,
			returnTo: this.props.location.pathname,
		});
	}

	handleCreateNewKey = () => {
		Actions.create({
			pathname: `/${MODULE}/${DATASTORE}/create`,
			query: {
				type: "apikey",
			},
			returnTo: this.props.location.pathname,
		});
	}

	render() {
		const { isLoading, searchText } = this.state;

		// const logins = this.state[DATASTORE];

		// const groupedOrgs = groupBy(logins, login => (
		// 	login.email ? login.email.split("@")[1].split(".")[0] : "_rest"
		// ));

		// let orgs = [], restOrgs = [];
		// forEach(groupedOrgs, (logins, org) => {
		// 	const { length } = logins;
		// 	if(length > 3) {
		// 		orgs.push({ org, length})
		// 	}
		// 	else {
		// 		restOrgs.push({ org, length})
		// 	}

		// });

		// orgs.sort((a, b) => (b.length - a.length));

		const modules = this.state.modules.filter(m => !m.parentModuleId); // Not needed when excludeChildModules starts working

		return (
			<App
				name={`c6-${MODULE}-${DATASTORE}`}
				layout="grid"
				isLoading={isLoading}>

				<Filter>
					<Left>
						<h1>{this.typeFilterFromRoute === "apikey" ? "API keys" : "Users"}</h1>
						<Search
							onChange={this.onSearch}
							placeholder="Search"
							searchText={searchText} />
					</Left>
					<Right>
						<DropDown
							name="moduleIds"
							title="Module access"
							onChange={this.onModuleFilter}
							states={modules.map(m => ({ key: m.id, text: m.displayName }))}
							currentState={this.state.moduleIds}
							emptyStateText="Any"
						/>
						{!this.typeFilterFromRoute && (
							<Switch
								name="type"
								title="Type"
								onChange={this.onFilter}
								states={TYPE_STATES}
								currentState={this.state.type}
							/>
						)}
						{(!this.typeFilterFromRoute || this.typeFilterFromRoute === "user") && (
							<Button
								type={ICON_CREATE}
								title={TEXT_CREATE}
								onClick={this.handleCreateNew}
							/>	
						)}
						{(!this.typeFilterFromRoute || this.typeFilterFromRoute === "apikey") && (
							<Button
								type={ICON_CREATE}
								title={TEXT_CREATE_APIKEY}
								onClick={this.handleCreateNewKey}
							/>
						)}
					</Right>
				</Filter>
				<Main>
					<List
						items={this.state[DATASTORE]}
						isLoading={this.state.isLoading}
						textEmpty={TEXT_EMPTY} />
				</Main>
				<StandardInfo location={this.props.location} />
			</App>
		);
	}
}
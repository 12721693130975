import React from 'react'
import { Link } from 'react-router'
import { browserHistory } from 'browserHistory'
import { Filter, Left, Right } from '../../../components/filter'
import Button from '../../../components/ui/controls/button';

export default ({
	filters,
	serviceId,
	// serviceName,
	items,
	// goBackInsteadOfLink,
	location,
	customer,
	routes,
}) => {

	// We need to go back instead of doing a normal navigation to allow for
	// react-router-scroll to restore the scroll position of the library list.
	function handleClick(e) {
		if (/*goBackInsteadOfLink || */location && location.state && location.state.returnTo) {
			e.preventDefault();
			browserHistory.goBack();
		}
	}

	const season = items.find(item => item.type?.toLowerCase() === "season");
	const title = season?.displayName || season?.originalTitle;
	const serviceName = items[0]?.service?.displayName;

	return (
		<Filter>
			<Left>
				{/* <Button	
					title={"Go Back"}
					onClick={handleClick()}
				/> */}
				<h1>					
					{getLink(location, customer, handleClick, routes)} / {title ? `"${title}"` : "..."} {serviceName ? `(${serviceName})`: ""}
				</h1>
			</Left>
		</Filter>
	);
}

// HELPERS
function getLink(location, customer, handleClick, routes) {
	const returnTo = location && location.state && location.state.returnTo;
	const returnToTitle = location && location.state && location.state.returnToTitle;
	// const customerPath = customer ? `/${customer}` : "";

	const servicePath = (routes.find(r => r.serviceId !== undefined) || { path: "programs" }).path;
	const url = returnTo ? returnTo : `/traffic/programs`;
	const parentTitle = returnToTitle || "Programs";

	return <Link to={url} onClick={handleClick}>{parentTitle}</Link>
}
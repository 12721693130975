import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Switch from '@mui/material/Switch'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'

import Label from '../../../components/ui/label'
import InlineHeading from '../../../components/ui/inlineHeading'


import styles from '../../../components/listItems/standard.css'

import { ShieldAccessLevels } from '../../../core/constants'

export default class ListContent extends Component {

	static propTypes = {
		item: PropTypes.object.isRequired,
		roles: PropTypes.array,
		login: PropTypes.object,

		handleSetProperty: PropTypes.func.isRequired,
		handleToggle: PropTypes.func.isRequired,
	}

	render() {
		const {
			item: module,
			roles = [],
			login = {},

			handleSetProperty,
			handleToggle,
		} = this.props;

		const {
			id: moduleId,
			displayName,
			description = "",
			enabled,
		} = module;

		const toggleLabelStyles = {
			color: enabled ? undefined : "var(--text-lighter-color)",
		};

		const active = moduleActive(login, moduleId);

	    return (
			<div className="flex flex-1-1-1">
				<div className="c6-content">
					<FormControlLabel
						control={
							<Switch
								checked={active}
								onChange={handleToggle.bind(this, module, "module")}
							/>
						}
						label={enabled ? displayName : `${displayName} (DISABLED)`}
						labelPlacement="end"
						style={toggleLabelStyles}
					/>
					
					<p style={{ marginLeft: "47px", marginTop: "-5px" }}>{description}</p>
				</div>
				<div className="c6-content">
					<InlineHeading title="Access level">{active ? getAccessLevelSelect(moduleId, handleSetProperty, login, module) : null}</InlineHeading>
				</div>
				<div className="c6-content">
					{/*
						Add special text only for Content API 
						since it was not easy to just add radiobuttons instead of checkboxes
						due to the way we communicate with the API in this module
					*/}
					{active && module.name === "api" && module.displayName === "Content" && renderContentAPISpecialMessage()}
					{active && getRoles(module, roles, handleToggle, handleSetProperty, login)}
				</div>
			</div>
		);
  	}
}

// HELPERS
function moduleActive(login, moduleId) {
	return login.modules ? login.modules.findIndex(m => m.id === moduleId) >= 0 : false;
}

function getAccessLevelSelect(moduleId, handleSetProperty, login, module) {
	let currentAccessLevel = null;
	if(login.modules) {
		const currentLoginModule = login.modules.find(m => m.id === moduleId);
		currentAccessLevel = currentLoginModule && currentLoginModule.accessLevel || null;
	}

	const MenuItems = ShieldAccessLevels.map(type => (
		<MenuItem
			key={type}
			value={type}
		>
			{type === "Undefined" ? "Roles only" : type}
		</MenuItem>
	));

	return (
		<Select
			value={currentAccessLevel ?? ""}
			onChange={handleSetProperty.bind(this, module, "module", "accessLevel")}
			variant="standard"
			placeholder="Access level"
			sx={{ width: "125px" }}
		>
			{MenuItems}
		</Select>
	);
}

function getRoles(module, roles, handleToggle, handleSetProperty, login) {
	const {
		id: moduleId,
		name,
		parentModuleId,
		displayName,
	} = module;

	let appRoles = roles.filter(r => r.moduleId === moduleId);
	if(!appRoles.length) {
		appRoles = roles.filter(r => r.moduleId === parentModuleId);
	}

	if (appRoles.length > 5) {
		const parsedValue = appRoles.filter(role => roleActive(login, moduleId, role.id)).map(role => role.id.toString());

		return (
			<Select
				value={parsedValue ?? ""}
				multiple={true}
				variant="standard"
				placeholder="Roles"
				sx={{ width: "125px" }}
			>
				{appRoles.map(role => (
					<MenuItem
						key={`rb${role.id}`}
						value={role.id.toString()}
						onClick={handleToggle.bind(this, role, "role", null, !roleActive(login, moduleId, role.id))}
					>
						{role.displayName}
					</MenuItem>
				))}
			</Select>
		);
	}

	return appRoles.map(role => (
		<FormControlLabel
			key={`rb${role.id}`}
			value={role.id.toString()}
			control={
				<Checkbox
					checked={roleActive(login, moduleId, role.id)}
					onChange={handleToggle.bind(this, role, "role")}
				/>
			}
			label={role.enabled ? role.displayName : `${role.displayName} (Disabled)`}
		/>
	));
}

function roleActive(login, moduleId, roleId) {
	return login.roles ? login.roles.findIndex(r => r.moduleId === moduleId && r.id === roleId) >= 0 : false;
}

function renderContentAPISpecialMessage() {
	return (
		<p style={{ color: "var(--attention-color)" }}>
			Linear rights (choose <strong style={{ textDecoration: "underline" }}>one</strong> set of rights to include in the response from the API)
		</p>
	);
}
import React from 'react'

import Editor, { parseUi, withQueryClient } from '../../../../components/editor/index'
import { hasAccessToPath } from '../../../../core/services/auth'

import schema from './schema'
import uiSchema from './uiSchema'
import useSportGenres from '../../../../core/queries/sport/useSportGenres'
import useSportArenas from '../../../../core/queries/sport/useSportArenas'

import './app.css'

const ParticipantEditor = props => {
	const { data: genres } = useSportGenres();
	const { data: arenas } = useSportArenas();

	return (
		<Editor
			className="c6-eventplanner-participant-editor"
			layout="grid"
			api="sport"
			entity="participant"
			getSchema={getSchema.bind(this, genres, arenas)}
			getUiSchema={getUiSchema}
			savePayloadTransform={savePayloadTransform}
			hasEditAccess={hasAccessToPath(props.routes, "editor")}
			enableEditorNavigation={true}
			{...props}
		/>
	);
};

export default withQueryClient(ParticipantEditor);

function getSchema(genres, arenas, model, isNew, location, route, params) {
	if (!genres?.items?.length || !arenas?.items?.length) {
		return { type: "object", properties: {} };
	}

	schema.properties.genre.properties.id = {
		...schema.properties.genre.properties.id,
		oneOf: genres.items.map(g => ({
			const: g.id,
			title: g.title,
		})),
		default: genres.items[0]?.id ?? 0,
	};

	schema.properties.arena.properties.id = {
		...schema.properties.arena.properties.id,
		oneOf: [
			...arenas.items.map(g => ({
				const: g.id,
				title: g.name,
			})),
		],
		default: arenas.items[0]?.id ?? 0,
	};

	return schema;
}

function getUiSchema(model, isNew, location, route, params) {
	return uiSchema;
}

function savePayloadTransform({ formData, ...rest }) {
	const isNew = !formData?.id;
	
	return {
		...rest,
		formData: {
			...formData,
			name: isNew ? formData.title : formData.name,
		},
	};
}
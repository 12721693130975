import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'

import { decoratorUIActions } from '../../../core/decorators/uiActions'
import {UICenter} from '../../../components/ui/alignment.jsx'
import { ItemGroup } from '../../../components/list/listItems'

import Actions from '../actions'
import Item from './listItem'

import RoleItem from './roleItem'
import roleItemActions from './roleItemActions'

import Constants from '../../../core/constants'


export default class List extends Component {

	static propTypes = {
		items: PropTypes.array.isRequired,
		handleToggle: PropTypes.func.isRequired,
		handleSetProperty: PropTypes.func.isRequired,

		roles: PropTypes.array.isRequired,
	}

	render() {
		const {
			isLoading,

			items,
			module,
			handleToggle,
			handleSetProperty,

			roles,
		} = this.props;

		const linkAction = {
			pathname: `/shield/admin/roles/create/`,
			search: `?moduleId=${module.id}`,
			state: {
				modal: true,
				// returnTo: `${this.props.location.pathname}${this.props.location.search}`,
			}
		};

		const filteredItems = items ? items.filter(item => !item._isHidden) : [];
		const filteredRoles = roles ? roles.filter(item => !item._isHidden) : [];
		if(module && (filteredItems.length || filteredRoles.length)) {
			const isLegacyModule = module.type && module.type.indexOf("C5") >= 0;
			const roleHeading = isLegacyModule ? "Comet 5 Roles (The role key needs to match the C5 role name exactly)" : "Roles (If you need to be more specific than the standard; Admin, Editor, Contributor and Reader)";
			const isSubModule = !!module.parentModuleId;

			return (
				<div>
					<ItemGroup title="Services (Basically the API:s the module uses)" visible={!isLegacyModule}>
						{this.renderServices(filteredItems, module, handleToggle, handleSetProperty)}
					</ItemGroup>
					<ItemGroup title={roleHeading} visible={!isSubModule}>
						<RolesWrapper>
							{this.renderRoles(filteredRoles, module.id, isLegacyModule)}
						</RolesWrapper>
						<UICenter style={{marginTop: "20px"}} className="c6-color-light"><Link to={linkAction} className="c6-action">Add a new role</Link> for this module.</UICenter>
					</ItemGroup>
				</div>
			);

		}

		return this.renderEmpty(isLoading);
  	}

	renderEmpty(isLoading) {
		const title = isLoading ? <h3>Loading...</h3> : <h3>No items found</h3>;
		const description = <p>No services? You need to <Link to="/shield/admin/services/" className="c6-action">create some</Link> first.</p>;

		return <UICenter className="c6-color-light">{title}{description}</UICenter>;
	}

	renderServices(services, module, handleToggle, handleSetProperty) {
		return services.map(service => (
			<Item
				{...service}
				key={service.id}
				id={service.id}
				item={service}
				module={module}
				handleToggle={handleToggle}
				handleSetProperty={handleSetProperty}
			/>
		));
	}

	renderRoles(roles, moduleId) {
		const filteredRoles = roles.filter(r => r.moduleId === moduleId);
		return filteredRoles?.map(role => (
			<RoleItem
				{...role}
				key={role.id}
				id={role.id}
			/>
		));
	}
}

@decoratorUIActions(roleItemActions)
class RolesWrapper extends Component {
	render() {
		return this.props.children;
	}
}
import React, { Component } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import MuiButton from '@mui/material/Button'
import moment from 'moment'

import Button from '../../../components/ui/controls/button'
import ContentItem, { Content } from '../../../components/list/contentItem'
import { decoratorUIActions } from '../../../core/decorators/uiActions'

import contentActions from './approveDialogListItemActions'
import { getExposureMonetizationModels, renderLicenseType } from '../utils'
import Actions from '../actions'
import AuthStore from '../../../core/authentication/store'

import './approveDialog.css'

const DATE_FORMAT = "D MMM YYYY";
const DATE_TIME_FORMAT = "D MMM YYYY (HH:mm)";

@decoratorUIActions(contentActions)
class ApproveDialog extends Component {
	state = {
		dialogOpen: false,
		confirm: null,
	}

	componentDidUpdate() {
		// Close dialog when there are no changes left
		if (this.props.dirtyPublishWindows.items.length === 0 && this.state.dialogOpen) {
			this.setState({ dialogOpen: false });
		}
	}

	renderChanges = (title, changes, hideUsername) => {
		if (!changes?.length) {
			return null;
		}

		return (
			<>
				<div className="actions">
					<div>{title}</div>
					<MuiButton onClick={() => Actions.selectChanges(changes)}>Select all</MuiButton>
				</div>
				<div className="changes">
					{changes.map(pw => {
						const startMoment = pw.start ? moment(pw.start) : moment.invalid();
						const endMoment = pw.end ? moment(pw.end) : moment.invalid();
						const changedStartMoment = pw.changedStart ? moment(pw.changedStart) : moment.invalid();
						const changedEndMoment = pw.changedEnd ? moment(pw.changedEnd) : moment.invalid();

						// Should we display time (HH:mm:ss) for start time?
						const showStartTime = pw.start && startMoment.format("HH:mm") !== "00:00" || pw.changedStart && changedStartMoment.format("HH:mm") !== "00:00";
						// Set start from & to text depending on showStartTime
						const startFromText = showStartTime ? startMoment.format(DATE_TIME_FORMAT) : startMoment.format(DATE_FORMAT);
						const startToText = showStartTime ? changedStartMoment.format(DATE_TIME_FORMAT) : changedStartMoment.format(DATE_FORMAT);

						// Should we display time (HH:mm:ss) for end time?
						const showEndTime = pw.end && endMoment.format("HH:mm") !== "00:00" || pw.changedEnd && changedEndMoment.format("HH:mm") !== "00:00";
						// Set end from & to text depending on showEndTime
						const endFromText = showEndTime ? endMoment.format(DATE_TIME_FORMAT) : endMoment.format(DATE_FORMAT);
						const endToText = showEndTime ? changedEndMoment.format(DATE_TIME_FORMAT) : changedEndMoment.format(DATE_FORMAT);

						let windowAction = "", windowActionDescription = "", licenseTypeActionDescription = "";
						const removed = !pw.changedStart && !pw.changedEnd;
						const added = pw.changedStart && !pw.start;
						const movedStart = pw.start && pw.changedStart !== pw.start;
						const movedEnd = pw.end && pw.changedEnd !== pw.end;
						const movedBoth = movedStart && movedEnd;

						if (removed) { windowAction = "Cancelled"; windowActionDescription = <span>Cancelled</span> }
						else if (added) { windowAction = "New"; windowActionDescription = <span>New program added <span className="new">{startToText}</span></span> }
						else if (movedBoth) { windowAction = "Moved"; windowActionDescription = <span>Start changed from <span className="old">{startFromText}</span> to <span className="new">{startToText}</span> and end changed from <span className="old">{endFromText}</span> to <span className="new">{endToText}</span></span> }
						else if (movedStart) { windowAction = "Moved"; windowActionDescription = <span>Start changed from <span className="old">{startFromText}</span> to <span className="new">{startToText}</span></span> }
						else if (movedEnd) { windowAction = "Changed period"; windowActionDescription = <span>The period has been changed from ending <span className="old">{endFromText}</span> to <span className="new">{endToText}</span></span> }

						const changedLicenseType = pw.changedOverrideLicenseType && pw.changedOverrideLicenseType.id !== pw.licenseType?.id;
						const changedLicenseTypeBackToDefault = !changedLicenseType && !removed && !added && !movedStart && !movedEnd;
						if (changedLicenseType) {
							licenseTypeActionDescription = (<span>The license type has been changed from {renderLicenseType(pw.licenseType, pw.style, pw.priority, pw.rights, true)} to {renderLicenseType(pw.changedOverrideLicenseType ?? pw.licenseType, pw.style, pw.priority, pw.rights)}</span>);
						} else if (changedLicenseTypeBackToDefault) {
							licenseTypeActionDescription = (<span>The license type has been changed back to the default: {renderLicenseType(pw.licenseType, pw.style, pw.priority, pw.rights)}</span>);
						}

						const changedByDescription = pw.changedByUser === "System"
							? "Comet (based on information provided by another system)"
							: pw.changedByUser;

						const monetizationModel = getExposureMonetizationModels(pw);

						return (
							<ContentItem key={pw.id} data={pw} extraClasses={`pw-change ${pw.selected ? "sel" : ""} ${windowAction.toLowerCase().replace(/ /g, "-")}`}>
								<Content>
									<h1>
										<div className="name">{pw.program.name}</div>
										<div className="license-info">
											{monetizationModel}
											{renderLicenseType(pw.licenseType, pw.style, pw.priority, pw.rights, pw.changedOverrideLicenseType !== undefined)}
										</div>
									</h1>
									{licenseTypeActionDescription && <p>{licenseTypeActionDescription} {!windowActionDescription && changedByDescription}</p>}
									{windowActionDescription && <p>{windowActionDescription}</p>}
									{!hideUsername && <p className="changed-by">{changedByDescription}</p>}
								</Content>
							</ContentItem>
						);
					})}
				</div>
			</>
		);
	}

	renderBottomActions = () => {
		const confirm = this.state.confirm;
		const selectedChanges = this.props.dirtyPublishWindows.items.filter(pw => pw.selected);

		return (
			<React.Fragment>
				<MuiButton
					onClick={() => this.setState({ dialogOpen: false, confirm: null })}
				>
					Cancel
				</MuiButton>
				{this.props.isLoadingApproveOrUndo && (
					<MuiButton
						color="primary"
						variant="contained"
						onClick={() => {}}
						disabled
					>
						Loading...
					</MuiButton>
				)}
				{!this.props.isLoadingApproveOrUndo && (
					<>
						<MuiButton
							onClick={() => Actions.selectNoChanges()}
							disabled={selectedChanges.length === 0}
						>
							Clear selection
						</MuiButton>
						<MuiButton
							color="error"
							onClick={() => {
								if (confirm === "undo") {
									Actions.undoChanges(selectedChanges.map(pw => pw.id), this.props.dirtyPublishWindows.filters);
									this.setState({ confirm: null });
								} else {
									this.setState({ confirm: "undo" });
								}
							}}
							disabled={selectedChanges.length === 0}
						>
							{confirm === "undo" ? "Really undo all selected changes?" : `Undo ${selectedChanges.length} change(s)`}
						</MuiButton>
						<MuiButton
							variant="contained"
							color="primary"
							onClick={() => {
								if (confirm === "approve") {
									Actions.approveChanges(selectedChanges.map(pw => pw.id), this.props.dirtyPublishWindows.filters);
									this.setState({ confirm: null });
								} else {
									this.setState({ confirm: "approve" });
								}
							}}
							disabled={selectedChanges.length === 0}
						>
							{confirm === "approve" ? "Really approve all selected changes?" : `Approve ${selectedChanges.length} change(s)`}
						</MuiButton>
					</>
				)}
			</React.Fragment>
		);
	}

	render() {
		const { isLoading, numberOfItems, items } = this.props.dirtyPublishWindows;
		
		let buttonText = "";
		let buttonStyle = {};
		if (isLoading) {
			buttonText = "Loading changes...";
		} else if (numberOfItems === 0) {
			buttonText = "No unapproved changes";
		} else {
			buttonText = `${numberOfItems} changes ready for approval`;
			buttonStyle = { "backgroundColor": "var(--pending-color)", "color": "var(--pending-fg-color)" };
		}

		const user = AuthStore.getState().user;
		const username = user?.username;
		const yourChanges = items.filter(pw => pw.changedByUser === username);
		const otherChanges = items.filter(pw => pw.changedByUser !== username);

		return (
			<React.Fragment>
				<Button
					className="approve-dialog-button"
					style={buttonStyle}
					title={buttonText}
					disabled={isLoading || numberOfItems === 0 || this.props.readonly}
					onClick={() => this.setState({ dialogOpen: true })}
				/>
				<Dialog
					open={this.state.dialogOpen}
					onClose={() => this.setState({ dialogOpen: false })}
					className="c6-modal approve-dialog"
					maxWidth={false}
					classes={{
						container: "c6-modal-content",
						paper: "c6-modal-body",
					}}
				>
					<DialogContent className="scroll-track-bg">
						{this.renderChanges("YOUR CHANGES", yourChanges, true)}
						{this.renderChanges(yourChanges.length ? "OTHER CHANGES" : "CHANGES", otherChanges)}
					</DialogContent>
					<DialogActions>
						{this.renderBottomActions()}
					</DialogActions>
				</Dialog>
			</React.Fragment>
		);
	}
}

export default ApproveDialog;
import React from 'react'
import moment from 'moment'
import FullCalendar from '@fullcalendar/react'
import DayGridPlugin from '@fullcalendar/daygrid'
import ResourceTimelinePlugin from '@fullcalendar/resource-timeline'
import InteractionPlugin, { Draggable } from '@fullcalendar/interaction'
import ListPlugin from '@fullcalendar/list'
import GoogleCalendarPlugin from '@fullcalendar/google-calendar'

import './calendar.css'
import './eventColors.css'

export default class Calendar extends React.Component {

	calendarRef = null
	catalogueDurationAfterDayChangesInMinutes = 0

	shouldComponentUpdate(nextProps) {
		const shouldUpdate = nextProps.date?.unix() !== this.props.date?.unix()
			|| nextProps.disabled !== this.props.disabled
			|| nextProps.readonly !== this.props.readonly
			|| nextProps.eventsTimestamp !== this.props.eventsTimestamp
			|| nextProps.activeEventsTimestamp !== this.props.activeEventsTimestamp
			|| nextProps.isCatalogueView !== this.props.isCatalogueView
			|| nextProps.activeTodayEventsCount.numberOfAssets !== this.props.activeTodayEventsCount.numberOfAssets
			|| nextProps.activeTodayEventsCount.durationInHours !== this.props.activeTodayEventsCount.durationInHours
		return shouldUpdate;
	}

	onDateClick = ({ dateStr }) => {
		if (this.props.onDateClick) {
			this.props.onDateClick(dateStr);
		}
	}

	onEventClick = ({ event, jsEvent }) => {
		if (this.props.onEventClick) {
			this.props.onEventClick({
				title: event.title,
				start: event.start,
				end: event.end,
				id: event.id,
				...event.extendedProps,
			});
		}
	}

	onEventDrop = info => {
		if (this.props.onEventDrop) {
			this.props.onEventDrop(info.event, info.revert);
		}
	}

	onEventDragStart = (a, b, c) => {
		console.log("eventDragStart");
	}

	onEventReceive = info => {
		if (this.props.onProgramDrop) {
			this.props.onProgramDrop(info.event);
		}
	}

	onDayHeaderDidMountCatalogue = ({ el, view, date }) => {
		if (view.type === "listDay") {
			const catalogueStats = formatAssetCountAndDuration(this.props.activeTodayEventsCount);
			const listDayText = el.querySelector(".fc-list-day-text");
			listDayText.textContent = "Active";

			const dailyStats = document.createElement("a");
			dailyStats.className = "fc-list-day-middle-text"; // Perhaps we need to use existing names
			dailyStats.textContent = catalogueStats;

			// Append statistics to the day header
			const listDayCushion = el.querySelector(".fc-list-day-cushion");
			listDayCushion.appendChild(dailyStats);
		}
	}

	onEventDidMountCatalogue = ({el, view, event }) => {
		// Adds tooltip/title with window expiration date
		if (view.type === "listDay") {
			const start = moment(event.extendedProps.publishWindowStart);
			const today = moment();

			const years = today.diff(start, 'years');
			start.add(years, 'years');
			const months = today.diff(start, 'months');
			start.add(months, 'months');
			const weeks = today.diff(start, 'weeks');
			start.add(weeks, 'weeks');
			const days = today.diff(start, 'days');
			start.add(days, 'days');

			const timeActiveText = mapTimeActiveText(years, months, weeks, days);
			
			// Append timeActiveText to the event title/name
			const eventTime = el.querySelector(".fc-list-event-title");
			eventTime.textContent = `${eventTime.textContent}, been available ${timeActiveText}`;

			// Remove the event time column
			const eventTimeSection = el.querySelector(".fc-list-event-time");
			eventTimeSection.parentNode.removeChild(eventTimeSection);

			// Remove the graphic / dot column
			const eventGraphicSection = el.querySelector(".fc-list-event-graphic");
			eventGraphicSection.parentNode.removeChild(eventGraphicSection);

			// Add the window expiration date as a tooltip to the event title
			const listEventTitleElement = el.querySelector(".fc-list-event-title");
			const publishWindowEndString = moment(event.extendedProps.publishWindowEnd).format("YYYY-MM-DD");
			listEventTitleElement.title = `Expires ${publishWindowEndString}`;
		}
	}

	onEventDidMount = ({ el, view, event }) => {
		el.title = `${event.title}`;

		if (event.extendedProps.preventMoveAndRemove) {
			el.title = `${el.title}\n\nCan't be moved/removed since date is inherited from parent exposure.`;
		}

		if (view.type === "catalogueView") {
			const publishWindowEndString = moment(event.extendedProps.publishWindowEnd).format("YYYY-MM-DD");
			el.title = `${el.title}\n\nExpires ${publishWindowEndString}\n\nAssets: ${event.extendedProps.numberOfAssets}`

			if (event.extendedProps.numberOfAssets) {
				const numberOfAssets = event.extendedProps.numberOfAssets;
				const eventTitle = el.querySelector(".fc-list-event-title");

				const assetSpan = document.createElement("span");
				const assetDisplayText = numberOfAssets === 1 ? 'asset' : 'assets';
				assetSpan.textContent = ` (${numberOfAssets} ${assetDisplayText})`

				eventTitle.appendChild(assetSpan);
			}
		}
	}

	onDayHeaderDidMountUpcomingChanges = ({ el, view, date }) => {
		if (view.type === "catalogueView") {
			// Get todays starting/ending windows (upcoming changes)
			const startingWindows = this.props.events.filter(({ eventType, start }) => eventType === 'add' && moment(start).isSame(date, 'day'));
			const endingWindows = this.props.events.filter(({ eventType, start }) => eventType === 'remove' && moment(start).isSame(date, 'day'));

			const totalStartingAssets = startingWindows.reduce((total, window) => {
				return total + window.numberOfAssets;
			}, 0);
			const totalEndingAssets = endingWindows.reduce((total, window) => {
				return total + window.numberOfAssets;
			}, 0);

			const noWindowsToday = (totalStartingAssets === 0 && totalEndingAssets === 0);

			// Generate the statistical text
			const statsDisplayText =  MapWindowsToStatisticsText(noWindowsToday, totalStartingAssets, totalEndingAssets);

			// Create new statistical information element
			const dailyStats = document.createElement("a");
			dailyStats.className = "fc-list-day-middle-text"; // Perhaps we need to use existing names
			dailyStats.textContent = statsDisplayText;

			// Append statistics to the day header
			const listDayCushion = el.querySelector(".fc-list-day-cushion");
			listDayCushion.appendChild(dailyStats);

			// Operations on catalogueDuration to get new total duration after the upcoming changes for the day
			if (this.catalogueDurationAfterDayChangesInMinutes !== 0) {
				// Calculate catalogue durations after todays changes
				const startingWindowsMinutes = startingWindows.map((window) => ConvertDurationToMinutes(window.duration)).reduce((partialSum, a) => partialSum + a, 0);
				const endingWindowsMinutes = endingWindows.map((window) => ConvertDurationToMinutes(window.duration)).reduce((partialSum, a) => partialSum + a, 0);

				this.catalogueDurationAfterDayChangesInMinutes += startingWindowsMinutes;
				this.catalogueDurationAfterDayChangesInMinutes -= endingWindowsMinutes;
			}

			// Create new statistical information element for total duration
			const dailyDuration = document.createElement("a");
			let duration = noWindowsToday ? null : minutesToHours(this.catalogueDurationAfterDayChangesInMinutes);
			dailyDuration.className = "fc-list-day-middle-duration-text"; // Perhaps we need to use existing names
			dailyDuration.textContent = noWindowsToday ? "" : ` | ${duration} hours in the catalogue`;

			// Append the duration to the day header
			listDayCushion.appendChild(dailyDuration);

		}
	}

	// Adds a "X"-button to remove events
	onEventMouseEnter = ({ event, el, jsEvent, view }) => {
		const isExpiringEvent = event.extendedProps.eventType === "remove";

		// Not allowed to manipulate HighProfile events or expiring events
		if (
			eventIsHighProfile(event)
			|| isExpiringEvent
			|| event.extendedProps.preventMoveAndRemove
		) {
			return;
		}

		const remove = document.createElement("button");
		remove.className = "remove icon-close";
		remove.title = "Remove exposure (Don't worry, you will need to approve this)";
		remove.onclick = (e) => {
			e.stopPropagation();
			this.props.onEventRemoveClick(event, view.type);
		};

		el.appendChild(remove);
	}

	onEventMouseLeave = ({ event, el, jsEvent, view }) => {
		const button = el.querySelector("button");
		button?.remove();
	}

	// Reposition popover if it is positioned outside screen
	onMoreLinkDidMount = ({ el }) => {
		el.addEventListener("click", () => {
			setTimeout(() => {
				const popover = document.querySelector(".fc-more-popover");
				if (popover) {
					repositionPopoverIfBelowScreen(popover);
				}
			});
		});
	}

	render() {
		const fullCalendarProps = {
			initialView: this.props.initialView ?? "dayGridMonth",
			views: { // Configure views. Settings in here override the global settings
				dayGridMonth: {
					
				},
				resourceTimelineMonth: {
					weekNumbers: false,
					dayHeaderFormat: { day: "numeric", weekday: false },
					resourceAreaHeaderContent: "Licenses",
					eventResourceEditable: false, // disable DnD between resources/licenses
				},
				dayGridWeek: {
					displayEventTime: true,
					eventTimeFormat: {
						hour: "2-digit",
						minute: "2-digit",
						hour12: false,
					},
					weekNumbers: false,
				},
				listMonth: {
					displayEventTime: true,
					eventTimeFormat: {
						hour: "2-digit",
						minute: "2-digit",
						hour12: false,
					}
				},
				catalogueView: {
					type: "listMonth",
					dateAlignment: "day",
					displayEventTime: true, // To show 6 months ahead.
					duration: { months: 1 },
					eventTimeFormat: {
						hour: "2-digit",
						minute: "2-digit",
						hour12: false,
					}
				}
			},
			firstDay: 1, // 1 = Monday
			weekends: true,
			weekNumbers: true,
			displayEventTime: false,
			defaultTimedEventDuration: "00:00", // This will prevent an event that starts at 23:30 from spanning 2 days since the default duration is 01:00 (1 hour) for events with no end specified
			dayMaxEventRows: true, // true => show as many events as we can fit in a cell. If there are more we will have a "X more"-action
			height: "100%", // The height of the calendar will match the height of its parent container element
			...this.props.fullCalendarProps,
		};

		const view = this.calendarRef?.getApi().view.type ?? this.props.initialView;

		const isCatalogueView = view === "catalogueView";
		this.catalogueDurationAfterDayChangesInMinutes =  isCatalogueView ? (this.props.activeTodayEventsCount.durationInHours * 60) : 0; // Used to calculate and keep track of total time after daily changes

		const mainCalendarEventSources = [{ events: transformEvents(this.props.events, view) }];
		if (!isCatalogueView) {
			mainCalendarEventSources.push({ googleCalendarId: "en.swedish#holiday@group.v.calendar.google.com", className: "holiday" });
		}

		return (
			<div className={`c6-calendar ${this.props.disabled ? "disabled" : ""} ${isCatalogueView ? "catalogue-view" : ""}`}>
				{isCatalogueView && (
					<div className={"calendar-wrapper"}>
						<FullCalendar
							ref={null}
							locale="en-GB" // Workaround for bug in FullCalendar where 00:00 is displayed as 24:00 https://github.com/fullcalendar/fullcalendar/issues/5303
							headerToolbar={null}
							plugins={[ ListPlugin ]}
							eventSources={[
								{ events: transformactiveTodayEvents(this.props.activeTodayEvents, view) },
							]}
							initialView="listDay"
							dayHeaderDidMount={this.props.readonly ? null : this.onDayHeaderDidMountCatalogue }
							eventDidMount={this.props.readonly ? null : this.onEventDidMountCatalogue }
							eventOrder={getEventOrder(view)}
							eventClick={this.onEventClick}
							eventMouseEnter={this.props.readonly ? null : this.onEventMouseEnter}
							eventMouseLeave={this.props.readonly ? null : this.onEventMouseLeave}
							eventDisplay="block" // block = display as a solid rectangle in daygrid views
						/>
					</div>
				)}
				<div className={"calendar-wrapper"}>
					{isCatalogueView && <h2>Coming changes</h2>}
					<FullCalendar
						{...fullCalendarProps}
						ref={el => {
							this.calendarRef = el;
							if (this.props.calendarRef) {
								this.props.calendarRef(el);
							}
						}}
						locale="en-GB" // Workaround for bug in FullCalendar where 00:00 is displayed as 24:00 https://github.com/fullcalendar/fullcalendar/issues/5303
						plugins={[ DayGridPlugin, ResourceTimelinePlugin, InteractionPlugin, ListPlugin, GoogleCalendarPlugin ]}
						headerToolbar={null}
						eventSources={mainCalendarEventSources}
						resources={getResourcesFromEvents(this.props.events, view)}
						resourceOrder="title"
						eventOrder={getEventOrder(view)}
						eventDisplay="block" // block = display as a solid rectangle in daygrid views
						dateClick={this.onDateClick}
						eventClick={this.onEventClick}
						editable={!this.props.disabled && !this.props.readonly}
						droppable={!this.props.readonly}
						eventReceive={this.onEventReceive}
						eventDrop={this.onEventDrop}
						eventDragStart={this.onEventDragStart}
						eventDragStop={() => console.log("eventDragStop")}
						eventMouseEnter={this.props.readonly ? null : this.onEventMouseEnter}
						dayHeaderDidMount ={this.props.readonly ? null : this.onDayHeaderDidMountUpcomingChanges}
						eventDidMount={this.onEventDidMount}
						eventMouseLeave={this.props.readonly ? null : this.onEventMouseLeave}
						googleCalendarApiKey="AIzaSyDsrxv03PtA3IOLHJGCXFvBVq8v-oonNNA"
						// schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives" // Scheduler can be downloaded and evaluated for an unlimited amount of time, free of charge. This evaluation version is licensed under a Creative Commons license that does not allow distribution of source code modifications nor use in commercial production websites or products.
						schedulerLicenseKey="0451657318-fcs-1699530062"
						moreLinkDidMount={this.onMoreLinkDidMount}
					/>
				</div>
			</div>
		);
	}
}

export class CustomDraggable extends React.Component {
	elementRef = React.createRef();

	componentDidMount() {
		if (!this.props.disableDrag && this.elementRef && this.elementRef.current) {
			new Draggable(this.elementRef.current, {
				eventData: this.props.data,
			});
		}
	}

	render() {
		return (
			<div
				draggable={!this.props.disableDrag}
				ref={this.elementRef}
				{...this.props.elementProps}
			>
				{this.props.children}
			</div>
		);
	}
}

function MapWindowsToStatisticsText(noWindowsToday, noOfStartingWindows, noOfEndingWindows) {
	if (noWindowsToday) return "";

	if (noOfStartingWindows !== 0 && noOfEndingWindows !== 0) {
		return `${noOfStartingWindows} New, ${noOfEndingWindows} Expiring assets`;
	} else if (noOfStartingWindows !== 0) {
		return `${noOfStartingWindows} New assets`;
	} else {
		return `${noOfEndingWindows} Expiring assets`;
	}
}

function formatAssetCountAndDuration(eventsStats) {
	const count =  eventsStats.numberOfAssets?.toLocaleString();
	const duration = eventsStats.durationInHours?.toLocaleString();
	return `${count ?? 0} assets, ${duration ?? 0} hours`;
}

function handlePlural(quantity, word) {
	if (quantity > 1) return `${word}s`;
	return `${word}`;
}

function mapTimeActiveText(years, months, weeks, days) {
	let timeActiveText = ``;

	if (years >= 1) {
		timeActiveText = timeActiveText.concat(`${years} ${handlePlural(years, "year")}`);
		if (months !== 0) timeActiveText = timeActiveText.concat(` and ${months} ${handlePlural(months, "month")}`);
	} else if (months >= 1) {
		timeActiveText = timeActiveText.concat(`${months} ${handlePlural(months, "month")}`);
		if (weeks !== 0) timeActiveText = timeActiveText.concat(` and ${weeks} ${handlePlural(weeks, "week")}`);
	} else if (weeks >= 1) {
		timeActiveText = timeActiveText.concat(`${weeks} ${handlePlural(weeks, "week")}`);
		if (days !== 0) timeActiveText = timeActiveText.concat(` and ${days} ${handlePlural(days, "day")}`);
	} else if (days >= 1) {
		timeActiveText = timeActiveText.concat(`${days} ${handlePlural(days, "day")}`);
	} else {
		timeActiveText = timeActiveText.concat(`N/A`);
	}

	return timeActiveText;
}

// Convert "HH:MM:SS" format of duration to minutes (we skip seconds)
function ConvertDurationToMinutes(durationInTimeSpanFormatString) {
	const timeUnits = durationInTimeSpanFormatString.split(':');
	const minutes = (+timeUnits[0]) * 60 + (+timeUnits[1]);
	return minutes;
}

function transformactiveTodayEvents(events, view) {
	return events;
}

function transformEvents(events, view) {
	switch (view) {
		case "resourceTimelineMonth": // Gantt
			let res = [...events].map(e => ({
				...e,
				end: e.end || e.constraint && e.constraint.end,
				resourceId: parseInt(e.licenseId),
			}));
			return res;
		case "dayGridMonth":
		case "dayGridWeek":
		case "listMonth":
			return [...events].map(e => ({
				...e,
				end: null, // This will prevent an event from spanning multiple days
			}));
		case "catalogueView":
			return [...events].map(e => ({
				...e,
				end: null
			}));
	}

	return events;
}

function getResourcesFromEvents(events, view) {
	if (!events?.length) {
		return [];
	}

	switch (view) {
		case "resourceTimelineMonth": // Gantt
			return events
				.map(e => ({ id: parseInt(e.licenseId), title: e.title }))
				.filter((r, i, list) => list.findIndex(r2 => r2.id === r.id) === i);
	}
	return [];
}

function eventIsHighProfile(event) {
	return event.licenseClass === "HighProfile";
}

function getEventOrder(view) {
	switch (view) {
		case "customDayGridMonth":
			return [
				titleNumericCompare,
				"start"
			];
	}

	return [
		"-allDay",
		"start",
		titleNumericCompare,
	];
}

const titleNumericCompare = (a, b) => a.title.localeCompare(b.title, {}, { numeric: true });

function repositionPopoverIfBelowScreen(el) {
	const rect = el.getBoundingClientRect();
	if (rect.bottom >= window.innerHeight - 20) {
		el.style.top = "unset";
		el.style.bottom = "20px";
	}
}

function minutesToHours(str) {
    let sec_num = parseInt(str, 10) * 60;
    let hours   = Math.floor(sec_num / 3600);
    if (hours   < 10) {hours   = "0" + hours;}
    
	if (isNaN(hours)) return 0; // To avoid displaying NaN
	return hours.toLocaleString();
}
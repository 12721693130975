import * as ReviewsAPI from '../../../apis/reviews'
import { useQuery } from '@tanstack/react-query'

interface args {
    filter?: object;
    enabled?: boolean;
}

const useReviewsSources = ({ filter = {}, enabled = true }: args = {}) => {
    const filters = {
        ...filter,
    };
    return useQuery(
        ["reviews", "sources", filters],
        () => ReviewsAPI.fetchSources(filters),
        { enabled }
    );
};

export default useReviewsSources;

import * as CMSAPI from '../../../apis/cms'
import { useQuery } from '@tanstack/react-query'

const useCMSGenres = (filter: object, enabled: boolean = true) => {
    const filters = {
        _entity: "genres",
        orderBy: "name",
        pageSize: 1000,
        ...filter,
    };
    return useQuery(
        ["cms", "genres", filters],
        () => CMSAPI.fetchCMSEntities(filters),
        { enabled }
    );
};

export default useCMSGenres;

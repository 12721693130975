import React from 'react'
import {Filter, Left, Right} from '../../../components/filter'
import Switch from '../../../components/ui/controls/switch'
import Search from '../../../components/ui/controls/search'
import { getProvider, getDownloadRightsStates } from '../shared/utils'
import appConfig from 'config'

export default ({
	title,
	searchText,
	filters,
	unselectedFilters,
	handleFilter,
	providers,
}) => (
	<Filter>
		<Left>
			<h1>{title}</h1>
			<Search
				onChange={handleFilter.bind(this, "search")}
				placeholder="Search"
				searchText={searchText} />
		</Left>
		<Right>
			<Switch
				name="providerId"
				title="Source"
				unselected={unselectedFilters}
				onChange={handleFilter.bind(this, "filter")}
				states={appConfig.features.acqC70 ? getProviderStates(providers) : getProvider()}
				currentState={filters.filter.providerId}
			/>
			<Switch
				name="hasDownloadRights"
				title="Download rights"
				unselected={unselectedFilters}
				onChange={handleFilter.bind(this, "filter")}
				states={getDownloadRightsStates()}
				currentState={filters.filter.hasDownloadRights}
			/>
			{renderAdsAllowedSwitch(unselectedFilters, handleFilter, filters.filter.hasAdsAllowed)}
		</Right>
	</Filter>
);

function getProviderStates(providers) {
	const states = [{
		key: "",
		text: "Any",
		description: "Any provider",
	}];

	states.push(...providers.map(p => ({
		key: p.id.toString(),
		text: p.name,
		description: `License contract comes from ${p.name}.`
	})));

	return states;
}

function renderAdsAllowedSwitch(unselectedFilters, handleFilter, hasAdsAllowed) {
	if (appConfig.features && appConfig.features.acqAdsAllowed) {
		return <Switch
					name="hasAdsAllowed"
					title="Ads allowed"
					unselected={unselectedFilters}
					onChange={handleFilter.bind(this, "filter")}
					states={getDownloadRightsStates()}
					currentState={hasAdsAllowed}
				/>
	}
}
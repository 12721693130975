import React from 'react'
import { browserHistory } from 'browserHistory'

import App from '../../../../components/app'
import { StandardInfo } from '../../../../components/comet'
import Empty from '../../../../components/list/empty'
import withDnDContext from '../../../../core/services/withDnDContext'

import Store from '../../store'
import Actions from '../../actions'

import Filter from './filter'
import List from './list'

import './app.css'

const DATASTORE = "groups";
const TARGETSTORE = "sections";

class GroupsApp extends React.Component {

	state = {
		...Store.getState()
	}

	// Lifecycle
	componentDidMount() {

		Store.listen(this.onChange);

		const { sections } = this.state.list;

		Actions.fetchItems(DATASTORE, sections.filters, { targetStore: TARGETSTORE });
	}

	componentWillUnmount() {
		Actions.unmount();
		Store.unlisten(this.onChange);
	}

	onChange = (state) => {
		this.setState(state);
	}

	render() {
		const { sections } = this.state.list;
		const { items, filters, _selectedSection, isLoading } = sections;

	    return (
			<App name="c6-selections-ott-groups" layout="grid" isLoading={isLoading}>
				<Filter
					conditions={filters}
					// groups={items}
					selectedGroup={_selectedSection}
				/>
				<List
					ref={r => this.listRef = r}
					items={this.state.list.sections.items}
					conditions={filters}
					selectedGroup={_selectedSection}
				>
					<Empty v2={true} title="" isLoading={isLoading}>
						Please select a section above.
					</Empty>
				</List>
				<StandardInfo location={this.props.location} />
			</App>
		);
	}
}

export default withDnDContext(GroupsApp);
import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import { Content } from '../../../../components/list/contentItem'
import SortableContentItem from '../../../../components/list/contentItemSortable'

import '../../../../components/listItems/standard.css'
import Label from '../../../../components/ui/label'
import DataBite from '../../../../components/ui/databite'
import Period from '../../../../components/ui/period'

import Preview from '../../../../components/assets/preview'
import { getImage, getPlaceholder } from '../../../../utils/assets'

import ListConstants from '../constants'
import Constants from '../../../../core/constants'
import { getListIcon, getAudienceDisplayName, getPeriod } from '../../shared/utils'

// import Constants from '../../../../core/constants'

export default class ListContent extends React.Component {

	static propTypes = {
		item: PropTypes.object,
		// title: PropTypes.string.isRequired,
		// description: PropTypes.string,
		// schedule: PropTypes.array,
		// autoGenerated: PropTypes.bool,

		isPlaceholder: PropTypes.bool,
		findItem: PropTypes.func,
		moveItem: PropTypes.func,
		handleOutsideDrop: PropTypes.func,
		handleItemManipulation: PropTypes.func,
		onDragStateChanged: PropTypes.func,
	}

	render() {
		const {
			item: itemData, // NEW
			selectedGroup,
			assets: coverAssets,

			// DND
			isPlaceholder,
			findItem,
			moveItem,
			handleOutsideDrop,
			handleItemManipulation,
			onDragStateChanged,
		} = this.props;

		const {
			name,
			description,
			autoGenerated,
			active,
			publishTargets,
			version,
			audience,
			type,
		} = itemData;

		const descriptionEl = description
			? <p>{description}</p>
			: null;

		const { from, until, weekdays, hours } = publishTargets?.[0] ?? {};
		const { period, isExpired, isUpcoming } = getPeriod({ from, until });

		let status = null;
		if (!active) {
			status = <Label status="inactive">Inactive</Label>;
		} else if (isExpired) {
			status = <Label status="expired">Expired</Label>;
		} else if (isUpcoming) {
			status = <Label status="disabled">Upcoming</Label>;
		}

		let partTimePublished = null;
		if ((weekdays > 0 && weekdays !== 127) || (hours > 0 && hours !== 16777215)) {
			partTimePublished = <Label status="headsup">Part time published</Label>;
		}

		const hasPreview = doesListHavePreview(this.props);
		const preview = getPreview(itemData, coverAssets);
		
		return (
			<SortableContentItem
				data={itemData}
				handleType="icon"
				extraClasses={`${hasPreview ? "has-preview" : ""} ${!active ? "inactive" : ""}`}

				// DND
				isPlaceholder={isPlaceholder}
				findItem={findItem}
				moveItem={moveItem}
				onItemDropOutside={handleOutsideDrop}
				insertCard={handleItemManipulation}
				onDragStateChanged={onDragStateChanged}
			>
				<div className="c6-icon-container">
					{getListIcon(itemData)}
				</div>
				<Content>
					<h1>{name}{autoGenerated ? " (Automatic)" : ""}</h1>
					{status}
					{partTimePublished}
					{descriptionEl}
				</Content>
				<Content>
					{period}
				</Content>
				<Content>
					{getTargets(version, audience, selectedGroup)}
				</Content>
				{hasPreview && (
					<Content className="cover-previews-new">
						{preview}
					</Content>
				)}
			</SortableContentItem>
		);
	}
}

// HELPERS
function getTargets(version, audience, selectedGroup) {
	if (
		!selectedGroup?.id
		|| selectedGroup?.service?.name === "partner"
	) {
		return null;
	}

	const groupVersionId = selectedGroup?.version?.id ?? 0;
	let targets = "";
	if (version && version.id !== groupVersionId && version.id !== 0) {
		targets = version.name;
	}

	if (targets && audience !== "everyone") {
		targets += ": ";
	}

	if (audience !== "everyone") {
		targets += getAudienceDisplayName(audience);
	}

	return targets
		? <DataBite icon="account_circle">{targets}</DataBite>
		: null ;
}

function getPreview(item, coverAssets) {	
	if (coverAssets?.length) {
		return coverAssets.slice(0, 15).map((itemAsset, i) => {
			if (item.type === "singleItem") {
				return <Preview key={i} imageGuid={itemAsset.imageGuid} size="medium" />;
			}
			return (
				<Cover
					key={i}
					itemAsset={itemAsset}
				/>
			);
		});
	}
	
	// coverAssets == [] => finished loading but has no assets
	if (Array.isArray(coverAssets) && !coverAssets.length) {
		return <div className="empty">Empty</div>;
	}

	// coverAssets == null => not finished loaded yet
	return null;
}



const Cover = ({ itemAsset, size = "largeCover" }) => {
	const { imageGuid, versionImageGuid, name, start, end } = itemAsset;

	const image = getImage(versionImageGuid ?? imageGuid, size);

	const now = moment();
	let hoverTitle = name ?? "",
		className = "cover";
	if (start && moment(start).isAfter(now)) { // upcoming
		hoverTitle += " (Upcoming)";
		className += " upcoming"
	} else if (end && moment(end).isBefore(now)) { // expired
		hoverTitle += " (Expired)";
		className += " expired";
	}


	return (
		<div title={hoverTitle} className={className}>
			{image && image.component || getPlaceholder(size)}
		</div>
	);
};

function doesListHavePreview({ item, assets }) {
	if (!["automatic", "curated", "singleItem"].includes(item.type))
		return false;

	// Most singleItems have assets and should have a preview box
	// but some don't (intentionally)
	if (item.type === "singleItem"
		&& Array.isArray(assets)
		&& (!assets.length || !assets.some(a => a.imageGuid ?? a.versionImageGuid))
	) {
		return false;
	}

	return true;
}
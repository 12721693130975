import * as SportAPI from '../../../apis/sport'
import { useQuery } from '@tanstack/react-query'

const useSportLeague = (seriesId: string | number) => {
    return useQuery(
        ["sport", "league", { id: seriesId }],
        () => SportAPI.fetchSeries({ id: seriesId }),
        { enabled: seriesId !== null }
    );
};

export default useSportLeague;
import React from 'react'

import App from '../../../components/app'
import { StandardInfo } from '../../../components/comet'
import Main from '../../../components/ui/main'
import Header from '../../../components/headers/header'
import Empty from '../../../components/list/empty'

import List from './list'
import Store from '../store'
import Actions from '../actions'

const PARENT_ENTITY =  "module"
const ENTITY =  "service"

const SECONDARY_ENTITY = "role"

//@Keydown
export default class ModuleServicesApp extends React.Component {

	constructor(props) {
		super(props);

		this.onChange = this.onChange.bind(this);
		this.handleToggle = this.handleToggle.bind(this);
		this.handleSetProperty = this.handleSetProperty.bind(this);

		// App data
		this.moduleId = this.props.params.id;

		this.state = {
			...Store.getState(),
		};
	}

	componentDidMount() {
		Store.listen(this.onChange);

		Actions.fetchItems(`${ENTITY}s`);
		Actions.fetchItem(PARENT_ENTITY, { id: this.moduleId });

		Actions.fetchItems(`${SECONDARY_ENTITY}s`);
	}

	componentWillUnmount() {
		Actions.unmount();
		Store.unlisten(this.onChange);
	}

	onChange(state) {
		this.setState(state);
	}

	handleSetProperty(data, entity, propertyName, e) {
		const property = {};
		property[propertyName] = e.target.value;

		const payload = { data, id: this.moduleId, property };
		const entities = { parentEntity: PARENT_ENTITY, entity };

		Actions.setItemEntityProperty(entities, payload);
	}

	handleToggle(data, entity, e, active) {
		const payload = { data, id: this.moduleId };
		const entities = { parentEntity: PARENT_ENTITY, entity };

		if(active) {
			Actions.addItemEntity(entities, payload);
		}
		else {
			Actions.removeItemEntity(entities, payload);
		}
	}

	render() {
		let filterTitle = "";
		if(this.state.item.module && this.state.item.module.displayName) {
			filterTitle = this.state.item.module.displayName;
		}

	    return (
			<App
				name="c6-shield-moduleservices"
				layout="grid"
				isLoading={this.state.isLoading}>
				<Header title={filterTitle} backToTitle="Modules" />
				<Main padding={true}>
					<List
						isLoading={this.state.isLoading}
						items={this.state.services}
						roles={this.state.roles}
						module={this.state.item.module}
						handleToggle={this.handleToggle}
						handleSetProperty={this.handleSetProperty}>
						<Empty isLoading={this.state.isLoading}>
							No modules? Just add them from the module settings!
						</Empty>
					</List>
				</Main>
				<StandardInfo location={this.props.location} />
			</App>
		);
	}
}
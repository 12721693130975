import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import { extractMetadata } from '../../../../utils/discovery'
import { getListIcon, getPeriod } from '../../shared/utils'

import '../../../../components/listItems/standard.css'
import StandardListItem from '../../../../components/listItems/standard'
import { Right } from '../../../../components/filter'
import Button from '../../../../components/ui/controls/button'

// import Actions from '../../actions'

const ItemContent = ({ item, onPreviewClick, onShowEpisodes, mode }) => {

	const { displayName, name, modified, contentType } = item;

	// Discovery program (Single, Season, Sport, Highlight, Summary, Episode, Series)
	// const needsMetadata = contentType && ["SingleProgram", "SeasonProgram", "LiveSport", "SportClipHighlights", "SportClipSummary", "EpisodeProgram", "SeriesProgram"].indexOf(contentType.name) >= 0;
	// const needsMetadata = item.type === "Program";
	const needsMetadata = mode === "discovery";
	if (needsMetadata) {
		const metadata = extractMetadata(item, onShowEpisodes);

		return (
			<StandardListItem
				title={/*metadata.epgTitle ||*/ metadata.episodeName || displayName || name || "!!! Missing title !!!"}
				description={renderDescription(item)}
				{...metadata}
			/>
		);
	}

	// Selection list
	const momentModified = moment(modified);
	const isoModified = momentModified.toISOString();
	const itemIcon = getListIcon(item);

	return (
		<div className="flex flex-dyn-1">
			<div className="c6-icon-container">
				{itemIcon}
			</div>
			<div className="c6-content">
				<h1>{displayName || name}</h1>
				<p>Modified <time title={isoModified} dateTime={isoModified}>{momentModified.fromNow()}</time></p>
			</div>
			<div className="c6-content c6-content-action">
				<Right>
					<Button type="pageview" noBackground={true} onClick={(e) => { onPreviewClick(e, item) }} />
				</Right>
			</div>
		</div>
	);
};
ItemContent.propTypes = {
	item: PropTypes.object.isRequired,
};

export default ItemContent;


function renderDescription(item) {
	const {
		type,
		activeDate,
		validFrom,
		validUntil,
		class: itemClass,
	} = item;

	const isSport = ["Sport"].includes(itemClass);
	const isProgram = ["SingleProgram", "SeriesProgram", "SeasonProgram", "EpisodeProgram"].includes(type);

	if (isSport && activeDate) {
		return moment(activeDate).format("HH:mm dddd D MMM");
	} else if (isProgram && validFrom) {
		const from = moment(validFrom);
		const future = from.isAfter(moment());
		if (future) {
			return from.calendar(null, {
				sameDay: '[Premieres today] (D MMM YYYY)',
				nextDay: '[Premieres tomorrow] (D MMM YYYY)',
				nextWeek: '[Premieres on] dddd (D MMM YYYY)',
				lastDay: '[Premiered yesterday] (D MMM YYYY)',
				lastWeek: '[Premiered last] dddd (D MMM YYYY)',
				sameElse: '[Premieres] D MMM YYYY',
			});
		}

		const { period } = getPeriod({ from: validFrom, until: validUntil });
		return period;
	}
}
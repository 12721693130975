import React from 'react'

// hook for getting previous value
// https://blog.logrocket.com/how-to-get-previous-props-state-with-react-hooks/
export default function usePrevious(value) {
    const ref = React.useRef();
    
    React.useEffect(() => {
        ref.current = value;
    });

    return ref.current;
}
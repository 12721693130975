import React, { Component } from 'react'
import PropTypes from 'prop-types'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'

export default class SubMoudles extends Component {

	static propTypes = {
		parentModule: PropTypes.object,
		items: PropTypes.array,
		login: PropTypes.object,
		handleToggle: PropTypes.func.isRequired,
	}

	render() {
		const {
			parentModule,
			items = [],
			login = {},
			handleToggle,
		} = this.props;

		const active = moduleActive(login, parentModule.id);

		// if(!active || parentModule.type.indexOf("C5") >= 0) {
		if(!active || !items.length) {
			return null;
		}

		const heading = parentModule.type.includes("C5") ? "Sub modules" : "Apps";

	    return (
			<div className="flex c6-secondary">
				<div className="c6-content">
					<h2 style={{color: "var(--text-lighter-color)"}}>{heading}</h2> {getSubmodules(items, handleToggle, login)}
				</div>
			</div>
		);
  	}
}

// HELPERS
function moduleActive(login, moduleId) {
	return login.modules ? login.modules.findIndex(m => m.id === moduleId) >= 0 : false;
}

function getSubmodules(submodules, handleToggle, login) {
	return submodules.map(submodule => (
		<FormControlLabel
			key={`rb${submodule.id}`}
			value={submodule.id.toString()}
			control={
				<Checkbox
					checked={moduleActive(login, submodule.id)}
					onChange={handleToggle.bind(this, submodule, "module")}
				/>
			}
			label={submodule.enabled ? submodule.displayName : `${submodule.displayName} (DISABLED)`}
			labelPlacement="end"
			style={{
				color: submodule.enabled ? undefined : "var(--text-lighter-color)",
				marginRight: "20px",
				marginLeft: 0
			}}
		/>
	));
}
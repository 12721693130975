import          debounce from 'lodash/debounce'
import { CONTENT, MENU } from '../../../../components/actions'

import       Actions from '../../actions'
// import ListConstants from '../constants'

// const DATASTORE = "lists";

export default {
	targetComponent: "SortableItem",
	actions: [
		{
			title: "Select section",
			method: (data, sourceProps) => { Actions.updateSelectedSection(data.id, sourceProps.items); },
			type: CONTENT,
			hidden: data => data && !data.isPlaceholder,
		},
		{
			title: "Edit",
			icon: "create",
			method: (data, sourceProps, location) => {
				Actions.edit({
					id: data.id,
					pathname: `/selections/admin/groups/${data.id}/edit`,
					// category: data.category,
					returnTo: `${location.pathname}${location.search}`,
				});
			},
			type: MENU,
		},
		{
			title: "Unhide",
			icon: "add_circle",
			method: debounce((data, sourceProps) => {
				Actions.unhideSection(data.id);
			}, 200, { leading: true }),
			type: MENU,
			hidden: data => data.status !== "Hidden",
		},
		{
			title: "Hide",
			icon: "remove_circle",
			method: debounce((data, sourceProps) => {
				Actions.hideSection(data.id);
			}, 200, { leading: true }),
			type: MENU,
			hidden: data => data.status === "Hidden",
		},

		// TODO: Does not require confirm!!!
		{
			title: "Delete",
			icon: "remove_circle",
			method: (data, sourceProps, location) => {
				Actions.removeSection(data.id);
			},
			type: MENU,
			hidden: data => data.status !== "Hidden",
			confirm: true,
			dangerous: true,
		}
	]
};
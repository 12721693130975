import React from 'react'
import { Link } from 'react-router'
import { browserHistory } from 'browserHistory'
import moment from 'moment'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'

import App from '../../../../components/app'
import { StandardInfo } from '../../../../components/comet'
import Main from '../../../../components/ui/main'
import DateTimePicker from '../../../../components/ui/controls/pickers/datetimepicker'

import InfoBar from '../../../../components/ui/infoBar'
import Empty from '../../../../components/list/empty'

import List from './list'

import Store from '../../store'
import Actions from '../../actions'

import Filter from './filter'
import FilterStore from './filterStore'
import FilterActions from './actions'

import Discovery from '../discovery/app'

import { Regions } from '../../../../core/constants'

// For rendering groups
import { syncDeepFindGroup } from '../../../../utils/groups'
import { getFormattedDate, getServiceConfiguration, isHourlyOrMinutelyList } from '../../shared/utils'

import withDnDContext from '../../../../core/services/withDnDContext'

import appConfig from 'config'
import { displayAlert } from '../../../../core/services/alert'

import './app.css'

const ENTITY = "listItems";

const TYPES = [
	{ key: "programs", text: "Programs", description: "", searchPlaceholder: "programs" },
	{ key: "broadcasts", text: "Broadcasts", description: "", searchPlaceholder: "broadcasts" },
];
const DEFAULT_DISCOVERY_TYPE = "programs";
const DEFAULT_DISCOVERY_PROGRAM_PREMIERE = "upcoming";
const DEFAULT_DISCOVERY_BROADCASTS_TYPE = "ott";

class ItemsApp extends React.Component {

	constructor(props) {
		super(props);

		// App data
		this.listId = this.props.params.id;

		this.state = {
			...Store.getState(),
			...FilterStore.getState(),
			prepublishDialogData: null,
		};
	}

	// shouldComponentUpdate(nextProps, nextState) {
	// 	return shallowCompare(this, nextProps, nextState);
	// }

	componentDidMount() {
		const { groups, discovery } = this.state.list;

		Store.listen(this.onChange);
		FilterStore.listen(this.onChange);

		const serviceConfiguration = getServiceConfiguration(this.props.params, this.props.location, this.state.item.selectionsConfiguration);

		getData(this.listId, this.state.filters, serviceConfiguration.serviceId);
		Actions.discoveryFilter(discovery.filters, DEFAULT_DISCOVERY_TYPE, DEFAULT_DISCOVERY_PROGRAM_PREMIERE, DEFAULT_DISCOVERY_BROADCASTS_TYPE, serviceConfiguration.discoveryPlatform);

		// Only fetch groups once since they're not changing so often
		if (groups.items.length === 0) {
			Actions.fetchItems(
				"groups",
				{ ...groups.filters, serviceId: serviceConfiguration.serviceId },
				{
					callback: items => Actions.updateSelectedGroup.defer(this.groupId, items),
				},
			);
		}

		if (appConfig.features.selectionsMovedToHeroComet) {
			displayAlert(
				"warning",
				null,
				null,
				null,
				60000,
				`Administration of the press service has been moved to <a href="https://tv4.junecomet.com">tv4.junecomet.com</a>`
			);
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.params.id != this.listId) {
			this.listId = nextProps.params.id;
			getData(this.listId, this.state.filters);
		}
	}

	componentWillUnmount() {
		Actions.unmount(ENTITY);
		Actions.unmount("listInstances");
		Actions.unmount("listReferences");
		FilterActions.unmount();

		Store.unlisten(this.onChange);
		FilterStore.unlisten(this.onChange);
	}

	onChange = (state) => {
		this.setState(state);
	}

	handleCreateNew = () => {
		const filters = this.state.filters;

		let itemType = "";
		if (this.state.item?.list?.contentLayout === "bulletin-board") {
			itemType = "&itemType=announcement";
		}

		const formattedDate = getFormattedDate(filters.date, filters);
		const pathname = `${this.props.location.pathname}/create`;
		const route = {
			pathname,
			search: `?date=${encodeURIComponent(formattedDate)}${itemType}`,
			state: {
				modal: true,
				returnTo: this.props.location.pathname
			}
		};

		browserHistory.push(route);
	}

	handleDeleteItems = () => {
		Actions.deleteListItems({
			listId: this.listId,
			filters: this.state.filters
		});
	}

	render() {
		const {
			item,
			filters,
			// isLoading,
		} = this.state;

		const {
			listItems,
			sectionsContainingList,
			groups,
			discovery,
			discoveryEpisodes,
		} = this.state.list;

		const {
			todayButtonTitle,
			showNavigation,
		} = filters;

		const { list } = item;


		const serviceConfiguration = getServiceConfiguration(this.props.params, this.props.location, this.state.item.selectionsConfiguration);
		const serviceId = serviceConfiguration.serviceId;
		const inheritedList = list?.source ?? null;
		const inheritedInfo = getInheritedListInfo(this.listId, inheritedList, groups.items, serviceId);
		const sectionId = parseInt(this.props.location?.query.groupId);

		const listCreated = list?.created ?? null;
		const listRegion = list?.version ? Regions.find(r => r.id === list.version.id) : null;
		const listTimezone = listRegion?.momentTimezone;

		const handleCreateNew = list.type === "curated"
			? this.handleCreateNew
			: null;

		const isLoading = listItems.isLoading
			|| sectionsContainingList.isLoading
			|| groups.isLoading
			|| discovery.isLoading
			|| discoveryEpisodes.isLoading;

		const layout = inheritedInfo ? "grid area-right area-info" : "grid area-right";
		return (
			<App
				name="c6-selections-ott c6-selections-ott-items"
				layout={layout}
				isLoading={isLoading}
			>

				<Filter
					title={getTitle(list, groups.items, sectionId, serviceId)}
					filters={filters}
					hasNavigation={showNavigation}
					todayButtonTitle={todayButtonTitle}
					onDeleteItems={this.handleDeleteItems}
					onCreateNew={handleCreateNew}
					listCreated={listCreated}

					section={syncDeepFindGroup(sectionId, groups.items)}
					sectionsContainingList={sectionsContainingList}
					groups={groups.items}
					listTimezone={listTimezone}
					{...this.props}
				/>

				{inheritedInfo}
				<Main>
					<List
						ref={r => this.listRef = r}
						listId={this.listId}
						readonly={inheritedList && !!inheritedList.id}
						items={listItems.items}
						filters={filters}
						list={list}
						openPrepublishDialog={(item) => this.setState({ prepublishDialogData: item })}
					>
						<Empty v2={true} isLoading={isLoading}>
							Why not drag some nice programs over from the list to the right?
						</Empty>
					</List>
				</Main>

				<Discovery
					types={TYPES}
					list={discovery}
					episodesList={discoveryEpisodes}
					onItemDropOutside={this.handleOutsideDrop}
					discoveryPlatform={serviceConfiguration.discoveryPlatform}
				/>

				<StandardInfo location={this.props.location} />

				{this.state.prepublishDialogData && (
					<PrepublishDialog
						listItem={this.state.prepublishDialogData}
						listId={this.listId}
						filters={filters}
						onClose={() => this.setState({ prepublishDialogData: null })}
					/>
				)}
			</App>
		);
	}
}

export default withDnDContext(ItemsApp);
// onItemDropOutside={this.removeItem}

// const linkAction = {
// 	pathname: `/selections/ott/lists/${this.listId}/link`,
// 	state: {
// 		modal: true,
// 		returnTo: `${this.props.location.pathname}${this.props.location.search}`,
// 	}
// };
// 	Why not drag some nice programs over from the list to the right or <Link className="c6-action" to={linkAction}>get programs from an existing list</Link>?

// TODO: Only render the filter title when navigation to another instance occurs

// HELPERS
function getData(listId, filters, serviceId) {
	Actions.fetchItem("list", listId, {
		callback: list => {
			const payload = {
				listId,
				filters: { date: getFormattedDate(filters.date, filters) },
			};
			Actions.fetchItems(ENTITY, payload);
			if (isHourlyOrMinutelyList(list)) {
				Actions.fetchItem("listInstances", payload);
			}
		}
	});
	Actions.fetchItems("groups", { listId, includeHidden: true, serviceId }, { targetStore: "sectionsContainingList" });
}

function getInheritedListInfo(listId, inheritedList, groups, serviceId) {

	if (inheritedList?.id) {
		return (
			<InfoBar>
				<p className="icon-lock">
					The content below comes from the list &quot;{getTitle(inheritedList, groups)}&quot;.
					{renderActions(listId, inheritedList, serviceId)}
				</p>
			</InfoBar>
		)
	}

	return null;
}

function renderActions(listId, inheritedList, serviceId) {

	const handleCopy = () => {
		Actions.copyItems({
			listId,
			inheritedList,
		});
	};

	return (
		<span>
			{"You can "}
			<Link className="c6-action" to={`/selections/service/${serviceId}/lists/${inheritedList.id}/items`}>go there to make changes</Link>
			{" or "}
			<span className="c6-action" onClick={handleCopy}>copy everything to this list</span>
			{" if you want to make specific changes."}
		</span>
	);
}

function getTitle(list, groups, sectionId, serviceId) {
	const { displayName, name } = list;
	let title = displayName || name || "";

	// Add the group title
	if (sectionId && groups.length) {
		const group = syncDeepFindGroup(sectionId, groups);
		if (group) {
			return (
				<span>
					{group.parentDisplayName ? `${group.parentDisplayName} / ` : null}<Link to={`selections/service/${serviceId}?groupId=${group.id}`}>{group.displayName}</Link> / {title}
				</span>
			);
		}
	}

	return title;
}

function PrepublishDialog({ onClose, listItem, listId, filters }) {
	const defaultDate = listItem.position?.activeDate
		? moment(listItem.position.activeDate).format()
		: moment().format();
	const [date, setDate] = React.useState(defaultDate);
	return (
		<Dialog
			open={true}
			onClose={onClose}
			className="c6-modal"
			classes={{
				container: "c6-modal-content",
				paper: "c6-modal-body"
			}}
			PaperProps={{ style: { overflowY: "visible" } }}
		>
			<DialogContent style={{ overflowY: "visible" }}>
				<h1>
					Pick a date &amp; time to prepublish or delay publish for:<br/>
					<strong>{listItem.displayName}</strong>
				</h1>
				<DateTimePicker
					value={date}
					onChange={newValue => {
						if (newValue) {
							setDate(moment(newValue).format());	
						} else {
							setDate(null);
						}
					}}
				/>
			</DialogContent>
			<DialogActions>
				<Button
					key="save"
					color="primary"
					variant="standard"
					// disabled={!date}
					onClick={() => {
						Actions.moveItemInList({
							listId,
							payload: {
								id: listItem.id,
								date: getFormattedDate(filters.date, filters),
								mediaType: listItem.mediaType,
								position: {
									...listItem.position,
									activeDate: date === null
										? "0001-01-01T00:00:00+00:00" // This string will tell the API to clear activeDate
										: date
								},
							},
							filters,
						});
						onClose();
					}}
				>
					Save
				</Button>
			</DialogActions>
		</Dialog>
	);
}
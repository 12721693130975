import React from 'react'

import Editor, { parseUi, withQueryClient } from '../../../../components/editor/index'
import { hasAccessToPath } from '../../../../core/services/auth'

import schema from './schema'
import uiSchema from './uiSchema'

import './app.css'

const ServiceEditor = props => {

	return (
		<Editor
			className="c6-vod-service-editor"
			layout="grid"
			api="vod"
			entity="service"
			getSchema={getSchema}//.bind(this, genres, arenas)}
			getUiSchema={getUiSchema}
			savePayloadTransform={savePayloadTransform}
			hasEditAccess={hasAccessToPath(props.routes, "editor")}
			{...props}
		/>
	);
};

export default withQueryClient(ServiceEditor);

function getSchema(model, isNew, location, route, params) {
	return schema;
}

function getUiSchema(model, isNew, location, route, params) {
	return uiSchema;
}

function savePayloadTransform({ formData, ...rest }) {
	const isNew = !formData?.id;
	
	return {
		...rest,
		formData: {
			...formData,
		},
	};
}
import React from 'react'
import moment from 'moment'
import Dialog from '@mui/material/Dialog'

import * as CMSAPI from '../../../apis/cms'
import AuditItem from './auditItem'
import GenericListApp from '../../../components/genericApp/listApp'
import useList from '../../../core/hooks/useList'
import { getItems } from '../../../components/genericApp/utils'
import './audit.css'

import Search from '../../../components/ui/controls/search'
import Dropdown from '../../../components/ui/controls/dropdown'

function AuditModal(props) {
	const { module, collection, id } = props;

	const [searchText, setSearchText] = React.useState("");
	const [attributes, setAttributes] = React.useState([]);
	const [changedBy, setChangedBy] = React.useState("");
	const [filteredItems, setFilteredItems] = React.useState([]);

	const filterDefaults = {
		_entity: collection,
		_module: module,
		id,
		systemtime: "all",
		pageSize: 10000,
	};
	
	const filterConfig = {};

	const listState = useList({
		listKey: "audit",
		fetchData: wrappedFetch,
		entity: collection,
		filterDefaults,
		filterConfig,
	});

	React.useEffect(
		() => {
			const newItems = filterItems({
				items: getItems(listState.data),
				searchText,
				attributes,
				changedBy,
			});
			setFilteredItems(newItems);
		},
		[listState.data, searchText, attributes, changedBy]
	);

	const leftHeaderExtra = renderCustomFilters({
		searchText,
		setSearchText,
		attributes,
		setAttributes,
		attributeOptions: getAttributeOptions(listState),
		changedBy,
		setChangedBy,
		userOptions: getUserOptions(listState),
	});

	return (
		<Dialog
			open={props.open ?? true}
			onClose={props.onClose}
			className="c6-modal audit-modal"
			classes={{
				container: "c6-modal-content",
				paper: "c6-modal-body"
			}}
			fullWidth
			maxWidth="md"
		>
			<GenericListApp
				module="eventplanner"
				datastore="audit"
				state={listState}
				items={filteredItems}
				textHeading="Audit trail"
				textEmpty=""
				filterConfig={filterConfig}
				collapseFiltersDefault={false}
				sortItemsBy={item => 0 - moment(item.changedAt ?? item.occuredAt).valueOf()}
				padding={false}
				virtualize={true}
				leftHeaderExtra={leftHeaderExtra}
			>
				<AuditItem />
			</GenericListApp>
		</Dialog>
	);
}

export default AuditModal;

// Find users in the initial full list of audit items
function getUserOptions(listState) {
	const [initialUsers, setInitialUsers] = React.useState(null);
	if (initialUsers !== null) {
		return initialUsers;
	}

	const items = getItems(listState.data);
	const users = items.map(item => item.changedBy ?? item.initiatedBy)
		.filter((item, index, list) => list.indexOf(item) === index)
		.sort()
		.map(user => ({ key: user, text: user }));
	
	if (users.length) {
		users.unshift({ key: "", text: "Anyone" });
		setInitialUsers(users);
	}

	return users;
}

// Find attributes in the initial full list of audit items
function getAttributeOptions(listState) {
	const [initialAttributes, setInitialAttributes] = React.useState(null);
	if (initialAttributes !== null) {
		return initialAttributes;
	}

	const items = getItems(listState.data);
	const attributes = items.map(item => item.attribute ?? item.name)
		.filter((item, index, list) => list.indexOf(item) === index)
		.sort()
		.map(attribute => ({ key: attribute, text: attribute }));
	
	if (attributes.length) {
		attributes.unshift({ key: "", text: "All" });
		setInitialAttributes(attributes);
	}

	return attributes;
}

function wrappedFetch(filters) {
	return new Promise((resolve, reject) => {
		const actionsFilters = {
			...filters,
			initiatedBy: filters.changedBy,
			changedBy: null,
		};
		Promise.all([
			CMSAPI.fetchEntityValues(filters),
			CMSAPI.fetchEntityActions(actionsFilters),
		]).then(responses => {
			const [values, actions] = responses;
			resolve({
				...values,
				items: [
					...values.items,
					...actions.items,
				],
			});
		}).catch(reject);
	});
}

function filterItems({ items, searchText, attributes, changedBy }) {
	let result = [...items];

	if (searchText?.length) {
		result = result.filter(item => {
			return [
				(item.attribute ?? item.name ?? "").toLowerCase(),
				(item.changedBy ?? item.initiatedBy ?? "").toLowerCase(),
				(`${item.value}`).toLowerCase(),
			].some(text => text.includes(searchText.toLowerCase()));
		});
	}

	if (attributes?.length) {
		result = result.filter(item => {
			return attributes.some(a => a.toLowerCase() === (item.attribute ?? item.name).toLowerCase());
		});
	}

	if (changedBy?.length) {
		result = result.filter(item => (item.changedBy ?? item.initiatedBy) === changedBy);
	}

	return result;
}

function renderCustomFilters({ searchText, setSearchText, attributes, setAttributes, attributeOptions, changedBy, setChangedBy, userOptions }) {
	return (
		<React.Fragment>
			<Search
				onChange={e => setSearchText(e.target.value)}
				placeholder="Search"
				searchText={searchText}
			/>
			<Dropdown
				title={"Values to include"}
				onChange={e => {
					const newValue = e.target.value;
					if (newValue === "") {
						setAttributes([]);
					} else if (attributes?.includes(newValue)) {
						setAttributes(attributes.filter(v => v !== newValue));
					} else {
						setAttributes([
							...(attributes ?? []),
							newValue,
						]);
					}
				}}
				states={attributeOptions}
				currentState={attributes}
				emptyStateText="All"
			/>
			<Dropdown
				title={"Changed by"}
				onChange={e => setChangedBy(e.target.value)}
				states={userOptions}
				currentState={changedBy}
			/>
		</React.Fragment>
	);
}
import React from 'react'
import { Route, IndexRoute, IndexRedirect, Redirect } from 'react-router'

import Module from '../app'

import PressApp      from './dashboard/app'

import OTTConst      	from './ott/constants'
import OTTApp        	from './ott/app'
import OTTListItems  	from './ott/items/app'
import OTTItemEditor 	from './ott/itemeditor/app'
import OTTListEditor 	from './ott/listeditor/app'


import AdminDashboard 	from './admin/dashboard/app'
import TemplatesAdmin 	from './admin/templates/app'
import TemplateEditor 	from './admin/templateeditor/app'
import AreasAdmin 		from './admin/areas/app'
import AreaEditor 		from './admin/areaeditor/app'
import Groups     		from './admin/groups/app'
import GroupEditor 		from './admin/groupeditor/app'

import Help from './help/app'

import appConfig from 'config'

export default class SelectionsModule {

	static routes() {
		return (
			<Route path="selections" component={Module} module="selections">
				<Route path="press" app="press">
					<IndexRoute component={PressApp} />
					<Route path="lists/:id/items" component={OTTListItems} returnTo="/selections/press" />
					<Route path="lists/:listId/items/(:id)/edit" component={OTTItemEditor} access="editor" modal={true} wideModal={appConfig.features.selectionsItemEditorSTARAddon} />
					<Route path="lists/:listId/items/create" component={OTTItemEditor} access="editor" modal={true} wideModal={appConfig.features.selectionsItemEditorSTARAddon} />
				</Route>

				<Redirect from="ott" to="service" /> {/* Some users will have OTT links from Shield for a while */}
				<Route path="service">
					<IndexRedirect to="1" /> {/* Default serviceId 1 */}
					<Route path=":serviceId">
						<IndexRoute component={OTTApp} />

						<Route path="lists/:id/items" component={OTTListItems} />

						<Route path="lists/:id/edit" component={OTTListEditor} access="editor" modal={true} />
						<Route path="lists/create" component={OTTListEditor} access="editor" modal={true} />

						<Route path="lists/:listId/items/(:id)/edit" component={OTTItemEditor} access="editor" modal={true} wideModal={appConfig.features.selectionsItemEditorSTARAddon} />
						<Route path="lists/:listId/items/create" component={OTTItemEditor} access="editor" modal={true} wideModal={appConfig.features.selectionsItemEditorSTARAddon} />
					</Route>
				</Route>

				<Route path="admin" app="admin">
					<IndexRoute component={AdminDashboard} />
					<Route path="templates" component={TemplatesAdmin} />
					<Route path="templates/create" component={TemplateEditor} modal={true} />
					<Route path="templates/:id/edit" component={TemplateEditor} modal={true} />
					<Route path="areas" component={AreasAdmin} />
					<Route path="areas/create" component={AreaEditor} modal={true} />
					<Route path="areas/:id/edit" component={AreaEditor} modal={true} />
					<Route path="groups">
						<IndexRoute component={Groups} />
						<Route path="create" component={GroupEditor} modal={true} />
						<Route path=":id/edit" component={GroupEditor} modal={true} />
					</Route>
				</Route>

				<Route path="help">
					<IndexRoute component={Help} />
				</Route>
			</Route>
		);
	}
}
import debounce from 'lodash/debounce'
import moment from 'moment'
import { browserHistory } from 'browserHistory'

import { CONTENT, MENU } from '../../../../components/actions'

import Actions from '../../actions'
import ListConstants from '../constants'
import { getFormattedDate } from '../../shared/utils'
import { hasAccessToPath } from '../../../../core/services/auth'

const DATASTORE = "lists";

const enablePrepublishForListCategories = ["regular", "showcase"];

export default {
	targetComponent: "SortableItem",
	actions: [
		{
			title: "Edit",
			icon: "create",
			method: (data, sourceProps, location) => {
				const formattedDate = getFormattedDate(sourceProps.filters.date, sourceProps.filters);
				Actions.edit({
					// pathname: `/selections/ott/lists/${sourceProps.listId}/items/${data.id}/edit`,
					pathname: `${location.pathname}/${data.id}/edit`,
					search: `?date=${encodeURIComponent(formattedDate)}`,
					// id: data.id,
					// listId: sourceProps.listId,
					returnTo: location.pathname
				});
			},
			type: MENU,
			hidden: (data, sourceProps) => {
				const isRegularList = sourceProps.list?.category?.name === "regular";
				if (isRegularList) {
					return false;
				}

				const isEditorialItem = data.type === "editorial";
				const isBroadcastItem = data.type === "broadcast";
				const isProgram = data.type === "program";
				return !isEditorialItem && !isBroadcastItem && !isProgram;
			}
		},
		{
			title: "Default version",
			icon: data => data.mediaType === "undefined" ? "check" : "",
			method: (data, sourceProps) => {
				const { listId, filters } = sourceProps;
				updateMediaType(data, listId, filters, "undefined");
			},
			type: MENU,
			disabled: data => data.mediaType === "undefined",
		},
		{
			title: "Original version",
			icon: data => data.mediaType === "regular" ? "check" : "",
			method: (data, sourceProps) => {
				const { listId, filters } = sourceProps;
				updateMediaType(data, listId, filters, "regular");
			},
			type: MENU,
			disabled: data => data.mediaType === "regular",
		},
		{
			title: "Dub version",
			icon: data => data.mediaType === "dubbed" ? "check" : "",
			method: (data, sourceProps) => {
				const { listId, filters } = sourceProps;
				updateMediaType(data, listId, filters, "dubbed");
			},
			type: MENU,
			disabled: data => data.mediaType === "dubbed",
		},
		{
			title: "Prepublish / Delay publish",
			method: (data, sourceProps) => {
				if (typeof(sourceProps.openPrepublishDialog) === "function") {
					sourceProps.openPrepublishDialog(data);
				}
			},
			disabled: (data, sourceProps) => !enablePrepublishForListCategories.includes(sourceProps.list?.category?.name)
				|| !data.start,
			type: MENU,
		},
		{
			title: "Remove prepublish date",
			method: (data, sourceProps) => {
				const { listId, filters } = sourceProps;
				clearActiveDate(listId, data, filters);
			},
			hidden: (data, sourceProps) => !data.start
				|| !data.position?.activeDate
				|| (data.position?.activeDate && moment(data.position.activeDate).isAfter(moment(data.start))),
			disabled: (data, sourceProps) => !enablePrepublishForListCategories.includes(sourceProps.list?.category?.name)
				|| !data.start,
			type: MENU
		},
		{
			title: "Remove delayed publish date",
			method: (data, sourceProps) => {
				const { listId, filters } = sourceProps;
				clearActiveDate(listId, data, filters);
			},
			hidden: (data, sourceProps) => !data.position?.activeDate
				|| (data.position?.activeDate && moment(data.position.activeDate).isSameOrBefore(moment(data.start))),
			disabled: (data, sourceProps) => !enablePrepublishForListCategories.includes(sourceProps.list?.category?.name)
				|| !data.start,
			type: MENU
		},
		{
			title: "Open media view for program",
			method: (data) => {
				browserHistory.push({
					pathname: `/metadata/images/program/guid/${data.programGuid}/all`,
					state: {
						returnTo: window.location.pathname,
						modal: true,
					}
				});
			},
			hidden: data => data.type !== "program",
			disabled: (data) => {
				return !hasAccessToPath([{ module: "metadata", app: "images", access: "editor" }])
					|| !data?.programGuid;
			},
			type: MENU,
			icon: "insert_photo",
		},
		{
			title: "Remove",
			icon: "remove_circle",
			method: (data, sourceProps) => {
				Actions.itemRemove({
					data: data,
					listId: sourceProps.listId,
					filters: sourceProps.filters
				});
			},
			type: MENU,
			confirm: false,
			dangerous: true
		}
	]
};

function updateMediaType(data, listId, filters, mediaType) {
	const formattedDate = getFormattedDate(filters.date, filters);
	Actions.moveItemInList({
		listId,
		payload: {
			id: data.id,
			position: data.position,
			date: formattedDate,
			mediaType,
		},
		filters,
	});
}

function clearActiveDate(listId, item, filters) {
	Actions.moveItemInList({
		listId,
		payload: {
			id: item.id,
			date: getFormattedDate(filters.date, filters),
			mediaType: item.mediaType,
			position: {
				...item.position,
				activeDate: "0001-01-01T00:00:00+00:00", // This string will tell the API to clear activeDate
			},
		},
		filters,
	});
}
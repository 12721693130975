import { browserHistory } from 'browserHistory'

import alt from '../../core/services/alt'

import * as ShieldData from '../../apis/shield'

import { handleRequestFailed } from '../../core/services/errorhandling'

import Const from '../../core/constants'

class Actions {

	// LIST ITEM ACTIONS (Used from multiple lists)
	edit({id, pathname, search, returnTo}) {

		const route = {
			pathname,
			search,
			state: {
				modal: true,
				returnTo
			}
		};

		browserHistory.push(route)

		return route;
	}

	create({ pathname, search, query, returnTo }) {

		const route = {
			pathname,
			search,
			query,
			state: {
				modal: true,
				returnTo,
			}
		};

		browserHistory.push(route);

		return route;
	}

	showLoginModules({id}) {
		browserHistory.push(`/shield/logins/${id}/modules`);
		return id;
	}

	showModuleServices({id}) {
		browserHistory.push(`/shield/admin/modules/${id}/services`);
		return id;
	}

	showServiceResources({id}) {
		browserHistory.push(`/shield/admin/services/${id}/resources`);
		return id;
	}

	showRoleResources({moduleId, id}) {
		browserHistory.push(`/shield/admin/modules/${moduleId}/roles/${id}/resources`);
		return id;
	}

	// TODO: How to return function without alt executing it?
	// go(pathname) {
	// 	return ({id}) => {
	// 		console.log("Going to push browserHistory");
	// 		//browserHistory.push(pathname.replace("{id}", id));
	// 		return id;
	// 	}
	// }

	// SHARED API actions
	fetchItems(entity, payload = {}) {
		return (dispatch) => {
			dispatch();
			const multiple = true;
			const command = getCommand("fetch", entity, multiple);
			const store = getStore(entity, multiple);

			payload.pageSize = 1000; // HACK: since Shield does not use auto paging when scrolling and that takes some work to implement, just use explicit pageSize for now.

			ShieldData[command](payload)
				.then(response => {
					this.itemsUpdated(store, response.items);
				}, this.requestFailed);
		};
	}
	itemsUpdated(datastore, items) { return {datastore, items}; }

	fetchItem(entity, payload) {
		return (dispatch) => {
			dispatch();

			const command = getCommand("fetch", entity);
			ShieldData[command](payload)
				.then(model => {
					this.itemLoaded({entity, model});
				}, this.requestFailed);
		};
	}
	itemLoaded(payload) { return payload; }

	updateItem(entity, data, payload) {
		return (dispatch) => {
			dispatch();

			const command = getCommand("update", entity);
			ShieldData[command](data, payload)
				.then(model => {
					this.itemUpdated({ datastore: `${entity}s`, model, originalId: data.id });
				}, this.requestFailed);
		}
	}

	enableItem(entity, data) {
		return (dispatch) => {
			dispatch(data.id);

			const command = getCommand("update", entity);
			ShieldData[command](data, { enabled: true })
				.then(model => {
					this.itemUpdated({ datastore: `${entity}s`, model, originalId: data.id });
				}, this.requestFailed);
		};
	}
	disableItem(entity, data) {
		return (dispatch) => {
			dispatch(data.id);

			const command = getCommand("update", entity);
			ShieldData[command](data, { enabled: false })
				.then(model => {
					this.itemUpdated({ datastore: `${entity}s`, model, originalId: data.id });
				}, this.requestFailed);
		};
	}
	itemUpdated(payload) { return payload; }

	removeItem(entity, data) {
		return (dispatch) => {
			dispatch({ entity, id: data.id});

			const command = getCommand("delete", entity);
			ShieldData[command](data)
				.then(() => {
					this.itemRemoved({ entity, id: data.id});
				}, (error, request) => {
					// TODO!: Keep item index when rollbacking
					this.rollbackRemoveItem({ entity, id: data.id });
					this.requestFailed({ error, request });
				});
		};
	}
	itemRemoved(payload) { return payload; }
	rollbackRemoveItem(payload) { return payload};

	addItemEntity(entities, payload) {
		return (dispatch) => {
			dispatch({entities, payload});

			const command = getCommand("add", entities);
			const store = getStore(entities);

			ShieldData[command](payload)
				.then(model => {
					this.itemLoaded({entity: store, model });
				}, this.requestFailed);
		};
	}

	removeItemEntity(entities, payload) {
		return (dispatch) => {
			dispatch({entities, payload});

			const command = getCommand("remove", entities);
			const store = getStore(entities);

			ShieldData[command](payload)
				.then(model => {
					this.itemLoaded({entity: store, model });
				}, this.requestFailed);
		};
	}

	setItemEntityProperty(entities, payload) {
		return (dispatch) => {
			dispatch({entities, payload});

			const command = getCommand("update", entities);
			ShieldData[command](payload)
				.then(model => {
					this.itemLoaded({ entity: entities.parentEntity, model });
				}, this.requestFailed);
		};
	}

	unmount() { return true; }

	requestFailed(error) {
		handleRequestFailed(error);
		return true;
	}
}

export default alt.createActions(Actions);

// Helpers
function getCommand(command, entity, multiple = false) {
	if(typeof(entity) === "object") {
		const extra = multiple ? "s" : "";

		if(entity.parentEntity) {
			return command + entity.parentEntity.substr(0, 1).toUpperCase() + entity.parentEntity.substr(1) + entity.entity.substr(0, 1).toUpperCase() + entity.entity.substr(1) + extra;
		}
		entity = `${entity.entity}${extra}`;
	}
	return command + entity.substr(0, 1).toUpperCase() + entity.substr(1);
}

function getStore(entity, multiple = false) {
	if(typeof(entity) === "object") {
		const extra = multiple ? "s" : "";
		return `${entity.entity}${extra}`;
	}
	return entity;
}
import React from 'react'
import moment from 'moment'
import { useQuery } from '@tanstack/react-query'
import Dialog from '@mui/material/Dialog'

import { getTemplateWithValues, getEntityWithUpdatedValue } from '../../../core/cms/utils'
import EventPlannerComponent from '../../../components/cms/component'
import CustomDragLayer from '../../../components/list/dragLayer'
import withDnDContext from '../../../core/services/withDnDContext'
import { createPatch } from '../../../core/cms/utils'

import * as CMSAPI from '../../../apis/cms'
import appConfig from 'config'
import './generic.css'
import useCMSTemplate from '../../../core/queries/cms/useCMSTemplate'

function GenericModal(props) {
	const { id, template: entity } = props;

	const { data: entityData, refetch: refetchEntity } = useQuery(
		[`generic-modal-${entity}`, { id }],
		() => CMSAPI.fetchCMSEntity({ _entity: entity, id }),
	);
	const template = useCMSTemplate(entity);

	const onChange = React.useCallback(
		async ({ data, path }) => {
			// Update entity locally so that we can generate a JSON-PATCH (rfc6902) based on the differences
			const updatedEntity = getEntityWithUpdatedValue(entityData, path, data);
			const patch = createPatch(entityData, updatedEntity);

			// Update entity in API and then refetch (since update returns 204)
			await CMSAPI.updateCMSEntity({ _entity: entity, id, patch });
			refetchEntity();
		},
		[id, entityData, entity]
	);

	const rootNodes = getTemplateWithValues(template.data?.children ?? [], entityData ?? {});

	return (
		<Dialog
			open={props.open ?? true}
			onClose={props.onClose}
			className="c6-modal generic-modal"
			classes={{
				container: "c6-modal-content",
				paper: "c6-modal-body"
			}}
			maxWidth={false}
		>
			{rootNodes?.map(child => (
				<EventPlannerComponent
					key={child.name}
					templateItem={child}
					onChange={onChange}
				/>
			))}
			<CustomDragLayer />
		</Dialog>
	);
}

class GenericModalDnDWrapper extends React.Component {
	render() {
		return <GenericModal {...this.props} />
	}
}

export default withDnDContext(GenericModalDnDWrapper);
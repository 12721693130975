import React, { Component } from 'react'
import { withRouter } from 'react-router'
import { browserHistory } from 'browserHistory'

import { decoratorUIActions } from '../../../core/decorators/uiActions'

import Items from '../shared/items'

import Store from '../store'

import './app.css'

import itemActions from './itemActions'

@withRouter
@decoratorUIActions(itemActions)
export default class StarSelection extends Component {
    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);


        this.searchRef = null;

        this.state = {
            ...Store.getState(),
        };
    }

    componentDidMount() {
        Store.listen(this.onChange);
    }

    componentWillUpdate(nextProps, nextState) {
        const { selected } = nextState.list;
        const { location } = nextProps;

        // Redirect to the previous view if we have no selected items
        // if(selected.items.length === 0 && location.state && location.state.returnTo) {
        //     const [pathname, search] = location.state.returnTo.split("?");
        //     browserHistory.push({
        //         pathname,
        //         search: search ? `?${search}`: undefined,
        //     });
        // }
    }

    componentWillUnmount() {
        Store.unlisten(this.onChange);
    }

    onChange(state) {
        this.setState(state);
    }

    render() {
        const { selected } = this.state.list;
        return <Items list={selected} setInputRef={el => this.searchRef = el} />;
	}
}
export const deepFindGroup = (groupId, groups = [], callback) => {
	groups.forEach(group => {
        if (group.id === groupId) { callback(group); }
        if (group.children) { deepFindGroup(groupId, group.children, callback) };
    });
}

export const syncDeepFindGroup = (groupId, groups = []) => {
	let group = null;
	for(let i = 0; i < groups.length; i++) {
		group = groups[i];

		if(group.id === groupId) { break; }

        if(group.children) {
			group = syncDeepFindGroup(groupId, group.children);
			if(group && group.id === groupId) { break; }
		};
	}
	return group;
}

export const syncIsGroupInGroups = (groupId, groups) => {
	return syncDeepFindGroup(groupId, groups) !== null;
}

// Returns the uppermost group in a parent.children structure
export const getTopLevelGroup = (groups, currentGroup) => {
	const parentId = currentGroup?.parentGroupId ?? currentGroup?.parentSectionId;
	if (parentId > 0) {
		const parentGroup = syncDeepFindGroup(parentId, groups);
		return getTopLevelGroup(groups, parentGroup);
	}
	return currentGroup;
}

function getGroupName(group) {
	return group?.displayName || group?.name || "";
}

export const getGroupLineage = (group, groups) => {
	const parentGroup = group?.parentSectionId && syncDeepFindGroup(group.parentSectionId, groups);
	if (parentGroup) {
		return `${getGroupLineage(parentGroup, groups)} / ${getGroupName(group)}`
	}
	return `${getGroupName(group)}`;
}
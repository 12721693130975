import React from 'react'
import moment from 'moment'

import Item from '../../../components/cms/item'
import Modals from '../modals/modals'

import * as CMSAPI from '../../../apis/cms'
import useList from '../../../core/hooks/useList'
import { useSessionStorage } from '../../../core/hooks/useStorage'
import GenericListApp from '../../../components/genericApp/listApp'

import { transformFilters, getSeriesOptions, EVENTPLANNER_DATE_FILTER } from '../shared/utils'
import { getValue } from '../../../core/cms/utils'
import { renderTodayOrDate } from '../../../utils/date'
import { useVisibilityState } from '../../../components/cms/visibilityContext'
import { ItemActionsContext, useItemActionsState } from '../../../components/cms/itemActionsContext'
import useCMSTemplate from '../../../core/queries/cms/useCMSTemplate'
import useCMSRegularSeries from '../../../core/queries/cms/useCMSRegularSeries'

import '../events/app.css'

const MODULE = "eventplanner";
const DATASTORE = "entertainment-metadata";
const TEXT_HEADING = "Metadata (Entertainment)";
const TEXT_BACK_BUTTON = null;//"Administration"
const TEXT_EMPTY = null;//"No events? It's about time to create some then!";
const TEXT_CREATE = null;//"Create event";
const ICON_CREATE = null;//"add";
const PATHNAME_CREATE = null;//"eventplanner/cms-events/create";

function ListApp(props) {
	const _entity = `regulareventsmetadatalist`;
	const template = useCMSTemplate(_entity);
	const { data: series } = useCMSRegularSeries();

	const [universalEventPlannerDate, setUniversalEventPlannerDate] = useSessionStorage(EVENTPLANNER_DATE_FILTER, moment().startOf("day").format());
	const filterConfig = {
		">=ott.start": {
			type: "date",
			alwaysVisible: true,
			onChange: (newValue) => {
				// Clear stored value when selecting today
				const isToday = moment(newValue).isSame(moment(), "day");
				setUniversalEventPlannerDate(isToday ? undefined : moment(newValue).startOf("day").format());
			},
		},
		searchText: {
			type: "search",
			alwaysVisible: true,
		},
		"season.series.id": {
			type: "dropdown",
			title: "Series",
			options: getSeriesOptions(series?.items, "Any series"),
			alwaysVisible: true,
		},
		"settings-and": {
			type: "pick",
			title: "Settings",
			options: [
				{ key: "ottCMore", text: "C More" },
				{ key: "ottTv4", text: "TV4" },
				{ key: "ottMtv", text: "MTV" },
				{ key: "linearTelia", text: "Telia" },
				{ key: "linear", text: "Linear" },
			],
		},
		status: {
			type: "switch",
			title: "Status",
			options: [
				{ key: "", text: "Any" },
				{ key: "!metadataReviewed", text: "For review" },
			],
		},
		show: {
			type: "pick",
			title: "Show",
			onlyUI: true,
			options: [
				{ key: "se_sv", text: "Swedish" },
				{ key: "fi_fi", text: "Finnish" },
			],
		},
	};

	const eventIdFromQuery = props.location?.query?.id?.length >= 0 ? props.location.query.id : null;
	let filterDefaults = {
		_entity,
		pageSize: 30,
		orderBy: "ott.start asc",
		">=ott.start": universalEventPlannerDate,
		searchText: "",
		"season.series.id": "",
		"settings-and": "",
		status: "",
		show: "se_sv",
		id: eventIdFromQuery,
	};

	const listState = useList({
		listKey: DATASTORE,
		fetchData: CMSAPI.fetchCMSEntities,
		transformFilters: transformFilters,
		fetchSingle: CMSAPI.fetchCMSEntity,
		patchData: CMSAPI.updateCMSEntity,
		runAction: CMSAPI.runAction,
		entity: _entity,
		updateDataExtraParams: { _entity },
		filterDefaults: filterDefaults,
		filterConfig: filterConfig,
	});

	let event;
	if (eventIdFromQuery) {
		event = listState.data?.pages[0]?.items?.[0];
	}

	// Trigger a fetch when id in query updates
	React.useEffect(
		() => {
			listState.filters.updateMethods["id"](eventIdFromQuery);
		},
		[eventIdFromQuery]
	);

	let heading = TEXT_HEADING;
	if (event) {
		heading = `Showing event "${event.description.name}" in series "${event.description.series}"`;
	}

	const [modalData, setModalData] = React.useState(null);
	const closeModal = React.useCallback(
		() => setModalData(null),
		[setModalData]
	);

	const visibilityState = useVisibilityState(listState.filters.values.show, eventIdFromQuery);

	const actions = useItemActionsState({
		extraActions: [],
		_entity,
		refetch: listState.refetch,
		setModalData,
		collection: template?.data?.collection,
	});

	return (
		<ItemActionsContext.Provider value={actions}>
			<GenericListApp
				{...props}
				className="c6-eventplanner-events"
				module={MODULE}
				datastore={DATASTORE}
				state={listState}
				textHeading={heading}
				textBackButton={TEXT_BACK_BUTTON}
				textEmpty={TEXT_EMPTY}
				textCreate={TEXT_CREATE}
				iconCreate={ICON_CREATE}
				filterConfig={eventIdFromQuery !== null ? {} : filterConfig}
				pathnameCreate={PATHNAME_CREATE}
				collapseFiltersDefault={true}
				groupItemsBy={groupItemsBy}
				getGroupTitle={getGroupTitle}
				sortItemsBy={sortItemsBy}
				groupItemsWhenSearching={true}
				padding={true}
				virtualize={false}
				visibilityState={visibilityState}
			>
				<Item
					template={template.data}
					onChange={listState.onChange}
					openModal={setModalData}
					refreshItem={listState.refreshItem}
				/>
			</GenericListApp>
			<Modals
				modalData={modalData}
				onClose={closeModal}
			/>
		</ItemActionsContext.Provider>
	);
}

export default class EventsMetadataApp extends React.Component {
	render() {
		return (
			<ListApp
				{...this.props}
			/>
		);
	}
}

function groupItemsBy(item) {
	const ottstart = getValue(item, "start", "metadata.info");
	const key = ottstart ? moment(ottstart).format("YYYYMMDD") : "";
	return key;
}

function getGroupTitle(groupKey) {
	return groupKey?.length ? renderTodayOrDate(groupKey, true) : "Missing OTT start";
}

function sortItemsBy(item) {
	const ottstart = getValue(item, "start", "metadata.info");
	const key = ottstart ? moment(ottstart).valueOf() : "";
	return key;
}
import React from 'react'
import PropTypes from 'prop-types'

import ContentItem, { Content } from '../../../../components/list/contentItem'

import styles from '../../../../components/listItems/standard.css'

const ParticipantItem = (props) => {
	const {
		itemData,
	} = props;
	
	const {
		id,
		title,
		externalReference,
		nickNames,
	} = itemData;

	return (
		<ContentItem data={props}>
			<Content>
				<h1>{title}</h1>
				<p>{nickNames?.join(", ")}</p>
			</Content>
		</ContentItem>
	);
};

export default ParticipantItem;
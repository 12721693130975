import { get, put, post, del, patch } from '../core/c6'

const API = "cms";
// const SKIP_TOKEN = true;

export const fetchCMSTemplate 	= ({ api = API, _entity, _module = "eventplanner" }) 				=> get(api, `${_module}/templates/${_entity}`);
export const fetchCMSEntity 	= ({ api = API, _entity, id, _module = "eventplanner" }) 			=> get(api, `${_module}/views/${_entity}/${id}`);
export const fetchCMSEntities 	= ({ api = API, _entity, _module = "eventplanner", ...filters }) 	=> {
	const { pageIndex, pageSize, orderBy, _customFilter, ...otherFilters } = filters;
	return get(
		api,
		`${_module}/views/${_entity}`,
		filters.id !== undefined && filters.id !== null
			? { filter: `id == '${filters.id}'` }
			: {
				pageIndex,
				pageSize,
				orderBy,
				filter: _customFilter ?? convertFiltersToCMSQuery(otherFilters),
			},
	);
};
export const downloadCMSEntities = ({ api = API, _entity, _module = "eventplanner", ...filters}) => {
	const { pageIndex, pageSize, orderBy, fileName, _customFilter, ...otherFilters } = filters;
	const headers = { accept: "application/vnd.ms-excel" };
	return get(
		api,
		`${_module}/views/${_entity}`,
		{
			pageIndex,
			pageSize,
			orderBy,
			fileName,
			filter: _customFilter ?? convertFiltersToCMSQuery(otherFilters),
		},
		false,
		headers,
	);
};
export const createCMSEntity 					= ({ api = API, _entity, _module = "eventplanner", payload }) 							=> post(api, `${_module}/views/${_entity}`, payload);
export const createCMSEntityAndReturnContent 	= ({ api = API, _entity, _module = "eventplanner", payload }) 							=> post(api, `${_module}/views/${_entity}?returncontent=true`, payload);
export const createOrUpdateCMSEntity 			= ({ api = API, _entity, _module = "eventplanner", id, payload }) 						=> put(api, `${_module}/views/${_entity}/${id}?returncontent=true`, payload);
export const deleteCMSEntity 					= ({ api = API, _entity, _module = "eventplanner", id }) 								=> del(api, `${_module}/views/${_entity}/${id}`);
export const updateCMSEntity 					= ({ api = API, _entity, _module = "eventplanner", id, payload, patch: patchPayload }) => patch(api, `${_module}/views/${_entity}/${id}`, patchPayload ?? payload);
export const updateCMSEntityAndReturnContent	= ({ api = API, _entity, _module = "eventplanner", id, payload, patch: patchPayload }) => patch(api, `${_module}/views/${_entity}/${id}?returncontent=true`, patchPayload ?? payload);
export const runAction 							= ({ api = API, _entity, _module = "eventplanner", id, payload }, actionName) 			=> post(api, `${_module}/views/${_entity}/${id}/actions/${actionName}`, payload);

/* ************** Audit ************** */
export const fetchEntityValues	= ({ api = API, _entity, id, _module = "eventplanner", ...filters }) => get(api, `${_module}/${_entity}/${id}/values`, filters);
export const fetchEntityActions = ({ api = API, _entity, id, _module = "eventplanner", ...filters }) => get(api, `${_module}/${_entity}/${id}/actions`, filters);

/* **************** Generic ***************** */
export const fetchCMSUrl    = (url, _module = "eventplanner", api = API)   => get(api, `${[_module, url].filter(f=>f).join('/')}`);
export const fetchUrl       = (url, api = API)                             => get(api, url);


function convertFiltersToCMSQuery(filters) {
	const criterias = Object.entries(filters)
		.filter(([key, value]) => !!value) // skip null/empty
		.map(([key, value]) => convertToCMSFilter(key, value))
		.join(" and ");

	return criterias;
}

function convertToCMSFilter(key, value) {
	switch (key) {
		case "settings-and":
			return value
				.split(",")
				.map(v => `${v}.selected == true`)
				.join(" and ");
		case "settings-or":
			return "("
				+ value.split(",")
					.map(v => `${v}.selected == true`)
					.join(" or ")
				+ ")";
		case "status":
			return value
				.split(",")
				.map(v => {
					const [negate, name] = v.startsWith("!")
						? [true, v.slice(1)]
						: [false, v];
					
					return negate ? `(${name} == null or ${name} == "false")` : `${name} == "true"`;
				})
				.join(" and ");
		case "searchText":
			// TODO: these are specific to eventplanner, should be dynamic
			const attributesToSearch = ["name", "eventgenre.name", "season.series.name", "series.name"];
			return value
				.split(" ")
				.filter(v => v?.length > 0)
				.map(v => {
					const attributeCriterias = attributesToSearch
						.map(a => `${a} contains '${v}'`)
						.join(" or ");
					return `(${attributeCriterias})`;
				})
				.join(" and ");
	}

	const operator = getOperator(key);
	const keyWithoutOperator = key.replace(operator, "");
	const formattedValue = getFormattedValue(value);
	return `${keyWithoutOperator} ${operator} ${formattedValue}`;
}

function getOperator(key) {
	const operator = ["<=", ">=", "<", ">", "!="].find(o => key.startsWith(o));
	return operator ?? "==";
}

function getFormattedValue(value) {
	if (["null", true, false].includes(value)) {
		return value;
	}

	return `'${value}'`;
}
import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { browserHistory } from 'browserHistory'

import { Filter, Left, Right } from '../../../../components/filter'

import Button from '../../../../components/ui/controls/button'
import { isEmpty } from '../../../../utils/misc'

import Actions from '../../actions'

@withRouter
export default class ListFilter extends React.Component {

	static propTypes = {
		conditions: PropTypes.object.isRequired,
		// groups: PropTypes.array,
		selectedGroup: PropTypes.object,
	}

	handleCreate = ({ type = "lists", listType = null }) => {
		const { selectedGroup, location } = this.props;  // location comes from @withRouter
		// debugger;
		// alert("Will add a new section inside the currently selected section.")

		// Actions.createSection(selectedGroup);

		browserHistory.push({
			pathname: `/selections/admin/groups/create`,
			query: {
				parentId: selectedGroup?.id,
				parentDisplayName: selectedGroup?.displayName,
				parentVersion: selectedGroup?.version?.name,
			},
			state: {
				modal: true,
			},
		});

		// Actions.edit({
		// 	pathname: `/selections/ott/${type}/create`,
		// 	search: `?groupId=${selectedGroup.id}`,
		// 	returnTo: `${location.pathname}${location.search}`,
		// 	listType
		// });
	}

	render() {
		const { selectedGroup } = this.props;

		// const listActionsDisabled = !selectedGroup || isEmpty(selectedGroup);
		const title = selectedGroup && !isEmpty(selectedGroup)
			? `New section in "${selectedGroup.displayName}"`
			: "New section" ;

		return (
			<Filter>
				<Left>
					<h1>Sections</h1>
				</Left>
				<Right>
					<Button
						type="add"
						title={title}
						onClick={this.handleCreate}
						// disabled={listActionsDisabled}
					/>
				</Right>
			</Filter>
		);
	}
};
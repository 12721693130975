import { browserHistory } from 'browserHistory'

import { CONTENT, MENU } from '../../../../components/actions'
import EditorNavigationController from '../../../../core/ui/editorNavigationController'

export default {
	targetComponent: "Content",
	actions: [
		{
			title: "Edit",
			icon: "create",
			type: MENU,
			method: (data, sourceProps) => {
				browserHistory.push({
					pathname: `/eventplanner/admin/leagues/${data.actionData.id}/edit`,
					state: {
						modal: true,
						returnTo: `${sourceProps.location.pathname}${sourceProps.location.search}`,
					},
				});

				EditorNavigationController.setCurrentItem(data);
			},
			hidden: (data, sourceProps) => false,
			disabled: (data, sourceProps) => false,
		},
		{
			title: "View arenas",
			icon: "room",
			type: MENU,
			method: (data, sourceProps) => {
				browserHistory.push({
					pathname: `/eventplanner/admin/arenas`,
					query: {
						seriesId: data.id,
					},
				});
			},
			hidden: (data, sourceProps) => false,
			disabled: (data, sourceProps) => true,
		},
		{
			title: "View teams/individuals",
			icon: "people",
			type: MENU,
			method: (data, sourceProps) => {
				browserHistory.push({
					pathname: `/eventplanner/admin/participants`,
					query: {
						seriesId: data.actionData.id,
					},
				});
			},
			hidden: (data, sourceProps) => false,
			disabled: (data, sourceProps) => false,
		},
	]
}
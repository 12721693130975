import { browserHistory } from 'browserHistory'

import { CONTENT, MENU } from '../../../components/actions'

import * as ReviewsAPI from '../../../apis/reviews'
import { updateItemInMatchingQueries } from '../../../core/hooks/useList'

export default {
	targetComponent: "Content",
	actions: [
		{
			title: "Edit",
			type: CONTENT,
			method: (data, sourceProps) => {
				const customerRoute = sourceProps.route.customer ? `${sourceProps.route.customer}/` : "";
				browserHistory.push({
					pathname: `/reviews/${customerRoute}reviews/${data.id}/edit`,
					state: {
						modal: true,
						returnTo: `${sourceProps.location.pathname}${sourceProps.location.search}`,
					},
				});
			},
			hidden: (data, sourceProps) => false,
			disabled: (data, sourceProps) => false,
		},
		{
			title: "Edit",
			icon: "create",
			type: MENU,
			method: (data, sourceProps) => {
				const customerRoute = sourceProps.route.customer ? `${sourceProps.route.customer}/` : "";
				browserHistory.push({
					pathname: `/reviews/${customerRoute}reviews/${data.id}/edit`,
					state: {
						modal: true,
						returnTo: `${sourceProps.location.pathname}${sourceProps.location.search}`,
					},
				});
			},
			hidden: (data, sourceProps) => false,
			disabled: (data, sourceProps) => false,
		},
		{
			title: "Edit program",
			icon: "create",
			type: MENU,
			method: (data, sourceProps) => {
				const customerRoute = sourceProps.route.customer ? `${sourceProps.route.customer}/` : "";
				browserHistory.push({
					pathname: `/reviews/${customerRoute}programs/${data.programId}/edit`,
					state: {
						modal: true,
						returnTo: `${sourceProps.location.pathname}${sourceProps.location.search}`,
					},
				});
			},
			hidden: (data, sourceProps) => false,
			disabled: (data, sourceProps) => false,
		},
		{
			title: "Delete",
			icon: "delete",
			type: MENU,
			method: async (data) => {
				await ReviewsAPI.deleteReview(data);
				updateItemInMatchingQueries({
					store: "reviews",
					item: data,
					isDelete: true,
				});
			},
			hidden: (data, sourceProps) => false,
			disabled: (data, sourceProps) => false,
			confirm: true,
		},
	]
}
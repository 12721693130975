import React from 'react'
import debounce from 'lodash/debounce'
import { ScrollContainer } from 'react-router-scroll'

import { getRouteName } from '../../../components/comet'
import App from '../../../components/app'
import { Info } from '../../../components/comet'
import Main from '../../../components/ui/main'

import Header from './header'
import List from './list'

import Actions from '../actions'
import Store from '../store'

import { getSearchPayload } from '../utils'

import './app.css'

import appConfig from 'config'

const MODULE = "traffic"
const DATASTORE = "programs"

export default class ProgramsListApp extends React.Component {

	constructor(props) {
		super(props);

		this.debouncedFilter = debounce(this.handleFilter, 500);

		this.scroll = null;

		this.state = {
			...Store.getState(),
			searchText: "",
			unselectedFilter: false,
		};
	}

	componentDidMount() {
		Store.listen(this.onChange);
		const l = this.state.list[DATASTORE];
		const payload = getSearchPayload(this.state, l.filters);
		Actions.fetchItems(DATASTORE, payload);

		const exposuretypes = this.state.list.exposuretypes;
		Actions.fetchItems("exposuretypes", exposuretypes.filters);
		
		const distributors = this.state.list.distributors;
		Actions.fetchItems("distributors", distributors.filters);

		const languages = this.state.list.languages;
		Actions.fetchItems("languages", languages.filters);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
	}

	componentWillUnmount() {
		Actions.unmount();
		Store.unlisten(this.onChange);
	}

	onChange = (state) => {
		this.setState(state);
	}

	onFilter = (type, e) => {
		if (type === "search") {
			e.persist();
			this.debouncedFilter(type, e);
		}
		else {
			this.handleFilter(type, e);
		}
	}

	handleFilter = (type, event) => {
		if (this.scroll) {
			this.scroll.scrollTop = 0; // Scroll list to top to prevent automatic paging
		}

		const { name, value } = event.target;
		Actions[type](value, name);
		const l = this.state.list[DATASTORE];
		const payload = getSearchPayload(this.state, l.filters);
		Actions.fetchItems(DATASTORE, payload);

		this.setState({
			searchText: type !== "search" ? "" : value,
			unselectedFilter: type !== "search" ? false : value.length > 0,
		});

		// Force lazyload to load visible images
		window.dispatchEvent(window.customLazyEvent);
	}

	loadMore = () => {
		const l = this.state.list[DATASTORE];
		if (l.nextPageUrl) {
			Actions.pageItems(DATASTORE, l.nextPageUrl);
		}
	}

	shouldUpdateScroll(prevRouterProps, currentRouterProps) {
		return currentRouterProps.location.action === "POP";
	}

	render() {
		const list = this.state.list[DATASTORE];
		
		const exposuretype = this.state.list.exposuretypes.items
		const distributor = this.state.list.distributors.items
		const languages = this.state.list.languages.items
		const { isLoading, searchText: componentSearchText, filters, nextPageUrl, unselectedFilter } = this.state;
		const searchText = componentSearchText || filters.searchText; // TODO!: We should probably only have one searchText since this will make people go mad.
		

		return (
			<App
				name={`c6-${MODULE}-${DATASTORE} c6-list`}
				layout="grid"
				isLoading={isLoading || list.isLoading}
			>
				<Header
					searchText={searchText}
					filters={filters}
					unselectedFilters={unselectedFilter || !!searchText.length}
					handleFilter={this.onFilter}
					exposureTypes={exposuretype}
					distroTypes={distributor}
					isLoading={this.state.excelLoading}
				/>
				<ScrollContainer scrollKey={`c6-${MODULE}-${DATASTORE}`} shouldUpdateScroll={this.shouldUpdateScroll}>
					<Main inputRef={el => this.scroll = el} padding={searchText?.length}>
						<List
							items={list.items}
							isLoading={isLoading || list.isLoading}
							searchText={searchText}
							filters={filters}
							hasMore={!!list.nextPageUrl}
							loadMore={this.loadMore}
							languages={languages}
							handleFilter={this.onFilter}
						/>
					</Main>
				</ScrollContainer>
				<Info>
					{getInfo(this.props.location, list)}
				</Info>
			</App>
		);
	}
}

// HELPERS
function getInfo(location, list) {
	const items = list.items.length ? `- displaying ${list.items.length} of ${list.numberOfItems}` : "";

	return `${getRouteName(location)} ${items}`;
}
import React from 'react'

import Program from './program'
import itemActions from './itemActions'

import * as API from '../../../apis/reviews'
import useListState from '../../../core/hooks/useList'
import GenericListApp from '../../../components/genericApp/listApp'
import { decoratorUIActions } from '../../../core/decorators/uiActions'
import { getLanguages } from '../utils'

import './programs.css'
import useReviewsCustomers from '../../../core/queries/reviews/useReviewsCustomers'
import useReviewsLanguages from '../../../core/queries/reviews/useReviewsLanguages'

const MODULE = "reviews";
const DATASTORE = "programs";
const TEXT_HEADING = "Programs";

const HIGH_PRIORITY_SOURCES = "_highPriority";
const MISSING_REVIEWS = "_missingreviews";

function ListApp(props) {
	const customer = props.route?.customer;
	const { data: customers } = useReviewsCustomers({
		enabled: !customer,
	});
	const { data: languagesData } = useReviewsLanguages({
		filter: { orderBy: "name", pageSize: 1000, onlyLanguagesWithSources: true },
		enabled: !customer,
	});

	const languages = getLanguages(customer, languagesData);

	const customerFilterConfig = {
		
	};

	const noCustomerFilterConfig = {
		customerFilterNew: {
			type: "dropdown",
			title: "Customer",
			options: [
				{ key: null, text: "Any" },
				...customers?.items?.map(c => ({
					key: c.name,
					text: c.name,
				})) ?? [],
			],
		},
	};
	
	const filterConfig = {
		searchText: {
			type: "search",
			alwaysVisible: true,
		},
		programTypes: {
			type: "switch",
			title: "Type",
			options: [
				{ key: "", text: "Any" },
				{ key: "single", text: "Movies" },
				{ key: "season", text: "Series" },
			],
		},
		...(customer ? customerFilterConfig : noCustomerFilterConfig),
		filter: {
			type: "pick",
			title: "Missing",
			options: [
				{ key: "missingimdb", text: "IMDb" },
				{ key: MISSING_REVIEWS, text: "Reviews (<3)" },
				{ key: "missingseasonnumber", text: "Season number" },
			],
		},
		language: {
			type: "dropdown",
			title: "In",
			options: [
				{ key: "", text: "Any language" },
				...languages,
			],
			disabled: (filters) => !filters.filter?.includes(MISSING_REVIEWS),
		},
		sourceId: {
			type: "dropdown",
			title: "From",
			options: [
				{ key: null, text: "Any source" },
				{ key: HIGH_PRIORITY_SOURCES, text: "High priority sources" },
			],
			disabled: (filters) => !filters.filter?.includes(MISSING_REVIEWS),
		},
	};

	let customerFilters = {};
	if (customer) {
		customerFilters = {
			customer,
			programTypes: "",
			orderBy: "customerpublishdatedescending",
			language: languages[0]?.key ?? "",
			filter: MISSING_REVIEWS,
		};
	}
	const FILTER_DEFAULTS = {
		searchText: "",
		includeReviews: true,
		customerFilterNew: null,
		customer: null,
		maxNumberOfReviews: null,
		programTypes: "",
		sourceId: null,
		orderBy: null,
		language: "",
		filter: "",

		...customerFilters,
	};

	const listState = useListState({
		listKey: DATASTORE,
		fetchData: API.fetchPrograms,
		filterDefaults: FILTER_DEFAULTS,
		transformFilters: transformFilters,
	});

	// Trigger a fetch when customer updates
	React.useEffect(
		() => listState.filters.reset(),
		[customer]
	);

	return (
		<GenericListApp
			{...props}
			module={MODULE}
			datastore={DATASTORE}
			state={listState}
			textHeading={TEXT_HEADING}
			textEmpty={customer ? null : "No programs? It's about time to create some then!"}
			textCreate={customer ? null : "Create program"}
			iconCreate={customer ? null : "add"}
			pathnameCreate={customer ? null : "/reviews/programs/create"}
			filterConfig={filterConfig}
			collapseFiltersDefault={false}
			showInfoBar={true}
		>
			<Program customer={customer} location={props.location} />
		</GenericListApp>
	);
}

@decoratorUIActions(itemActions)
export default class ProgramsApp extends React.Component {
	render() {
		return (
			<ListApp
				{...this.props}
			/>
		);
	}
}

function transformFilters(filters) {
	const transformed = { ...filters };

	if (filters.sourceId === HIGH_PRIORITY_SOURCES) {
		delete transformed.sourceId;
		transformed.minimumSourceRank = "70";
	}

	if (filters.filter?.includes(MISSING_REVIEWS)) {
		transformed.filter = transformed.filter.replace(`,${MISSING_REVIEWS}`, "").replace(`${MISSING_REVIEWS},`, "").replace(MISSING_REVIEWS, "");
		transformed.maxNumberOfReviews = 3;
	}

	return transformed;
}
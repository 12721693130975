import React, { Component } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import MuiButton from '@mui/material/Button'

import Actions from '../actions'
import Store from '../store'

import List from './list'
import Scroll from '../../../components/scroll'
import Button from '../../../components/ui/controls/button'
import LinksToSectionsContainingList from '../shared/linksToSectionsContainingList'

import dialogStyles from '../../../dialogs/dialogs.css'
import previewDialogStyles from './previewDialog.css'

export default class PreviewDialog extends Component {

	state = {
		...Store.getState(),
	}

	componentDidMount() {
		Store.listen(this.onChange);

		const listId = this.props.list?.id;
		if (listId >= 0) {
			Actions.fetchItems("listItems", { listId }, { targetStore: "previewListItems" });

			Actions.fetchItems("groups", { listId }, { targetStore: "sectionsContainingList" });
			if (!this.state.list.sections.items.length) {
				Actions.fetchItems("groups", {}, { targetStore: "sections" });
			}
		}
	}

	componentWillUnmount() {
		Store.unlisten(this.onChange);
	}

	onChange = (state) => {
		this.setState(state);
	}

	handleClose = () => {
		this.props.onClose();
	}

	handleDeleteList = () => {
		SelectionsActions.deleteList(this.props.list, this.handleClose);
	}

	renderHeaderContent = () => {
		const { list } = this.props;
		const { sectionsContainingList, sections } = this.state.list;

		return (
			<div>
				<h1>
					{list.displayName ?? list.name}
					{list.category?.name !== "genre" && (
						<Button
							type="delete"
							title="Delete list"
							onClick={this.handleDeleteList}
							confirm="Delete this list?"
							dangerous={true}
						/>
					)}
				</h1>
				<p>Reference: {list.reference}</p>
				<LinksToSectionsContainingList
					currentSection={null}
					sectionsContainingList={sectionsContainingList}
					allSections={sections.items}
					onLinkClick={this.handleClose}
				/>
			</div>
		);
	}

	renderContent = () => {
		const { list } = this.props;
		const { previewListItems } = this.state.list;

		if (previewListItems.isLoading) {
			return <p>Loading items...</p>;
		}
		if (!previewListItems.items.length) {
			return <p>This list is empty!</p>;
		}
		
		return (
			<Scroll nopadding={true} inDialog={true}>
				<List
					isLoading={previewListItems.isLoading}
					items={previewListItems.items}
					list={list}
				/>
			</Scroll>
		);
	}

	render() {
		return (
			<Dialog
				open={this.props.open}
				onClose={this.handleClose}
				className="c6-modal c6-selections-preview-dialog"
				classes={{
					container: "c6-modal-content",
					paper: "c6-modal-body",
				}}
				maxWidth="sm"
				fullWidth={true}
			>
				<DialogContent>
					<div className="c6-dialog-header">
						{this.renderHeaderContent()}
					</div>
					{this.renderContent()}
				</DialogContent>
				<DialogActions>
					<MuiButton onClick={this.handleClose}>Close</MuiButton>
				</DialogActions>
			</Dialog>
		);
	}
}
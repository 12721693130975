import React from 'react'

import Item from './item'
import itemActions from './listItemActions'

import * as VodCoreAPI from '../../../../apis/vodcore'
import useList from '../../../../core/hooks/useList'
import GenericListApp from '../../../../components/genericApp/listApp'
import { decoratorUIActions } from '../../../../core/decorators/uiActions'
import { sortServicesAndOperators } from '../../shared/utils'
import useVodCoreOperators from '../../../../core/queries/vodcore/useVodCoreOperators'

const MODULE = "vod";
const DATASTORE = "admin-services";
const TEXT_HEADING = "Services";
const TEXT_BACK_BUTTON = "Administration";
const TEXT_EMPTY = "No services? It's about time to create some then!";
const TEXT_CREATE = "Create service";
const ICON_CREATE = "add";
const PATHNAME_CREATE = "vod/admin/services/create";

function ListApp(props) {

	const { data: operators } = useVodCoreOperators({
		filter: { orderBy: "name" },
	})

	const FILTER_DEFAULTS = {
		searchText: "",
		operatorId: null,
		status: "",
		orderBy: "name",
	};

	const filterConfig = {
		searchText: {
			type: "search",
			alwaysVisible: true,
		},
		operatorId: {
			type: "dropdown",
			title: "Operator",
			options: [
				{ key: null, text: "Any" },
				...operators?.items?.sort(sortServicesAndOperators).filter(o => o.name !== "Undefined").map(o => ({
					key: o.id,
					text: (o.displayName ?? o.name),
				})) ?? []
			],
		},
		status: {
			type: "switch",
			title: "Status",
			options: [
				{ key: "", text: "Any" },
				{ key: "enabled", text: "Active" },
			],
		},
	};

	const listState = useList({
		listKey: DATASTORE,
		fetchData: VodCoreAPI.fetchServices,
		filterDefaults: FILTER_DEFAULTS,
	});

	return (
		<GenericListApp
			{...props}
			module={MODULE}
			datastore={DATASTORE}
			state={listState}
			textHeading={TEXT_HEADING}
			textBackButton={TEXT_BACK_BUTTON}
			textEmpty={TEXT_EMPTY}
			textCreate={TEXT_CREATE}
			iconCreate={ICON_CREATE}
			filterConfig={filterConfig}
			pathnameCreate={PATHNAME_CREATE}
			collapseFiltersDefault={false}
		>
			<Item />
		</GenericListApp>
	);
}

@decoratorUIActions(itemActions)
export default class ServicesApp extends React.Component {
	render() {
		return (
			<ListApp
				{...this.props}
			/>
		);
	}
}
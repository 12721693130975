import React from 'react'

import Item from './item'
import itemActions from './listItemActions'

import * as API from '../../../../apis/sport'
import useList from '../../../../core/hooks/useList'
import GenericListApp from '../../../../components/genericApp/listApp'
import { decoratorUIActions } from '../../../../core/decorators/uiActions'
import useEditorNavigation from '../../../../core/hooks/useEditorNavigation'
import useSportGenre from '../../../../core/queries/sport/useSportGenre'
import useSportGenres from '../../../../core/queries/sport/useSportGenres'

const MODULE = "eventplanner";
const DATASTORE = "series";
const TEXT_HEADING = "Leagues";
const TEXT_BACK_BUTTON = "Administration";
const TEXT_EMPTY = "No leagues? It's about time to create some then!";
const TEXT_CREATE = "Create league";
const ICON_CREATE = "add";
const PATHNAME_CREATE = "/eventplanner/admin/leagues/create";

function ListApp(props) {
	const genreIdFromQuery = props.location?.query?.genreId >= 0 ? props.location.query.genreId : null;

	const { data: genre } = useSportGenre(genreIdFromQuery);
	const { data: genres } = useSportGenres(genreIdFromQuery === null);

	const filterDefaults = {
		searchText: "",
		type: "league",
		seriesGenreId: genreIdFromQuery,
	};

	const filterConfig = {
		searchText: {
			type: "search",
		},
	};

	if (genreIdFromQuery === null) {
		filterConfig.seriesGenreId = {
			type: "dropdown",
			title: "Sport",
			options: [
				{ key: null, text: "Any" },
				...genres?.items?.map(g => ({
					...g,
					key: g.id,
					text: g.title,
					description: "",
				})) ?? [],
			],
		}
	}

	const listState = useList({
		listKey: DATASTORE,
		fetchData: API.fetchSeriesList,
		filterDefaults: filterDefaults,
	});

	useEditorNavigation(listState);

	return (
		<GenericListApp
			{...props}
			module={MODULE}
			datastore={DATASTORE}
			state={listState}
			textHeading={genre ? `${TEXT_HEADING} in sport "${genre?.title ?? genre?.name}"` : TEXT_HEADING}
			textBackButton={TEXT_BACK_BUTTON}
			textEmpty={TEXT_EMPTY}
			textCreate={TEXT_CREATE}
			iconCreate={ICON_CREATE}
			filterConfig={genre ? {} : filterConfig}
			pathnameCreate={PATHNAME_CREATE}
			collapseFiltersDefault={false}
			showInfoBar={true}
		>
			<Item />
		</GenericListApp>
	);
}

@decoratorUIActions(itemActions)
export default class LeaguesApp extends React.Component {
	render() {
		return (
			<ListApp
				{...this.props}
			/>
		);
	}
}
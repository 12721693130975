import React from 'react'
import PropTypes from 'prop-types'

import {decoratorUIActions} from '../../../../core/decorators/uiActions'
import {Todos, Todo} from '../../../../components/ui/todos'

import { DroppableItemGroup } from '../../../../components/list/listItems'
import SortableList from '../../../../components/list/sortableList'
import Empty from '../../../../components/list/empty'

import Item from './listItem'

import Actions from '../../actions'
import itemActions from './listItemActions'
import ListConstants from '../constants'

const DATASTORE = "lists";
const LIST_INACTIVE = "listInactive";

@SortableList({
	onItemMoved: ({ payload, originalItem, targetType, sourceProps }) => {
		const sectionId = sourceProps.conditions?.sectionId;
		const areaId = sourceProps.areas?.items.find(a => a.name === targetType)?.id ?? originalItem.areaId;
		payload.active = targetType !== LIST_INACTIVE;
		Actions.moveListInGroup({ payload, originalItem, sectionId, areaId });
	},
	generateNewItem: ({ payload, droppedItem, targetType }) => {
		console.log("droppedItem", droppedItem);
		const { referenceId } = droppedItem;
		if (referenceId) {
			if (referenceId.indexOf("discoveryprogram") >= 0) {
				payload.isProgram = true;
			}
			payload.referenceId = referenceId.split("-")[1];
		}

		return {
			...droppedItem.data,
			...payload,
			itemType: droppedItem.data.type,
			active: targetType !== LIST_INACTIVE,
		};
	},
	onItemAdded: ({ payload, sourceProps, targetType }) => {
		const { selectedGroup, conditions, selectionsConfiguration } = sourceProps;
		const { isProgram, ...rest } = payload;
		const areaId = targetType === LIST_INACTIVE
			? 1
			: sourceProps.areas?.items.find(a => a.name === targetType)?.id ?? 1;

		const requestPayload = {
			store: DATASTORE,
			section: selectedGroup,
			payload: rest,
			filters: conditions,
			areaId,
			area: targetType === LIST_INACTIVE ? "main" : targetType,
			active: targetType !== LIST_INACTIVE,
		};

		if (isProgram) { // PROGRAM
			Actions.createFeatureAndAddToGroupV2(requestPayload, selectionsConfiguration);
		} else { // LIST
			const listCategoryName = payload.category.name;
			const shouldCreateNewList = !["information-box", "widget"].includes(listCategoryName);
			if (shouldCreateNewList) {
				Actions.addListToGroup(requestPayload);
			} else {
				Actions.moveListInGroup({ payload, originalItem: null, sectionId: conditions.sectionId, areaId })
			}
		}
	},
})
@decoratorUIActions(itemActions)
export default class List extends React.Component {

	static propTypes = {
		items: PropTypes.array.isRequired,
		conditions: PropTypes.object,
		selectedGroup: PropTypes.object,
		areas: PropTypes.object,
	}

	state = {
		isDraggingItem: false,
	}

	handleDragStateChanged = (isDraggingItem) => {
		this.setState({ isDraggingItem });
	}

	render() {
		const {
			isLoading,
			areas,
			sectionAssets,

			// Injected by the SortableList HOC ReactDnD
			connectDropTarget,
			isOver,

			// Injected by the SortableList HOC
			items,
			handleItemDrop,
			handleTargetHover,
		} = this.props;

		if (areas.isLoading || areas.items.length === 0) {
			return (
				<div style={{ height: "100%" }}>
					<Empty v2={true} title={areas.isLoading ? "Loading areas..." : "No areas available"}></Empty>;
				</div>
			);
		}

		if (items.length) {
			const inactiveItems = items.filter(i => (!i.active && !i.isOver || i.isOver === LIST_INACTIVE));
			const isDraggingClass = this.state.isDraggingItem ? " is-dragging-item" : "";

			return (
				<div className={isDraggingClass}>
					{areas.items.map(area => {
						const areaItems = items.filter(i => (i.active && i.areaId === area.id && !i.isOver || i.isOver === area.name));
						return (
							<DroppableItemGroup
								key={area.id}
								type={area.name}
								title={areas.items.length > 1 ? `Area: ${area.name}` : null}
								onTargetHover={handleTargetHover}
								onItemDrop={handleItemDrop}
								useCustomScrollWhileDragging={true}
							>
								{this.renderItems(
									areaItems,
									areas.items.length > 1 ? `There are no selections in the "${area.name}" area.` : "There are no active selections.",
									sectionAssets,
								)}
							</DroppableItemGroup>
						);
					})}

					<DroppableItemGroup
						type={LIST_INACTIVE}
						title="Inactive"
						onTargetHover={handleTargetHover}
						onItemDrop={handleItemDrop}
						useCustomScrollWhileDragging={true}
					>
						{this.renderItems(
							inactiveItems,
							"There are no inactive selections.",
							sectionAssets,
						)}
					</DroppableItemGroup>
				</div>
			);
		}

		return connectDropTarget(
			<div style={{height: "100%"}}>
				<Empty v2={true} title="No selections found">Add some by dragging from the right or by using the buttons above.</Empty>
			</div>
		);
  	}

	renderItems = (items, noItemsFoundText, sectionAssets) => {
		const {
			selectedGroup,

			// Injected by the SortableList HOC
			findItem,
			moveItem,
			handleOutsideDrop,
			handleItemManipulation,
		} = this.props;


		if (!items.length) {
			return (
				<div style={{height: "100%"}}>
					<Empty v2={true} title="">{noItemsFoundText}</Empty>
				</div>
			);
		}

		return items.map(item => {
			// const title = item.autoGenerated ? `${item.displayName} (Automatic)` : item.displayName;
			// const todos = getTodos(item, selectedGroup);

			return (
				<Item
					key={item.id}
					id={item.id}
					item={item}
					selectedGroup={selectedGroup}
					assets={sectionAssets
						? sectionAssets[item.id] ?? []
						: null}

					// DND
					isPlaceholder={item.isPlaceholder || !!item.isOver}
					findItem={findItem}
					moveItem={moveItem}
					handleOutsideDrop={handleOutsideDrop}
					handleItemManipulation={handleItemManipulation}
					onDragStateChanged={this.handleDragStateChanged}
				/>
			);

			// return (
			// 	<SortableItem
			// 		key={item.id}
			// 		id={item.id}
			// 		handleType="icon"
			// 		actionData={item}

			// 		isPlaceholder={item.isPlaceholder || !!item.isOver}
			// 		moveItem={moveItem}
			// 		onItemDropOutside={handleOutsideDrop}
            //       	findItem={findItem}
			// 		insertCard={handleItemManipulation}

			// 		onDragStart={this.handleDragStart}
			// 		onDragEnd={this.handleDragEnd}>

			// 		<ItemContent
			// 			{...item}
			// 			title={title}
			// 			selectedGroup={selectedGroup}
			// 		/>
			// 		{todos}
			// 	</SortableItem>
			// );
		});
	}
}

// HELPERS
function getTodos(item, selectedGroup) {
	if (item?.isPlaceholder) {
		return null;
	}

	const ALL_VERSIONS = 0;

	const groupVersionId = selectedGroup?.version?.id ?? ALL_VERSIONS;
	const listVersionId = item && item.version && item.version.id;

	let todos = [];
	// Show all unpublished localizations if the group has all versions
	if (groupVersionId === ALL_VERSIONS && item) {
		if (item.localizations?.[0]?.version.name) {
			todos = item.localizations.filter(l => !l.active);
		} else {
			const availableVersions = ListConstants.VERSIONS || [];
			todos = availableVersions.map(({id, name}) => ({ version: { id, name } }));
		}
	}

	// If the list has a specific version, show the missing localization
	if (listVersionId !== ALL_VERSIONS && item) {
		if (item.localizations?.[0]?.version.name) {
			todos = item.localizations.filter(l => l.version.id === listVersionId && !l.active);
		} else {
			const availableVersions = ListConstants.VERSIONS || [];
			const { id, name } = availableVersions.filter(v => v.id === listVersionId)[0];
			todos = [{ version: { id, name } }];
		}
	}

	if (!todos.length) {
		return null;
	}

	return <Todos label="Not published">{todos.map(todo => <Todo key={todo.version.id}>{todo.version.name}</Todo>)}</Todos>;
}
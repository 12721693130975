import React, { Component } from 'react'
import { withRouter } from 'react-router'
import { browserHistory } from 'browserHistory'
import debounce from 'lodash/debounce'

import { decoratorUIActions } from '../../../core/decorators/uiActions'

import Items from '../shared/items'

import Actions from '../actions'
import Store from '../store'

const DATASTORE = "searchItems";
const RECENT_KEY = "star-recentSearches";

import itemActions from './itemActions'
@withRouter
@decoratorUIActions(itemActions)
export default class StarSearch extends Component {
    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);
        this.handleLoadMore = this.handleLoadMore.bind(this);
        this.debouncedAddToRecentSearches = debounce(this.addToRecentSearches, 2000); // HACK: When we move debouncing to the search component itself, we can move the recent searches logic there aswell

        const { params, location } = this.props;
        this.searchText = params.searchText || "";
        this.typeFilter = location.query.type || "";
		this.tagsFilter = location.query.tags || "";
        this.searchRef = null;

        this.state = {
            ...Store.getState(),
        };
    }

    componentDidMount() {
        Store.listen(this.onChange);

        if(this.searchText) {
            const { searchItems } = this.state.list;
            const { latestSearchPayload, filters } = searchItems;

            const payload = {
                searchText: this.searchText,
                type: this.typeFilter,
				tags: this.tagsFilter,
                ...filters,
            };

			console.log("payload", payload);

            // Don't do a new search if the params havent changed
            const { searchText: latestSearchText, type: latestType, tags: latestTags } = latestSearchPayload || {};

            if(latestSearchText !== payload.searchText || latestType !== payload.type || latestTags !== payload.tags) {
                Actions.fetchItems(DATASTORE, payload);
                this.debouncedAddToRecentSearches(this.searchText);
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { searchText = "" } = this.props.params;
        const { state, query, pathname } = this.props.location;
        const typeFilter = query.type || "";
		const tagsFilter = query.tags || "";

        // debugger;

        // If we have opened a modal, we don't want to mess with the background components
        if (state && state.modal) {
            return true;
        }

		if (searchText !== this.searchText || typeFilter !== this.typeFilter || tagsFilter !== this.tagsFilter) {
			this.searchText = searchText;
			this.typeFilter = typeFilter;
			this.tagsFilter = tagsFilter;

			if (this.searchText) {
				const { list } = this.state;

				Actions.fetchItems(DATASTORE, {
					searchText: this.searchText,
					type: this.typeFilter,
					tags: this.tagsFilter,
					...list.searchItems.filters,
				});

				this.debouncedAddToRecentSearches(this.searchText);
			}

			if (this.searchRef) {
				this.searchRef.scrollTop = 0;
			}
		}

		if (!searchText?.length && prevProps.params.searchText?.length > 0) {
            Actions.clearSearch();
            // browserHistory.push({  pathname: "/star2" }); // Go back to dashboard view
		}
    }

    componentWillUnmount() {
        this.debouncedAddToRecentSearches.cancel();
        Store.unlisten(this.onChange);
    }

    onChange(state) {
        this.setState(state);
    }

    handleLoadMore() {
        const nextPageUrl = this.state.list.searchItems.nextPageUrl;
		if(nextPageUrl) {
			Actions.pageItems(DATASTORE, nextPageUrl);
		}
	}

    render() {
        const { searchItems, selected } = this.state.list;

        return (
            <Items
                searchText={this.searchText}
                list={searchItems}
                selected={selected}
                loadMore={this.handleLoadMore}
                setInputRef={el => this.searchRef = el}
                masonry={false}
            />
		);
	}

	addToRecentSearches(searchText) {
        const st = searchText.trim();

        if(st.length) {
            let recentSearches = localStorage.getItem(RECENT_KEY) || "";
            recentSearches = recentSearches.split("|");

            recentSearches = recentSearches.filter(s => s.trim() !== st); // Remove old entry that is the same
		    recentSearches.unshift(st); // Add new search to start of array
            recentSearches = recentSearches.slice(0, 20); // Limit saved searches to 20 items

            localStorage.setItem(RECENT_KEY, recentSearches.join("|"));
        }
    }
}
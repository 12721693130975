import React from 'react'
import Broadcasts from './broadcasts'

import Button from '../../../components/ui/controls/button'
import Actions from '../actions'

import appConfig from 'config'
// import channelLogos from '../shared/channels.svg'
// import channelLogos from '!!file?name=channels.svg!../shared/channels.svg'

export default ({
	channel: channelBroadcasts,
	renderChannelName = false,
	channelDisplayName,
	group,
	noBroadcastsText = "Inga visningar.",
	children,
	excelDownloadSites,
	vodPlatform,
	vodType,
 }) => {
	const { channel, broadcasts } = channelBroadcasts;
	const { name, displayName } = channel;
	let lastAvailableDayTitle = "";
	let isVodViews = false;

	if (channel.name.includes("tv4play") || channel.name === "cmore-se") {
		lastAvailableDayTitle = "Sista chansen";
		isVodViews = true;
	}
	
	return (
		<div className="c6-schedule-channel">
			<h1 className={`channel-logo channel-${name?.toLowerCase()}`}>
				{renderChannelName && (channelDisplayName ?? displayName ?? name)}
				{isVodViews && (
					<Button
						type="file-excel"
						noBackground
						title=""
						hoverTitle="Ladda ned kommande fem veckor som Excel"
						style={{ marginLeft: "auto" }}
						onClick={() => handleExportButtonClick(name, vodPlatform, excelDownloadSites, vodType)} 
					/>
				)}
			</h1>
			
			{lastAvailableDayTitle && broadcasts?.length > 0 && <h5 className="vod-package-last-day">{lastAvailableDayTitle}</h5>}
			{broadcasts?.length
				? <Broadcasts broadcasts={broadcasts} group={group}>{children}</Broadcasts>
				: <p>{noBroadcastsText}</p>
			}
		</div>
	);
};

//<h1 className={`channel-logo channel-${channel.channel.name}`}>
// <svg height="25"><use xlinkHref={`/channels.svg#${channel.channel.name}`}></use></svg>

{/* <h1>
			<svg><use width="50" height="50" href={`/channels.svg#${channel.channel.name}`}></use></svg> */}
function handleExportButtonClick(channelName, vodPlatform, sites, type) {
	// #TempHackForTv4InternalSchedule
	const isTv4Play = channelName.includes("tv4play");
	Actions.exportVodPackageExcel({
		sites: isTv4Play ? sites : null,
		type: isTv4Play ? type : null,
		channel: isTv4Play ? null : channelName,
		platform: isTv4Play ? null : vodPlatform,
		apikey: appConfig.api.contentKey || "",
	}, isTv4Play);
}
import React from 'react'

import Editor, { parseUi, withQueryClient } from '../../../../components/editor/index'
import { hasAccessToPath } from '../../../../core/services/auth'

import * as SportAPI from '../../../../apis/sport'

import schema from './schema'
import uiSchema from './uiSchema'
import useSportSeriesList from '../../../../core/queries/sport/useSportSeriesList'
import useSportGenres from '../../../../core/queries/sport/useSportGenres'

const LeagueEditor = props => {

	const { data: leagues } = useSportSeriesList();
	const { data: genres } = useSportGenres();

	return (
		<Editor
			layout="grid"
			api="sport"
			entity="genre"
			getSchema={getSchema.bind(this, leagues, genres)}
			getUiSchema={getUiSchema}
			getCommands={getCommands}
			savePayloadTransform={savePayloadTransform}
			hasEditAccess={hasAccessToPath(props.routes, "editor")}
			enableEditorNavigation={true}
			{...props}
		/>
	);
};

export default withQueryClient(LeagueEditor);

function getCommands() {
	return {
		// fetchItem: SportAPI.fetchSeries,
		// createItem: SportAPI.createSeries,
		// updateItem: SportAPI.updateSeries,
	};
}

function getSchema(leagues, genres, model, isNew, location, route, params) {
	if (!leagues?.items?.length || !genres?.items?.length) {
		return { type: "object", properties: {} };
	}

	schema.properties.parent.properties.id.oneOf = leagues.items.map(league => ({
		const: league.id,
		title: league.title ?? league.name,
	}));

	schema.properties.genre.properties.id.oneOf = genres.items.map(genre => ({
		const: genre.id,
		title: genre.title,
	}));

	return schema;
}

function getUiSchema(model, isNew, location, route, params) {
	return uiSchema;
}

function savePayloadTransform({ formData, ...rest }) {
	const isNew = !formData?.id;
	
	return {
		...rest,
		formData: {
			...formData,
			name: isNew ? formData.title : formData.name,
			type: isNew ? "sport" : formData.type,
		},
	};
}
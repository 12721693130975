import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'

import InlineHeading from '../../../components/ui/inlineHeading'
import ContentItem, { Content } from '../../../components/list/contentItem'

import { ShieldAccessLevels } from '../../../core/constants'

export default class LoginContent extends PureComponent {

	static propTypes = {
		displayName: PropTypes.string,
		name: PropTypes.string,
		path: PropTypes.string,
		methods: PropTypes.array,
		accessLevel: PropTypes.string,
		className: PropTypes.string,

		handleSetProperty: PropTypes.func.isRequired,
	}

	render() {
		const {
			item: resource,
			handleSetProperty,
		} = this.props;

		const {
			id: resourceId,
			displayName,
			name,
			path,
			methods,
			accessLevel,
		} = resource;

	    return (
			<ContentItem data={this.props}>
				<Content>
					<h1>{`${displayName}`}</h1>
					<p>{name}</p>
				</Content>
				<Content>
					<InlineHeading title="Access level">{getAccessLevelSelect(resourceId, handleSetProperty, resource)}</InlineHeading>
				</Content>
				<Content>
					<InlineHeading title="Path"><code>{path}</code></InlineHeading>
				</Content>
				<Content>
					<InlineHeading title="Methods">{methods.map(m => <code key={m}> {m}</code>)}</InlineHeading>
				</Content>
			</ContentItem>
		);
  	}
}

// HELPERS
function getAccessLevelSelect(resourceId, handleSetProperty, resource) {

	const MenuItems = ShieldAccessLevels.map(type => (
		<MenuItem
			key={type}
			value={type}
		>
			{type === "Undefined" ? "Roles only" : type }
		</MenuItem>
	));

	return (
		<Select
			value={resource.accessLevel ?? ""}
			onChange={handleSetProperty.bind(this, resource, "resource", "accessLevel")}
			variant="standard"
			placeholder="Access level"
			sx={{ width: "125px" }}
		>
			{MenuItems}
		</Select>
	);
}
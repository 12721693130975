import React, { Component } from 'react'
import { findDOMNode } from 'react-dom'
import PropTypes from 'prop-types'
import { DragSource, DropTarget } from 'react-dnd'
// import { getEmptyImage } from 'react-dnd-html5-backend'

import ItemTypes from '../../core/itemTypes'
import ContentItem from './contentItem'

import './list.css'
import './dnd.css'

const itemSource = {
	beginDrag(props) {
		const { data, selectedItems, findItem, onDragStateChanged } = props;

		// For hiding actions, todos etc. when dragging
		if(typeof(onDragStateChanged) === "function") {
			onDragStateChanged(true);
		}

		return {
			id: data.id,
			originalIndex: findItem(data.id).index,
			originalData: Object.assign({}, data), // Clone, so we have the original item state for rollbacks. (For example if the item position is changing during drag and we want to revert it)
			data,
			selectedItems,
		};
	},

	// Possibly needed if we're dragging between list components
	// isDragging(props, monitor) {
    //     return props.actionData === monitor.getItem().actionData;
	// },

	// TODO: Maybe even dragging outside the list should remove the item?
	// Handle cases when we drop outside a target
	endDrag(props, monitor) {
		const { onItemDropOutside, onDragStateChanged } = props;

		// For hiding actions, todos etc. when dragging
		if(typeof(onDragStateChanged) === "function") {
			onDragStateChanged(false);
		}

		const { id: droppedId, originalIndex } = monitor.getItem();
		const didDrop = monitor.didDrop();

		// Set item original location if no real drop
		if (!didDrop && typeof(onItemDropOutside) === "function") {
			onItemDropOutside(droppedId, originalIndex); // Resets the dragged item to it's original location
		}
	}
};


const itemTarget = {
	canDrop(props, monitor) {
		console.log({ item: monitor.getItem(), props});
		return false;
	},

	// canDrop(props, monitor) {
	// 	// You can disallow drop based on props or item
	// 	const item = monitor.getItem()
	// 	return canMakeChessMove(item.fromPosition, props.position)
	//   },

	hover(props, monitor, component) {
		const { data, findItem, moveItem, insertCard} = props;

		const draggedId = monitor.getItem().id;
		const overId = data.id;

		if (draggedId !== overId) {
			const { index: draggedIndex } = findItem(draggedId);
			if (draggedIndex >= 0) {
				// moveItem(draggedId, overId); // TODO: See if adding the overTarget prop here is faster than using state

				// https://github.com/react-dnd/react-dnd/issues/233#issuecomment-121623792
				// These checks makes DnD work better when items don't have the same heights
				const overIndex = findItem(overId).index;
				const boundingRect = findDOMNode(component).getBoundingClientRect();
				const middleY = (boundingRect.bottom - boundingRect.top) / 2;
				const clientOffset = monitor.getClientOffset();
				const offsetY = clientOffset.y - boundingRect.top;

				if (
					(draggedIndex < overIndex && offsetY < middleY)
					|| (draggedIndex > overIndex && offsetY > middleY)
				) {
					return;
				}
				
				moveItem(draggedId, overId);

			}
			else {
				insertCard(draggedId, overId); // Insert a new card wHen dragging in new items
			}
		}
	}
};

// https://github.com/gaearon/react-dnd/issues/229
// const dragSourceOptions = {
// 	arePropsEqual: (nextProps, props, component) => {
// 		return 	nextProps.id === props.id; // NOW: Needs more logic
// 	}
// };

@DropTarget(ItemTypes.ITEM, itemTarget, (connect, monitor) => ({
	connectDropTarget: connect.dropTarget(),
}))
@DragSource(ItemTypes.ITEM, itemSource, (connect, monitor) => ({
	connectDragSource: connect.dragSource(),
	connectDragPreview: connect.dragPreview(),
	isDragging: monitor.isDragging()
}))
export default class SortableContentItem extends Component {
	static displayName = "SortableItem"; // Needed to support UI-actions since babel is minifying the class name (this.constructor.name) when we build to production

	static propTypes = {
		data: PropTypes.object,
		flex: PropTypes.string,
		selected: PropTypes.bool,
		disabled: PropTypes.bool,
		selectedItems: PropTypes.array,

		// DnD
		connectDragSource: PropTypes.func.isRequired,
		connectDragPreview: PropTypes.func.isRequired,
		connectDropTarget: PropTypes.func.isRequired,
		isDragging: PropTypes.bool.isRequired,
		id: PropTypes.oneOfType([
			PropTypes.string.isRequired,
			PropTypes.number.isRequired
		]),
		moveItem: PropTypes.func.isRequired,
		findItem: PropTypes.func.isRequired,
		insertCard: PropTypes.func,
		isPlaceholder: PropTypes.bool,
		handleType: PropTypes.string,
	}

	// componentDidMount() {
	// 	if(this.props.connectDragPreview) {
	// 		this.props.connectDragPreview(getEmptyImage());
	// 	}
	// }

	render() {
		const {
			handleType, // "icon" || "thumbnail" || "item"
			isDragging,
			isPlaceholder,
			noHandle = false,
			connectDragSource,
			connectDragPreview,
			connectDropTarget,
			children,
			...props
		} = this.props;

		// Dim the item we're dragging from
		const style = { opacity: isDragging || isPlaceholder ? 0 : 1 };

		// We can drag by part of the item; handle (default), icon or thumbnail... or just drag the whole item.
		let handle = null;
		if(handleType !== "item") {
			const handleClassNames = handleType ? `c6-handle width-${handleType}`: "c6-handle";
			handle = connectDragSource(<div className={handleClassNames} />);
		}

		const item = (
			<div style={style}>
				<ContentItem {...props}>
					{handle}
					{children}
				</ContentItem>
			</div>
		);

		return connectDragPreview(connectDropTarget(item));

		// // We have a custom drag preview so we MUST NOT wrap the draggable item with the connectDragPreview
		// // method or we'll render both the browser ghost preview as well as our custom preview.
		// return handleType === "item"
		// 	? connectDragSource(item)
		// 	: item;

		// return handleType === "item"
		// 	? connectDragPreview(connectDragSource(item))
		// 	: connectDragPreview(item);
  	}
}
import React from 'react'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'

const ALL_DAY_VALUE = 16777215;

export default class hourSchedule extends React.Component {
	constructor(props) {
		super(props);

		const value = props.value > 0 ? props.value : ALL_DAY_VALUE;

		this.state = {
			blocks: getBlocks(this.props.options.mode),
			value,
			specificity: value === ALL_DAY_VALUE ? "all" : "custom",
			hours: convertValueToHours(value)
		}
	}

	handleSpecifityChange = (event, specificity) => {
		const value = specificity === "all" ? ALL_DAY_VALUE : convertHoursToValue(this.state.hours);
		this.setState({
			value,
			specificity
		});
		this.props.onChange(value);
	}

	handleBlockChange = (event, checked) => {
		const { period } = this.state.blocks.get(event.target.value);
		let newHours = this.state.hours;
		const hoursContainsPeriod = period.every(h => ( newHours.includes(h) ));

		if(checked && !hoursContainsPeriod) {
			newHours = mergePeriod(newHours, period); // Merge period hours with selected hours
		}
		else if(!checked) {
			newHours = removePeriod(newHours, period); // Remove period hours from selected hours
		}

		const value = convertHoursToValue(newHours);

		this.setState({
			value,
			hours: newHours
		});

		// TODO: Debounce this call for performance?
		this.props.onChange(value);
	}

	render() {
		const { blocks, specificity, value, hours } = this.state;

		let hourPicker = null;
		if (specificity === "custom") {
			hourPicker = [];

			blocks.forEach((block, key) => {
				const { label, period} = block;
				hourPicker.push(
					<FormControlLabel
						key={key}
						control={
							<Checkbox
								value={key}
								checked={getCheckedState(period, hours)}
								onChange={this.handleBlockChange}
							/>
						}
						label={label}
						style={{ display: "block", height: "2rem" }}
					/>
				);
			})
		}

		const rbStyles = {
			display: "inline-block",
			width: "75px",
			whiteSpace: "nowrap",
			marginRight: "1rem"
		};
		const iconStyles = { marginRight: 3 };


		return (
			<div className="hourSchedule">
				<RadioGroup
					row={true}
					name="hours"
					value={specificity}
					onChange={this.handleSpecifityChange}
					style={{ marginTop: "14px", marginBottom: "3px" }}
				>
					<FormControlLabel
						value={"all"}
						label={"All week"}
						control={<Radio />}
					/>
					<FormControlLabel
						value={"custom"}
						label={"On specific hours"}
						control={<Radio />}
					/>
				</RadioGroup>
				{hourPicker}
			</div>
		);
	}
};

function mergePeriod(hours, period) {
	hours.push(...period);
	return hours.filter((v, i) => (hours.indexOf(v) === i)); // We only want unique hours
}

function removePeriod(hours, period) {
	return hours.filter(h => ( !period.includes(h) ));
}

function getCheckedState(period, hours) {
	let periodCoverage = true;
	period.forEach(hour => {
		if(periodCoverage && !hours.includes(hour)) {
			periodCoverage = false;
		}
	});
	return periodCoverage;
}

function convertHoursToValue(hours) {
	let binaryFlags = "";
	let i = 0;
	for(i; i<24; i++) {
		binaryFlags += hours.includes(i) ? 1 : 0;
	}
	return parseInt(binaryFlags, 2);
}

function convertValueToHours(value) {
	const binaryFlags = value.toString(2).padStart(24, "0").split("");
	let hours = [];
	binaryFlags.forEach((hour, index) => {
		if(parseInt(hour)) {
			hours.push(index);
		}
	});
	return hours;
}

function getBlocks(mode) {
	if (mode === "individual-hours") {
		const map = new Map([]);
		for (let i = 0; i < 24; i++) {
			const hour = (i + 5) % 24;
			const hour1 = `${hour}`.padStart(2, "0");
			const hour2 = `${hour + 1}`.padStart(2, "0");
			const hours = `${hour1}-${hour2}`;
			map.set(hours, { label: hours, period: [hour] });
		}

		return map;
	}

	return new Map([
		["morning", { label: "Morning (5-10)", period: [5, 6, 7, 8, 9] }],
		["midday", { label: "Midday (10-14)", period: [10, 11, 12, 13] }],
		["afternoon", { label: "Afternoon (14-17)", period: [14, 15, 16] }],
		["evening", { label: "Evening (17-19)", period: [17, 18] }],
		["primetime", { label: "Primetime (19-21)", period: [19, 20] }],
		["lateprimetime", { label: "Late primetime (21-00)", period: [21, 22, 23] }],
		["night", { label: "Night (00-05)", period: [0, 1, 2, 3, 4] }],
	]);
}
import React from 'react'
import TextField from '@mui/material/TextField'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Dialog from '@mui/material/Dialog'

import Button from '../../../components/ui/controls/button'
import { Tabs, Tab } from '../../../components/tabs'

import * as ReviewsAPI from '../../../apis/reviews'
import { displayAlert } from '../../../core/services/alert'

const cleanUpFunctions = [
    "ToLower",
    "ToUpperCaseFirst",
];

const ScrapingPreview = ({ modelLoaded, scrapingTemplate, scrapingTestUrl, updateModel }) => {
    const wrapper = React.useRef();

    const [fieldsSingle, setFieldsSingle] = React.useState([]);
    const [fieldsSeason, setFieldsSeason] = React.useState([]);
    const [scrapingEnabledSingle, setScrapingEnabledSingle] = React.useState(false);
    const [scrapingEnabledSeason, setScrapingEnabledSeason] = React.useState(false);

    const [programType, setProgramType] = React.useState("single");
    const [expandPaywall, setExpandPaywall] = React.useState(false);
    const [paywallXPath, setPaywallXPath] = React.useState("");
    const [loginXPath, setLoginXPath] = React.useState("");
    const [username, setUsername] = React.useState("");
    const [usernameXPath, setUsernameXPath] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [passwordXPath, setPasswordXPath] = React.useState("");
    const [submitXPath, setSubmitXPath] = React.useState("");
    const [overlayXPath, setOverlayXPath] = React.useState("");
    const [nextStepXPath, setNextStepXPath] = React.useState("");

    const [urlSingle, setUrlSingle] = React.useState("");
    const [urlSeason, setUrlSeason] = React.useState("");

    const [isLoadingReviews, setIsLoadingReviews] = React.useState(false);
    const [reviews, setReviews] = React.useState(null);
    const [showReviews, setShowReviews] = React.useState(false);
    const [isLoadingHtml, setIsLoadingHtml] = React.useState(false);
    const [htmlPage, setHtmlPage] = React.useState("");
    // const [unlockCheckbox, setUnlockCheckbox] = React.useState(false);

    const loadPage = React.useCallback(
        async () => {
            setIsLoadingHtml(true);
            let payload = null;
            if (username) {
                payload = {
                    "paywallElement": { xPath: paywallXPath },
                    "loginElement": { xPath: loginXPath },
                    "username": username,
                    "usernameElement": { xPath: usernameXPath },
                    "password": password,
                    "passwordElement": { xPath: passwordXPath },
                    "submitElement": { xPath: submitXPath },
                };
            }

            try {
                let body = (await ReviewsAPI.fetchScrapingBody(scrapingTestUrl, payload));
                body = cleanBody(body);
                setHtmlPage(body);
                updateField();
                setIsLoadingHtml(false);
            } catch {
                setIsLoadingHtml(false);    
            }
        },
        [scrapingTestUrl]
    );

    const updateField = React.useCallback(
        (key, prop, value) => {
            const updateMethod = programType === "single" ? setFieldsSingle : setFieldsSeason;
            updateMethod((fields) => {
                const newFields = [...fields];

                if (key) {
                    // Update changed field
                    const index = newFields.findIndex(f => f.key === key);
                    newFields[index] = {
                        ...newFields[index],
                        [prop]: value,
                    };
                }

                // Update result
                newFields.forEach(field => {
                    let result;
                    const xPaths = field.xPath?.split("??") ?? [];
                    for (let xPath of xPaths) {
                        // Find elements using XPATH
                        const elements = xPath.length ? getElementsByXpath(wrapper.current, field.xPath) : [];
                        if (!elements.length) {
                            continue;
                        }

                        // Select element attribute
                        // Defaults to innerText
                        const attributes = field.attribute?.split("??") ?? [];
                        for (let attribute of attributes) {
                            if (attribute.trim() === "[count]") {
                                result = `${elements.length}`;
                            } else {
                                const results = elements.map(el => attribute.trim().length ? el.getAttribute(attribute.trim()) : el.innerText);
                                if (!results?.length) {
                                    continue;
                                }

                                result = results.join("\n");
                            }

                            // Break attribute loop if we have a result
                            if (result?.length) {
                                break;
                            }
                        }

                        if (!result?.length) {
                            continue;
                        }

                        // Apply optional regex
                        // Uses groups if available, otherwise full match
                        if (result?.length && field.valueRegex?.length) {
                            try {
                                const regexes = field.valueRegex.split("??");
                                for (let r of regexes) {
                                    const regex = new RegExp(r, "i");
                                    const res = regex.exec(result) ?? [];
                                    let groups = [];
                                    if (res.length > 1) {
                                        groups = res.slice(1).filter(r => r?.length);
                                    }

                                    result = groups.length ? groups.join("") : res[0];
                                }
                            } catch (_) {}
                        }

                        // Cleanup
                        if (result?.length) {
                            result = result
                                .replace(/&nbsp;/g, "")
                                .replace(/&quot;/g, "\"")
                                .trim();
                            
                            if (field.cleanUpFunctions.includes("ToLower")) {
                                result = result.toLowerCase();
                            }
                            if (field.cleanUpFunctions.includes("ToUpperCaseFirst")) {
                                result = result.substr(0, 1).toUpperCase() + result.substr(1);
                            }
                        }

                        // Break regex loop if we have a result
                        if (result?.length) {
                            break;
                        }
                    }

                    field.result = result;
                });
                return newFields;
            });
        },
        [fieldsSingle, setFieldsSingle, fieldsSeason, setFieldsSeason]
    );

    const resetExpandedFields = React.useCallback(
        () => {
            setFieldsSingle(fields => {
                return fields.map(f => ({ ...f, expand: false }));
            });
            setFieldsSeason(fields => {
                return fields.map(f => ({ ...f, expand: false }));
            });
        },
        [setFieldsSingle, setFieldsSeason]
    );

    const parseTemplate = React.useCallback(
        () => {
            const parsedTemplate = parseScrapingTemplate(scrapingTemplate, programType);
            setUrlSingle(parsedTemplate.urlSingle);
            setUrlSeason(parsedTemplate.urlSeason);
            setLoginXPath(parsedTemplate.loginXPath);
            setUsername(parsedTemplate.username);
            setUsernameXPath(parsedTemplate.usernameXPath);
            setPassword(parsedTemplate.password);
            setPasswordXPath(parsedTemplate.passwordXPath);
            setPaywallXPath(parsedTemplate.paywallXPath);
            setSubmitXPath(parsedTemplate.submitXPath);
            setOverlayXPath(parsedTemplate.overlayXPath);
            setNextStepXPath(parsedTemplate.nextStepXPath);
            setFieldsSingle(parsedTemplate.fieldsSingle);
            setFieldsSeason(parsedTemplate.fieldsSeason);
            setScrapingEnabledSingle(parsedTemplate.scrapingEnabledSingle);
            setScrapingEnabledSeason(parsedTemplate.scrapingEnabledSeason);
        },
        [scrapingTemplate, fieldsSingle, setFieldsSingle, fieldsSeason, setFieldsSeason, urlSingle, setUrlSingle, urlSeason, setUrlSeason, updateField, programType, scrapingEnabledSingle, scrapingEnabledSeason]
    );

    const buildTemplate = React.useCallback(
        () => updateModel({
            scrapingTemplate: getTemplate({ scrapingTemplate, programType, urlSingle, urlSeason, loginXPath, username, usernameXPath, password, passwordXPath, paywallXPath, submitXPath, overlayXPath, nextStepXPath, fieldsSingle, fieldsSeason, scrapingEnabledSingle, scrapingEnabledSeason }),
        }),
        [scrapingTemplate, programType, urlSingle, urlSeason, loginXPath, username, usernameXPath, password, passwordXPath, paywallXPath, submitXPath, overlayXPath, nextStepXPath, fieldsSingle, fieldsSeason, scrapingEnabledSingle, scrapingEnabledSeason]
    );

    // Trigger xpath check on load (after scrapingTemplate has loaded)
    const didInitialCheck = React.useRef(false);
    React.useEffect(
        () => {
            if (scrapingTemplate !== null && !didInitialCheck.current) {
                parseTemplate();
                didInitialCheck.current = true;
            }
        },
        [scrapingTemplate]
    );


    // This effect runs when the model is loaded
    // so we don't run buildTemplate until any changes have been made _BY THE USER_
    const skipBuild = React.useRef(true);
    React.useEffect(
        () => {
            if (skipBuild.current && modelLoaded) {
                skipBuild.current = false;
            } else if (!skipBuild.current && modelLoaded) {
                buildTemplate();
            }
        },
        [programType, urlSingle, urlSeason, loginXPath, username, usernameXPath, password, passwordXPath, paywallXPath, submitXPath, overlayXPath, nextStepXPath, fieldsSingle, fieldsSeason]
    );

    const findReviews = React.useCallback(
        async () => {
            try {
                setIsLoadingReviews(true);
                const reviews = (await ReviewsAPI.fetchScrapingTests(scrapingTemplate));
                setReviews(reviews);
                setIsLoadingReviews(false);
                // setUnlockCheckbox(true);
                setShowReviews(true);
            } catch (e) {
                console.error(e);
                displayAlert("error", e.exceptionMessage);
                setIsLoadingReviews(false);
            }
        },
        [scrapingTemplate]
    );

    const fields = programType === "single" ? fieldsSingle : fieldsSeason;
    const rootFields = fields.filter(f => f.root);
    const otherFields = fields.filter(f => !f.root);

    const content = (
        <React.Fragment>
            <div className="full">
                <FormControlLabel
                    // disabled={!scrapingEnabledOriginally && !unlockCheckbox}
                    // title={!scrapingEnabledOriginally && !unlockCheckbox ? `Locked until reviews have successfully been found using the "Find reviews" button.` : ""}
                    className="scraping-enabled"
                    control={
                        <Checkbox
                            checked={programType === "single" ? scrapingEnabledSingle : scrapingEnabledSeason}
                            onChange={(_, value) => (programType === "single" ? setScrapingEnabledSingle : setScrapingEnabledSeason)(value)}
                        />
                    }
                    label="Scraping enabled"
                />
            </div>
            <div className="scraping-url">
                <TextField
                    variant="standard"
                    value={(programType === "single" ? urlSingle : urlSeason) ?? ""}
                    onChange={e => (programType === "single" ? setUrlSingle : setUrlSeason)(e.target.value)}
                    label="Scraping URL"
                />
                <Button
                    title={isLoadingReviews ? "Finding reviews" : "Find reviews"}
                    onClick={findReviews}
                    disabled={isLoadingReviews}
                />
                <Button
                    title="Show reviews"
                    onClick={() => setShowReviews(true)}
                    disabled={isLoadingReviews || !reviews?.length}
                />
            </div>
            <div className="root-fields">
                {rootFields.map((field, i) => (
                    <div key={field.key}>
                        <TextField
                            variant="standard"
                            value={field.xPath ?? ""}
                            onChange={e => updateField(field.key, "xPath", e.target.value)}
                            label={`${field.name} XPath`}
                            fullWidth
                        />
                        {field.key === "nextPage" && (
                            <FormControlLabel
                                className="scroll-to-checkbox"
                                control={
                                    <Checkbox
                                        checked={field.attribute?.trim() === "ScrollTo"}
                                        onChange={(_, value) => {
                                            updateField(field.key, "attribute", value ? "ScrollTo" : null);
                                        }}
                                    />
                                }
                                label="Infinite scroll"
                                title="Check this box if the list does not have pages but instead uses infinite scroll. The scraper will then try to scroll to this element in order to load more items."
                            />
                        )}
                    </div>
                ))}
            </div>
            <div className="url-wrapper">
                <TextField
                    variant="standard"
                    value={scrapingTestUrl ?? ""}
                    onChange={e => {
                        e.persist();
                        const cursor = e.target.selectionStart;
                        updateModel({ scrapingTestUrl: e.target.value });

                        // HACK: Move input cursor/pointer because it is moved to the end after update (not sure why)
                        setTimeout(() => {
                            e.target.selectionStart = cursor;
                            e.target.selectionEnd = cursor;
                        });
                    }}
                    label="Test scraping URL"
                    style={testUrlStyle}
                />
                <Button
                    title={isLoadingHtml ? "Loading page" : "Load page"}
                    onClick={loadPage}
                    disabled={isLoadingHtml}
                />
            </div>
            {otherFields.map(field => (
                <div className={`field ${field.expand ? "expand" : ""}`} key={field.key}>
                    <p
                        className={`field-name ${field.expand ? "icon-expand_less" : "icon-expand_more"}`}
                        onClick={() => updateField(field.key, "expand", !field.expand)}
                    >
                        {field.name}
                    </p>
                    {field.expand && (
                        <React.Fragment>
                            <TextField
                                variant="standard"
                                value={field.xPath ?? ""}
                                onChange={e => updateField(field.key, "xPath", e.target.value)}
                                label="XPath"
                                fullWidth
                            />
                            {!field.root && (
                                <React.Fragment>
                                    <TextField
                                        variant="standard"
                                        value={field.attribute ?? ""}
                                        onChange={e => updateField(field.key, "attribute", e.target.value)}
                                        label={`Attribute (default is element text, use "[count]" to get number of matching elements)`}
                                        fullWidth
                                    />
                                    <TextField
                                        variant="standard"
                                        value={field.valueRegex ?? ""}
                                        onChange={e => updateField(field.key, "valueRegex", e.target.value)}
                                        label={`Regex (applied before the cleanup functions below)`}
                                        fullWidth
                                        sx={{ marginBottom: "10px" }}
                                    />
                                    {cleanUpFunctions.map(functionName => (
                                        <FormControlLabel
                                            className="cleanup-function"
                                            key={functionName}
                                            control={
                                                <Checkbox
                                                    checked={field.cleanUpFunctions?.includes(functionName)}
                                                    onChange={(_, value) => {
                                                        let newValue = [...field.cleanUpFunctions];
                                                        if (value) {
                                                            newValue.push(functionName);
                                                        } else {
                                                            newValue = newValue.filter(v => v !== functionName);
                                                        }
                                                        updateField(field.key, "cleanUpFunctions", newValue);
                                                    }}
                                                />
                                            }
                                            label={functionName}
                                        />
                                    ))}
                                </React.Fragment>
                            )}
                        </React.Fragment>
                    )}
                    <div className={`result ${field.result?.trim().length ? "has-match" : "no-match"}`}>
                        {htmlPage && (field.result?.trim() || "(No match)")}
                    </div>
                </div>
            ))}
        </React.Fragment>
    );


    const reviewDialog = React.useMemo(() => renderReviewsDialog(showReviews, reviews, setShowReviews), [reviews, showReviews]);
    return (
        <div className="scraping-preview">
            {reviewDialog}

            <div className={`settings ${expandPaywall ? "open" : ""}`}>
                {expandPaywall && (
                    <React.Fragment>
                        <TextField variant="standard" value={paywallXPath ?? ""} className="half margin-right" onChange={e => setPaywallXPath(e.target.value)} label="Paywall XPath" />
                        <TextField variant="standard" value={loginXPath ?? ""} className="half" onChange={e => setLoginXPath(e.target.value)} label="Login link XPath" />
                        <TextField variant="standard" value={username ?? ""} className="half margin-right" onChange={e => setUsername(e.target.value)} label="Login for paywall" />
                        <TextField variant="standard" value={usernameXPath ?? ""} className="half" onChange={e => setUsernameXPath(e.target.value)} label="Login XPath" />
                        <TextField variant="standard" value={password ?? ""} className="half margin-right" onChange={e => setPassword(e.target.value)} label="Password for paywall" />
                        <TextField variant="standard" value={passwordXPath ?? ""} className="half" onChange={e => setPasswordXPath(e.target.value)} label="Password XPath" />
                        <TextField variant="standard" value={submitXPath ?? ""} className="half margin-right" onChange={e => setSubmitXPath(e.target.value)} label="Login submit XPath" />
                        <TextField variant="standard" value={nextStepXPath ?? ""} className="half" onChange={e => setNextStepXPath(e.target.value)} label="Next step XPath" />
                        <TextField variant="standard" value={overlayXPath ?? ""} className="full" onChange={e => setOverlayXPath(e.target.value)} label="Overlay XPath" />
                    </React.Fragment>
                )}
                <p
                    className={expandPaywall ? "icon-expand_less" : "icon-expand_more"}
                    onClick={() => setExpandPaywall(!expandPaywall)}
                >
                    {expandPaywall ? "Hide" : "Show"} paywall and overlay settings
                </p>
            </div>

            <Tabs defaultActiveTab="single">
                <Tab
                    id="single"
                    label="Single"
                    onClick={() => {
                        setProgramType("single");
                        resetExpandedFields();
                    }}
                >
                    {content}
                </Tab>
                <Tab
                    id="season"
                    label="Season"
                    onClick={() => {
                        setProgramType("season");
                        resetExpandedFields();
                    }}
                >
                    {content}
                </Tab>
			</Tabs>

            <div className="wrapper" ref={wrapper}>
                <div dangerouslySetInnerHTML={{ __html: htmlPage }}></div>
            </div>
        </div>
    );
}

export default ScrapingPreview;


function getElementsByXpath(context, path) {
    try {
        const res = document.evaluate(path, context, null, XPathResult.ORDERED_NODE_ITERATOR_TYPE, null);
        const elements = [];
        for (let el; el = res.iterateNext(); el !== null) {
            elements.push(el);
        }
        return elements;
    } catch (e) {
        return [];
    }
}

function getFieldsInPage(page, programType) {
    const fields = [
        { key: "reviewTitle", name: "Review title" },
        { key: "programTitle", name: "Program title" },
        { key: "originalTitle", name: "Original title" },
        { key: "imdb", name: "IMDb" },
        { key: "genre", name: "Genre" },
        { key: "text", name: "Text" },
        { key: "score", name: "Score" },
        { key: "scoreHalf", name: "Score (half)" },
        { key: "releaseYear", name: "Production year" },
        { key: "publicationDate", name: "Review date" },
        { key: "reviewBy", name: "Review author" },

        { key: "link", name: "Link element", root: true },
        { key: "nextPage", name: "Next page element", root: true },
    ];

    if (programType === "season") {
        fields.push(
            { key: "seasonNumber", name: "Season number" },
            { key: "episodeNumber", name: "Episode number" },
        );
    }
    
    return fields.map(field => {
        let element = page?.dataTemplate?.[`${field.key}Element`];
        if (field.root) {
            element = page?.[`${field.key}Element`];
        }
        return {
            ...(element ?? {}),
            ...field,
            xPath: element?.xPath ?? "",
            valueRegex: element?.valueRegex ?? "",
            attribute: element?.attribute ?? "",
            cleanUpFunctions: element?.cleanUpFunctions ?? [],
        };
    });
}

function parseScrapingTemplate(scrapingTemplate) {
    const pageSingle = scrapingTemplate?.pages?.find(p => p.dataTemplate?.programType === "single");
    const pageSeason = scrapingTemplate?.pages?.find(p => p.dataTemplate?.programType === "season");
    return {
        fieldsSingle: getFieldsInPage(pageSingle, "single"),
        fieldsSeason: getFieldsInPage(pageSeason, "season"),
        scrapingEnabledSingle: pageSingle?.scrapingEnabled,
        scrapingEnabledSeason: pageSeason?.scrapingEnabled,
        urlSingle: pageSingle?.url,
        urlSeason: pageSeason?.url,
        paywallXPath: scrapingTemplate?.paywall?.paywallElement?.xPath,
        loginXPath: scrapingTemplate?.paywall?.loginElement?.xPath,
        username: scrapingTemplate?.paywall?.username,
        usernameXPath: scrapingTemplate?.paywall?.usernameElement?.xPath,
        password: scrapingTemplate?.paywall?.password,
        passwordXPath: scrapingTemplate?.paywall?.passwordElement?.xPath,
        submitXPath: scrapingTemplate?.paywall?.submitElement?.xPath,
        nextStepXPath: scrapingTemplate?.paywall?.nextElement?.xPath,
        overlayXPath: scrapingTemplate?.overlays?.[0]?.xPath,
    };
}

function getTemplate({ scrapingTemplate, programType, urlSingle, urlSeason, loginXPath, username, usernameXPath, password, passwordXPath, paywallXPath, submitXPath, overlayXPath, nextStepXPath, fieldsSingle, fieldsSeason, scrapingEnabledSingle, scrapingEnabledSeason }) {
    const fields = (programType === "single" ? fieldsSingle : fieldsSeason);
    const reviewElements = fields.filter(f => !f.root).reduce(fieldsReducer, {});
    const rootElements = fields.filter(f => f.root).reduce(fieldsReducer, {});

    const existingPage = scrapingTemplate?.pages?.find(p => p.dataTemplate?.programType === programType) ?? {};
    const otherPages = scrapingTemplate?.pages?.filter(p => p.dataTemplate?.programType !== programType) ?? [];

    const newTemplate = {
        "pages": [
            ...otherPages,
            {
                ...existingPage,
                "url": programType === "single" ? urlSingle : urlSeason,
                "scrapingEnabled": programType === "single" ? scrapingEnabledSingle : scrapingEnabledSeason,
                ...rootElements,
                "dataTemplate": {
                    ...(existingPage?.dataTemplate ?? {}),
                    "programType": programType,
                    ...reviewElements,
                },
            }
        ],
        "overlays": [{ xPath: overlayXPath }], // backend has support for multiple overlays
    };

    if (username) {
        newTemplate.paywall = {
            ...(newTemplate.paywall ?? {}),
            "paywallElement": { xPath: paywallXPath },
            "loginElement": { xPath: loginXPath },
            "username": username,
            "usernameElement": { xPath: usernameXPath },
            "password": password,
            "passwordElement": { xPath: passwordXPath },
            "submitElement": { xPath: submitXPath },
            "nextElement": { xPath: nextStepXPath },
        };
    }

    return newTemplate;
}

const testUrlStyle = {
    width: "unset",
    flex: 1,
};

function renderReviewsDialog(showReviews, reviews, setShowReviews) {
    if (!showReviews || !reviews?.length) {
        return null;
    }

    return (
        <Dialog
            open={true}
            onClose={() => setShowReviews(false)}
            className="c6-modal scraping-results-dialog"
            maxWidth={false}
            classes={{
                container: "c6-modal-content",
                paper: "c6-modal-body"
            }}
		>
            {reviews.map((review, i) => (
                <div key={review.link} className="review">
                    <p><strong>Review #{i + 1}:</strong> <a href={review.link} className="c6-link">{review.link}</a></p>
                    <p><strong>Review title:</strong> {getReviewAttribute(review, "reviewTitle")}</p>
                    <p><strong>Program:</strong> {getReviewAttribute(review, "program.title")} <strong>from:</strong> {getReviewAttribute(review, "program.releaseYear")}</p>
                    <p><strong>Written by:</strong> {getReviewAttribute(review, "reviewBy")} <strong>on:</strong> {getReviewAttribute(review, "publicationDate")}</p>
                    <p><strong>Score:</strong> {getReviewAttribute(review, "score")} <strong>Text:</strong> {getReviewAttribute(review, "text")}</p>
                </div>
            ))}
        </Dialog>  
    );
}

function getReviewAttribute(review, attribute) {
    if (attribute.includes("program.")) {
        const [_, programAttribute] = attribute.split(".");
        if (review.program?.[programAttribute]) {
            return review.program[programAttribute];
        }
    }
    if (!review[attribute]) {
        return <span className="attribute-missing">[MISSING]</span>;
    }

    return review[attribute];
}

function fieldsReducer(obj, field) {
    return {
        ...obj,
        [`${field.key}Element`]: {
            ...field,
            xPath: field.xPath,
            valueRegex: field.valueRegex ?? "",
            attribute: field.attribute ?? "",
            replaceText: field.replaceText ?? {},
            cleanUpFunctions: field.cleanUpFunctions ?? [],
        },
    };
};

function cleanBody(body) {
    const tagsToRemoveWithContent = [
        "head", "link", "script", "style", "iframe"
    ];
    tagsToRemoveWithContent.forEach(tag => {
        // Not sure how to merge these regexes so that they don't remove too much
        const regex = new RegExp(`<${tag}>[\\s\\S]*</${tag}>`, "gi");
        const regex2 = new RegExp(`<${tag}[^>]*>[\\s\\S]*</${tag}>`, "gi");
        body = body.replace(regex, "").replace(regex2, "");
    });

    const tagsToRemoveButKeepContent = [
        "html", "body"
    ];
    tagsToRemoveButKeepContent.forEach(tag => {
        // Not sure how to merge these regexes so that they don't remove too much
        const regex = new RegExp(`</*${tag}>`, "gi");
        const regex2 = new RegExp(`</*${tag}[^>]*>`, "gi");
        body = body.replace(regex, "").replace(regex2, "");
    });
    
    return body;
}
import React, { Component } from 'react'

import Editor from '../../../components/editor/'

import * as ShieldAPI from '../../../apis/shield'
import * as Alert from '../../../core/services/alert'

import schema from './schema'
import ui from './ui'

export default class ExternalEditor extends Component {
	constructor(props) {
		super(props);

		this.state = {
			module: null,
			roles: null,
		};
	}
	
	componentDidMount() {
		const externalName = this.props.params.externalName;
		fetchModule(externalName, module => this.setState({ module }));
		fetchRoles(externalName, roles => this.setState({ roles }));
	}

	render() {
		return (
			<Editor
				layout="grid"
				api="shield"
				entity="login"
				getSchema={(model) => getSchema(model, this.state)}
				getUiSchema={(model, isNew, location, route, params) => getUiSchema(model, isNew, location, route, params, this.state)}
				loadPayloadTransform={loadPayloadTransform}
				savePayloadTransform={args => savePayloadTransform(args, this.state)}
				{...this.props}
			/>
		);
	}
}

function getSchema(model, { roles }) {
	if (model.id !== undefined || roles !== null) {
		if (roles !== null && !roles.length) {
			delete schema.properties.roles;
		}
		return schema;
	}

	return {};
}

function getUiSchema(model, isNew, location, route, params, { roles }) {
	const createCopy = route.path.includes("createcopy");
	ui.name["ui:readonly"] = !isNew && !createCopy;

	if (roles !== null) {
		ui.roles["ui:options"].dataSource = filterRoles(roles, params.externalName);
		return ui;
	}

	ui.password["ui:options"].hideValueBehindLock = !isNew;

	return {};
}

function loadPayloadTransform({ model, entity, location, route, params }) {
	const createCopy = route.path.includes("createcopy");

	if (createCopy) {
		model.email = "";
		model.name = "";
		model.lastName += " (Copy)";
	}


	const moduleIndex = model.modules && model.modules.findIndex(m => m.name === params.externalName);
	model.accessLevel = moduleIndex != null && moduleIndex >= 0 ? model.modules[moduleIndex].accessLevel : "Editor";

	return model;
}

function savePayloadTransform({ formData, entity, location, route, params, ...rest }, { roles, module }) {
	const saveAsCopy = route.path.includes("createcopy");

	formData.roles = formData.roles || [];
	if (params.externalName === "onestopdrop") {
		const contentProviderRole = roles.find(role => role.name === "ContentProvider user");
		formData.roles.push(contentProviderRole);
	}

	formData.modules = formData.modules || [];
	const moduleIndex = formData.modules.findIndex(m => m.id === module.id);
	if (moduleIndex < 0) {
		formData.modules.push({
			...module,
			accessLevel: formData.accessLevel,
		});
	} else {
		formData.modules[moduleIndex].accessLevel = formData.accessLevel;
	}
	delete formData.accessLevel;
	formData.defaultModule = module.displayName;
	
	return {
		...rest,
		formData,
		params,
		entity,
		location,
		saveAsCopy,
	};
}

function fetchModule(externalName, callback) {
	ShieldAPI.fetchModules({ name: externalName })
		.then(response => {
			const module = response.items.length > 1
				? response.items.find(m => m.name === externalName)
				: response.items[0];
			
			if (!module) {
				Alert.displayAlert("error", "Hmm, could not determine which module this is... Please contact support@junefirst.tv");
				return;
			}
			
			callback(module);
		});
}

function fetchRoles(externalName, callback) {
	ShieldAPI.fetchRoles({ module: externalName })
		.then(response => {
			const roles = response.items.map(role => ({
				...role,
				name: role.displayName,
			}));
			callback(roles);
		}, error => {
			console.error(error);
			callback([]);
		});
}

function filterRoles(roles, moduleName) {
	if (moduleName === "onestopdrop") {
		return roles.filter(r => r.name !== "ContentProvider user");
	}

	return roles;
}
import get from 'lodash/get';
import moment from 'moment';

const formatFunctions = {
    toUpperCase: (string) => string.toUpperCase(),
    toLowerCase: (string) => string.toLowerCase(),
    capitalize: (string) => string.charAt(0).toUpperCase() + string.slice(1),
    capitalizeWords: (string) => string.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' '),
    formatDate: (date, format) => moment(date)?.format(format),
}

export function format(string, data) {
    const tokens = _splitTokens(string);
    let result = '';

    tokens.forEach(token => {
        if (typeof token === 'string') {
            result += token;
        } else {
            const { property, formatFunction, fnArgs } = token;
            const value = get(data, property);

            const formattedValue = formatFunction in formatFunctions ?
                formatFunctions[formatFunction](value, ...fnArgs) :
                value;
            result += formattedValue ?? '${N/A}';
        }
    }); 

    return result;
}

function _splitTokens(string) {
    const tokenRegex = /(\${[\w-\.\\:]*})/g;

    const deconstructedString = [];

    const tokens = string.split(tokenRegex);
    tokens.forEach((token, index) => {
        if (token.startsWith("${") && token.endsWith("}")) {
            const [property, formatFunction, ...fnArgs] = token.slice(2, -1).split(/(?<!\\):/g);
            deconstructedString.push({ property, formatFunction, fnArgs: fnArgs?.map(arg => arg.replace(/\\:/g, ':')) });
        } else {
            deconstructedString.push(token);
        }
    });

    return deconstructedString;
}
import React from 'react'

import { Filter, Left, Right } from '../../../components/filter'
import Switch from '../../../components/ui/controls/switch'
import Search from '../../../components/ui/controls/search'
import Dropdown from '../../../components/ui/controls/dropdown'
import { getStatus, getPremiere, sortServicesAndOperators } from '../shared/utils'

export default ({
	searchText,
	filters,
	unselectedFilters,
	handleFilter,
	handleSelectService,
	services,
	serviceId,
	serviceName,
}) => {
	return (
		<Filter>
			<Left>
				<h1>
					Packages&nbsp;
					{(serviceName || !searchText) && "for "}
					{serviceName && (
						<span>{serviceName}</span>
					)}
					{!serviceName && !filters.filter.searchPackagesAcrossAllServices && (
						<Dropdown
							name="serviceId"
							onChange={handleSelectService}
							states={getServices(services)}
							currentState={filters.filter.serviceId}
						/>
					)}
				</h1>
				<Search
					onChange={handleFilter.bind(this, "search")}
					placeholder="Search"
					searchText={searchText}
					disabled={filters.filter.status === "attention"}
				/>
			</Left>
			<Right>
				<Switch
					name="premiere"
					title="Show"
					unselected={unselectedFilters || filters.filter.status === "attention"}
					onChange={handleFilter.bind(this, "filter")}
					states={getPremiere()}
					currentState={filters.filter.premiere}
					disabled={filters.filter.status === "attention" || !!searchText && searchText !== ""}
				/>
				<Switch
					name="status"
					title="Status"
					unselected={unselectedFilters}
					onChange={handleFilter.bind(this, "filter")}
					states={getStatus()}
					currentState={filters.filter.status}
					disabled={!!searchText && searchText !== ""}
				/>
			</Right>
		</Filter>
	);
}

function getServices(services) {
	return services.sort(sortServicesAndOperators).map(service => ({ key: service.id, text: service.displayName || service.name }));
}
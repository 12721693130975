import React from 'react'

import { formatToMinutes, formatToTime } from '../utils'
import { getIconForStyle } from '../utils_new'

import './label.css'

const Label = ({
	value,
	style,
	className,
	displayName,
	options,
	tooltip,
}) => {

	const { formattedValue, extraClasses } = React.useMemo(
		() => {
			if (options?.switch) {
				const matchingCase = options.switch[value];
				if (matchingCase) {
					return { formattedValue: matchingCase.text, extraClasses: matchingCase.cssClass };
				}
				
				return { formattedValue: null };
			}
			if (style?.format === "minutes") {
				return { formattedValue: formatToMinutes(value) };
			}
			
			if (style?.format === "time") {
				return { formattedValue: formatToTime(value) };
			}

			return { formattedValue: value };
		},
		[value, options, style]
	);

	const classNames = React.useMemo(() => `${className} ${extraClasses ?? ""}`, [className, extraClasses]);

	return (
		<div className={classNames} title={tooltip}>
			{displayName && (<label>{displayName}</label>)}
			{formattedValue}
			{style && getIconForStyle(style, value)}
		</div>
	);
};

export default Label;
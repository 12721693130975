import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Label from '../../../components/ui/label'
import ContentItem, { Content } from '../../../components/list/contentItem'

export default class ListContent extends Component {

	static propTypes = {
		enabled: PropTypes.bool,
		displayName: PropTypes.string,
		description: PropTypes.string,
		isLegacyModule: PropTypes.bool,
	}

	render() {
		const {
			enabled,
			id,
			displayName,
			description = "",
			resources = [],
			isLegacyModule = false,
		} = this.props;

		return (
			// actionData needed for CONTENT action "hidden" method
			<ContentItem data={this.props} actionData={this.props}>
				<Content>
					<h1>{displayName} {getLabel(enabled, "Disabled")} {getLabel(isLegacyModule || resources.length, "No active resources")}</h1>
					<p>{description}</p>
				</Content>
			</ContentItem>
		);
  	}
}

// HELPERS
function getLabel(hide, text) {
	return !hide
		? <Label status="expired">{text}</Label>
		: null ;
}
import { browserHistory } from 'browserHistory'

import { CONTENT, LINK, CLICK_CTRL_OR_OPT, MENU } from '../../../components/actions'
import { hasAccessToPath } from '../../../core/services/auth'
import { getLinkUrlAndHash } from './utils'
import Actions from '../actions'

const TV4PLAY = "tv4play";

export default {
	targetComponent: "Content",
	actions: [
		{
			title: "Display deliveries",
			method: ({ id }) => Actions.showDeliveries({id}),
			type: CONTENT,
		},
		{
			title: "Display deliveries (in new tab)",
			method: ({ id }) => {
				const newTab = true;
				Actions.showDeliveries({ id }, newTab);
			},
			type: CLICK_CTRL_OR_OPT,
		},
		{
			title: "View in Metadata module",
			icon: "movie",
			method: ({ service, metadata }) => {
				browserHistory.push(getLinkUrlAndHash(service, metadata));
			},
			hidden: () => {
				const accessRequirement = [{ module: "metadata", app: "library", access: "reader" }];
				const hasMetadataAccess = hasAccessToPath(accessRequirement);
				return !hasMetadataAccess;
			},
			type: MENU,
		},
		{
			title: "Resend",
			icon: "sync",
			method: ({ id }) => Actions.resend(id),
			disabled: ({ service, status }) => {
				const disableActionsForTV4 = service.name?.toLowerCase() === TV4PLAY;
				return disableActionsForTV4 || !["Delivered", "Published"].includes(status);
			},
			type: MENU,
		},
		{
			title: "Update metadata",
			icon: "sync",
			method: ({ id }) => Actions.update(id),
			disabled: ({ service, status }) => {
				const disableActionsForTV4 = service.name?.toLowerCase() === TV4PLAY;
				return disableActionsForTV4 || !["Delivered", "Published", "Unpublished", "Canceled"].includes(status);
			},
			type: MENU,
		},
		{
			title: "Add",
			icon: "add_circle",
			method: ({ id }) => Actions.republish(id),
			disabled: ({ service }) => {
				const disableActionsForTV4 = service.name?.toLowerCase() === TV4PLAY;
				return disableActionsForTV4;
			},
			hidden: ({ status }) => status !== "Unpublished",
			type: MENU,
		},
		{
			title: "Remove",
			icon: "remove_circle",
			method: ({ id }) => Actions.unpublish(id),
			disabled: ({ service }) => {
				const disableActionsForTV4 = service.name?.toLowerCase() === TV4PLAY;
				return disableActionsForTV4;
			},
			hidden: ({ status }) => !(status === "Published" || status === "Delivered"),
			type: MENU,
		}
	]
};
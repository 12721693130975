import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Moment from 'moment'

import UIActions from '../../../core/decorators/uiActions'
import {UICenter} from '../../../components/ui/alignment.jsx'
import { Item, ItemGroup } from '../../../components/list/listItems'

import Actions from '../actions'
import ItemContent from './listItem'
import SubModules from './subModules'

import Constants from '../../../core/constants'

export default class List extends Component {

	static propTypes = {
		login: PropTypes.object.isRequired,
		items: PropTypes.array.isRequired,
		roles: PropTypes.array.isRequired,
		handleSetProperty: PropTypes.func.isRequired,
		handleToggle: PropTypes.func.isRequired,
	}

	render() {
		const {
			isLoading,

			items,
			roles,
			login,

			handleSetProperty,
			handleToggle,
		} = this.props;

		const itemsLoaded = items && items.length;
		const rolesLoaded = roles && roles.length;
		const loginLoaded = login && login.id;
		if (itemsLoaded && rolesLoaded && loginLoaded) {
			return (
				<div>
					{items.map(module => (
						<ItemGroup key={module.id}>
							<Item
								id={module.id}
								actionData={module}>
								<ItemContent
									item={module}
									login={login}
									roles={roles}

									handleSetProperty={handleSetProperty}
									handleToggle={handleToggle}
								/>
								<SubModules
									parentModule={module}
									login={login}
									items={module.subModules}
									handleToggle={handleToggle}
								/>
							</Item>
						</ItemGroup>
					))}
				</div>
			);
		}

		return this.renderEmpty(true);
  	}

	renderEmpty(isLoading) {
		const title = isLoading ? <h3>Loading...</h3> : <h3>No items found</h3>;
		const description = <p>Loading user access rights</p>;

		return <UICenter className="c6-color-light">{title}{description}</UICenter>;
	}
}
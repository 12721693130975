import          debounce from 'lodash/debounce'
import { CONTENT, MENU } from '../../../../components/actions'

import       Actions from '../../actions'
import ListConstants from '../constants'

const DATASTORE = "lists";

export default {
	targetComponent: "SortableItem",
	actions: [
		{
			title: "Show selection items",
			method: (data, sourceProps, location, params) => {
				const serviceId = params.serviceId ?? 1;

				// We should only show the list content if it's a showcase or regular list (curated)
				// otherwise, open the editor instead
				if (["curated", "automatic"].includes(data.type)) {
					Actions.showItems({
						listId: data.id,
						query: location.query,
						serviceId,
					});
				} else {
					Actions.edit({
						...location.query,
						id: data.id,
						serviceId,
						returnTo: `${location.pathname}${location.search}`,
					});
				}
			},
			type: CONTENT,
			hidden: data => data && !data.isPlaceholder,
		},
		{
			title: "Edit",
			icon: "create",
			method: (data, sourceProps, location, params) => {
				const serviceId = params.serviceId ?? 1;
				Actions.edit({
					...location.query,
					id: data.id,
					serviceId,
					returnTo: `${location.pathname}${location.search}`,
				});
			},
			type: MENU,
		},
		{
			title: "Activate",
			icon: "add_circle",
			method: debounce((data, sourceProps, location) => {
				Actions.moveListInGroup({
					payload: { ...data, active: true },
					originalItem: data,
					sectionId: sourceProps.conditions.sectionId,
					areaId: data.areaId,
				});
			}, 200, { leading: true }),
			type: MENU,
			hidden: data => data.active
		},
		{
			title: "Inactivate",
			icon: "remove_circle",
			method: debounce((data, sourceProps, location) => {
				Actions.moveListInGroup({
					payload: { ...data, active: false },
					originalItem: data,
					sectionId: sourceProps.conditions.sectionId,
					areaId: data.areaId,
				});
			}, 200, { leading: true }),
			type: MENU,
			hidden: data => !data.active
		},
		{
			title: "Remove list from this section/page",
			icon: "remove_circle",
			method: (data, sourceProps, location) => {
				Actions.removeListFromGroup({
					store: DATASTORE,
					id: data.id,
					groupId: sourceProps.conditions.sectionId,
					payload: data,
				});
			},
			disabled: data => false,
			type: MENU,
			// TODO, confirmar inte
			confirm: true,
			dangerous: true,
		}
	]
};
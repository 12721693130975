import React, { Component } from 'react'
import PropTypes from 'prop-types'
import InfiniteScroll from 'react-infinite-scroller'

import Empty from '../../../components/list/empty'

import Item from './listItem'

const ONLY_SEARCH_WHEN_SEARCHTEXT_IS_AT_LEAST = 2;

export default class List extends Component {


	static propTypes = {
		items: PropTypes.array.isRequired,
		// isLoading: PropTypes.number.isRequired,
		searchText: PropTypes.string,
		filters: PropTypes.object,
		textEmpty: PropTypes.string,
	}

	constructor(props) {
		super(props);
		this.state = {
		}
	}

	render() {
		const {
			items,
			isLoading,
			searchText = "",
			textEmpty = "Sorry, could not find any items.",
			hasMore,
			loadMore,			
		} = this.props;

		if (!(items && items.length)) {
			const textTooFewCharacters = searchText && searchText.length < ONLY_SEARCH_WHEN_SEARCHTEXT_IS_AT_LEAST ? `Please type ${ONLY_SEARCH_WHEN_SEARCHTEXT_IS_AT_LEAST} characters or more to search.` : "";
			return <Empty key="empty" v2={true} isLoading={!!isLoading}>{textTooFewCharacters || textEmpty}</Empty>;
		}

		return (
			<InfiniteScroll
				loadMore={loadMore}
				hasMore={hasMore}
				loader={<div className="infinite-loader" key="infinite-loader">Loading ...</div>}
				useWindow={false}
				threshold={700}
				initialLoad={false}
			>
				{this.renderGroups(this.props)}
			</InfiniteScroll>
		);
	}

	renderGroups({ items, filters }) {
		return this.renderItems(items);
		// const groupedItems = groupItems(items);
		// return Object.keys(groupedItems).map(groupKey => (
		// 	<ItemGroup
		// 		key={groupKey}
		// 		title={getGroupTitle(groupedItems, groupKey)}
		// 	>
		// 		{this.renderItems(groupedItems[groupKey])}
		// 	</ItemGroup>
		// ));
	}

	renderItems(items) {
		return items.map(item => (
			<Item
				key={item.id}
				id={item.id}
				actionData={item}
				item={item}
			/>
		));
	}
}
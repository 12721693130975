import alt from '../../core/services/alt'
import { getAssetOrContainerId } from './shared/utils'
import Actions from './actions'

class StarStore {

    constructor() {

        this.item = {
            isLoading: false,
            album: {},
        }

        this.list = {};
        this.initStore("selected");
		this.initStore("recentActivity", {}, getPlaceholderItems(16));
        this.initStore("searchItems", { pageSize: 100 });
        this.initStore("albums", { pageSize: 100 });
        this.initStore("mySpace", { pageSize: 100 });
        this.initStore("trashed", { status: "trashed", onlyRoot: false, pageSize: 100 });
        this.initStore("selectedTrashed");

        // Worklists
        // this.worklists = [];
        // this.worklistsLoading = false;

        // Recent containers
        // this.recentContainers = [];
        // this.recentContainersLoading = false;


        this.bindListeners({
            // SELECT
            onSelect: [Actions.SELECT, Actions.SELECT_IN_STORE],
            onUnselect: [Actions.UNSELECT, Actions.UNSELECT_IN_STORE],
            onToggleSelect: [Actions.TOGGLE_SELECT, Actions.TOGGLE_SELECT_IN_STORE],
            onToggleMultipleSelect: Actions.TOGGLE_MULTIPLE_SELECT,
            onClearSelected: [Actions.CLEAR_SELECTED, Actions.ADD_SELECTED_TO_ALBUM_DONE, Actions.CLEAR_SELECTED_IN_STORE],

            // SEARCH
            onFetchItems: Actions.FETCH_ITEMS,
            onPageItems: Actions.PAGE_ITEMS,
            onItemsUpdated: Actions.ITEMS_UPDATED,
            onClearSearch: Actions.CLEAR_SEARCH,

            // ITEM
            onFetchItem: [Actions.FETCH_ITEM, Actions.FETCH_SHARED_ALBUM],
			onItemLoaded: Actions.ITEM_LOADED,
			// onItemSaved: [Actions.ITEM_UPDATED,EditorActions.MODEL_SAVED],
            // onItemSaved: Actions.ITEM_UPDATED,
            onRemoveItem: Actions.REMOVE_ITEM,
            // onRemoveItems: Actions.REMOVE_ITEMS,
			onItemRemoved: Actions.ITEM_REMOVED,
			onRollbackRemoveItem: Actions.ROLLBACK_REMOVE_ITEM,


            // RECENT
            // onFetchRecentActivity: Actions.FETCH_RECENT_ACTIVITY,
            // onRecentActivityLoaded: Actions.RECENT_ACTIVITY_LOADED,

            // ALBUMS
            onAddSelectedToAlbum: Actions.ADD_SELECTED_TO_ALBUM,
            onAddSelectedToAlbumDone: Actions.ADD_SELECTED_TO_ALBUM_DONE,
            onShareAlbum: Actions.SHARE_ALBUM,
            onCreateAlbumDone: Actions.CREATE_ALBUM_DONE, // TODO: Make more generic

            // onFetchWorklists: Actions.FETCH_WORKLISTS,
            // onWorklistsLoaded: Actions.WORKLISTS_LOADED,

            // onFetchRecentContainers: Actions.FETCH_RECENT_CONTAINERS,
            // onRecentContainersLoaded: Actions.RECENT_CONTAINERS_LOADED,

            // GENERAL
            onUnmount: Actions.UNMOUNT,
            onPersist: Actions.PERSIST,
        });
    }

    // SELECT
    onSelect({ item, selectedStore = "selected" }) {
        let { items } = this.list[selectedStore];
        const itemId = getAssetOrContainerId(item);
        const index = items.findIndex(i => getAssetOrContainerId(i) === itemId);
        if(index < 0) {
            items.push(item);
        }

        this.list[selectedStore].lastActionItemId = itemId;
    }
    onUnselect({ item, selectedStore = "selected" }) {
        let { items } = this.list[selectedStore];
        this.list[selectedStore].items = items.filter(i => getAssetOrContainerId(i) !== getAssetOrContainerId(item));

        this.list[selectedStore].lastActionItemId = null;
    }
    onToggleSelect({ item, selectedStore = "selected" }) {
        const { items } = this.list[selectedStore];
        const index = items.findIndex(i => getAssetOrContainerId(i) === getAssetOrContainerId(item));

        if(index >= 0) {
            this.onUnselect({ item, selectedStore });
        }
        else {
            this.onSelect({ item, selectedStore });
        }
    }
    onClearSelected(selectedStore) {
        const store = selectedStore && selectedStore.length ? selectedStore : "selected";
        this.list[store].items = [];
        this.list[store].lastActionItemId = null;
    }
    onToggleMultipleSelect({ item, storeName, selectedStore = "selected" }) {
        const { items } = this.list[storeName];

        const start = Math.max(items.findIndex(i => getAssetOrContainerId(i) === this.list[selectedStore].lastActionItemId), 0);
        const end = items.findIndex(i => getAssetOrContainerId(i) === getAssetOrContainerId(item));
        const itemRange = items.slice(Math.min(start, end), Math.max(start, end) + 1);

        itemRange.forEach(i => this.onSelect({ item: i, selectedStore }));
    }

    // SEARCH
    onFetchItems({ payload, store, requestTime }) {
        const l = this.list[store];
        l.isLoading = true;

		l.nextPageUrl = null;
		l.numberOfItems = 0;
        l.latestRequestTime = requestTime;
        l.latestSearchPayload = payload;

        this.list.selected.lastActionItemId = null;
	}

	onPageItems({ entity, store }) {
        const l = this.list[store || entity];
        l.isLoading = true;
		l.nextPageUrl = null;
	}

    onItemsUpdated({ store, items, appendToExistingItems, numberOfItems, nextPageUrl, requestTime }) {
        const l = this.list[store];

		if(l.latestRequestTime > requestTime) {
			console.log("[%s] Ignoring result with %s items since there have been newer requests.", store, numberOfItems);
		}
		else {
			l.items = appendToExistingItems ? l.items.concat(items) : items;
			l.nextPageUrl = nextPageUrl;
			l.numberOfItems = numberOfItems;

            l.isLoading = false;
            this.errorMessage = null;
		}
    }

    onClearSearch() {
        this.initStore("searchItems", {	pageSize: 100 });
    }

    /* Item */
	onFetchItem(entity) {
        this.item[entity] = {};
		this.item.isLoading = true;
	}

	onItemLoaded({ entity, model }) {
		this.item[entity] = model;
		this.item.isLoading = false;
	}

	// onItemSaved({ entity, model, originalId, targetStore = null }) {
	// 	const store = targetStore || `${entity}s`;

	// 	// const hasUpdatedStore = this.persistToStore(store, model); // UPDATES OR ADDS
	// 	const hasUpdatedStore = this.updateStore(store, model); // ONLY UPDATES

	// 	// We might have several editor stores listening for the same Editor Component
	// 	// action so we need to bail out if this regards another entity.
	// 	if(!hasUpdatedStore) {
	// 		return false;
	// 	}

	// 	this.item.isLoading = false;
    // }

    onRemoveItem({ entity, id, targetStore }) {
		this.isLoading = true;

		const item = this.getItem(entity, id, targetStore);
		item._isHidden = true;
    }
	onItemRemoved({ entity, id, targetStore }) {
		this.isLoading = false;

		this.removeItem(entity, id, targetStore);
	}
	onRollbackRemoveItem({ entity, id, targetStore }) {
		this.isLoading = false;

		const item = this.getItem(entity, id, targetStore);
		delete item._isHidden;
	}

    // ALBUMS
    onAddSelectedToAlbum(album) {
        const albumId = album.id;
        console.log("Add to %o", album);
    }

    onAddSelectedToAlbumDone(updatedAlbum) {
        // Update or add to list
        const albumIndex = this.list.albums.items.findIndex(a => a.id === updatedAlbum.id);
        if (albumIndex >= 0) {
            this.list.albums.items[albumIndex] = updatedAlbum;
        } else {
            this.list.albums.items.push(updatedAlbum);
        }

        // Update item
        if (this.item.album.id === updatedAlbum.id) {
            this.item.album = updatedAlbum;
        }
    }

    onShareAlbum(recipients) {
        if(recipients) {
            const currentRecipients = this.item.album.recipients;
            this.item.album.recipients = `${recipients}${currentRecipients ? "," + currentRecipients : ""}`;
        }
    }

    onCreateAlbumDone(album) {
        this.list.albums.items.unshift(album);
        this.list.albums.numberOfItems++;
    }

    /* =============== */
    /* RECENT ACTIVITY */
    /* =============== */
    // onFetchRecentActivity() {
    //     this.list.recentActivity.isLoading = true;
    // }

    // onRecentActivityLoaded(items) {
    //     this.list.recentActivity.items = items.map(({id, assets, displayName}) => ({
    //         id,
    //         assets,
    //         displayName,
    //         href: `/star2/container/${id}/edit`,
    //         // parent: item.parent && {
    //         //     displayName: item.parent.displayName,
    //         //     href: `/star/container/${item.parent.id}`,
    //         //     parent: item.parent.parentId && {
    //         //         displayName: item.parent.parentDisplayName,
    //         //         href: `/star/container/${item.parent.parentId}`
    //         //     }
    //         // }
    //     }));

    //     this.list.recentActivity.isLoading = false;
    // }

    /* ================= */
    /*     WORKLISTS     */
    /* ================= */
    // onFetchWorklists() {
    //     this.worklistsLoading = true;
    // }

    // onWorklistsLoaded(items) {
    //     this.worklists = items;
    //     this.worklistsLoading = false;
    // }

    /* ================= */
    /* RECENT CONTAINERS */
    /* ================= */
    // onFetchRecentContainers() {
    //     this.recentContainersLoading = true;
    // }

    // onRecentContainersLoaded(items) {
    //     this.recentContainers = items;
    //     this.recentContainersLoading = false;
    // }


    /* =============== */
    /*    GENERAL      */
    /* =============== */
    onUnmount() {

        // this.worklists = [];
        // this.worklistsLoading = false;

        // this.recentContainers = [];
        // this.recentContainersLoading = false;

    }

    initStore(store, filters = null, items = []) {
		this.list[store] = {
            items,
			nextPageUrl: null,
			numberOfItems: 0,
            latestRequestTime: null,
            latestSearchPayload: null,
            filters,
            isLoading: false,
		};
    }

    onPersist({ store, model, prepend = false }) {
		const storeItems = this.list[store].items;
		if(!storeItems) {
			return false;
		}

		const index = storeItems.findIndex(si => si.id === model.id);

		if(index >= 0) {
			storeItems[index] = model;
        }
        else if(prepend) {
			storeItems.unshift(model);
        }
        else {
            storeItems.push(model);
        }

		return true;
    }

    // updateStore(store, model) {
	// 	const storeItems = this.list[store] ? this.list[store].items : this[store];

	// 	if(!storeItems) {
	// 		return false;
	// 	}

	// 	let didUpdate = false;
	// 	storeItems.forEach((item, index) => {
	// 		if(item.id === model.id) {

	// 			storeItems[index] = {
	// 				...model,
	// 				type: item.type, // Never change the item type (since it could be a template)
	// 			};

	// 			didUpdate = true;
	// 		}
	// 	});

	// 	return didUpdate;
    // }
    // HELPERS
	getItem(entity, id, targetStore) {
        const store = this.list[targetStore || `${entity}s`];
        if(!store.items) {
            return null;
        }
		return store.items.find(e => e.id === id);
	}

	removeItem(entity, id, targetStore) {
        const store = this.list[targetStore || `${entity}s`];
        if(!store.items) {
            return null;
        }
		const index = store.items.findIndex(e => e.id === id);
		store.items.splice(index, 1);
	}
}

// HELPERS


const getPlaceholderItems = numberOfItems => {
    let arr = new Array(numberOfItems);
    for(let i = 0; i < numberOfItems; i++) {
        arr[i] = { id: -i, };
    }
    return arr;
}

export default alt.createStore(StarStore, '[Star2]Store');
import React from 'react'
import { withRouter } from 'react-router'

import alt from '../../../core/services/alt'
import withDnDContext from '../../../core/services/withDnDContext'
import { getStarRole, hasStarEditAccess } from '../shared/utils'

import App from '../../../components/app'
import { UICenter } from '../../../components/ui/alignment'

import ContainerNavigation from './containerNavigation'
import Header from './header'
import Assets from './assets'
import EditorAssetDialog from '../../../components/assets/asseteditor/dialog'
import CustomDragLayer from '../../../components/list/dragLayer'

import ContainerActions from './actions'
import ContainerStore from './store'
import StarActions from '../actions'
import StarStore from '../store'

import './app.css'

@withRouter
class Star2Container extends React.Component {

	constructor(props) {
		super(props);

		this.containerId = parseInt(this.props.params.id || -1);

		this.state = {
			containerState: ContainerStore.getState(),
			starState: StarStore.getState(),
		};
	}

	componentDidMount() {
		ContainerStore.listen(this.onContainerStateChange);
		StarStore.listen(this.onStarStateChange);

		if(!getStarRole()) {
			return;
		}

		const { params, location } = this.props;
		const { types, container } = this.state.containerState;

		this.containerType = this.props.location?.state?.typeName;

		// If no types are loaded, fetch them
		// if(types.length <= 0) {
		// 	ContainerActions.fetchTypes();
		// }

		// If there is no container data, get container and possible child containers by id or reference id
		if(!container.id) {
			const { reference, provider } = params;

			if(reference && provider) {
				ContainerActions.fetchContainer(null, reference, provider, this.containerType);
			}
			else if(this.containerId > 0) {
				ContainerActions.fetchContainer(this.containerId);
				ContainerActions.fetchChildContainers.defer(this.containerId);
			}
		}
	}

	componentDidUpdate() {
		const { params, location } = this.props;
		const nextContainerId = parseInt(params.id);

		if (nextContainerId !== this.containerId) {
			this.containerId = nextContainerId;

			if (this.containerId > 0) {
				ContainerActions.fetchContainer(this.containerId);
				if (location?.state?.fetchChildren) {
					const orderBy = "title";
					ContainerActions.fetchChildContainers.defer(this.containerId, orderBy);
				}
			}
		}
	}

	componentWillUnmount() {
		ContainerStore.unlisten(this.onContainerStateChange);
		StarStore.unlisten(this.onStarStateChange);
		alt.recycle(ContainerStore);
	}

	onContainerStateChange = (state) => {
		this.setState({ containerState: state });
	}

	onStarStateChange = (state) => {
		this.setState({ starState: state });
	}

	render() {
		const {
			typesIsLoading,
			// types,
			containerIsLoading,
			container = {},

			childContainersIsLoading,
			childContainers,

			list,

			displayAssetId,
			// assetReadOnly = false, // Should come from the rendering of the asset

		} = this.state.containerState;

		// const { params } = this.props;
		// const customer = params && params.customer;

		const { selected } = this.state.starState.list;

		const isLoading = typesIsLoading || !!containerIsLoading;
		const starRole = getStarRole();

		if(!starRole) {
			return (
				<App name="no-access" isLoading={isLoading}>
					<UICenter>
						No read or editor access.
						Please contact <a className="c6-link" href="mailto:support@junefirst.tv">support@junefirst.tv</a> if you believe you should have access to this editor.
					</UICenter>
				</App>
			);
		}

		const canEdit = hasStarEditAccess();

		if (!this.props.location.pathname.includes("create") && !containerIsLoading && !container.id) {
			return (
				<App name="c6-star2-container">
					<div className="no-asset-message">
						<h2 className="icon-security">Could not load container with id {this.containerId}</h2>
						<p>You either don't have access to this container or it doesn't exist.</p>
					</div>
				</App>
			);
		}

		const containerType = this.props.location.query?.containerType || "Folder";

		return (
			<App name="c6-star2-container">

				<ContainerNavigation
					loadingContainerId={containerIsLoading || childContainersIsLoading}
					container={container}
					childContainers={childContainers}
					hasEditAccess={canEdit}
					selected={selected}
				/>

				<Header
					isLoading={isLoading}
					container={container}
					hasEditAccess={canEdit}
					containerType={containerType}
				/>

				{container.id && <Assets
					isLoading={!!containerIsLoading}
					container={container}
					assets={container.assets}
					selected={selected}
					hasEditAccess={canEdit}
					// Add a key so that the asset list is replaced with a new instance when we change to a different container,
					// otherwise the user will open the new container at the same scroll position as the previous container
					key={container.id}
				/>}

				{container.id && <EditorAssetDialog
					open={!!displayAssetId}
					onClose={ContainerActions.closeAsset}
					assetId={displayAssetId}
					disabled={!canEdit || !!container.approved}
					// disabled={!canEdit || !!container.approved || !!assetReadOnly}
				/>}

				<CustomDragLayer />

			</App>
		);
	}
}

export default withDnDContext(Star2Container);
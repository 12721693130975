import React, { Component } from 'react'
import PropTypes from 'prop-types'
import InfiniteScroll from 'react-infinite-scroller'
import { useVirtual } from 'react-virtual'
import groupBy from 'lodash/groupBy'
import sortBy from 'lodash/sortBy'
import throttle from 'lodash/throttle'

import { ItemGroup } from '../list/listItems'
import Empty from '../list/empty'

import DefaultItem from './defaultItem'

const List = React.forwardRef((props, ref) => {
	const {
		items,
		isLoading,
		textEmpty = "Sorry, could not find any items.",
		hasNextPage,
		fetchNextPage,
		filters,

		virtualize = false,
		groupItemsBy,
		getGroupTitle,
		getGroupTitleAdvanced,
		getGroupTitleWhenSearching,
		sortItemsBy,
		sortItemsByAdvanced,
		groupItemsWhenSearching,
		padding = false,
		onToggleContainer,
	} = props;

	const className = `c6-main ${!(items && items.length) ? "center c6-color-light" : ""} ${padding ? "pad26" : ""}`;

	const filteredItems = items ? items.filter(item => !item._isHidden) : [];

	const throttledFetchNextPage = React.useRef(throttle(
		() => fetchNextPage(),
		1000,
		{ trailing: false }
	));

	const rowVirtualizer = useVirtual({
		size: items.length,
		overscan: 20,
		parentRef: ref,
	});

	if (filteredItems.length) {
		if (!virtualize) {
			return (
				<div className={className} ref={ref}>
					<InfiniteScroll
						loadMore={() => throttledFetchNextPage.current()}
						hasMore={hasNextPage}
						loader={<div className="infinite-loader" key="infinite-loader">Loading ...</div>}
						useWindow={false}
						threshold={700}
						initialLoad={false}
					>
						{renderGroups({
							items: filteredItems,
							children: props.children,
							groupItemsBy,
							getGroupTitle,
							getGroupTitleAdvanced,
							getGroupTitleWhenSearching,
							sortItemsBy,
							sortItemsByAdvanced,
							filters,
							groupItemsWhenSearching,
							onToggleContainer,
						})}
					</InfiniteScroll>
				</div>
			);
		}

		// TODO: Handle grouped items with virtualized list. If possible?
		const { totalSize, virtualItems } = rowVirtualizer;
		const sortedItems = getSortedItems(filteredItems, sortItemsBy, sortItemsByAdvanced);

		return (
			<div
				ref={ref}
				className={className}
				style={{ height: "100%", overflow: "auto", width: "100%" }}
			>
				<InfiniteScroll
					loadMore={() => throttledFetchNextPage.current()}
					hasMore={hasNextPage}
					loader={<div className="infinite-loader" key="infinite-loader">Loading ...</div>}
					useWindow={false}
					threshold={700}
					initialLoad={false}
				>
					<div
						style={{
							height: `${totalSize}px`,
							width: "100%",
							position: "relative",
						}}
					>
						{virtualItems.map(virtualRow => {
							const { index, measureRef, start } = virtualRow;
							return (
								<div
									key={index}
									ref={measureRef}
									style={{
										position: "absolute",
										top: 0,
										left: 0,
										width: "100%",
										transform: `translateY(${start}px)`
									}}
								>
									{renderItem(sortedItems[index], props.children, null, onToggleContainer)}
								</div>
							);
						})}
					</div>
				</InfiniteScroll>
			</div>
		);
	}

	return (
		<div className={className}>
			<Empty isLoading={isLoading}>{textEmpty}</Empty>
		</div>
	);
});

export default List;

List.propTypes = {
	items: PropTypes.array.isRequired,
	isLoading: PropTypes.bool.isRequired,
	textEmpty: PropTypes.string,
};

function renderGroups({
	items,
	children,
	groupItemsBy = () => 0,
	getGroupTitle = () => "",
	getGroupTitleAdvanced = null,
	getGroupTitleWhenSearching = null,
	sortItemsBy = null,
	sortItemsByAdvanced = null,
	filters,
	groupItemsWhenSearching = false,
	onToggleContainer,
}) {
	if (filters?.values?.searchText?.length && !groupItemsWhenSearching) {
		const title = getGroupTitleWhenSearching
			? getGroupTitleWhenSearching()
			: <span>Items matching "{filters?.values?.searchText}"</span>;
		return (
			<ItemGroup title={title}>
				{items.map((item, index) => renderItem(item, children, index, onToggleContainer))}
			</ItemGroup>
		);
	}

	const groupedItems = groupBy(items, groupItemsBy);
	const sortedKeys = sortBy(
		Object.keys(groupedItems),
		key => items.indexOf(groupedItems[key][0]),
	);

	return sortedKeys.map(groupKey => {
		const groupItems = getSortedItems(groupedItems[groupKey], sortItemsBy, sortItemsByAdvanced);
		const groupTitle = typeof getGroupTitleAdvanced === "function"
			? getGroupTitleAdvanced(groupedItems[groupKey])
			: getGroupTitle(groupKey);
		
		return (
			<ItemGroup key={groupKey} title={groupTitle}>
				{groupItems.map((item, index) => renderItem(item, children, index, onToggleContainer))}
			</ItemGroup>
		);
	});
}

function renderItem(item, children, index, onToggleContainer) {
	if (children) {
		return React.cloneElement(children, {
			...item,
			key: item.id ?? index,
			actionData: item,
			itemData: item,
			onToggleContainer,
		});
	}

	return (
		<DefaultItem
			{...item}
			key={item.id ?? index}
			actionData={item}
		/>
	);
}

function getSortedItems(items, sortItemsBy, sortItemsByAdvanced) {
	if (sortItemsByAdvanced) {
		return [...items].sort(sortItemsByAdvanced);
	}
	
	if (sortItemsBy) {
		return [...items].sort((a, b) => sortItemsBy(a) - sortItemsBy(b));
	}

	return items;
}
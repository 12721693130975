import React from 'react'

import ContentItem from '../list/contentItem'
import '../listItems/standard.css'

import { getTemplateWithValues } from '../../core/cms/utils'
import CMSComponent from './component'
import { getClassName } from './utils'

const CMSEventItem = React.memo((props) => {
	const {
		template,
		itemData,
		module,
		collection,
		...otherProps
	} = props;

	const rootNodes = React.useMemo(() => getTemplateWithValues(template?.children ?? [], itemData), [template, itemData]);
	const className = React.useMemo(() => getClassName({ item: template, root: true, value: itemData }), [template, itemData]);

	const onChange = React.useCallback(
		async ({ data, path, actionName, actionText, replaceObjectFields, refreshAllItemsAfterUpdate }) => {
			await props.onChange({
				id: itemData.id,
				data,
				path,
				originalItem: itemData,
				actionName,
				actionText,
				replaceObjectFields,
				refreshAllItemsAfterUpdate,
			});
		},
		[itemData]
	);

	const components = React.useMemo(
		() => rootNodes?.map(rootNode => (
			<CMSComponent
				key={rootNode.name}
				templateItem={rootNode}
				onChange={onChange}
				otherProps={otherProps}
				itemData={itemData}
				parentReadOnly={rootNode.readOnly ?? itemData.dynamicReadOnly}
			/>
		)),
		[rootNodes, itemData, onChange]
	);

	const refreshIntervalRef = React.useRef();
	React.useEffect(
		() => {
			const refreshInterval = itemData.refreshIntervalSeconds && props.refreshItem
				? parseInt(itemData.refreshIntervalSeconds) * 1000
				: 0;
			if (refreshInterval > 0) {
				refreshIntervalRef.current = setInterval(
					() => props.refreshItem(itemData),
					refreshInterval,
				);
			} else {
				clearInterval(refreshIntervalRef.current);
			}

			// Cleanup function: clear interval when item is unmounted
			return () => {
				clearInterval(refreshIntervalRef.current);
			};
		},
		[itemData.refreshIntervalSeconds, itemData.id, props.refreshItem]
	);

	return (
		<ContentItem
			data={props}
			extraClasses={className}
			module={module}
			collection={collection}
			openModal={otherProps.openModal}
		>
			{components}
		</ContentItem>
	);
});

export default CMSEventItem;
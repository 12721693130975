import React from 'react'
import Radio from '@mui/material/Radio'
import FormControlLabel from '@mui/material/FormControlLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import ListSubheader from '@mui/material/ListSubheader'
import groupBy from 'lodash/groupBy'

import MuiAutoComplete from '../../../components/ui/editorWidgets/muiAutoComplete'

import useCMSSportSeries from '../../../core/queries/cms/useCMSSportSeries'
import useCMSSeasons from '../../../core/queries/cms/useCMSSeasons'
import useCMSGenres from '../../../core/queries/cms/useCMSGenres'
import useSportSeriesList from '../../../core/queries/sport/useSportSeriesList'
import useSportSeasons from '../../../core/queries/sport/useSportSeasons'
import useMetadataPrograms from '../../../core/queries/metadata/useMetadataPrograms'

const NEW_SERIES_OPTION = "__new_series_option__";
const NEW_SEASON_OPTION = "__new_season_option__";
const DEFAULT_GENRE_OPTION = "__default_genre_option__";

export default function StepOne(props) {
	const [mode, setMode] 							= React.useState("multiple");
	const [seriesId, setSeriesId] 					= React.useState(NEW_SERIES_OPTION);
	const [seasonId, setSeasonId] 					= React.useState(NEW_SEASON_OPTION);
	const [newSeriesName, setNewSeriesName] 		= React.useState("");
	const [newSeasonName, setNewSeasonName] 		= React.useState("");
	const [newSeriesGenreId, setNewSeriesGenreId] 	= React.useState(DEFAULT_GENRE_OPTION);
	const [leagueId, setLeagueId]					= React.useState(null);
	const [leagueSeasonId, setLeagueSeasonId]		= React.useState(null);
	const [metadataSeriesSearchText, setMetadataSeriesSearchText] = React.useState("");
	const [metadataSeriesGuid, setMetadataSeriesGuid] = React.useState(null);
	const [metadataSeasonGuid, setMetadataSeasonGuid] = React.useState(null);

	const { data: seriesData } = useCMSSportSeries(props.seriesFilters);
	const seriesItems = getMenuItems(
		seriesData?.items?.sort((a, b) => a.name.localeCompare(b.name)).sort((a, b) => a.genre?.name.localeCompare(b.genre?.name)),
		item => item.genre?.name ?? " "
	);
	seriesItems.unshift(<MenuItem key={NEW_SERIES_OPTION} value={NEW_SERIES_OPTION}>The new series</MenuItem>);

	const { data: seasonData } = useCMSSeasons(
		seriesId === NEW_SERIES_OPTION ? undefined : seriesId,
		seriesId !== NEW_SERIES_OPTION
	);
	const seasonItems = seasonData?.items?.map(s => (
		<MenuItem key={s.id} value={s.id}>{s.name}</MenuItem>
	)) ?? [];
	seasonItems.unshift(<MenuItem key={NEW_SEASON_OPTION} value={NEW_SEASON_OPTION}>The new season</MenuItem>);

	const { data: genreData } = useCMSGenres(props.genreFilters);
	const genreItems = genreData?.items?.map(g => (
		<MenuItem key={g.id} value={g.id}>{g.name}</MenuItem>
	)) ?? [];
	genreItems.unshift(<MenuItem key={DEFAULT_GENRE_OPTION} value={DEFAULT_GENRE_OPTION}>Genre unspecified</MenuItem>);

	const { data: leagueData } = useSportSeriesList({
		filter: { type: "league", orderBy: "unspecified-first-then-by-title" },
		enabled: mode === "score24",
	});
	const leagueItems = getMenuItems(
		leagueData?.items?.sort((a, b) => a.name.localeCompare(b.name)).sort((a, b) => a.genre?.name.localeCompare(b.genre?.name)),
		item => item.genre?.name ?? " "
	);

	const { data: leagueSeasonsData } = useSportSeasons({
		filter: { seriesId: leagueId },
		enabled: mode === "score24" && leagueId !== null,
	});
	const leagueSeasonItems = leagueSeasonsData?.items?.map(s => (
		<MenuItem key={s.id} value={s.id}>{s.title ?? s.name}</MenuItem>
	)) ?? [];

	const { data: metadataSeriesData, fetchStatus: metadataSeriesFetchStatus } = useMetadataPrograms({
		filter: { searchText: metadataSeriesSearchText, programType: "series" },
		enabled: mode === "metadata" && metadataSeriesSearchText.length > 1,
	});
	const metadataSeriesItems = metadataSeriesData?.items?.map(s => (
		{ id: s.guid, displayName: s.displayName }
	)) ?? [];

	const { data: metadataSeasonsData } = useMetadataPrograms({
		filter: { parentProgramGuid: metadataSeriesGuid, pageSize: 1000 },
		enabled: mode === "metadata" && metadataSeriesGuid !== null,
	});
	const metadataSeasonItems = metadataSeasonsData?.items?.map(s => (
		<MenuItem key={s.guid}value={s.guid}>{s.displayName ?? s.name}</MenuItem>
	)) ?? [];

	if (!props.active) {
		return null;
	}

	const content = (
		<div>
			{mode === "score24" && (
				<div className="radio-sub">
					<span>from</span>
					<Select
						className="c6-mui-select"
						value={leagueId ?? ""}
						onChange={(e) => setLeagueId(e.target.value)}
						variant="standard"
						placeholder="Choose league/sport to import"
						MenuProps={{ className: "c6-mui-select-menu" }}
						autoWidth={true}
						sx={{ minWidth: "200px" }}
					>
						{leagueItems}
					</Select>
					<Select
						className="c6-mui-select"
						value={leagueSeasonId ?? ""}
						onChange={(e) => setLeagueSeasonId(e.target.value)}
						variant="standard"
						placeholder="Choose season"
						MenuProps={{ className: "c6-mui-select-menu" }}
						autoWidth={true}
						sx={{ minWidth: "200px" }}
					>
						{leagueSeasonItems}
					</Select>
					
				</div>
			)}
			{mode === "metadata" && (
				<div className="radio-sub">
					<span>from</span>
					<MuiAutoComplete
						filter={() => true}
						placeholder="Search for a series"
						options={{
							openOnFocus: true,
							dataSource: metadataSeriesItems,
							dataSourceConfig: { text: "displayName", value: "id" },
							fetch: searchText => {
								setMetadataSeriesSearchText(searchText);
							},
							remove: () => {},
						}}
						isLoading={metadataSeriesFetchStatus === "fetching"}
						onChange={(guid) => setMetadataSeriesGuid(guid)}
					/>
					<Select
						className="c6-mui-select"
						value={metadataSeasonGuid ?? ""}
						onChange={(e) => setMetadataSeasonGuid(e.target.value)}
						variant="standard"
						MenuProps={{ className: "c6-mui-select-menu" }}
						autoWidth={true}
						sx={{ minWidth: "200px" }}
					>
						{metadataSeasonItems}
					</Select>
				</div>
			)}
			<div className="radio-sub">
				{["score24", "metadata"].includes(mode) && <span>into</span>}
				{mode === "multiple" && <span>in</span>}
				<Select
					className="c6-mui-select"
					value={seriesId ?? ""}
					onChange={(e) => setSeriesId(e.target.value)}
					variant="standard"
					MenuProps={{ className: "c6-mui-select-menu" }}
					autoWidth={true}
					sx={{ minWidth: "200px" }}
				>
					{seriesItems}
				</Select>
				{seriesId === NEW_SERIES_OPTION && (
					<TextField
						variant="standard"
						className="c6-mui"
						name="newSeriesName"
						value={newSeriesName}
						onChange={e => setNewSeriesName(e.target.value)}
						style={{ width: "auto" }}
						placeholder="New series name"
					/>
				)}
				{seriesId !== NEW_SERIES_OPTION && (
					<Select
						className="c6-mui-select"
						value={seasonId ?? ""}
						onChange={(e) => setSeasonId(e.target.value)}
						variant="standard"
						MenuProps={{ className: "c6-mui-select-menu" }}
						autoWidth={true}
						sx={{ minWidth: "200px" }}
					>
						{seasonItems}
					</Select>
				)}
				{(seriesId === NEW_SERIES_OPTION || seasonId === NEW_SEASON_OPTION) && (
					<TextField
						variant="standard"
						className="c6-mui"
						name="newSeasonName"
						value={newSeasonName}
						onChange={e => setNewSeasonName(e.target.value)}
						style={{ width: "auto" }}
						placeholder="New season name"
					/>
				)}
				{seriesId === NEW_SERIES_OPTION && (
					<Select
						className="c6-mui-select"
						value={newSeriesGenreId ?? ""}
						onChange={(e) => setNewSeriesGenreId(e.target.value)}
						variant="standard"
						MenuProps={{ className: "c6-mui-select-menu" }}
						autoWidth={true}
						sx={{ minWidth: "200px" }}
					>
						{genreItems}
					</Select>
				)}
			</div>
		</div>
    );
    
    const actions = props.getActions({
		nextDisabled: mode !== "single" && (
			seriesId === NEW_SERIES_OPTION && !newSeriesName.length
			|| seasonId === NEW_SEASON_OPTION && !newSeasonName.length
			|| mode === "score24" && (leagueId === null || leagueSeasonId === null)
			|| mode === "metadata" && metadataSeasonGuid === null
		),
        onNextClick: () => props.onNextClick({
			mode: mode,
			league: leagueId === null
				? null
				: leagueData.items.find(l => l.id === leagueId),
			leagueSeason: leagueSeasonId === null
				? null
				: leagueSeasonsData.items.find(s => s.id === leagueSeasonId),
			newSeriesGenre: newSeriesGenreId === DEFAULT_GENRE_OPTION
				? null
				: genreData.items.find(g => g.id === newSeriesGenreId),
            series: seriesId === NEW_SERIES_OPTION
                ? { name: newSeriesName }
                : seriesData.items.find(s => s.id === seriesId),
            season: seasonId === NEW_SEASON_OPTION
                ? { name: newSeasonName }
                : seasonData.items.find(s => s.id === seasonId),
			metadataSeasonGuid,
        }),
    });

	return (
		<div className="step">
			<div className="content">
				<FormControlLabel
					className="radio"
					name="mode"
					checked={mode === "single"}
					label="Single event"
					onClick={() => setMode("single")}
					control={<Radio />}
				/>
				<FormControlLabel
					className="radio"
					name="mode"
					checked={mode === "multiple"}
					label="Series of events / Add events to an existing series"
					onClick={() => setMode("multiple")}
					control={<Radio />}
				/>
				{mode === "multiple" && content}
				{props.importTypes.includes("score24") && (
					<FormControlLabel
						className="radio"
						name="mode"
						checked={mode === "score24"}
						label="Import events from external provider (Score 24)"
						onClick={() => setMode("score24")}
						control={<Radio />}
					/>
				)}
				{mode === "score24" && content}
				{props.importTypes.includes("metadata") && (
					<FormControlLabel
						className="radio"
						name="mode"
						checked={mode === "metadata"}
						label="Import events from the Metadata module"
						onClick={() => setMode("metadata")}
						control={<Radio />}
					/>
				)}
				{mode === "metadata" && content}
			</div>

            {actions}
		</div>
	);
}

function getMenuItems(items, groupByProperty) {
	if (groupByProperty) {
		const grouped = groupBy(items ?? [], groupByProperty);
		return Object.keys(grouped).reduce((acc, curr) => (
			[...acc, <ListSubheader key={`group-${curr}`}>{curr}</ListSubheader>, ...getMenuItems(grouped[curr])]
		), []);
	}

	return (items ?? []).map(i => (
		<MenuItem
			key={`item-${i.id}`}
			value={i.id}
		>
			{i.title ?? i.name}
		</MenuItem>
	));
}
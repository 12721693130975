import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'

import ContentItem, { Content } from '../../../../components/list/contentItem'

import styles from '../../../../components/listItems/standard.css'

const LeagueItem = (props) => {
	const {
		itemData,
	} = props;
	
	const {
		id,
		title,
		name,
		parent,
		genre,
	} = itemData;

	return (
		<ContentItem data={props}>
			<Content>
				<h1>{title ?? name}</h1>
				<p>
					<Link
						className="c6-link"
						to={{
							pathname: `/eventplanner/admin/participants`,
							query: { seriesId: id }
						}}
					>Display teams/individuals</Link>
				</p>
			</Content>
		</ContentItem>
	);
};

export default LeagueItem;
import { MENU } from '../../../components/actions'

import Actions from '../actions'

import appConfig from 'config'

const ENTITY = "license";

export default appConfig.features && !appConfig.features.acqLight
	? {
		targetComponent: "Content",
		actions: [
			{
				title: "Edit license",
				icon: "create",
				method: (data, sourceProps, location, params) => {
					const targetStore = sourceProps.parent ? "licenseEpisodes" : undefined;

					Actions.edit({
						id: data.id,
						returnTo: `${location.pathname}${location.search}`,
						targetStore,
					});
				},
				type: MENU,
			},
			{
				title: "Delete license",
				confirm: true,
				dangerous: true,
				icon: "delete",
				method: (data, sourceProps, location, params) => {
					const targetStore = sourceProps.parent ? "licenseEpisodes" : undefined;
					Actions.removeItem(ENTITY, data, targetStore);
				},
				type: MENU,
			},
		]
	}
	: {
		actions: [],
	};
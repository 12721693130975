import Moment from 'moment'
import appConfig from 'config'

import { getRouteName } from '../../../components/comet'

export function getSearchPayload({ filters = {} }) {
	const { filter, ...rest } = filters;
	return {
		...rest,
		...filter,
	};
}

export function getInfo(location, state, entityOrEntityTarget) {
	const multiple = true;
	const { entity, store } = getEntityAndStore(entityOrEntityTarget, multiple);

	const ds = state[store];
	const numberOfItems = state[`${store}Count`]
	const items = ds && ds.length ? `- displaying ${ds.length} of ${numberOfItems}` : "";

	return `${getRouteName(location)} ${items}`;
}

export function getStore(entity, multiple = false) {
	const extra = multiple ? "s" : "";
	if(typeof(entity) === "object") {
		return `${entity.entity}${extra}`;
	}
	return `${entity}${extra}`;
}

export function getEntityAndStore(entity, multiple, targetStore) {
	return typeof(entity) === "object"
		? entity
		: { entity, store: targetStore || getStore(entity, multiple) };
}

export function getLicenseClass() {
	return [
		{
			key: "A",
			text: "A",
			description: "This is a very high priority license.",
		},
		{
			key: "B",
			text: "B",
			description: "This is a high priority license.",
		},
		{
			key: "C",
			text: "C",
			description: "This is a normal priority license.",
		},
		{
			key: "D",
			text: "D",
			description: "This is a low priority license.",
		},
	];
}

export function getDeviceRights() {
	return [
		{
			key: 5,
			text: "STB",
			description: "This license window has a STB right.",
		},
		{
			key: 6,
			text: "Mobile",
			description: "This license window has a mobile right.",
		},
		{
			key: 7,
			text: "Web",
			description: "This license window has a web right.",
		},
		{
			key: 8,
			text: "Smart TV",
			description: "This license window has a Smart TV right.",
		},
		{
			key: 9,
			text: "Tablet",
			description: "This license window has a tablet right.",
		},
		{
			key: 10,
			text: "Phone",
			description: "This license window has a mobile phone right.",
		},
	];
}

export function getFilters() {
	return [
		{
			key: "",
			text: "Any",
			description: "",
		},
		{
			key: "active",
			text: "Current",
			description: "License has a current window.",
		},
		{
			key: "upcoming",
			text: "Upcoming",
			description: "License has an upcoming license window.",
		},
		{
			key: "premieres",
			text: "Premieres",
			description: "License has a window beginning during a specific month.",
		},
		{
			key: "expired",
			text: "Expired",
			description: "License has no active windows.",
		},
	];
}

export function getProvider() {
	if(appConfig.features && !appConfig.features.acqLight) {
		return [
			{
				key: "",
				text: "Any",
				description: "Any provider",
			},
			{
				key: "1",
				text: "Acquisitions",
				description: "License contract comes from Acquisitions (ACQ).",
			},
			{
				key: "2",
				text: "Louise",
				description: "License contract comes from Louise.",
			},
			{
				key: "3",
				text: "SF Kids",
				description: "License contract comes from SF Kids.",
			},
		];
	}
	else {
		return [
			{
				key: "",
				text: "Any",
				description: "Any provider",
			},
			{
				key: "2",
				text: "Louise",
				description: "License contract comes from Louise.",
			},
			{
				key: "3",
				text: "SF Kids",
				description: "License contract comes from SF Kids.",
			},
			{
				key: "6",
				text: "WHATS'ON",
				description: "License contract comes from WHATS'ON.",
			},
		];
	}
}

export function getWindowTypeClass() {
	return [
		{
			key: "",
			text: "Any",
			description: "Any license type.",
		},
		{
			key: "svod",
			text: "SVOD",
			description: "A SVOD license.",
		},
		{
			key: "avod",
			text: "AVOD",
			description: "An AVOD license.",
		},
		{
			key: "linear",
			text: "Linear",
			description: "A linear license.",
		},
		{
			key: "catchup",
			text: "Catchup",
			description: "A catchup license.",
		}
	]
}

export function getPremiere() {
	const premieres = [
		// {
		// 	key: "upcoming",
		// 	text: "Upcoming",
		// 	description: "Program has an upcoming premiere sometime in the future.",
		// }
	];

	let month = Moment().startOf("month");
	for(let i = 0; i <= 3; i++) {
		premieres.push({
			key: month.format("YYYY-MM-DD"),
			text: month.format("MMMM"),
			description: "Program premieres in " + month.format("MMMM"),
		})
		month = month.add(1, "months").startOf("month");
	}
	return premieres;
}

const SWEDISH = 1;
export function getSwedishTitle(versions, originalTitle) {
	const swedish = versions ? versions.filter(v => v.versionId === SWEDISH) : [];
	return swedish[0] && swedish[0].title
		? swedish[0].title
		: originalTitle || '';
}

export const DownloadRightsAttributes = [
	{ key: "timeToLiveDownloadedAfterStart", text: "TTL Downloaded after start", unit: "hour" },
	{ key: "maxNumberOfDownloads", text: "Max number of downloads" },
	{ key: "maxConcurrentDownloads", text: "Max concurrent downloads" },
	{ key: "maxConcurrentDevicesPerDownload", text: "Max concurrent devices per download", unit: "device" },
	{ key: "maxTotalDurationDownloaded", text: "Max total duration downloaded", unit: "hour" },
	{ key: "timeToKeepDownloaded", text: "Time to keep downloaded", unit: "hour" },
];

export function getDownloadRightsStates() {
	return [
		{ key: "", text: "Any", description: "" },
		{ key: "true", text: "Yes", description: "" },
		{ key: "false", text: "No", description: "" },
	];
}
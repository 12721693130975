import * as VodCoreAPI from '../../../apis/vodcore'
import { useQuery } from '@tanstack/react-query'

interface args {
    filter?: object;
    enabled?: boolean;
}

const useVodCoreLibraries = ({ filter = {}, enabled = true }: args = {}) => {
    const filters = {
        ...filter,
    };
    return useQuery(
        ["vodcore", "libraries", filters],
        () => VodCoreAPI.fetchLibraries(filters),
        { enabled }
    );
};

export default useVodCoreLibraries;

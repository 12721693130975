import React from 'react'

import DropdownControl from '../../ui/controls/dropdown'
import * as CMSAPI from '../../../apis/cms'

import isEqualWith from 'lodash/isEqualWith'
import isEqual from 'lodash/isEqual'

// TODO: This component was created in a hurry to give EDI a working dropdown for a template.
// We should maybe use something that looks more similar to our other CMS components.
const Dropdown = (props) => {
	const {
		className,
		onChange,
		displayName,
		name,
		readOnly,
        valuesUrl,
		style,
		value,
        options,
        itemData,
        multiValue,
        nullable,
	} = props;

	const {
		visible = true, // Handle style.visible in item.jsx instead of per component?
	} = style;

    const [newValue, setNewValue] = React.useState(value);

    React.useEffect(
        () => {
            setNewValue(value);
        }, [value]
    );

    const [optionsState, setOptionsState] = React.useState(null);
    React.useEffect(
        () => {
            if (valuesUrl && !readOnly) {
                const api = options?.api ?? "cms";
                const _module = options?._module ?? "eventplanner";
                const filter = options?.filter ?? {};

                CMSAPI.fetchCMSUrl(valuesUrl, _module, api, filter).then(data => { 
                    setOptionsState(data.items.map(item => {
                        return { 
                            key: item.id, 
                            val: (options?.valueProperty ? item[options?.valueProperty] : item), 
                            text: item.name 
                        }
                    }));
                });
            } else {
                setOptionsState(Object.entries(options?.values ?? {}).map(([key, text]) => ({ key, val: key, text })));
            }
        },
        [valuesUrl, readOnly]
    );

    const looseEquality = (val1, val2) => {
        if(typeof val1 == "object" && typeof val2 == "object") {
            return isEqual(val1, val2);
        } else {
            return val1 == val2;
        }
    }

    const onDropdownChange = React.useCallback(
        (e) => {
            let updatedValue = newValue;
            if(multiValue) {
                // Find if this value is already in the array
                const index = newValue.findIndex(f => isEqualWith(f, e.target.value, looseEquality));
                if(index >= 0) {
                    // Remove it
                    if(newValue.length != 1 || nullable) {
                        updatedValue = newValue.filter((f, i) => i != index);
                    }
                } else {
                    // Add it
                    updatedValue = [...newValue, e.target.value]; 
                }
            } else {
                updatedValue = e.target.value;
            }
            setNewValue(updatedValue);
            onChange(updatedValue);
        },
        [onChange]
    );

    // const states = React.useMemo(
    //     () => {
    //         return Object.entries(options?.values ?? {}).map(([key, text]) => ({ key, text }));
    //     },
    //     [options?.values]
    // );
	
	return (
		<div
			className={`${className} c6-cms-dropdown ${visible ? "" : "hidden"}`}
		>
            {
                optionsState?.length && (
                    <DropdownControl
                        name={`${name}-${itemData?.id}`}
                        title={displayName}
                        emptyStateText={displayName}
                        states={optionsState}
                        currentState={newValue}
                        onChange={onDropdownChange}
                        disabled={readOnly}
                        multiValue={multiValue}
                        nullable={nullable}
                        style={{ whiteSpace: "nowrap" }}
                        filterable={options?.filterable}
                        isItemCheckedFn={looseEquality}
                    />
                )
            }
		</div>
	);
};

// React.memo everything? Hmm...
export default React.memo(Dropdown);
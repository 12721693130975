import { CONTENT, INLINE, CLICK_OPT, CLICK_SHIFT } from '../../../../components/actions'
import Actions from '../../actions'
import Store from '../../store'

export default {
	targetComponent: "Item",
	actions: [
		// {
		// 	type: CONTENT,
		// 	title: "Display item",
		// 	method: Actions.edit,
		// },
		{
			// title: "Select item",
			type: INLINE,
			icon: "check_box_outline_blank",//"star_border",
			method: Actions.select,
			hidden: (data, sourceProps, location, params, itemProps) => {
				const album = Store.getState().item.album;
				return itemProps.selected || !album.type.includes("Download");
			},
		},
		{
			// title: "Unselect item",
			type: INLINE,
			icon: "check_box",//"star",
			method: Actions.unselect,
			hidden: (data, sourceProps, location, params, itemProps) => {
				const album = Store.getState().item.album;
				return !itemProps.selected || !album.type.includes("Download");
			},
		},
	]
};
import React from 'react'
import App from '../../../components/app'

import './app.css'

function Help(props) {
	const [isLoaded, setIsLoaded] = React.useState(false);

	return (
		<App name="c6-selections-help" layout="grid">
			<iframe
				src="client/help-pages/selections.html"
				onLoad={() => setIsLoaded(true)}
			/>
			{!isLoaded && <p>Loading help page...</p>}
		</App>
	);
}

export default Help;
import React from 'react'
import { withRouter } from 'react-router'
import { browserHistory } from 'browserHistory'
import debounce from 'lodash/debounce'

import Search from '../../../components/ui/controls/search'
import Pick from '../../../components/ui/controls/pick'
import Tags from '../../../components/ui/editorFields/tags'
import Create from './create'

import { starContentTypes, hasStarEditAccess } from './utils'

import * as StarAPI from '../../../apis/star'
import { handleRequestFailed } from '../../../core/services/errorhandling'

import './topsearch.css'
import appConfig from 'config'

@withRouter
export default class TopSearch extends React.Component {
	constructor(props) {
		super(props);

        this.debouncedSearch = debounce(this.search, 500);

		this.state = {
			starTags: null,
		};
	}

	componentDidMount() {
		if (appConfig.features && appConfig.features.starDAMFeatures) {
			StarAPI.fetchTags({ tagType: "metadata" }).then(response => {
				this.setState({ starTags: response.items });
			}, handleRequestFailed);
		}
	}

	componentWillUnmount() {
        this.debouncedSearch.cancel();
    }

    search(event) {
        const { type, value = "", checked } = event.target;
        let { pathname, query } = this.props.location;

        switch(type) {
            case "checkbox":
                const types = query.type && new Set(query.type.split(",")) || new Set();
                checked ? types.delete(value) : types.add(value);

                query = {
                    ...query,
                    type: types.size ? Array.from(types).join(",") : null,
                };
                break;
            case "search":
                pathname = `/star/search/${encodeURIComponent(value)}`;
                break;
			case "tags":
				query.tags = value.map(t => t.name).join(",");
				break;
        }
        if (!query.type) delete query.type;
		if (!query.tags) delete query.tags;
        browserHistory.push({ pathname, query });
    }

    handleSearch = (event) => {
        if (event.type === "keydown" || event.target.value.length === 0) {
            this.debouncedSearch.cancel(); // Cancel the debounced call since we're doing it right now
            this.search(event);
        } else {
            event.persist();
            this.debouncedSearch(event);
        }
    }

	handleTagsChange = value => {
		const event = {
			target: {
				type: "tags",
				value,
			},
		}
		this.search(event);
	}

	render() {
		const {
			searchText,
			recentSearches,
			selectedCategories,
			selectedTags,
		} = this.props;

		const searchTags = this.state.starTags
			? selectedTags.map(st => this.state.starTags.find(t => t.name === st)).filter(st => st !== undefined)
			: [];

		const tagsOptions = {
			createNewTags: false,
			allowReorder: false,
			placeholder: "Filter by tag...",
			maxTags: 5,
		};

		return (
			<div className="c6-topsearch">
				<Search
					searchText={searchText}
					suggestions={recentSearches}
					onChange={this.handleSearch}
					onEnterKeyDown={this.handleSearch}
					placeholder="Search"
					autoFocus
				/>
				{appConfig.features && appConfig.features.starDAMFeatures && (
					<Tags
						formData={searchTags}
						uiSchema={tagsOptions}
						dataSourceConfig={{ value: "name", text: "displayName" }}
						suggestions={this.state.starTags || []}
						onChange={this.handleTagsChange}
						readonly={this.state.starTags === null}
					/>
				)}
				<Pick
					onChange={this.handleSearch}
					states={starContentTypes}
					currentState={selectedCategories}
				/>
				<Create visible={hasStarEditAccess()} />
			</div>
		);
	}
}
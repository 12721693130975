import { get, put, post, doFetch } from '../core/c6'
import Qs from 'qs'
import uniqBy from 'lodash/uniqBy'

import appConfig from 'config'

const API = "press";
const DISCOVERY_API = "discovery";
const SCHEDULE_CHANGES_API = "scheduleChanges";
const SKIP_TOKEN = true;

// Access requests
export const createAccessRequest = (payload) => post(API, "accessrequests", payload, SKIP_TOKEN);
export const updateAccessRequest = (payload) =>  put(API, `accessrequests/${payload.guid}/${payload.newStatus}`, null, SKIP_TOKEN);
export const updateAccessRequestWithRole = ({ guid, newStatus, role }) => {
	return put(API, `accessrequests/${guid}/${newStatus}/${role}`);
};

// Programs
export const fetchSingle 			= ({ id }) => fetchFromBlobStorage(`programs/${id}.json`);
export const fetchSeason 			= ({ id }) => fetchFromBlobStorage(`programs/${id}.json`);
export const fetchSeries			= ({ id }) => fetchFromBlobStorage(`programs/${id}.json`);
export const fetchProgramSchedules 	= ({ id }) => fetchFromBlobStorage(`programschedules/${id}.json`);
export const fetchProgramReviews 	= ({ id }) => doFetch({
	pathWithAPI: `${appConfig.api.pressProgramReviews}/${id}.json`,
	skipToken: true,
	payload: {
		method: "GET",
	},
});

// Press releases
export const fetchPressRelease = ({ id }) => fetchFromBlobStorage(`pressreleases/${id}.json`);
// Bulletin board items: Since we don't have JSON files for each item, we fetch the whole list and filter it in the component
export const fetchBulletinBoardItem = () => fetchFromBlobStorage(`lists/bulletin-board.json`);

// Schedule
export const fetchSchedules = ({ date, channels }) => {
	const requests = uniqBy(channels, c => c.key)
		.map(channel => fetchFromBlobStorage(`schedules/${channel.key}/${date}.json`));
	return new Promise((resolve) => {
		allSettled(requests).then(responses => {
			const emptyInsteadOfFailed = responses.map((schedule, index) => {
				return schedule.status === "fulfilled"
					? schedule.value
					: {
						channel: { name: channels[index].key, id: channels[index].key },
						broadcasts: [],
					};
			});
			resolve(emptyInsteadOfFailed);
		});
	});
};
export const fetchChannelGroups = filters => get(API, "settings/channelgroups", filters, SKIP_TOKEN);

// Widget
export const fetchWidget = ({ widgetName }) => fetchFromBlobStorage(`lists/${widgetName}.json`);

// Site structure
export const fetchSiteStructure = () => fetchFromBlobStorage(`sections/${appConfig.features.pressSiteStructureFilename}`);

// C70 Discovery
export const fetchDiscovery = filters => {
	// const excludeTypeFilter = (prefix, value) => (prefix === "type" ? undefined : value);
	const queryString = Qs.stringify(filters, { skipNulls: true/*, filter: excludeTypeFilter*/ });
	return get(DISCOVERY_API, `items?${queryString}`, null, SKIP_TOKEN);
};

// Subscriptions (C70 ScheduleChanges)
export const fetchSubscription 	= ({ guid, providerId }) 						=> 	get(SCHEDULE_CHANGES_API, `mailgroups?userGuid=${guid}&providerId=${providerId}`, null, SKIP_TOKEN);
export const updateSubscription = ({ guid, providerId }, payload) 	=> post(SCHEDULE_CHANGES_API, `mailgroups?userGuid=${guid}&providerId=${providerId}`, payload, SKIP_TOKEN);
export const subscribe 			= payload 							=> post(SCHEDULE_CHANGES_API, `recipients?useremail=${encodeURIComponent(payload.email)}&lang=fi`, null, SKIP_TOKEN);



// User
export const fetchResetState 	= resetKey 				=> get(API, `logins/forgotPassword/${resetKey}`, null, SKIP_TOKEN);
export const forgotPassword 	= payload 				=> put(API, "logins/forgotPassword/", payload, SKIP_TOKEN);
export const resetPassword 		= (resetKey, payload) 	=> put(API, `logins/changePassword/${resetKey}`, payload, SKIP_TOKEN);
export const logout 			= payload 				=> post(API, "logins/logout", payload, SKIP_TOKEN);
export const login = (name, password) => {
	const API_URL = appConfig.api[API];
	return new Promise((resolve, reject) => {
		if (API_URL) {
			const payload = {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify({ name, password }),
			}

			fetch(`${API_URL}logins/login`, payload)
				.then(_processStatus)
				.then(response => {
					resolve(transformC70Response(response));
				}, error => {
					reject(error);
				})
				.catch(error => {
					console.error("Catch error %o", error);
				});
		}
		else {
			console.error("Could not find the API BASE URL: appConfig.api." + API);
			reject();
		}
	});
}

// Helpers
const fetchFromBlobStorage = (jsonPath) => doFetch({
	pathWithAPI: `${appConfig.api.pressBlobStorage}json/${appConfig.features.pressBlobStorageLocale}/${jsonPath}`,
	skipToken: true,
	payload: {
		method: "GET",
	},
});

function _processStatus(response) {
	const ct = response.headers.get('Content-Type');
	switch (response.status) {
		case 200:
			if (ct.includes("json")) {
				return response.json();
			}
			return response.text();
		case 400:
		case 401:
		case 500:
			return response.json().then(json => {
				return Promise.reject(json);
			});
		default:
			return Promise.reject(new Error(response.statusText))
	}
}

function transformC70Response(response) {
	const isC70 = response && response.result && response.meta && response.meta.requestId;
	if (isC70 && !Array.isArray(response.result)) {
		return response.result;
	}

	return response;
}

// https://github.com/ungap/promise-all-settled/blob/master/index.js
// Not using Promise.allSettled as fallback because it was behaving weird in Chrome 76 (2019-08-12)
const allSettled = function($) {
	return Promise.all(
		$.map(
			function(value) {
				return Promise.resolve(value).then(this.$).catch(this._);
			}, {
				$: function(value) {
					return {
						status: 'fulfilled',
						value: value
					};
				},
				_: function(reason) {
					return {
						status: 'rejected',
						reason: reason
					};
				}
			}
		)
	);
};
import React, { Component } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import ListItemIcon from '@mui/material/ListItemIcon'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import moment from 'moment'

import Actions from '../actions'
import Const from '../../../core/constants'

import ProgramPreview from '../../metadata/shared/preview'
import DropDown from '../../../components/ui/controls/dropdown'
import Switch from '../../../components/ui/controls/switch'
import { Filter, Left } from '../../../components/filter'
import { getExposureMonetizationModels, renderLicenseType, shouldDisplayRegionForLicenses } from '../utils'
import DateTimePicker from '../../../components/ui/controls/pickers/datetimepicker'
import Button from '../../../components/ui/controls/button'
import Period from '../../../components/ui/period'
import TextAreaConfirm from '../../../components/ui/textarea-confirm'

import './programDialog.css'

class ProgramDialog extends Component {
	state = {
		showCommentField: false,
	}

	componentDidUpdate(prevProps) {
		if (
			this.props.programId && (this.props.programId !== prevProps.programId)
			|| this.props.programReferenceId && (this.props.programReferenceId !== prevProps.programReferenceId)
		) {
			this.props.fetchData();
			this.setState({ showCommentField: false });
		}
	}

	loadEpisodes = () => {
		this.setState({ showEpisodes: true });
		this.props.fetchEpisodes();
	}

	goToSeason = () => {
		this.props.selectProgram({ referenceId: this.props.program.parentReferenceId });
	}

	showCommentField = e => {
		e.preventDefault();
		this.setState({ showCommentField: true });
	}

	onClose = () => {
		this.props.onClose();
		this.setState({ showEpisodes: false });
	}

	render() {
		const {
			isLoading,
			program,
			programChildren,
			programChildrenIsLoading,
			readonly,
		} = this.props;

		const {
			id: programId,
			licenses = [],
			typeName: programType,
		} = program;

		let content;
		if (isLoading) {
			content = <p>Loading...</p>;
		} else if (!programId) {
			content = <p>Select a program below or in the calendar for more information</p>;
		} else {
			// Fake license for testing
			// licenses.push(fakeLicense);

			const displayRegion = shouldDisplayRegionForLicenses(licenses);
			const sortedLicenses = licenses?.sort((a, b) => {
				return moment(a.validFrom).diff(moment(b.validFrom));
			});

			const comment = (
				<div className="comment-wrapper">
					{this.state.showCommentField && (
						<TextAreaConfirm
							defaultValue={program.comment}
							onSave={(value) => {
								Actions.updateProgram(program.id, { comment: value });
								this.setState({ showCommentField: false });
							}}
							maxLength={500}
							style={{ color: "var(--text-color)" }}
							readOnly={readonly}
						/>
					)}
					{program.comment && !this.state.showCommentField && (
						<div className="comment">
							{program.comment}
						</div>
					)}
					{!readonly && !this.state.showCommentField && (
						<a className="c6-link" onClick={this.showCommentField}>{program.comment ? "Edit comment" : "Add a comment"}</a>
					)}
				</div>
			);

			content = (
				<div>
					<ProgramPreview
						model={{
							...program,
							duration: durationToMinutes(program.duration),
							guid: program.programGuid,
							defaultGenres: program.genres,
							type: programType,
						}}
						leftContentExtra={comment}
					/>

					<div className="program">
						{sortedLicenses?.map((l) => (
							<License
								key={`${l.id}_${l._licenseUpdatedTimestamp}`} // HACK: Add timestamp to license key because it was displaying old data otherwise. Couldn't figure out why...
								license={l}
								program={program}
								licenseTypes={this.props.licenseTypes}
								readonly={this.props.readonly}
								displayLicenseRegion={displayRegion}
								onPublishWindowCreated={this.props.onPublishWindowCreated}
								onPublishWindowUpdated={this.props.onPublishWindowUpdated}
								onPublishWindowDeleted={this.props.onPublishWindowDeleted}
								onLicenseUpdated={this.props.onLicenseUpdated}
							/>
						))}
					</div>

					{programType === "Season" && !this.state.showEpisodes && (
						<h3 className="child-programs-header">
							<a className="c6-link" onClick={this.loadEpisodes}>Load episodes</a>
						</h3>
					)}
					{programType === "Season" && this.state.showEpisodes && programChildrenIsLoading && (
						<h3 className="child-programs-header">Loading episodes...</h3>
					)}
					{programType === "Season" && this.state.showEpisodes && !programChildrenIsLoading && programChildren?.map(child => {
						const sortedChildLicenses = child.licenses?.sort((a, b) => {
							return moment(a.validFrom).diff(moment(b.validFrom));
						});
						const displayRegionForChildLicense = shouldDisplayRegionForLicenses(child.licenses);
						return (
							<div key={child.id} className="child-program">
								<h3 className="child-program-name">{child.name}</h3>
								{sortedChildLicenses?.map((l) => (
									<License
										key={`${l.id}_${l._licenseUpdatedTimestamp}`} // HACK: Add timestamp to license key because it was displaying old data otherwise. Couldn't figure out why...
										license={l}
										program={child}
										licenseTypes={this.props.licenseTypes}
										readonly={this.props.readonly}
										displayLicenseRegion={displayRegionForChildLicense}
										onPublishWindowCreated={this.props.onPublishWindowCreated}
										onPublishWindowUpdated={this.props.onPublishWindowUpdated}
										onPublishWindowDeleted={this.props.onPublishWindowDeleted}
										onLicenseUpdated={this.props.onLicenseUpdated}
									/>
								))}
							</div>
						);
					})}
					{programType === "Episode" && program.parentReferenceId && (
						<h3 className="child-programs-header">
							<a className="c6-link" onClick={this.goToSeason}>Go to season</a>
						</h3>
					)}
				</div>
			);
		}

		return (
			<React.Fragment>
				<Dialog
					open={!!this.props.programId || !!this.props.programReferenceId}
					onClose={this.onClose}
					className="c6-modal c6-planner-program-dialog"
					maxWidth="lg"
					fullWidth={true}
					classes={{
						container: "c6-modal-content",
						paper: "c6-modal-body scroll-track-bg",
						scrollBody: "asd",
					}}
				>
					<DialogContent>
						<Filter>
							<Left>
								<Button
									title="Close"
									type="cancel"
									noBackground={true}
									onClick={this.onClose}
								/>
							</Left>
						</Filter>
						{content}
					</DialogContent>
				</Dialog>
			</React.Fragment>
		);
	}
}

export default ProgramDialog;

function License(props) {
	const {
		license,
		program,
		licenseTypes,
		readonly,
		displayLicenseRegion,
		onPublishWindowCreated,
		onPublishWindowUpdated,
		onPublishWindowDeleted,
		onLicenseUpdated,
	} = props;

	const {
		id: licenseId,
		type: licenseType,
		validFrom,
		validUntil,
		priority,
		style,
		rights,
		dynamicHvod,
		dynamicSvod,
	} = license;

	const {
		id: programId,
		typeName: programType,
	} = program;

	const showHVODOptions = ["Season", "Single"].includes(programType)
		&& !["hvod"].includes(licenseType.monetizationModel);
	const showSVODOptions = ["Season", "Single"].includes(programType)
		&& !["svod"].includes(licenseType.monetizationModel);
	const vodOptionsCount = [showHVODOptions, showSVODOptions].filter(x => x).length;
	const showCountryOptions = programType !== "Episode";

	const enableHvodOptions = license.dynamicHvod === "always"
		|| (
			license.dynamicHvod === "ruleBased"
			&& license.type.monetizationModel === "svod"
			&& license.adsAllowed
		);
	
	const rightsVodOptions = rights?.map(r => {
		return {
			right: r,
			enabled: r.status !== "disabled",
			includeSvod: ["enabled","enabledNoHvod"].includes(r.status) && license.dynamicSvod !== "never",
			includeHvod: ["enabled","enabledNoSvod"].includes(r.status) && enableHvodOptions
		};
	});

	const exposures = getExposures(license, license.exposures);
	return (
		<div className="license">
			<div className="license-info">
				<div className="license-window-priority">
					<div className="license-window">
						{renderLicenseType(licenseType, style, priority, rights, undefined, displayLicenseRegion)}
						<Period start={validFrom} end={validUntil} format={Const.DATE_FORMAT} />
					</div>
					{programType !== "Episode" && (
						<Switch
							name={`priority-${licenseId}`}
							title="Priority"
							states={getPriorityStates()}
							currentState={priority}
							onChange={(e) => handlePriorityChange(e, license, programId, onLicenseUpdated)}
							triggerOnChangeWhenCurrentValueIsClicked={true}
							disabled={readonly}
						/>
					)}
				</div>
				{
					license.type.monetizationModel === "svod" && (
						<small>Ads are {license.adsAllowed ? '' : 'not'} allowed</small>
					)
				}
				{(vodOptionsCount > 0 || rightsVodOptions.length > 0) && (
					<div className={`vod-options vod-options-${vodOptionsCount}`}>
						{rightsVodOptions?.length > 1 && <div></div>}
						{showHVODOptions && (
							<DropDown
								style={{ padding: "2px 0px" }}
								title="Add HVOD Exposures"
								name={`hvod-${licenseId}`}
								states={getHvodOptions().filter(f => f.key !== "ruleBased" || license.type.monetizationModel === "svod")}
								currentState={dynamicHvod}
								onChange={(_, value) => handleVodChange(value, "dynamicHvod", license, programId, onLicenseUpdated)}
								disabled={readonly}
							/>
						)}
						{showSVODOptions && (
							<DropDown
								style={{ padding: "2px 0px" }}
								title="Add SVOD Exposures"
								name={`svod-${licenseId}`}
								states={getSvodOptions().filter(f => f.key !== "ruleBased")}
								currentState={dynamicSvod}
								onChange={(_, value) => handleVodChange(value, "dynamicSvod", license, programId, onLicenseUpdated)}
								disabled={readonly}
							/>
						)}
						{rightsVodOptions?.length > 1 && rightsVodOptions.map((rvo, i) => {
							return (
								<React.Fragment key={i + "-countrylabel"}>
									{showCountryOptions && (
										<div>
											<FormControlLabel
												control={
													<Checkbox 
														checked={rvo.enabled}
														onChange={(e) => handleRightEnabledChange(rvo.right, e.target.checked, license, programId, onLicenseUpdated)}
														disabled={readonly}
														style={{ padding: 0 }}
													/>
												}
												style={{ margin: 0 }}
												label={rvo.right.name}
											/>
										</div>
									)}
									{showHVODOptions && (
										<div>
											<FormControlLabel
												control={
													<Checkbox 
														checked={rvo.includeHvod}
														onChange={(e) => handleVodRightChange(rvo.right, e.target.checked, rvo.includeSvod, license, programId, onLicenseUpdated)}
														disabled={readonly || !license.dynamicHvod || !enableHvodOptions || !rvo.enabled}
														style={{ padding: 0 }}
													/>
												}
												style={{ margin: 0 }}
												label="HVOD"
											/>
										</div>
									)}
									{showSVODOptions && (
										<div>
											<FormControlLabel
												control={
													<Checkbox 
														checked={rvo.includeSvod}
														onChange={(e) => handleVodRightChange(rvo.right, rvo.includeHvod, e.target.checked, license, programId, onLicenseUpdated)}
														disabled={readonly || !license.dynamicSvod || license.dynamicSvod === "never" || !rvo.enabled}
														style={{ padding: 0 }}
													/>
												}
												style={{ margin: 0 }}
												label="SVOD"
											/>	
										</div>
									)}
								</React.Fragment>
							);
						})}
					</div>
				)}
			</div>


			{programType !== "Season" && (
				<div className="publish-window-info">
					{exposures?.map((pw, i) => {
						const changedStart = pw.changedStart ? moment(pw.changedStart).format("YYYY-MM-DD HH:mm") : null;
						const changedEnd = pw.changedEnd ? moment(pw.changedEnd).format("YYYY-MM-DD HH:mm") : null;
						const fromMinDate = moment(validFrom);
						const fromMaxDate = changedEnd ? moment.min(moment(changedEnd), moment(validUntil)) : moment(validUntil);
						const untilMinDate = changedStart ? moment.max(moment(changedStart), moment(validFrom)) : moment(validFrom);
						const untilMaxDate = moment(validUntil);
					
						// If current publishwindow licensetype is same as license.type, select "Default" (id 0)
						let licenseTypeIdToDisplay = null;
						if (!pw.changedOverrideLicenseType || pw.changedOverrideLicenseType.id === licenseType.id) {
							licenseTypeIdToDisplay = 0;
						} else {
							licenseTypeIdToDisplay = pw.changedOverrideLicenseType.id;
						}

						const monetizationModel = getExposureMonetizationModels(pw, license.type);
						
						return (
							<div key={pw.id} className="planned-window">
								<div>
									{monetizationModel} exposure
									<DropDown
										title="Type"
										description="Override the default license type"
										name={`licenseType-${pw.id}-${i}`}
										currentState={licenseTypeIdToDisplay}
										states={getLicenseTypeStates(licenseTypes, license)}
										onChange={(e) => updatePublishWindow(pw, "changedOverrideLicenseType", { id: e.target.value }, licenseId, programId, onPublishWindowUpdated)}
									/>
								</div>
								<div>
									<DateTimePicker
										value={changedStart}
										onChange={(value) => {
											// Handle min & max values here since flatpickr will show an empty input if value is outside min or max
											const newValue = moment(value);
											const fixedMinValue = moment.max(fromMinDate, newValue);
											const fixedMinAndMaxValue = moment.min(fromMaxDate, fixedMinValue);
											updatePublishWindow(pw, "changedStart", fixedMinAndMaxValue.format(), licenseId, programId, onPublishWindowUpdated);
										}}
										options={{
											hideClear: true,
										}}
										readOnly={readonly}
										staticRender={false}
									/>
									<DateTimePicker
										value={changedEnd}
										onChange={(value) => {
											// Handle min & max values here since flatpickr will show an empty input if value is outside min or max
											const newValue = moment(value);
											const fixedMinValue = moment.max(untilMinDate, newValue);
											const fixedMinAndMaxValue = moment.min(untilMaxDate, fixedMinValue);
											updatePublishWindow(pw, "changedEnd", fixedMinAndMaxValue.format(), licenseId, programId, onPublishWindowUpdated);
										}}
										options={{
											hideClear: true,
										}}
										readOnly={readonly}
										staticRender={false}
									/>
									{!readonly && (
										<ExposureMenu
											onCreateClick={createPublishWindow.bind(this, licenseId, programId, onPublishWindowCreated)}
											onDeleteClick={deletePublishWindow.bind(this, pw.id, licenseId, programId, onPublishWindowDeleted)}
										/>
									)}
								</div>
							</div>
						);
					})}
					{!readonly && exposures.length === 0 && (
						<div className="publish-window-add-container">
							<Button
								title="Add new exposure"
								noBackground 
								className="add-license-exposure"
								type="add" 
								onClick={createPublishWindow.bind(this, licenseId, programId, onPublishWindowCreated)}
							/>
						</div>
					)}
				</div>
			)}
		</div>
	);
}

function durationToMinutes(duration) {
	if (!duration) {
		return 0;
	}
	
	const [hours, minutes] = duration.split(":").map(token => parseInt(token));
	return hours * 60 + minutes;
}

function isEnqueuedForDeletion(pw, licensePublishWindows) {
	return pw.changedStart === undefined && pw.changedEnd === undefined && pw.dirty === true && licensePublishWindows.length !== 1;
}

function getPriorityStates() {
	return [
		{ key: "A", text: "A", description: "" },
		{ key: "B", text: "B", description: "" },
		{ key: "C", text: "C", description: "" },
		{ key: "D", text: "D", description: "" },
	];
}

function getHvodOptions() {
	return [
		{ key: "ruleBased", text: "Depends", longText: "Create if ads are allowed", description: "HVOD ingerited" },
		{ key: "never", text: "Never", longText: "Never create HVOD Exposure", description: "No HVOD" },
		{ key: "always", text: "Always", longText: "Always create HVOD Exposure", description: "HVOD" },
	];
}

function getSvodOptions() {
	return [
		{ key: "never", text: "Never", longText: "Never create SVOD Exposure", description: "No SVOD" },
		{ key: "always", text: "Always", longText: "Always create SVOD Exposure", description: "SVOD" },
	];
}

function getLicenseTypeStates(licenseTypes, license) {
	const samePlatformLicenseTypes = licenseTypes?.items?.filter(lt => 
		lt.platformId === license.type.platformId
	) ?? [];

	const types = samePlatformLicenseTypes
		.filter(t => t.id !== license.type.id) // Don't show license.type since it is replaced by the "Default" option
		.filter((t, i, list) => list.findIndex(t2 => t2.id === t.id) === i) // Unique

	return [
		{ key: 0, text: "Default" },
		...types.map(t => ({ key: t.id, text: t.displayName })),
	];
}

function getExposures(program, publishWindows) {
	if (program.typeName === "Season") {
		return [];
	}

	return publishWindows
		.filter(pw => !isEnqueuedForDeletion(pw, publishWindows))
		.reduce((acc, pw) => {
			if (pw.monetizationModel !== "undefined") {
				return acc;
			}
			const matchingPublishWindows = publishWindows.filter(pw2 => {
				return pw2.monetizationModel !== "undefined"
					&& pw2.start === pw.start
					&& pw2.end === pw.end
					&& pw2.licenseId === pw.licenseId;
			});
			if (matchingPublishWindows?.length) {
				acc.push({
					...pw,
					extraMonetizationModels: matchingPublishWindows.map(pw2 => pw2.monetizationModel),
				});
			} else {
				acc.push(pw);
			}
			return acc;
		}, []);
}

function rightStatusFromCheckboxes(includeHvod, includeSvod) {
	if (includeHvod && includeSvod) {
		return "enabled";
	}
	
	if (includeHvod) {
		return "enabledNoSvod";
	}
	
	if (includeSvod) {
		return "enabeldNoHvod";
	}

	return "enabledNoHvodNoSvod";
}

function handlePriorityChange(e, license, programId, onLicenseUpdated) {
	const payload = {
		...license,
		priority: license.priority !== "None" && e.target.value === license.priority ? "None" : e.target.value,
	};
	Actions.updateLicense(license.id, payload, programId, onLicenseUpdated);
}

function handleVodChange(value, property, license, programId, onLicenseUpdated) {
	const payload = {
		...license,
		[property]: value,
	};
	Actions.updateLicense(license.id, payload, programId, onLicenseUpdated);
}

function handleVodRightChange(right, includeHvod, includeSvod, license, programId, onLicenseUpdated) {
	const payload = {
		...license,
		rights: license.rights.map(r => {
			if (r.id === right.id) {
				return {
					...r,
					status: rightStatusFromCheckboxes(includeHvod, includeSvod),
				};
			}
			return r;
		}),
	};
	Actions.updateLicense(license.id, payload, programId, onLicenseUpdated);
}

function handleRightEnabledChange(right, enabled, license, programId, onLicenseUpdated) {
	const payload = {
		license,
		rights: license.rights.map(r => {
			if (r.id === right.id) {

				return {
					...r,
					status: enabled ? rightStatusFromCheckboxes(right.includeHvod, right.includeSvod) : "disabled",
				};
			}
			return r;
		}),
	};
	Actions.updateLicense(license.id, payload, programId, onLicenseUpdated);
}

function updatePublishWindow(pw, key, value, licenseId, programId, onPublishWindowUpdated) {
	const payload = {
		...pw,
		[key]: value,
	};
	Actions.updatePublishWindow(pw.id, payload, null, onPublishWindowUpdated, licenseId, programId);
}

function deletePublishWindow(id, licenseId, programId, onPublishWindowDeleted) {
	Actions.deletePublishWindow(id, onPublishWindowDeleted, licenseId, programId);
}

function createPublishWindow(licenseId, programId, onPublishWindowCreated) {
	// Can't have a start that is after the license end boundary
	const payload = {
		"licenseId": licenseId,
	};
	Actions.createPublishWindow(payload, null, onPublishWindowCreated, licenseId, programId);
}

function ExposureMenu(props) {
	const {
		onCreateClick,
		onDeleteClick,
	} = props;
	const buttonRef = React.useRef();
	const [menuOpen, setMenuOpen] = React.useState(false);
	return (
		<>
			<Button
				buttonRef={buttonRef}
				onClick={() => setMenuOpen(true)}
				type="c6-menuitem icon-more_vert"
				noBackground
			/>
			<Menu
				anchorEl={buttonRef.current}
				open={menuOpen}
				onClose={() => setMenuOpen(false)}
				className="c6-planner-exposure-menu"
			>
				<MenuItem
					onClick={() => onCreateClick()}
					className="menuitem-add"
				>
					<ListItemIcon><span className="icon-add large-icon"></span></ListItemIcon>
					<span>Add new exposure</span>
				</MenuItem>
				<MenuItem
					onClick={() => onDeleteClick()}
					className="menuitem-remove"
				>
					<ListItemIcon><span className="icon-delete large-icon"></span></ListItemIcon>
					<span>Remove exposure</span>
				</MenuItem>
			</Menu>
		</>
	);
}


// const fakeLicense = {
// 	"id": 1333333337,
// 	"type": {
// 		"id": 17,
// 		"providerId": 2,
// 		"name": "CMore-CATCHUP",
// 		"displayName": "Hugos Licens",
// 		"class": "CatchUp",
// 		"controllsWindow": false,
// 		"autoApprove": false,
// 		"owner": "CMore",
// 		"monetizationModel": "avod"
// 	},
// 	"isActive": true,
// 	"contractId": 24,
// 	"contractName": "itvstudiosnordic",
// 	"channelId": 0,
// 	"channelName": "Undefined",
// 	"validFrom": "2018-04-10T19:55:19+02:00",
// 	"validUntil": "2099-12-31T23:59:59+01:00",
// 	"programId": 0,
// 	"distributorId": 0,
// 	"hasWindow": false,
// 	"hasChangedWindow": false,
// 	"dynamicHvod": "never",
// 	"dynamicSvod": "always",
// 	"cssName": [
// 		"CatchUp",
// 		"cmorecatchup"
// 	],
// 	"rights": [
// 		{
// 			"id": 1,
// 			"name": "Sweden",
// 			"ordinal": 0,
// 			"enabled": true,
// 			"relationId": -1,
// 			"status": "enabled"
// 		},
// 		{
// 			"id": 2,
// 			"name": "Finland",
// 			"ordinal": 0,
// 			"enabled": true,
// 			"relationId": -1,
// 			"status": "enabledNoHvod"
// 		},
// 		{
// 			"id": 3,
// 			"name": "Denmark",
// 			"ordinal": 0,
// 			"enabled": true,
// 			"relationId": -1,
// 			"status": "enabledNoSvod"
// 		}
// 	],
// 	"exposures": [
// 		{
// 			"id": 1234,
// 			"start": "2023-11-14T00:00:00+01:00",
// 			"end": "2099-12-31T23:59:59+01:00",
// 			"changedStart": "2023-11-14T00:00:00+01:00",
// 			"changedEnd": "2099-12-31T23:59:59+01:00",
// 			"dirty": false,
// 			"licenseId": 0,
// 			"monetizationModel": "undefined",
// 			"adsAllowed": false,
// 			"dynamicHvod": "never",
// 			"dynamicSvod": "never"
// 		},
// 		{
// 			"id": 2345,
// 			"dirty": false,
// 			"licenseId": 0,
// 			"monetizationModel": "undefined",
// 			"adsAllowed": false,
// 			"dynamicHvod": "never",
// 			"dynamicSvod": "never"
// 		}
// 	],
// 	"reference": "30103",
// 	"adsAllowed": false
// };
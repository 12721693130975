import moment from 'moment'
import { Regions } from '../../core/constants'

export function renderLicenseType(licenseType = {}, style, priority, rights, disabled, displayRegion) {
	const { displayName, name } = licenseType;
	const countries = rights?.map(r => r.name) ?? [];
	const countriesContent = countries.map(c => {
		const country = Regions.find(r => r.countryDisplayName === c);
		return country?.country;
	}).filter(c => !!c).join(",").toUpperCase();

	return (
		<span className={`c6-planner-license-type vod-type-${licenseType.monetizationModel} priority-${priority} ${disabled ? "disabled" : ""}`}>
			{displayRegion && countriesContent && <span className="countries">{countriesContent}</span>}
			<span>{displayName || name}</span>
		</span>
	);
}

export function shouldDisplayRegionForLicenses(licenses) {
	// Display region if there are multiple licenses with the same licenseType.id but different rights
	return !!licenses?.some(l => {
		return l.rights?.length === 1
			&& licenses.some(l2 => l2.id !== l.id && l2.rights[0]?.name !== l.rights[0].name && l2.type?.id === l.type?.id);
	});
}

export function getExposureMonetizationModels(pw, licenseType) {
	return [
		(pw.licenseType ?? licenseType)?.monetizationModel,
		...pw.extraMonetizationModels ?? [],
	].filter(Boolean).map(m => m.toUpperCase()).join(" + ");
}

export function getProgramTypeStates() {
	return [
		{ key: "single|season", text: "All" },
		{ key: "single", text: "Singles" },
		{ key: "season", text: "Series" },
	];
}

export function getPlatformStates(platforms) {
	return platforms?.items.filter(p => p.name.toLowerCase() !== "undefined").map(p => ({
		key: p.name,
		text: p.displayName
	})) ?? [];
}

export function getSourceStates(currentPlatform, platforms) {
	const sourcesForCurrentPlatform = platforms?.items.find(p => p.name === currentPlatform)?.owners ?? [];
	return sourcesForCurrentPlatform.map(s => ({
		key: s.name,
		text: s.displayName,
	}));
}

export function getRightsStates() {
	return [
		{ key: null, text: "Any" },
		{ key: 1, text: "Sweden" },
		{ key: 2, text: "Norway" },
		{ key: 3, text: "Denmark" },
		{ key: 4, text: "Finland" },
	]
}

export function getPremiereStates() {
	const premieres = [
		{
			key: null,
			text: "Any",
		},
		{
			key: "currentandupcoming",
			text: "Current and Upcoming",
		},
		{
			key: "upcoming",
			text: "Upcoming",
		},
	];

	let month = moment().startOf("month");

	// add 3 upcoming months after current month
	for (let i = 0; i <= 3; i++) {
		premieres.push({
			key: month.format("YYYY-MM-DD"),
			text: month.format("MMMM"),
		})
		month = month.add(1, "months").startOf("month");
	}

	return premieres;
}

export function getMonetizationModels() {
	return [
		{ key: "", text: "All" },
		{ key: "avod", text: "AVOD" },
		{ key: "hvod", text: "HVOD" },
		{ key: "svod", text: "SVOD" },
	];
}

// HACK: Add timestamp to license key because it was displaying old data otherwise. Couldn't figure out why...
export function markLicenseAsChanged(license) {
	license._licenseUpdatedTimestamp = Date.now();
}
import { CONTENT, INLINE } from '../../../components/actions'
import Actions from '../actions'

export default {
	targetComponent: "Item",
	actions: [
		{
			type: CONTENT,
			title: "Display item",
			method: Actions.edit,
		},
		{
			// title: "Remove selected item",
			type: INLINE,
			icon: "cancel",
			method: Actions.unselect,
		},
	]
};
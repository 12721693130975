import React from 'react'
import { Route, IndexRoute } from 'react-router'

import Module from '../app'

import StarHome from './app'
// import StarDashboard from './dashboard/app'
import StarSearch from './search/app'
import StarSelection from './selection/app'
import StarTrashcan from './trashcan/app'

import StarContainer from './containereditor/app'
import StarAsset from '../../components/assets/asseteditor/editor'

import StarAlbums from './albums/albums/app'
import StarAlbum from './albums/album/app'
import StarAlbumAddDialog from './albums/dialog/add'
import StarAlbumShareDialog from './albums/dialog/share'
import StarSharedAlbum from './albums/sharedalbum/app'

import StarAdminDashboard from './admin/dashboard'
import StarFileFormats from './admin/fileformats/app'
import StarFileFormatEditor from './admin/fileformateditor/app'
import StarCropTypes from './admin/croptypes/app'
import StarCropTypeEditor from './admin/croptypeeditor/app'
import StarFileTypes from './admin/filetypes/app'
import StarFileTypeEditor from './admin/filetypeeditor/app'

export default class StarModule {

	static routes() {
		return (
			<Route path="star" component={Module} module="star">

				<Route path="albums" app="albums">
					<IndexRoute component={StarAlbums} />
					<Route path="add" component={StarAlbumAddDialog} modal={true} />
					<Route path="share" component={StarAlbumShareDialog} modal={true} />
					<Route path="shared/:guid" component={StarSharedAlbum} public={true} />
					<Route path=":id" component={StarAlbum} />
				</Route>

				{/* <Route app="home" component={StarHome}> */}
				<Route component={StarHome}>
					{/* <IndexRoute component={StarDashboard} /> */}
					<Route path="search" app="assets">
						<IndexRoute component={StarSearch} />
						<Route path="selected" component={StarSelection} />
						<Route path=":searchText" component={StarSearch} />
					</Route>
				</Route>

				<Route path="trashcan" component={StarTrashcan} />

				<Route path="container/:id/edit" component={StarContainer} modal={true} wideModal={true} app="assets" />
				<Route path="container/create" component={StarContainer} modal={true} wideModal={true} app="assets" access="editor" />

				<Route path="asset/:assetId/edit" component={StarAsset} modal={true} wideModal={true} dialogMaxHeightNone={true} />

				<Route path="admin" app="admin">
					<IndexRoute component={StarAdminDashboard} />

					<Route path="fileformats" component={StarFileFormats} returnTo="/star/admin" />
					<Route path="fileformats/create" component={StarFileFormatEditor} modal={true} />
					<Route path="fileformats/:id/edit" component={StarFileFormatEditor} modal={true} />

					<Route path="croptypes" component={StarCropTypes} returnTo="/star/admin" />
					<Route path="croptypes/create" component={StarCropTypeEditor} modal={true} />
					<Route path="croptypes/:id/edit" component={StarCropTypeEditor} modal={true} />

					<Route path="filetypes" component={StarFileTypes} returnTo="/star/admin" />
					<Route path="filetypes/create" component={StarFileTypeEditor} modal={true} />
					<Route path="filetypes/:id/edit" component={StarFileTypeEditor} modal={true} />
				</Route>

				{/* <Route path="container/provider/:providerId/reference/:referenceId" component={StarContainer} /> */}
			</Route>
		);
	}
}
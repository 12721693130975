import * as ReviewsAPI from '../../../apis/reviews'
import { useQuery } from '@tanstack/react-query'

const useReviewsProgram = (programId: string | number) => {
    return useQuery(
        ["reviews", "programs", { id: programId }],
        () => ReviewsAPI.fetchProgram({ id: programId }),
        { enabled: programId !== null && programId !== undefined }
    );
};

export default useReviewsProgram;
import React from 'react'

import App from '../../../../components/app'
import Dashboard from '../../../../components/dashboard/dashboard'
import { StandardInfo } from '../../../../components/comet'

const SHORTCUTS = [
	{
		displayName: "Sections",
		description: "Add, rename or remove sections.",
		key: "selections.admin-groups",
		url: "/selections/admin/groups",
		urlText: "Sections list",
		links: [],
	},
	{
		displayName: "Templates",
		description: "Add, rename or remove list templates. List templates affect the visual representation of lists in the clients.",
		key: "selections.admin-templates",
		url: "/selections/admin/templates",
		urlText: "Templates list",
		links: [],
	},
	{
		displayName: "Areas",
		description: "Add, rename or remove section areas. Section areas allow you to place lists in different locations on the page.",
		key: "selections.admin-areas",
		url: "/selections/admin/areas",
		urlText: "Areas list",
		links: [],
	},
	// {
	// 	displayName: "Current feature",
	// 	description: "",
	// 	key: "selections.press-cfh",
	// 	url: "/selections/press/lists/11/items",
	// 	urlText: "Highlights list",
	// 	links: [],
	// },
];

class AdminDashboardApp extends React.Component {

	constructor(props) {
		super(props);
	}

	render() {
	    return (
			<App name="c6-selections-admin" layout="grid">
				<Dashboard title="Selections administration" data={SHORTCUTS} />
				<StandardInfo location={this.props.location} />
			</App>
		);
	}
}

export default AdminDashboardApp;
import React from 'react'
import { Route, IndexRoute } from 'react-router'

import Schedules from './schedules/schedules'
import Schedule from './schedule/schedule'
import ScheduleReleasesApp from './schedulereleases/app'
import ScheduleReleasesEditor from './schedulereleaseseditor/app'

import Module from '../app'

export default class SchedulesModule {
	
	static routes() {
		return (
			<Route path="schedules" component={Module} module="schedules">
				<Route path="changes" app="changes">
					<IndexRoute component={Schedules} />
					<Route path=":channel/:from/:until" component={Schedule} access="editor" />
					<Route path=":channel/:days" component={Schedule} access="editor" />
					<Route path=":id" component={Schedule} access="editor" />
				</Route>
				<Route path="releases" app="releases">
					<IndexRoute component={ScheduleReleasesApp} />
					<Route path=":id/edit" component={ScheduleReleasesEditor} access="editor" modal={true} />
					<Route path="create" component={ScheduleReleasesEditor} access="editor" modal={true} />	
				</Route>
			</Route>
		);
	}
}

import { browserHistory } from 'browserHistory'

import { CONTENT, MENU } from '../../../components/actions'
import Actions from '../actions'
import { updateItemInMatchingQueries } from '../../../core/hooks/useList'

import * as SchedulesAPI from '../../../apis/schedules'

export default {
	targetComponent: "Content",
	actions: [
		{
			type: CONTENT,
			method: (data, sourceProps) => {
				browserHistory.push({
					pathname: `/schedules/releases/${data.id}/edit`,
					state: {
						modal: true,
						returnTo: `${sourceProps.location.pathname}${sourceProps.location.search}`,
					},
				});
			},
			hidden: (data, sourceProps) => false,
			disabled: (data, sourceProps) => false,
		},
		{
			title: "Edit",
			icon: "create",
			type: MENU,
			method: (data, sourceProps) => {
				browserHistory.push({
					pathname: `/schedules/releases/${data.id}/edit`,
					state: {
						modal: true,
						returnTo: `${sourceProps.location.pathname}${sourceProps.location.search}`,
					},
				});
			},
			hidden: (data, sourceProps) => false,
			disabled: (data, sourceProps) => false,
		},
		{
			title: "Delete",
			icon: "delete",
			type: MENU,
			method: async (data) => {
				await SchedulesAPI.deleteScheduleRelease(data);
				updateItemInMatchingQueries({
					store: "scheduleReleases",
					item: data,
					isDelete: true,
				});
			},
			hidden: (data, sourceProps) => false,
			disabled: (data, sourceProps) => false,
			confirm: true,
		},
	]
}
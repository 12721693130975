import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'

import { Filter, Left, Right } from '../../../components/filter'
import LightApproval from '../../../components/ui/editorFields/lightApproval' // TODO: Move out of editorFields since it has nothing to do with RJSF editorfields
import EditableText from '../../../components/ui/controls/editableText'
import Button from '../../../components/ui/controls/button'

import Actions from './actions'

import './header.css'
import { downloadAssets } from '../shared/utils'
import { displayAlert } from '../../../core/services/alert'

@withRouter
class Header extends React.Component {
	static propTypes = {
		isLoading: PropTypes.bool,
		hasEditAccess: PropTypes.bool,
		container: PropTypes.object,
		containerType: PropTypes.string,

		location: PropTypes.object, // From @withRouter
	}

	state = {
		isDownloading: false,
	}
	_mounted = false

	constructor(props) {
		super(props);

		this.handleTitleChange = this.handleTitleChange.bind(this);
		this.handleApprove = this.handleApprove.bind(this);
	}

	componentDidMount() {
		this._mounted = true;
	}

	componentWillUnmount() {
		this._mounted = false;
	}

	// componentWillReceiveProps(nextProps) {
	// 	console.log("NP: %o", nextProps);
	// }

	handleTitleChange(value) {
		const { container, location, routes, containerType } = this.props;

		// Don't try to save when we know the API will crash due to the container name missing
		if(value && (!container || container && container.displayName !== value)) {
			if(!container.id) {
				const fetchChildren = true;
				Actions.createContainer({ displayName: value, type: containerType }, location.state, routes, null, fetchChildren);
			}
			else {
				Actions.updateTitle(container.id, value, container);
			}
		}
	}

	handleApprove(isApproved) {
		const { container, location } = this.props;
		const targetStore = location.state && location.state.targetStore; // Also return the store to update in the module that opened the STAR editor
		Actions.approveContainer(container, isApproved, targetStore);
	}

	download = async () => {
		let removeAlert;
		try {
			this.setState({ isDownloading: true });
			removeAlert = displayAlert(
				"info",
				"Generating ZIP-file for folder, please wait...",
				null,
				null,
				2 * 60 * 1000,
			);
			const items = [
				{ type: "Container", id: this.props.container.id }
			];
			await downloadAssets(items, "id");
		} catch (e) {
			console.error(e);
		} finally {
			if (this._mounted) {
				this.setState({ isDownloading: false });
			}
			if (typeof removeAlert === "function") {
				removeAlert();
			}
		}
	}

	render() {
		const { isLoading, container, hasEditAccess } = this.props;
		const isNewContainer = !container.id;
		const disableEdit = isLoading || !!container.approved || !hasEditAccess

		let containerName = isLoading ? "Loading..." : "";
		if(!isNewContainer && !isLoading) {
			containerName = container.displayName || "Missing name!";
		}

		return (
			<Filter className="c6-filter c6-header">
				<Left>
					<h1>
						<EditableText
							onChange={this.handleTitleChange}
							hintText="Please type a folder name"
							isEditing={!isLoading && isNewContainer}
							disableEdit={disableEdit} >
							{containerName}
						</EditableText>
					</h1>
				</Left>
				<Right>
					{container && container.id && (
						<Button
							type="download"
							title="Download"
							hoverTitle="Download all images in this folder"
							onClick={this.download}
							noBackground
							disabled={this.state.isDownloading}
						/>
					)}
					{container && container.id && (
						<Button
							type="delete"
							title="Trash"
							onClick={() => Actions.trashContainer(container.id)}
							noBackground
							confirm="Are you sure?"
							dangerous={true}
						/>
					)}
					{container && container.id &&
						<LightApproval
							isApproved={!!container.approved}
							onChange={this.handleApprove}
							disabled={!hasEditAccess}
						/>
					}
				</Right>
			</Filter>
		);
	}
};

export default Header;
import React from 'react'

type Style = {
	cssClass?: string;
}

export function getIconForStyle(style: Style, enabled: boolean): React.ReactElement | null {
	let icon: string = null;
	switch (style?.cssClass) {
		case "sports-icon": icon = enabled ? "scoreboard" : "scoreboard-outline";
	}

	return icon
		? <span className={`c6-cms-icon icon-${icon} large-icon ${enabled ? "enabled" : ""}`}></span>
		: null;
}
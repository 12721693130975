import { get, put, post, del, processStatus, getCompatibleData, getCompatibleError } from '../core/c6'

import appConfig from 'config'

const API = "shield";
const SKIP_TOKEN = true;


/* **************** Admin: Modules ***************** */
export const fetchModules =         filters =>  get(API, "modules", filters);
export const fetchModule  =          ({id}) =>  get(API, `modules/${id}`);
export const createModule =         payload => post(API, "modules", payload);
export const updateModule = ({id}, payload) =>  put(API, `modules/${id}`, payload);
export const deleteModule =          ({id}) =>  del(API, `modules/${id}`);

export const updateModuleService =         ({ id, data, property }) => put(API, `modules/${id}/services/${data.id}`, property.accessLevel);
export const addModuleService    = ({ id, data, level = "Reader" }) => put(API, `modules/${id}/services/${data.id}`, level);
export const removeModuleService =                   ({ id, data }) => del(API, `modules/${id}/services/${data.id}`);

/* **************** Admin: Logins ***************** */
export const fetchLogins =            filters =>  get(API, "logins", filters);
export const fetchLogin  =             ({id}) =>  get(API, `logins/${id}`);
export const createLogin =            payload => post(API, "logins", payload);
export const updateLogin =    ({id}, payload) =>  put(API, `logins/${id}`, payload);
export const deleteLogin =             ({id}) =>  del(API, `logins/${id}`);
export const copyLogin   = ({id, ...payload}) => post(API, `logins/${id}/copy`, payload);

export const addLoginRole    = ({ id, data }) =>  put(API, `logins/${id}/roles/${data.id}`);
export const removeLoginRole = ({ id, data }) =>  del(API, `logins/${id}/roles/${data.id}`);

export const updateLoginModule =         ({ id, data, property }) => put(API, `logins/${id}/modules/${data.id}`, property.accessLevel);
export const addLoginModule    = ({ id, data, level = "Reader" }) => put(API, `logins/${id}/modules/${data.id}`, level);
export const removeLoginModule =                   ({ id, data }) => del(API, `logins/${id}/modules/${data.id}`);

export const fetchResetState  =            resetKey => get(API, `logins/forgotPassword/${resetKey}`, null, SKIP_TOKEN);
export const forgotPassword   =             payload => put(API, "logins/forgotPassword/", payload, SKIP_TOKEN);
export const resetPassword    = (resetKey, payload) => put(API, `logins/changePassword/${resetKey}`, payload, SKIP_TOKEN);

export const logout 	= payload =>  post(API, "logins/logout", payload, SKIP_TOKEN);
export const logoutSSO 	= payload =>  post(API, "logins/logout/sso", payload, SKIP_TOKEN);

/* **************** Admin: Services ***************** */
export const fetchServices =         filters =>  get(API, "services", filters);
export const fetchService  =          ({id}) =>  get(API, `services/${id}`);
export const createService =         payload => post(API, "services", payload);
export const updateService = ({id}, payload) =>  put(API, `services/${id}`, payload);
export const deleteService =          ({id}) =>  del(API, `services/${id}`);

/* **************** Admin: Resources ***************** */
export const fetchResources =         filters =>  get(API, "resources", filters);
export const fetchResource  =          ({id}) =>  get(API, `resources/${id}`);
export const createResource =         payload => post(API, "resources", payload);
export const updateResource = ({id}, payload) =>  put(API, `resources/${id}`, payload);
export const deleteResource =          ({id}) =>  del(API, `resources/${id}`);

export const fetchModuleResources = ({ moduleId }) =>  get(API, `resources`, { moduleId });

/* **************** Admin: Roles ***************** */
export const fetchRoles =           filters =>  get(API, "roles", filters);
export const fetchRole  =          ({ id }) =>  get(API, `roles/${id}`);
export const createRole =           payload => post(API, `roles`, payload);
export const updateRole = ({ id }, payload) =>  put(API, `roles/${id}`, payload);
export const deleteRole =            ({id}) =>  del(API, `roles/${id}`);

export const addRoleResource      = ({ roleId, data }) =>  put(API, `roles/${roleId}/resources/${data.id}`);
export const removeRoleResource   = ({ roleId, data }) =>  del(API, `roles/${roleId}/resources/${data.id}`);

/* **************** Action: Login ***************** */
// export const login = (name, password) => post(API, "logins/login", { name, password }, SKIP_TOKEN);

export const loginSSO = (authToken) => {
	const type = "azure";
	return _login(`logins/login/${type}`, JSON.stringify({ authToken }));
};

export const login = (name, password) => {
	return _login("logins/login", JSON.stringify({ name, password }));
};

function _login(path, body) {
	const API_URL = appConfig.api[API];
	return new Promise((resolve, reject) => {
		if (API_URL) {
			const payload = {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body,
			};

			fetch(`${API_URL}${path}`, payload)
				.then(processStatus)
				.then(getCompatibleData)
				.then(response => {
					resolve(response);
				}, error => {
					reject(getCompatibleError(error));
				})
				.catch(error => {
					console.error("Catch error %o", error);
				});
		}
		else {
			console.error("Could not find the API BASE URL: appConfig.api." + API);
			reject();
		}
	});
}

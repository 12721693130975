export default {
	single: {
		name: ["internalName", "name", "originalTitle"],
		localizations: {
			title: ["title"],
			// description: ["synopsis.short"],
			description: ["synopsis"],
			assets: [
				{ type: "Image", fromCategory: "cover" },
			],
		},
		assets: [
			{ type: "Image", fromCategory: "main" },
		],
	},
	series: {
		name: ["internalName", "name", "originalTitle"],
		localizations: {
			title: ["title"],
			// description: ["synopsis.short"],
			description: ["synopsis"],
			assets: [
				{ type: "Image", fromCategory: "cover" },
			],
		},
		assets: [
			{ type: "Image", fromCategory: "main" },
		],
	},
	season: {
		name: ["internalName", "name", "originalTitle"],
		localizations: {
			title: ["title"],
			subTitle: ["label"],
			// description: ["synopsis.short"],
			description: ["synopsis"],
			assets: [
				{ type: "Image", fromCategory: "cover" },
			],
		},
		assets: [
			{ type: "Image", fromCategory: "main" },
		],
	},
	episode: {
		name: ["internalName", "name", "originalTitle"],
		localizations: {
			title: ["seriesTitle"],
			subTitle: ["title"],
			// description: ["synopsis.short"],
			description: ["synopsis"],
			assets: [
				{ type: "Image", fromCategory: "cover" },
			],
		},
		assets: [
			{ type: "Image", fromCategory: "main" },
		],
	},
	broadcast: {
		name: ["internalName", "name", "originalTitle"],
		channel: ["broadcast.channel"],
		localizations: {
			title: ["epgTitle"],
			subTitle: ["seriesTitle"],
			// description: ["synopsis.short"],
			description: ["synopsis"],
			assets: [
				{ type: "Image", fromCategory: "main" },
			],
		},
		assets: [
			{ type: "Image", fromCategory: "main" },
		],
	},
	single_bubbles: {
		name: ["internalName", "name", "originalTitle"],
		localizations: {
			title: ["title"],
			// description: ["synopsis.short"],
			description: ["synopsis"],
			assets: [
				{ type: "Image", fromCategory: "cover" },
			],
		},
		assets: [
			{ type: "Image", fromCategory: "Character1x1 Clean" },
		],
	},
	series_bubbles: {
		name: ["internalName", "name", "originalTitle"],
		localizations: {
			title: ["title"],
			// description: ["synopsis.short"],
			description: ["synopsis"],
			assets: [
				{ type: "Image", fromCategory: "cover" },
			],
		},
		assets: [
			{ type: "Image", fromCategory: "Character1x1 Clean" },
		],
	},
	season_bubbles: {
		name: ["internalName", "name", "originalTitle"],
		localizations: {
			title: ["title"],
			subTitle: ["label"],
			// description: ["synopsis.short"],
			description: ["synopsis"],
			assets: [
				{ type: "Image", fromCategory: "cover" },
			],
		},
		assets: [
			{ type: "Image", fromCategory: "Character1x1 Clean" },
		],
	},
	episode_bubbles: {
		name: ["internalName", "name", "originalTitle"],
		localizations: {
			title: ["seriesTitle"],
			subTitle: ["title"],
			// description: ["synopsis.short"],
			description: ["synopsis"],
			assets: [
				{ type: "Image", fromCategory: "cover" },
			],
		},
		assets: [
			{ type: "Image", fromCategory: "Character1x1 Clean" },
		],
	},
}
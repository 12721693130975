import React, { PureComponent } from 'react'
import { Link } from 'react-router'
import PropTypes from 'prop-types'
import moment from 'moment'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'

import Status from '../../../components/ui/status'
import { Content } from '../../../components/list/contentItem'
import ContentItem from '../../../components/list/contentItem'
import InlineHeading from '../../../components/ui/inlineHeading'
import Period from '../../../components/ui/period'
import Datetimepicker from '../../../components/ui/controls/pickers/datetimepicker'
import Button from '../../../components/ui/controls/button'
// import CommentField from '../commentfield/comments'

import Actions from '../actions'
import Const from '../../../core/constants'

const sortByLanguage = ["SV", "NO", "DA", "FI", "EN"]
const sortByIndex = ["1", "2", "3", "4"]
const sortByCountry = ["SE", "NO", "DK", "FI"]

export default class ProgramContent extends PureComponent {

	static propTypes = {
		id: PropTypes.number,
		thread: PropTypes.shape({
			numberOfComments: PropTypes.number
		}),
		originalTitle: PropTypes.string,
		type: PropTypes.string,
		allApproved: PropTypes.bool,
		approvals: PropTypes.object,
		versions: PropTypes.arrayOf(
			PropTypes.shape({
				packages: PropTypes.number,
				packagesSent: PropTypes.number,
				exposures: PropTypes.arrayOf(
					PropTypes.shape({
						owner: PropTypes.string
					})
				),
				assets: PropTypes.arrayOf(
					PropTypes.shape({
						name: PropTypes.string
					})
				)
			})
		),
		thread: PropTypes.object
	}

	constructor(props) {
		super(props);
		this.state = {
			action: "",
			value: "",
			name: [],
			open: false,
			// expandComments: true,
			expandActionsRegular: false,
			expandActionRegularAssetId: 0,
			expandActionsDubb: false,
			expandActionDubbAssetId: 0,
			expandRegions: true,
			expandEarliestDelivery: true,
			expandMediaRefInputField: null,
		}
	}

	toggleDownloadRights(exposure, version) {
		Actions.toggleDownloadRights(exposure, version, this.props.item);
		this.setState({ open: false });
	}

	updateDistributionData(version, distribution, value) {
		Actions.EarliestDerlivery(version, distribution, value, this.props.item)
	}

	addRegion(version, exposure, regions, name) {
		const index = exposure.regions.findIndex(r => r.displayName === name);
		if (index === -1) {
			exposure.regions.push({ displayName: name });
		} else {
			exposure.regions.splice(index, 1)
		};

		// TODO: Not updating list
		Actions.setRegions(version, exposure, regions, this.props.item)
	}

	updateAssetStatus(asset, version, event) {
		const value = event.target.value;
		if (value?.length) {
			Actions.updateAssetStatus(asset, version, this.props.item, value)
		}
	}

	exposureUpdate(exposure, version, key, value) {
		const payload = { [key]: value };
		Actions.exposureUpdate(exposure, version, this.props.item, payload)
	}
	priorityLvls(version, exposure, e) {
		Actions.priorityLvls(exposure, version, this.props.item, e.target.value)
	}

	updateSubStatus(version, asset, childAsset, e) {
		const value = e.target.value;
		if (value?.length) {
			Actions.updateSubStatus(version, asset, childAsset, this.props.item, value)
		}
	}

	updateAudioAssetLanguage(version, asset, childAsset, e) {
		const value = e.target.value;
		if (value?.length) {
			Actions.updateAudioLanguage(version, asset, childAsset, this.props.item, value)
		}
	}

	packageStatus(version, key, value) {
		const payload = { [key]: value };
		Actions.packageStatus(version, this.props.item, payload)
	}

	recall(version) {
		Actions.Recall(version, this.props.item)
	}

	send(version) {
		Actions.Send(version, this.props.item)
	}

	// toggleComments() {
	// 	this.setState({ expandComments: !this.state.expandComments })
	// }

	toggleActions = (assetId) => {
		this.setState({ expandActionsRegular: !this.state.expandActionsRegular, expandActionRegularAssetId: assetId})
	}

	toggleActionsDubb = (assetId) => {
		this.setState({ expandActionsDubb: !this.state.expandActionsDubb, expandActionDubbAssetId: assetId })
	}

	showRegions() {
		this.setState({ expandRegions: !this.state.expandRegions })
	}

	toggleEarliestDelivery() {
		this.setState({ expandEarliestDelivery: !this.state.expandEarliestDelivery })
	}

	handleOpen = () => {
		this.setState({ open: true });
	};

	handleClose = () => {
		this.setState({ open: false });
	};

	renderActionsForVideo = ({ version }) => {
		return (
			<div>
				{version.assets.map((asset, j) =>
					<div key={asset.id}>
						<Status
							status={asset.status}
							style={{ marginBottom: "5px" }}
							classNames={version.type === "Dubbed" && "hidden"}
							title={`${asset.format} ${asset.resolution === "Undefined" ? "" : (asset.resolution)}`}
						>
							{ truncate(`${asset.format} ${asset.resolution === "Undefined" ? "" : (asset.resolution)}`, 6) }
							<select value={asset.status} className={`select`} onChange={this.updateAssetStatus.bind(this, asset, version)}>
								<option value="">-- Select a status --</option>
								<option value="ignored">Ignored</option>
								<option value="failed">Failed</option>
								<option value="ok">Ok</option>
							</select>
						</Status>
					</div>
				)}
			</div>
		)
	}

	renderActionsForVideoDubb = ({ version }) => {
		return (
			<div>
				{version.assets.map((asset, j) =>
					<div key={asset.id}>
						<Status
							status={asset.status}
							style={{ marginBottom: "5px" }}
							classNames={version.type === "Regular" && "hidden"}
							title={`${asset.format} ${asset.resolution === "Undefined" ? "" : (asset.resolution)}`}
						>
							{ truncate(`${asset.format} ${asset.resolution === "Undefined" ? "" : (asset.resolution)}`, 6) }
							<select value={asset.status} className={`select`} onChange={this.updateAssetStatus.bind(this, asset, version)}>
								<option value="">-- Select a status --</option>
								<option value="ignored">Ignored</option>
								<option value="failed">Failed</option>
								<option value="ok">Ok</option>
							</select>
						</Status>
					</div>
				)}
			</div>
		)
	}

	renderActionsForVideoDubbAudios = ({ asset, version }) => {
		const languages = this.props.languages;

		return (
			<div>
				<Content>
					{version.assets.map((asset, j) =>
						<div key={asset.id} style={{ display: "flex" }}>
							{asset.childAssets.map(l => l.index).sort().map((trackIndex, l) =>
								<div key={l}>
								{/* This part writes out empty "statuses" so the boxes align correctly incase some assets are missing childAssets */}
									{asset.childAssets.length === 0 && (
										<div key={j+asset.id}  style={{ marginBottom: "1px", marginTop: "1px" }}>
											<Status
												status={"hidden"}
												classNames={version.type === "Regular" ? "hidden" : ""}
											>
											</Status>
										</div>
									)}
									{asset.childAssets.map((childAsset, i) =>
										<div key={i} style={{ display: "grid", textAlign: "center"}}>
											{/* {lang.iso2.includes(childAsset.language) && */}
											{trackIndex === childAsset.index &&
												<React.Fragment>
													<Status
														status={asset.status} // Audiotracks are part of the video file and not seperate assets, therefor childAssets share status with the asset
														classNames={version.type === "Regular" ? "hidden" : ""}
													>
														{`${childAsset.language} ${childAsset.resolution === "Undefined" ? "" : ""}`}
														<select value={childAsset.language} className={`select`} onChange={this.updateAudioAssetLanguage.bind(this, version, asset, childAsset)}>
															<option value="">-- Select a language --</option>
															{languages.map((language, key) => {
																		return <option key={language.iso2} value={language.iso2}>{language.name}</option>;
																	})}
														</select>
													</Status>
												</React.Fragment>	
											}
										</div>
									)}
								</div>
							)}
						</div>
					)}
				</Content>
			</div>
		)
	}

	renderActionsForVideoSubs = ({ asset, version }) => {
		return (
			<div>
				<Content>
					{version.assets.map((asset, j) =>
							<div key={asset.id} style={{ display: "flex"}}>
								{sortByLanguage.map((lang, l) =>
									<div key={l}>
										{/* This part writes out empty "statuses" so the boxes align correctly incase some assets are missing childAssets */}
										{asset.childAssets.length === 0 && (
											<div key={j+asset.id}  style={{ marginBottom: "1px", marginTop: "1px" }}>
												<Status
													status={"hidden"}
													classNames={version.type === "Dubbed" ? "hidden" : ""}
												>
												</Status>
											</div>
										)}
										{asset.childAssets.map((childAsset, i) =>
											<div key={i}>
												{lang.includes(childAsset.language) &&
													<Status
														status={getAssetStatus(childAsset)}
														classNames={version.type === "Dubbed" ? "hidden" : ""}
													>
														{`${childAsset.format} ${childAsset.resolution === "Undefined" ? "" : ""}`}
														<select value={childAsset.status} className={`select`} onChange={this.updateSubStatus.bind(this, version, asset, childAsset)}>
															<option value="">-- Select a status --</option>
															<option value="Ignored">Ignored</option>
															<option value="Failed">Failed</option>
															<option value="Ok">Ok</option>
														</select>
													</Status>
												}
											</div>
										)}
									</div>
								)}
							</div>
					)}
				</Content>
			</div>
		)
	}

	renderRegions = ({ exposure, version }) => {
		return (
			<Content>
				{sortByCountry.map((region) => (
					<button
						key={region}
						onClick={(event) => this.addRegion(version, exposure, exposure.regions, event.target.name)}
						className={exposure.regions.some(r => r.displayName === region) ? `regionButtons-included` : `regionButtons-excluded`}
						name={region}
						title="Region"
						style={{ fontSize: "9px", height: "18px", width: "29px", fontWeight: "bold" }}
					>
						{region}
					</button>
				))}
			</Content>
		)
	}

	renderDistributionStatus = ({ version }) => {
		return (
			<Content>
				<div style={{ display: "flex" }} className="distribution-status">
					{version.distribution.status === "Recalled" && (
						<span style={{ marginTop: "3px", marginRight: "10px", fontWeight: "bold", color: "red" }}>RECALLED</span>
					)}
					{version.distribution.status === "OnHold" && (
						<div style={{ display: "flex" }}><InlineHeading title={`On hold until`}><Period start={version.distribution.onHoldUntil} format={Const.DATE_TIME_FORMAT} /></InlineHeading></div>
					)}
					<Datetimepicker
						options={{ hideClear: true, static: false }}
						onChange={this.updateDistributionData.bind(this, version, version.distribution)}
						value={version.distribution.onHoldUntil}
					/>
					<div><span title="Recall" value="Recall" className="icon-sync large-icon" onClick={this.packageStatus.bind(this, version, "status")}></span></div>
					<div><span title="Send" value="Send" className="icon-cube-send large-icon" onClick={this.packageStatus.bind(this, version, "status")}></span></div>
				</div>
			</Content>
		)
	}

	renderWindowPeriods = ({ exposure, version, type }) => {
		if (typeof exposure.windowStart === "undefined") return <div></div>; // ?

		const ws = moment(exposure.windowStart);
		const we = moment(exposure.windowEnd);
		const ls = moment(exposure.licenseStart);
		const le = moment(exposure.licenseEnd);
		return (
			<div>
				<div style={{ marginBottom: "30px", marginRight: "6px" }}>
					<div className="windowPicker">
						<Datetimepicker
							options={{
								hideClear: true,
								static: false,
								// HACK?: Set seconds to minimum because otherwise the input field goes blank?? hmm...
								minDate: ls.seconds(0).toDate(),
								maxDate: moment.min(we, le).toDate(),
							}}
							onChange={this.exposureUpdate.bind(this, exposure, version, "windowStart")}
							// onChange={(value) => this.exposureUpdate(this, exposure, version, { windowStart: value, ... })}
							// value={exposure.windowStart}
							value={ws.toDate()}
						/>
						<span className="arrow-between-window" style={{ fontSize: "11px" }}>▶</span>
						<Datetimepicker
							options={{
								hideClear: true,
								static: false,
								minDate: moment.max(ws, ls).toDate(),
								// HACK?: Set seconds to maximum because otherwise the input field goes blank?? hmm...
								maxDate: le.seconds(59).toDate(),
							}}
							onChange={this.exposureUpdate.bind(this, exposure, version, "windowEnd")}
							// value={exposure.windowEnd}
							value={we.toDate()}
						/>
					</div>
					{type !== "Season" && this.renderMediaReference({ exposure, version })}
				</div>
			</div>
		);
	}

	renderLicensePeriod = ({ exposure, version }) => {
		return (
			<div>
				{typeof exposure.windowStart === "undefined" && (
					<div style={{ marginBottom: "30px" }}>
						<div className="license-period">
							<span className="icon-subscriptions"></span>
							<Period start={exposure.licenseStart} end={exposure.licenseEnd} format={Const.DATE_TIME_FORMAT} />
							{this.renderMediaReference({ exposure, version })}
						</div>
					</div>
				)}
			</div>
		)
	}

	renderMediaReference = ({ exposure, version }) => {
		const references = version.references.filter(ref => ref.type === "MediaReference");
		return (
			<div>
				<span className="reference-licens">Media reference:</span>
				<select
					title="Reference"
					value={exposure.mediaReference}
					onChange={(event) => this.exposureUpdate(exposure, version, "mediaReference", event.target.value)}
					className="reference-select"
					disabled={references.length < 1}
				>
					<option value={null}>{"No reference selected"}</option>
					{references.map(ref => (
						<option key={ref.reference} value={ref.reference}>
							{ref.reference}
						</option>
					))}
				</select>
			</div>
		)
	}

	render() {
		const { type, displayName, id, versions, seasonReference, reference, thread, allApproved, approvals, imdb, open, seriesReference, expandPackageDatepicker, watermarkVideo } = this.props.item;
		const openRegion = this.state.expandRegions;

		const imdbLink = imdb?.length > 0 && <a target="_blank" href={`https://www.imdb.com/title/${imdb}/`} className="c6-link">IMDB</a>;

		return (
			<ContentItem data={this.props.item} onExpandEarliestDelivery={this.toggleEarliestDelivery.bind(this)}>
				<Content className="program">
					<div className="head">
						<h1 title={displayName}>
							{`${displayName}`}
						</h1>
						<p>ID: {id} | {type} {imdbLink && "|"} {imdbLink}</p>
						{!this.props.isSeasonPackagesList && (type === "Episode" || type === "Season") && (
							<Link
								to={{ pathname: `/traffic/programs/season/${seasonReference}` }}
								className="c6-link"
								style={{ marginLeft: "4px" }}
							>
								Display season
							</Link>
						)}
					</div>
					<div className="metadata">
						<div style={{ display: "flex", justifyContent: "flex-end", marginRight: "5px" }}>
							{/* ***************** SINGLE ***************** */}
							{sortByCountry.map((country, i) =>
								<div className="asset-group-title" style={{ display: "grid" }} key={i}>
									{(type.includes("Single") || type.includes("Season") || type.includes("Series")) && (
										<React.Fragment>
											<span style={{ marginTop: "10px" }}>{country}</span>
											<Status
												status={getMetadataStatusSingle(type, approvals)}
												classNames={getMetadataStatusSingle(type, approvals, allApproved)}
											>
												<Link to={`/metadata/programs/cmore/${reference}`}>METADATA</Link>
											</Status>
										</React.Fragment>
									)}
								</div>
							)}
							{/* ***************** EPISODES ************** */}
							{sortByCountry.map((country, i) =>
								<div className="asset-group-title" style={{ display: "grid" }} key={i}>
									{type === "Episode" && (
										<React.Fragment>
											<span style={{ marginTop: "10px" }}>{country}</span>
											<Status
												status={getMetadataStatus(type, approvals, allApproved)}
												classNames={getMetadataStatus(type, approvals, allApproved)}
											>
												<Link to={`/metadata/programs/cmore/series/${seriesReference}`}>METADATA</Link>
											</Status>
										</React.Fragment>	
									)}
								</div>
							)}
						</div>
					</div>
					{versions.map((version, index) => (
						<React.Fragment key={index}>
							<div className="exposures">
								{/*<div className="dummy-space">
									<p>&nbsp;</p>
								</div>*/}
								<span className="exposure-type">{version.type === "Regular" ? " " : version.type}</span>
								{version.exposures.map((exposure, i) =>
									<div className="exposure" key={i}>
										{renderAttentionWarning({ exposure })}
										<InlineHeading title={exposure.type.name.toUpperCase()} key={i}>
											{this.renderWindowPeriods({ exposure, version, type })}
											{this.renderLicensePeriod({ exposure, version })}
											<div className="priority">
												<select className={`prioritylvls ${exposure.distributionPriority.toLowerCase()}`} title="Priority" value={exposure.distributionPriority} onChange={(event) => this.exposureUpdate(exposure, version, "distributionPriority", event.target.value)}>
													<option>Low</option>
													<option>Normal</option>
													<option>High</option>
													<option>Panic</option>
												</select>
											</div>
											{/*Object.entries(exposure.contract.downloadRights).length > 0 ?
												<div>
													{exposure.excludeDownloadRights ?
														<span title="Download Rights" onClick={this.handleOpen.bind(this)}><span className="icon-download dlr-false">DLR</span></span>
														:
														<span title="Download Rights" onClick={this.handleOpen.bind(this)}><span className="icon-download dlr-true">DLR</span></span>
													}
												</div>
												:
												<span style={{ marginLeft: "59.5px" }}>&nbsp;</span>
											*/}
											{/* ****** Denna kod är inte säker på att vara kvar, väntar på Johan. Men om den ska det så kanske den borde vara i egen component? ********/}
											{/* <Dialog
												modal={false}
												open={this.state.open}
												onRequestClose={this.handleClose}
												style={{ textAlign: "center" }}
											>
												{exposure.excludeDownloadRights ? <span style={{ fontSize: "25px" }}>Do you want to exclude download rights?</span> : <span style={{ fontSize: "25px" }}>Do you want to include download rights?</span>}
												<ul style={{ listStyle: "none", textAlign: "left", float: "left", paddingLeft: "50px" }}>
													<li style={{ fontWeight: "bold" }}>TimeToLiveDownloadedAfterStart:<span style={{ marginLeft: "49px", fontWeight: "bold" }}> {exposure.contract.downloadRights.timeToLiveDownloadedAfterStart ? exposure.contract.downloadRights.timeToLiveDownloadedAfterStart : "N/A"}</span></li>
													<li>MaxNumberOfDownloads:<span style={{ marginLeft: "108px" }}>{exposure.contract.downloadRights.maxNumberOfDownloads ? exposure.contract.downloadRights.maxNumberOfDownloads : "N/A"}</span></li>
													<li style={{ fontWeight: "bold" }}>MaxConcurrentDevicesPerDownload:	<span style={{ marginLeft: "30px", fontWeight: "bold" }}> {exposure.contract.downloadRights.maxConcurrentDevicesPerDownload ? exposure.contract.downloadRights.maxConcurrentDevicesPerDownload : "N/A"}</span></li>
													<li>TimeToKeepDownloaded:<span style={{ marginLeft: "114px" }}>{exposure.contract.downloadRights.timeToKeepDownloaded ? exposure.contract.downloadRights.timeToKeepDownloaded : "N/A"}</span></li>
													<li style={{ fontWeight: "bold" }}>MaxConcurrentDownloads:<span style={{ marginLeft: "102px", fontWeight: "bold" }}>{exposure.contract.downloadRights.maxConcurrentDownloads ? exposure.contract.downloadRights.maxConcurrentDownloads : "N/A"}</span></li>
												</ul>
												<FlatButton
													label="No"
													value="No"
													primary={true}
													onClick={this.handleClose}
												/>
												<FlatButton
													label="Yes"
													value="Yes"
													primary={true}
													onClick={this.toggleDownloadRights.bind(this, exposure, version)}
												/>
											</Dialog> */}
											{/* ************************************************************* SLUT KOMMENTAR **********************************************************/}
										</InlineHeading>
										{this.renderRegions({ version, exposure })}
									</div>
								)}
							</div>
							<div className="studio">
								{versions.map((version, v) =>
									<span key={v}>
										{version.exposures.slice(0, 1).map((exposure, e) =>
											<span key={e} title="Studio">
												{version.type === "Regular" && <InlineHeading title={`${exposure.contract.distributor !== null ? exposure.contract.distributor : ""}`}>&nbsp;</InlineHeading>}
											</span>
										)}
									</span>
								)}
							</div>
							{/* {version.assets.length > 0 && (version.type === "Regular" ? version.assets.slice(0, 1) : version.assets).map((asset, k) => ( */}

							{version.assets.length > 0 && version.assets.slice(0, 1).map((asset, k) => (
								<div className="asset-buttons" key={`asset-buttons-${asset.id}`}>
									<div>
										<Status
											label={asset.type}
											status={getDefaultAssetStatus(asset, version)} // Default asset status is the status you see in the expandable video status button
											classNames={version.type === "Dubbed" ? "hidden" : ""}
										>
											<span onClick={this.toggleActions.bind(this, asset.id)}>{asset.status.toUpperCase()}<span className={!this.expandActionsRegular ? "icon-expand_more medium-icon" : "icon-expand_less medium-icon"} style={{ float: "right" }}></span></span>
										</Status>
										<Status
											label={asset.type}
											status={getDefaultAssetStatus(asset, version)} // Default asset status is the status you see in the expandable video status button
											classNames={version.type === "Regular" ? "hidden" : ""}
										>
											<span onClick={this.toggleActionsDubb.bind(this, asset.id)}>{asset.status.toUpperCase()}<span className={!this.expandActionsDubb ? "icon-expand_more medium-icon" : "icon-expand_less medium-icon"} style={{ float: "right" }}></span></span>
										</Status>
										{/* {(version.type === "Regular") sortByLanguage.map((lang, k) => */}
										{(version.type === "Regular" ? sortByLanguage : sortByIndex).map((sortValue, k) =>
											<div key={k}>
												{asset.childAssets.map((childAsset, j) =>
													<div key={j} style={{ display: "flex"/*, justifyContent: "flex-end"*/ }}>
														{(sortValue.includes(childAsset.language) || sortValue.includes(childAsset.index)) &&
															<div>
																<Status
																	label={childAsset.type.toUpperCase() + ` ${childAsset.index ?? ""}`}
																	status={version.type === "Dubbed" ? getAssetStatus(asset) : getAssetStatus(childAsset)} // dubbed show status of parentAsset (included)
																	classNames={"waiting"} // Dubbed child asset status flags not shown unless expanded
																>
																	{version.type === "Dubbed" ? asset.status.toUpperCase() : childAsset.status.toUpperCase()}
																</Status>
															</div>
														}
													</div>
												)}
											</div>
										)}
									</div>
									<div style={{ display: "flex" }}>
										{this.state.expandActionsRegular && this.state.expandActionRegularAssetId === asset.id && version.type === "Regular" && this.renderActionsForVideo({ version })}
										{this.state.expandActionsDubb && this.state.expandActionDubbAssetId === asset.id && version.type === "Dubbed" && this.renderActionsForVideoDubb({ version })}
										{this.state.expandActionsRegular && this.state.expandActionRegularAssetId === asset.id && this.renderActionsForVideoSubs({ asset, version })}
										{this.state.expandActionsDubb && this.state.expandActionDubbAssetId === asset.id && this.renderActionsForVideoDubbAudios({ asset, version })}
									</div>
								</div>
							))}
							{!(version.assets.length > 0) && <div className="asset-buttons"><p style={{ margin: "0 5px", width: "100%" }}>NO ASSETS AVAILABLE</p></div>}
							<div className="last-changed" /*style={{ paddingTop: "36px", marginLeft: "25px" }}*/>
								<InlineHeading title="Last changed" className="Last modified">
									<Period start={version.modified} format={Const.DATE_TIME_FORMAT} />
								</InlineHeading>
							</div>
							<div className="deliveries" /*style={{ display: "flex", paddingTop: "36px" }}*/>
								<Status
									label={version.packages ? "DELIVERIES" : ""}
									status={version.packagesSent === version.packages ? "ok" : "ok-with-warning"}
									classNames={version.packages ? "packagesColor" : "hidden"}
								>
									<Link to={{ pathname: `/traffic/programversions/${version.id}/packages`, state: { modal: true, returnTo: window.location.pathname } }}>{version.packagesSent}/{version.packages}</Link>
								</Status>
								{version.distribution.status !== "Normal" ? this.state.expandEarliestDelivery && this.renderDistributionStatus({ version }) : !this.state.expandEarliestDelivery && this.renderDistributionStatus({ version })}
							</div>
							<div className="watermark">
								<FormControlLabel
									control={
										<Checkbox
											defaultChecked={watermarkVideo ?? false}
											onChange={handleWatermarkCheck.bind(this, id)}
										/>
									}
									label="Watermark"
								/>
							</div>
							<div className="version-menu">
								{type === "Season" && (
									<>
										<Button
											type="more_vert large-icon"
											noBackground
											onClick={() => this.setState({ versionMenuOpen: true })}
											buttonRef={ref => this.versionMenuButtonRef = ref}
											style={{ color: "var(--action-color) "}}
										/>
										<Menu
											anchorEl={this.versionMenuButtonRef}
											open={!!this.state.versionMenuOpen}
											onClose={() => this.setState({ versionMenuOpen: false })}
										>
											<MenuItem
												onClick={() => {
													const callback = this.props.onApplySeasonChangesToEpisodes;
													Actions.applySeasonChangesToEpisodes(this.props.item, version, "all", callback);
												}}
											>
												Apply to all
											</MenuItem>
											<MenuItem
												onClick={() => {
													const callback = this.props.onApplySeasonChangesToEpisodes;
													Actions.applySeasonChangesToEpisodes(this.props.item, version, "dubbed", callback);
												}}
											>
												Apply to dubbed only
											</MenuItem>
											<MenuItem
												onClick={() => {
													const callback = this.props.onApplySeasonChangesToEpisodes;
													Actions.applySeasonChangesToEpisodes(this.props.item, version, "regular", callback);
												}}
											>
												Apply to not dubbed only
											</MenuItem>
										</Menu>
									</>
								)}
							</div>
						</React.Fragment>
					))}
				</Content>
				{/*<Content className="comments">
					{!this.state.expandComments && <CommentField thread={typeof thread === "undefined" ? 0 : thread} programId={id} numberOfComments={this.renderNumberOfComments({ thread })} />}
				</Content>*/}
			</ContentItem>
		);
	}
}

function handleWatermarkCheck(id, event, checked) {
	if (checked === true) {
		Actions.enableVideoWatermark(id);
	} else {
		Actions.disableVideoWatermark(id);
	}
}

function getMetadataStatus(type, approvals, allApproved, approvedVersions, version) {
	if (type === "Single" || type === "Season" || type === "Series") {
		return "hidden";
	}
	if (!approvals.metadataApproved && !approvals.imageApproved && !approvals.seasonApproved && !approvals.seriesApproved) {
		return "failed"
	}
	if (approvals.metadataApproved && approvals.imageApproved && approvals.seasonApproved && approvals.seriesApproved) {
		return "ok"
	}
	return "ok-with-warning";
}

function getDefaultAssetStatus(asset, version) {
	if (version.assets?.length === 1) {
		return asset.status; // Single asset, it should dominate the default asset status
	} else {
		const childAssetsHasSameStatus = version.assets?.every(a => a.status === version.assets[0]?.status);
		if (childAssetsHasSameStatus) return asset.status;
		
		return "ok-with-warning";
	}
}

function getMetadataStatusSingle(type, approvals, allApproved, approvedVersions, version) {
	if (type === "Episode") {
		return "hidden";
	}

	if (!approvals.metadataApproved && !approvals.programApproved && !approvals.imageApproved) {
		return "failed"
	} else if (approvals.metadataApproved && approvals.programApproved && approvals.imageApproved) {
		return "ok"
	}
	return "ok-with-warning";
}

function renderAttentionWarning({ exposure }) {
	switch (exposure.attentionStatus) {
		case "Orange":
			return <span title="Needs Attention" className="need-attention-true icon-report"></span>;
		case "Red":
			return <span title="Needs Attention" className="need-attention-true-red icon-report"></span>;
	}
}

function getAssetStatus(asset) {
	if (asset.canBeIgnored/* || asset.status === "Waiting"*/) {
		return "disabled";
	}

	return asset.status?.toLowerCase();
}

// Truncating text in video file status boxes for a unified size/aligntment
function truncate(text, maxLength) {
	if (text.length < maxLength) return text;
	return text.slice(0, maxLength);
}
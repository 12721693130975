import React from 'react'
import moment from 'moment'

import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import Radio from '@mui/material/Radio'
import FormControlLabel from '@mui/material/FormControlLabel'

import DateTimePicker from '../../../components/ui/controls/pickers/datetimepicker'
import DatePicker from '../../../components/ui/controls/pickers/datepicker'
import EventPlannerComponent from '../../../components/cms/component'
import CMSDurationPicker from '../../../components/cms/components/durationPicker'
import CMSDateTimePicker from '../../../components/cms/components/datetimepicker'

import { getTemplateWithValues, getEntityWithUpdatedValue } from '../../../core/cms/utils'

import appConfig from 'config'

const DEFAULT_CATCHUP_AVAILABILITY_PERIOD = appConfig.features.eventplannerDefaultCatchupAvailabilityPeriod ?? "2.00:00:00";
const DEFAULT_PUBLISH_BEFORE_EVENT_START = appConfig.features.eventplannerDefaultPublishBeforeEventStart ??  "90.00:00:00";
const DEFAULT_PUBLISH_TIME_OF_DAY = appConfig.features.eventplannerDefaultPublishTimeOfDay ?? "00:00:00";

export default function StepTwo(props) {
	const now = moment().format();

	const [start, setStart] 							= React.useState(now);
	const [duration, setDuration] 						= React.useState(240);
	const [title, setTitle] 							= React.useState("");
	const [interval, setInterval]						= React.useState("day");
	const [eventsPerInterval, setEventsPerInterval] 	= React.useState(5);
	
	const [catchupAvailabilityPeriod, setCatchupAvailabilityPeriod] 					= React.useState(DEFAULT_CATCHUP_AVAILABILITY_PERIOD);
	const [publishBeforeEventStart, setPublishBeforeEventStart] 						= React.useState(DEFAULT_PUBLISH_BEFORE_EVENT_START);
	const [publishTimeOfDay, setPublishTimeOfDay] 										= React.useState(DEFAULT_PUBLISH_TIME_OF_DAY);

	const [limit, setLimit]								= React.useState("date");
	const [dateLimit, setDateLimit]						= React.useState(now);
	const [numberLimit, setNumberLimit]					= React.useState(50);

	const [broadcastOffset, setBroadcastOffset]			= React.useState(5);

	const [settings, setSettings] 						= React.useState({});
	React.useEffect(
		() => {
			// Since settings is initialized empty (props.payload.season is null when StepTwo is mounted)
			// we need to update settings when the user has selected an existing season
			if (props.payload.season?.id !== settings?.id) {
				setSettings(props.payload.season ?? {});
				setCatchupAvailabilityPeriod(props.payload.season.catchupAvailabilityPeriod);
				setPublishBeforeEventStart(props.payload.season.publishBeforeEventStart);
				setPublishTimeOfDay(props.payload.season.publishTimeOfDay);
			}
		},
		[props.payload.season?.id, settings, setSettings]
	);

	const nodes = getTemplateWithValues(props.eventTemplate?.children ?? [], settings);
	const onComponentChange = React.useCallback(
		({ data, path }) => {
			const updatedSettings = getEntityWithUpdatedValue(settings, path, data);
			setSettings(updatedSettings);
		},
		[settings, setSettings]
	);

	if (!props.active) {
		return null;
	}
    
    const actions = props.getActions({
        onNextClick: () => props.onNextClick({
            ...props.payload,
            title,
            duration,
			catchupAvailabilityPeriod,
			publishBeforeEventStart,
			publishTimeOfDay,
            start,
            interval,
			eventsPerInterval,
			broadcastOffset,
			mode: props.mode,
			dateLimit: limit === "date" ? dateLimit : null,
			numberLimit: limit === "number" ? numberLimit : null,
			settings,
		}),
	});

	const isImportMode = ["score24", "metadata"].includes(props.mode);

	return (
		<div className="step">
			<div className="content">
				{isImportMode && (
					<>
						<span>Start the broadcast</span>
						<TextField
							variant="standard"
							className="c6-mui"
							name="broadcastOffset"
							value={broadcastOffset}
							onChange={e => setBroadcastOffset(e.target.value)}
							style={{ width: "60px", verticalAlign: "unset" }}
							type="number"
							min={0}
							max={1439}
						/>
						<span>minutes before the event</span>

						<br/>
					</>
				)}

				{!isImportMode && (
					<>
						{props.mode === "multiple" && (
							<span>First event at:</span>
						)}

						<DateTimePicker
							onChange={(val) => setStart(moment(val).format())}
							value={start}
							options={{ hideClear: true }}
							staticRender={false}
						/>
					</>
				)}

				{isImportMode && <span>Estimated event duration:</span>}
				{!isImportMode && <span>Estimated duration:</span>}

				<TextField
					variant="standard"
					className="c6-mui"
					name="duration"
					value={duration}
					onChange={e => setDuration(e.target.value)}
					style={{ width: "60px", verticalAlign: "unset" }}
					type="number"
					min={0}
					max={1439}
				/>
				<span>minutes</span>

				<br/>

				{isImportMode && (
					<>
						<p><strong>Standard settings for the events</strong></p>
						<div className="c6-eventplanner-events">
							{nodes?.map(node => (
								<EventPlannerComponent
									key={node.name}
									templateItem={node}
									onChange={onComponentChange}
									path={[]}
									itemData={settings}
								/>
							))}
						</div>
					</>
				)}

				{props.mode === "multiple" && (
					<>
						<Select
							className="c6-mui-select"
							value={interval}
							onChange={(e) => setInterval(e.target.value)}
							variant="standard"
							MenuProps={{ className: "c6-mui-select-menu" }}
							autoWidth={true}
							sx={{ minWidth: "200px" }}
						>
							<MenuItem value={"no-interval"}>No repeats (just a single event)</MenuItem>
							<MenuItem value={"day"}>Repeat daily</MenuItem>
							<MenuItem value={"week"}>Repeat weekly</MenuItem>
							<MenuItem value={"month"}>Repeat monthly</MenuItem>
						</Select>

						{interval !== "no-interval" && (
							<>
								<span>with</span>
								<TextField
									name="eventsPerInterval"
									type="number"
									variant="standard"
									min={1}
									max={100}
									value={eventsPerInterval}
									onChange={e => setEventsPerInterval(e.target.value)}
									style={{ width: "auto" }}
								/>
								<span>events per {interval}.</span>
							</>
						)}
						<br/>
					</>
				)}
				
				<div className="catchup-publish-settings">
					<div>
						<p style={{ margin: 0 }}>
							<strong>
								{props.mode === "single" && "Catchup availability period"}
								{props.mode !== "single" && "Default catchup availability period"}
							</strong>
						</p>
						<div className="c6-eventplanner-events">
							<CMSDurationPicker
								onChange={val => setCatchupAvailabilityPeriod(val)}
								name="catchup-availability"
								displayName="Catchup"
								value={catchupAvailabilityPeriod}
								style={{ format: "days-hours" }}
								className="template-item"
							/>
						</div>
					</div>
					<div>
						<p style={{ margin: 0 }}>
							<strong>
								{props.mode === "single" && "When to publish event (how long before event start)"}
								{props.mode !== "single" && "Default settings for when to publish events (how long before event start)"}
							</strong>
						</p>
						<div className="c6-eventplanner-events">
							<CMSDurationPicker
								onChange={val => setPublishBeforeEventStart(val)}
								name="publish-before-event-start"
								displayName="Number of days"
								value={publishBeforeEventStart}
								style={{ format: "days" }}
								className="template-item"
							/>
							<CMSDateTimePicker
								onChange={val => setPublishTimeOfDay(val)}
								name="publish-time-of-day"
								displayName="Time of day"
								value={publishTimeOfDay}
								style={{ format: "hours-minutes" }}
								dataType="timeSpan"
								className="template-item"
							/>
						</div>
					</div>
				</div>

				{props.mode === "single" && (
					<TextField
						autoComplete="off"
						variant="standard"
						className="c6-mui"
						name="title"
						value={title}
						onChange={e => setTitle(e.target.value)}
						style={{ width: "auto" }}
						placeholder={`Event title${props.mode === "multiple" ? " (optional)" : ""}`}
					/>
				)}

				{props.mode === "multiple" && interval !== "no-interval" && (
					<>
						<FormControlLabel
							className="radio"
							name="limit"
							checked={limit === "date"}
							label={(
								<div className="radio-label-wrapper" disabled={limit !== "date"}>
									<span>Until</span>
									<DatePicker
										onChange={(val) => setDateLimit(moment(val).format("YYYY-MM-DD"))}
										value={dateLimit}
										options={{ hideClear: true }}
										staticRender={false}
									/>
								</div>
							)}
							onClick={() => setLimit("date")}
							control={<Radio />}
						/>
						<FormControlLabel
							className="radio"
							name="limit"
							checked={limit === "number"}
							label={(
								<div className="radio-label-wrapper" disabled={limit !== "number"}>
									<span>Up until</span>
									<span className="number-limit-wrapper">
										<TextField
											autoComplete="off"
											variant="standard"
											className="c6-mui"
											name="numberLimit"
											value={numberLimit}
											onChange={e => setNumberLimit(e.target.value)}
											style={{ width: "auto" }}
											type="number"
											min={1}
											max={100}
										/>
									</span>
									<span>events in total</span>
								</div>
							)}
							onClick={() => setLimit("number")}
							control={<Radio />}
						/>
					</>
				)}
			</div>

			{actions}
		</div>
	);
}
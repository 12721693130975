import { browserHistory } from 'browserHistory'

import { MENU } from '../../../../components/actions'
import * as CMSAPI from '../../../../apis/cms'
import { getUserAccessLevelForModule } from '../../../../core/services/auth'
import { updateItemInMatchingQueries } from '../../../../core/hooks/useList'
import EditorNavigationController from '../../../../core/ui/editorNavigationController'

const actions = [
	{
		title: "Edit",
		icon: "create",
		type: MENU,
		method: (data, sourceProps) => {
			browserHistory.push({
				pathname: `/eventplanner/admin/${sourceProps.params.appKey}/${data.id}/edit`,
				state: {
					modal: true,
					returnTo: `${sourceProps.location.pathname}${sourceProps.location.search}`,
				},
			});

			EditorNavigationController.setCurrentItem(data);
		},
		hidden: (data, sourceProps, location, params, itemProps) => {
			return itemProps.hideActions;
		},
		disabled: (data, sourceProps) => false,
	},
	{
		title: "Audit trail",
		icon: "shoe-print",
		type: MENU,
		method: (data, sourceProps, location, params, itemProps) => {
			itemProps.openModal({
				modalName: "audit",
				itemData: data,
				id: data.id,
				collection: itemProps.collection,
				module: itemProps.module,
			});
		},
		hidden: (data, sourceProps, location, params, itemProps) => {
			// const user = getUser();
            // const isSysadmin = user.username === "sysadmin";
			// const hasAuditRole = hasRole("eventplanner.audit");
			return itemProps.hideActions
				// || !isSysadmin && !hasAuditRole
				|| !itemProps.openModal
				|| !itemProps.collection;
		},
	},
	{
		title: "Delete",
		icon: "delete",
		type: MENU,
		method: async (data, sourceProps) => {
			await CMSAPI.deleteCMSEntity({ _entity: sourceProps.params.appKey, id: data.id });
			updateItemInMatchingQueries({ store: sourceProps.params.appKey, item: data, isDelete: true });
		},
		confirm: true,
		confirmText: "Are you sure you want to delete this item?",
		hidden: (data, sourceProps, location, params, itemProps) => {
			const accessLevel = getUserAccessLevelForModule("eventplanner");
            const isAdmin = accessLevel === "admin";
			return itemProps.hideActions || !isAdmin;
		}
	}
];

export default {
	targetComponent: "Content",
	actions,
}

import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'
import appConfig from 'config'

import Label from '../../../components/ui/label'
import Switch from '../../../components/ui/controls/switch'
import { getLicenseClass } from '../shared/utils'

import Actions from '../actions'

const ENTITY = "license";

import ContentItem, { Content } from '../../../components/list/contentItem'

export default class LicenseContent extends PureComponent {

	static propTypes = {
		parent: PropTypes.object,
		id: PropTypes.number,
		program: PropTypes.object,
		contract: PropTypes.object,
		class: PropTypes.string,

		className: PropTypes.string,
	}

	constructor(props) {
		super(props);
	}

	setClass(id, e) {
		if(e.target.value) {
			const targetStore = this.props.parent && this.props.parent.id ? "licenseEpisodes" : null;
			Actions.updateItem(ENTITY, id, { class: e.target.value }, "update", targetStore);
		};
	}

	render() {
		const {
			parent,
			id,
			program,
			contract,
			class: licenseClass,
			className = "",
			listFilters,
		} = this.props;

		const { class: seasonClass } = parent;
		const { id: programId, name, productionYear, type } = program;
		const { provider } = contract;
		const providerName = provider.name === "Acq" ? "Acquisitions" : provider.name;
		const year = type === "Single" && productionYear ? ` (${productionYear})` : "";
		const episodesLink = type === "Season" && getEpisodesLink(listFilters, contract, id, program);

	    return (
			<ContentItem extraClasses={className} data={this.props}>
				<Content style={{ flex: 4 }}>
					<h1>{name}{year}{episodesLink}</h1>
					<p>
						<Link to={`/acq/contracts/${contract.id}/licenses`}>{contract.name}</Link>&nbsp;
						({contract.distributor.name})&nbsp;
						<Label size="">{providerName}</Label>&nbsp;
					</p>
				</Content>
				<Content style={{ flex: 3 }}>
					<Switch
						name={`licenseClass-${id}`}
						title="Class"
						onChange={this.setClass.bind(this, id)}
						states={getLicenseClass()}
						currentState={type === "Episode" ? seasonClass : licenseClass}
						disabled={type === "Episode"}
					/>
				</Content>
			</ContentItem>
		);
  	}
}


function getEpisodesLink(listFilters, contract, licenseId, program) {
	const contractId = listFilters.contractId || contract && contract.id;
	const episodesLink = contractId
		? `/acq/contracts/${contractId}/licenses/${licenseId}/?parentProgramId=${program.id}`
		: `/acq/licenses/${licenseId}/?parentProgramId=${program.id}`;
	
	return <Link to={episodesLink} className="c6-link" style={{ marginLeft: "5px", padding: "0 1px" }}>Display episodes</Link>;
}
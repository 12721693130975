import { Regions } from '../../../core/constants'

export function translateData(source, config) {
	const { assets: assetsConfig, localizations: localizationsConfig, ...rest } = config;
	const assets = getRootLevelAssets(source, assetsConfig);
	const localizations = getLocalizationsFromSource(source, localizationsConfig);
	const otherFields = getFieldsFromSource(source, rest);
	return {
		...otherFields,
		assets,
		localizations,
	};
}

function getFieldsFromSource(source, config) {
	let result = {};
	Object.entries(config).forEach(([targetKey, sourceKeys]) => {
		const value = getValueFromSource(source, sourceKeys);
		result[targetKey] = value;
	});
	return result;
}


function getRootLevelAssets(source, assetsConfig = []) {
	const lang = /*config.preferredLang ??*/ "sv";
	let assets = source.programVersions?.find(v => v.language === lang)?.assets ?? [];
	if (!assets.length) {
		assets = source.programVersions?.find(v => v.assets?.length)?.assets ?? [];
	}
	return getAssetsFromSource(assets, assetsConfig);
}

function getAssetsFromSource(assets, assetsConfig = []) {
	let result = [];
	assetsConfig.forEach(config => {
		const matchingAsset = assets.find(a => 
			a.type.toLowerCase() === config.type.toLowerCase()
			&& a.category.toLowerCase() === config.fromCategory.toLowerCase()
		);
		if (matchingAsset) {
			result.push({
				guid: matchingAsset.id,
				type: config.type,
			});
		}
	});
	return result;
}

export function getLocalizationsFromSource(source, localizationsConfig) {
	let result = [];
	source.programVersions?.forEach(version => {
		const region = Regions.find(r => r.language === version.language || r.country === version.language);
		const versionResult = {
			version: { id: region?.id ?? 1, name: region?.languageDisplayName ?? "Swedish" },
		};
		for (const [targetKey, sourceKeys] of Object.entries(localizationsConfig)) {
			let value;
			if (targetKey === "assets") {
				value = getAssetsFromSource(version.assets, localizationsConfig.assets);
			} else {
				value = getValueFromSource(version, sourceKeys);
			}
			versionResult[targetKey] = value;
		}
		result.push(versionResult);
	});
	return result;
}

function getValueFromSource(source, keys) {
	for (const key of keys) {
		if (source[key]) return source[key];
		if (key.includes(".")) {

			const value = getNestedValueFromSource(source, key);
			if (value) return value;
		}
	}

	return null;
}

function getNestedValueFromSource(source, key) {
	const [currentKey, ...restOfKeyParts] = key.split(".");
	const restOfKey = restOfKeyParts.join(".");
	const currentValue = source[currentKey];
	if (!restOfKey?.length) {
		return currentValue;
	}

	return getNestedValueFromSource(currentValue, restOfKey);
}
import React 				 from 'react'
import { Route, IndexRoute } from 'react-router'

import Programs from './programs/app'
import ExposureEditor from './exposureeditor/app'
import VersionEditor from './versioneditor/app'
import Packages from './packages/packages'
import Events from './events/app'
import SeasonApp from './season/app'

import TemplateApp from '../eventplanner/template/app'

export default class TrafficModule {
	static routes() {
		return (
			<Route path="traffic" module="traffic">
				<Route path= "programs" component={Programs} app="programs" />
				{/* <Route path= "programs/cmore" component={Programs} app="programs"  /> */}
				<Route path="programs/(:id)/edit" component={VersionEditor} app="programs" modal={true} access="editor" />
				
				<Route path="programs/season/:id" component={SeasonApp} />
				
				<Route path="programversions/:id/packages" component={Packages} modal={true}/>
				
				<Route path="programs/:id/events" component={Events} />

				<Route path="exposures/(:id)/edit" component={ExposureEditor} modal={true} app="programs" access="editor" />
				<Route path="exposures/:id" app="programs" access="editor" />

				<Route path="template" component={TemplateApp} api="trafficc8" defaultTemplate="programs" defaultModule="traffic" />
			</Route>
		);
	}
}
import React from 'react'

import { MuiRadio, MuiText } from '../../../components/ui/editorWidgets'
import { DateTimePicker } from '../../../components/ui/controls/pickers'

import appConfig from 'config'

export const Period = ({ formData, schema, onChange, formContext }) => {

	const { id, type, inputSource, start, end, mediaReference } = formData;

	const typeProperties = schema.properties.type.properties;

	// If we only have one window type we need to pre-fill it when changing other
	// properties since there will be no radio buttons to select it from
	const refConsts = typeProperties.reference?.oneOf?.map(r => r.const);
	const idConsts = typeProperties.id?.oneOf?.map(i => i.const);
	let typeObject = {};
	if (refConsts && refConsts.length === 1) {
		typeObject = setTypeData({}, refConsts[0]);
	}
	else if (idConsts && idConsts.length === 1) {
		typeObject = setTypeData({}, idConsts[0]);
	}

	const      handleTypeChange = value => { onChange(setTypeData(formData, value)); };
	const     handleStartChange = value => { onChange({ ...formData, start: value, ...typeObject }); };
	const       handleEndChange = value => { onChange({ ...formData, end: value, ...typeObject }); };
	const handleReferenceChange = value => { onChange({ ...formData, mediaReference: value, ...typeObject }); };

	const isExternal = inputSource === "Auto";
	const isEditable = !isExternal && !type.name;

	const defaultMediaReference = id || mediaReference || mediaReference === "" ? mediaReference : formContext.programReference;

	return (
		<fieldset className="field" key={id || Date.now()}>
			<div className="row">
				{renderWindowType(type, typeProperties, isEditable, isExternal, handleTypeChange)}
				<div className="flex flex-space-between">
					<DateTimePicker
						value={start}
						label="First date"
						disabled={!isEditable}
						onChange={handleStartChange}
						options={{ thirdWidth: true }}
					/>
					<DateTimePicker
						value={end}
						label="Last date"
						disabled={!isEditable}
						onChange={handleEndChange}
						options={{ thirdWidth: true }}
					/>
					<MuiText
						value={ defaultMediaReference }
						label="Media Reference"
						disabled={!isEditable}
						onChange={handleReferenceChange}
						options={{ thirdWidth: true }}
					/>
				</div>
			</div>
		</fieldset>
	);
};

// HELPERS
function setTypeData(formData, reference) {
	// HACK: Since Octopus does not handle JSON values nicely, try parsing internalWindowTypes from a string
	let wt = appConfig.acq && appConfig.acq.internalWindowTypes || [];
	try {
		if (!Array.isArray(wt)) {
			wt = JSON.parse(wt);
		}
	} catch (e) {
		console.error("Could not parse JSON value in appConfig.acq.internalWindowTypes. Setting wt = []");
		wt = [];
	}

	const references = wt.reduce((acc, curr) => [...acc, ...curr.reference], []);

	const { owner = undefined } = references.find(r => r.key === reference);

	return {
		...formData,
		type: {
			...formData.type,
			reference,
			owner,
		},
	}
}

function renderWindowType(type, typeProperties, isEditable, isExternal, handleTypeChange) {
	const tp = typeProperties.reference || typeProperties.id;

	if(!isEditable || tp.oneOf?.length === 1) {
		return isExternal
			? <label>{`${type.class.toUpperCase()} (${type.name} in ${type.owner || "Louise"})`}</label>
			: <label>{(type.name || tp.oneOf?.[0].title).toUpperCase()}</label>;
	}
	else {
		const options = {
			enumOptions: tp.oneOf.map((o) => ({
				value: o.const,
				label: o.title,
			}))
		}

		return <MuiRadio value={type.reference} options={options} onChange={handleTypeChange} />;
	}
}
import React from 'react'
import { getItems } from '../../components/genericApp/utils'
import EditorNavigationController from '../ui/editorNavigationController'

export default function useEditorNavigation(listState) {
    React.useEffect(
        () => {
            const items = getItems(listState?.data);
            EditorNavigationController.setItems(items);
        },
        [listState?.data]
    );
}

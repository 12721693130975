import React from 'react'
import { Filter, Left, Right } from '../../../components/filter'
import Switch from '../../../components/ui/controls/switch'
import Button from '../../../components/ui/controls/button'
import Search from '../../../components/ui/controls/search'
import { getFilters, getProvider, getWindowTypeClass, getPremiere, getDownloadRightsStates } from '../shared/utils'

import Actions from '../actions'

import appConfig from 'config'

export default ({
	title,
	searchText,
	filters,
	unselectedFilters,
	handleFilter,
	handleCreateNew,
	contract,
	providers,
	excelLoading
}) => (
	<Filter>
		<Left>
			<h1>{title}</h1>
			<Search
				onChange={handleFilter.bind(this, "search")}
				placeholder="Search"
				searchText={searchText} />
			<div>
				<Switch
					name="filter"
					title="Status"
					unselected={unselectedFilters}
					onChange={handleFilter.bind(this, "filter")}
					states={getFilters()}
					currentState={filters.filter.filter}>
						<Switch
							whenParentState="premieres"
							name="premiere"
							unselected={unselectedFilters}
							onChange={handleFilter.bind(this, "filter")}
							states={getPremiere()}
							currentState={filters.filter.premiere} />
				</Switch>
				<Switch
					name="windowTypeClass"
					title="License type"
					unselected={unselectedFilters}
					onChange={handleFilter.bind(this, "filter")}
					states={getWindowTypeClass()}
					currentState={filters.filter.windowTypeClass} />
				<Switch
					name="providerId"
					title="Source"
					unselected={unselectedFilters}
					disabled={!!filters.contractId}
					onChange={handleFilter.bind(this, "filter")}
					states={appConfig.features.acqC70 ? getProviderStates(providers) : getProvider()}
					currentState={filters.filter.providerId} />
				<Switch
					name="hasDownloadRights"
					title="Download rights"
					unselected={unselectedFilters}
					disabled={false}
					onChange={handleFilter.bind(this, "filter")}
					states={getDownloadRightsStates()}
					currentState={filters.filter.hasDownloadRights} />
					{renderAdsAllowedSwitch(unselectedFilters, handleFilter, filters.filter.hasAdsAllowed)}
			</div>
		</Left>
		<Right>
			{ appConfig.features && !appConfig.features.acqLight && filters.contractId ?
				<Button
					type="add"
					title="Create new license"
					onClick={handleCreateNew} />
				: null
			}
			<Button
				type="file-excel"
				title="Ads allowed"
				hoverTitle="Download an excel list containing all programs with adsAllowed enabled (show ads)."
				style={{ marginLeft: "auto" }}
				onClick={() => handleExportAdsAllowedButtonClick()}
				disabled={excelLoading} 
			/>
		</Right>
	</Filter>
);

function getProviderStates(providers) {
	const states = [{
		key: "",
		text: "Any",
		description: "Any provider",
	}];

	states.push(...providers.map(p => ({
		key: p.id.toString(),
		text: p.name,
		description: `License contract comes from ${p.name}.`
	})));

	return states;
}

function renderAdsAllowedSwitch(unselectedFilters, handleFilter, hasAdsAllowed) {
	if (appConfig.features && appConfig.features.acqAdsAllowed) {
		return <Switch
					name="hasAdsAllowed"
					title="Ads allowed"
					unselected={unselectedFilters}
					disabled={false}
					onChange={handleFilter.bind(this, "filter")}
					states={getDownloadRightsStates()}
					currentState={hasAdsAllowed} 
				/>
	}
}

function handleExportAdsAllowedButtonClick() {
	Actions.ExportExcel();
}
import React from 'react'
import moment from 'moment'

import Comments from '../../comments'

import './comments.css'
import { getUser } from '../../../core/services/auth'

const CommentsWrapper = ({
	className,
	value,
	onChange,
}) => {
    const containerRef = React.useRef();
	const [expand, setExpand] = React.useState(false);

    const closeComments = React.useCallback(
		() => setExpand(false),
		[setExpand]
	);

    const closeIfClickOutside = React.useCallback(
        (e) => {
            const clickedNode = e.target;
            const isChildOfContainer = containerRef.current.contains(clickedNode);
            if (!isChildOfContainer) {
                closeComments();
            }
        },
        [closeComments, containerRef.current]
    );

	React.useEffect(
        () => {
            if (expand) {
                window.addEventListener("click", closeIfClickOutside);
            } else {
                window.removeEventListener("click", closeIfClickOutside);
            }

            return () => {
                window.removeEventListener("click", closeIfClickOutside);
            };
	    },
        [expand, closeIfClickOutside]
    );

    const postComment = React.useCallback(
        (newCommentText) => {
            const { username } = getUser();

            const newValue = [...value];
            newValue.push({
                comment: {
                    text: newCommentText,
                    modified: moment.utc().format(),
                    user: username,
                    index: newValue.length,
                    commentType: "regular",
                },
            });
            onChange(newValue);
        },
        [value, onChange]
    );

    const deleteComment = React.useCallback(
        (id) => {
            const newValue = [...value];
            const itemIndex = newValue.findIndex(c => c.comment.index === id);
            if (itemIndex >= 0) {
                newValue.splice(itemIndex, 1);
                onChange(newValue);
            }
        },
        [value, onChange]
    );

    

    const dismissWarningOrError = React.useCallback(
        (/*id*/) => {
            const dismissedComment = value[value.length - 1]?.comment;
            postComment(`[Dismissed the ${dismissedComment.commentType}: "${dismissedComment.text}"]`);
        },
        [postComment, value]
    );

    const comments = mapComments(value);

    const { status, hoverText } = getStatus(comments);

	return (
		<div
            ref={containerRef}
			className={`c6-cms-comments ${className} ${expand ? "active" : ""}`}
            onClick={(e) => {
                // If the clicked comments container is also the currently active comments container,
                // prevent the event from propagating to the window eventlistener that closes our container
                if (expand) {
                    e.stopPropagation();
                }
            }}
		>
            <a
                className={`c6-link icon-chat ${status}`}
                onClick={(e) => {
                    e.stopPropagation();
                    setExpand(!expand);
                }}
                title={hoverText}
            >
                {`${comments.length ? "(" + comments.length + ")" : ""}`.trim()}
            </a>
            {expand && (
                <Comments
                    commentsLoading={false}
                    comments={comments}
                    postComment={postComment}
                    deleteComment={deleteComment}
                    dismissWarningOrError={dismissWarningOrError}
                />
            )}
		</div>
	);
}

export default CommentsWrapper;

function mapComments(comments) {
    return comments?.map(c => ({
        createdBy: c.comment.commentType === "regular" ? c.comment.user : null,
        created: moment.utc(c.comment.modified).format(),
        text: c.comment.text,
        error: c.comment.commentType === "error",
        warning: c.comment.commentType === "warning",
        id: c.comment.index,
    })) ?? [];
}

function getStatus(comments) {
    let status = "", hoverText;
    if (comments[comments.length - 1]?.error) {
        status = "has-error";
        hoverText = "Has errors - click to see them.";
    } else if (comments[comments.length - 1]?.warning) {
        status = "has-warning";
        hoverText = "Has warnings - click to see them.";
    }

    return { status, hoverText };
}
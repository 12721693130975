import React from 'react'
import { Link } from 'react-router'
import { browserHistory } from 'browserHistory'

import {Filter, Left, Right} from '../../../components/filter'
import Button from '../../../components/ui/controls/button'

import appConfig from 'config'

export default ({
	title,
	searchText,
	filters,
	contractId,
	handleCreateNew,
	goBackInsteadOfLink,
}) => {
	// We need to go back instead of doing a normal navigation to allow for
	// react-router-scroll to restore the scroll position of the library list.
	function handleClick(e) {
		if(goBackInsteadOfLink) {
			e.preventDefault();
			browserHistory.goBack();
		}
	}

	const backUrl = contractId
		? `/acq/contracts/${contractId}/licenses`
		: "/acq/licenses" ;

	return (
		<Filter>
			<Left>
				<h1><Link className="c6-link" to={backUrl} onClick={handleClick}>Licenses</Link> / {title}</h1>
			</Left>
			<Right>
				{ appConfig.features && !appConfig.features.acqLight && contractId ?
					<Button
						type="add"
						title="Create new license"
						onClick={handleCreateNew} />
					: null
				}
			</Right>
		</Filter>
	)
};
import React from 'react'
import { Route, IndexRoute, IndexRedirect } from 'react-router'

import Module from '../app'

import CalendarApp from './calendar/app'
import ProgramsApp from './programs/app'

export default class PlannerModule {

	static routes() {
		return (
			<Route path="planner" component={Module} module="planner">
				<Route path="calendar" app="calendar">
					<IndexRedirect to="today" />
					<Route path=":date" component={CalendarApp} />
				</Route> 
				<Route path="programs" app="programs" component={ProgramsApp} />
			</Route>
		);
	}
}
import React from 'react'
import PropTypes from 'prop-types'

import ContentItem, { Content } from '../../../../components/list/contentItem'

import styles from '../../../../components/listItems/standard.css'

const ArenaItem = (props) => {
	const {
		itemData,
	} = props;

	const {
		id,
		name,
		city,
	} = itemData;

	return (
		<ContentItem data={props}>
			<Content>
				<h1>{name}</h1>
				<p>{city}</p>
			</Content>
		</ContentItem>
	);
};

export default ArenaItem;
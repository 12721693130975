import React from 'react'
import { findFirstScrollableParent } from '../utils'

import './durationPicker.css'

const DurationPicker = ({
	style,
	name,
	className,
	value,
	displayName,
	onChange,
}) => {
	const [isOpen, setIsOpen] = React.useState(false);
	const [isSaving, setIsSaving] = React.useState(false);
	const unit1El = React.useRef();
	const unit2El = React.useRef();
	const pickerWrapperRef = React.useRef();
	const containerRef = React.useRef();

	const openPicker = React.useCallback(
		(e) => {
			e.preventDefault();
			if (isOpen) {
				e.stopPropagation();
			}
			setIsOpen(true);
			makeSurePickerIsVisible(pickerWrapperRef);
		},
		[isOpen, setIsOpen]
	);

	const closePicker = React.useCallback(
		() => setIsOpen(false),
		[setIsOpen]
	);

	const closeIfClickOutside = React.useCallback(
        (e) => {
            const clickedNode = e.target;
            const isChildOfContainer = containerRef.current.contains(clickedNode);
            if (!isChildOfContainer) {
                closePicker();
            }
        },
        [closePicker, containerRef.current]
    );

	React.useEffect(
		() => {
			if (isOpen) {
				window.addEventListener("click", closeIfClickOutside);
			} else {
				window.removeEventListener("click", closeIfClickOutside);
			}

			return () => {
				window.removeEventListener("click", closeIfClickOutside);
			};
		},
		[isOpen, closeIfClickOutside]
	);

	const onConfirm = React.useCallback(
		async (e) => {
			e.preventDefault();
			const value = getValue(style?.format, unit1El.current?.value, unit2El.current?.value);
			setIsSaving(true);
			await onChange(value);
			setIsOpen(false);
			setIsSaving(false);
		},
		[setIsOpen, setIsSaving, onChange]
	);

	const [unit1Label, unit2Label] = getUnitLabels(style?.format);
	const [unit1Max, unit2Max] = getUnitMax(style?.format);
	const [unit1Value, unit2Value, formattedValue] = getUnitValues(style?.format, value);

	return (
		<div
			key={name}
			ref={containerRef}
			className={`${className} c6-cms-duration-picker time-picker time-compact`}
		>
			{displayName && <span>{displayName}</span>}
            <input type="hidden" className="flatpickr-input" /> {/* HACK for flatpickr styling :) */}
            <input type="text" readOnly value={formattedValue} onClick={openPicker} />
			{isOpen && (
				<div
					className="picker-wrapper c6-cms-datetimepicker"
					ref={pickerWrapperRef}
					onClick={e => e.stopPropagation()}
				>
					<div className="units">
						<input defaultValue={unit1Value} name={`${name}-unit1`} type="number" min={0} max={unit1Max} className="unit1" ref={unit1El} />
						<label htmlFor={`${name}-unit1`}>{unit1Label}</label>
						{unit2Label && (
							<>
								<input defaultValue={unit2Value} name={`${name}-unit2`} type="number" min={0} max={unit2Max} className="unit2" ref={unit2El} />
								<label htmlFor={`${name}-unit2`}>{unit2Label}</label>
							</>
						)}
					</div>
					<div className="confirm-container">
						<button type="button" className="confirm" onClick={onConfirm} disabled={isSaving}>OK</button>
						<button type="button" className="cancel" onClick={closePicker}>CANCEL</button>
					</div>
				</div>
			)}
		</div>
	);
}

export default DurationPicker;

// Get value in correct format to be sent to API
function getValue(format, value1, value2) {
	if (format === "days-hours") {
		const days = `${value1 || 0}`;
		const hours = `${value2 || 0}`.padStart(2, "0");
		return `${days}.${hours}:00:00`;
	}
	
	if (format === "days") {
		const days = `${value1 || 0}`;
		return `${days}.00:00:00`;
	}

	const _hours = Number(value1 || 0);
	const days = Math.floor(_hours / 24);
	const hours = `${_hours % 24}`.padStart(2, "0");
	const minutes = `${value2 || 0}`.padStart(2, "0");
	return `${days}.${hours}:${minutes}:00`;
}

function getUnitLabels(format) {
	if (format === "days-hours") {
		return ["days", "hours"];
	}

	if (format === "days") {
		return ["days", null];
	}

	return ["hours", "minutes"];
}

function getUnitMax(format) {
	if (format === "days-hours") {
		return [undefined, 23];
	}

	if (format === "days") {
		return [undefined, undefined];
	}

	return [undefined, 59];
}

function getUnitValues(format, value) {
	let [daysHours, _minutes, _seconds] = typeof value === "string" && value.includes(":")
		? value.split(":")
		: "0.00:00:00".split(":");
	
	let _days, _hours;
	if (daysHours.includes(".")) {
		[_days = 0, _hours = 0] = daysHours.split(".");
	} else {
		_days = 0;
		_hours = daysHours;
	}

	if (format === "days-hours") {
		_hours = _hours.startsWith("0") ? _hours.substring(1) : _hours;
		return [Number(_days ?? 0), Number(_hours ?? 0), `${_days}d ${_hours}h`];
	}
	
	if (format === "days") {
		return [Number(_days ?? 0), undefined, `${_days}d`];
	}

	_days = Number(_days);
	_hours = `${_days * 24 + Number(_hours)}`;
	_hours = _hours.startsWith("0") ? _hours.substring(1) : _hours;
	_minutes = _minutes.startsWith("0") ? _minutes.substring(1) : _minutes;
	return [Number(_hours ?? 0), Number(_minutes ?? 0), `${_hours}h ${_minutes}min`];
	
}

function makeSurePickerIsVisible(ref) {
	setTimeout(() => {
		if (!ref.current) {
			return;
		}
		
		const rect = ref.current.getBoundingClientRect();
		const scrollParent = findFirstScrollableParent(ref.current);
		const parentRect = scrollParent.getBoundingClientRect();
		if (rect.bottom >= parentRect.bottom - 50) {
			ref.current.style.top = "unset";
			ref.current.style.bottom = "100%";
		}
	});
}

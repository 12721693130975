import React from 'react'

import App from '../../../components/app'
import Dashboard from '../../../components/dashboard/dashboard'

function AdminDashboardApp() {

	const SHORTCUTS = [
		{
			displayName: "Modules",
			key: "shield.modules",
            url: "shield/admin/modules",
            urlText: "Set up available modules for this tenant.",
            links: [],
		},
		{
			displayName: "Services",
			key: "shield.services",
            url: "shield/admin/services",
            urlText: "Services are basically the API:s that the UI will consume.",
            links: [],
		},
	];

	return (
		<App name="c6-shield-admin" layout="grid">
			<Dashboard title="Shield administration" data={SHORTCUTS} />
		</App>
	);
}

export default AdminDashboardApp;
import React from 'react'

import Item from './item'
import itemActions from './listItemActions'

import * as VodAPI from '../../../../apis/vod'
import useList from '../../../../core/hooks/useList'
import GenericListApp from '../../../../components/genericApp/listApp'
import { decoratorUIActions } from '../../../../core/decorators/uiActions'

const MODULE = "vod";
const DATASTORE = "admin-services-classic";
const TEXT_HEADING = "Services";
const TEXT_BACK_BUTTON = "Administration";
const TEXT_EMPTY = "";//"No services? It's about time to create some then!";
const TEXT_CREATE = null;//"Create service";
const ICON_CREATE = null;//"add";
const PATHNAME_CREATE = null;//"vod/admin/services/create";

function ListApp(props) {

	const FILTER_DEFAULTS = {
		// searchText: "",
	};

	const filterConfig = {
		// searchText: {
		// 	type: "search",
		// 	alwaysVisible: true,
		// },
	};

	const listState = useList({
		listKey: DATASTORE,
		fetchData: VodAPI.fetchServices,
		filterDefaults: FILTER_DEFAULTS,
	});

	return (
		<GenericListApp
			{...props}
			module={MODULE}
			datastore={DATASTORE}
			state={listState}
			textHeading={TEXT_HEADING}
			textBackButton={TEXT_BACK_BUTTON}
			textEmpty={TEXT_EMPTY}
			textCreate={TEXT_CREATE}
			iconCreate={ICON_CREATE}
			filterConfig={filterConfig}
			pathnameCreate={PATHNAME_CREATE}
			collapseFiltersDefault={false}
		>
			<Item />
		</GenericListApp>
	);
}

@decoratorUIActions(itemActions)
export default class ServicesApp extends React.Component {
	render() {
		return (
			<ListApp
				{...this.props}
			/>
		);
	}
}
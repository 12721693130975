import React from 'react'
import moment from 'moment'
import merge from 'lodash/merge'
import { QueryClientProvider } from '@tanstack/react-query'

import Dialog from '@mui/material/Dialog'
import Button from '@mui/material/Button'

import * as CMSAPI from '../../../apis/cms'
import * as Alert from '../../../core/services/alert'
import { createPatch, getEntityWithUpdatedValue } from '../../../core/cms/utils'
import { DEFAULT_QUERY_CLIENT } from '../../../core/constants'

import StepOne from './step-1'
import StepTwo from './step-2'
import StepThree from './step-3'

import './create-event-dialog.css'

const SERIES_ENTITY = "serieslist";
const SEASON_ENTITY = "seasonslist";
const EVENT_WIZARD_ENTITY = "eventwizard";

class CreateEventDialog extends React.Component {
	state = {
		step: 1,
		payload: {},
		mode: "single",
		newSeriesGenre: null,
		league: null,
		leagueSeason: null,
		isLoading: false,
		eventTemplate: null,
	}

	componentDidMount() {
		CMSAPI.fetchCMSTemplate({ _entity: this.props.eventRowTemplate })
			.then(template => {
				this.setState({ eventTemplate: template });
			});
	}

	createEvents = async () => {
		const { payload, mode, newSeriesGenre } = this.state;
		const { title, start, duration, events } = payload;

		try {
			this.setState({ isLoading: true });

			const season = mode === "multiple"
				? await getOrCreateSeason(payload, newSeriesGenre, this.props.onSeriesCreated, this.props.eventType)
				: null;

			if (mode === "single") {
				const cmsEventPayload = generateEventPayload({ title, start, duration }, null, this.props.eventType);
				const newWizardEvent = await CMSAPI.createCMSEntityAndReturnContent({
					_entity: EVENT_WIZARD_ENTITY,
					payload: cmsEventPayload,
				});

				const newEvent = await CMSAPI.fetchCMSEntity({
					_entity: this.props.eventEntity,
					id: newWizardEvent.id,
				});
				Alert.displayAlert("success", `Event "${title}" created!`);
				this.props.onCreated([newEvent]);
				this.setState({ isLoading: false });
			} else {
				const eventsPayload = [];
				events.forEach(eventGroup => {
					eventGroup.forEach(event => {
						if (!event.deleted) {
							const cmsEventPayload = generateEventPayload(
								event,
								season,
								this.props.eventType,
								payload.catchupAvailabilityPeriod,
								payload.publishBeforeEventStart,
								payload.publishTimeOfDay,
							);
							eventsPayload.push(cmsEventPayload);
						}
					});
				});
				await CMSAPI.createCMSEntity({
					_entity: EVENT_WIZARD_ENTITY,
					payload: eventsPayload,
				});
				
				Alert.displayAlert(
					"success",
					`${eventsPayload?.length} event(s) created!`,
					null,
					{
						title: "Reload list to see them all.",
						onClick: () => this.props.onCreated(),
					}
				);
				this.setState({ isLoading: false });
			}
			this.props.onClose();
		} catch (e) {
			Alert.displayAlert("error", "Something went wrong when creating your event.");
			console.error("Error when creating event: ", e);
			this.setState({ isLoading: false });
		}
	}

	importEvents = async () => {
		const { mode, payload, newSeriesGenre, league, leagueSeason } = this.state;

		try {
			this.setState({ isLoading: true });
			const season = await getOrCreateSeason(payload, newSeriesGenre, this.props.onSeriesCreated, this.props.eventType);

			const newSubscription = {
				active: true,
				broadcastOffset: -(payload.broadcastOffset ?? 0),
			};
			if (mode === "score24") {
				newSubscription.sportSeriesId = league?.id;
				newSubscription.sportSeasonId = leagueSeason?.id;
			} else {
				// Backend will set eventType automatically
				newSubscription.metadataProgramGuid = payload.metadataSeasonGuid;
			}
			const updatedSeason = {
				...season,
				subscriptions: season.subscriptions?.length
					? [...season.subscriptions, newSubscription]
					: [newSubscription],
			};

			const patch = createPatch(season, updatedSeason);

			await CMSAPI.updateCMSEntity({ _entity: SEASON_ENTITY, id: season.id, patch });
			await CMSAPI.runAction({ _entity: SEASON_ENTITY, id: season.id, payload: newSubscription }, "import");
			Alert.displayAlert("success", `New subscription added!`);
			this.setState({ isLoading: false });

			this.props.onClose();
		} catch (e) {
			Alert.displayAlert("error", "Something went wrong when importing the events.");
			console.error("Error when importing events: ", e);
			this.setState({ isLoading: false });
		}
	}

	getActions = (options) => {
		return (
			<Actions
				onClose={this.props.onClose}
				step={this.state.step}
				mode={this.state.mode}
				onBackClick={() => {
					this.setState({ step: this.state.step - 1 });
				}}
				{...options}
				isLoading={this.state.isLoading}
			/>
		);
	}

	render() {
		return (
			<Dialog
				open={this.props.open ?? true}
				onClose={(e, reason) => {
					if (reason === "backdropClick") {
						return;
					}

					this.props.onClose();
				}}
				className={`c6-modal create-event-dialog ${this.state.isLoading ? "is-loading" : ""}`}
				fullWidth={true}
				maxWidth="lg"
				classes={{
					container: "c6-modal-content",
					paper: "c6-modal-body"
				}}
			>
				<QueryClientProvider client={DEFAULT_QUERY_CLIENT}>
					<h1>Create events</h1>

					<StepOne
						active={this.state.step === 1}
						onNextClick={({ mode, league, leagueSeason, newSeriesGenre, series, season, metadataSeasonGuid }) => {
							this.setState({
								step: this.state.step + 1,
								mode,
								newSeriesGenre,
								league,
								leagueSeason,
								payload: { series, season, metadataSeasonGuid },
							});
						}}
						getActions={this.getActions}
						seriesFilters={this.props.seriesFilters ?? {}}
						genreFilters={this.props.genreFilters ?? {}}
						importTypes={this.props.importTypes}
					/>

					<StepTwo
						active={this.state.step === 2}
						payload={this.state.payload}
						mode={this.state.mode}
						onNextClick={(payload) => {
							if (this.state.mode === "multiple") {
								this.setState({ step: this.state.step + 1, payload });
							} else if (["score24", "metadata"].includes(this.state.mode)) {
								this.setState({ payload }, () => this.importEvents());
							} else {
								this.setState({ payload }, () => this.createEvents());
							}
						}}
						getActions={this.getActions}
						eventTemplate={this.state.eventTemplate}
					/>
					
					{this.state.step === 3 && (
						<StepThree
							payload={this.state.payload}
							onNextClick={(payload) => {
								console.log("payload", payload);
								this.setState(
									{ payload },
									() => this.createEvents()
								);
							}}
							getActions={this.getActions}
							eventTemplate={this.state.eventTemplate}
						/>
					)}
				</QueryClientProvider>
			</Dialog>
		);
	}
}

export default CreateEventDialog;


function Actions({ mode, onNextClick, onBackClick, nextDisabled, step, onClose, isLoading }) {
	const saveInsteadOfNext = mode === "multiple" && step === 3
		|| mode !== "multiple" && step === 2;
	
	return (
		<div className="actions">
			<Button variant="standard" className="close" onClick={onClose}>Close</Button>
			<Button variant="standard" onClick={onBackClick} disabled={step === 1 || isLoading}>Back</Button>
			{!saveInsteadOfNext && <Button variant="standard" onClick={onNextClick} disabled={nextDisabled || isLoading}>Next</Button>}
			{saveInsteadOfNext && <Button variant="standard" color="primary" onClick={onNextClick} disabled={nextDisabled || isLoading}>Save</Button>}
		</div>
	);
}

function generateEventPayload(event, season, eventType, defaultCatchupAvailabilityPeriod, defaultPublishBeforeEventStart, defaultPublishTimeOfDay) {
	const { title, start, duration, catchupAvailabilityPeriod, publishBeforeEventStart, publishTimeOfDay, ...restEvent } = event;
	const customPayload = {
		"description": {
			name: title,
		},
		"tabbed-settings": {
			"settings": {
				"ottstart-start-end-settings": {
					"start-end": {
						start: start,
						duration: formatToTimespan(duration),
						ott: {
							end: moment(start).add(duration, "minutes").format(),
						},
					},
					ott: {
						start,
					},
				},
			},
		},
		season: season?.id ? { id: season?.id } : undefined,
		reviewed: false,
		metadataReviewed: false,
		eventType,
	};

	// Only set these values if they were changed from the season defaults
	if (catchupAvailabilityPeriod !== defaultCatchupAvailabilityPeriod) {
		customPayload.catchupAvailabilityPeriod = catchupAvailabilityPeriod;
	}
	if (publishBeforeEventStart !== defaultPublishBeforeEventStart) {
		customPayload.publishBeforeEventStart = publishBeforeEventStart;
	}
	if (publishTimeOfDay !== defaultPublishTimeOfDay) {
		customPayload.publishTimeOfDay = publishTimeOfDay;
	}

	const payload = merge({}, customPayload, restEvent);
	delete payload.deleted;
	delete payload.startOffset;
	return payload;
}

async function getOrCreateSeason(payload, newSeriesGenre, onSeriesCreated, eventType) {
	let series = payload.series;
	if (!series.id) {
		const newSeries = await CMSAPI.createCMSEntityAndReturnContent({
			_entity: SERIES_ENTITY,
			payload: {
				name: series.name,
				genre: newSeriesGenre?.id ? { id: newSeriesGenre.id } : undefined,
				eventType,
			},
		});
		series = {
			...series,
			...newSeries,
		};

		onSeriesCreated();
	}

	// Make sure series.hasActiveEvents is true so that the series will be visible in the series dropdown
	if (!series.hasActiveEvents) {
		const updatedSeries = {
			...series,
			hasActiveEvents: true,
		};
		const patch = createPatch(series, updatedSeries);
		series = await CMSAPI.updateCMSEntityAndReturnContent({
			_entity: SERIES_ENTITY,
			id: series.id,
			patch,
		});
	}

	let season = payload.season;
	if (!season.id) {
		const newSeason = await CMSAPI.createCMSEntityAndReturnContent({
			_entity: SEASON_ENTITY,
			payload: {
				name: season.name,
				series: {
					id: series.id,
				},
				catchupAvailabilityPeriod: payload.catchupAvailabilityPeriod,
				publishBeforeEventStart: payload.publishBeforeEventStart,
				publishTimeOfDay: payload.publishTimeOfDay,
			},
		});
		season = {
			...season,
			...newSeason,
		};
	}

	// Update any season defaults
	let updatedSeason = {
		...season,
		...payload.settings,
		catchupAvailabilityPeriod: payload.catchupAvailabilityPeriod,
		publishBeforeEventStart: payload.publishBeforeEventStart,
		publishTimeOfDay: payload.publishTimeOfDay,
	};
	updatedSeason = getEntityWithUpdatedValue(updatedSeason, "tabbed-settings.settings.series.duration", payload.duration);

	const patch = createPatch(season, updatedSeason);
	if (patch.length) {
		season = await CMSAPI.updateCMSEntityAndReturnContent({
			_entity: SEASON_ENTITY,
			id: season.id,
			patch,
		});
	}

	return season;
}

function formatToTimespan(durationMinutes) {
	const hours = `${Math.floor(durationMinutes / 60)}`.padStart(2, "0");
	const minutes = `${durationMinutes % 60}`.padStart(2, "0");
	return `${hours}:${minutes}:00`;
}
// function formatToTimespan(durationMinutes) {
// 	const days = Math.floor(durationMinutes / 1440);
// 	const hours = `${Math.floor((durationMinutes % 1440) / 60)}`;
// 	const minutes = `${durationMinutes % 60}`;
// 	return `${days.toString()}.${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:00`;
// }
import { CONTENT, INLINE, CLICK_OPT, CLICK_SHIFT } from '../../../../components/actions'
import Actions from '../../actions'

import { hasStarEditAccess } from '../../shared/utils'

export default {
	targetComponent: "Item",
	actions: [
		{
			type: CONTENT,
			title: "Display item",
			method: (data, sourceProps, location, params, itemProps, e, listState) => {
				const list = listState.item.album.assets;
				Actions.edit(data, sourceProps, location, params, itemProps, e, list);
			},
		},
		// {
		// 	// title: "Remove selected item",
		// 	type: INLINE,
		// 	icon: "cancel",
		// 	method: Actions.removeAssetFromAlbum,
		// 	hidden: (data, sourceProps) => !hasStarEditAccess() || sourceProps.params.id === "0",
		// },
		// {
		// 	// title: "Remove selected item",
		// 	type: INLINE,
		// 	dangerous: true,
		// 	confirm: true,
		// 	confirmationText: "Are you really sure you want to delete this asset from STAR?",
		// 	icon: "cancel",
		// 	method: data => { Actions.removeItem("asset", data, "mySpace"); },
		// 	hidden: (data, sourceProps) => sourceProps.params.id !== "0",
		// },

		{
			// title: "Select item",
			type: INLINE,
			icon: "star_border",
			method: Actions.select,
			hidden: (data, sourceProps, location, params, itemProps) => itemProps.selected,
		},
		{
			// title: "Unselect item",
			type: INLINE,
			icon: "star",
			method: Actions.unselect,
			hidden: (data, sourceProps, location, params, itemProps) => !itemProps.selected,
		},
		{
			type: CLICK_OPT,
			method: Actions.toggleSelect,
		},
		{
			type: CLICK_SHIFT,
			method: data => {
				const storeName = "searchItems";
				Actions.toggleMultipleSelect({ item: data, storeName });
			},
		},
	]
};
import React from 'react'

import App from '../../../components/app'
import { StandardInfo } from '../../../components/comet'
import Main from '../../../components/ui/main'
import Header from '../../../components/headers/header'
import Button from '../../../components/ui/controls/button'

import List from './list'

import Actions from '../actions'
import Store from '../store'

const MODULE = "shield"
const ENTITY =  "module"
const TEXT_HEADING = "Modules"
const TEXT_EMPTY = "No modules? It's about time to create some then!"

const TEXT_CREATE = "Add new module"
const ICON_CREATE = "add"

export default class ModulesApp extends React.Component {

	constructor(props) {
		super(props);

		this.onChange = this.onChange.bind(this);

		this.state = {
			...Store.getState(),
		};
	}

	componentDidMount() {
		Store.listen(this.onChange);
		Actions.fetchItems(`${ENTITY}s`);
	}

	componentWillUnmount() {
		Actions.unmount();
		Store.unlisten(this.onChange);
	}

	onChange(state) {
		this.setState(state);
	}

	handleCreateNew() {
		Actions.create({
			pathname: `/shield/admin/modules/create`,
			returnTo: this.props.location.pathname,
		});
	}

	render() {
	    return (
			<App
				name={`c6-${MODULE}-${ENTITY}s`}
				layout="grid"
				isLoading={this.state.isLoading}>

				<Header title={TEXT_HEADING}>
					<Button
						type={ICON_CREATE}
						title={TEXT_CREATE}
						onClick={this.handleCreateNew.bind(this)} />
				</Header>
				<Main>
					<List
						items={this.state[`${ENTITY}s`]}
						isLoading={this.state.isLoading}
						textEmpty={TEXT_EMPTY} />
				</Main>
				<StandardInfo location={this.props.location} />
			</App>
		);
	}
}
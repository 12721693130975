import React from 'react'
import moment from 'moment'

import ContentItem, { Content } from '../../../components/list/contentItem'

const AuditItem = (props) => {
    const {
        attribute,
        name,
        changedAt,
        occuredAt,
        changedBy,
        initiatedBy,
        value,
    } = props;

    return (
        <ContentItem data={props} hideActions={true}>
            <Content size="min" className="changed-at">
                <p>{moment.utc(changedAt ?? occuredAt).local().format("D MMM YYYY HH:mm:ss")}</p>
            </Content>
            <Content size="min" className="changed-by">
                <p>{changedBy ?? initiatedBy}</p>
            </Content>
            <Content size="min" className="attribute">
                {name && (
                    <p title={name}>
                        <span className="icon-gesture-double-tap large-icon"></span>
                        <span className="attribute-name">{name}</span>
                    </p>
                )}

                {!name && (
                    <p title={attribute}>
                        <span className="icon-create large-icon"></span>
                        <span className="attribute-name">{attribute}</span>
                    </p>
                )}
            </Content>
            <Content className="value">
                <p>{formatValue(value)}</p>
            </Content>
        </ContentItem>
    );
}

export default AuditItem;

function formatValue(value) {
    // if (typeof value === "string") {
    //     return `"${value}"`.replace(/^\"\"/, "\"").replace(/\"\"$/, "\"");
    // }

    return `${value ?? ""}`;
}
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import moment from 'moment'

import Button from '../../../components/ui/controls/button'
// import Preview from '../../../components/assets/preview'
import { DownloadRightsAttributes } from '../shared/utils'
import Actions from '../actions'
import { hasRole } from '../../../core/services/auth'
import Const from '../../../core/constants'

import appConfig from 'config'

import styles from '../../../components/listItems/standard.css'

export default class ContractContent extends PureComponent {

	static propTypes = {
		id: PropTypes.number,
		name: PropTypes.string,
		reference: PropTypes.string,
		distributor: PropTypes.object,

		className: PropTypes.string,
	}

	constructor(props) {
		super(props);

		this.state = {
			popoverOpen: false,
		};
	}

	openPopover = (e) => {
		e.stopPropagation();
		this.setState({
			popoverOpen: true,
		});
	}

	openDownloadRights = (e) => {
		e.stopPropagation();
		this.setState({ popoverOpen: false });
		Actions.edit({
			pathname: `/acq/contracts/${this.props.id}/downloadrights`,
			returnTo: this.props.location.pathname,
		});
	}

	render() {
		const {
			id,
			name,
			reference,
			distributor,
			created,
			provider,
			className = "",
			adsAllowed,
		} = this.props;

		const attributes = DownloadRightsAttributes.filter(a => this.props.hasOwnProperty(a.key));
		let downloadRightsText;
		let adsAllowedText;
		if (attributes.length > 0) {
			downloadRightsText = "Download rights available";
		}
		if (adsAllowed === true) {
			adsAllowedText = "Ads allowed";
		}

	    return (
			<div className={`flex flex-dyn-1 ${className}`}>
				<div className="c6-content">
					<h1>{name}{reference !== name ? ` (${reference})`: ""}<span style={{ color: "var(--text-light-color)" }}>&nbsp;-&nbsp;{provider.name}</span></h1>
					<p>{distributor.name}</p>
				</div>
				<div className="c6-content">
					<p>{created && `Created ${moment(created).format(Const.PERIOD_FORMAT)}`}</p>
				</div>
				<div className="c6-content">
					{appConfig.features && appConfig.features.acqDownloadRights && (
						<p style={{ textTransform: "uppercase", textAlign: "right" }}>{downloadRightsText}</p>
					)}
				</div>
				<div className="c6-content">
					{appConfig.features && appConfig.features.acqAdsAllowed && (
						<p style={{ textTransform: "uppercase", textAlign: "right" }}>{adsAllowedText}</p>
					)}
				</div>
				<div className="c6-content">
					{appConfig.features && appConfig.features.acqDownloadRights && hasRole("acq.downloadrightseditor") && (
						<React.Fragment>
							<Button
								buttonRef={ref => this.buttonRef = ref}
								onClick={this.openPopover}
								type="more_vert large-icon"
								noBackground
								style={{ float: "right" }}
							/>
							<Menu
								className="c6-actions-menu"
								anchorEl={this.buttonRef}
								open={this.state.popoverOpen}
								onClose={() => this.setState({ popoverOpen: false })}
							>
								<MenuItem onClick={this.openDownloadRights}>
									{MenuButtonText()}
								</MenuItem>
							</Menu>
						</React.Fragment>
					)}
				</div>
			</div>
		);
  	}
}
//<Preview programId={programId} />
// HELPERS

function MenuButtonText() {
	let downloadRightsEnabled = appConfig.features && appConfig.features.acqDownloadRights;
	let adsAllowedEnabled = appConfig.features && appConfig.features.acqAdsAllowed;

	if(adsAllowedEnabled && downloadRightsEnabled) return "Download & Ad rights";
	return "Download rights"
}

import React, { PureComponent } from 'react'
import { Link } from 'react-router'
import PropTypes from 'prop-types'
import moment from 'moment'
import MuiSwitch from '@mui/material/Switch'

import Period from '../../../components/ui/period'
import Label from '../../../components/ui/label'
import Pick from '../../../components/ui/controls/pick'
import Switch from '../../../components/ui/controls/switch'
import Button from '../../../components/ui/controls/button'

import { getActivation, getDeviceRights, DownloadRightsAttributes } from '../shared/utils'
import Actions from '../actions'
import { hasRole } from '../../../core/services/auth'

import '../../../components/listItems/standard.css'

import appConfig from 'config'

const AUTO_CONFIRM = true;

export default class LicenseWindows extends PureComponent {

	static propTypes = {
		parent: PropTypes.object,
		id: PropTypes.number,
		windows: PropTypes.array,
		contract: PropTypes.object,
		class: PropTypes.string,

		listFilters: PropTypes.object,
	}

	constructor(props) {
		super(props);

		this.renderWindows = this.renderWindows.bind(this);

		this.targetStore = props.parent && props.parent.id ? "licenseEpisodes" : null;

	}

	handleWindowToggle(window, licenseId, e, value) {
		Actions.setWindowStatus(window, licenseId, value, AUTO_CONFIRM, this.targetStore);
	}

	handleWindowRemove(id, licenseId) {
		// Actions.removeLicenseWindow(id, licenseId, AUTO_CONFIRM);
		Actions.removeLicenseWindow(id, licenseId, null, this.targetStore);
	}

	setDeviceRight(id, licenseId, window, e) {
		const deviceRightId = parseInt(e.target.value);

		if(deviceRightId) {
			let { rights } = window;
			const index = rights.findIndex(r => r.id === deviceRightId);
			if(e.target.checked) {
				if(index >= 0) {
					rights[index] = {
						...rights[index],
						status: "Active",
						confirmedStatus: "Active",
					}
				}
				else {
					rights.push({
						id: deviceRightId,
						type: "Device",
						status: "Active",
						confirmedStatus: "Active",
					});
				}
			}
			else {
				rights.splice(index, 1);
			}

			Actions.updateLicenseWindow(id, licenseId, {
				...window,
				// inputSource: "Auto", // TODO: Why force/change inputSource to Auto? API crashes when they're different
				rights,
			}, AUTO_CONFIRM, this.targetStore);
		}
	}

	render() {
		const {
			id,
			windows,
			contract,
			program,
			listFilters,
		} = this.props;

	    return <div className="c6-secondary flex flex-1-1-1 flex-wrap">{this.renderWindows(id, windows, contract, program, listFilters)}</div>;
	}

	renderWindows(licenseId, windows, contract, program, listFilters) {
		if (!windows.length) {
			return <div className="c6-content"><p>{program.type || "The"} license has no license windows. Please add them in {contract.provider.name}.</p></div>;
		}

		if (program.type === "Season") {
			return windows.map(w => {
				const contractDownloadRights = appConfig.features && appConfig.features.acqDownloadRights && getDownloadRightsInfo(contract, w);
				const contractAdsAllowed = appConfig.features && appConfig.features.acqAdsAllowed && getContractAdsAllowed(contract, w);
				const now = moment();
				const isNotActive = now.isBefore(moment(w.start)) || now.isAfter(moment(w.end));
				const className = `c6-content ${isNotActive ? "dimmed" : ""}`;
				return [
					<div key="a" className={className}>
						{/*<Link to={episodesLink} className="c6-link">Display episodes</Link>*/}
						<Period start={w.start} end={w.end} />
					</div>,
					<div key="b" className={className}>
						{contractDownloadRights && renderDownloadRights(w, licenseId, contractDownloadRights, this.targetStore)}
					</div>,
					<div key="c" className={className}>
						{contractAdsAllowed && renderAdsAllowed(w, licenseId, contractAdsAllowed, this.targetStore)}
					</div>
				];
			});
		}

		return windows.map(w => {
			const { id, type, start, end, confirmedStatus, rights } = w;
			const renderWindowStatus = getRenderWindowStatus(listFilters, start, end, program.type);

			if(renderWindowStatus === "norender") {
				return null;
			}

			const status = (confirmedStatus || "").toLowerCase();

			if(appConfig.features && !appConfig.features.acqLight) {
				return [
					<div key={`a-${id}`} className="c6-content" disabled={renderWindowStatus === "recent" ? "disabled" : ""}>
						<MuiSwitch
							checked={status === "active"}
							onChange={this.handleWindowToggle.bind(this, w, licenseId)}
						/>
						<Period start={start} end={end} />
						{renderWindowType(type, contract)} <Label status={status}>{confirmedStatus}</Label>
					</div>,
					<div key={`b-${id}`} className="c6-content" disabled={renderWindowStatus === "recent" ? "disabled" : ""}>
						<Pick
							title="Devices"
							onChange={this.setDeviceRight.bind(this, id, licenseId, w)}
							states={getDeviceRights()}
							currentState={rights.filter(r => r.type === "Device" && r.status === "Active")}
							currentStateKey="id" />
						{/* <Button
							title="Remove window"
							type="clear"
							dangerous={true}
							confirm="Really remove this license window?"
							noBackground={true}
							onClick={this.handleWindowRemove.bind(this, id, licenseId)}
						/> */}
					</div>
				];
			}
			else {
				const contractDownloadRights = appConfig.features && appConfig.features.acqDownloadRights && getDownloadRightsInfo(contract, w);
				const contractAdsAllowed = appConfig.features && appConfig.features.acqAdsAllowed && getContractAdsAllowed(contract, w);
				return [
					<div key={`a-${id}`} className="c6-content" disabled={renderWindowStatus === "recent" ? "disabled" : ""}>
						<Period start={start} end={end} />
						{renderWindowType(type, contract)} <Label status={status}>{confirmedStatus}</Label>
					</div>,
					<div key={`b-${id}`} className="c6-content">
						{contractDownloadRights && renderDownloadRights(w, licenseId, contractDownloadRights, this.targetStore)}
					</div>,
					<div key={`c-${id}`} className="c6-content">
						{contractAdsAllowed && renderAdsAllowed(w, licenseId, contractAdsAllowed, this.targetStore)}
					</div>
				];
			}
		});
	}
}

const toggleStyles = {
	display: "inline-table",
	width: "auto",
	marginRight: "",
	whiteSpace: "nowrap",
	top: "2px",
};

{/* <button
						className="fg-green icon-check"
						onClick={this.setStatus.bind(this, id)}>
						<span>Activate</span>
					</button>
					<button
						className="fg-red icon-close"
						onClick={this.setStatus.bind(this, id)}>
						<span>Inactivate</span>
					</button> */}

{/* <Switch
						name="activation"
						onChange={this.setStatus.bind(this, id)}
						states={getActivation()}
						currentState={confirmedStatus} /> */}

// HELPERS
function getRenderWindowStatus({ padding, filter}, start, end, type) {

	const now = moment().startOf("day");
	const startMoment = moment(start);
	const endMoment = moment(end);
	const paddedStartMoment = moment(startMoment).subtract(padding, "days");
	const paddedEndMoment = moment(endMoment).add(padding, "days");

	let renderWindow = true;
	switch(filter.filter) {
		case "active":
			renderWindow = now.isBetween(paddedStartMoment, paddedEndMoment, null, '[]');
			break;
		case "upcoming":
			renderWindow = now.isSameOrBefore(startMoment);
			break;
		case "premieres":
			const selectedMonth = moment(filter.premiere);
			renderWindow = selectedMonth.isSameOrBefore(startMoment);
			break;
	}

	if(!renderWindow && type !== "Episode") {
		return "norender";
	}

	return startMoment.isAfter(now) || endMoment.isBefore(now) ? "recent" : "render";
}

function renderWindowType({class: typeClass, name: typeName}, { provider }) {
	const type = ["svod", "avod"].includes(typeClass.toLowerCase()) ? typeClass.toUpperCase() : typeClass;
	const providerName = provider.name === "Acq" ? "Acquisitions" : provider.name;

	return ["Acquisitions","ComHem","MTV"].includes(providerName) ? type : `${type} (${typeName} in ${providerName})`;
}

function getCurrentDeviceRight(rights) {
	const dr = rights.filter(r => r.type === "Device" && r.status === "Active");

	return dr.length ? dr[0].id.toString() : null;
}

function renderDownloadRights(licenseWindow, licenseId, contractDownloadRights, targetStore) {
	const states = [
		{ key: "true", text: "YES", description: contractDownloadRights },
		{ key: "false", text: "NO", description: contractDownloadRights },
	];
	
	return (
		<Switch
			name={`downloadrights-${licenseWindow.id}`}
			title="Download rights"
			onChange={e => {
				Actions.updateLicenseWindow(licenseWindow.id, licenseId, {
					...licenseWindow,
					excludeDownloadRights: e.target.value != "true",
				}, AUTO_CONFIRM, targetStore);
			}}
			states={states}
			currentState={`${!licenseWindow.excludeDownloadRights}`}
			disabled={!hasRole("acq.downloadrightseditor")}
		/>
	);
}

function renderAdsAllowed(licenseWindow, licenseId, contractAdsAllowed, targetStore) {
	const states = [
		{ key: "true", text: "YES", description: contractAdsAllowed },
		{ key: "false", text: "NO", description: contractAdsAllowed },
	];
	
	return (
		<Switch
			name={`adsallowed-${licenseWindow.id}`}
			title="Ads Allowed"
			onChange={e => {
				Actions.updateLicenseWindow(licenseWindow.id, licenseId, {
					...licenseWindow,
					disableAdsAllowed: e.target.value != "true",
				}, AUTO_CONFIRM, targetStore);
			}}
			states={states}
			currentState={`${!licenseWindow.disableAdsAllowed}`}
			disabled={!hasRole("acq.downloadrightseditor")}
		/>
	);
}

function getDownloadRightsInfo(contract, licenseWindow) {
	const attributes = DownloadRightsAttributes.filter(a => contract.hasOwnProperty(a.key));
	if (attributes.length === 0 || !licenseWindow.type || licenseWindow.type.class !== "Svod") {
		return false;
	}

	const excludeText = licenseWindow.excludeDownloadRights ? "(This license window is set to ignore the rules below.)\n\n" : "";
	// \xa0 = nbsp
	const attributesText = attributes.map(({ key, text, unit = "" }) => `
		${text}:\xa0 
		${contract[key]}\xa0
		${unit}
		${unit && contract[key] != 1 ? "s" : ""}\xa0
		${unit === "hour" && contract[key] >= 24 ? hoursToDays(contract[key]) : ""}
	`.replace(/\n/gi, "")).join("\n");
	
	return `${excludeText}${attributesText}`.replace(/\t/gi, "");
}

function getContractAdsAllowed(contract, licenseWindow) {
	return contract.adsAllowed && licenseWindow.type?.class === "Svod";
}

function hoursToDays(hours) {
	return `(${(hours / 24).toString().substr(0, 4)} days)`;
}
import React from 'react'
import moment from 'moment'
import TextField from '@mui/material/TextField'

import DateTimePicker from '../../../components/ui/controls/pickers/datetimepicker'
import EventPlannerComponent from '../../../components/cms/component'
import CMSDurationPicker from '../../../components/cms/components/durationPicker'
import CMSDateTimePicker from '../../../components/cms/components/datetimepicker'
import Button from '../../../components/ui/controls/button'

import { getTemplateWithValues, getEntityWithUpdatedValue } from '../../../core/cms/utils'

const EventRow = ({
	groupIndex,
	eventIndex,
	event,
	series,
	season,
	onChange,
	template,
}) => {

	const nodes = getTemplateWithValues(template?.children ?? [], event);

	const onComponentChange = React.useCallback(
		({ data, path }) => {
			const updatedItem = getEntityWithUpdatedValue(event, path, data);
			onChange({ groupIndex, eventIndex, item: updatedItem });
		},
		[event, onChange]
	);

	return (
		<div className={`event ${event.deleted ? "deleted" : ""}`}>
			<div className="event-wrapper">
				<div className="event-number">#{eventIndex + 1}</div>
				<DateTimePicker
					onChange={(val) => onChange({ groupIndex, eventIndex, name: "start", val: moment(val).format() })}
					value={event.start}
					options={{ hideClear: true }}
					staticRender={false}
				/>
				<span className="weekday">{moment(event.start).format("dddd")}</span>
				<TextField
					variant="standard"
					className="c6-mui"
					name={"duration" + groupIndex}
					value={event.duration}
					onChange={(e) => onChange({ groupIndex, eventIndex, name: "duration", val: e.target.value })}
					style={{ width: "auto" }}
					type="number"
					min={0}
					max={1439}
				/>
				<span>min</span>
				<TextField
					autoComplete="off"
					variant="standard"
					className="c6-mui"
					name={"title" + groupIndex}
					value={event.title}
					onChange={(e) => onChange({ groupIndex, eventIndex, name: "title", val: e.target.value })}
					style={{ width: "auto" }}
					placeholder="Event title (optional)"
				/>
				<span>{series?.name} {season?.name}</span>

				<Button
					type={event.deleted ? "add_circle_outline" : "remove_circle_outline"}
					className="delete"
					onClick={() => onChange({ groupIndex, eventIndex, name: "deleted", val: !event.deleted })}
					noBackground
				/>
			</div>

			<div className="catchup-publish-settings">
				<div>
					<p style={{ margin: 0 }}><strong>Catchup availability period</strong></p>
					<div className="c6-eventplanner-events">
						<CMSDurationPicker
							onChange={val => onChange({ groupIndex, eventIndex, name: "catchupAvailabilityPeriod", val })}
							name="catchup-availability"
							displayName="Catchup"
							value={event.catchupAvailabilityPeriod}
							style={{ format: "days-hours" }}
							className="template-item"
						/>
					</div>
				</div>
				<div>
					<p style={{ margin: 0 }}><strong>When to publish (how long before event start)</strong></p>
					<div className="c6-eventplanner-events">
						<CMSDurationPicker
							onChange={val => onChange({ groupIndex, eventIndex, name: "publishBeforeEventStart", val })}
							name="publish-before-event-start"
							displayName="Number of days"
							value={event.publishBeforeEventStart}
							style={{ format: "days" }}
							className="template-item"
						/>
						<CMSDateTimePicker
							onChange={val => onChange({ groupIndex, eventIndex, name: "publishTimeOfDay", val })}
							name="publish-time-of-day"
							displayName="Time of day"
							value={event.publishTimeOfDay}
							style={{ format: "hours-minutes" }}
							dataType="timeSpan"
							className="template-item"
						/>
					</div>
				</div>
			</div>

			<div>
				{nodes?.map(node => (
					<EventPlannerComponent
						key={node.name}
						templateItem={node}
						onChange={onComponentChange}
						path={[]}
						itemData={event}
					/>
				))}
			</div>
		</div>
	);
};

export default EventRow;
import { CONTENT, MENU } from '../../../components/actions'

import Actions from '../actions'

const ENTITY = "module";

export default {
	targetComponent: "Content",
	actions: [
		{
			title: "Edit access",
			method: Actions.showModuleServices,
			type: CONTENT,
		},
		{
			title: "Edit",
			icon: "create",
			method: (data, sourceProps, location) => {
				Actions.edit({
					id: data.id,
					pathname: `/shield/admin/${ENTITY}s/${data.id}/edit`,
					returnTo: `${location.pathname}${location.search}`,
				});
			},
			type: MENU,
		},
		{
			title: "Add app/sub module",
			icon: "playlist_add",
			method: (data, sourceProps, location) => {
				Actions.edit({
					id: data.id,
					pathname: `/shield/admin/${ENTITY}s/create`,
					search: `?parentModuleId=${data.id}`,
					returnTo: `${location.pathname}${location.search}`,
				});
			},
			type: MENU,
			hidden: data => data.parentModuleId,
		},
		{
			title: "Enable",
			icon: "add_circle",
			method: data => { Actions.enableItem(ENTITY, data); },
			type: MENU,
			hidden: data => data.enabled,
		},
		{
			title: "Disable",
			confirm: true,
			icon: "remove_circle",
			method: data => { Actions.disableItem(ENTITY, data); },
			type: MENU,
			hidden: data => !data.enabled,
		},
		{
			title: "Delete",
			confirm: true,
			dangerous: true,
			icon: "delete",
			method: data => { Actions.removeItem(ENTITY, data); },
			type: MENU,
			hidden: data => data.enabled,
		},
	]
};
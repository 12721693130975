import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import Label from '../../../components/ui/label'
import InlineHeading from '../../../components/ui/inlineHeading'
import ContentItem, { Content } from '../../../components/list/contentItem'

import * as Alert from '../../../core/services/alert'

export default class LoginContent extends PureComponent {

	static propTypes = {
		enabled: PropTypes.bool,
		firstName: PropTypes.string,
		lastName: PropTypes.string,
		name: PropTypes.string,
		email: PropTypes.string,
		// phone: PropTypes.string,
		lastLoginTimestamp: PropTypes.string,
		className: PropTypes.string,
	}

	render() {
		const {
			enabled,
			firstName = "",
			lastName = "",
			name,
			email,
			// phone,
			lastLoginTimestamp,
			className = "",
			type,
			activeUntil,
		} = this.props;

		const loginType = type?.toLowerCase() ?? "user";

		const title = loginType === "apikey"
			? firstName || name
			: `${firstName} ${lastName}`;

		const lastLogin = lastLoginTimestamp
			? moment(lastLoginTimestamp).format("YYYY-MM-DD HH:mm")
			: "";

		return (
			<ContentItem data={this.props}>
				<Content>
					<h1>{title} {getLabel(enabled)}</h1>
				</Content>
				<Content>
					{loginType === "apikey" && (
						<InlineHeading title="Key">
							<span
								title="Click to copy to clipboard"
								onClick={(e) => {
									e.stopPropagation();
									copyToClipboard(name);
								}}
							>
								{name}
							</span>
						</InlineHeading>
					)}
					{loginType !== "apikey" && (
						<InlineHeading title="Login">{name}</InlineHeading>
					)}
				</Content>
				<Content>
					{loginType === "user" && <InlineHeading title="E-mail">{email}</InlineHeading>}
					{loginType === "apikey" && (
						<InlineHeading title="Active until">
							{activeUntil ? moment(activeUntil).format() : "Always"}
						</InlineHeading>
					)}
				</Content>
				{/* <div className="c6-content">
					{loginType === "user" && <InlineHeading title="Phone">{phone}</InlineHeading>}
				</div> */}
				<Content>
					{lastLogin && <InlineHeading title="Last login"><span title={lastLoginTimestamp}>{lastLogin}</span></InlineHeading>}
				</Content>
			</ContentItem>
		);
	}
}

//<a href={`mailto:${email}`}>{email}</a>

// HELPERS
function getLabel(enabled) {
	return !enabled
		? <Label status="expired">Disabled</Label>
		: null;
}


function copyToClipboard(text) {
	// Create a holding element
	const el = document.createElement("div");
	el.style.backgroundColor = "#fff";
	el.style.position = "absolute";

	const textEl = document.createElement("span");
	textEl.innerText = text;
	el.append(textEl);
	document.body.appendChild(el);

	// Clear current selection if there is one and copy our changes
	const selection = window.getSelection();
	selection.removeAllRanges();

	const range = document.createRange();
	range.selectNode(el);
	selection.addRange(range);

	document.execCommand("copy");

	// Clear selection again so its not visible for the user and clean up the holding element
	selection.removeAllRanges();
	el.remove();

	Alert.displayAlert("success", `Copied key to clipboard.`);
}
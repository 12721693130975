import React from 'react'
import { withRouter } from 'react-router'
import Moment from 'moment'
// import keydown from 'react-keydown'
import debounce from 'lodash/debounce'

import App from '../../../components/app'
import { Info } from '../../../components/comet'
import Main from '../../../components/ui/main'
import Button from '../../../components/ui/controls/button'

import Header from './header'
import List from './list'

import Actions from '../actions'
import Store from '../store'

import { getSearchPayload, getInfo } from '../shared/utils'
import appConfig from 'config'

const MODULE = "acq"
const ENTITY =  "contract"

const TEXT_HEADING = "Contracts"
const TEXT_EMPTY = "No contracts? Try adjusing your list filters, if possible."
const TEXT_CREATE = "Create contract"
const ICON_CREATE = "add"

@withRouter
export default class ListApp extends React.Component {

	constructor(props) {
		super(props);

		this.onChange = this.onChange.bind(this);
		this.onFilter = this.onFilter.bind(this);
		this.handleFilter = this.handleFilter.bind(this);
		this.debouncedFilter = debounce(this.handleFilter, 300);
		this.loadMore = this.loadMore.bind(this);

		this.scroll = null;

		this.state = {
			...Store.getState(),
			searchText: "",
			unselectedFilter: false,
		};
	}

	componentDidMount() {
		Store.listen(this.onChange);
		const payload = getSearchPayload(this.state);
		payload.orderBy = "created";
		Actions.fetchItems(ENTITY, payload);
		if (appConfig.features.acqC70 && !this.state.providers.length) {
			Actions.fetchItems("provider");
		}
	}

	// componentWillReceiveProps({ keydown }) {
	// 	if (keydown.event) {
	// 		console.log( keydown.event.which );
	// 		this.onSearch(keydown.event);
	// 	}
	// }

	componentWillUnmount() {
		Actions.unmount();
		Store.unlisten(this.onChange);
	}

	onChange(state) {
		this.setState(state);
	}

	onFilter(type, e) {
		if(type === "search") {
    		e.persist();
    		this.debouncedFilter(type, e);
		}
		else {
			this.handleFilter(type, e);
		}
  	}

	handleFilter(type, event) {
		if(this.scroll) {
			this.scroll.scrollTop = 0; // Scroll list to top to prevent automatic paging
		}

		const { name, value } = event.target;
		Actions[type](value, name);
		const payload = getSearchPayload(this.state);
		payload.orderBy = "created";
		Actions.fetchItems(ENTITY, payload);

		this.setState({
			searchText: type !== "search" ? "" : value,
			unselectedFilter: type !== "search" ? false : value.length > 0,
		});

		// Force lazyload to load visible images
		window.dispatchEvent(window.customLazyEvent);
	}

	loadMore() {
		const url = this.state[`${ENTITY}sNextPageUrl`];
		if(url) {
			Actions.pageItems(ENTITY, url);
		}
	}

	render() {
		const { isLoading, searchText: componentSearchText, filters, unselectedFilter, contracts, contractsNextPageUrl, providers } = this.state;
		const searchText = componentSearchText || filters.searchText; // TODO!: We should probably only have one searchText since this will make people go mad.

	    return (
			<App
				name={`c6-${MODULE}-${ENTITY}s c6-list`}
				layout="grid"
				isLoading={isLoading}>
				<Header
					title={TEXT_HEADING}
					searchText={searchText}
					filters={filters}
					unselectedFilters={unselectedFilter || !!searchText.length}
					handleFilter={this.onFilter}
					providers={providers}
				/>
				<Main inputRef={el => this.scroll = el}>
					<List
						items={contracts}
						isLoading={isLoading}
						searchText={searchText}
						filters={filters}
						textEmpty={TEXT_EMPTY}
						hasMore={!!contractsNextPageUrl}
						loadMore={this.loadMore}
						location={this.props.location}
					/>
				</Main>
				<Info>{getInfo(this.props.location, this.state, ENTITY)}</Info>
			</App>
		);
	}
}
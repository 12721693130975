import Moment from 'moment'
import cloneDeep from 'lodash/cloneDeep'
import alt from '../../core/services/alt'
// import EditorActions  from '../../components/editor/actions'
import EditorActions  from './licenseeditortemp/actions'

import Actions from './actions'
import appConfig from 'config'

export const START_FILTER = "active";

const filterStorageKey = "c6-acq-store-filter";
const prevFilterStorageKey = "c6-acq-store-filter-prev";

class AcqStore {

	constructor() {
		this.isLoading = false;
		this.isLoadingNumber = 0;
		this.excelLoading = false;

		this.item = {
			license: {},
			contract: {},
		};

		this.licenses = [];
		this.licensesCount = 0;
		this.licensesNextPageUrl = null;
		this.licensesLatestRequestTime = null;

		this.licenseEpisodes = [];
		this.licenseEpisodesCount = 0;
		this.licenseEpisodesNextPageUrl = null;
		this.licenseLatestRequestTime = null;

		this.contracts = [];
		this.contractsCount = 0;
		this.contractsNextPageUrl = null;
		this.contractsLatestRequestTime = null;

		this.providers = [];
		this.providersCount = 0;
		this.providersNextPageUrl = null;
		this.providersLatestRequestTime = null;

		const storedFilters = appConfig.features.saveListFiltersInStorage ? JSON.parse(sessionStorage.getItem(filterStorageKey)) : null;
		this.filters = storedFilters ?? {
			searchText: "",
			filter: {
				premiere: "",
				filter: START_FILTER,
				windowTypeClass: "",
				providerId: "",
				programType: "single|season",
				hasDownloadRights: "",
				hasAdsAllowed: "",
			},
			pageSize: 50,
			orderBy: getOrderBy(START_FILTER),
			padding: 14, // Pad start and end dates with 2 weeks to show recently expired or upcoming events
			contractId: "",
		};
		const storedPrevFilter = appConfig.features.saveListFiltersInStorage ? JSON.parse(sessionStorage.getItem(prevFilterStorageKey)) : null;
		this._prevFilter = storedPrevFilter ?? null;

		this.bindListeners({
      		onRequestFailed: Actions.REQUEST_FAILED,

			onFilter: Actions.FILTER,
			onSearch: Actions.SEARCH,

			onFetchItems: Actions.FETCH_ITEMS,
			onPageItems: Actions.PAGE_ITEMS,
			onItemsUpdated: Actions.ITEMS_UPDATED,
			onClearItem: Actions.CLEAR_ITEM,
			onFetchItem: Actions.FETCH_ITEM,
			onItemLoaded: Actions.ITEM_LOADED,
			// onItemSaved: [Actions.ITEM_UPDATED,EditorActions.MODEL_SAVED],
			onItemSaved: Actions.ITEM_UPDATED,
			onEditorSaved: EditorActions.MODEL_SAVED,

			onExportExcel: Actions.EXPORT_EXCEL,
			onExportExcelFinish: Actions.EXCEL_FINISH,

			onRemoveItem: Actions.REMOVE_ITEM,
			onItemRemoved: Actions.ITEM_REMOVED,
			onRollbackRemoveItem: Actions.ROLLBACK_REMOVE_ITEM,

			onUpdateContractId: Actions.UPDATE_CONTRACT_ID,

			onUnmount: Actions.UNMOUNT,
		});
	}

	onRequestFailed() {
		this.isLoading = false;
		this.isLoadingNumber = 0;
	}

	/* Filters */
	onSearch(searchText) {
		if (searchText === '') {
			this.filters.filter = this._prevFilter;
			this.filters.orderBy = getOrderBy(this.filters.filter.filter);
		} else {
			if (!this._prevFilter) {
				this._prevFilter = cloneDeep(this.filters.filter);
			}
			this.filters.filter = {
				programType: "single|season",
			};
			this.filters.orderBy = 'title';
		}
		this.filters.searchText = searchText;
		this.saveFiltersToStorage();
	}

	onFilter(payload) {
		const filterParams = Object.keys(this.filters.filter).length
			? this.filters.filter
			: this._prevFilter;

		this.filters.filter = {
			...filterParams,
			...payload,
		};

		// Set correct sorting
		this.filters.orderBy = getOrderBy(this.filters.filter.filter);

		// No padding when we're displaying upcoming licenses
		this.filters.padding = this.filters.filter.filter === "upcoming" || this.filters.filter.filter === "expired" ? 0 : 14;

		// No premiere date when we're not displaying premieres
		this.filters.filter.premiere = this.filters.filter.filter !== "premieres" ? "" : this.filters.filter.premiere || Moment().startOf("month").format("YYYY-MM-DD");

		this.filters.searchText = "";
		this._prevFilter = null;
		this.saveFiltersToStorage();
	}


	/* Items */
	onFetchItems({ entity, store, payload, requestTime }) {
		this.isLoading = true;
		this[`${store}Count`] = 0;
		this[`${store}NextPageUrl`] = null;
		this[`${store}LatestRequestTime`] = requestTime;
		this[`${store}IsLoading`] = true;
		this[store] = [];
	}

	onPageItems({url, store}) {
		this.isLoading = true;
		this[`${store}NextPageUrl`] = null;
	}

	onItemsUpdated({ datastore, items, appendToExistingItems, numberOfItems, nextPageUrl, requestTime }) {
		if (this[`${datastore}LatestRequestTime`] > requestTime) {
			console.log("[%s] Ignoring result with %s items since there have been newer requests.", datastore, numberOfItems);
			return;
		}

		// Remove provider id 0 (Undefined) and provider "Comet"
		if (datastore === "providers") {
			items = items.filter(i => i.id !== 0 && i.name !== "Comet");
		}
		this[datastore] = appendToExistingItems ? this[datastore].concat(items) : items;
		this[`${datastore}NextPageUrl`] = nextPageUrl;
		this[`${datastore}Count`] = numberOfItems;
		this[`${datastore}IsLoading`] = false;
		this.isLoading = false;
	}

	/* Item */
	onClearItem(entity) {
		this.item[entity] = {};
	}

	onFetchItem() {
		this.isLoading = true;
	}

	onItemLoaded({entity, model}) {
		this.item[entity] = model;
		this.isLoading = false;
	}

	// TODO: Remove datastore, we're using entities
	// TODO!: Keep item index when rollbacking
	onItemSaved({ entity, model, originalId, targetStore = null }) {
		const store = targetStore || `${entity}s`;
		const hasUpdatedStore = this.persistToStore(store, model);

		// We might have several editor stores listening for the same Editor Component
		// action so we need to bail out if this regards another entity.
		if(!hasUpdatedStore) {
			return false;
		}

		this.isLoading = false;
	}

	onEditorSaved({ entity, apiModel: model, originalId, targetStore = null }) {
		// HACK: Since the model from the editor contains all Rights, and the list model filters the rights
		// from the API we also need to apply filtering to the model we're about to insert in the list.

		// Filter rights from the model (if we have any)
		const { rights, ...rest } = model;

		if(!(rights && rights.length)) {
			this.onItemSaved({ entity, model, originalId, targetStore }); // No rights => we don't need to filter them
		}
		else {
			const { premiere, right } = this.filters.filter;

			// Get the date we should compare with, depending on the premiere filter
			const baseTime = premiere !== "upcoming"
				? new Date(premiere).getTime()
				: new Date().getTime() ;

			const newModel = {
				...rest,
				rights: rights.filter(r => (
					new Date(r.start).getTime() >= baseTime && // Right date filtering
					(!right || r.rightTypeClass.toLowerCase() === right || r.rightTypeClass === `S${right}`)) // Right class filtering (HACK: vod => Svod)
				),
			}

			this.onItemSaved({ entity, model: newModel, originalId, targetStore });
		}
	}

	onRemoveItem({ entity, id, targetStore }) {
		this.isLoading = true;

		const item = this.getItem(entity, id, targetStore);
		item._isHidden = true;
	}
	onItemRemoved({ entity, id, targetStore }) {
		this.isLoading = false;

		this.removeItem(entity, id, targetStore);
	}
	onRollbackRemoveItem({ entity, id, targetStore }) {
		this.isLoading = false;

		const item = this.getItem(entity, id, targetStore);
		delete item._isHidden;
		// item._isHidden = false;
	}

	onUnmount() {
		this.item = {
			license: {},
			contract: {},
		};

		// this.licenses = [];

		this.licenseEpisodes = [];
		this.licenseEpisodesCount = 0;
		this.licenseEpisodesNextPageUrl = null;

		this.contracts = [];
		this.contractsCount = 0;
		this.contractsNextPageUrl = null;


		this.isLoading = false;
	}

	// CUSTOM
	onUpdateContractId(id) {
		this.filters.contractId = id;
	}


	// HELPERS
	getItem(entity, id, targetStore) {
		const store = this[targetStore || `${entity}s`];
		return store.find(e => e.id === id);
	}

	removeItem(entity, id, targetStore) {
		const store = this[targetStore || `${entity}s`];
		const index = store.findIndex(e => e.id === id);
		store.splice(index, 1);
	}

	persistToStore(store, model) {
		if(!this[store]) {
			return false;
		}

		const index = this[store].findIndex(m => (m.id === model.id));

		if(index >= 0) {
			this[store][index] = model;
		} else {
			this[store].push(model);
		}

		return true;
	}

	updateStore(store, model) {
		if(!this[store]) {
			return false;
		}

		const index = this[store].findIndex(m => (m.id === model.id));

		if(index < 0) {
			return false;
		}

		this[store][index] = model;
		return true;
	}

	onExportExcel(){
		this.excelLoading = true;
	}
	
	onExportExcelFinish(){
		this.excelLoading = false
	}

	saveFiltersToStorage() {
		sessionStorage.setItem(filterStorageKey, JSON.stringify(this.filters));
		sessionStorage.setItem(prevFilterStorageKey, JSON.stringify(this._prevFilter));
	}
}
export default alt.createStore(AcqStore, '[Acq]Store');

// HELPLERS
function getOrderBy(filter) {
	switch(filter) {
		case "":
		case "active":
			return "activewindowslatestfirst";
		case "upcoming":
			return "upcomingwindowsearlieststartfirst";
		case "premieres":
			return "premierewindowsearlieststartfirst";
		case "expired":
			return "expiredwindowslatestendfirst";
	}
}
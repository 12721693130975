import appConfig from 'config'

export default {
	"ui:classNames": "c6-selections-listeditor",
	"name": {
		"ui:classNames": "grid-50",
		"ui:widget": "text"
	},
	"reference": {
		"ui:classNames": "grid-50",
		"ui:widget": "text",
		"ui:readonly": true
	},
	"description": {
		"ui:classNames": "grid-100 separator-bottom",
		"ui:widget": "text"
	},
	"contentLayout": {
		"ui:classNames": "grid-50",
		"ui:widget": appConfig.features.selectionsUseTemplate ? "select" : "text"
	},
	"version": {
		"ui:classNames": "grid-50",
		"id": {
			"ui:classNames": "grid-100",
			"ui:widget": "select"
		}
	},
	"audience": {
		"ui:classNames": "grid-50",
		"ui:widget": "select",
		"ui:readonly": true
	},
	"platforms": {
		"ui:classNames": "grid-50",
		"web": {
			"ui:widget": "checkbox"
		},
		"mobile": {
			"ui:widget": "checkbox"
		},
		"smarttv": {
			"ui:widget": "checkbox"
		},
		"appletv": {
			"ui:widget": "checkbox"
		}
	},
	"content": {
		"ui:classNames": "grid-100 padding-bottom",
		"ui:widget": "textarea"
	}
}
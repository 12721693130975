import React from 'react'
import moment from 'moment'
import FullCalendar from '@fullcalendar/react'
import ResourceTimelinePlugin from '@fullcalendar/resource-timeline'
import InteractionPlugin, { Draggable } from '@fullcalendar/interaction'

import './calendar.css'
import '../../../components/calendar/calendar.css'
import '../../../components/calendar/eventColors.css'

import { getEntityWithUpdatedValue } from '../../../core/cms/utils'

const __UNPLANNED_OTT_EVENTS__ = "__UNPLANNED_OTT_EVENTS__";
// const __UNPLANNED_LINEAR_EVENTS__ = "__UNPLANNED_LINEAR_EVENTS__";

export default class Calendar extends React.Component {

	calendarRef = null

	state = {}
	eventPlaceholders = []

	// shouldComponentUpdate(nextProps) {
	// 	const shouldUpdate = nextProps.date?.unix() !== this.props.date?.unix()
	// 		|| nextProps.disabled !== this.props.disabled
	// 		|| nextProps.readonly !== this.props.readonly
	// 		|| nextProps.eventsTimestamp !== this.props.eventsTimestamp
	// 	return shouldUpdate;
	// }

	componentDidMount() {
		if (this.calendarRef) {
			this.calendarRef.getApi().gotoDate(this.props.date);
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.date !== prevProps.date && this.calendarRef) {
			this.calendarRef.getApi().gotoDate(this.props.date);
		}
	}

	onEventClick = ({ event, jsEvent }) => {
		
	}

	onEventDrop = async ({ event, oldResource }) => {
		console.log("onEventDrop", event);

		const resource = event.getResources()[0];
		const parentResource = resource.extendedProps.parent;
		const id = event.id;
		const originalEvent = this.props.events.find(e => e.id === id);

		let updatedItem = originalEvent,
			didChange = false;

		// Check if start changed
		if (!moment(event.start).isSame(originalEvent["ottstart-start-end-settings-rightmenu"].ott.start)) {
			updatedItem = getEntityWithUpdatedValue(
				updatedItem,
				"ottstart-start-end-settings-rightmenu.ott.start",
				moment(event.start).format(),
			);
			didChange = true;
		}

		// Check if end changed
		if (!moment(event.end).isSame(originalEvent["ottstart-start-end-settings-rightmenu"]["start-end"].ott.end)) {
			updatedItem = getEntityWithUpdatedValue(
				updatedItem,
				"ottstart-start-end-settings-rightmenu.start-end.ott.end",
				moment(event.end).format(),
			);
			didChange = true;
		}

		// Check if TV4 OTT changed
		const tv4OttEncoderId = originalEvent["ottstart-start-end-settings-rightmenu"].settings.ottTv4.encoder?.id ?? __UNPLANNED_OTT_EVENTS__;
		if (parentResource === "OTT" && event.extendedProps.tv4 && resource.id !== tv4OttEncoderId) {
			updatedItem = getEntityWithUpdatedValue(
				updatedItem,
				"ottstart-start-end-settings-rightmenu.settings.ottTv4.encoder",
				{ id: resource.id === __UNPLANNED_OTT_EVENTS__ ? null : resource.id },
			);
			didChange = true;
		}

		// Check if linear channel(s) changed
		// const linearChannels = originalEvent["ottstart-start-end-settings-rightmenu"].settings.linear.channels;
		// if (parentResource === "Linear" && !linearChannels.some(c => c.id === resource.id)) {
		// 	let newLinearChannels = [...linearChannels];
		// 	// If dragging to "Unplanned", remove the old resource
		// 	if (resource.id === __UNPLANNED_LINEAR_EVENTS__) {
		// 		newLinearChannels = newLinearChannels.filter(c => c.id !== oldResource.id);
		// 	} else {
		// 		newLinearChannels.push({ id: resource.id });
		// 	}

		// 	updatedItem = getEntityWithUpdatedValue(
		// 		updatedItem,
		// 		"ottstart-start-end-settings-rightmenu.settings.linear.channels",
		// 		newLinearChannels,
		// 	);
		// 	if (newLinearChannels?.length > 0) {
		// 		updatedItem = getEntityWithUpdatedValue(
		// 			updatedItem,
		// 			"ottstart-start-end-settings-rightmenu.settings.linear.selected",
		// 			true,	
		// 		);
		// 	}
		// 	didChange = true;
		// }
		
		if (didChange) {
			await this.props.onChange({
				id,
				updatedItem,
				originalItem: originalEvent,
			});
		}
	}

	onEventResize = async ({ event }) => {
		await this.onEventDrop({ event });
	}

	eventAllow = ({ resource }, event) => {
		// console.log("eventAllow");
		// console.log("resource.title", resource.title);
		// console.log("event", event);

		return true;
	}

	onEventRemove = async (event) => {
		const resource = event.getResources()[0];
		const parentResource = resource.extendedProps.parent;
		const id = event.id;
		const originalEvent = this.props.events.find(e => e.id === id);

		if (parentResource === "OTT" && event.extendedProps.tv4) {
			await this.props.onChange({
				id,
				path: "ottstart-start-end-settings-rightmenu.settings.ottTv4.encoder",
				data: null,
				originalItem: originalEvent,
			});
		}

		// if (parentResource === "Linear") {
		// 	const linearChannels = originalEvent["ottstart-start-end-settings-rightmenu"].settings.linear.channels;
		// 	const newList = linearChannels.filter(c => c.id !== resource.id);
		// 	await this.props.onChange({
		// 		id,
		// 		path: "ottstart-start-end-settings-rightmenu.settings.linear.channels",
		// 		data: newList,
		// 		originalItem: originalEvent,
		// 	});
		// }
	}

	onEventMouseEnter = ({ event, el, jsEvent, view }) => {
		removeTempElements();

		// Add tooltip showing time and title
		const tooltip = document.createElement("div");
		tooltip.className = "tooltip";
		const eventTitle = event.extendedProps.description.name;
		const eventSeries = event.extendedProps.description.series;
		tooltip.innerHTML = `
			<p class="time">${moment(event.start).format("HH:mm")} - ${moment(event.end).format("HH:mm")}</p>
			<p class="title">${eventSeries}${eventSeries?.length > 0 && eventTitle?.length > 0 ? ": " : ""}${eventTitle}</p>
		`;

		const tbody = document.querySelector(".c6-eventplanner-resources .fc-scrollgrid > tbody");
		tbody.appendChild(tooltip);
		// setTimeout(() => {
		// 	if (isTooltipOutsideCalendar(tooltip)) {
		// 		tooltip.style.right = 0;
		// 		tooltip.style.left = "unset";
		// 	}
		// });

		const resource = event.getResources()[0];
		if (resource.extendedProps?.allowDelete) {
			// Add "X"-button
			const remove = document.createElement("button");
			remove.className = "remove icon-close";
			remove.title = "Remove";
			remove.onclick = (e) => {
				e.stopPropagation();
				this.onEventRemove(event);
			};

			el.appendChild(remove);
		}
	}

	onEventMouseLeave = (/*{ event, el, jsEvent, view }*/) => {
		removeTempElements();
	}

	eventDragStart = ({ event, jsEvent }) => {
		const resource = event.getResources()[0];
		if (resource.extendedProps.copyInsteadOfMove) {
			const eventCopy = {
				title: event.title,
				start: event.start,
				end: event.end,
				resourceId: resource.id,
				className: event.className,
				id: `event-copy-${Date.now()}}`,
				isPlaceholder: true,
			};
			const addedEvent = this.calendarRef.getApi().addEvent(eventCopy);
			this.eventPlaceholders = [...this.eventPlaceholders, addedEvent ];
		}
	}

	eventDragStop = ({ event, jsEvent }) => {
		this.eventPlaceholders.forEach(e => e.remove());
		this.eventPlaceholders = [];
	}

	eventOverlap = (stillEvent, movingEvent) => {
		const targetResource = stillEvent.getResources()[0];
		const excludeResourcesFromOverlapCheck = [__UNPLANNED_OTT_EVENTS__/*, __UNPLANNED_LINEAR_EVENTS__*/];
		if (excludeResourcesFromOverlapCheck.includes(targetResource?.id)) {
			return true;
		}

		const allowedDiff = 30;
		const diff1 = Math.abs(moment(stillEvent.end).diff(movingEvent.start, "minutes"));
		const diff2 = Math.abs(moment(stillEvent.start).diff(movingEvent.end, "minutes"));
		if (diff1 > allowedDiff && diff2 > allowedDiff) {
			return false;
		}
		return true;
	}

	render() {
		// const view = this.calendarRef?.getApi().view.type;

		return (
			<div className={`c6-calendar c6-calendar-resources ${this.props.disabled ? "disabled" : ""}`}>
				<FullCalendar
					{...this.props.fullCalendarProps}
					
					ref={el => this.calendarRef = el}
					initialView="resourceTimelineDay"
					views={{ // Configure views. Settings in here override the global settings
						resourceTimelineDay: {
							weekNumbers: false,
							dayHeaderFormat: { day: "numeric", weekday: false },
							resourceAreaHeaderContent: "Resources",
							// eventResourceEditable: false, // disable DnD between resources/licenses
							resourceAreaWidth: "20%",
							nowIndicator: true,
							snapDuration: "00:05:00",
							displayEventTime: true,
							displayEventEnd: true,
							slotLabelDidMount: (slotLabel) => slotDidMount(slotLabel, this.props.date),
							slotLaneDidMount: (slotLabel) => slotDidMount(slotLabel, this.props.date),
							eventTimeFormat: {
								omitZeroMinute: false,
								meridiem: false,
								hour: "2-digit",
								minute: "2-digit",
							},
							slotMinTime: "00:00:00",
							slotMaxTime: "36:00:00", // slotMaxTime is an exclusive end time
						},
					}}
					height="100%" // The height of the calendar will match the height of its parent container element
					locale="en-GB" // Workaround for bug in FullCalendar where 00:00 is displayed as 24:00 https://github.com/fullcalendar/fullcalendar/issues/5303
					plugins={[ResourceTimelinePlugin, InteractionPlugin ]}
					headerToolbar={null}
					eventSources={[
						{ events: transformEvents(this.props.events) },
					]}
					resources={getResources(this.props.ottResources/*, this.props.linearResources*/)}
					resourceOrder={getResourceOrder()}
					eventOrder={getEventOrder()}
					eventDisplay="block" // block = display as a solid rectangle in daygrid views
					dateClick={this.onDateClick}
					eventClick={this.onEventClick}

					editable={!this.props.disabled && !this.props.readonly}
					eventResourceEditable={true}

					droppable={!this.props.readonly}
					eventResize={this.onEventResize}
					eventDrop={this.onEventDrop}
					eventAllow={this.eventAllow}
					eventDragStart={this.eventDragStart}
					eventDragStop={this.eventDragStop}
					eventOverlap={this.eventOverlap}
					eventMouseEnter={this.props.readonly ? null : this.onEventMouseEnter}
					eventMouseLeave={this.props.readonly ? null : this.onEventMouseLeave}
					// schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives" // Scheduler can be downloaded and evaluated for an unlimited amount of time, free of charge. This evaluation version is licensed under a Creative Commons license that does not allow distribution of source code modifications nor use in commercial production websites or products.
					schedulerLicenseKey="0181342086-fcs-1636364664"
					resourceLabelClassNames={getResourceClassName}
					resourceLaneClassNames={getResourceClassName}

					filterResourcesWithEvents={this.props.hideEmptyResources}
				/>
			</div>
		);
	}
}

function transformEvents(events) {
	const res = []
	events.forEach(event => {
		const series = event.description.series;
		const title = event.description.name?.length ? event.description.name : series;
		const start = event["ottstart-start-end-settings-rightmenu"].ott.start;
		const end = event["ottstart-start-end-settings-rightmenu"]["start-end"].ott.end;

		const ottTv4Enabled = event["ottstart-start-end-settings-rightmenu"].settings.ottTv4?.selected;
		const ottTv4EncoderId = ottTv4Enabled && event["ottstart-start-end-settings-rightmenu"].settings.ottTv4?.encoder?.id;
		if (ottTv4Enabled) {
			const className = ottTv4EncoderId ? "allow-delete tv4" : "tv4";
			res.push({
				...event,
				id: event.id,
				title,
				start,
				end,
				resourceId: ottTv4EncoderId ?? __UNPLANNED_OTT_EVENTS__,
				className,
				startEditable: false,
				durationEditable: false,
				tv4: true,
			});
		}

		const ottMTVEnabled = event["ottstart-start-end-settings-rightmenu"].settings.ottMtv?.selected;
		const ottMTVEncoderId = ottMTVEnabled && event["ottstart-start-end-settings-rightmenu"].settings.ottMtv?.encoder?.id;
		if (ottMTVEnabled) {
			const className = ottMTVEncoderId ? "allow-delete mtv" : "mtv";
			res.push({
				...event,
				id: event.id,
				title,
				start,
				end,
				resourceId: ottMTVEncoderId ?? __UNPLANNED_OTT_EVENTS__,
				className,
				startEditable: false,
				durationEditable: false,
				cmore: true,
			});
		}
		
		// const linearEnabled = event["ottstart-start-end-settings-rightmenu"].settings.linear.selected;
		// const linearChannels = event["ottstart-start-end-settings-rightmenu"].settings.linear.channels;
		// if (linearEnabled && linearChannels.length) {
		// 	const className = "allow-delete";
		// 	linearChannels.forEach(channel => {
		// 		res.push({
		// 			...event,
		// 			id: event.id,
		// 			title,
		// 			start,
		// 			end,
		// 			resourceId: channel.id,
		// 			className,
		// 			startEditable: false,
		// 			durationEditable: false,
		// 		});
		// 	});
		// } else if (linearEnabled && !linearChannels.length) {
		// 	res.push({
		// 		...event,
		// 		id: event.id,
		// 		title,
		// 		start,
		// 		end,
		// 		resourceId: __UNPLANNED_LINEAR_EVENTS__,
		// 		startEditable: false,
		// 		durationEditable: false,
		// 	});
		// }
	});
	return res;
}

function getResources(ottResources/*, linearResources*/) {
    return [
		// {
		// 	id: "OTT",
		// 	title: "OTT",
		// 	children: [
				{
					id: __UNPLANNED_OTT_EVENTS__,
					title: "Unplanned",
					parent: "OTT",
					allowDelete: false,
					startEditable: false,
				},
				...ottResources.map(r => ({
					... r,
					title: r.name,
					parent: "OTT",
					allowDelete: true,
					// startEditable: false,
					// durationEditable: false,
				})),
			// ],
		// },
		// {
		// 	id: "Linear", 
		// 	title: "Linear",
		// 	children: [
		// 		{
		// 			id: __UNPLANNED_LINEAR_EVENTS__,
		// 			title: "Unplanned",
		// 			parent: "Linear",
		// 			allowDelete: false,
		// 			startEditable: false,
		// 		},
		// 		...linearResources.map(r => ({
		// 			...r,
		// 			title: r.name,
		// 			parent: "Linear",
		// 			allowDelete: true,
		// 			copyInsteadOfMove: true,
		// 			// startEditable: false,
		// 			// durationEditable: false,
		// 		})),
		// 	],
		// },
	];
}

function getResourceClassName({ resource }) {
	if (["OTT"/*, "Linear"*/].includes(resource.title)) {
		return "parent-resource";
	}
	if ([__UNPLANNED_OTT_EVENTS__/*, __UNPLANNED_LINEAR_EVENTS__*/].includes(resource.id)) {
		return "unplanned";
	}

	return "";
}

function getResourceOrder() {
	return [
		unplannedFirst,
		titleNumericCompare,
	];
}

function getEventOrder() {
	return [
		"-allDay",
		"start",
		titleNumericCompare,
	];
}

const unplannedFirst = (a, b) => {
	if ([__UNPLANNED_OTT_EVENTS__/*, __UNPLANNED_LINEAR_EVENTS__*/].includes(a.id)) return -1;
	if ([__UNPLANNED_OTT_EVENTS__/*, __UNPLANNED_LINEAR_EVENTS__*/].includes(b.id)) return 1;
	return 0;
};
const titleNumericCompare = (a, b) => a.title.localeCompare(b.title, {}, { numeric: true });

// function isTooltipOutsideCalendar(element) {
// 	const calendarRect = document.querySelector(".c6-calendar-resources").getBoundingClientRect();
// 	const elementRect = element.getBoundingClientRect();
// 	return elementRect.right > calendarRect.right;
// }

function removeTempElements() {
	const calendar = document.querySelector(".c6-calendar-resources");
	const buttons = calendar.querySelectorAll("button");
	buttons.forEach(b => b.remove());
	const tooltips = calendar.querySelectorAll(".tooltip");
	tooltips.forEach(t => t.remove());
}

function slotDidMount(slot, date) {
	const isStartOfNextDay = moment(slot.date).isSame(moment(date).add(1, "days").startOf("day"));
	if (isStartOfNextDay) {
		slot.el.classList.add("start-of-next-day");
	}
}
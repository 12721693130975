import React, { Component } from 'react'
import PropTypes from 'prop-types'

import debounce from 'lodash/debounce'

import { Filter, Left, Right } from '../../../../components/filter'
import Search from '../../../../components/ui/controls/search'
import Switch from '../../../../components/ui/controls/switch'

import Actions from '../../actions'

export default class DiscoveryFilter extends Component {

	static propTypes = {
		types: PropTypes.array.isRequired,

		list: PropTypes.shape({
			items: PropTypes.array,
			filters: PropTypes.object,
			isLoading: PropTypes.bool,
		}).isRequired,

		onBackToResults: PropTypes.func,
	}

	constructor(props) {
		super(props);

		this.searchWithDebounce = debounce(this.handleSearch, 1000);
	}

	handleFilter = (e) => {
		const filter = e.target.value;
		const premiereFilter = this.props.list.filters._programPremiereFilter ?? DEFAULT_PROGRAM_PREMIERE;
		const typeFilter = this.props.list.filters._broadcastsTypeFilter ?? DEFAULT_BROADCASTS_TYPE;
		Actions.discoveryFilter(this.props.list.filters, filter, premiereFilter, typeFilter, this.props.discoveryPlatform);
	}

	handleProgramPremiereFilter = (e) => {
		const premiereFilter = e.target.value;
		const typeFilter = this.props.list.filters._broadcastsTypeFilter ?? DEFAULT_BROADCASTS_TYPE;
		Actions.discoveryFilter(this.props.list.filters, this.props.list.filters._filter, premiereFilter, typeFilter, this.props.discoveryPlatform);
	}

	handleBroadcastsTypeFilter = (e) => {
		const typeFilter = e.target.value;
		const premiereFilter = this.props.list.filters._programPremiereFilter ?? DEFAULT_PROGRAM_PREMIERE;
		Actions.discoveryFilter(this.props.list.filters, this.props.list.filters._filter, premiereFilter, typeFilter, this.props.discoveryPlatform);
	}

	search = (e) => {
		if (e.type === "keydown" || e.target.value.length === 0) {
			this.searchWithDebounce.cancel(); // Cancel the debounced call since we're doing it right now
			this.handleSearch(e);
		}
		else {
			e.persist();
			this.searchWithDebounce(e);
		}
	}

	handleSearch = (e) => {
		const searchText = e.target.value;

		if (!searchText || searchText.length > 1) {
			Actions.discoverySearch(this.props.list.filters, searchText, this.props.discoveryPlatform);
		}
	}

	render() {

		const { list, types } = this.props;
		const { _filter, _programPremiereFilter, _broadcastsTypeFilter, searchText } = list.filters;
		const selectedType = types.find(type => type.key === _filter);

		return (
			<Filter className="c6-filter secondary">
				<Left>
					<Switch
						name="discoveryFilter"
						unselected={false}
						onChange={this.handleFilter}
						states={types}
						currentState={_filter}
					>
						<Switch
							whenParentState="programs"
							name="programPremiereFilter"
							unselected={searchText?.length > 0}
							onChange={this.handleProgramPremiereFilter}
							states={PROGRAM_PREMIERE_OPTION}
							currentState={_programPremiereFilter}
						/>

						<Switch
							whenParentState="broadcasts"
							name="broadcastsTypeFilter"
							// unselected={searchText?.length > 0}
							onChange={this.handleBroadcastsTypeFilter}
							states={BROADCASTS_TYPE_OPTION}
							currentState={_broadcastsTypeFilter}
						/>
					</Switch>
				</Left>
				<Right>
					<Search
						onChange={this.search}
						onEnterKeyDown={this.search}
						placeholder={`Search ${selectedType?.searchPlaceholder || ""}`}
						searchText={searchText}
					/>
				</Right>
			</Filter>
		);
	}
}


const DEFAULT_PROGRAM_PREMIERE = "upcoming";
const PROGRAM_PREMIERE_OPTION = [
	{ key: "upcoming", text: "Upcoming", description: "List all titles with premieres in the near future." },
	{ key: "fresh", text: "Fresh", description: "List all titles which have premiered in the last month." },
];

const DEFAULT_BROADCASTS_TYPE = "ott";
const BROADCASTS_TYPE_OPTION = [
	{ key: "ott", text: "OTT", description: "" },
	{ key: "linear", text: "Linear", description: "" },
];
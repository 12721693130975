import React from 'react'

import Item from './item'
import itemActions from './listItemActions'

import * as SportAPI from '../../../../apis/sport'
import useList from '../../../../core/hooks/useList'
import GenericListApp from '../../../../components/genericApp/listApp'
import { decoratorUIActions } from '../../../../core/decorators/uiActions'
import useEditorNavigation from '../../../../core/hooks/useEditorNavigation'
import useSportSeries from '../../../../core/queries/sport/useSportSeries'
import useSportSeriesList from '../../../../core/queries/sport/useSportSeriesList'

const MODULE = "eventplanner";
const DATASTORE = "participants";
const TEXT_HEADING = "Teams/Individuals";
const TEXT_BACK_BUTTON = "Administration";
const TEXT_EMPTY = "No participants? It's about time to create some then!";
const TEXT_CREATE = "Create participant";
const ICON_CREATE = "add";
const PATHNAME_CREATE = "eventplanner/admin/participants/create";

function ListApp(props) {
	const seriesIdFromQuery = props.location?.query?.seriesId >= 0 ? props.location.query.seriesId : null;
	const { data: series } = useSportSeries(seriesIdFromQuery);
	const { data: seriesList } = useSportSeriesList({
		enabled: seriesIdFromQuery === null,
	});

	const FILTER_DEFAULTS = {
		searchText: "",
		seriesId: seriesIdFromQuery,
	};

	const filterConfig = {
		searchText: {
			type: "search",
		},
	};

	if (seriesIdFromQuery === null) {
		filterConfig.seriesId = {
			type: "dropdown",
			title: "Series",
			options: [
				{ key: null, text: "Any" },
				...seriesList?.items?.map(s => ({
					...s,
					key: s.id,
					text: s.title,
					description: "",
				})) ?? [],
			],
			groupByProperty: item => item.genre?.title ?? " ",
		};
	}

	const listState = useList({
		listKey: DATASTORE,
		fetchData: SportAPI.fetchParticipants,
		filterDefaults: FILTER_DEFAULTS,
	});

	useEditorNavigation(listState);

	return (
		<GenericListApp
			{...props}
			module={MODULE}
			datastore={DATASTORE}
			state={listState}
			textHeading={series ? `${TEXT_HEADING} in league/series "${series?.title}"` : TEXT_HEADING}
			textBackButton={TEXT_BACK_BUTTON}
			textEmpty={TEXT_EMPTY}
			textCreate={TEXT_CREATE}
			iconCreate={ICON_CREATE}
			filterConfig={series ? {} : filterConfig}
			pathnameCreate={PATHNAME_CREATE}
			collapseFiltersDefault={false}
			showInfoBar={true}
		>
			<Item />
		</GenericListApp>
	);
}

@decoratorUIActions(itemActions)
export default class ParticipantsApp extends React.Component {
	render() {
		return (
			<ListApp
				{...this.props}
			/>
		);
	}
}
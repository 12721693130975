import React from 'react'
import moment from 'moment'

import ContentItem, { Content } from '../../../components/list/contentItem'
import InlineHeading from '../../../components/ui/inlineHeading'

import '../../../components/listItems/standard.css'
import { getProgramTitle, renderImdbIdOrLinks } from '../utils'

const Review = (props) => {

	const {
		id,
		source,
		score,
		maxScore,
		normalizedScore,
		imdbId,
		quotes = [],
		link,
		programId,
		programTitle,
		published,

		customer,
		location,
	} = props;

	const title = getProgramTitle(props);
	const firstQuote = quotes[0];

	return (
		<ContentItem key={id} data={props}>
			<Content size="min" className="info">
				<h1>{title ?? "[MISSING TITLE]"}</h1>
				<p>
					{renderImdbIdOrLinks(imdbId, programTitle, programId, customer, location)}
					{published && " - "}
					{published && moment(published).format("Do MMM YYYY")}
				</p>
				<p>{source.language} - <a onClick={onLinkClick} className="c6-link" target="_blank" href={link}>{source.name}</a></p>
			</Content>
			<Content size="min" className="content-score">
				<InlineHeading title="Score" className="score" hoverTitle={getHoverTitle(score, maxScore, normalizedScore, source)}>
					{formatScore(normalizedScore)}<span>({formatScore(score)}/{formatScore(maxScore)})</span>
				</InlineHeading>
			</Content>
			<Content>
				<p>{firstQuote?.text}</p>
			</Content>
			{firstQuote && (
				<Content size="min" className="quote-info">
					<p><strong>{firstQuote.addedBy}</strong></p>
					<p>{moment(firstQuote.created).format("D MMM YYYY")}</p>
				</Content>
			)}
		</ContentItem>
	);
};

export default Review;

function onLinkClick(e) {
	e.stopPropagation();
}

function getHoverTitle(score, maxScore, normalizedScore, source) {
	return `
		Score: ${formatScore(score)}
		Max score for ${source.name}: ${formatScore(maxScore)}
		Normalized score: ${formatScore(normalizedScore)}
	`.replace(/[\t]/gi, "").trim();
}

function formatScore(score) {
	if (isNaN(parseFloat(score))) {
		return "?";
	}
	
	const scoreStr = `${score}`.replace(",", ".");
	return `${(Math.round(parseFloat(scoreStr) * 100) / 100)}`;
}
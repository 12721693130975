import React from 'react'
import { Link } from 'react-router'

import { getModuleIcon } from '../../core/constants'

import './dashboard.css'

export default class Dashboard extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		const { title = "Dashboard", data } = this.props;
		const blocks = data.filter(b => !b.hidden);
		const heading = blocks.length ? <h1>{title}</h1> : null ;

		return (
			<div className="c6-dashboard">
				{heading}
				<div className="flex">
					{ blocks.map(block => {
						const { displayName, description, key, url, urlText, icon } = block;
						const links = (block.links || []).filter(l => !l.hidden);

						const moduleLink = !links.length && url?.length
							? getLink(url, key, urlText || `Go to the ${displayName} list`, "icon-chevron_right")
							: null;

						return (
							<section key={ key }>
								<header className={ getIcon(key) }><span>{ displayName }</span></header>
								{description && <p>{description}</p>}
								{
									links.map(link => {
										const { displayName, description, key, url } = link;

										const linkEl = <article className={ getIcon(key) }>{ displayName }</article>;

										if(link.ui === "React") {
											return <Link key={ key } to={ url }>{linkEl}</Link>;
										}

										return <a key={ key } href={ url }>{linkEl}</a>;
									})
								}
								{ moduleLink }
							</section>
						);
					})}
				</div>
			</div>
		);
	}
}

// HELPERS
function getIcon(key) {
	return getModuleIcon(key) || "";
}

function getLink(url, key, linkEl, className = null) {
	return url.indexOf("http://") < 0 || url.indexOf("https://") < 0
		? <Link key={ key } to={ url } className={className}>{linkEl}</Link>
		: <a key={ key } href={ url } className={className}>{linkEl}</a> ;
}
import * as ShieldAPI from '../../../apis/shield'
import { useQuery } from '@tanstack/react-query'

interface args {
    filter?: object;
    enabled?: boolean;
}

const useShieldLogins = ({ filter = {}, enabled = true }: args = {}) => {
    const filters = {
        pageSize: 1000,
        ...filter,
    };
    return useQuery(
        ["shield", "logins", filters],
        () => ShieldAPI.fetchLogins(filters),
        { enabled }
    );
};

export default useShieldLogins;

import React from 'react'

import StarLibrary from '../../../apps/star2/containereditor-metadata/library'

import './assetSearch.css'

const AssetSearch = ({
	className,
}) => {

	const [expandStarSection, setExpandStarSection] = React.useState(false);
	return (
		<div className={`${className} c6-cms-asset-search ${expandStarSection ? "expand-star" : ""}`}>
			<div
				className="star-toggler"
				onClick={() => setExpandStarSection(!expandStarSection)}
			>
				<p>Add an image or video from STAR</p>
				<p className={expandStarSection ? "icon-expand_more" : "icon-expand_less"}></p>
			</div>
			<StarLibrary
				container={{}}
				defaultIsSearching={true}
			/>
		</div>
	);
}

export default AssetSearch;
import moment from 'moment-timezone'
import appConfig from 'config'

import alt from '../../../../core/services/alt'
import Actions from './actions'
import SelectionsActions from '../../actions'

import Const, { Regions } from '../../../../core/constants'

class ItemsFilterStore {
	constructor() {
		this.filters = getFilters();

		this.shouldSetDateFromInstance = false;

		this.bindListeners({
			handleNavNextInstance: Actions.NAV_NEXT_INSTANCE,
			handleNavPrevInstance: Actions.NAV_PREV_INSTANCE,
			handleNavNextPeriod: Actions.NAV_NEXT_PERIOD,
			handleNavPrevPeriod: Actions.NAV_PREV_PERIOD,
			// handleNavToday: Actions.NAV_TODAY,
			handleNavDate: Actions.NAV_DATE,
			handleNavListCreated: Actions.NAV_LIST_CREATED,
			// handleListLoaded: Actions.LIST_LOADED,
			handleInstancesLoaded: Actions.INSTANCES_LOADED,
			onItemLoaded: SelectionsActions.ITEM_LOADED,

			onUnmount: Actions.UNMOUNT
		});
	}

	handleInstancesLoaded(data) {
		this.filters.instances = data || {};
		if (!data?.current)
			return;

		if (this.shouldSetDateFromInstance) {
			this.filters.date = moment(data.current.activeFrom);
		}
	}

	handleNavNextInstance(date) {
		const { next } = this.filters.instances;
		if (next) {
			this.filters.date = moment(next.activeFrom)
			this.shouldSetDateFromInstance = true;
		}
	}

	handleNavPrevInstance(date) {
		const { previous } = this.filters.instances;
		if (previous) {
			this.filters.date = moment(previous.activeFrom);
			this.shouldSetDateFromInstance = true;
		}
	}

	handleNavNextPeriod(date) {
		this.filters.date = date.add(1, this.filters.interval);
		this.shouldSetDateFromInstance = false;
	}

	handleNavPrevPeriod(date) {
		this.filters.date = date.add(-1, this.filters.interval);
		this.shouldSetDateFromInstance = false;
	}

	// handleNavToday() {
	// 	this.filters.date = moment();
	// 	setFilterInfo(this.filters);
	// }

	handleNavDate({ date }) {
		this.filters.date = moment(date);
		this.shouldSetDateFromInstance = false;
	}

	handleNavListCreated({date, listCreated}) {
		this.filters.date = moment(listCreated);
		this.shouldSetDateFromInstance = false;
	}

	handleListLoaded(list) {
		this.filters.instanceType = list.instanceType;
		this.filters.showNavigation = ["hourly", "minutely"].includes((list.instanceType || "").toLowerCase());
		const listRegion = list?.version ? Regions.find(r => r.id === list.version.id) : null;
		this.filters.listTimezone = listRegion?.momentTimezone;
		setNavigationProperites(this.filters);
	}

	onUnmount() {
		this.filters = getFilters();
	}


	onItemLoaded({ entity, model }) {
		if (entity === "list") {
			this.handleListLoaded(model);	
		} else if (entity === "listInstances") {
			this.handleInstancesLoaded(model);
		}
	}
}

export default alt.createStore(ItemsFilterStore, '[Selections]OttItemsFilterStore');

/* Helpers */
function setNavigationProperites(filters) {
	switch ((filters.instanceType || "").toLowerCase()) {
		case "hourly":
		case "minutely":
			filters.interval = "days";
			filters.showNavigation = true;
			filters.navigationTitleFormat = {
				normal: "LLL",
				short: "MMMM dd hh:mm"
			};
			filters.todayButtonTitle = "Today";
			break;
		case "daily":
			filters.interval = "days";
			filters.showNavigation = true;
			filters.navigationTitleFormat = {
				normal: "LL",
				short: "MMMM D"
			};
			filters.todayButtonTitle = "Today";
			break;
		case "monthly":
			filters.interval = "months";
			filters.showNavigation = true;
			filters.navigationTitleFormat = {
				normal: "MMMM YYYY",
				short: "MMMM"
			};
			filters.todayButtonTitle = "Current month";
			break;
		default:
			//filters.navigationTitle = "";
			filters.showNavigation = false;
	}
}

function getFilters() {
	return {
		date: moment(),
		instanceType: null,
		navigationTitleFormat: {},
		todayButtonTitle: "",
		showNavigation: false,
		instances: {},
		listTimezone: null,
	};
}

function formatDate(date, timezone, format) {
	return timezone
		? moment(date).tz(timezone).format(format)
		: moment(date).format(format);
}
import React from 'react'

import Editor, { parseUi } from '../../../../components/editor/index'

import { hasAccessToPath } from '../../../../core/services/auth'

import schema from './schema'
import uiSchema from './uiSchema'
import useSelectionsServices from '../../../../core/queries/selections/useSelectionsServices'

export default props => {
	const { data: servicesData } = useSelectionsServices();

	const services = servicesData?.items?.length
		? servicesData.items.filter(s => s.id !== 0)
		: [];

	return (
		<Editor
			layout="grid"
			api="selections"
			entity="area"
			getSchema={getSchema.bind(this, services)}
			getUiSchema={getUiSchema}
			hasEditAccess={hasAccessToPath(props.routes, "editor")}
			{...props}
		/>
	);
};

function getSchema(services, model, isNew, location, route, params) {
	if (!services?.length) {
		return {};
	}

	schema.properties.serviceId.oneOf = services.map(s => ({
		const: s.id,
		title: s.displayName,
	}));
	return schema;
}

function getUiSchema(model, isNew, location, route, params) {
	return uiSchema;
}
import React from 'react'

import ContentItem, { Content } from '../../../../components/list/contentItem'
import InlineHeading from '../../../../components/ui/inlineHeading'

import '../../../../components/listItems/standard.css'

const FileType = (props) => {
    const { id, name, assetType, mimeType, extension } = props;

    return (
        <ContentItem data={props}>
            <Content>
                <h1>{name}</h1>
                <p>ID: {id}</p>
            </Content>
            <Content className="filetype">
                <InlineHeading title="Asset type">{assetType}</InlineHeading>
                <div className="mimetype">
                    <InlineHeading title="MIME"><span title={mimeType}>{mimeType}</span></InlineHeading>
                </div>
                <InlineHeading title="Extension">{extension}</InlineHeading>
            </Content>
        </ContentItem>
    );
};

export default FileType;
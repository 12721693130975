import React, { useEffect } from 'react'
import { classNames } from '../../../utils/misc'

import { getServiceToken } from '../../../core/services/auth'
import IFrameDialog from '../../../dialogs/iFrameDialog'
import './subtitleHorse.css'

import { fetchContainerByGuid, fetchAsset } from '../../../apis/star'

import appConfig from 'config'

const VERSION_SRCLANG = {
	1: "sv",
    "sv": 1,
	2: "no",
    "no": 2,
	3: "da",
    "da": 3,
	4: "fi",
    "fi": 4,
	5: "en",
    "en": 5,
};

export default function SubtitleHorse(props) {

    const [dialogOpen, setDialogOpen] = React.useState(props.open ?? false);
    const [iFrameSrc, setIFrameSrc] = React.useState(null);
    const [status, setStatus] = React.useState({});
    const [subtitles, setSubtitles] = React.useState({
        sv: null,
        no: null,
        da: null,
        fi: null,
        en: null
    });
    const [videoInfo, setVideoInfo] = React.useState(null);
    const token = useStarToken();

    const setSubtitleForLanguage = (language, subtitle) => {
        console.log({ ...subtitles, [language]: subtitle });
        setSubtitles({ ...subtitles, [language]: subtitle });
    }

    useEffect(async () => {
        if(!props.program?.guid) {
            return;
        }
        
        setStatus({ loading: true, message: "Fetching container..." });
        let container = await fetchContainerByGuid(props.program?.guid);
        if(!container) {
            setStatus({ loading: false, error: true, message: "No container found" });
            return;
        }
        
        setStatus({ loading: true, message: "Looking for video in container..." });
        let videoAssetInContainer = findVideoAssetInContainer(container, "mezz");
        if(!videoAssetInContainer) {
            setStatus({ loading: false, error: true, message: "No video found in container" });
            return;
        }

        setStatus({ loading: true, message: "Fetching video asset files..." });
        let videoAsset = await fetchAsset(videoAssetInContainer.id);

        setStatus({ loading: false, message: null });

        const subtitleLanguages = {};
        videoAsset.files?.filter(file => 
            file.assetType === "Subtitle"
            && !["deleted", "trashed", "replaced"].includes(file.status?.toLowerCase())
        ).forEach(file => {
            subtitleLanguages[VERSION_SRCLANG[file.versionId]] = file;
        });

        setVideoInfo(videoAsset.files.find(file => 
            file.assetType === "Sidecar"
            && !["deleted", "trashed", "replaced"].includes(file.status?.toLowerCase())
        ));

        setSubtitles(subtitleLanguages);

    }, [props.program]);

	const openDialog = (lang) => {
        setIFrameSrc(getSHUrl(props.program, lang, token, props.light));
        setDialogOpen(true);
	};

    const closeDialog = () => {
        setDialogOpen(false);
        if(props.onClose) {
            props.onClose();
        }
    };

    const dialog = (
        <IFrameDialog
            src={iFrameSrc}
            open={true}
            onClose={closeDialog}
            data={
                {
                    token: token
                }
            }
        />
    );

    if (props.renderOnlyDialog) {
        return dialog;
    }

    if(!props.open) {
        return null
    }

    if(dialogOpen) {
        return dialog;
    }

    return (
        <div className="sh-dialog-wrapper" onClick={props.onClose}>
            <div className='sh-dialog' onClick={e => e.stopPropagation()}>
                {
                    status.message &&
                    <small>{status.message}</small>
                }
                <h4>Choose subtitle language:</h4>
                <button className={classNames({'icon-check':subtitles.sv})} onClick={() => openDialog("sv")}>Swedish</button>
                <button className={classNames({'icon-check':subtitles.no})} onClick={() => openDialog("no")}>Norwegian</button>
                <button className={classNames({'icon-check':subtitles.da})} onClick={() => openDialog("da")}>Danish</button>
                <button className={classNames({'icon-check':subtitles.fi})} onClick={() => openDialog("fi")}>Finnish</button>
                <button className={classNames({'icon-check':subtitles.en})} onClick={() => openDialog("en")}>English</button>

                {   videoInfo &&
                    <small>Video info exists</small>
                }
            </div>
        </div>
    );
}

function getSHUrl(program, language, token, light) {
    const params = new URLSearchParams();
    if(token.length < 1800) {
        params.set("tkn", token);
    }
    params.set("programGuid", program?.guid);
    params.set("light", light);
    params.set("lang", language);
    const testPlayerBaseUrl = appConfig.features.metadataSHBaseUrl;
    return `${testPlayerBaseUrl}?${params.toString()}`;
}

function useStarToken(disabled = false) {
    const [token, setToken] = React.useState(null);

    React.useEffect(
        () => {
            if (!disabled) {
                const fetchToken = async () => {
                    // Get Star token since we need to pass it to the iframe to make STAR-requests from there
                    const starToken = await getServiceToken("star");
                    setToken(starToken);
                }
                fetchToken();
            }
        },
        [disabled]
    );

    return token;
}

function findVideoAssetInContainer(container, videoFormat) {
    if(!container.assets) {
        return null;
    }
    return container.assets
        .sort((a, b) => b.created.localeCompare(a.created))
        .find((asset) => 
            asset.type?.toLowerCase() === "video" &&
            asset.format?.toLowerCase() === videoFormat.toLowerCase() &&
            !["deleted", "trashed", "replaced"].includes(asset.status?.toLowerCase())
        );
}
import React from 'react'
import PropTypes from 'prop-types'

import {decoratorUIActions} from '../../../../core/decorators/uiActions'
import { syncDeepFindGroup } from '../../../../utils/groups'

import { DroppableItemGroup } from '../../../../components/list/listItems'
import SortableList from '../../../../components/list/sortableList'
import  Main from '../../../../components/ui/main'
import Empty from '../../../../components/list/empty'
import ItemTypes from '../../../../core/itemTypes'


import Item from './listItem'

import Actions from '../../actions'
import itemActions from './listItemActions'
// import ListConstants from '../constants'

const DATASTORE = "lists";

@SortableList({
	onItemMoved: ({ payload, originalItem, targetType, sourceProps }) => {
		// The ordinal is for the whole list, not the ordinal in each nested level
		console.log("Move section %o", { payload, originalItem });
		Actions.moveSection({ payload, originalItem });
	},
	generateNewItem: ({ payload, droppedItem, targetType }) => {
		// debugger;
		// const { referenceId } = droppedItem;
		// if(referenceId) {
		// 	if(referenceId.indexOf("discoveryprogram") >= 0) {
		// 		payload.isProgram = true;
		// 		payload.category = { id: ListConstants.CATEGORY_IDS.FEATURE }; // Set temp newItem category to Feature if we drop a program
		// 	}
		// 	payload.referenceId = referenceId.split("-")[1];
		// }

		return {
			...droppedItem.data,
			...payload,
			// type: { id: 5 },
			// active: targetType === ItemTypes.LIST_ACTIVE,
		}
	},
	onItemAdded: ({ payload, sourceProps }) => {
		const { conditions } = sourceProps;
		const { isProgram, ...rest } = payload;

		const requestPayload = {
			store: DATASTORE,
			groupId: conditions.groupId,
			payload: rest,
			filters: conditions
		};

		console.log("Item added %o", { requestPayload, payload, sourceProps });

		// if(isProgram) {
		// 	Actions.createFeatureAndAddToGroup(requestPayload);
		// }
		// else {
		// 	Actions.addListToGroup(requestPayload);
		// }
	},
})
class ListLevel extends React.Component {
	state = {
		isDraggingItem: false,
		draggedItem: null,
	}

	handleDragStateChanged = (item, isDraggingItem) => {
		this.props.onDragStateChanged({
			id: this.props.parentId,
			isDragging: isDraggingItem,
		});
		this.setState({ isDraggingItem, draggedItem: item });
	}

	render() {
		const {
			parentId,
			selectedItems,
			disabled,

			// Injected by the SortableList HOC ReactDnD
			connectDropTarget,
			isOver,

			// Injected by the SortableList HOC
			items,
			handleItemDrop,
			handleTargetHover,
		} = this.props;

		const { isDraggingItem, draggedItem } = this.state;
		const isDraggingClass = isDraggingItem ? " is-dragging-item" : "";

		return (
			<Main key={`parent-${parentId}`} className={isDraggingClass}>
				<DroppableItemGroup
					// disabled={isDraggingItem && parentId !== draggedItem?.parentSectionId} // Disable dragging between group levels (we decided that we will disable it in the UI since it's not supported in the backend anyways)
					disabled={disabled} // Disable dragging between group levels (we decided that we will disable it in the UI since it's not supported in the backend anyways)
					type={`listLevel${parentId}`}
					onTargetHover={handleTargetHover}
					onItemDrop={handleItemDrop} >
					{this.renderItems(items, selectedItems, "Would you like to add a section here?")}
				</DroppableItemGroup>
			</Main>
		);
	}

	renderItems = (items, selectedItems, noItemsFoundText) => {
		const {
			// Injected by the SortableList HOC
			findItem,
			moveItem,
			handleOutsideDrop,
			handleItemManipulation,
		} = this.props;


		if (!items.length) {
			return (
				<div style={{ height: "100%" }}>
					<Empty v2={true} title="This section is empty">{noItemsFoundText}</Empty>
				</div>
			);
		}

		return items.map(item => {
			return (
				<Item
					key={item.id}
					id={item.id}
					item={item}
					selected={selectedItems.includes(item.id)}

					// DND
					isPlaceholder={item.isPlaceholder || !!item.isOver}
					findItem={findItem}
					moveItem={moveItem}
					handleOutsideDrop={handleOutsideDrop}
					handleItemManipulation={handleItemManipulation}
					onDragStateChanged={this.handleDragStateChanged.bind(this, item)}
				/>
			);
		});
	}
}

@decoratorUIActions(itemActions)
export default class List extends React.Component {

	static propTypes = {
		items: PropTypes.array.isRequired,
		conditions: PropTypes.object,
		selectedGroup: PropTypes.object,
	}

	state = {
		listLevelIsDragging: null,
	}

	handleDragStateChangedInListLevel = ({ id, isDragging }) => {
		this.setState({ listLevelIsDragging: isDragging ? id : null });
	}

	render() {
		const {
			isLoading,
			selectedGroup,
			items,
		} = this.props;

		if (items.length) {
			const [levelItems, selectedItems] = generateLevels(items, selectedGroup);

			return (
				<div className="flex">
					{levelItems.map(l => {
						return (
							<ListLevel
								key={l.parentId}
								parentId={l.parentId}
								items={l.items}
								selectedItems={selectedItems}
								conditions={this.props.conditions}
								disabled={!!this.state.listLevelIsDragging && this.state.listLevelIsDragging !== l.parentId}
								onDragStateChanged={this.handleDragStateChangedInListLevel}
							/>
						);
					})}
				</div>
			);
		}

		return (
			<div style={{height: "100%"}}>
				<Empty v2={true} title="No selections found">Add a new section above to get started.</Empty>
			</div>
		);
  	}
}

// HELPERS
function generateLevels(items, selectedItem) {
	// TODO: PERF: Find a way to start with level 0 and then 1 to prevent nesting folder keys changing when they should not

	let groups = [];
	let selected = [];
	let depth = 0;
	let currentItem = selectedItem;

	// Recursively add items from the deepest level to the most shallow
	while (currentItem && currentItem.id) {
		// Remember the path to the selected item
		selected.unshift(currentItem.id);

		const parentId = currentItem.id;

		// For rendering the selected item children
		groups.unshift({
			// items: items.filter(i => !i._isHidden && i.parentGroupId === currentItem.id && !i.isOver || i.isOver === `listLevel${depth}`),
			// items: items.filter(i => i.parentGroupId === currentItem.id && !i.isOver || i.isOver === `listLevel${depth}`),
			items: items.filter(i => i.parentSectionId == parentId && !i.isOver || i.isOver === `listLevel${parentId}`),
			depth: ++depth,
			parentId,
		});

		currentItem = items.find(i => i.id == currentItem.parentSectionId);
	}

	// Finally add the top level items
	groups.unshift({
		// items: items.filter(i => !i._isHidden && !i.parentGroupId && !i.isOver || i.isOver === `listLevel${depth}`),
		// items: items.filter(i => !i.parentGroupId && !i.isOver || i.isOver === `listLevel${depth}`),
		items: items.filter(i => !i.parentSectionId && !i.isOver || i.isOver === `listLevel0`),
		depth: 0,
		parentId: 0,

	});

	return [ groups, selected ];
}
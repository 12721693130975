import React, { Component } from 'react'

import ContentItem, { Content } from '../../../../components/list/contentItem'

import styles from '../../../../components/listItems/standard.css'

export default class AreaContent extends Component {

	render() {
		const {
			item: itemData,
		} = this.props;

		const {
			id,
			name,
		} = itemData;

		return (
			<ContentItem
				data={itemData}
			>
				<Content>
					<h1>{name}</h1>
					<p>ID: {id}</p>
				</Content>
			</ContentItem>
		);
	}
}

import React from 'react'

import * as CMSAPI from '../../../apis/cms'
import { getValue } from '../../../core/cms/utils'
import useDebounce from '../../../core/hooks/useDebounce'

import Button from '../../ui/controls/button'
import Spinner from '../../spinner'

import './list.css'

const ITEM_ID_CREATE = "item_id_create";

const List = ({
	className,
	displayName,
	name,
	readOnly,
	valuesUrl,
	onChange,
	options,
	multiValue = true,
	entityType,
	dataType,
	parameters,
	getTooltipEl,
	tooltip,
	itemData,
	path,
	...props
}) => {
	const containerRef = React.useRef();
	const [isActive, setIsActive] = React.useState(false);
	const [searchIsLoading, setSearchIsLoading] = React.useState(dataType !== "string");
	const [searchResults, setSearchResults] = React.useState(null);
	const [searchText, setSearchText] = React.useState("");
	const [searchError, setSearchError] = React.useState(null);
	const [focusedItemIndex, setFocusedItemIndex] = React.useState(null);

	const [editingItem, setEditingItem] = React.useState(null);
	const [editItemInput, setEditItemInput] = React.useState("");

	const searchResultsContainerRef = React.useRef();
	const inputRef = React.useRef();


	let value = props.value ?? [];
	if (!multiValue) {
		value = props.value?.id ? [props.value] : [];
	}
	const filteredSearchResults = getFilteredSearchResults(searchResults, value, searchText, searchIsLoading, dataType);

	const search = React.useCallback(
		(text) => {
			if (text?.length) {
				setSearchIsLoading(true);
				const trimmedText = text.trim();
				const url = valuesUrl.replace("$name", trimmedText);
				CMSAPI.fetchCMSUrl(url)
					.then(response => {
						setSearchResults(response.items);
						setSearchIsLoading(false);
					}, error => {
						console.error(error);
						setSearchError("Something went wrong... Try again!");
						setSearchIsLoading(false);
					});
			} else {
				setSearchResults(null);
				setSearchIsLoading(false);
			}

			setFocusedItemIndex(null);
			setSearchError(null);
		},
		[value, valuesUrl, setSearchResults, setSearchIsLoading, setFocusedItemIndex, setSearchError]
	);

	const debouncedSearch = useDebounce(search, 500);

	React.useEffect(() => {
		if (dataType !== "string" && searchText?.length) {
			debouncedSearch(searchText);
		}
	}, [searchText]);

	const closeList = React.useCallback(
		() => {
			setIsActive(false);
			setSearchIsLoading(false);
			setSearchResults(null);
			setFocusedItemIndex(null);
			setEditingItem(null);
		},
		[setIsActive, setSearchIsLoading, setSearchResults, setFocusedItemIndex, setEditingItem]
	);

	const closeIfClickOutside = React.useCallback(
        (e) => {
            const clickedNode = e.target;
            const isChildOfContainer = containerRef.current.contains(clickedNode);
            if (!isChildOfContainer) {
                closeList();
            }
        },
        [closeList, containerRef.current]
    );

	React.useEffect(() => {
		if (isActive || editingItem) {
			window.addEventListener("click", closeIfClickOutside);
		} else {
			window.removeEventListener("click", closeIfClickOutside);
		}

		return () => {
			window.removeEventListener("click", closeIfClickOutside);
		};
	}, [isActive, editingItem, closeIfClickOutside]);

	const add = React.useCallback(
		async (item) => {
			// Create the item if necessary
			if (dataType !== "string" && item.id === ITEM_ID_CREATE) {
				const cmsItemPayload = {};
				Object.entries(parameters).forEach(([key, val]) => {
					if (val === "$value") {
						cmsItemPayload[key] = item.newItemName ?? item.name;
					} else if (val.startsWith("@")) {
						const dynamicValue = getValue(itemData, key, path.join("."));
						cmsItemPayload[key] = dynamicValue;
					} else {
						cmsItemPayload[key] = val;
					}
				});
				// Creates or updates item in CMS
				item = await CMSAPI.createCMSEntityAndReturnContent({
					_entity: `${entityType}s`,
					payload: cmsItemPayload,
				});
			}

			const newItem = dataType === "string"
				? item.newItemName
				: item;
			const newValue = multiValue ? [...value, newItem] : newItem;
			onChange(newValue);
			setSearchText("");

			setTimeout(() => {
				inputRef.current?.focus();	
			});
		},
		[onChange, value, filteredSearchResults]
	);

	const remove = React.useCallback(
		(e, item, index) => {
			e.stopPropagation();
			const newValue = multiValue
				? value.filter((v, i) => item ? v.id !== item.id : i !== index)
				: null;
			onChange(newValue);
		},
		[onChange, value]
	);

	const editItem = React.useCallback(
		(e, item, index) => {
			e.stopPropagation();
			closeList();
			setEditingItem(index ?? item);
			setEditItemInput(index !== undefined ? item : item.name);
		},
		[setEditingItem, setEditItemInput, setIsActive]
	);

	const confirmEdit = React.useCallback(
		async (e) => {
			e.stopPropagation();
			
			const replaceObjectFields = true;
			const refreshAllItemsAfterUpdate = true;
			const newText = editItemInput.trim();
			if (multiValue) {
				const newValue = [...value];
				if (dataType === "string") {
					newValue[editingItem] = newText;
				} else {
					const index = newValue.findIndex(i => i.id === editingItem.id);
					newValue[index] = {
						...editingItem,
						name: newText,
					};
				}

				await onChange(newValue, replaceObjectFields, refreshAllItemsAfterUpdate);
			} else {
				await onChange(
					{
						...value[0],
						name: newText,
					},
					replaceObjectFields,
					refreshAllItemsAfterUpdate,
				);
			}

			setEditingItem(null);
			setEditItemInput("");
			setIsActive(false);
			
		},
		[editItemInput, valuesUrl, editingItem, setEditingItem, setEditItemInput, onChange, value]
	);
	
	const onEditKeyDown = React.useCallback(
		(e) => {
			switch (e.key) {
				case "Esc":
				case "Escape":
					e.preventDefault();
					setEditingItem(null);
					setEditItemInput("");
					break;
				case "Enter":
					e.preventDefault();
					confirmEdit(e);
					break;
			}
		},
		[setEditingItem, setEditItemInput, confirmEdit]
	);

	const onKeyDown = React.useCallback(
		(e) => {
			switch (e.key) {
				case "Esc":
				case "Escape":
					e.preventDefault();
					closeList();
					break;
				case "Enter":
					e.preventDefault();
					// Add currently focused item
					if (focusedItemIndex !== null) {
						const item = filteredSearchResults[focusedItemIndex];
						add(item);
						setFocusedItemIndex(null);
					} else if (dataType === "string") {
						const item = filteredSearchResults[0];
						add(item);
					}
					break;
				case "ArrowDown":
					e.preventDefault();
					// Focus next item, don't go past last item
					if (focusedItemIndex !== null) {
						setFocusedItemIndex(Math.min(focusedItemIndex + 1, filteredSearchResults.length - 1));
					}
					// Focus first item
					else {
						setFocusedItemIndex(0);
					}
					scrollItemIntoView(searchResultsContainerRef.current, focusedItemIndex);
					break;
				case "ArrowUp":
					e.preventDefault();
					// If already focusing first item, focus input (by clearing item focus)
					if (focusedItemIndex === 0) {
						setFocusedItemIndex(null);
					}
					// Focus previous item
					else if (focusedItemIndex !== null) {
						setFocusedItemIndex(Math.max(focusedItemIndex - 1, 0));
					}
					scrollItemIntoView(searchResultsContainerRef.current, focusedItemIndex);
					break;
			}
		},
		[setIsActive, setSearchIsLoading, setSearchResults, filteredSearchResults, focusedItemIndex, setFocusedItemIndex, add]
	);

	const maxCountReached = typeof options?.maxCount === "number" && value?.length >= options.maxCount
		|| multiValue === false && value?.length >= 1;

	let inputPlaceholder;
	if (maxCountReached) {
		inputPlaceholder = "Max number of items reached.";
	} else if (dataType === "string") {
		inputPlaceholder = `Enter new ${(displayName ?? name ?? "").toLowerCase()}`;
	} else {
		inputPlaceholder = `Search ${(displayName ?? name ?? "").toLowerCase()}...`;
	}

	return (
		<div
			ref={containerRef}
			className={`c6-cms-list ${className} ${!value?.length ? "empty" : ""} ${isActive ? "active" : ""}`}
			onClick={(e) => {
				if (readOnly) {
					return;
				}
				// If the clicked list is also the currently active list,
				// prevent the event from propagating to the window eventlistener that closes our lists
				if (isActive) {
					e.stopPropagation();
				} else if (editingItem === null) {
					setIsActive(true);
				}
			}}
		>
			<label
				className="title"
				title={readOnly ? null : `Add ${name}`}
			>
				{displayName ?? name}
				{getTooltipEl(tooltip)}
			</label>
			<div className="list">
				{value.map((item, i) => {
					const isEditing = dataType === "string"
						? editingItem === i
						: editingItem?.id === item.id;
					return (
						<div
							key={item.id ?? i}
							className="item"
							title={`Click to edit item${dataType === "string" ? "" : " (this will change this item everywhere it appears)"}`}
							onClick={(e) => {
								if (dataType === "string") {
									editItem(e, item, i);
								} else {
									editItem(e, item);
								}
							}}
						>
							{!isEditing && (
								<React.Fragment>
									<div className="name">{dataType === "string" ? item : item.name}</div>
									<Button
										title=""
										hoverTitle="Remove item"
										onClick={(e) => {
											if (dataType === "string") {
												remove(e, null, i);
											} else {
												remove(e, item);
											}
										}}
										className="remove icon-cancel"
									/>
								</React.Fragment>
							)}
							{isEditing && (
								<React.Fragment>
									<input
										type="text"
										value={editItemInput}
										onChange={e => setEditItemInput(e.target.value)}
										onKeyDown={onEditKeyDown}
										autoFocus={true}
									/>
									<Button
										title=""
										hoverTitle={`Confirm edit${dataType === "string" ? "" : " (this will change this item everywhere it appears)"}`}
										onClick={(e) => confirmEdit(e)}
										className="confirm icon-check"
									/>
								</React.Fragment>
							)}
						</div>
					);
				})}

				{!maxCountReached && !isActive && (
					<div className="item item-add">
						<Button
							title=""
							hoverTitle="Click to start adding items"
							onClick={(e) => {
								e.stopPropagation();
								closeList();
								setIsActive(true);
							}}
							className="add icon-add_circle"
							tabIndex={-1}
						/>
					</div>
				)}
			</div>
			{!maxCountReached && isActive && (
				<div className="input-wrapper">
					<input
						type="text"
						value={searchText}
						onChange={e => setSearchText(e.target.value)}
						placeholder={inputPlaceholder}
						onKeyDown={onKeyDown}
						autoFocus={true}
						ref={inputRef}
						className={`${searchError ? "error" : ""}`}
						disabled={maxCountReached}
						tabIndex={-1}
					/>
					<Spinner loading={searchIsLoading} />
				</div>
			)}
			{!maxCountReached && (
				<div className="search-results card-2" ref={searchResultsContainerRef}>
					{searchError && <div className="search-result-item error">{searchError}</div>}
					{filteredSearchResults.map((item, index) => (
						<div
							key={item.id + index}
							onClick={() => add(item)}
							className={`search-result-item ${index === focusedItemIndex ? "focus" : ""}`}
							onKeyDown={onKeyDown}
						>
							{item.name}
						</div>
					))}
				</div>
			)}
		</div>
	);
}

export default List;

function getFilteredSearchResults(searchResults, value = [], searchText, searchIsLoading, dataType) {
	const trimmedSearchText = searchText.trim();
	const newItem = {
		id: ITEM_ID_CREATE,
		name: dataType === "string" ? `Add "${trimmedSearchText}"` : `Create & add "${trimmedSearchText}"`,
		newItemName: trimmedSearchText,
	};

	if (dataType === "string" && trimmedSearchText?.length) {
		return [newItem];
	}

	const valueIds = value.map(v => v.id);
	const filteredResults = searchResults?.filter(sr => !valueIds.includes(sr.id)) ?? [];
	const searchTextMatchInResults = searchResults?.some(r => r.name.toLowerCase().trim() === trimmedSearchText.toLowerCase());
	if (
		searchText.length
		&& !searchIsLoading
		&& searchResults !== null
		&& !searchTextMatchInResults
	) {
		filteredResults.unshift(newItem);
	}
	return filteredResults;
}

function scrollItemIntoView(container, itemIndex) {
	const childElement = container?.children[itemIndex];
	if (childElement) {
		childElement.scrollIntoView({
			block: "center",
			inline: "nearest",
		});
	}	
}
import React from 'react'
import moment from 'moment'
import cloneDeep from 'lodash/cloneDeep'

import * as PlannerAPI from '../../../apis/planner'
import useList from '../../../core/hooks/useList'
import usePlannerPlatforms from '../../../core/queries/planner/usePlannerPlatforms'
import { getMonetizationModels, getPlatformStates, getPremiereStates, getSourceStates, markLicenseAsChanged } from '../utils'

import Item from './listItem'
import GenericListApp from '../../../components/genericApp/listApp'
import itemActions from './listItemActions'

import './programs.css'
import ProgramDialog from '../calendar/programDialog'
import usePlannerLicenseTypes from '../../../core/queries/planner/usePlannerLicenseTypes'
import { hasAccessToPath } from '../../../core/services/auth'
import { decoratorUIActions } from '../../../core/decorators/uiActions'

const MODULE = "planner";
const DATASTORE = "programs";
const TEXT_HEADING = "Programs";
const TEXT_BACK_BUTTON = null;
const TEXT_EMPTY = null;
const TEXT_CREATE = null;
const ICON_CREATE = null
const PATHNAME_CREATE = null;

function ListApp(props) {

	const { data: platforms } = usePlannerPlatforms();
	const { data: licenseTypes } = usePlannerLicenseTypes();

	const [selectedProgram, setSelectedProgram] = React.useState(null);

	const [program, setProgram] = React.useState({});
	const [programIsLoading, setProgramIsLoading] = React.useState(false);
	const fetchProgram = React.useCallback(
		async () => {
			if (selectedProgram) {
				setProgramIsLoading(true);
				const p = await PlannerAPI.fetchProgram({
					id: selectedProgram?.id,
					reference: selectedProgram?.referenceId,
				});
				setProgram(p);
				setProgramIsLoading(false);
			}
		},
		[selectedProgram]
	);

	const [programChildren, setProgramChildren] = React.useState(null);
	const [programChildrenIsLoading, setProgramChildrenIsLoading] = React.useState(false);
	const fetchEpisodes = React.useCallback(
		async () => {
			setProgramChildrenIsLoading(true);
			const pc = await PlannerAPI.fetchPrograms({
				parentReferenceId: program?.referenceId
			});
			setProgramChildren(pc.items);
			setProgramChildrenIsLoading(false);
		},
		[program]
	);

	const onPublishWindowDeleted = React.useCallback(
		(id, licenseId, programId) => {
			if (program?.id === programId) {
				const licenseIndex = program.licenses.findIndex(l => l.id === licenseId);
				if (licenseIndex >= 0) {
					const exposureIndex = program.licenses[licenseIndex].exposures.findIndex(e => e.id === id);
					if (exposureIndex >= 0) {
						const newProgram = cloneDeep(program);
						newProgram.licenses[licenseIndex].exposures.splice(exposureIndex, 1);
						// HACK: Add timestamp to license key because it was displaying old data otherwise. Couldn't figure out why...
						newProgram.licenses[licenseIndex]._exposuresUpdatedTimestamp = Date.now();
						setProgram(newProgram);
					}
				}
			} else {
				const programIndex = programChildren?.findIndex(p => p.id === programId);
				if (programIndex >= 0) {
					const licenseIndex = programChildren[programIndex].licenses.findIndex(l => l.id === licenseId);
					if (licenseIndex >= 0) {
						const exposureIndex = programChildren[programIndex].licenses[licenseIndex].exposures.findIndex(e => e.id === id);
						if (exposureIndex >= 0) {
							const newProgramChildren = cloneDeep(programChildren);
							newProgramChildren[programIndex].licenses[licenseIndex].exposures.splice(exposureIndex, 1);
							// HACK: Add timestamp to license key because it was displaying old data otherwise. Couldn't figure out why...
							newProgramChildren[programIndex].licenses[licenseIndex]._exposuresUpdatedTimestamp = Date.now();
							setProgramChildren(newProgramChildren);
						}
					}
				}
			}
		},
		[program, programChildren, setProgram, setProgramChildren]
	);

	const onPublishWindowCreated = React.useCallback(
		(newPublishWindow, licenseId, programId) => {
			if (program?.id === programId) {
				const licenseIndex = program.licenses.findIndex(l => l.id === licenseId);
				if (licenseIndex >= 0) {
					const newProgram = cloneDeep(program);
					newProgram.licenses[licenseIndex].exposures.push(newPublishWindow);
					// HACK: Add timestamp to license key because it was displaying old data otherwise. Couldn't figure out why...
					newProgram.licenses[licenseIndex]._exposuresUpdatedTimestamp = Date.now();
					setProgram(newProgram);
				}
			} else {
				const programIndex = programChildren?.findIndex(p => p.id === programId);
				if (programIndex >= 0) {
					const licenseIndex = programChildren[programIndex].licenses.findIndex(l => l.id === licenseId);
					if (licenseIndex >= 0) {
						const newProgramChildren = cloneDeep(programChildren);
						newProgramChildren[programIndex].licenses[licenseIndex].exposures.push(newPublishWindow);
						// HACK: Add timestamp to license key because it was displaying old data otherwise. Couldn't figure out why...
						newProgramChildren[programIndex].licenses[licenseIndex]._exposuresUpdatedTimestamp = Date.now();
						setProgramChildren(newProgramChildren);
					}
				}
			}
		},
		[program, programChildren, setProgram, setProgramChildren]
	);

	const onPublishWindowUpdated = React.useCallback(
		(updatedPublishWindow, licenseId, programId) => {
			if (program?.id === programId) {
				const licenseIndex = program.licenses.findIndex(l => l.id === licenseId);
				if (licenseIndex >= 0) {
					const exposureIndex = program.licenses[licenseIndex].exposures.findIndex(e => e.id === updatedPublishWindow.id);
					if (exposureIndex >= 0) {
						const newProgram = cloneDeep(program);
						newProgram.licenses[licenseIndex].exposures[exposureIndex] = updatedPublishWindow;
						// HACK: Add timestamp to license key because it was displaying old data otherwise. Couldn't figure out why...
						newProgram.licenses[licenseIndex]._exposuresUpdatedTimestamp = Date.now();
						setProgram(newProgram);
					}
				}
			} else {
				const programIndex = programChildren?.findIndex(p => p.id === programId);
				if (programIndex >= 0) {
					const licenseIndex = programChildren[programIndex].licenses.findIndex(l => l.id === licenseId);
					if (licenseIndex >= 0) {
						const exposureIndex = programChildren[programIndex].licenses[licenseIndex].exposures.findIndex(e => e.id === updatedPublishWindow.id);
						if (exposureIndex >= 0) {
							const newProgramChildren = cloneDeep(programChildren);
							newProgramChildren[programIndex].licenses[licenseIndex].exposures[exposureIndex] = updatedPublishWindow;
							// HACK: Add timestamp to license key because it was displaying old data otherwise. Couldn't figure out why...
							newProgramChildren[programIndex].licenses[licenseIndex]._exposuresUpdatedTimestamp = Date.now();
							setProgramChildren(newProgramChildren);
						}
					}
				}
			}
		},
		[program, programChildren, setProgram, setProgramChildren]
	);

	const onLicenseUpdated = React.useCallback(
		(updatedLicense, licenseId, programId) => {
			if (program?.id === programId) {
				const licenseIndex = program.licenses.findIndex(l => l.id === licenseId);
				if (licenseIndex >= 0) {
					const newProgram = cloneDeep(program);
					newProgram.licenses[licenseIndex] = {
						...newProgram.licenses[licenseIndex],
						...updatedLicense,
					};
					markLicenseAsChanged(newProgram.licenses[licenseIndex]);
					setProgram(newProgram);
				}
			} else {
				const programIndex = programChildren?.findIndex(p => p.id === programId);
				if (programIndex >= 0) {
					const licenseIndex = programChildren[programIndex].licenses.findIndex(l => l.id === licenseId);
					if (licenseIndex >= 0) {
						const newProgramChildren = cloneDeep(programChildren);
						newProgramChildren[programIndex].licenses[licenseIndex] = {
							...newProgramChildren[programIndex].licenses[licenseIndex],
							...updatedLicense,
						};
						markLicenseAsChanged(newProgramChildren[programIndex].licenses[licenseIndex]);
						setProgramChildren(newProgramChildren);
					}
				}
			}
		},
		[program, programChildren, setProgram, setProgramChildren]
	);

	const route = [{ module: "planner", access: "editor" }];
	const readonly = !hasAccessToPath(route);

	const FILTER_DEFAULTS = {
		search: "",
		programTypes: "single|season",
		orderBy: "unplannedtitle",
		dynamicHvod: null,
		dynamicSvod: null,
		platform: null,
		owner: null,
		_premiere: null,
		monetizationModel: "",
	};

	const listState = useList({
		listKey: DATASTORE,
		fetchData: PlannerAPI.fetchPrograms,
		filterDefaults: FILTER_DEFAULTS,
		transformFilters: transformFilters,
	});
	// PRiority - A, B, C, D

	const filterConfig = {
		search: {
			type: "search",
			alwaysVisible: true,
		},
		platform: {
			type: "dropdown",
			title: "Platform",
			options: [
				{ key: null, text: "Any" },
				...getPlatformStates(platforms),
			],
		},
		owner: {
			type: "dropdown",
			title: "Source",
			options: getSourceStates(listState.filters.values.platform, platforms)
		},
		monetizationModel: {
			type: "dropdown",
			title: "License type",
			options: getMonetizationModels(),
		},
		_premiere: {
			type: "dropdown",
			title: "Premiere",
			options: getPremiereStates(),
		},
		dynamicHvod: {
			type: "dropdown",
			title: "HVOD rule",
			options: [
				{ key: null, text: "Any" },
				{ key: "never", text: "Never add HVOD" },
				{ key: "always", text: "Always add HVOD" },
				{ key: "ruleBased", text: "Add HVOD based on Ads Allowed flag" },
			],
			disabled: (filterValues) => {
				return filterValues.monetizationModel === "hvod";
			},
		},
		dynamicSvod: {
			type: "dropdown",
			title: "SVOD rule",
			options: [
				{ key: null, text: "Any" },
				{ key: "never", text: "Never add SVOD" },
				{ key: "always", text: "Always add SVOD" },
			],
			disabled: (filterValues) => {
				return filterValues.monetizationModel === "svod";
			},
		},
	};

	return (
		<>
			<GenericListApp
				{...props}
				module={MODULE}
				datastore={DATASTORE}
				state={listState}
				textHeading={TEXT_HEADING}
				textBackButton={TEXT_BACK_BUTTON}
				textEmpty={TEXT_EMPTY}
				textCreate={TEXT_CREATE}
				iconCreate={ICON_CREATE}
				filterConfig={filterConfig}
				pathnameCreate={PATHNAME_CREATE}
				collapseFiltersDefault={false}
				virtualize={false}
			>
				<Item
					onProgramClick={setSelectedProgram}
				/>
			</GenericListApp>
			<ProgramDialog
				programId={selectedProgram?.id}
				programReferenceId={selectedProgram?.referenceId}

				program={program}
				programChildren={programChildren}
				programChildrenIsLoading={programChildrenIsLoading}
				isLoading={programIsLoading && !program?.id}
				fetchData={fetchProgram}
				fetchEpisodes={fetchEpisodes}

				onPublishWindowUpdated={onPublishWindowUpdated}
				onPublishWindowDeleted={onPublishWindowDeleted}
				onPublishWindowCreated={onPublishWindowCreated}
				onLicenseUpdated={onLicenseUpdated}

				selectProgram={setSelectedProgram}
				onClose={() => {
					setSelectedProgram(null);
					setProgram({});
				}}
				readonly={readonly}
				licenseTypes={licenseTypes}
			/>
		</>
	);
}

@decoratorUIActions(itemActions)
export default class ProgramsApp extends React.Component {
	render() {
		return (
			<ListApp
				{...this.props}
			/>
		);
	}
}

function transformFilters(filters) {
	const transformed = { ...filters };

	switch (filters._premiere) {
		case null:
			break;
		case "currentandupcoming":
			transformed.includeExpiredLicenses = false;
			break;
		case "upcoming":
			transformed.start = moment().format("YYYY-MM-DD");
			transformed.end = moment().add(1, 'y').format("YYYY-MM-DD");
			transformed.premieres = true;
			transformed.includeExpiredLicenses = false;
			break;
		default:
			transformed.start = moment(filters._premiere).startOf("month").format("YYYY-MM-DD");
			transformed.end = moment(filters._premiere).endOf("month").format("YYYY-MM-DD");
			transformed.premieres = true;
			break;
	}

	if (filters.monetizationModel === "hvod") {
		delete transformed.dynamicHvod;
	}
	if (filters.monetizationModel === "svod") {
		delete transformed.dynamicSvod;
	}

	return transformed;
}
import cloneDeep from 'lodash/cloneDeep'
import alt from '../../core/services/alt'
import moment from 'moment'

import Const from '../../core/constants'

import Actions from './actions'
import EditorActions  from '../../components/editor/actions'

import appConfig from 'config'

class VodStore {

	constructor() {
		this.isLoading = 0;

		this.item = {
			package: {},
		};

		this.list = {
			packages: {
				items: [],
				nextPageUrl: null,
				numberOfItems: 0,
				latestRequestTime: null,
			},
			deliveries: {
				items: [],
				nextPageUrl: null,
				numberOfItems: 0,
				latestRequestTime: null,
			},
			services: {
				items: [],
				nextPageUrl: null,
				numberOfItems: 0,
				latestRequestTime: null,
			},
			seriesPackages: {
				items: [],
				nextPageUrl: null,
				numberOfItems: 0,
				latestRequestTime: null,
			},
			pastNeedingAttention: {
				items: [],
				nextPageUrl: null,
				numberOfItems: 0,
				latestRequestTime: null,
			},
		};

		const storedServiceId = localStorage.getItem("vodServiceId");
		this.filters = {
			searchText: "",
			filter: {
				serviceId: parseInt(storedServiceId || appConfig && appConfig.features && appConfig.features.defaultVodServiceId || 1),
				status: "",
				premiere: "lastsent",
				startBefore: null,
				startAfter: null,
				endBefore: null,
				endAfter: null,
				searchPackagesAcrossAllServices: false,
				attentionPastOrUpcoming: null,
			},

			pageSize: 50,
			// orderBy: "startpublish",
			orderBy: "publishStartDescending",
			// orderBy: "name",
			// packageId={packageId}
			// mediaId={mediaId}
			// programId={programId}
		};
		this._prevFilter = null;
		setDependentFilters(this.filters);

		this.bindListeners({
      		onRequestFailed: Actions.REQUEST_FAILED,

			onFilter: Actions.FILTER,
			onSearch: Actions.SEARCH,

			onFetchItems: [Actions.FETCH_ITEMS, Actions.FETCH_SERIES_PACKAGES],
			onPageItems: Actions.PAGE_ITEMS,
			onItemsUpdated: Actions.ITEMS_UPDATED,
			onPackageAction: [Actions.BLOCK, Actions.UNBLOCK, Actions.UNPUBLISH, Actions.REPUBLISH, Actions.RESEND, Actions.UPDATE],
			onRefreshListItem: Actions.REFRESH_LIST_ITEM,
			onToggleDeliveryItems: Actions.TOGGLE_DELIVERY_ITEMS,
			onDeliveryItemsLoaded: Actions.DELIVERY_ITEMS_LOADED,

			onFetchItem: Actions.FETCH_ITEM,
			onItemLoaded: Actions.ITEM_LOADED,

			onUnmount: Actions.UNMOUNT,

			// Custom actions

		});
	}

	onRequestFailed() {
		this.isLoading--;
	}

	/* Filters */
	onSearch(searchText) {
		if (searchText === "") {
			this.filters.filter = this._prevFilter;
		} else {
			if (!this._prevFilter) {
				this._prevFilter = cloneDeep(this.filters.filter);
			}
			this.filters.filter = {
				serviceId: this.filters.filter.serviceId,
				searchPackagesAcrossAllServices: this.filters.filter.searchPackagesAcrossAllServices,
			};
		}
		this.filters.searchText = searchText;
		this.list.packages.items = [];
		setDependentFilters(this.filters, searchText);
	}

	onFilter(payload) {
		const filterParams = Object.keys(this.filters.filter).length
			? this.filters.filter
			: this._prevFilter;

		this.filters.filter = {
			...filterParams,
			...payload,
		};

		if (payload.searchPackagesAcrossAllServices === undefined && payload.serviceId === undefined) {
			this.filters.searchText = "";
			this.filters.filter.searchPackagesAcrossAllServices = false;
			this._prevFilter = null;
		}
		this.list.packages.items = [];
		setDependentFilters(this.filters, this.filters.searchText);
	}


	/* Items */
	onFetchItems({ entity, payload}) {
		this.isLoading++;
		const l = this.list[entity];
		l.items = [];
		l.nextPageUrl = null;
		l.numberOfItems = 0;
		l.latestRequestTime = Date.now();
	}

	onPageItems({ entity, url }) {
		this.isLoading++;
		const l = this.list[entity];
		l.nextPageUrl = null;
	}

	onItemsUpdated({ datastore, items, appendToExistingItems, numberOfItems, nextPageUrl, requestTime }) {
		const l = this.list[datastore];

		if(l.latestRequestTime > requestTime) {
			console.log("Ignoring result with %s items since there have been newer requests.", numberOfItems);
		}
		else {
			l.items = appendToExistingItems ? l.items.concat(items) : items;
			l.nextPageUrl = nextPageUrl;
			l.numberOfItems = numberOfItems;
			// if (datastore === "deliveries") {
			// 	hideIgnoredDeliveries(l, numberOfItems);
			// }
		}

		this.isLoading--;
	}

	/* Item */
	onFetchItem() {
		this.isLoading++;
	}

	onItemLoaded({ entity, model }) {
		this.item[entity] = model;
		const l = this.list[entity];
		this.isLoading--;
	}

	onPackageAction(id) {
		this.isLoading++;
	}

	onRefreshListItem(refreshedItem) {
		const p = this.list.packages;
		const index = p.items.findIndex(item => refreshedItem.id === item.id);
		p.items[index] = refreshedItem;
		this.isLoading--;
	}

	onToggleDeliveryItems({ deliveryId }) {
		const delivery = this.list.deliveries.items.find(d => d.id === deliveryId);
		if (delivery) {
			delivery.showDeliveryItems = !delivery.showDeliveryItems;
		}
	}
	
	onDeliveryItemsLoaded({ deliveryId, items }) {
		const delivery = this.list.deliveries.items.find(d => d.id === deliveryId);
		if (delivery) {
			delivery.deliveryItems = items;
		}
	}

	onUnmount() {
		this.isLoading = 0;

		this.item = {
			package: {},
		};

		this.list.deliveries = {
			items: [],
			nextPageUrl: null,
			numberOfItems: 0,
			latestRequestTime: null,
		};
	}

	// HELPERS
	// getItem(entity, id) {
	// 	const store = this[`${entity}s`];
	// 	return store.find(e => e.id === id);
	// }

	// removeItem(entity, id) {
	// 	const store = this[`${entity}s`];
	// 	const index = store.findIndex(e => e.id === id);
	// 	store.splice(index, 1);
	// }

	// persistToStore(store, model) {
	// 	if(!this[store]) {
	// 		return false;
	// 	}

	// 	const index = this[store].findIndex(m => (m.id === model.id));

	// 	if(index >= 0) {
	// 		this[store][index] = model;
	// 	} else {
	// 		this[store].push(model);
	// 	}

	// 	return true;
	// }

	// updateStore(store, model) {
	// 	if(!this[store]) {
	// 		return false;
	// 	}

	// 	const index = this[store].findIndex(m => (m.id === model.id));

	// 	if(index < 0) {
	// 		return false;
	// 	}

	// 	this[store][index] = model;
	// 	return true;
	// }
}
export default alt.createStore(VodStore, "[Vod]Store");

/* Helpers */
function setDependentFilters(filters, searchText) {
	let startBefore, startAfter, endBefore, endAfter, orderBy;
	const now = moment();

	const format = "YYYY-MM-DD HH:mm:ss";
	switch (filters.filter.premiere) {
		case "upcoming":
			startAfter = now.format(format);
			orderBy = "publishStart";
			break;
		case "current":
			startBefore = now.format(format);
			endAfter = now.format(format);
			orderBy = "publishStartDescending";
			break;
		case "past":
			endBefore = now.format(format);
			orderBy = "publishEndDescending";
			break;
		case "lastsent":
			orderBy = "lastsent";
			break;
		default:
			orderBy = "publishStart";
			break;
	}

	if (filters.searchText && filters.searchText.length) {
		startBefore = startAfter = endBefore = endAfter = null;
	}

	if (filters.filter.status === "attention") {
		if (filters.filter.attentionPastOrUpcoming === "past") {
			startAfter = null;
			startBefore = now.format(format);
			orderBy = "publishStartDescending";
		} else {
			startAfter = now.clone().add(1, "days").format(format);
			startBefore = null;
			orderBy = "publishStart";
		}
	}

	filters.filter.startAfter = startAfter;
	filters.filter.endBefore = endBefore;
	filters.filter.startBefore = startBefore;
	filters.filter.endAfter = endAfter;
	filters.orderBy = orderBy || filters.orderBy;
	if (searchText && searchText !== "") {
		filters.orderBy = "name";
	}
}

function hideIgnoredDeliveries(list, numberOfItems) {
	let filteredItemsCount = 0;
	const filtered = list.items.filter(d => d.status !== "Ignored" || d.statusDescription !== "Not the latest delivery");
	filteredItemsCount = list.items.length - filtered.length;
	list.items = filtered;
	list.numberOfItems = numberOfItems - filteredItemsCount;
}
import * as StarAPI from '../../../apis/star'
import { useQuery } from '@tanstack/react-query'

const useStarFileFormats = (enabled: boolean = true) => {
    const filters = {
        pageSize: 1000,
    };
    return useQuery(
        ["star", "fileformats", filters],
        () => StarAPI.fetchFileformats(filters),
        { enabled }
    );
};

export default useStarFileFormats;
import { browserHistory } from 'browserHistory'

import { CONTENT, MENU } from '../../../components/actions'

export default {
	targetComponent: "Content",
	actions: [
		{
			title: "Edit",
			icon: "create",
			type: CONTENT,
			method: (data, sourceProps) => {
				const customerRoute = sourceProps.route.customer ? `${sourceProps.route.customer}/` : "";
				const id = data.id ?? data.program?.id;
				browserHistory.push({
					pathname: `/reviews/${customerRoute}programs/${id}/edit`,
					state: {
						modal: true,
						returnTo: `${sourceProps.location.pathname}${sourceProps.location.search}`,
					},
				});
			},
			hidden: (data, sourceProps) => false,
			disabled: (data, sourceProps) => false,
		},
		{
			title: "Edit",
			icon: "create",
			type: MENU,
			method: (data, sourceProps) => {
				const customerRoute = sourceProps.route.customer ? `${sourceProps.route.customer}/` : "";
				const id = data.id ?? data.program?.id;
				browserHistory.push({
					pathname: `/reviews/${customerRoute}programs/${id}/edit`,
					state: {
						modal: true,
						returnTo: `${sourceProps.location.pathname}${sourceProps.location.search}`,
					},
				});
			},
			hidden: (data, sourceProps) => false,
			disabled: (data, sourceProps) => false,
		},
		{
			title: "Show reviews",
			icon: "rate_review",
			type: MENU,
			method: (data, sourceProps) => {
				const customerRoute = sourceProps.route.customer ? `${sourceProps.route.customer}/` : "";
				const id = data.id ?? data.program?.id;
				browserHistory.push({
					pathname: `/reviews/${customerRoute}reviews`,
					query: {
						programId: id,
					},
					state: {
						returnTo: `${sourceProps.location.pathname}${sourceProps.location.search}`,
					},
				});
			},
			hidden: (data, sourceProps) => false,
			disabled: (data, sourceProps) => false,
		}
	]
}
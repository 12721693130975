import React from 'react'
import Moment from 'moment'
// import keydown from 'react-keydown'
import debounce from 'lodash/debounce'
import { ScrollContainer } from 'react-router-scroll';

import App from '../../../components/app'
import { Info } from '../../../components/comet'
import Main from '../../../components/ui/main'

import Header from './header'
import List from '../shared/list'

import Actions from '../actions'
import Store, { START_FILTER } from '../store'

import { getSearchPayload, getInfo } from '../shared/utils'
import appConfig from 'config'
import Styles from './app.css'

const MODULE = "acq"
const ENTITY =  "license"
const PARENT_ENTITY =  "contract"

const TEXT_HEADING = "Licenses"
const TEXT_EMPTY = "No licenses? Try adjusing your list filters, if possible."
const TEXT_CREATE = "Create license"
const ICON_CREATE = "add"

export default class ListApp extends React.Component {

	constructor(props) {
		super(props);

		this.onChange = this.onChange.bind(this);
		this.onFilter = this.onFilter.bind(this);
		this.handleFilter = this.handleFilter.bind(this);
		this.debouncedFilter = debounce(this.handleFilter, 300);
		this.handleCreateNew = this.handleCreateNew.bind(this);
		this.loadMore = this.loadMore.bind(this);

		this.scroll = null;
		this.contractId = props.params.id;
		if (this.contractId) {
			Actions.updateContractId(this.contractId);
		}

		this.state = {
			...Store.getState(),
			searchText: "",
			unselectedFilter: false,
		};
	}

	componentDidMount() {
		Store.listen(this.onChange);
		if (this.contractId) {
			Actions.updateContractId(this.contractId);
			Actions.fetchItem(PARENT_ENTITY, this.contractId);
		}
		if(!this.state.licenses.length || this.contractId) {
			Actions.fetchItems(ENTITY, getSearchPayload(this.state));
		}
		if (appConfig.features.acqC70 && !this.state.providers.length) {
			Actions.fetchItems("provider");
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if(nextProps.params.id !== this.contractId) {
			this.contractId = nextProps.params.id;
			Actions.updateContractId(this.contractId);
			Actions.filter(this.contractId ? "" : START_FILTER, "filter");

			if(this.contractId) {
				Actions.fetchItem(PARENT_ENTITY, this.contractId);
			}
			else {
				Actions.clearItem(PARENT_ENTITY);
			}
		}

		Actions.fetchItems(ENTITY, getSearchPayload(this.state));

		if(this.scroll) {
			this.scroll.scrollTop = 0; // Scroll list to top to prevent automatic paging
		}
	}

	// componentWillReceiveProps({ keydown }) {
	// 	if (keydown.event) {
	// 		console.log( keydown.event.which );
	// 		this.onSearch(keydown.event);
	// 	}
	// }

	componentWillUnmount() {
		Actions.updateContractId("");
		Actions.unmount();
		Store.unlisten(this.onChange);
	}

	onChange(state) {
		this.setState(state);
	}

	onFilter(type, e) {
		if(type === "search") {
    		e.persist();
    		this.debouncedFilter(type, e);
		}
		else {
			this.handleFilter(type, e);
		}
  	}

	handleFilter(type, event) {
		if(this.scroll) {
			this.scroll.scrollTop = 0; // Scroll list to top to prevent automatic paging
		}

		const { name, value } = event.target;
		Actions[type](value, name);
		Actions.fetchItems(ENTITY, getSearchPayload(this.state));

		this.setState({
			searchText: type !== "search" ? "" : value,
			unselectedFilter: type !== "search" ? false : value.length > 0,
		});

		// Force lazyload to load visible images
		window.dispatchEvent(window.customLazyEvent);
	}

	loadMore() {
		const url = this.state[`${ENTITY}sNextPageUrl`];
		if(url) {
			Actions.pageItems(ENTITY, url);
		}
	}

	handleCreateNew() {
		const { contract = {} } = this.state.item;
		const providerId = contract.provider && contract.provider.id;
		Actions.edit({
			pathname: "/acq/licenses/create",
			search: `?contractId=${this.contractId}&providerId=${providerId}`,
			returnTo: `${this.props.location.pathname}${this.props.location.search}`,
		});
	}

	shouldUpdateScroll(prevRouterProps, currentRouterProps) {
		return currentRouterProps.location.action === "POP";
	}

	render() {
		const { isLoading, excelLoading, searchText: componentSearchText, filters, unselectedFilter, item, licenses, licensesNextPageUrl, licensesIsLoading, providers } = this.state;
		const searchText = componentSearchText || filters.searchText; // TODO!: We should probably only have one searchText since this will make people go mad.
		const { contract } = item;

		const title = contract && contract.id ? `${TEXT_HEADING} in contract "${contract.name}"`: TEXT_HEADING;

		return (
			<App
				name={`c6-${MODULE}-${ENTITY}s c6-list`}
				layout="grid"
				isLoading={isLoading || excelLoading}>
				<Header
					title={title}
					searchText={searchText}
					filters={filters}
					unselectedFilters={unselectedFilter || !!searchText.length}
					handleFilter={this.onFilter}
					handleCreateNew={this.handleCreateNew}
					contract={contract}
					providers={providers}
					excelLoading={excelLoading}
				/>
				<ScrollContainer scrollKey={`c6-${MODULE}-${ENTITY}s`} shouldUpdateScroll={this.shouldUpdateScroll}>
					<Main inputRef={el => this.scroll = el}>
						<List
							items={licenses}
							isLoading={isLoading || licensesIsLoading}
							searchText={searchText}
							filters={filters}
							textEmpty={TEXT_EMPTY}
							hasMore={!!licensesNextPageUrl}
							loadMore={this.loadMore} />
					</Main>
				</ScrollContainer>
				<Info>{getInfo(this.props.location, this.state, ENTITY)}</Info>
			</App>
		);
	}
}
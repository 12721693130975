import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Item, ItemGroup } from '../../../components/list/listItems'
import Empty from '../../../components/list/empty'

import Content from './listItem'

export default class List extends Component {

	static propTypes = {
		items: PropTypes.array.isRequired,
		role: PropTypes.object.isRequired,
		module: PropTypes.object.isRequired,

		handleResourceToggle: PropTypes.func.isRequired,
		isLoading: PropTypes.bool.isRequired,
		textEmpty: PropTypes.string,
	}

	render() {
		const {
			isLoading,

			items,
			role,
			module,
			handleResourceToggle,

			textEmpty = "Sorry, could not find any items.",
		} = this.props;

		let itemGroup = null;
		if(module && module.services && module.services.length && items && items.length) {
			itemGroup = module.services.map(service => {
				const serviceItems = items.filter(i => i.serviceId === service.id);
				return (
					<ItemGroup key={service.id} title={service.displayName}>
						{ this.renderItems(serviceItems, role, handleResourceToggle) }
					</ItemGroup>
				);
			});
		}
		else {
			itemGroup = <Empty isLoading={isLoading}>{textEmpty}</Empty>;
		}

		const className = !(items && items.length) ? "center c6-color-light" : "";
		return <div className={className}>{itemGroup}</div>;
  	}

	renderItems(items, role, handleResourceToggle) {
		return items.map(item => (
			<Item key={item.id}>
				<Content
					role={role}
					module={module}
					item={item}

					handleResourceToggle={handleResourceToggle} />
			</Item>
		));
	}
}
import React from 'react'
import Moment from 'moment'
import { Link } from 'react-router'
import appConfig from 'config'

export function getSearchPayload({ filters = {} }, extra) {
	const { filter, searchText, ...rest } = filters;
	const { searchWithoutFrom, ...filterRest } = filter;

	if (searchText) {
		return {
			...extra,
			searchText,
			from: searchWithoutFrom ? null : "upcoming",
		};
	}

	const payload = {
		...rest,
		...filterRest,
		...extra,
	};

	return payload;
}

export function getPremiere() {
	return [
		{ key: "", text: "Any", description: "" },
		{ key: "true", text: "Yes", description: "Only include premieres for the selected period." },
	];
}

export function getFrom() {
	const premieres = [];

	let month = Moment().startOf("month").subtract(1, "month");
	for (let i = 0; i <= 7; i++) {
		premieres.push({
			key: month.format("YYYY-MM-DD"),
			text: month.format("MMMM"),
			// description: `Program premieres in ${month.format("MMMM")}.`,
		});
		month = month.add(1, "months").startOf("month");
	}
	premieres.push({
		key: "upcoming",
		text: "In the future",
		// description: "Program has an upcoming premiere sometime in the future.",
	});
	return premieres;
}

export function getTypes() {
	return [
		{
			key: "single,episode",
			text: "All",
			description: ""
		},
        {
            key: "single",
            text: "Single",
            description: "Display Movies."
        },
		{
			key: "episode",
			text: "Episode",
			description: "Display Episodes."
		},
	];
}

export function getProvider(){
    return [
        {
			key: "",
			text: "All",
			description: ""
		},
        {
            key: "TV4",
            text: "TV4",
            description: "Display content from TV4"
        },
        {
            key: "CMore",
            text: "C More",
            description: "Display content from C MORE"
        },
        {
            key: "SFKids",
            text: "SF Kids",
            description: "Display content from SF Kids"
        },
        {
            key: "MTVFi",
            text: "MTV Fi",
            description: "Display content from MTV Fi"
        }
    ]
}

export function getPriority(){
    return [
        {
			key: "",
			text: "Any",
			description: ""
		},
		{
            key: "Inherited",
            text: "Inherited",
            description: "Display content with inherited as priority"
        },
        {
            key: "Low",
            text: "Low",
            description: "Display content with low as priority"
        },
        {
            key: "Normal",
            text: "Normal",
            description: "Display content with Normal as priority"
        },
        {
            key: "High",
            text: "High",
            description: "Display content with High as priority"
        },
        {
            key: "Panic",
            text: "Panic",
            description: "Display content with Panic as priority"
        }
    ]
}

export function getDistributor(){
    return [
        {
			key: "",
			text: "Any",
			description: ""
		},
		{
			key: "Test",
			text: "Test",
			description: ""
		}
    ]
}

export function getOrder(){
    return [
		{
			key: "title",
			text: "Title",
			description: ""
		},
		{
			key: "premiere",
			text: "Premiere",
			description: ""
		},
		{
			key: "priority",
			text: "Priority",
			description: ""
		}
    ]
}

export function getFilter(){
    return [
        {
			key: "",
			text: "Any",
			description: ""
		},
		{
			key: "true",
			text: "Planned",
			description: ""
		},
		{
			key: "false",
			text: "Not Planned",
			description: ""
		}
    ]
}

export function getAttention(){
    return [
		{
			key: "",
			text: "Any",
			description: "",
		},
		{
			key: "true",
			text: "Yes",
			description: ""
		}
	]
}

export function getRegion() {
	return [
		{
			key: "",
			text: "Any",
			description: ""
		},
		{
			key: "se",
			text: "SE",
			description: ""
		},
		{
			key: "no",
			text: "NO",
			description: ""
		},
		{
			key: "dk",
			text: "DK",
			description: ""
		},
		{
			key: "fi",
			text: "FI",
			description: ""
		}
	]
}
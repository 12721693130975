import React from 'react'
import moment from 'moment'

import { Filter, Left, Right } from '../../../components/filter'

const Preview = (props) => {
	const {
		model,
	} = props;

	const {
		programId,
		programTitle,
		source,
		created,
		imdbId,
		link,
	} = model;

	if (!programId || !programTitle?.length) {
		return null;
	}

	return (
		<Filter className="c6-reviews-program-preview">
			<Left>
				<div>
					<h1>{programTitle}</h1>
					<p>{source?.name}{source?.name && source?.language && ", "}{source?.language}</p>
					<p>{created && `Created ${moment(created).format("Do MMM YYYY")}`}</p>
				</div>
			</Left>
			<Right>
				<dl>
					{getImdbLink(imdbId)}
				</dl>

				{getReviewLink(link)}
			</Right>
		</Filter>
	);
};

export default Preview;

// HELPERS
function getImdbLink(imdbId) {
	const imdbLink = imdbId
		? <a href={`http://imdb.com/title/${imdbId}`} className="c6-link" target="_blank">{imdbId}</a>
		: null;//<a href={`http://imdb.com/find?q=${model.originalTitle}&s=all`} className="c6-link" target="_blank">Search</a>;

	if (imdbLink) {
		return [
			<dt key="label">IMDb</dt>,
			<dd key="value">{imdbLink}</dd>
		];
	}

	return null;
}

function getReviewLink(link) {
	return link ? <a className="c6-link" target="_blank" href={link}>Review link</a> : null;
}
import React from 'react'
// import keydown from 'react-keydown'
import debounce from 'lodash/debounce'
import { ScrollContainer } from 'react-router-scroll'
import moment from 'moment'

import { getRouteName } from '../../../components/comet'
import App from '../../../components/app'
import { Info } from '../../../components/comet'
import Main from '../../../components/ui/main'

import Header from './header'
import List from '../shared/list'

import Actions from '../actions'
import Store from '../store'

import { getSearchPayload } from '../shared/utils'

import './app.css'

import appConfig from 'config'

const MODULE = "vod"
const DATASTORE = "packages"
const ENTITY = "package"
// const SERVICE = appConfig && appConfig.features && appConfig.features.vodServiceName || "TV4 Play"
// const TEXT_HEADING = `Packages for ${SERVICE}`

export default class ListApp extends React.Component {

	constructor(props) {
		super(props);
		
		this.debouncedFilter = debounce(this.handleFilter, 500);

		this.scroll = null;

		this.state = {
			...Store.getState(),
			searchText: "",
			unselectedFilter: false,
		};
	}

	componentDidMount() {
		Store.listen(this.onChange);
		const l = this.state.list[DATASTORE];

		this.updateService(this.props, true);

		if (!l.items.length) {
			Actions.fetchItems(DATASTORE, getSearchPayload(this.state, this.serviceId));
		}

		if (this.serviceId === undefined && !this.state.list["services"].items.length) {
			Actions.fetchItems("services", { filter: "enabled", orderBy: "name" });
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.updateService(nextProps);

		if (this.serviceId === undefined && !this.state.list["services"].items.length) {
			Actions.fetchItems("services", { filter: "enabled", orderBy: "name" });
		}
	}

	componentWillUnmount() {
		Actions.unmount();
		Store.unlisten(this.onChange);
	}

	updateService = ({ routes }, skipFetch) => {
		const serviceRoute = routes.find(r => r.serviceId !== null && r.serviceId !== undefined) || {};
		const { serviceId, serviceName } = serviceRoute;

		if (serviceId !== this.serviceId && !skipFetch) {
			Actions.fetchItems(DATASTORE, getSearchPayload(this.state, serviceId));
		}

		this.serviceId = serviceId;
		this.serviceName = serviceName;
	}

	onChange = (state) => {
		this.setState(state);
	}

	onFilter = (type, e) => {
		if(type === "search") {
    		e.persist();
    		this.debouncedFilter(type, e);
		}
		else {
			this.handleFilter(type, e);
		}
  	}

	handleFilter = (type, event) => {
		if(this.scroll) {
			this.scroll.scrollTop = 0; // Scroll list to top to prevent automatic paging
		}

		const { name, value } = event.target;
		Actions[type](value, name);
		const payload = getSearchPayload(this.state, this.serviceId);
		Actions.fetchItems(DATASTORE, payload);

		this.setState({
			searchText: type !== "search" ? "" : value,
			unselectedFilter: type !== "search" ? false : value.length > 0,
		});

		if (name === "status" && value === "attention") {
			getPastProgramsNeedingAttention(payload);
		}

		// Force lazyload to load visible images
		window.dispatchEvent(window.customLazyEvent);
	}

	handleSelectService = (e, item) => {
		const serviceId = item && item.props && item.props.value || e.target.value;
		this.handleFilter("filter", { target: { name: "serviceId", value: serviceId } });
		localStorage.setItem("vodServiceId", serviceId);
	}

	loadMore = () => {
		const l = this.state.list[DATASTORE];
		if(l.nextPageUrl) {
			Actions.pageItems(DATASTORE, l.nextPageUrl);
		}
	}

	shouldUpdateScroll(prevRouterProps, currentRouterProps) {
		return currentRouterProps.location.action === "POP";
	}

	render() {
		const list = this.state.list[DATASTORE];
		const { isLoading, searchText: componentSearchText, filters, nextPageUrl, unselectedFilter } = this.state;
		const searchText = componentSearchText || filters.searchText; // TODO!: We should probably only have one searchText since this will make people go mad.

		const pastNeedingAttention = filters.filter.status === "attention" && filters.filter.attentionPastOrUpcoming !== "past" ? this.state.list.pastNeedingAttention : null;

	    return (
			<App
				name={`c6-${MODULE}-${DATASTORE} c6-list`}
				layout="grid"
				isLoading={!!isLoading}
			>
				<Header
					searchText={searchText}
					filters={filters}
					unselectedFilters={unselectedFilter || !!searchText.length}
					handleFilter={this.onFilter}
					handleSelectService={this.handleSelectService}
					services={this.state.list.services.items}
					serviceId={this.serviceId}
					serviceName={this.serviceName}
				/>
				<ScrollContainer scrollKey={`c6-${MODULE}-${DATASTORE}`} shouldUpdateScroll={this.shouldUpdateScroll}>
					<Main inputRef={el => this.scroll = el} padding={true}>
						<List
							items={list.items}
							isLoading={isLoading}
							searchText={searchText}
							filters={filters}
							hasMore={!!list.nextPageUrl}
							loadMore={this.loadMore}
							handleFilter={this.onFilter}
							pastNeedingAttention={pastNeedingAttention}
						/>
					</Main>
				</ScrollContainer>
				<Info>
					{getInfo(this.props.location, list)}
				</Info>
			</App>
		);
	}
}

// HELPERS
function getInfo(location, list) {
	const items = list.items.length ? `- displaying ${list.items.length} of ${list.numberOfItems}` : "";

	return `${getRouteName(location)} ${items}`;
}

function getPastProgramsNeedingAttention(payload) {
	delete payload.startAfter;
	Actions.fetchItems(DATASTORE, {
		...payload,
		validationStatus: "failed,warning",
		startBefore: moment().format("YYYY-MM-DD HH:mm:ss"),
		pageSize: 1,
	}, "pastNeedingAttention");
}
import { useQuery } from '@tanstack/react-query';
import * as CMSAPI from '../../../apis/cms'

const useCMSTemplate = (
    entity: string,
    module?: string,
    enabled: boolean = true,
) => {
    return useQuery(
        ["cms", "template", entity, module],
        () => CMSAPI.fetchCMSTemplate({ _entity: entity, _module: module }),
        { enabled }
    );
};

export default useCMSTemplate;
import * as CMSAPI from '../../../apis/cms'
import { useQuery } from '@tanstack/react-query'

const useCMSSeries = (id: string | number) => {
    const filters = {
        _entity: "serieslist",
        id,
    };
    return useQuery(
        ["cms", "series", filters],
        () => CMSAPI.fetchCMSEntity(filters),
        { enabled: id !== null }
    );
};

export default useCMSSeries;
import React from 'react'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import { Link } from 'react-router'
import { browserHistory } from 'browserHistory'
import moment from 'moment'

import { Filter, Left, Right } from '../../../components/filter'
import Button from '../../../components/ui/controls/button'
import Const from '../../../core/constants'
import * as Alert from '../../../core/services/alert'

import Actions from '../actions'

const Header = ({ scheduleList, scheduleListIsLoading, schedules, approved, hasSelectedItems, updateInProgress }) => {

	const approveSchedule = () => {
		const ignore = !hasSelectedItems;
		if (scheduleList.length === 1) {
			Actions.approveSchedule(ignore, scheduleList[0]);
		} else {
			Actions.approveMany(ignore, scheduleList);
		}
	}

	const channelName = scheduleList[0] && scheduleList[0].channel.name || "";

	let title = "";
	if (scheduleListIsLoading) {
		title = "/ Loading...";
	} else if (scheduleList.length === 1) {
		title = `/ ${moment(scheduleList[0].day).format(Const.PERIOD_FORMAT)} / ${channelName}`;
	} else if (scheduleList.length > 1) {
		const dateRange = `${moment(scheduleList[0].day).format(Const.PERIOD_FORMAT)} to ${moment(scheduleList[scheduleList.length - 1].day).format(Const.PERIOD_FORMAT)}`;
		title = `/ ${dateRange} / ${channelName}`;
	}
	
	const allIgnored = approved && !scheduleList.some(schedule => schedule.broadcasts.some(broadcast => broadcast.status === "Confirmed"));
	const approveButton = getApproveButton(approved, hasSelectedItems, approveSchedule, allIgnored);
	const allItemsSelected = scheduleList.length > 0 && scheduleList.every(schedule => schedule.broadcasts.every(change => change.selected || change.status === "Confirmed"));
	const modified = scheduleList.length === 1 && moment(scheduleList[0].modified); // TODO: Handle multiple schedules
	const isoModified = modified && modified.format(Const.DATE_TIME_FORMAT);

	let status = "";
	if (allIgnored) {
		status = "Ignored ";
	} else if (approved) {
		status = "Published ";
	} else if (modified) {
		status = "Last changes received ";
	}

	return (
		<Filter>
			<Left>
				<h1><Link to={'/schedules/changes'} onClick={e => handleBackClick(e, schedules)}>Schedule changes</Link> {title}</h1>
				<p>
					{!updateInProgress && !scheduleListIsLoading && status}
					{!updateInProgress && !scheduleListIsLoading && modified && <time title={isoModified} dateTime={isoModified}>{modified.fromNow()}</time>}
				</p>
				{scheduleList.length > 0 && !approved && !updateInProgress && (
					<div className="select-all-wrapper">
						<FormControlLabel
							control={
								<Checkbox
									checked={allItemsSelected}
									onChange={() => Actions.selectAll()}
								/>
							}
							label="Select all"
						/>
					</div>
				)}
			</Left>
			{scheduleList.length > 0 && !updateInProgress && (
				<Right>
					<Button
						type="clipboard"
						title="Copy EVERYTHING"
						onClick={() => copyToClipboard(channelName, false, true)}
						disabled={false}
					/>
					<Button
						type="clipboard"
						title={hasSelectedItems ? "Copy selected changes" : "Copy changes"}
						onClick={() => copyToClipboard(channelName, hasSelectedItems)}
						disabled={approved}
					/>
					{approveButton}
				</Right>
			)}
		</Filter>
	);
};

export default Header;

function handleBackClick(e, schedules) {
	if (schedules.length) {
		e.preventDefault();
		browserHistory.goBack();
	}
}

function copyToClipboard(channelName, hasSelectedItems, takeAll) {
	// Create a holding element since we can't add multiple ranges into our selection in anything else than Firefox
	const changes = document.createElement("div");
	changes.style.backgroundColor = "#fff";
	changes.style.position = "absolute";

	const lists = document.querySelectorAll(".changes-list");
	lists.forEach(list => {
		const selector = `.change${takeAll ? "" : ":not(.confirmed):not(.clipboard-hide)"}`;
		const changeNodes = list.querySelectorAll(selector);
		if (changeNodes.length === 0) {
			return;
		}

		// Title
		let firstChild = list.children.item(0);
		if (!firstChild || firstChild.tagName !== "H1") {
			// List has no header = we take title from top header
			const headerText = document.querySelector(".c6-filter h1").innerText;
			const headerParts = headerText.split("/");
			const newTitle = document.createElement("h1");
			newTitle.innerText = `${headerParts[1].trim()}, ${headerParts[2].trim()}`;
			changes.appendChild(newTitle);
		} else {
			const headerText = firstChild.innerText;
			const newTitle = document.createElement("h1");
			newTitle.innerText = `${headerText}, ${channelName}`;
			changes.appendChild(newTitle);
		}

		// Collect the changed rows
		changeNodes.forEach(el => {
			changes.appendChild(el.cloneNode(true));
		});

		// New line
		changes.appendChild(document.createElement("br"));
	});

	// Alter the temporary nodes with localized text and remove unwanted elements
	const tempChangeNodes = Array.from(changes.querySelectorAll(".change"));
	tempChangeNodes.forEach(el => {
		const titleEl = el.querySelector("h1");
		titleEl.style.cssText = "color: #333; font-size: 14px;";

		// Add season and episode number
		const seasonNumber = el.querySelector(".season-number");
		if (seasonNumber && !!seasonNumber.innerText.length) {
			titleEl.innerHTML += ` S${seasonNumber.innerText}`;
		}
		const episodeNumber = el.querySelector(".episode-number");
		if (episodeNumber && !!episodeNumber.innerText.length) {
			titleEl.innerHTML += ` E${episodeNumber.innerText}`;
		}
		
		// Add status text
		if (el.classList.contains("insert")) {
			titleEl.innerHTML += " (Nyinsatt program)";
		}
		if (el.classList.contains("delete")) {
			titleEl.innerHTML += " (Utgår)";
		}
		if (el.classList.contains("update")) {
			const oldTime = el.querySelector(".status").innerText;
			const timeEl = el.querySelector(".time");
			timeEl.style.color = "#333";
			titleEl.innerHTML += ` (Ny tid, flyttad från ${oldTime})`;
		}

		// Remove elements with .clipboard-hide class
		const elementsToRemove = Array.from(el.querySelectorAll(".clipboard-hide"));
		elementsToRemove.forEach(e => {
			e.parentNode.removeChild(e);
		})
	});

	document.body.appendChild(changes);

	// Clear current selection if there is one and copy our changes
	const selection = window.getSelection();
	selection.removeAllRanges();

	const range = document.createRange();
	range.selectNode(changes);
	selection.addRange(range);

	document.execCommand("copy");

	// Clear selection again so its not visible for the user and clean up the holding element
	selection.removeAllRanges();
	changes.remove();

	Alert.displayAlert("success", `Successfully copied ${hasSelectedItems ? "selected " : ""}changes.`);
}

function getApproveButton(approved, hasSelectedItems, approveSchedule, allIgnored) {
	let icon = "approve";
	let title = "";
	let confirmTitle = "";

	if (allIgnored) {
		title = "Ignored";
	} else if (approved) {
		title = "Published";
	} else if (hasSelectedItems) {
		title = "Publish selected, ignore others";
		confirmTitle = "Publish selected changes and ignore the others?";
	} else {
		icon = "close";
		title = "Ignore all";
		confirmTitle = "Ignore all changes?";
	}
	return (
		<Button
			type={icon}
			title={title}
			onClick={approveSchedule}
			disabled={approved}
			confirm={confirmTitle}
		/>
	);
}
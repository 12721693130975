import React from 'react'
import PropTypes from 'prop-types'
import Preview from '../../components/assets/preview'

import Button from '../ui/controls/button'

export default class StandardContent extends React.Component {

	static propTypes = {
		title: PropTypes.string.isRequired,
		description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
		image: PropTypes.object,
		tooltip: PropTypes.string,
		action: PropTypes.object,
		isGame: PropTypes.bool
	}

	render() {
		const { title, description, image, tooltip, action, isGame } = this.props;
		const elDescription = description ? <p>{description}</p> : null;

		const elAction = action ? <p><a className="c6-link" onClick={action.onClick}>{action.title}</a></p> : null;

	    return (
			<div className="flex flex-dyn-1" title={tooltip}>
				<Preview programId={image.referenceId} imageGuid={image.guid} contentType={image.contentType} isGame={isGame} />
				<div className="c6-content">
					<h1>{title}</h1>
					{elAction}
					{elDescription}
				</div>
			</div>
	    );
  	}
}
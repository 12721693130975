import React from 'react'

import Upload from '../../upload'
import Asset from '../../assets/asset'
import AssetEditor from '../../assets/asseteditor/dialog'
import withDnDContext from '../../../core/services/withDnDContext'

import * as StarData from '../../../apis/star'
import * as Alert from '../../../core/services/alert'

import appConfig from 'config'
import './starUpload.css'

@withDnDContext
export default class StarUpload extends React.Component {

	state = {
		assetEditorOpen: false,
		isLoading: false,
	}

	componentDidMount() {
		const { value: assetGuid } = this.props;
		if (assetGuid) {
			this.fetchAsset(assetGuid);
		}
	}

	componentDidUpdate(prevProps, prevState) {
		const { value: assetGuid } = this.props;
		const { value: prevAssetGuid } = prevProps;
		if (assetGuid && assetGuid !== prevAssetGuid) {
			this.fetchAsset(assetGuid);
		}
	}

	fetchAsset = (assetGuid) => {
		this.setState({
			isLoading: true,
		});
		StarData.fetchAssetByGUID(assetGuid)
			.then(asset => {
				this.setState({
					asset,
					isLoading: false,
				});
			}, error => {
				this.setState({ isLoading: false });
				Alert.displayAlert("error", error.exceptionMessage);
			});
	}

	handleRemoveImage = () => {
		const { value: assetGuid, options, onChange } = this.props;
		if (options && typeof (options.onRemove) === "function") {
			options.onRemove(assetGuid);
		}
		onChange("");
	}

	handleUploadComplete = (response) => {
		const { asset } = response;
		const { options, onChange } = this.props;

		if (options.type && asset.type?.toLowerCase() !== options.type?.toLowerCase()) {
			Alert.displayAlert("error", `Can not add ${asset.type} to ${options.type} slot.`);
			return;
		}

		if (options && typeof (options.onComplete) === "function") {
			options.onComplete(response);
		}

		onChange(asset.assetGuid);
	}

	openAssetEditor = (assetId) => {
		this.setState({ assetEditorOpen: true });
	}

	closeAssetEditor = () => {
		this.setState({ assetEditorOpen: false });
		// Load the asset when we close the editor because we want to get rid of the "crop this"-ribbon
		this.fetchAsset(this.props.value);
	}

	render() {
		const {
			value: assetGuid,
			options,
			onChange,
			schema,
		} = this.props;
		// console.log("this.props", this.props);

		const {
			cropTypeId = 3,
			croppedThumbnailFormat,
			needsCrop = true,
			assetCategoryId,
		} = options;

		const {
			asset,
			isLoading,
		} = this.state;

		let content;
		if (
			!assetGuid
			|| (!isLoading && assetGuid && !asset?.id) // HACK: Since items in b17g-staging sometimes have asset references that do not exist
		) {
			content = (
				<Upload
					categoryName="Undefined"
					update={() => {}}
					canDrop={true}
					multipleUploads={false}
					autoUpload={true}
					disabled={false}
					assetType={options.type ?? options.assetType ?? "Image"}
					endpoint={`${appConfig.api.star}files/upload`}
					onComplete={this.handleUploadComplete}
					onDrop={asset => this.handleUploadComplete({ asset })}
					searchable={false}
				/>
			);
		}

		else if (isLoading) {
			content = (
				<div className="asset asset-loading">
					<p style={{ whiteSpace: "nowrap" }}>Loading asset...</p>
				</div>
			);
		}

		else if (asset && asset.id) {
			const crop = (asset.crops || []).find(c => c.cropTypeId === cropTypeId);

			content = (
				<React.Fragment>
					<Asset
						asset={asset}
						onClick={this.openAssetEditor}
						onRemove={this.handleRemoveImage}
						needsCrop={needsCrop && !crop}
						format={!!crop && croppedThumbnailFormat ? croppedThumbnailFormat : undefined}
					/>
					<AssetEditor
						open={this.state.assetEditorOpen}
						onClose={this.closeAssetEditor}
						assetId={asset.id}
						assetCategoryId={assetCategoryId}
						// disabled={!canEdit || !!container.approved}
					/>
				</React.Fragment>
			);
		}

		return (
			<div className="c6-widget-star-upload">
				{schema.title && <div>{schema.title}</div>}
				{content}
			</div>
		);
	}
};
import React from 'react'
import uniqBy from 'lodash/uniqBy'
import sortBy from 'lodash/sortBy'
import moment from 'moment'

import Const from '../../../core/constants'

const VERSION = "version", AUDIENCE = "audience";

export const getUnique = (group, property) => {
	return group && group.children
		? sortBy(uniqBy(group.children.map(v => v[property]), "id"), "id")
		: [];
}

export const hasSubGroups = group => {
	const firstChild = group && group.children && group.children[0];
	return firstChild ? firstChild.hasOwnProperty(VERSION) || firstChild.hasOwnProperty(AUDIENCE) : false;
}

export const getFirstSubGroup = group => {
	// const firstVersion = getUnique(group, VERSION);
	// const firstAudience = getUnique(group, AUDIENCE);

	// if(firstVersion && firstAudience) {
	// 	return group.children.find(sg => (sg.version.id === firstVersion[0].id && sg.audience.id === firstAudience[0].id));
	// }

	// TODO!: Make the handling of subGroups with either audience or version much tighter
	const firstVersion = getUnique(group, VERSION)[0];
	const firstAudience = getUnique(group, AUDIENCE)[0];

	if (firstVersion && firstAudience) {
		return group.children.find(sg => (sg.version.id === firstVersion.id && sg.audience.id === firstAudience.id));
	}
	else if (firstVersion && !firstAudience) {
		return group.children.find(sg => sg.version.id === firstVersion.id);
	}
	else if (!firstVersion && firstAudience) {
		return group.children.find(sg => sg.audience.id === firstAudience.id);
	}
	else {
		console.error("Could not get first version and/or first audience! Possible something has changed in the API");
		return group;
	}
}

export function getFormattedDate(d, filters) {
	const date = moment(d);
	return isHourlyOrMinutelyList(filters || {})
		? date.format(Const.DATE_TIME_FORMAT_HZ)
		: date.format(Const.DATE_FORMAT_TZ);
}

export function isHourlyOrMinutelyList({ instanceType }) {
	return instanceType && ["hourly", "minutely"].includes(instanceType.toLowerCase());
}

export function getListIcon({ category, autoGenerated }) {
	let icon, title;

	if (autoGenerated) {
		icon = "icon-settings";
		title = "Auto generated list";
	} else {
		switch (category?.name) {
			case "regular":
				icon = "icon-list";
				title = "Curated list";
				break;
			case "showcase":
				icon = "icon-view_carousel";
				title = "Curated highlights list";
				break;
			case "editorial":
				icon = "icon-art_track";
				title = "Editorial";
				break;
			case "widget":
				// icon = "icon-auto-fix"; // Magic wand
				icon = "icon-extension"; // Piece of a puzzle
				title = "Widget";
				break;
			case "feature":
				icon = "icon-art_track";
				title = "Single item";
				break;
		}
	}

	return <span className={`${icon} large-icon`} title={title}></span>;
}

export function getAudienceDisplayName(audience) {
	switch (audience?.toLowerCase()) {
		case "everyone": return "Everyone";
		case "guest": return "Guest only";
		case "loggedinonly": return "Logged in only";
		case "subscribers": return "Subscribers";
		case "loggedinnosubscriber": return "Logged in without subscription";
	}

	return "";
}

export function getPeriod({ from, until, prepublishDate }) {
	let isExpired = false,
		isUpcoming = false,
		className = "",
		now = moment();
	
	if (until && moment(until).isBefore(now)) {
		className = "fg-lightred c6-text-italic";
		isExpired = true;
	} else if (from && moment(from).isAfter(now)) {
		className = "c6-color-lighter c6-text-italic";
		isUpcoming = true;
	}

	const fromText = from && !ignoreBecauseYearOneOrZero(from)
		? moment(from).format(Const.PERIOD_FORMAT)
		: null;
	const untilText = (!until || isVeryFarInFuture(until))
		? null
		: moment(until).format(Const.PERIOD_FORMAT)

	const prepublish = prepublishDate && moment(prepublishDate);

	const period = (
		<span className={`c6-period ${className}`}>
			<span>
				{fromText && prepublish && (
					<span
						className="prepublish"
						title={`${prepublish.isBefore(moment(from)) ? "Prepublish at" : "Delayed publish to"}: ${prepublish.format()}`}
					>
						{prepublish.format(Const.PERIOD_FORMAT)}
					</span>
				)}
				{fromText}
			</span>
			{(fromText || untilText) && <span className="arrow"></span>}
			<span>{untilText}</span>
		</span>
	);

	return {
		period,
		isExpired,
		isUpcoming,
	};
}

const isVeryFarInFuture = date => moment(date).isAfter(moment("2050-01-01", "YYYY-MM-DD"));
const ignoreBecauseYearOneOrZero = date => moment(date).format("YYYY") === "0001" || moment(date).format("YYYY") === "0000";

export function getServiceConfiguration(params, location, selectionsConfiguration) {
	const serviceId = params?.serviceId;

	const serviceConfigurations = selectionsConfiguration.serviceConfigurations;
	const serviceConfiguration = serviceConfigurations.find(sc => (
		!!serviceId && sc.serviceId === parseInt(serviceId)
		|| location.pathname.includes("/press") && sc.serviceName === "press"
	));

	return serviceConfiguration ?? serviceConfigurations[0];
}

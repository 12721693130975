import React from 'react'
import { Route, IndexRoute } from 'react-router'

import Core         from '../core'
import Login        from '../core/authentication/login'
import Unauthorized from '../core/authentication/unauthorized'
import ForgotPassword from '../core/authentication/forgotpassword'
import ResetPassword from '../core/authentication/resetpassword'
import PageNotFound from '../core/authentication/pagenotfound'

// AUTHENTICATION
import RequireAuthentication from '../core/authentication'

// MODULES
import Module from '../apps/app'

// import AcquisitionsLegacy from '../apps/_OLD_acquisitions/module'
// import DiscoveryLegacy from '../apps/_OLD_discovery/module'
// import StarLegacy from '../apps/_OLD_star/module'
// import SelectionsLegacy from '../apps/_OLD_selections/module'
// import LiveLegacy from '../apps/_OLD_live/module'
// import Playground from '../apps/playground/module'

import Acq from '../apps/acq/module'
import EventPlanner from '../apps/eventplanner/module'
import Reviews from '../apps/reviews/module'
import Metadata from '../apps/metadata/module'
import Planner from '../apps/planner/module'
import Schedules from '../apps/schedules/module'
import Selections from '../apps/selections2/module'
import Shield from '../apps/shield/module'
import Star from '../apps/star2/module'
import Traffic from '../apps/traffic/module'
import Vod from '../apps/vod/module'

// import PublicRoutes from './publicRoutes'

import appConfig from 'config'

// TODO!: Load module apps dynamically when react-router 4 is released.
// https://github.com/ReactTraining/react-router/blob/master/docs/guides/DynamicRouting.md

// <Route path=":id/items" component={SEL.OTTListItems} />

const routeConfig = (
	<Route component={Core} path='/'>
		<Route renderInAuthForm={true}>
			<Route path="login" component={Login} />
			<Route path="logout" component={Login} />
			<Route path="forgotpassword" component={ForgotPassword} />
			<Route path="resetpassword/:guid" component={ResetPassword} />
		</Route>
		<Route path="unauthorized" component={Unauthorized} />

		{/* { PublicRoutes() } */}

		<Route component={RequireAuthentication}>
			<IndexRoute component={Module} />
			{/* { AcquisitionsLegacy.routes() } */}
			{/* { DiscoveryLegacy.routes() } */}
			{/* { StarLegacy.routes() } */}
			{/* { SelectionsLegacy.routes() } */}
			{/* { LiveLegacy.routes() } */}
			{/* { Playground.routes() } */}

			{Acq.routes()}
			{EventPlanner.routes()}
			{Reviews.routes()}
			{Metadata.routes()}
			{Planner.routes()}
			{Schedules.routes()}
			{Selections.routes()}
			{Shield.routes()}
			{Star.routes()}
			{Traffic.routes()}
			{Vod.routes()}
		</Route>

		<Route path="*" component={PageNotFound} />
	</Route>
);

export default routeConfig;
import * as SportAPI from '../../../apis/sport'
import { useQuery } from '@tanstack/react-query'

const useSportSeries = (id: string | number) => {
    return useQuery(
        ["sport", "series", { id }],
        () => SportAPI.fetchSeries({ id }),
        { enabled: id !== null }
    );
};

export default useSportSeries;
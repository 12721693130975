import React, { Component } from 'react'
import PropTypes from 'prop-types'
import LinearProgress from '@mui/material/LinearProgress'

import { getRouteName } from '../../../components/comet'
import * as Alert from '../../../core/services/alert'

import App from '../../../components/app'
import InfoBar from '../../../components/ui/infoBar'
import { Info } from '../../../components/comet'
import Main from '../../../components/ui/main'

import List from './list'
import Header from './header'

import Store from '../store'
import Actions from '../actions'

import './schedule.css'

const MODULE = "schedules";
const DATASTORE = "schedule";

class Schedule extends Component {
	constructor(props) {
		super(props);

		this.state = {
			...Store.getState(),
		};
	}

	componentDidMount() {
		Store.listen(this.onChange);
		if (this.props.params.id) {
			Actions.fetchSchedule(this.props.params.id);
		} else {
			Actions.fetchRange(this.props.params);
		}
	}

	componentWillUnmount() {
		Actions.unmountSchedule();
		Store.unlisten(this.onChange);
	}

	onChange = (state) => {
		this.setState(state);
	}

	render() {
		const {
			scheduleListIsLoading,
			scheduleList,
			approveFailed,
			hasSelectedItems,
			approveIsLoading,
		} = this.state;
		
		const schedulesWaitingForUpdateCount = scheduleList.filter(day => day.status === "UpdateRequested").length;
		
		const approved = !!scheduleList.length && scheduleList.every(schedule => schedule.status === "Confirmed");
		const newChanges = !!scheduleList.length && approveFailed;

		return (
			<App
				name={`c6-${MODULE}-${DATASTORE} c6-list`}
				layout="grid"
				isLoading={scheduleListIsLoading || approveIsLoading}
			>
				<Header
					schedules={this.state.schedules}
					scheduleList={scheduleList}
					scheduleListIsLoading={scheduleListIsLoading}
					approved={approved}
					hasSelectedItems={hasSelectedItems}
					updateInProgress={scheduleListIsLoading || schedulesWaitingForUpdateCount}
				/>
				{renderUpdateStatus(scheduleList.length - schedulesWaitingForUpdateCount, scheduleList.length)}
				{newChanges &&
					<InfoBar separate={scheduleList.length > 1}>
						<p className="icon-error">
							More recent changes to {scheduleList.length > 1 ? "one of the schedules" : "this day and channel"} have been included in the list below. Please check them and try approving again.
						</p>
					</InfoBar>
				}
				{schedulesWaitingForUpdateCount === 0 && (
					<Main padding={true}>
						{scheduleList.map(schedule => (
							<List
								key={schedule.id}
								day={scheduleList.length > 1 && schedule.day}
								items={schedule.broadcasts}
								isLoading={scheduleListIsLoading}
								onSelectItem={!approved ? Actions.selectItem : null}
								hasSelectedItems={hasSelectedItems}
							/>
						))}
					</Main>
				)}
				<Info>{getInfo(this.props.location, scheduleList, !!schedulesWaitingForUpdateCount)}</Info>
			</App>
		);
	}
}

Schedule.propTypes = {
	params: PropTypes.shape({
		id: PropTypes.string,
	}).isRequired,
	location: PropTypes.shape().isRequired,
};

export default Schedule;

// HELPERS
function getInfo(location, scheduleList, waitingForUpdate) {
	const itemCount = scheduleList.reduce((acc, currentSchedule) => acc + currentSchedule.broadcasts.length, 0);
	const items = !waitingForUpdate ? `- displaying ${itemCount} items` : "";

	return `${getRouteName(location)} ${items}`;
}

const normalise = (value, min, max) => ((value - min) * 100) / (max - min);
function renderUpdateStatus(progress, max) {
	if (progress < max) {
		return (
			<div className="update-progress">
				<h1>Update in progress<span><span>{/* This double <span> is intentional! */}</span></span></h1>
				<p>
					Waiting for selected schedules to update. <strong>{progress} of {max}</strong> schedules updated.
					<br/>This page will display the selected schedules as soon as the update is finished.
				</p>
				<LinearProgress
					variant="determinate"
					value={normalise(progress, 0, max)}
				/>
			</div>
		);
	}

	return null;
}
import alt from '../../core/services/alt'
import Actions from './actions'
import EditorActions from '../../components/editor/actions'

import { Regions } from '../../core/constants'

class SelectionsStore {

	constructor() {

		this.item = {
			isLoading: false,
			list: {},
			listInstances: {},
			// selectionsConfiguration: null,
			selectionsConfiguration: getDefaultSelectionsConfiguration(),
		};

		this.list = {};
        // this.initStore("selected");

        // Lists
		this.initStore("lists", getListFilters({ }));
		this.initStore("sectionAssets");
		
		// Sections/Groups (used in the GroupMenu controller)
		this.initStore("groups", { returnAsTree: true, groupTypeId: 2, includeHidden: true }, { _selectedGroup: {} });
		// Setting this to true will cause the OTT app to reload the list of groups
		this.reloadGroupsBecauseModified = false;

        // Items
		this.initStore("listItems");
		this.initStore("sectionsContainingList");
		this.initStore("previewListItems");

        // Sections (used in the Groups App)
		this.initStore("sections", { /*groupTypeId: 2,*/ includeHidden: true }, { _selectedSection: {} });

        // Discovery
		this.initStore("discovery",
			{ searchText: "", _filter: "recent-regular", _programPremiereFilter: "upcoming", _broadcastsTypeFilter: "ott" },
			{ _prevFilters: {} }
		);
		this.initStore("discoveryEpisodes", {}, {
			_showEpisodes: false,
		});

		// Admin resources
		this.initStore("templates");
		this.initStore("areas");

		this.bindListeners({
            // SELECT
            // onSelect: [Actions.SELECT, Actions.SELECT_IN_STORE],
            // onUnselect: [Actions.UNSELECT, Actions.UNSELECT_IN_STORE],
            // onToggleSelect: [Actions.TOGGLE_SELECT, Actions.TOGGLE_SELECT_IN_STORE],
            // onToggleMultipleSelect: Actions.TOGGLE_MULTIPLE_SELECT,
            // onClearSelected: [Actions.CLEAR_SELECTED, Actions.ADD_SELECTED_TO_ALBUM_DONE, Actions.CLEAR_SELECTED_IN_STORE],

            // LISTS
			onFetchItems: Actions.FETCH_ITEMS,
			onPageItems: Actions.PAGE_ITEMS,
			onItemsUpdated: Actions.ITEMS_UPDATED,

			onMoveListInGroup: Actions.MOVE_LIST_IN_GROUP,
			onRollbackMoveListInGroup: Actions.ROLLBACK_MOVE_LIST_IN_GROUP,
			onListMoved: Actions.LIST_MOVED,
			onRefreshList: Actions.REFRESH_LIST,
			onListDeleted: Actions.LIST_DELETED,

            // ITEM
			onFetchItem: Actions.FETCH_ITEM,
			onItemLoaded: Actions.ITEM_LOADED,
			onItemSaved: [Actions.ITEM_UPDATED, EditorActions.MODEL_SAVED],
			onAddItem: Actions.ADD_LIST_TO_GROUP,
			onRemoveItem: [Actions.REMOVE_ITEM, Actions.REMOVE_LIST_FROM_GROUP, Actions.ROLLBACK_ADD_LIST_TO_GROUP],
			onItemRemoved: Actions.ITEM_REMOVED,
			onRollbackRemoveItem: [Actions.ROLLBACK_REMOVE_ITEM, Actions.ROLLBACK_REMOVE_LIST_FROM_GROUP],

            // GENERAL
			onUnmount: Actions.UNMOUNT,
			onPersist: Actions.PERSIST,

            // GROUPS
			onSetListGroup: Actions.SET_LIST_GROUP,
			onSelectGroup: [Actions.SELECT_GROUP, Actions.UPDATE_SELECTED_GROUP],

            // SECTIONS
			onSelectSection: Actions.UPDATE_SELECTED_SECTION,
            // onCreateSection: Actions.CREATE_SECTION,
			onRemoveSection: [Actions.REMOVE_SECTION, Actions.ROLLBACK_CREATE_SECTION],
			onHideSection: Actions.HIDE_SECTION,
			onUnhideSection: Actions.UNHIDE_SECTION,
			onMoveSection: Actions.MOVE_SECTION,
			onRollbackMoveSection: Actions.ROLLBACK_MOVE_SECTION,
			onSectionMoved: Actions.SECTION_MOVED,
			onSectionAssetsForNewListUpdated: Actions.SECTION_ASSETS_FOR_NEW_LIST_UPDATED,

            // DISCOVERY
			onDiscoverySearch: Actions.DISCOVERY_SEARCH,
			onDiscoveryFilter: Actions.DISCOVERY_FILTER,
			onDiscoveryFetchEpisodes: Actions.DISCOVERY_FETCH_EPISODES,
			onDiscoveryClearEpisodes: Actions.DISCOVERY_CLEAR_EPISODES,

			// ADMIN
			onDeleteTemplate: Actions.DELETE_TEMPLATE,
			onTemplateDeleted: Actions.TEMPLATE_DELETED,
			onDeleteArea: Actions.DELETE_AREA,
			onAreaDeleted: Actions.AREA_DELETED,
		});
	}

    // SELECT
    // onSelect({ item, selectedStore = "selected" }) {
    //     let { items } = this.list[selectedStore];
    //     const itemId = getAssetOrContainerId(item);
    //     const index = items.findIndex(i => getAssetOrContainerId(i) === itemId);
    //     if(index < 0) {
    //         items.push(item);
    //     }

    //     this.list[selectedStore].lastActionItemId = itemId;
    // }
    // onUnselect({ item, selectedStore = "selected" }) {
    //     let { items } = this.list[selectedStore];
    //     this.list[selectedStore].items = items.filter(i => getAssetOrContainerId(i) !== getAssetOrContainerId(item));

    //     this.list[selectedStore].lastActionItemId = null;
    // }
    // onToggleSelect({ item, selectedStore = "selected" }) {
    //     const { items } = this.list[selectedStore];
    //     const index = items.findIndex(i => getAssetOrContainerId(i) === getAssetOrContainerId(item));

    //     if(index >= 0) {
    //         this.onUnselect({ item, selectedStore });
    //     }
    //     else {
    //         this.onSelect({ item, selectedStore });
    //     }
    // }

    // onToggleMultipleSelect({ item, storeName, selectedStore = "selected" }) {
    //     const { items } = this.list[storeName];

    //     const start = Math.max(items.findIndex(i => getAssetOrContainerId(i) === this.list[selectedStore].lastActionItemId), 0);
    //     const end = items.findIndex(i => getAssetOrContainerId(i) === getAssetOrContainerId(item));
    //     const itemRange = items.slice(Math.min(start, end), Math.max(start, end) + 1);

    //     itemRange.forEach(i => this.onSelect({ item: i, selectedStore }));
    // }

    // LISTS
	onFetchItems({ payload, store, requestTime, options }) {
		const l = this.list[store];
		l.isLoading = true;

		const clearList = options.clearList ?? true;
		if (clearList) {
			l.items = [];
		}

		l.nextPageUrl = null;
		l.numberOfItems = 0;
		l.latestRequestTime = requestTime;
		l.latestSearchPayload = payload;
	}

	onPageItems({ entity, store }) {
		const l = this.list[store || entity];
		l.isLoading = true;
		l.nextPageUrl = null;
	}

	onItemsUpdated({ store, items, appendToExistingItems, numberOfItems, nextPageUrl, requestTime }) {
		const l = this.list[store];

		if (l.latestRequestTime > requestTime) {
			console.log("[%s] Ignoring result with %s items since there have been newer requests.", store, numberOfItems);
		}
		else {
			l.items = appendToExistingItems ? l.items.concat(items) : items;

			// Select first available section by default to save clicks
			// Might have to rethink this when there are more than one "root" section...
			if (store === "sections") {
				this.onSelectSection(items[0] || {});
				this.reloadGroupsBecauseModified = false;
			}
			
			l.nextPageUrl = nextPageUrl;
			l.numberOfItems = numberOfItems;

			l.isLoading = false;
			this.errorMessage = null;
		}
	}

    // LIST ITEM MOVEMENT
	onMoveListInGroup({ id, ordinal, areaId, active }) {
		const itemUpdater = item => {
			item.areaId = areaId;
			item.active = active;
		};
		this.moveItem({ datastore: "lists", id, ordinal, itemUpdater });
	}
	onRollbackMoveListInGroup(originalItem) {
		this.rollbackMoveItem({ datastore: "lists", originalItem });
	}

	// TODO: Refreshes the list so the item jumps back to it's original position
	onListMoved() {
		const l = this.list["lists"];
		l.isLoading = false;
	}

	onRefreshList(refreshedItem) {
		const l = this.list["lists"];
		l.isLoading = false;
		const index = l.items.findIndex(item => refreshedItem.id === item.id);
		l.items[index] = refreshedItem;
	}

	onListDeleted(deletedList) {
		// Remove deleted list from the current group's lists
		const lists = this.list["lists"].items;
		const listsIndex = lists.findIndex(l => l.id === deletedList.id);
		if (listsIndex >= 0) {
			lists.splice(listsIndex, 1);
		}

		// Remove deleted list from discovery lists
		const discoveryLists = this.list["discovery"].items;
		const discoveryIndex = discoveryLists.findIndex(dl => dl.type === deletedList.type && dl.id === deletedList.id);
		if (discoveryIndex >= 0) {
			discoveryLists.splice(discoveryIndex, 1);
		}
	}

    /* Item */
	onFetchItem(entity) {
		this.item[entity] = {};
		this.item.isLoading = true;
	}

	onItemLoaded({ entity, model }) {
		this.item[entity] = model;
		this.item.isLoading = false;
	}

	onItemSaved({ entity, model, originalId, targetStore = null }) {
		let store = targetStore || `${entity}s`;
		if (entity === "item") {
			store = "listItems";
		}
		if (entity === "section") {
			this.reloadGroupsBecauseModified = true;
		}

		// const hasUpdatedStore = this.persistToStore(store, model); // UPDATES OR ADDS
		const hasUpdatedStore = this.onPersist({ store, model, originalId });

		// We might have several editor stores listening for the same Editor Component
		// action so we need to bail out if this regards another entity.
		if (!hasUpdatedStore) {
			return false;
		}

		this.item.isLoading = false;
	}

	onAddItem({ payload, entity, store, targetStore }) {
		const s = this.list[targetStore || store || `${entity}s`];
		s.isLoading = true;

		this.insertItem(s, payload);

		// Update ordinals
		s.items.forEach((item, index) => {
			item.ordinal = index + 1;
		});
	}
	onRemoveItem({ id, entity, store, targetStore }) {
		const s = this.list[targetStore || store || `${entity}s`];
		s.isLoading = true;

		const item = this.getItem(s, id);
		item._isHidden = true;
	}
	onItemRemoved({ id, entity, store, targetStore }) {
		const s = this.list[targetStore || store || `${entity}s`];

		this.removeItem(s, id);

		s.isLoading = false;
	}
	onRollbackRemoveItem({ id, entity, store, targetStore }) {
		const s = this.list[targetStore || store || `${entity}s`];

		const item = this.getItem(s, id);
		delete item._isHidden;

		s.isLoading = false;
	}

    /* =============== */
    /*    GENERAL      */
    /* =============== */
	onUnmount(store) {
		if (store && this.list.hasOwnProperty(store)) {
			this.list[store].items = [];
		}
		if (store && this.item.hasOwnProperty(store)) {
			this.item[store] = {};
		}
	}

    // NOTE: Not compatible with metadata/star2 etc initStores since extras property squeezed it's way into this mess
	initStore(store, filters = null, extras = {}, items = [], ) {
		this.list[store] = {
			items,
			nextPageUrl: null,
			numberOfItems: 0,
			latestRequestTime: null,
			latestSearchPayload: null,
			filters,
			isLoading: false,
			...extras,
		};
	}

	onPersist({ store, model, prepend = false, originalId }) {
		const storeItems = this.list[store] && this.list[store].items;
		if (!storeItems) {
			return false;
		}

		const id = originalId && originalId !== model.id ? originalId : model.id;
		const index = storeItems.findIndex(si => si.id === id);

		if (index >= 0) {
			storeItems[index] = model;
		}
		else if (prepend) {
			storeItems.unshift(model);
		}
		else {
			storeItems.push(model);
		}

		return true;
	}

    // GROUPS
	onSetListGroup(sectionId) {
		this.list.lists.filters.sectionId = sectionId;
	}

	onSelectGroup(group) {
		this.list.groups._selectedGroup = group;
	}

    // SECTIONS
	onSelectSection(section) {
		if (this.list.sections._selectedSection?.id === section.id) {
			const parentSectionId = this.list.sections._selectedSection.parentSectionId;
			const parentSection = this.list.sections.items.find(s => s.id === parentSectionId);
			this.list.sections._selectedSection = parentSection ?? {};
		} else {
			this.list.sections._selectedSection = section;
		}

	}

	onUpdateSection(/*section*/) {

	}

	onCreateSection(section) {
		const s = this.list["sections"];
		this.insertItem(s, section);
		this.reloadGroupsBecauseModified = true;
	}

	onRemoveSection(id) {
		const s = this.list["sections"];
		const removed = this.removeItem(s, id);
		// If the removed section is also the selected sesction, select parent section of the removed section
		if (this.list.sections._selectedSection?.id === removed?.id && removed?.[0]?.parentSectionId) {
			this.list.sections._selectedSection = this.list.sections.items.find(s => s.id === removed[0].parentSectionId);
		}
		this.reloadGroupsBecauseModified = true;
	}

	onHideSection(id) {
		console.log("Hide " + id);
	}

	onUnhideSection(id) {
		console.log("Unhide " + id);
	}

	onMoveSection({ id, ordinal }) {
		// this.moveItem({ datastore: "sections", id, ordinal });
		// Use a custom move method since sections DnD is a little bit special
		this.moveSection({ id, ordinal });
		this.reloadGroupsBecauseModified = true;
	}

	onRollbackMoveSection(originalItem) {
		// this.rollbackMoveItem({ datastore: "sections", originalItem });
		// Use a custom rollback method since sections DnD is a little bit special
		this.rollbackMoveSection({ originalItem });
		this.reloadGroupsBecauseModified = true;
	}

	onSectionMoved() {
		const l = this.list["sections"];
		l.isLoading = false;
		this.reloadGroupsBecauseModified = true;
	}

	onSectionAssetsForNewListUpdated(items) {
		const { listId, assets } = items[0] ?? {};
		if (!listId) {
			return;
		}

		const existing = this.list.sectionAssets.items.find(l => l.listId === listId);
		if (existing) {
			existing.assets = assets;
		} else {
			this.list.sectionAssets.items.push({ listId, assets });
		}
	}

    // DISCOVERY
	onDiscoverySearch(searchText) {
		this.list.discoveryEpisodes._showEpisodes = false;

		const df = this.list.discovery.filters;
		const pdf = this.list.discovery._prevFilters;

		if (searchText === "") {
			df._filter = pdf._filter;
			df._programPremiereFilter = pdf._programPremiereFilter;
			df._broadcastsTypeFilter = pdf._broadcastsTypeFilter;
			df.orderBy = pdf.orderBy;
		}
		else if (df._filter) {
			pdf._filter = `${df._filter}`;
			pdf._programPremiereFilter = `${df._programPremiereFilter}`;
			pdf._broadcastsTypeFilter = `${df._broadcastsTypeFilter}`;
			pdf.orderBy = df.orderBy;
		}
		df.searchText = searchText;

	}

	onDiscoveryFilter(filters) {
		this.list.discoveryEpisodes._showEpisodes = false;
		this.list.discovery.filters = filters;
		const pdf = this.list.discovery._prevFilters;

		if (filters._filter) {
			pdf._filter = `${filters._filter}`;
			pdf._programPremiereFilter = `${filters._programPremiereFilter}`;
			pdf._broadcastsTypeFilter = `${filters._broadcastsTypeFilter}`;
			pdf.orderBy = filters.orderBy;
		}
	}

	onDiscoveryFetchEpisodes() {
		this.list.discoveryEpisodes._showEpisodes = true;
	}

	onDiscoveryClearEpisodes() {
		this.initStore("discoveryEpisodes", {}, { _showEpisodes: false });
	}

	// ADMIN
	onDeleteTemplate() {
		this.list.templates.isLoading = true;
	}

	onTemplateDeleted({ id }) {
		const store = this.list.templates;
		store.isLoading = false;
		this.removeItem(store, id);
	}

	onDeleteArea() {
		this.list.areas.isLoading = true;
	}

	onAreaDeleted({ id }) {
		const store = this.list.areas;
		store.isLoading = false;
		this.removeItem(store, id);
	}

    // updateStore(store, model) {
	// 	const storeItems = this.list[store] ? this.list[store].items : this[store];

	// 	if(!storeItems) {
	// 		return false;
	// 	}

	// 	let didUpdate = false;
	// 	storeItems.forEach((item, index) => {
	// 		if(item.id === model.id) {

	// 			storeItems[index] = {
	// 				...model,
	// 				type: item.type, // Never change the item type (since it could be a template)
	// 			};

	// 			didUpdate = true;
	// 		}
	// 	});

	// 	return didUpdate;
    // }
    // HELPERS
	getItem(store, id) {
		return !store.items ? null : store.items.find(e => e.id === id);
	}

	insertItem(store, payload) {
		if (!store.items) {
			return null;
		}
		store.items.splice(payload.ordinal - 1, 0, payload);
	}

	removeItem(store, id) {
		if (!store.items) {
			return null;
		}
		const index = store.items.findIndex(e => e.id === id);
		return store.items.splice(index, 1);
	}

	moveItem({ datastore, id, ordinal, itemUpdater }) {
		const l = this.list[datastore];
		l.isLoading = true;

		const itemIndex = l.items.findIndex(item => id === item.id);

		// Remove the item from it's old position and insert the updated item
		const item = l.items.splice(itemIndex, 1)[0];
		if (typeof itemUpdater === "function") {
			itemUpdater(item);	
		}
		l.items.splice(ordinal - 1, 0, item);

		// Update ordinals
		l.items.forEach((item, index) => {
			item.ordinal = index + 1;
		});
	}

	rollbackMoveItem({ datastore, originalItem }) {
		const l = this.list[datastore];
		l.isLoading = false;

		const itemIndex = l.items.findIndex(item => originalItem.id === item.id);

		// Remove the item from it's new position and insert it in the old position
		const item = l.items.splice(itemIndex, 1)[0];
		l.items.splice(originalItem.ordinal - 1, 0, item);

		// Update ordinals
		l.items.forEach((item, index) => {
			item.ordinal = index + 1;
		});
	}

	// Use a custom move method since sections DnD is a little bit special
	// We first create a new list containing the sections on the same level (same parent)
	// Then we move the item and update the ordinals
	// Then we add these sections to the end of this.list.sections.items
	moveSection({ id, ordinal }) {
		const l = this.list.sections;
		l.isLoading = true;

		const item = l.items.find(i => i.id === id);
		const sectionsOnSameLevel = l.items.filter(i => i.parentSectionId === item.parentSectionId);
		const itemIndex = sectionsOnSameLevel.findIndex(i => i.id === id);
		// Remove the item from it's old position and insert the updated item
		sectionsOnSameLevel.splice(itemIndex, 1);
		sectionsOnSameLevel.splice(ordinal - 1, 0, item);

		// Update ordinals
		sectionsOnSameLevel.forEach((item, index) => {
			item.ordinal = index + 1;
		});

		// Update in datastore
		l.items = l.items.filter(i => i.parentSectionId !== item.parentSectionId);
		l.items = l.items.concat(sectionsOnSameLevel);
	}

	// Use a custom rollback method since sections DnD is a little bit special
	rollbackMoveSection({ originalItem }) {
		const l = this.list.sections;
		l.isLoading = false;

		const item = l.items.find(i => i.id === originalItem.id);
		const sectionsOnSameLevel = l.items.filter(i => i.parentSectionId === item.parentSectionId);
		const itemIndex = sectionsOnSameLevel.findIndex(i => i.id === originalItem.id);
		// Remove the item from it's old position and insert the updated item
		sectionsOnSameLevel.splice(itemIndex, 1);
		sectionsOnSameLevel.splice(originalItem.ordinal - 1, 0, item);

		// Update ordinals
		sectionsOnSameLevel.forEach((item, index) => {
			item.ordinal = index + 1;
		});

		// Update in datastore
		l.items = l.items.filter(i => i.parentSectionId !== item.parentSectionId);
		l.items = l.items.concat(sectionsOnSameLevel);		
	}
}

// HELPERS


// const getPlaceholderItems = numberOfItems => {
//     let arr = new Array(numberOfItems);
//     for(let i = 0; i < numberOfItems; i++) {
//         arr[i] = { id: -i, };
//     }
//     return arr;
// }

export default alt.createStore(SelectionsStore, '[Selections]Store');

// HELPERS
function getListFilters(options) {
	return {
		// listTypeId: 5, // OTT Lists
		pageSize: 500,

		searchText: "",
		filter: "",
		...options,
	};
}

function getDefaultSelectionsConfiguration() {
	return {
		availableRegions: Regions,
		contentLayoutsForCategories: {
			regular: [
				{ key: "press-release-list", text: "Press release list" },
				{ key: "full-press-release-list", text: "Full press release list" },
				{ key: "compact-press-release-list", text: "Compact press release list" },
				{ key: "regular-item-list", text: "Regular item list (default)", default: true },
				{ key: "event-item-list", text: "Event item list" },
				{ key: "compact-item-list", text: "Compact item list" },
				{ key: "faq", text: "FAQ" },
				{ key: "bulletin-board", text: "Bulletin board" },
				{ key: "carousel", text: "Carousel" },
				{ key: "highlights", text: "Highlights" },
				{ key: "svt-slidable-selections-landscape", text: "Slidable landscape" },
				{ key: "svt-slidable-selections", text: "Slidable portrait" },
				{ key: "downloads", text: "Downloads" },
				{ key: "tabbed-items", text: "Tabbed items" },
				{ key: "metadata-list-premiere", text: "Metadata list with premiere" },
				{ key: "schedules-live-sport", text: "Live sport schedule" },
			],
			feature: [
				{ key: "feature", text: "Single item (default)", default: true },
			],
			editorial: [
				{ key: "information-box", text: "Neutral (default)", default: true },
				{ key: "hero-item", text: "Hero item", default: true },
			],
			widget: [
				{ key: "second-level-menu", text: "Second level menu" },
				{ key: "tabbed-sub-menu", text: "Tabbed sub menu (default)", default: true },
				{ key: "broadcasts", text: "Broadcasts" },
				{ key: "signup-alerts", text: "Signup" },
				{ key: "schedules-controller", text: "Schedules" },
				{ key: "login-alerts", text: "Login" },
				{ key: "forgotpassword-alerts", text: "Forgot password" },
				{ key: "resetpassword-alerts", text: "Reset password" },
				{ key: "schedules-subscribe", text: "Schedules - subscribe" },
				{ key: "update-subscription", text: "Schedules - update subscription" },
				{ key: "bulletin-board-item", text: "Bulletin board item" },
			],
			showcase: [
				{ key: "carousel", text: "Carousel" },
				{ key: "highlights", text: "Highlights", default: true },
				{ key: "svt-slidable-selections-landscape", text: "Slidable landscape (default)" },
				{ key: "svt-slidable-selections", text: "Slidable portrait" },
				{ key: "tabbed-items", text: "Tabbed items" },
				{ key: "metadata-list-premiere", text: "Metadata list with premiere" },
			]
		},
		itemEditorAssets: [
			{
				title: "Landscape",
				type: "Image",
				needsCrop: true,
				cropTypeId: 3,
				croppedThumbnailFormat: "Star_16x9_400x",
			},
			{
				title: "Portrait",
				type: "Image",
				needsCrop: true,
				cropTypeId: 4,
				croppedThumbnailFormat: "Star_7x10_400x",
			},
			{
				title: "Clip",
				type: "Clip",
				needsCrop: false,
			},
			{
				title: "Document",
				type: "Document",
				needsCrop: false,
			}
		],

		itemEditorChannels: [
			{ key: "cmore-fotboll", text: "C More Fotboll" },
			{ key: "cmore-golf-swe", text: "C More Golf SE" },
			{ key: "cmore-mix", text: "C More Mix" },
			{ key: "cmore-hockey", text: "C More Hockey" },
			{ key: "cmore-live", text: "C More Live" },
			{ key: "cmore-live-2", text: "C More Live 2" },
			{ key: "cmore-live-3", text: "C More Live 3" },
			{ key: "cmore-live-4", text: "C More Live 4" },
			{ key: "cmore-live-5", text: "C More Live 5" },
			{ key: "cmore-sport", text: "C More Sport" },
			{ key: "sportkanalen", text: "Sportkanalen" },
			{ key: "tv4", text: "TV4" },
			{ key: "tv12", text: "TV12" },
			{ key: "tv4-fotboll", text: "TV4 Fotboll" },
			{ key: "tv4-hockey", text: "TV4 Hockey" },
			{ key: "tv4-tennis", text: "TV4 Tennis" },
			{ key: "tv4-motor-sf-kanalen", text: "TV4 Motor/SF-Kanalen" },
			{ key: "tv4-sport-live-1", text: "TV4 Sport Live 1" },
			{ key: "tv4-sport-live-2", text: "TV4 Sport Live 2" },
			{ key: "tv4-sport-live-3", text: "TV4 Sport Live 3" },
			{ key: "tv4-sport-live-4", text: "TV4 Sport Live 4" },
			{ key: "tv4-sportkanalen", text: "TV4 Sportkanalen" },
		],

		serviceConfigurations: [
			{
				serviceId: 1,
				serviceName: "ott",
				allowNonAdminsToCreateCategories: ["regular", "editorial"],
				discoveryPlatform: "tv4",
			},
			{
				serviceId: 2,
				serviceName: "onair",
				allowNonAdminsToCreateCategories: [],
				discoveryPlatform: "tv4",
			},
			{
				serviceId: 3,
				serviceName: "press",
				allowNonAdminsToCreateCategories: ["regular", "editorial"],
				discoveryPlatform: "mtvpress",
				regions: ["fi"],
			},
			{
				serviceId: 4,
				serviceName: "partner",
				allowNonAdminsToCreateCategories: ["regular", "showcase", "editorial", "widget"],
				discoveryPlatform: "tv4",
				regions: ["se"],
			}
		],
	};
}




// {
// 	regular: [
// 		{ key: "cmore_3/carousel/editorial_column_3", text: "Editorial 3 Columns" },
// 		{ key: "cmore_3/carousel/editorial_column_4", text: "Editorial 4 Columns" },
// 		{ key: "cmore_3/grid/column_list", text: "Grid list" },
// 		{ key: "cmore_3/carousel/highlight_list", text: "Highlight covers list" },
// 		{ key: "cmore_3/carousel/tv4_branded_highlight_list", text: "Highlight Branded covers list" },
// 		{ key: "cmore_3/carousel/subgenre_list", text: "Subgenre covers list (default)", default: true },
// 		{ key: "cmore_3/carousel/sport_highlights", text: "Sports highlights" },
// 	],
// 	feature: [
// 		{ key: "cmore_3/featured/single_item", text: "Single item" },
// 	],
// 	editorial: [
// 		{ key: "cmore_3/sports/editorial_item", text: "Sports" },
// 		{ key: "cmore_3/film_series/editorial_item", text: "Film & Series" },
// 		{ key: "cmore_3/neutral/editorial_item", text: "Neutral (default)", default: true },
// 		{ key: "cmore_3/neutral/banner", text: "Banner" },
// 	],
// 	// widget: [],
// 	showcase: [
// 		{ key: "cmore_3/carousel/showcase", text: "Showcase (default)", default: true },
// 		{ key: "cmore_3/carousel/bubbles", text: "Bubbles" },
// 	]
// }
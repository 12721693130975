import cloneDeep from 'lodash/cloneDeep'
import moment from 'moment'

import alt from '../../core/services/alt'
import Actions from './actions'
import AuthStore from '../../core/authentication/store'

import Const from '../../core/constants'
import appConfig from 'config'
import { markLicenseAsChanged } from './utils'

const filterStorageKey = "c6-planner-store-filter";
const programFilterStorageKey = "c6-planner-store-filter-programs";

class PartnerStore {

    constructor() {

		this.isLoading = false;
		this.isLoadingApproveOrUndo = false;

        this.item = {
			isLoading: false,
			program: {},
			activePublishWindowsTodayCount: { "numberOfAssets": 0, "durationInHours": 0 }
        };

		this.list = {};
		this.initStore("publishWindows", {
			// highProfile: false,
			// includeEndingBetweenStartAndEnd: true,
		});
		this.initStore("unfilteredProgramsStats", {
		});
		this.initStore("unfilteredPrograms", {
		});
		this.initStore("dirtyPublishWindows", {
			dirty: true,
			includeInactiveLicenses: true,
		});
		this.initStore("activePublishWindowsToday", {
		});
		this.initStore("activePublishWindowsTodayCount", {
		});
		this.initStore("programs", {
			premieres: true,
		});
		this.initStore("episodes", {
			orderBy: "title",
			programTypes: "episode",
		});
		this.initStore("platforms");
		this.initStore("licenseTypes", {
			active: true,
			pageSize: 1000,
			orderBy: "name",
			filter: "userselectable",
		});
		this.initStore("programChildren");
		
		// TODO: Add Comet version when saving/reading filter from storage
		const storedFilters = appConfig.features.saveListFiltersInStorage ? JSON.parse(sessionStorage.getItem(filterStorageKey)) : null;
		this.filters = storedFilters ?? {
			filter: {
				programTypes: "single|season",
				owner: "",
				platform: appConfig.features.plannerDefaultPlatform,
			},
			calendarView: "dayGridMonth",
		};
		setDependentFilters(this.filters, this.filters.searchText);

		const storedProgramFilters = appConfig.features.saveListFiltersInStorage ? JSON.parse(sessionStorage.getItem(programFilterStorageKey)) : null;
		this.programFilters = storedProgramFilters ?? {
			filter: {
				_premiereFilter: "upcoming",
				_expandSeasons: false,
				programTypes: "single|season",
				owner: "cmore",
				platform: appConfig.features.plannerDefaultPlatform,
				onlyunplanned: false,
				_monetizationModel: "",
			},
			orderBy: "unplannedtitle",
			// orderBy: "hasnotwindowlicensestart",
			premieres: true,
			searchText: "",
			pageSize: 30,
		};
		setDependentProgramFilters(this.programFilters, this.programFilters.searchText, this.filters, true, this.list.platforms.items);

        this.bindListeners({
			// FILTERS
			onSearch: Actions.SEARCH,
			onFilter: Actions.FILTER,
			onSearchPrograms: Actions.SEARCH_PROGRAMS,
			onFilterPrograms: Actions.FILTER_PROGRAMS,
			onChangeCalendarView: Actions.CHANGE_CALENDAR_VIEW,

            // LISTS
            onFetchItems: Actions.FETCH_ITEMS,
            onPageItems: Actions.PAGE_ITEMS,
            onItemsUpdated: Actions.ITEMS_UPDATED,

            // ITEM
            onFetchItem: Actions.FETCH_ITEM,
			onItemLoaded: Actions.ITEM_LOADED,
			onItemSaved: Actions.ITEM_UPDATED,
            onRemoveItem: Actions.REMOVE_ITEM,
			onItemRemoved: Actions.ITEM_REMOVED,
			onRollbackRemoveItem: Actions.ROLLBACK_REMOVE_ITEM,

            // GENERAL
            onUnmount: Actions.UNMOUNT,
			onPersist: Actions.PERSIST,

			// CUSTOM
			onCreatePublishWindow: Actions.CREATE_PUBLISH_WINDOW,
			onPublishWindowCreated: Actions.PUBLISH_WINDOW_CREATED,
			onPublishWindowUpdated: Actions.PUBLISH_WINDOW_UPDATED,
			onPublishWindowDeleted: Actions.PUBLISH_WINDOW_DELETED,
			onToggleSelectedChange: Actions.TOGGLE_SELECTED_CHANGE,
			onSelectAllChanges: Actions.SELECT_ALL_CHANGES,
			onSelectNoChanges: Actions.SELECT_NO_CHANGES,
			onSelectChangesByUser: Actions.SELECT_CHANGES_BY_USER,
			onSelectChanges: Actions.SELECT_CHANGES,
			onLicenseUpdated: Actions.LICENSE_UPDATED,
			onProgramLoaded: Actions.PROGRAM_LOADED,
			onApproveChanges: Actions.APPROVE_CHANGES,
			onUndoChanges: Actions.UNDO_CHANGES,
			onApproveOrUndoFinished: Actions.APPROVE_OR_UNDO_FINISHED,
        });
	}
	
	// FILTERS
	onSearch({ searchText: text, targetStore }) {
		this.filters.searchText = text;
		this.list[targetStore || "publishWindows"].items = [];
		setDependentFilters(this.filters, text, this.list.platforms.items);
		this.saveFiltersToStorage();
	}

	onFilter(payload) {
		const filterParams = Object.keys(this.filters.filter).length
			? this.filters.filter
			: {};

		const { targetStore, ...filterPayload } = payload;

		this.filters.filter = {
			...filterParams,
			...filterPayload,
		};

		this.list[targetStore || "files"].items = [];
		setDependentFilters(this.filters, this.filters.searchText, this.list.platforms.items);
		this.saveFiltersToStorage();
	}

	onSearchPrograms({ searchText: text }) {
		this.programFilters.searchText = text;
		this.list["programs"].items = [];
		setDependentProgramFilters(this.programFilters, text, this.filters, true, this.list.platforms.items);
		this.saveFiltersToStorage();
	}

	onFilterPrograms(payload) {
		const filterParams = Object.keys(this.programFilters.filter).length
			? this.programFilters.filter
			: {};

		this.programFilters.filter = {
			...filterParams,
			...payload,
		};

		this.list["programs"].items = [];
		setDependentProgramFilters(this.programFilters, this.programFilters.searchText, this.filters, true, this.list.platforms.items);
		this.saveFiltersToStorage();
	}

	onChangeCalendarView(view) {
		this.filters.calendarView = view;
		this.saveFiltersToStorage();
	}

    // Items
    onFetchItems({ payload, store, requestTime }) {
        const l = this.list[store];
        l.isLoading = true;
		l.items = [];

		l.nextPageUrl = null;
		l.numberOfItems = 0;
        l.latestRequestTime = requestTime;
        l.latestSearchPayload = payload;
	}

	onPageItems({ entity, store }) {
        const l = this.list[store || entity];
        l.isLoading = true;
		l.nextPageUrl = null;
	}

    onItemsUpdated({ store, items, appendToExistingItems, numberOfItems, nextPageUrl, requestTime }) {
        const l = this.list[store];

		if (l.latestRequestTime > requestTime) {
			console.log("[%s] Ignoring result with %s items since there have been newer requests.", store, numberOfItems);
		}
		else {
			l.items = appendToExistingItems ? l.items.concat(items) : items;
			l.nextPageUrl = nextPageUrl;
			l.numberOfItems = numberOfItems;

            l.isLoading = false;
		}
    }

    /* Item */
	onFetchItem(entity) {
        this.item[entity] = {};
		this.item.isLoading = true;
	}

	onItemLoaded({ entity, model }) {
		this.item[entity] = model;
		this.item.isLoading = false;
	}

	onItemSaved({ entity, model, originalId, targetStore = null }) {
		const store = targetStore || `${entity}s`;

		// const hasUpdatedStore = this.persistToStore(store, model); // UPDATES OR ADDS
		// const hasUpdatedStore = this.updateStore(store, model); // ONLY UPDATES
		const hasUpdatedStore = this.onPersist({ store, model });

		// We might have several editor stores listening for the same Editor Component
		// action so we need to bail out if this regards another entity.
		if (!hasUpdatedStore) {
			return false;
		}

		this.item.isLoading = false;
    }

    onRemoveItem({ entity, id, targetStore }) {
		this.isLoading = true;

		const item = this.getItem(entity, id, targetStore);
		item._isHidden = true;
    }
	onItemRemoved({ entity, id, targetStore }) {
		this.isLoading = false;

		this.removeItem(entity, id, targetStore);
	}
	onRollbackRemoveItem({ entity, id, targetStore }) {
		this.isLoading = false;

		const item = this.getItem(entity, id, targetStore);
		delete item._isHidden;
	}

    /* =============== */
    /*    GENERAL      */
    /* =============== */
    onUnmount() {
		// this.initFilters();

    }

    initStore(store, filters = null, extras = {}, items = [], ) {
		this.list[store] = {
            items,
			nextPageUrl: null,
			numberOfItems: 0,
            latestRequestTime: null,
            latestSearchPayload: null,
            filters,
            isLoading: false,
            ...extras,
		};
    }

    onPersist({ store, model, prepend = false }) {
		const storeItems = this.list[store].items;
		if(!storeItems) {
			return false;
		}

		const index = storeItems.findIndex(si => si.id === model.id);

		if(index >= 0) {
			storeItems[index] = model;
        }
        else if(prepend) {
			storeItems.unshift(model);
        }
        else {
            storeItems.push(model);
        }

		return true;
	}



	/* =============== */
    /*     CUSTOM      */
    /* =============== */
	onCreatePublishWindow() {
		this.isLoading = true;
	}

	onPublishWindowCreated({ model, licenseId, programId }) {
		const license = getLicenseFromPrograms(this.list.programChildren.items, this.item.program, programId, licenseId);
		if (license) {
			license.exposures.push(model);
			markLicenseAsChanged(license);
		}
	}

	onPublishWindowUpdated({ model, licenseId, programId }) {
		const license = getLicenseFromPrograms(this.list.programChildren.items, this.item.program, programId, licenseId);
		if (license) {
			const index = license.exposures.findIndex(e => e.id === model.id);
			if (index >= 0) {
				license.exposures[index] = model;
				markLicenseAsChanged(license);
			}
		}
	}

	onPublishWindowDeleted({ id, licenseId, programId }) {
		const license = getLicenseFromPrograms(this.list.programChildren.items, this.item.program, programId, licenseId);
		if (license) {
			const index = license.exposures.findIndex(e => e.id === id);
			if (index >= 0) {
				license.exposures.splice(index, 1);
				markLicenseAsChanged(license);
			}
		}
	}

	onToggleSelectedChange(id) {
		const itemToToggle = this.list.dirtyPublishWindows.items.find(pw => pw.id === id);
		itemToToggle.selected = !itemToToggle.selected;
	}

	onSelectAllChanges() {
		this.list.dirtyPublishWindows.items.forEach(pw => pw.selected = true);
	}

	onSelectNoChanges() {
		this.list.dirtyPublishWindows.items.forEach(pw => pw.selected = false);
	}

	onSelectChangesByUser() {
		const user = AuthStore.getState().user;
		const username = user?.username;
		if (username?.length) {
			this.list.dirtyPublishWindows.items.forEach(pw => pw.selected = pw.changedByUser === username);
		} else {
			console.warn("User has no username?");
		}
	}

	onSelectChanges(changes) {
		this.list.dirtyPublishWindows.items.forEach(pw => pw.selected = pw.selected || changes.some(c => c.id === pw.id));
	}

	onLicenseUpdated({ license, programId }) {
		const program = getProgram(this.list.programChildren.items, this.item.program, programId);
		const licenseIndex = program?.licenses?.findIndex(l => l.id === license.id);
		if (licenseIndex >= 0) {
			program.licenses[licenseIndex] = {
				...program.licenses[licenseIndex],
				...license,
			};
			markLicenseAsChanged(program.licenses[licenseIndex]);
		}
	}

	onProgramLoaded(program) {
		if (program) {
			this.item.program.comment = program.comment;
		}
	}

	onApproveChanges() {
		this.isLoadingApproveOrUndo = true;
	}

	onUndoChanges() {
		this.isLoadingApproveOrUndo = true;
	}

	onApproveOrUndoFinished() {
		this.isLoadingApproveOrUndo = false;
	}

	saveFiltersToStorage() {
		sessionStorage.setItem(filterStorageKey, JSON.stringify(this.filters));
		sessionStorage.setItem(programFilterStorageKey, JSON.stringify(this.programFilters));
	}
	
}

export default alt.createStore(PartnerStore, '[Partner]Store');

/* Helpers */
function setDependentFilters(filters, searchText, platforms) {
	filters.filter.owner = getPlatformSource(filters.filter.platform, filters.filter.owner, platforms);
}

function setDependentProgramFilters(licenseFilters, searchText, filters, excludeExpiredLicenses, platforms) {
	let search/*, orderBy = "hasnotwindowlicensestart"*/;

	// switch (licenseFilters.filter._premiereFilter) {
	// 	case "upcoming":
	// 		// orderBy = "hasnotwindowlicensestart";
	// 		break;
	// 	case "fresh":
	// 		// orderBy = "hasnotwindowlicensestartdesc";
	// 		break;
	// }

	if (searchText && searchText.length) {
		search = searchText;
		// orderBy = "title";
	}

	licenseFilters.search = search;
	// licenseFilters.orderBy = orderBy;

	// map owner by platform
	licenseFilters.filter.owner = getPlatformSource(filters.filter.platform, filters.filter.owner, platforms);

	// 2022-06-27 HACK: Planner panic fix because upcoming licenses were not included in search
	// // add date to make sure only active licenses gets fetched
	// if (excludeExpiredLicenses) {
	// 	licenseFilters.filter.date = moment().format(Const.DATE_FORMAT);
	// }
}

function getPlatformSource(currentPlatform, currentSource, platforms) {
	if (!platforms?.length) {
		return currentSource;
	}

	const platform = platforms.find(p => p.name === currentPlatform);
	return platform.owners.some(s => s.name === currentSource) ? currentSource : platform.owners[0]?.name ?? "";
}

function getLicenseFromPrograms(programs, program, programId, licenseId) {
	const _program = getProgram(programs, program, programId);
	return _program?.licenses?.find(l => l.id === licenseId);
}

function getProgram(programs, program, programId) {
	if (program?.id === programId) {
		return program;
	}

	return programs.find(p => p.id === programId);
}
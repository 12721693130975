import React from 'react'

import App from '../../../components/app'
import { StandardInfo } from '../../../components/comet'
import Main from '../../../components/ui/main'
import Header from '../../../components/headers/header'
import Button from '../../../components/ui/controls/button'

import List from './list'

import Actions from '../actions'
import Store from '../store'

const MODULE = "shield"
const GRANDPARENT_ENTITY =  "module"
const PARENT_ENTITY =  "role"
const PARENT_TITLE = "Services"
const ENTITY =  "resource"
const TEXT_HEADING = "Resources"
const TEXT_EMPTY = "No resources? Activate some services for the module!"

export default class ListApp extends React.Component {

	constructor(props) {
		super(props);

		this.onChange = this.onChange.bind(this);
		this.handleResourceToggle = this.handleResourceToggle.bind(this);

		this.moduleId = this.props.params.moduleId;
		this.roleId = this.props.params.roleId;

		this.state = {
			...Store.getState(),
		};
	}

	componentDidMount() {
		Store.listen(this.onChange);
		Actions.fetchItems({ parentEntity: GRANDPARENT_ENTITY, entity: ENTITY }, { moduleId: this.moduleId });
		Actions.fetchItem(PARENT_ENTITY, { moduleId: this.moduleId, id: this.roleId });
		Actions.fetchItem(GRANDPARENT_ENTITY, { id: this.moduleId });
	}

	componentWillUnmount() {
		Actions.unmount();
		Store.unlisten(this.onChange);
	}

	onChange(state) {
		this.setState(state);
	}

	handleResourceToggle(data, e, active) {
		const payload = { data, roleId: this.roleId };
		const entities = { parentEntity: PARENT_ENTITY, entity: ENTITY };

		if(active) {
			Actions.addItemEntity(entities, payload);
		}
		else {
			Actions.removeItemEntity(entities, payload);
		}
	}

	render() {
		const { item } = this.state;
		const heading = getHeading(item);
		const backToTitle = item && item.module && item.module.displayName || "Module";
	    return (
			<App
				name={`c6-${MODULE}-${ENTITY}s`}
				layout="grid"
				isLoading={this.state.isLoading}>
				<Header title={heading} backToTitle={backToTitle} />
				<Main>
					<List
						isLoading={this.state.isLoading}
						items={this.state[`${ENTITY}s`]}
						role={this.state.item.role}
						module={this.state.item.module}
						handleResourceToggle={this.handleResourceToggle}
						textEmpty={TEXT_EMPTY}
					/>
				</Main>
				<StandardInfo location={this.props.location} />
			</App>
		);
	}
}

// HELPERS
function getHeading(item) {
	const data = item[PARENT_ENTITY]
	const heading = data && data.displayName || "(Loading)";
	return `${heading} / ${TEXT_HEADING}`;
}
import React from 'react'
import { Link } from 'react-router'
import { browserHistory } from 'browserHistory'

import { Filter, Left, Right } from '../../../components/filter'

export default ({
	filters,
	serviceId,
	// serviceName,
	items,
	// goBackInsteadOfLink,
	location,
	customer,
	routes,
}) => {

	// We need to go back instead of doing a normal navigation to allow for
	// react-router-scroll to restore the scroll position of the library list.
	function handleClick(e) {
		if (/*goBackInsteadOfLink || */location && location.state && location.state.returnTo) {
			e.preventDefault();
			browserHistory.goBack();
		}
	}

	// const firstPackage = items && items[0];
	const seriesTitle = items[0] && items[0].metadata && items[0].metadata.series.originalTitle;
	const serviceName = items[0] && items[0].service && items[0].service.displayName;
	return (
		<Filter>
			<Left>
				<h1>
					{getLink(location, customer, handleClick, routes)} / {seriesTitle ? `"${seriesTitle}"` : "..."} {serviceName ? `(${serviceName})`: ""}
				</h1>
			</Left>
		</Filter>
	);
}

// HELPERS
function getLink(location, customer, handleClick, routes) {
	const returnTo = location && location.state && location.state.returnTo;
	const returnToTitle = location && location.state && location.state.returnToTitle;
	// const customerPath = customer ? `/${customer}` : "";

	const servicePath = (routes.find(r => r.serviceId !== undefined) || { path: "packages" }).path;
	const url = returnTo ? returnTo : `/vod/${servicePath}`;
	const parentTitle = returnToTitle || "Packages";

	return <Link to={url} onClick={handleClick}>{parentTitle}</Link>
}
import React, { Component } from 'react'
import { Link } from 'react-router'
import PropTypes from 'prop-types'
import moment from 'moment'
import upperFirst from 'lodash/upperFirst'

import Preview from '../../../components/assets/preview'
import InlineHeading from '../../../components/ui/inlineHeading'
import Period from '../../../components/ui/period'
import Status from '../../../components/ui/status'
import { formatBytes } from '../../../utils/misc'

import Actions from '../actions'
import Const from '../../../core/constants'

import appConfig from 'config'

import styles from '../../../components/listItems/standard.css'

export default class DeliveryItemsContent extends Component {

	constructor(props) {
		super(props);
	}

	renderDeliveryItems = () => {
		const { deliveryItems } = this.props;
		if (!Array.isArray(deliveryItems)) {
			return <div className="c6-content">Loading...</div>
		}

		if (!deliveryItems.length) {
			return <div className="c6-content">Nothing to show here.</div>
		}

		return (
			<table style={{ width: "auto", padding: "7px" }}>
				<tbody>
					{deliveryItems.map(({ id, size, content, response, destinationPath }) => {
						const filenameRegex = /[^\/\\]+$/;
						const filename = destinationPath && filenameRegex.exec(destinationPath)[0] || "JSON";
						const fileIcon = getFileIcon(filename);
						return (
							<tr key={id} className="c6-content delivery-item">
								<td><span className={`file-icon ${fileIcon}`}>{filename}</span></td>
								<td>{getContentLinkOrInfo(id, content, size)}</td>
								<td>{getResponseLinkOrInfo(id, response)}</td>
							</tr>
						);
					})}
				</tbody>
			</table>
		);
	}

	render() {
	    return (
			<div className={`c6-secondary flex flex-dyn-1 delivery-items`} onClick={e => e.stopPropagation()}>
				{this.renderDeliveryItems()}
			</div>
		);
  	}
}

export function getContentLinkOrInfo(deliveryItemId, content, size, api = "vod") {
	if (!(content && content.length) && !size) {
		return <span>No content</span>;
	}

	const formattedSize = formatBytes(size, 1);

	// XML or JSON
	if (content && (content.startsWith("<") || content.startsWith("{"))) {
		const contentLink = (
			<a
				title={`${size} bytes`}
				className="c6-link"
				href={`${appConfig.api[api]}deliveryItems/${deliveryItemId}/content`}
				target="_blank"
				onClick={e => e.stopPropagation()}
			>
				View content ({formattedSize})
			</a>
		);
		return contentLink;
	}

	// Other
	return <span title={`${size} bytes`}>{formattedSize}</span>;
}

export function getResponseLinkOrInfo(deliveryItemId, response, api = "vod") {
	if (!response || !response.length) {
		return null;
	}

	return (
		<a
			className="c6-link"
			href={`${appConfig.api[api]}deliveryItems/${deliveryItemId}/response`}
			target="_blank"
			onClick={e => e.stopPropagation()}
		>
			View response
		</a>
	);
}

export function getFileIcon(filename) {
	const extensionRegex = /[^\.]+$/;
	const extension = extensionRegex.exec(filename)[0];
	switch (extension.toLowerCase()) {
		case "xml":
			return "icon-code";
		case "mp4":
			return "icon-file-video";
		default:
			return "icon-insert_drive_file";
	}
}

function openXML(e, deliveryItemId) {
	e.preventDefault();
	e.stopPropagation();
	window.open(`${appConfig.api.vod}deliveryItems/${deliveryItemId}/content`, "_blank");
}
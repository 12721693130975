import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Switch from '@mui/material/Switch'
import FormControlLabel from '@mui/material/FormControlLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'

import InlineHeading from '../../../components/ui/inlineHeading'
import ContentItem, { Content } from '../../../components/list/contentItem'

import { ShieldAccessLevels } from '../../../core/constants'

export default class ListContent extends Component {

	static propTypes = {
		displayName: PropTypes.string,
		description: PropTypes.string,
	}

	render() {
		const {
			item: service,
			module = {},
			handleToggle,
			handleSetProperty,
		} = this.props;

		const {
			id: serviceId,
			displayName,
			description = "",
		} = service;

		const active = serviceActive(module, serviceId)

	    return (
			<ContentItem>
				<Content>
					<FormControlLabel
						control={
							<Switch
								checked={active}
								onChange={handleToggle.bind(this, service, "service")}
							/>
						}
						label={displayName}
						labelPlacement="end"
					/>
					<p style={{ marginLeft: "47px", marginTop: "-5px" }}>{description}</p>
				</Content>
				<Content>
					<InlineHeading title="Access level">{active ? getAccessLevelSelect(serviceId, handleSetProperty, module, service) : null}</InlineHeading>
				</Content>
			</ContentItem>
		);
  	}
}

// HELPERS
function serviceActive(module, serviceId) {
	return module.services ? module.services.findIndex(s => s.id === serviceId) >= 0 : false;
}

function getAccessLevelSelect(serviceId, handleSetProperty, module, service) {
	let currentAccessLevel = null;
	if(module.services) {
		const currentLoginModule = module.services.find(s => s.id === serviceId);
		currentAccessLevel = currentLoginModule && currentLoginModule.accessLevel || null;
	}

	const MenuItems = ShieldAccessLevels.filter(t => t !== "Undefined").map(type => (
		<MenuItem
			key={type}
			value={type}
		>
			{type}
		</MenuItem>
	));

	return (
		<Select
			value={currentAccessLevel ?? ""}
			onChange={handleSetProperty.bind(this, service, "service", "accessLevel")}
			variant="standard"
			placeholder="Access level"
			sx={{ width: "125px" }}
		>
			{MenuItems}
		</Select>
	);
}
import React from 'react'

import App from '../../../../components/app'

import AlbumList from '../shared/list'
import FloatActions from '../../shared/floatactions'

import Header from './header'

import Actions from '../../actions'
import Store from '../../store'

import { decoratorUIActions } from '../../../../core/decorators/uiActions'
import itemActions from './itemActions'

import './app.css'

@decoratorUIActions(itemActions)
export default class StarAlbum extends React.Component {
	constructor(props) {
		super(props);

		this.onChange = this.onChange.bind(this);
		// this.handleSearch = this.handleSearch.bind(this);
		this.handleCreateAlbum = this.handleCreateAlbum.bind(this);

		this.state = {
			...Store.getState(),
			searchText: null,
			onlyMine: "true",
        };
	}

	componentDidMount() {
		Store.listen(this.onChange);

		// Fetch albums the user has access to
		Actions.fetchItems("albums", { orderBy: "createdDesc", onlyMine: this.state.onlyMine });

		// Also fetch the private files (mySpace) so we can fake an album with them
		Actions.fetchItems("assets", { audience: "private" }, "mySpace");
	}

	componentWillUnmount() {
        Store.unlisten(this.onChange);
    }

	onChange(state) {
        this.setState(state);
	}

	// handleSearch(e) {
	// 	const searchText = e.target.value;
	// 	this.setState({ searchText });
	// }

	handleCreateAlbum(name) {
		Actions.createAlbum(name);
	}

	handleSearch = (e) => {
		const searchText = e.target.value;
		this.setState({ searchText });
	}

	handleFilter = e => {
		Actions.fetchItems("albums", { orderBy: "createdDesc", onlyMine: e.target.value });
		this.setState({ onlyMine: e.target.value });
	}

	render() {
		const { list, searchText, onlyMine } = this.state;
		const { isLoading } = list.albums;

		return (
			<App name="c6-star c6-star-album" isLoading={isLoading}>
				<Header title="Albums" onSearch={this.handleSearch} onlyMine={onlyMine} onFilter={this.handleFilter} />
				<AlbumList
					list={list}
					searchText={searchText}
					onCreateAlbum={this.handleCreateAlbum}
				/>
				<FloatActions selectedItems={list.selected.items} />
			</App>
		);
	}
};
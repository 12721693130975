import React from 'react'
import moment from 'moment'

import ContentItem, { Content } from '../../../components/list/contentItem'

import '../../../components/listItems/standard.css'

const ScheduleRelease = (props) => {

	const {
		from,
		until,
		channels,
		allBundles,
	} = props;

	const selectedBundles = [];
	allBundles?.forEach(bundle => {
		const bundleIsSelected = bundle.channels.every(bc => channels.some(c => c.channelId === bc.id));
		if (bundleIsSelected) {
			selectedBundles.push(bundle.displayName);
		}
	});
	
	const releaseFrom = moment(from).format("YYYY-MM-DD");
	const releaseUntil = moment(until).format("YYYY-MM-DD")
	return (
		<ContentItem data={props}>
			<Content>
				<h1>{selectedBundles.join(", ")}</h1>
			</Content>
			<Content>
				<p>The schedule will be released: <span className="c6-text-bold">{releaseFrom}</span></p>
			</Content>
			<Content>
				<p>...and include broadcasts up until: <span className="c6-text-bold">{releaseUntil}</span></p>
			</Content>
		</ContentItem>
	);
};

export default ScheduleRelease;
import { CONTENT, MENU } from '../../../components/actions'
import Actions from '../actions'

export default {
	targetComponent: "Content",
	actions: [
		{
			title: "Select change",
			method: ({ id }, sourceProps) => Actions.toggleSelectedChange(id),
			type: CONTENT,
		},
		// {
		// 	title: "Undo change",
		// 	icon: "undo",
		// 	method: ({ id }) => Actions.undoChanges([id]),
		// 	type: MENU,
		// },
	]
};
import React from 'react'
import Moment from 'moment'

import * as Alert from '../../../../core/services/alert'

import { Filter, Left, Right } from '../../../../components/filter'
import Main from '../../../../components/ui/main'
import { MuiText, MuiRadio } from '../../../../components/ui/editorWidgets'
import DatePicker from '../../../../components/ui/controls/pickers/datepicker'
import Button from '../../../../components/ui/controls/button'

import Actions from '../../actions'
import Store from '../../store'

import RecipientList from './recipientList'
import { getAlbumStatus } from '../shared/utils'

import './dialog.css'

import Constants from '../../../../core/constants'

export default class AlbumDialog extends React.Component {
	constructor(props) {
		super(props);

		this.onChange = this.onChange.bind(this);
		this.handleShareClick = this.handleShareClick.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handlePersonChange = this.handlePersonChange.bind(this);

		const store = Store.getState();
		this.state = {
			...store,
			pass: null,
			expiry: "one_week",
			customDate: "",
			albumType: store.item.album.type || "Upload",
			newRecipients: [],
		};
	}

	componentDidMount() {
		Store.listen(this.onChange);
	}

	componentWillUnmount() {
        Store.unlisten(this.onChange);
    }

	onChange(state) {
        this.setState(state);
	}

	handleShareClick() {
		const {
			pass,
			expiry,
			customDate,
			item,
			albumType,
			newRecipients,
		} = this.state;

		const { album } = item;

		const validUntil = getExpiryDate(expiry, customDate);

		const recipients = newRecipients.length ? newRecipients.join(",") : null;

		if(validUntil !== "Invalid date") {
			Actions.shareAlbum(album, pass, validUntil, recipients, albumType);
			this.setState({ newRecipients: [] });
		}
		else {
			Alert.displayAlert("error", "Please enter a valid date!");
		}
	}

	handleStopSharingClick(id) {
		Actions.stopAlbumSharing(id);
	}

	handleChange(type, value) {
		this.setState({
			[type]: value,
		});
	}

	handlePersonChange(op, value) {
		this.setState({
			newRecipients: changeAlbumPerson(op, value, [...this.state.newRecipients], this.state.item.album.recipients),
		});
	}

	render() {
		const {
			item,
			pass,
			expiry,
			customDate,
			albumType,
			newRecipients,
		} = this.state;

		const { album } = item;
		const { name, recipients } = album;
		const { albumIsShared, albumExpires, href, password } = getAlbumStatus(album);
		const typeText = getAlbumTypeText(albumType);
		const passText = pass ? <span> using the password <em>{pass}</em>.</span> : ".";

		let headerInfo, saveTitle = "Share", saveType = "sharewithpeople", stopSharingButton = null;
		if(albumIsShared) {
			headerInfo = (
				<Left>
					{/* <h1>"{name}" is shared until {albumExpires.format(Constants.DATE_TIME_FORMAT)}</h1> */}
					<h1>"{name}" is shared ({albumExpires.fromNow(true)} left)</h1>
					<p>
						The <a className="c6-link" href={href} target="_blank">album is available here</a> {password ? `with the password: ` : "without any password protection."}<strong>{password}</strong>
					</p>
				</Left>
			);
			saveTitle = "Save";
			saveType = "check";

			stopSharingButton = (
				<Button
					noBackground
					type="cancel"
					title={"Stop sharing"}
					onClick={this.handleStopSharingClick.bind(this, album.id)}
				/>
			);
		}
		else {
			headerInfo = (
				<Left>
					<h1>Share album "{name}"</h1>
					{typeText
						? <p>Sharing this album will let people <em>{typeText}</em> it{passText}</p>
						: <p>&nbsp;</p>
					}
				</Left>
			);
		}

		return (
			<div className="c6-dialog c6-dialog-star c6-dialog-star-share">
				<Filter>
					{headerInfo}
					<Right>
						{stopSharingButton}
						<Button
							type={saveType}
							title={saveTitle}
							disabled={expiry === "custom" && customDate == null || !albumType || albumType === "Undefined"}
							onClick={this.handleShareClick}
						/>
					</Right>
				</Filter>
				<Main modal={true}>
					<div>
						<section>
							<h3 className="icon-sharewithpeople large-icon">Sharing options (required)</h3>
							<MuiRadio
								options={getAlbumTypeOptions()}
								onChange={this.handleChange.bind(this, "albumType")}
								value={albumType}
							/>
							<MuiText
								onChange={this.handleChange.bind(this, "pass")}
								label="Password to access album (optional)"
								value={pass === null ? "" : pass}
							/>
						</section>
						<section className="separator">
							<h3 className="icon-date_range large-icon">The album link will expire in</h3>
							<MuiRadio
								options={getOptions()}
								onChange={this.handleChange.bind(this, "expiry")}
								value={expiry}
							/>
							<DatePicker
								onChange={this.handleChange.bind(this, "customDate")}
								value={customDate}
								hidden={expiry !== "custom"}
							/>
						</section>
						<section className="separator">
							<h3 className="icon-group_add large-icon">Share with these persons</h3>
							<p>They will get an email containing a public link to this album{pass || password ? <span> together with the password <em>{pass || password}</em>.</span> : "."}</p>
							<RecipientList items={newRecipients} onChange={this.handlePersonChange} />
						</section>
						{renderPreviousRecipients(recipients)}
					</div>
				</Main>
			</div>
		);
	}
};

// HELPERS
function getAlbumTypeText(type) {
	switch(type) {
		case "Upload":
			return "upload to";
		case "Download":
			return "download from";
		case "UploadAndDownload":
			return "upload to or download from";
	}
}

function getAlbumTypeOptions() {
	return {
		dataSource: [
			{ value: "Upload", label: "People can upload to the album" },
			{ value: "Download", label: "People can download from the album" },
			{ value: "UploadAndDownload", label: "People can upload to and download from the album" },
		]
	}
}

function getOptions() {
	return {
		dataSource: [
			{ value: "one_day", label: `1 day (${Moment().add(1, "days").format(Constants.PERIOD_FORMAT)})` },
			{ value: "three_days", label: `3 days (${Moment().add(3, "days").format(Constants.PERIOD_FORMAT)})` },
			{ value: "one_week", label: `1 week (${Moment().add(7, "days").format(Constants.PERIOD_FORMAT)})` },
			{ value: "one_month", label: `1 month (${Moment().add(1, "month").format(Constants.PERIOD_FORMAT)})` },
			{ value: "custom", label: "a specific date" }
		]
	}
}

function getExpiryDate(expiry, customDate) {
	let now = Moment();

	switch (expiry) {
		case "one_day":
			now.add(1, "days");
			break;
		case "three_days":
			now.add(3, "days");
			break;
		case "one_week":
			now.add(7, "days");
			break;
		case "one_month":
			now.add(1, "month");
			break;
		case "custom":
			now = Moment(customDate);
	}

	return now.endOf("day").format(Constants.DATE_TIME_FORMAT);
}

function changeAlbumPerson(op, person, persons, previousPersons = []) {
	const trimmedPerson = person.trim();
	if(op === "add" && trimmedPerson && !persons.includes(trimmedPerson) && !previousPersons.includes(trimmedPerson)) {
		persons.push(trimmedPerson);
	}
	else if(op === "remove" && person) {
		const index = persons.findIndex(r => r === person);
		if(index >= 0) {
			persons.splice(index, 1);
		}
	}

	return persons;
}

function renderPreviousRecipients(recipients) {
	if(!recipients) {
		return null;
	}

	return (
		<section className="no-margin">
			<h3 className="icon-people large-icon">Previously shared with these persons</h3>
			<RecipientList items={recipients.split(",")} />
		</section>
	);
}
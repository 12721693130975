import * as PlannerAPI from '../../../apis/planner'
import { useQuery } from '@tanstack/react-query'

interface args {
    filter?: object;
    enabled?: boolean;
}

const usePlannerPlatforms = ({ filter = {}, enabled = true }: args = {}) => {
    const filters = {
        ...filter,
    };
    return useQuery(
        ["planner", "platforms", filters],
        () => PlannerAPI.fetchPlatforms(filters),
        { enabled }
    );
};

export default usePlannerPlatforms;

import React from 'react'
import moment from 'moment'

import Button from '../../../components/ui/controls/button'
import EventRow from './eventrow'

export default function StepThree(props) {
	const { payload } = props;
    const [events, setEvents] = React.useState(() => generateDefaultEvents(payload));
    
    const actions = props.getActions({
        onNextClick: () => props.onNextClick({
            ...props.payload,
            events,
        }),
    });

	const onChange = React.useCallback(
		({ groupIndex, eventIndex, name, val, item }) => {
			const newEvents = [...events];
			newEvents[groupIndex][eventIndex] = item
				? item
				: { ...newEvents[groupIndex][eventIndex], [name]: val };
			setEvents(newEvents);
		},
		[events, setEvents]
	);

	return (
		<div className="step">
			<div className="content c6-eventplanner-events scroll-track-bg">
                {events.map((eventGroup, groupIndex) => (
                    <div key={groupIndex} className="event-group">
                        {eventGroup.map((event, eventIndex) => (
                            <EventRow
								key={eventIndex}
								groupIndex={groupIndex}
								eventIndex={eventIndex}
								event={event}
								series={payload.series}
								season={payload.season}
								onChange={onChange}
								template={props.eventTemplate}
							/>
                        ))}

                        {events.length > 1 && groupIndex === 0 && (
                            <Button
                                type="expand_more"
                                title="Duplicate planning and titles to coming periods below"
                                onClick={() => {
                                    const newEvents = duplicatePlanning(events, payload);
                                    setEvents(newEvents);
                                }}
                            />
                        )}
                    </div>
                ))}
            </div>

			{actions}
		</div>
	);
}

function generateDefaultEvents({
    numberLimit,
    dateLimit,
    eventsPerInterval,
    start,
    title,
    duration,
    interval,
    catchupAvailabilityPeriod,
    publishBeforeEventStart,
    publishTimeOfDay,
}) {
    const baseEvent = {
        title,
        start: moment(start).format(),
        duration,
        deleted: false,
        catchupAvailabilityPeriod,
        publishBeforeEventStart,
        publishTimeOfDay,
    };
	if (interval === null || interval === "no-interval") {
		return [[ baseEvent ]];
	}

    const eventGroups = [];
    let eventCounter = 0;
    const dateMax = dateLimit ? moment(dateLimit) : null;
    const dateCursor = moment(start);

    let i = 0;
    while (
        (numberLimit === null || eventCounter < numberLimit)
        && (dateMax === null || dateCursor.isSameOrBefore(dateMax, "day"))
    ) {
        const events = [];
		for (let j = 0; j < eventsPerInterval; j++) {
			events.push({
                ...baseEvent,
                start: dateCursor.format(),
            });
            eventCounter += 1;

            if (numberLimit !== null && eventCounter >= numberLimit) {
                break;
            }
        }

        eventGroups.push(events);
        i += 1;
        dateCursor.add(1, interval);
	}

	return eventGroups;
}

function duplicatePlanning(eventGroups = [], { start: originalStart, interval }) {
    const newEvents = [...eventGroups];

    const firstGroup = eventGroups[0];
    const templates = firstGroup.map((event) => {
        return {
            ...event,
            startOffset: moment(event.start).diff(originalStart),
        };
    });

    eventGroups.slice(1).forEach((eventGroup, groupIndex) => {
        const events = eventGroup.map((event, eventIndex) => {
            const templateEvent = templates[eventIndex];
            return {
				...templateEvent,
                start: moment(originalStart)
                    .add(groupIndex + 1, interval)
                    .add(templateEvent.startOffset, "ms")
                    .format(),
            };
        });
        newEvents[groupIndex + 1] = events;
    });

    return newEvents;
}
import { CONTENT, MENU } from '../../../components/actions'

import * as VodCoreAPI from '../../../apis/vodcore'
import { updateItemInMatchingQueries } from '../../../core/hooks/useList'

export default {
	targetComponent: "ContentItem",
	actions: [
		{
			title: "Display delivery items",
			method: async (data) => {
				const { id, status } = data;
				if (status === "Complete") {

					const showDeliveryItems = !data.showDeliveryItems;
					let updatedItem = {
						...data,
						showDeliveryItems,
					};
					updateItemInMatchingQueries({
						store: "deliveries",
						item: updatedItem,
					});

					if (showDeliveryItems) {
						const deliveryItems = data.deliveryItems
							? data.deliveryItems
							: (await VodCoreAPI.fetchDeliveryItems({ deliveryId: id })).items;

						updatedItem = {
							...updatedItem,
							deliveryItems,
						};
						updateItemInMatchingQueries({
							store: "deliveries",
							item: updatedItem,
						});
					}
				}
			},
			type: CONTENT,
		},
	]
};
import { browserHistory } from 'browserHistory'
import * as ACQData from '../../../apis/acq'
import * as Alert from '../../../core/services/alert'
import alt from '../../../core/services/alt'

class Actions {
	requestFailed(errorPayload) {
		const { error } = errorPayload;
		Alert.displayAlert("error", error.exceptionMessage);
		return errorPayload;
	}

	// LICENSES
	fetchLicense(id) {
		return (dispatch) => {
			dispatch();

			ACQData.fetchLicense(id)
				.then(response => {
					this.modelUpdated(apiToModel(response));

				}, (error, api) => {
					this.requestFailed({error, api, id});
				});
				// .catch((error, api) => {
				// 	this.requestFailed({error, api});
				// });
		};
	}

	updateLicense({ location, id, payload, entity }) {
		return (dispatch) => {
			dispatch();

			ACQData.updateLicense(id, modelToAPI(payload))
				.then(response => {
					this.modelSaved({
						targetStore: location.state && location.state.targetStore,
						model: response,
						entity,
					});
				}, (error, api) => {
					this.requestFailed({error, api, payload});
				});
				// .catch((error, api) => {
				// 	this.requestFailed({error, api, payload});
				// });
		}
	}

	createLicense({ location, payload, returnTo, program, entity }) {
		return (dispatch) => {
			dispatch();

			//ACQData.createList(translateFromState(payload))
			ACQData.createLicense(modelToAPI(payload, program))
				.then(response => {
					this.modelSaved({
						targetStore: location.state && location.state.targetStore,
						model: response,
						entity,
					});

					const route = {
						pathname: `/acq/licenses/${response.id}/edit`,
						state: {
							modal: true,
							returnTo,
							targetStore: location.state && location.state.targetStore,
						}
					};

					browserHistory.replace(route);

				}, (error, api) => {
					this.requestFailed({error, api, payload});
				});
				// .catch((error, api) => {
				// 	this.requestFailed({error, api, payload});
				// });
		}
	}

	modelChanged(model) { return model; }
	modelSaved({ model, ...rest }) {
		return {
			model: apiToModel(model), // We need to split the response since it is also used to refresh the list
			apiModel: model,
			...rest,
		}
	}
	modelUpdated(model) { return { model }; }

	// PROGRAMS
	fetchPrograms(searchText, programType = null, preventAdd = false) {
		return (dispatch) => {
			dispatch();

			ACQData.fetchPrograms(searchText, programType)
				.then(response => {

					const programs = response.items.length || preventAdd
						? response.items
						: generateNewProgram(searchText);

					this.programsUpdated(programs);

				}, (error, api) => {
					this.requestFailed({error, api, searchText});
				});
				// .catch((error, api) => {
				// 	this.requestFailed({error, api});
				// });
		};
	}
	programsUpdated(programs) { return programs; }
	selectProgram(name, id, providerId) { return { name, id, providerId }; }
	removeProgram() { return true; }
	removeParentProgram() { return true; }

	// createProgram(payload) {
	// 	return (dispatch) => {
	// 		dispatch();

	// 		ACQData.createProgram(payload)
	// 			.then(response => {
	// 				this.programCreated(response);
	// 			})
	// 			.catch((error, api) => {
	// 				this.requestFailed({error, api, payload});
	// 			});
	// 	}
	// }

	createProgramAndCreateLicense({ location, program, payload, returnTo, entity, }) {
		return (dispatch) => {
			dispatch();
			ACQData.createProgram(program)
				.then(programResponse => ACQData.createLicense(modelToAPI(payload, programResponse)), (error, api) => { this.requestFailed({error, api, program}) })
				.then(licenseResponse => {

					licenseResponse.programId = licenseResponse.program && licenseResponse.program.id;
					this.modelSaved({
						targetStore: location.state && location.state.targetStore,
						model: licenseResponse,
						entity,
					});
					const route = {
						pathname: `/acq/licenses/${licenseResponse.id}/edit`,
						state: {
							modal: true,
							returnTo,
							targetStore: location.state && location.state.targetStore,
						}
					};

					browserHistory.replace(route);

				}, (error, api) => { this.requestFailed({error, api, payload}) })
				// .catch((error, api) => {
				// 	this.requestFailed({error, api, payload});
				// });
		}
	}

	createProgramAndUpdateLicense({ location, program, id, payload, entity }) {
		return (dispatch) => {
			dispatch();

			ACQData.createProgram(program)
				.then(programResponse => ACQData.updateLicense(id, modelToAPI(payload, programResponse)), (error, api) => { this.requestFailed({error, api, program}) })
				.then(licenseResponse => {

					licenseResponse.programId = licenseResponse.program && licenseResponse.program.id;
					this.modelSaved({
						targetStore: location.state && location.state.targetStore,
						model: licenseResponse,
						entity,
					});

					const route = {
						pathname: `/acq/licenses/${licenseResponse.id}/edit`,
						state: {
							modal: true,
							returnTo,
							targetStore: location.state && location.state.targetStore,
						}
					};

					browserHistory.replace(route);

				}, (error, api) => { this.requestFailed({error, api, payload}) })
				// .catch((error, api) => {
				// 	this.requestFailed({error, api, payload});
				// });
		}
	}


	programCreated(model) { return model; }

	// COUNTRIES
	// TODO: This can be cached super hard
	fetchCountries(searchText = "") {

		return (dispatch) => {
			dispatch();

			ACQData.fetchCountries(searchText)
				.then(response => {
					this.countriesUpdated(response.items);
				}, (error, api) => {
					this.requestFailed({error, api, searchText});
				});
				// .catch((error, api) => {
				// 	this.requestFailed({error, api});
				// });
		};
	}
	countriesUpdated( countries ) { return countries; }
	clearCountry() { return true; }

	// GENRES
	// TODO: This can be cached super hard
	fetchGenres(searchText = "") {

		return (dispatch) => {
			dispatch();

			ACQData.fetchGenres(searchText)
				.then(response => {
					this.genresUpdated(response.items);
				}, (error, api) => {
					this.requestFailed({error, api, searchText});
				});
				// .catch((error, api) => {
				// 	this.requestFailed({error, api});
				// });
		};
	}
	genresUpdated( genres ) { return genres; }
	clearGenre() { return true; }

	unmount() { return true; }
}

export default alt.createActions(Actions);

// Helpers

function generateNewProgram(title) {
	return [{
		id: -1,
		name: title,
		type: "New program"
	}];
}

function apiToModel(response) {

	// Split the license windows
	const { windows, ...rest } = response;
	const externalWindows = windows.filter(w => (w.inputSource === "Auto"));
	const internalWindows = windows.filter(w => (w.inputSource === "Manual"));

	return {
		externalWindows,
		internalWindows,
		...rest
	};
}

function modelToAPI(model, program) {
	// Set the programId when saving the license
	if(program) {
		model.program = {
			id: program.id,
		};
		//delete model.programId;
	}

	// Merge the license windows
	const { externalWindows = [], internalWindows = [], ...rest } = model;

	return {
		windows: [
			...externalWindows,
			...internalWindows
		],
		...rest
	};
}
import Moment from 'moment'

export const getAlbumStatus = ({ expires, albumGuid, password, status }) => {
	const albumExpires = expires && Moment(expires) || false;
	const albumActive = albumExpires ? albumExpires.isAfter(new Moment()) : false;
	
	return {
		albumExpires,
		albumIsShared: albumActive && albumGuid && status === "Shared",
		// href: `${window.location.origin}/star/albums/shared/${albumGuid}`,
		href: `star/albums/shared/${albumGuid}`,
		password,
	};
}

export const getMySpaceAlbum = () => {
	return {
		id: 0,
		audience: "Private",
		displayName: "My private STAR assets (permanent album)",
	}
}
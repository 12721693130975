import React from 'react'
import MenuItem from '@mui/material/MenuItem'

import { browserHistory } from 'browserHistory'

import appConfig from 'config'

const Link = ({
	name,
	displayName,
	componentType,
	itemData = {},
	otherProps = {},
	href,
	actionName,
	actionBody,
	onChange,
	children,
	options = {},
}) => {
	let linkProps = {}, modalLinkProps = {};
	if (otherProps.isExtraAction) {
		linkProps = {
			onClick: () => {
				otherProps.onLinkClick();
			}
		}
	} else if (actionName) {
		linkProps = {
			onClick: () => {
				onChange(actionBody);

				// We use onLinkClick because material-ui does not close the Menu when a MenuItem is clicked :(
				// It's passed from the menu component
				if (otherProps.onLinkClick) {
					otherProps.onLinkClick();
				}
			},
		};
	} else if (componentType === "link") {
		const formattedHref = getFormattedHref(href ?? "", itemData);
		linkProps = {
			href: formattedHref,
			onClick: (e) => {
				e.preventDefault();
				e.stopPropagation();

				if (formattedHref.includes("://")) {
					window.open(formattedHref, "_blank");
					return;
				}
				
				const queryStringStart = formattedHref.indexOf("?");
				const pathname = formattedHref.substring(0, queryStringStart);
				const search = formattedHref.substr(queryStringStart);
				browserHistory.push({
					pathname,
					search,
				});
			},
		};

		if (options?.regularLink) { // TODO: Should be a new componentType
			return (
				<a
					key={name}
					className="c6-link"
					{...linkProps}
				>
					{displayName}
				</a>
			);
		}

	} else if (componentType === "modal") {
		modalLinkProps = {
			onClick: () => {
				if (componentType === "modal" && typeof (otherProps.openModal) === "function") {
					const idComponent = children.find(c => c.name === "id");
					otherProps.openModal({
						modalName: name,
						itemData: itemData,
						id: idComponent?.value ?? itemData.id,
					});
					// We use onLinkClick because material-ui does not close the Menu when a MenuItem is clicked :(
					// It's passed from the menu component
					if (otherProps.onLinkClick) {
						otherProps.onLinkClick();
					}
				}
			},
		};
	}

	return (
		<MenuItem
			key={name}
			{...linkProps}
			{...modalLinkProps}
		>
			{displayName}
		</MenuItem>
	);
};

export default Link;

function getFormattedHref(href, itemData) {
	let formatted = `${href}`;
	const regex = /\{[\w]+\}/gmi;
	const tokens = href.match(regex);
	tokens?.forEach(token => {
		const tokenWithoutBrackets = token.replace("{", "").replace("}", "");
		const value = itemData[tokenWithoutBrackets];
		formatted = formatted.replace(token, value);
	});
	const module = window.location.pathname.split("/")[appConfig.app.basePath?.length ? 2 : 1];
	const modulePrefix = href?.includes("://")
		? ""
		: `${module}/`;
	return `${modulePrefix}${formatted}`.replace(/[^:]\/\//, "/"); // Remove any duplicate slashes
}


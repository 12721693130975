import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import keydown from 'react-keydown'
import { withRouter } from 'react-router'
import { browserHistory } from 'browserHistory'

import { Filter, Left, Right } from '../../../components/filter'
import Button from '../../../components/ui/controls/button'
import Switch from '../../../components/ui/controls/switch'
// import Pick from '../../../components/ui/controls/pick'
import Dropdown from '../../../components/ui/controls/dropdown'
import ApproveDialog from './approveDialog'

import { getMonetizationModels, getPlatformStates, getProgramTypeStates, getSourceStates } from '../utils'
import { saveBlobToDisc } from '../../../utils/save'
import * as PlannerAPI from '../../../apis/planner'
import { getPublishWindowPayload } from './app'
import { displayAlert } from '../../../core/services/alert'
import Const from '../../../core/constants'

import './header.css'

import appConfig from 'config'

const DISABLE_ADDON_VIEWS = !appConfig.features.plannerEnableAddonViews;

@withRouter
export default class Header extends React.Component {

	static propTypes = {
		date: PropTypes.object,
		filters: PropTypes.object,
		publishWindowsFilters: PropTypes.object,
		activePublishWindowsTodayFilters: PropTypes.object,
	}

	state = {
		isLoadingExcel: false,
	}

	constructor(props) {
		super(props);

		this.handleNavPrev = this.handleNavPrev.bind(this);
		this.handleNavNext = this.handleNavNext.bind(this);
		this.handleNavToday = this.handleNavToday.bind(this);
	}

	@keydown("left")
	handleNavPrev() {
		const unit = this.props.filters.calendarView.toLowerCase().includes("week") ? "week" : "month";
		const newDate = this.props.date.clone().subtract(1, unit);
		browserHistory.replace({
			pathname: `/planner/calendar/${newDate.format(Const.DATE_FORMAT)}`,
		});
	}

	@keydown("right")
	handleNavNext() {
		const unit = this.props.filters.calendarView.toLowerCase().includes("week") ? "week" : "month";
		const newDate = this.props.date.clone().add(1, unit);
		browserHistory.replace({
			pathname: `/planner/calendar/${newDate.format(Const.DATE_FORMAT)}`,
		});
	}

	@keydown("ctrl+t")
	handleNavToday() {
		const newDate = moment();
		browserHistory.replace({
			pathname: `/planner/calendar/${newDate.format(Const.DATE_FORMAT)}`,
		});
	}

	exportExcel = async () => {
		try {
			this.setState({ isLoadingExcel: true });
			const isCatalogueView = this.props.filters.calendarView === "catalogueView";
			const payload = isCatalogueView
				? getPublishWindowPayload(this.props.filters, this.props.publishWindowsFilters, this.props.date, this.props.filters.calendarView, true, false, false)
				: getPublishWindowPayload(this.props.filters, this.props.publishWindowsFilters, this.props.date, this.props.filters.calendarView);
			const fileName = `planner_${payload.platform}_${payload.start ?? payload.IsActiveOn}.xlsx`;
			const excel = await PlannerAPI.excelExport(payload);
			saveBlobToDisc(excel, fileName);
			this.setState({ isLoadingExcel: false });	
		} catch (error) {
			displayAlert("error", "Failed to export, please try again.");
			console.error("Error exporting excel", error);
			this.setState({ isLoadingExcel: false });
		}
	}

	render() {
		const { filters, handleFilter, platforms, isLoadingApproveOrUndo } = this.props;
		const unit = filters.calendarView.toLowerCase().includes("week") ? "week" : "month";
		const regularTitle = this.props.date.format(`MMMM YYYY${unit === "week" ? "[, week ] w" : ""}`);
		
		const isCatalogueView = this.props.filters.calendarView === "catalogueView";
		const title = isCatalogueView ? "Current" : regularTitle;

		const hidePlatform = platforms.items.filter(p => p.name !== "all").length <= 1;

		return (
			<Filter>
				<Left>
					{!isCatalogueView && <Button
						type=""
						title="Today"
						hoverTitle={`Go to the current ${unit} [CTRL-T]`}
						onClick={this.handleNavToday}
					/>}
					{!isCatalogueView && <Button
						type="prev"
						hoverTitle={`Go to previous ${unit} [ARROW LEFT]`}
						onClick={this.handleNavPrev}
					/>}
					{!isCatalogueView && <Button
						type="next"
						hoverTitle={`Go to next ${unit} [ARROW RIGHT]`}
						onClick={this.handleNavNext}
					/>}
					<h1>{title}</h1>
					<Dropdown
						name="view"
						onChange={(e) => this.props.onViewChange(e.target.value)}
						currentState={filters.calendarView}
						states={getViewStates()}
					/>
					{/* <Dropdown
						name="highprofileEvents"
						title="Show"
						onChange={() => {}}
						currentState={["ott-sports"]}
						states={getHighProfileStates()}
						buttonTextMode="long"
					/> */}
				</Left>
				<Right>
					{/* <Dropdown
						name="plan"
						title="Plan"
						onChange={() => {}}
						currentState="cmore"
						states={getPlanStates()}
					/> */}
					{/* <Dropdown
						name="right"
						title="Right"
						onChange={handleFilter.bind(this, "filter")}
						currentState={filters.filter.right}
						states={getRightStates()}
					/> */}
					{!hidePlatform && (
						<Dropdown
							name="platform"
							title="Platform"
							onChange={handleFilter.bind(this, "filter")}
							currentState={filters.filter.platform}
							states={getPlatformStates(platforms)}
						/>
					)}
					{appConfig.features.plannerC80 && (
						<Dropdown
							name="_monetizationModel"
							title="License"
							onChange={handleFilter.bind(this, "filter")}
							currentState={filters.filter._monetizationModel}
							states={getMonetizationModels()}
						/>
					)}
					<Dropdown
						name="owner"
						title="Source"
						onChange={handleFilter.bind(this, "filter")}
						currentState={filters.filter.owner}
						states={getSourceStates(filters.filter.platform, platforms)}
					/>
					<Switch
						name="programTypes"
						title="Type"
						onChange={handleFilter.bind(this, "filter")}
						currentState={filters.filter.programTypes}
						states={getProgramTypeStates()}
					/>
					{appConfig.features.plannerEnableExcelExport && (
						<Button
							type="file-excel"
							title={this.state.isLoadingExcel ? "Exporting..." : "Export"}
							hoverTitle="Export to .xlsx file"
							onClick={this.exportExcel}
							disabled={this.state.isLoadingExcel}
						/>
					)}
					<ApproveDialog
						dirtyPublishWindows={this.props.dirtyPublishWindows}
						readonly={this.props.readonly}
						isLoadingApproveOrUndo={isLoadingApproveOrUndo}
					/>
				</Right>
			</Filter>
		);
	}
}

function getViewStates() {
	return [
		{ key: "dayGridMonth", text: "Month", description: "", icon: "grid_on" },
		{ key: "resourceTimelineMonth", text: "Timeline", description: "", icon: "chart-gantt", disabled: true/*DISABLE_ADDON_VIEWS*/ },
		{ key: "dayGridWeek", text: "Week", description: "", icon: "view_week1", disabled: DISABLE_ADDON_VIEWS },
		{ key: "listMonth", text: "Schedule", description: "", icon: "view_day", disabled: DISABLE_ADDON_VIEWS },
		{ key: "catalogueView", text: "Catalogue", description: "", icon: "view_day", disabled: DISABLE_ADDON_VIEWS },
	];
}

import React from 'react'
import PropTypes from 'prop-types'
import { browserHistory } from 'browserHistory'

import { Filter, Left, Right } from '../filter'

export default class Header extends React.PureComponent {

	static propTypes = {
		title: PropTypes.string,
		backToTitle: PropTypes.string,
		description: PropTypes.string,
	}

	render() {
		const {
			title = "",
			backToTitle = "",
			description,
			children,
		} = this.props;

		const descriptionEl = description ? <p>{description}</p> : null;

		return (
			<Filter>
				<Left>{getTitle(title, backToTitle)}{descriptionEl}</Left>
				<Right>{children}</Right>
			</Filter>
		);
  	}
}

// Helpers

function getTitle(title, backToTitle) {
	if(title && backToTitle) {
		return <h1 className="title"><span onClick={browserHistory.goBack} className="titleLink">{backToTitle}</span> / {title}</h1>;
	}
	else if(title) {
		return <h1 className="title">{title}</h1>;
	}

	return null;
}
import React from 'react'

import ContentItem, { Content } from '../../../../components/list/contentItem'

import '../../../../components/listItems/standard.css'

const ServiceItem = (props) => {
	const {
		name,
	} = props;

	return (
		<ContentItem data={props}>
			<Content>
				<h1>{name}</h1>
			</Content>
			<Content>
				<span className="fg-red">Classic services cannot be setup or edited in Comet, please contact <a target="_blank" className="c6-link" href="mailto:support@junefirst.tv">support@junefirst.tv</a> for assistance.</span>
			</Content>
		</ContentItem>
	);
};

export default ServiceItem;
import React from 'react'

import Editor, { parseUi } from '../../../components/editor/index'
import { hasAccessToPath } from '../../../core/services/auth'

import ProgramPreview from './preview'

import { getUser } from '../../../core/services/auth'
import { getProgramTitle } from '../utils'
import { withQueryClient } from '../../../components/editor/index'

import * as ReviewsAPI from '../../../apis/reviews'

import schema from './schema'
import uiSchema from './uiSchema'

import './app.css'
import useReviewsSources from '../../../core/queries/reviews/useReviewsSources'
import useReviewsProgram from '../../../core/queries/reviews/useReviewsProgram'

const ReviewsEditor = (props) => {

	const { data: program } = useReviewsProgram(props.location.query?.programId);

	const [searchResults, setSearchResults] = React.useState([]);
	const searchPrograms = React.useCallback(
		(searchText) => {
			ReviewsAPI.fetchPrograms({ searchText })
				.then(response => {
					setSearchResults(response?.items ?? []);
				});
		},
		[]
	);

	const { data: sources } = useReviewsSources({
		filter: { orderBy: "name", pageSize: 1000 },
	});

	return (
		<Editor
			className="c6-reviews-reviews-editor"
			layout="grid"
			api="reviews"
			entity="review"
			getSchema={getSchema.bind(this, program, sources)}
			getUiSchema={getUiSchema.bind(this, searchPrograms, program, searchResults)}
			customFields={{}}
			loadPayloadTransform={loadPayloadTransform}
			savePayloadTransform={savePayloadTransform}
			hasEditAccess={hasAccessToPath(props.routes, "editor")}
			{...props}
		>
			<ProgramPreview />
		</Editor>
	);
};

export default withQueryClient(ReviewsEditor);

function getSchema(program, sources, model, isNew, location, route, params) {
	if (location.query?.programId && program === undefined || !sources?.items) {
		return { type: "object", properties: {} };
	}

	if (isNew && location.query?.programId && program !== undefined && !model?.programId) {
		model.programId = parseInt(location.query.programId);
		model.programTitle = getProgramTitle(program);
	}

	schema.properties.source.properties.id.oneOf = sources.items.map(s => ({
		const: s.id,
		title: s.name,
	}));

	return schema;
}

function getUiSchema(searchPrograms, program, searchResults, model, isNew, location, route, params) {
	const ui = { ...uiSchema };

	ui.programId = {
		...ui.programId,
		"ui:options": {
			...ui.programId["ui:options"],
			dataSource: searchResults.map(item => ({
				id: item.id,
				displayName: getProgramTitle(item)
			})),
			fetch: searchPrograms,
			defaultText: getProgramTitle(model) || getProgramTitle(program),
		},
	};

	ui.source.id["ui:readonly"] = !isNew;

	ui.notAvailablePermanent["ui:widget"] = model.notAvailable ? "checkbox" : "hidden";

	return ui;
}

function loadPayloadTransform({ model }) {
	return {
		...model,
		isPositive: `${model.isPositive ?? null}`,
		notAvailable: model.status === "temporaryOffline" || model.status === "permanentOffline",
		notAvailablePermanent: model.status === "permanentOffline",
	};
}

function savePayloadTransform({ formData, ...rest }) {
	// Include added by if the quote is newly added
	const quotes = formData?.quotes?.map(quote => ({
		...quote,
		addedBy: quote.id ? quote.addedBy : getUser().username,
	}));

	let isPositiveBool = null;
	if (formData.isPositive === "true") isPositiveBool = true;
	else if (formData.isPositive === "false") isPositiveBool = false;

	let status = "ok";
	if (formData.notAvailable && formData.notAvailablePermanent) status = "permanentOffline";
	else if (formData.notAvailable) status = "temporaryOffline";

	// Calculate normalizedScore so that the item in the list is updated
	let normalizedScore = formData.normalizedScore;
	const score = parseInt(formData.score);
	const maxScore = parseInt(formData.maxScore);
	if (!isNaN(score) && !isNaN(maxScore)) {
		normalizedScore = `${(score / maxScore) * 10}`;
	}

	return {
		...rest,
		formData: {
			...formData,
			quotes,
			isPositive: isPositiveBool,
			status,
			normalizedScore,
		},
	};
}
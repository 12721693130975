import React from 'react'
import PropTypes from 'prop-types'

import  Main from '../../../../components/ui/main'
import Empty from '../../../../components/list/empty'

import Filter from './filter'
import   List from './list'

export default class Lists extends React.Component {

	static propTypes = {
		list: PropTypes.shape({
			items: PropTypes.array,
			filters: PropTypes.object,
			isLoading: PropTypes.bool,
		}).isRequired,

		groups: PropTypes.shape({
			items: PropTypes.array,
			filters: PropTypes.object,
			isLoading: PropTypes.bool,
		}).isRequired,
	}

	// TODO!: Try to remove this
	// https://3.basecamp.com/3091592/buckets/1615235/todos/336613940
	handleOutsideDrop = (id, originalIndex) => {
		this.listRef.decoratedComponentInstance.handleOutsideDrop(id, originalIndex);
	}

	render() {
		const { list, groups, areas, selectionsConfiguration, sectionAssets, serviceId } = this.props;
		const { items, filters, isLoading } = list;
		const { items: groupItems, _selectedGroup } = groups;

		// Add padding if we have more than 1 area since that will make the list.jsx component render group titles
		const padding = areas.items?.length > 1;

		return (
			<div className="c6-mainapp c6-layout-grid">
				<Filter
					conditions={filters}
					groups={groupItems}
					selectedGroup={_selectedGroup}
					selectionsConfiguration={selectionsConfiguration}
					serviceId={serviceId}
				/>
				<Main padding={padding}>
					<List
						ref={r => this.listRef = r}
						items={items.filter(i => !i._isHidden)}
						conditions={filters}
						selectedGroup={_selectedGroup}
						areas={areas}
						selectionsConfiguration={selectionsConfiguration}
						sectionAssets={sectionAssets.isLoading ? null : transformSectionAssets(sectionAssets.items)}
					>
						<Empty v2={true} title="" isLoading={isLoading}>
							Please select a section above.
						</Empty>
					</List>
				</Main>
			</div>
		);
	}
}

function transformSectionAssets(sectionAssets) {
	return sectionAssets.reduce((acc, cur) => ({
		...acc,
		[cur.listId]: cur.assets,
	}), {});
}
import React from 'react'
import { Route, IndexRoute } from 'react-router'

import Module from '../app'

import ReviewsApp from './reviews/app'
import ReviewEditor from './revieweditor/app'
import SourcesApp from './sources/app'
import SourceEditor from './sourceeditor/app'
import ProgramsApp from './programs/app'
import ProgramEditor from './programeditor/app'

export default class ReviewsModule {
	static routes() {
		return (
			<Route path="reviews" component={Module} module="reviews">
				<Route path="reviews">
					<IndexRoute component={ReviewsApp} />
					<Route path=":id/edit" component={ReviewEditor} modal={true} returnTo="/reviews/reviews" />
					<Route path="create" component={ReviewEditor} modal={true} returnTo="/reviews/reviews" />
				</Route>
				
				<Route path="sources">
					<IndexRoute component={SourcesApp} />
					<Route path=":id/edit" component={SourceEditor} modal={true} returnTo="/reviews/sources" wideModal={true} />
					<Route path="create" component={SourceEditor} modal={true} returnTo="/reviews/sources" wideModal={true} />
				</Route>

				<Route path="programs">
					<IndexRoute component={ProgramsApp} />
					<Route path=":id/edit" component={ProgramEditor} modal={true} returnTo="/reviews/programs" />
					<Route path="create" component={ProgramEditor} modal={true} returnTo="/reviews/programs" />
				</Route>

				{["blockbuster", "tvnu"].map(customer => (
					<Route path={customer} key={customer}>
						<Route path="reviews">
							<IndexRoute component={ReviewsApp} customer={customer} />
							<Route path=":id/edit" component={ReviewEditor} modal={true} returnTo={`/reviews/${customer}/reviews`} />
							<Route path="create" component={ReviewEditor} modal={true} returnTo={`/reviews/${customer}/reviews`} />
						</Route>
						<Route path="programs">
							<IndexRoute component={ProgramsApp} customer={customer} />
							<Route path=":id/edit" component={ProgramEditor} modal={true} returnTo={`/reviews/${customer}/programs`} />
							<Route path="create" component={ProgramEditor} modal={true} returnTo={`/reviews/${customer}/programs`} />
						</Route>
					</Route>
				))}
			</Route>
		);
	}
}
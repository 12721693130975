import React from 'react'
import { browserHistory } from 'browserHistory'
import appConfig from 'config'

import Editor, { parseUi } from '../../../../components/editor/index'
import * as SelectionsAPI from '../../../../apis/selections'

import { hasAccessToPath } from '../../../../core/services/auth'

import schema from './schema'
import uiSchema from './uiSchema'

import { Regions } from '../../../../core/constants'

export default props => (
	<Editor
		layout="grid"
		api="selections"
		entity="section"
		getSchema={getSchema}
		getUiSchema={getUiSchema}
		getCommands={getCommands}
		hasEditAccess={hasAccessToPath(props.routes, "editor")}
		{...props}
	/>
);

function getCommands({ entity, location, route, params }) {
	return {
		fetchItem: ({ id }) => SelectionsAPI.fetchGroup(id),
		updateItem: ({ id }, payload) => SelectionsAPI.updateGroup(id, payload),
		createItem: (payload) => {
			payload.parentSectionId = location.query.parentId;
			return SelectionsAPI.insertGroup(payload);
		},
	}
}

function getSchema(model, isNew, location, route, params) {
	schema.properties.displayName.default = `New section in ${location.query.parentDisplayName ?? "Root"}`;
	schema.properties.version.properties.name.default = location.query.parentVersion ?? "Swedish";
	return schema;
}

function getUiSchema(model, isNew, location, route, params) {
	return uiSchema;
}
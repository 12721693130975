import React from 'react'
import { getModuleRoles } from '../../core/services/auth'
import { browserHistory } from 'browserHistory'

export function getProgramTitle(programOrReview) {
    const title = programOrReview?.programTitle ?? programOrReview?.title ?? "";
    const seasonNumber = programOrReview?.seasonNumber ? ` season ${programOrReview?.seasonNumber}` : "";
    const programReleaseYear = programOrReview?.programReleaseYear ?? programOrReview?.releaseYear;
    const releaseYear = programReleaseYear ? ` (${programReleaseYear})` : "";
    return `${title}${seasonNumber}${releaseYear}`;
}

export function getLanguages(customer, languagesData) {
	return ((customer ? getModuleRoles("reviews").map(l => l.split(".")[1]) : languagesData?.items.map(l => l.name)) ?? [])
		.sort((lA, lB) => lA.toLowerCase() === "english" ? 1 : -1) // Put English last
		.map(lang => {
			return { key: lang, text: lang.substr(0, 1).toUpperCase() + lang.substr(1) }; // Uppercase first
		});
}

export function renderImdbIdOrLinks(imdbId, title, programId, customer, location) {
	if (imdbId) {
		return (
			<a onClick={onLinkClick} className="c6-link" target="_blank" href={`http://imdb.com/title/${imdbId}`}>{imdbId}</a>
		);
	}

	function openProgramEditor(e) {
		e.stopPropagation();
		e.preventDefault();
		const customerRoute = customer ? `${customer}/` : "";
		browserHistory.push({
			pathname: `/reviews/${customerRoute}programs/${programId}/edit`,
			state: {
				modal: true,
				returnTo: `${location.pathname}${location.search}`,
			},
		})
	}

	return (
		<span className="imdb">
			<a onClick={openProgramEditor} className="c6-link">Add IMDb ID</a>
			<span>|</span>
			<a onClick={onLinkClick} className="c6-link" target="_blank" href={`https://www.imdb.com/find?q=${title}`}>Search IMDb</a>
		</span>
	);
}

function onLinkClick(e) {
	e.stopPropagation();
}
import { CONTENT, MENU } from '../../../../components/actions'

import Actions from '../../actions'

export default {
	targetComponent: "ContentItem",
	actions: [
		{
			title: "Edit",
			method: (data, sourceProps, location) => {
				Actions.edit({
					pathname: `/selections/admin/templates/${data.id}/edit`,
					returnTo: location.pathname,
				});
			},
			type: CONTENT,
		},
		{
			title: "Edit",
			icon: "create",
			method: (data, sourceProps, location) => {
				Actions.edit({
					pathname: `/selections/admin/templates/${data.id}/edit`,
					returnTo: location.pathname,
				});
			},
			type: MENU,
		},
		{
			title: "Remove",
			icon: "remove_circle",
			method: (data, sourceProps) => {
				Actions.deleteTemplate(data);
			},
			type: MENU,
			confirm: true,
			dangerous: true
		}
	]
};
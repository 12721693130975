import moment from 'moment'

export function formatToMinutes(duration) {
	if (duration?.includes(":")) {
		const [hours, minutes, seconds] = duration.split(":");
		return `${parseInt(hours) * 60 + parseInt(minutes)}min`;
	}

	return `0min`;
}

export function formatToTime(date) {
	if (!date) return null;

	try {
		const d = moment(date);
		return d.format("HH:mm");
	} catch (e) {
		return null;
	}
}

export function getClassName({ item, extra = "", root = false, value, parentReadOnly }) {
	if (!item) {
		return "";
	}
	
	let layout = "";
	if (root || item?.componentType === "container") {
		layout = item?.style?.layout === "rows" ? " flex-col" : " flex-row";
	}

	const dynamicCssClass = value?.dynamicCssClass ?? item?.dynamicCssClass;

	let className = `template-item ${item?.componentType ?? ""} ${layout} ${item?.style?.cssClass ?? ""} ${extra}`;
	className += ` __name_${item?.name ?? "missing"}__`; // to help with debugging Elements
	className += ` __id_${item?.id ?? "missing"}__`; // to help with debugging Elements
	className += item.valueIndex !== undefined ? ` __valueIndex_${item.valueIndex}__` : ""; // to help with debugging Elements
	className += !root && item?.style?.visible !== true ? " hidden" : "";
	className += (item?.readOnly ?? parentReadOnly) ? " readonly" : "";
	className += root ? " root" : "";
	className += dynamicCssClass ? ` ${dynamicCssClass}` : "";

	return className;
}

export function findFirstScrollableParent(element) {
	if (!element || element === document.body || !element.parentElement || element.parentElement === document.body) {
		return document.body;
	}

	const overflow = getComputedStyle(element.parentElement).getPropertyValue("overflow");
	const overflowY = getComputedStyle(element.parentElement).getPropertyValue("overflow-y");
	if (overflow === "auto" || overflow === "scroll" || overflowY === "auto" || overflowY === "scroll") {
		return element.parentElement;
	}

	return findFirstScrollableParent(element.parentElement);
}
import React from 'react'
import { Link } from 'react-router'
import { browserHistory } from 'browserHistory'

import App from '../app'
import { Info, getRouteName } from '../comet'
import { Filter, Left, Right } from '../filter'
import Button from '../ui/controls/button'

import List from './list'
import { Filters } from './filters'
import { getItems } from './utils'

import { getReturnTo } from '../../core'
import usePrevious from '../../core/hooks/usePrevious'
import { VisibilityContext } from '../cms/visibilityContext'

import './app.css'

export default function GenericListApp(props) {
	const {
		module,
		datastore,
		state,
		filterConfig,
		pathnameCreate,
		onCreateClick,
		collapseFiltersDefault,
		virtualize = true,
		groupItemsBy,
		getGroupTitle,
		getGroupTitleAdvanced,
		getGroupTitleWhenSearching,
		sortItemsBy,
		sortItemsByAdvanced,
		groupItemsWhenSearching,
		padding,
		className = "",
		showInfoBar = false,
		rightHeaderExtra,
		leftHeaderExtra,
		visibilityState,
	} = props;
	
	const {
		isLoading,
		data,
		error,
		filters,
		fetchNextPage,
		hasNextPage,
	} = state;

	const handleCreateNew = () => {
		if (onCreateClick) {
			onCreateClick();
		} else {
			browserHistory.push({
				pathname: pathnameCreate ?? `/${module}/${datastore}/create`,
				state: {
					modal: true,
					returnTo: props.location.pathname,
				},
			});
		}
	};

	const backButton = renderBackButton(props);
	const heading = props.textHeading ?? "";

	const listRef = React.useRef();
	const prevFilterValues = usePrevious(filters.values);
	React.useEffect(
		() => {
			// Make sure we scroll to top after filter changes
			if (shouldUpdateScroll(prevFilterValues, filters.values)) {
				setTimeout(() => {
					listRef.current?.scrollTo({ top: 0 });
				});
			}			
		},
		[prevFilterValues, filters.values, listRef.current]
	);

	const [hideableContainersState, setHideableContainersState] = React.useState({});

	const mergedVisibilityState = React.useMemo(
		() => ({
			...visibilityState,
			...hideableContainersState,
		}),
		[visibilityState, hideableContainersState]
	);

	const toggleContainer = React.useCallback(
		(containerName, id) => {
			setHideableContainersState(state => {
				return {
					...state,
					[`${containerName}_${id}`]: !state[`${containerName}_${id}`],
				};
			});
		},
		[setHideableContainersState],
	);
		
	const items = props.items ?? getItems(data);
	const appKey = `c6-${module}-${datastore}`;
	return (
		<App
			name={`${appKey} ${className} c6-generic-app`}
			layout="grid"
			isLoading={isLoading}
		>
			<Filter>
				<Left>
					{!!(backButton || heading?.length) && (
						<h1>
							{backButton}{backButton ? ` / ${heading}` : heading}
						</h1>
					)}
					<Filters
						filterConfig={filterConfig}
						filters={filters}
						collapseFiltersDefault={collapseFiltersDefault}
						appKey={appKey}
					/>
					{leftHeaderExtra}
				</Left>
				<Right>
					{rightHeaderExtra}
					{(onCreateClick || pathnameCreate) && (
						<Button
							type={props.iconCreate ?? "add"}
							title={props.textCreate ?? "Create"}
							onClick={handleCreateNew}
						/>
					)}
				</Right>
			</Filter>
			<VisibilityContext.Provider value={mergedVisibilityState}>
				<List
					items={items}
					isLoading={isLoading}
					textEmpty={props.textEmpty ?? "Empty"}
					hasNextPage={hasNextPage}
					fetchNextPage={fetchNextPage}
					virtualize={virtualize}
					groupItemsBy={groupItemsBy}
					getGroupTitle={getGroupTitle}
					getGroupTitleAdvanced={getGroupTitleAdvanced}
					getGroupTitleWhenSearching={getGroupTitleWhenSearching}
					sortItemsBy={sortItemsBy}
					sortItemsByAdvanced={sortItemsByAdvanced}
					groupItemsWhenSearching={groupItemsWhenSearching}
					padding={padding}
					ref={listRef}
					filters={filters}
					onToggleContainer={toggleContainer}
				>
					{props.children}
				</List>
			</VisibilityContext.Provider>
			{showInfoBar && getInfo(props.location, data, items)}
		</App>
	);
}

// HELPERS
function getInfo(location, data, items) {
	const numberOfItems = data?.pages?.[0]?.numberOfItems;
	const itemsDisplayed = items?.length;
	const itemsText = numberOfItems != null && itemsDisplayed != null
		? `- displaying ${itemsDisplayed} of ${numberOfItems}`
		: "";

	return (
		<Info>
			{getRouteName(location)} {itemsText}
		</Info>
	);
}

function renderBackButton({ location = {}, routes = [], params = {}, textBackButton }) {
	const returnTo = getReturnTo(location, routes, params);
	if (!returnTo || returnTo === "/" || !textBackButton?.length) {
		return null;
	}

	function handleClick(e) {
		e.preventDefault();
		browserHistory.replace(returnTo);
	}

	return (
		<Link to={returnTo} onClick={handleClick}>
			{textBackButton}
		</Link>
	);
}

function shouldUpdateScroll(prevFilterValues, currentFilterValues) {
	const filtersDidChange = !shallowCompare(prevFilterValues, currentFilterValues);
	return filtersDidChange;
}

function shallowCompare(obj1 = {}, obj2 = {}) {
	return Object.keys(obj1).length === Object.keys(obj2).length
		&& Object.keys(obj1).every(key => obj1[key] === obj2[key]);
}
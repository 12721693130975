import React from 'react'

import App from '../../../components/app'
import { StandardInfo } from '../../../components/comet'
import Main from '../../../components/ui/main'
import Header from '../../../components/headers/header'
import Empty from '../../../components/list/empty'

import List from './list'
import Store from '../store'
import Actions from '../actions'

const MODULE = "shield"
const PARENT_ENTITY = "login";
const ENTITY =  "module"
const TEXT_HEADING = "Modules"
const TEXT_EMPTY = "No modules? Just add them from the module settings!"

const TEXT_CREATE = "Add new module"
const ICON_CREATE = "add"

//@Keydown
export default class LoginModulesApp extends React.Component {

	constructor(props) {
		super(props);

		this.onChange = this.onChange.bind(this);
		this.handleSetProperty = this.handleSetProperty.bind(this);
		this.handleToggle = this.handleToggle.bind(this);

		// App data
		this.loginId = this.props.params.id;

		this.state = {
			...Store.getState(),
		};
	}

	componentDidMount() {
		Store.listen(this.onChange);

		Actions.fetchItems(`${ENTITY}s`, {returnAsTree: true});
		Actions.fetchItems("roles");
		Actions.fetchItem("login", { id: this.loginId });

	}

	componentWillUnmount() {
		Actions.unmount();
		Store.unlisten(this.onChange);
	}

	onChange(state) {
		this.setState(state);
	}

	handleSetProperty(data, entity, propertyName, e) {
		const property = {};
		property[propertyName] = e.target.value;

		const payload = { data, id: this.loginId, property };
		const entities = { parentEntity: PARENT_ENTITY, entity };

		Actions.setItemEntityProperty(entities, payload);
	}

	handleToggle(data, entity, e, active) {
		const { login } = this.state.item;
		const payload = { data, id: this.loginId };
		const entities = { parentEntity: PARENT_ENTITY, entity };

		if(active) {
			if(!login.id) {
				return false;
			}

			if(data.parentModuleId) {
				payload.level = getAccessLevel(login, data.parentModuleId);
			}

			Actions.addItemEntity(entities, payload);
		}
		else {
			Actions.removeItemEntity(entities, payload);
		}
	}

	render() {
		let filterTitle = "Access rights";
		if(this.state.item.login && this.state.item.login.firstName) {
			const { firstName = "", lastName = "" } = this.state.item.login;
			filterTitle = `${firstName} ${lastName} / Access rights`;
		}

	    return (
			<App
				name="c6-shield-logins"
				layout="grid"
				isLoading={this.state.isLoading}>
					<Header title={filterTitle} backToTitle="Users" />
					<Main>
						<List
							isLoading={this.state.isLoading}

							login={this.state.item.login}
							items={this.state.modules}
							roles={this.state.roles}

							handleSetProperty={this.handleSetProperty}
							handleToggle={this.handleToggle}

							filters={this.state.filters} >
							<Empty isLoading={this.state.isLoading}>
								{TEXT_EMPTY}
							</Empty>
						</List>
					</Main>
				<StandardInfo location={this.props.location} />
			</App>
		);
	}
}

// HELPERS
function getAccessLevel(login, moduleId) {
	const module = login.modules.find(m => m.id === moduleId);
	return module ? module.accessLevel : null;
}
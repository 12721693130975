import { browserHistory } from 'browserHistory'
import moment from 'moment-timezone'

import alt from '../../core/services/alt'
import * as APIS from '../../apis/'
import * as Alert from '../../core/services/alert'

import { syncDeepFindGroup } from '../../utils/groups'
import { getFormattedDate, isHourlyOrMinutelyList } from './shared/utils'
import { translateData } from './shared/templateConfig'
import defaultTemplateConfig from './shared/defaultTemplateConfig'

import Const, { Regions } from '../../core/constants'
import ListConstants from './ott/constants'
import appConfig from 'config'

const API = "selections"

class Actions {
    // GROUPS
	setListGroup(groupId) {
		return groupId;
	}

	selectGroup(group) {
		const { serviceId } = group;
		const route = {
			pathname: `/selections/service/${serviceId}`,
			query: { groupId: group.id }
		};
		browserHistory.push(route);
		return group;
	}

	updateSelectedGroup(groupId, groupItems) {
        const group = syncDeepFindGroup(groupId, groupItems);
        return group;
	}

	// SECTIONS
	updateSelectedSection(groupId, sectionItems) {
        const group = sectionItems.find(i => i.id === groupId);
        return group;
	}

	// createSection(selectedSection) {
		// return (dispatch) => {

			// browserHistory.push({
			// 	pathname: `/selections/groups/create`,
			// 	query: {
			// 		parentId: selectedSection?.id,
			// 		parentDisplayName: selectedSection?.displayName,
			// 		versionId: selectedSection?.version?.id,
			// 	},
			// 	state: {
			// 		modal: true,
			// 	},
			// });

			// const { id = null, displayName = "Root", version } = selectedSection || {};
			// const payload = {
			// 	id: -1,
			// 	parentSectionId: id,
			// 	parentDisplayName: displayName,
			// 	name: "NEW_SECTION",
			// 	displayName: `New section under ${displayName}`,
			// 	version,
			// };

			// dispatch(payload);

			// APIS[API].insertGroup(payload)
			// 	.then(response => {
			// 		this.itemUpdated({ entity: "section", model: response, originalId: payload.id });
			// 	}, (error, request) => {
			// 		this.rollbackCreateSection(payload.id);
			// 		this.requestFailed({ error, request });
			// 	});
		// }
	// }
	rollbackCreateSection(id) {
		return id;
	}
	removeSection(id) {
		return (dispatch) => {
			dispatch(id);

			APIS[API].deleteGroup(id)
				.then(response => {
					console.log(response);
				}, (error, request) => {
					this.rollbackDeleteSection(id);
					this.requestFailed({ error, request });
				});

		}
	}

	hideSection(id) {
		return (dispatch) => {
			dispatch(id);

			APIS[API].hideGroup(id)
				.then(response => {
					this.itemUpdated({ entity: "section", model: response });
				}, (error, request) => {
					// this.rollbackDeleteSection(payload.id);
					this.requestFailed({ error, request });
				});

		}
	}

	unhideSection(id) {
		return (dispatch) => {
			dispatch(id);

			APIS[API].unhideGroup(id)
				.then(response => {
					this.itemUpdated({ entity: "section", model: response });
				}, (error, request) => {
					// this.rollbackDeleteSection(payload.id);
					this.requestFailed({ error, request });
				});

		}
	}

	moveSection({ payload, originalItem }) {
		return (dispatch) => {
			dispatch(payload);

			const { ordinal: position, id: sectionId } = payload;
			APIS[API].moveGroup(sectionId, position)
				.then(response => {
					this.sectionMoved();
				}, (error, request) => {
					this.rollbackMoveSection(originalItem);
					this.requestFailed({ error, request });
				});
		};
	}
	rollbackMoveSection(originalItem) {
		return originalItem;
	}
	sectionMoved() {
		return true;
	}



    // LISTS
    showItems({ listId, query, serviceId }) {
		browserHistory.push({
			pathname: `/selections/service/${serviceId}/lists/${listId}/items`,
			query,
		});
		return true;
	}

	insertListItem(payload) {
		const { listId, filters, body, droppedItem, list } = payload;
		const { date, id, referenceId, position } = body;

		return (dispatch) => {
			dispatch(payload);

			const discoveryId = referenceId.split("-")[1]; // We get something like "discovery-234234" as an ID.
			if (!referenceId || parseInt(referenceId, 10) === NaN || !discoveryId || parseInt(discoveryId, 10) === NaN) {
				this.requestFailed({date, api, body});
				return;
			}
			// We should have program metadata and assets directly in discovery
			const { json, ...discoveryData } = droppedItem.data;
			const program = JSON.parse(json);
			const itemPayload = generateItemPayload(discoveryData, program, list);

			// Add item metadata to selections api (it will handle insert vs update logic)
			APIS[API].insertItem(itemPayload)
				.then(response => {
					const positionPayload = {
						id: response.id,
						position,
						date: getFormattedDate(date, filters),
					}

					// Add the item to the current list and date
					const callback = () => {
						const refreshPayload = { listId, filters: { date: positionPayload.date } };
						this.fetchItems(
							"listItems",
							refreshPayload,
							{ clearList: false },
						);
						if (isHourlyOrMinutelyList(filters)) {
							this.fetchItem("listInstances", refreshPayload);
						}
					};
					this.updateItemInList({ payload: positionPayload, listId, filters, callback });
				}, (error, api) => {
					this.rollbackInsertListItem(id);
					this.requestFailed(error, api);
				});
		}
	}
	rollbackInsertListItem(id) {
		return id;
	}

	createFeatureAndAddToGroupV2({ store, section, area, payload, filters, active }, selectionsConfiguration) {
		return dispatch => {
			dispatch({ store, payload });

			const { json, ...discoveryData } = payload;
			const program = JSON.parse(json);

			const itemPayload = generateItemPayload(discoveryData, program, {});
			const listPayload = generateFeaturePayload(payload, itemPayload, section, area, active, selectionsConfiguration);

			APIS[API].createList(listPayload)
				.then(response => {
					const list = response;
					this.createNewItemAndAddToList(itemPayload, list.id, filters);
				}, this.requestFailed);
		};
	}

	createNewItemAndAddToList(payload, listId, filters) {
		return dispatch => {
			dispatch({ listId });

			APIS[API].insertItem(payload)
				.then(response => {
					const itemPayload = {
						id: response.id,
						position: { ordinal: payload.ordinal },
						date: moment().format(),
					};

					const callback = () => {
						this.fetchItems("lists", filters);
						APIS[API].fetchSectionAssets({ sectionId: filters.sectionId, listId })
							.then(sectionAssets => {
								this.sectionAssetsForNewListUpdated(sectionAssets.items);
							});
					};
					this.updateItemInList({ payload: itemPayload, listId, filters, callback });
				}, this.requestFailed);
		};
	}

	updateItemInList({ payload, listId, filters, callback }) {
		return dispatch => {
			dispatch({ listId });

			APIS[API].updateListItem(listId, payload)
				.then(response => {
					if (callback) {
						callback();
					}
				}, this.requestFailed);
		}
	}

	moveItemInList({ payload, listId, filters }) {
		return dispatch => {
			dispatch({ listId });

			// TODO: Should we use a move API method instead?
			// https://3.basecamp.com/3091592/buckets/1615235/todos/335574217
			const callback = () => {
				this.fetchItems(
					"listItems",
					{ listId, filters: { date: getFormattedDate(filters.date, filters) } },
					{ clearList: false },
				);
				if (isHourlyOrMinutelyList(filters)) {
					this.fetchItem("listInstances", { listId, filters: { date: getFormattedDate(filters.date, filters) } });
				}
			};
			this.updateItemInList({ payload, listId, filters, callback });
		}
	}

	addListToGroup({ store, groupId, sectionId, areaId, section, payload, filters }) {
		return (dispatch) => {
			let grpId = groupId ?? sectionId ?? section.id;
			dispatch({
				store,
				id: payload.id,
				groupId: grpId,
				areaId,
				payload,
			}); // Opportunistic update + loading

			APIS[API].addListToGroup(grpId, { listId: payload.id, areaId: areaId, position: payload.ordinal ?? 0 })
				.then(response => {
					this.fetchItems(store, filters);
					APIS[API].fetchSectionAssets({ sectionId: grpId, listId: response.id })
						.then(response => {
							this.sectionAssetsForNewListUpdated(response.items);
						});
				}, (error, request) => {
					this.rollbackAddListToGroup({ store, id: payload.id });
					this.requestFailed({ error, request });
				});
		};
	}
	rollbackAddListToGroup({ store, id }) { return { store, id } }
	sectionAssetsForNewListUpdated(items) { return items }
	

	removeListFromGroup({ store, id, groupId, payload }) {
		return (dispatch) => {
			dispatch({ store, id });

			APIS[API].removeListFromGroup(groupId, id)
				.then(response => {
					this.itemRemoved({ store, id });
					//this.fetchLists({filters}); // TODO: If we can get hold of the filters we could refresh the list here (just because we can)
				}, (error, request) => {
					this.rollbackRemoveListFromGroup({ store, id: payload.id, groupId, payload });
					this.requestFailed({ error, request });
				})
				.catch((error, request) => {});
		};
	}
	rollbackRemoveListFromGroup({ store, groupId, payload }) {
		return { store, id: payload.id, groupId, payload };
	}

	moveListInGroup({ sectionId, payload, originalItem, areaId }) {
		return (dispatch) => {
			dispatch({ ...payload, areaId });

			// Testing of removal of this now that the B.E has improved, keep it until our F.E chief is pleased
			// await APIS[API].enableList(payload.id, { active: payload.active }); // Temporary because API is not behaving correctly
			const { ordinal: position, id: listId, active } = payload;
			APIS[API].moveListInGroup(sectionId, { listId, position, areaId, active })
				.then(response => {
					this.listMoved();
				}, (error, request) => {
					this.rollbackMoveListInGroup(originalItem);
					this.requestFailed({ error, request });
				});
		};
	}
	rollbackMoveListInGroup(originalItem) {
		return originalItem;
	}
	listMoved() {
		return true;
	}

	refreshList(item) {
		return item;
	}

	disableList({ id, filters }) {
		return (dispatch) => {
			dispatch(id);

			APIS[API].disableList(id)
				.then(response => {
					this.refreshList(response);
					this.fetchLists({ filters });
				}, (error, request) => {
					//this.fetchLists({ filters });
					this.requestFailed({ error, request });
				});
		};
	}

	enableList({ id, filters }) {
		return (dispatch) => {
			dispatch(id);

			APIS[API].enableList(id)
				.then(response => {
					this.refreshList(response);
					this.fetchLists({ filters });
				}, (error, request) => {
					//this.fetchLists({ filters });
					this.requestFailed({ error, request });
				});
		};
	}

	deleteList(list, callback) {
		return (dispatch) => {
			dispatch();

			APIS[API].deleteList(list.id)
				.then(response => {
					if (typeof callback === "function") {
						callback();
					}
					this.listDeleted(list);
				}, this.requestFailed);
		}
	}

	listDeleted(deletedList) { return deletedList }

	updateListImages(items) {
		return items;
	}

	fetchListImages(items) {
		return (dispatch) => {
			// dispatch(listId);

			const listIds = items.map(item => item.id).join(",");

			const filters = {
				listIds: listIds,
				maxItemsPerList: 5
			};


			APIS[API].fetchMultipleListItems(filters)
				.then(response => {
					this.updateListImages(response.items); // FIX WHEN C70
				}, (error, request) => {
					this.requestFailed({ error, request });
				});
		}
	}

    // DISCOVERY
	discoveryFilter(filters, _filter, _programPremiereFilter, _broadcastsTypeFilter, platform) {
		return (dispatch) => {
			const discoveryTypes = ["programs", "broadcasts"];
			if (discoveryTypes.includes(_filter)) {
				const searchFilters = getDiscoveryFilters(_filter, filters, _programPremiereFilter, _broadcastsTypeFilter, platform);
				dispatch({ ...searchFilters, _filter, _programPremiereFilter, _broadcastsTypeFilter });
                this.fetchItems("items", searchFilters, { targetStore: "discovery", api: "discovery" });
            } else {
				const payload = { /*...filters,*/ filter: _filter, pageSize: 200 };
				dispatch({ ...payload, _filter, _programPremiereFilter, _broadcastsTypeFilter });
                this.fetchItems("lists", payload, { targetStore: "discovery" });
			}
		};
	}

	discoverySearch(filters, searchText, platform) {
		return (dispatch) => {
            dispatch(searchText);

			const { _filter } = filters;
			if (["programs", "broadcasts", /*"liveSport",*/ "upcoming", "fresh"].includes(_filter)) {
				const searchFilters = getDiscoveryFilters(_filter, { ...filters, searchText }, null, null, platform);
                this.fetchItems("items", searchFilters, { targetStore: "discovery", api: "discovery" });
            } else {
				const payload = {
					...filters,
					searchText,
					filter: searchText?.length ? null : filters.filter, // Don't search lists with the filter parameter or else users will be confused because they can not search for their lists.
				};
                this.fetchItems("lists", payload, { targetStore: "discovery" });
			}
		};
	}

	discoveryFetchEpisodes(filters, parentItemId, platform) {
		return (dispatch) => {
			dispatch(parentItemId);

			const searchFilters = {
				...filters,
				parentItemId,
				pageSize: 1000,
				platform,
			};

			this.fetchItems("items", searchFilters, { targetStore: "discoveryEpisodes", api: "discovery" });
		};
    }

    discoveryClearEpisodes() {
        return true;
    }

    // create({ id, pathname, returnTo }) {

	// 	const route = {
	// 		pathname,
	// 		state: {
	// 			modal: true,
    //             returnTo,
	// 		}
	// 	};

	// 	browserHistory.push(route);

	// 	return route;
    // }

    // edit({ subType, type, reference, id }, sourceProps, location) {

    //     let entity = "container", darkModal = false;
    //     if(isAssetEditorType(type, subType)) {
    //         entity = "asset";
    //         darkModal = true;
    //     }

	// 	const route = {
	// 		pathname: `/star/${entity}/${reference || id}/edit`,
	// 		// search,
	// 		state: {
	// 			modal: true,
    //             returnTo: `${location.pathname}${location.search}`,
    //             darkModal,
	// 		}
	// 	};

	// 	browserHistory.push(route);

	// 	return route;
    // }

    // select(item)                        { return { item } }
    // unselect(item)                      { return { item } }
    // toggleSelect(item)                  { return { item } }
    // toggleMultipleSelect(payload)       { return payload }
    // selectInStore(item, selectedStore)                  { return { item, selectedStore } }
    // unselectInStore(item, selectedStore)                { return { item, selectedStore } }
    // toggleSelectInStore(item, selectedStore)            { return { item, selectedStore } }

    // toggleShowSelected(selectedIsVisible, location, e) {

    //     if(selectedIsVisible) {
    //         // We need to go back instead of doing a normal navigation to allow for
	//         // react-router-scroll to restore the scroll position of the library list.
	// 		e.preventDefault();
	// 		browserHistory.goBack();
    //     }
    //     else {
    //         const route = {
    //             pathname: "/star/search/selected",
    //             state: {
    //                 returnTo: `${location.pathname}${location.search}`,
    //             }
    //         }
    //         browserHistory.push(route);
    //     }

    //     return true;
    // }


    // clearSelectedInStore(selectedStore) { return selectedStore }
    // clearSelected() { return true }

    /* **************** Items **************** */
    fetchItems(dataStore, payload, options = {}) {

        return (dispatch) => {
            const {
                targetStore = null,
                callback = null,
                api = API,
            } = options;

            const requestTime = Date.now();
            const multiple = true;
            const command = getCommand("fetch", dataStore, multiple);
            const store = targetStore || getStore(dataStore, multiple);

			dispatch({ payload, store, requestTime, options });

            APIS[api][command](payload)
                .then(response => {
					// C60
                    const { pageIndex, numberOfItems, items, links } = response;
					const nextPageUrl = getNextPageUrl(links);

					// C70
                    // const { result: items, meta } = response;
					// const { pageIndex, totalItems: numberOfItems, next: nextPageUrl } = meta;

                    const appendToExistingItems = pageIndex > 0;

                    this.itemsUpdated({
                        store,
                        items,
                        appendToExistingItems,
                        numberOfItems,
                        nextPageUrl,
                        requestTime,
                    });

                    if(callback) {
                        callback(items);
                    }

                }, this.requestFailed);

        };
    }
    pageItems(entity, url, options) {
        return (dispatch) => {
            const {
                targetStore = null,
                callback = null,
                api = API,
            } = options;

            const requestTime = Date.now();
            const multiple = true;
            const store = targetStore || getStore(entity, multiple);

            dispatch({ entity, url, store });

            APIS[api].fetchUrl(url)
                .then(response => {
					// C60
                    const { pageIndex, numberOfItems, items, links } = response;
					const nextPageUrl = getNextPageUrl(links);

					// C70
					// const { result: items, meta } = response;
					// const { pageIndex, totalItems: numberOfItems, next: nextPageUrl } = meta;

                    const appendToExistingItems = pageIndex > 0;

                    this.itemsUpdated({
                        store,
                        items,
                        appendToExistingItems,
                        numberOfItems,
                        nextPageUrl,
                        requestTime,
                    });

                    if(callback) {
                        callback(items);
                    }

                }, this.requestFailed);
        };
    }
    itemsUpdated(payload) {
        return payload;
    }
    // clearSearch() {
    //     return true;
    // }

    /* **************** Item **************** */
	fetchItem(entity, payload, options = {}) {
		return (dispatch) => {
			const {
                // targetStore = null,
                callback = null,
                api = API,
			} = options;

			dispatch(entity);

			const command = getCommand("fetch", entity);
			APIS[api][command](payload)
				.then(model => {
					this.itemLoaded({entity, model});

					if(callback) {
                        callback(model);
                    }
				}, this.requestFailed);
		};
    }

    itemLoaded(payload) { return payload; }

	updateItem(entity, data, payload, type = "update", targetStore) {
		return (dispatch) => {
			dispatch();

			const command = getCommand(type, entity);
			APIS[API][command](data, payload)
				.then(model => {
					this.itemUpdated({ entity, model, originalId: data.id, targetStore });
				}, this.requestFailed);
		}
    }
    itemUpdated(payload) { return payload; }

    removeItem(entity, data, targetStore) {
		return (dispatch) => {
			dispatch({ entity, id: data.id, targetStore });

            const command = getCommand("delete", entity);
			APIS[API][command](data)
				.then(() => {
					this.itemRemoved({ entity, id: data.id, targetStore });
				}, (error, request) => {
					// TODO!: Keep item index when rollbacking
					this.rollbackRemoveItem({ entity, id: data.id, targetStore });
                    // this.requestFailed({ error, request });
                    this.requestFailed(error);
				});
		};
    }

    itemRemoved(payload) { return payload; }

    rollbackRemoveItem(payload) { return payload};

    /* ================ */
	/*      GENERAL     */
	/* ================ */
    unmount(store) { return store ? store : true }

    persist(model, store, prepend) {
        return { store, model, prepend };
	}

	requestFailed(requestError) {
		const { exceptionMessage, error } = requestError || {};

		const networkMessage = error && error.status ? `${error.status} ${error.statusText} ${error.url}` : null;
		const codeMessage = error && error.message || null;
		const message = exceptionMessage || networkMessage || codeMessage || "A pretty unknown error occured. And by pretty unknown I mean totally unknown. Maybe you should try that thing again?";

		Alert.displayAlert("error", message);
		return true;
	}

	// CUSTOM
	copyItems({ listId, inheritedList }) {
		return (dispatch) => {
			dispatch();

			APIS[API].copyList(listId, inheritedList)
				.then(response => {
					this.itemLoaded({ entity: "list", model: response });
				}, this.requestFailed);
		};
	}

	// LIST ITEM ACTIONS
	edit({ id, serviceId, pathname = `/selections/service/${serviceId}/lists/${id}/edit`, listId, groupId, category, groupVersion, returnTo, search }) {
		const route = {
			pathname,
			// search,
			query: {
				groupId,
				category,
				listId,
				groupVersion
			},
			state: {
				modal: true,
				returnTo
			}
		};

		browserHistory.push(route)

		return route;
	}

	itemRemove({data, listId, filters}) {
		return (dispatch) => {
			const itemId = data.id;
			dispatch(itemId);

			const { date } = filters;
			const payload = {
				listId,
				itemId,
				date: getFormattedDate(date, filters),
			}

			APIS[API].deleteListItem(payload)
				.then(response => {
					this.fetchItems(
						"listItems",
						{ listId, filters: { date: payload.date } },
						{ clearList: false },
					);

					if (isHourlyOrMinutelyList(filters)) {
						this.fetchItem("listInstances", { listId, filters: { date: payload.date } });
					}
				}, this.requestFailed);
		};
	}

	deleteListItems({listId, filters}) {
		return (dispatch) => {
			dispatch();

			const date = getFormattedDate(date, filters);

			SelectionsData.deleteItems(listId, date)
				.then(response => {
					this.fetchList({listId, filters});
					this.fetchListItems({listId, filters});
				}, this.requestFailed);
		};
	}

	deleteTemplate(template) {
		return async dispatch => {
			dispatch();

			try {
				await APIS[API].deleteTemplate(template);
				this.templateDeleted();
			} catch (error) {
				if (error.exceptionMessage.includes("template is in use")) {
					const lists = await APIS[API].fetchLists({ templateId: template.id });
					const details = lists.items.map(l => `${l.section?.name ?? "[Section name missing]"} > ${l.displayName || l.name}`);
					Alert.displayAlert("error", `${error.message}. Expand this message to see the lists that use this template.`, details);
				} else {
					this.requestFailed(error);
				}
			}
		}
	}

	templateDeleted() { return true }

	deleteArea(area) {
		return async dispatch => {
			dispatch();

			try {
				await APIS[API].deleteArea(area);
				this.areaDeleted();
			} catch (error) {
				if (error.exceptionMessage.includes("area is in use")) {
					const lists = await APIS[API].fetchLists({ areaId: area.id });
					const details = lists.items.map(l => `${l.section?.name ?? "[Section name missing]"} > ${l.displayName || l.name}`);
					Alert.displayAlert("error", `${error.message}. Expand this message to see the lists that use this area.`, details);
				} else {
					this.requestFailed(error);
				}
			}
		}
	}

	areaDeleted() { return true }
}

export default alt.createActions(Actions);

// Helpers
function getCommand(command, entity, multiple = false) {
	if(typeof(entity) === "object") {
		const extra = multiple ? "s" : "";

		if(entity.parentEntity) {
			return command + entity.parentEntity.substr(0, 1).toUpperCase() + entity.parentEntity.substr(1) + entity.entity.substr(0, 1).toUpperCase() + entity.entity.substr(1) + extra;
		}
		entity = `${entity.entity}${extra}`;
	}
	return command + entity.substr(0, 1).toUpperCase() + entity.substr(1);
}

function getStore(entity, multiple = false) {
	if(typeof(entity) === "object") {
		const extra = multiple ? "s" : "";
		return `${entity.entity}${extra}`;
	}
	return entity;
}

function getNextPageUrl(links = []) {
	const nextLink = links.find(l => l.rel === "next" || l.Rel === "next");

	return nextLink
		? nextLink.href || nextLink.Href
		: null;
}

function getDiscoveryFilters(filter, conditions, programPremiereFilter, broadcastsTypeFilter, platform) {
	const { searchText } = conditions;

	let orderBy, itemClass, startsAfter, daysAhead, type, pageSize;
	switch (filter) {
		case "broadcasts":
			startsAfter = moment().format(Const.DATE_FORMAT_TZ);
			orderBy = "activedate";
			itemClass = "sport";
			if (broadcastsTypeFilter === "linear") {
				type = "broadcast";
			} else {
				type = "clip,live";
			}
			pageSize = 400; // Larger pageSize for sport until we have a better solution in place (like paging or including a specific number of days in backend when using sport filter)
			break;
		case "programs":
			itemClass = "regular,children";
			type = "single,season,series";
			if (programPremiereFilter === "fresh") {
				daysAhead = 0;
				orderBy = "start-desc";
			} else {
				startsAfter = moment().format(Const.DATE_FORMAT_TZ);
				orderBy = "start";
			}
			break;
	}

	if (searchText) {
		orderBy = null;
		startsAfter = null;
		daysAhead = null;
		pageSize = null;
	}

	return {
		platform,
		searchText,
		orderBy,
		type,
		itemClass,
		startsAfter,
		daysAhead,
		pageSize,
	};
}

// HELPERS
function generateItemPayload(discoveryData, program, list) {
	const {
		reference,
		activeDate,
		validFrom,
		validUntil,
		displayName,
		ordinal,
		provider,
		type,
		guid,
	} = discoveryData;

	const configKey = getConfigKey(type, list);
	const config = defaultTemplateConfig; // Should come from API/Store, not use default config
	let configForType = config[configKey];
	if (!configForType) {
		console.error(`No config available for key "${configKey}". Using default "single" config.`);
		configForType = config.single;
	}
	
	const itemPayload = translateData(program, configForType);
	const useOnlyStart = ["liveprogram", "clip", "broadcastprogram"].includes(type?.toLowerCase());

	let payload = {
		reference,
		displayName,
		type: getSelectionsTypeForDiscoveryType(type),
		inputSource: "batch",
		start: useOnlyStart ? activeDate : validFrom,
		end: useOnlyStart ? null : validUntil,
		ordinal,
		provider,
		programGuid: guid,

		...itemPayload,
	};

	return payload;
}

function generateFeaturePayload(payload, itemPayload, section, area, active, selectionsConfiguration) {
	const { name, displayName, itemType, ordinal } = payload;

	
	return {
		name: itemPayload.name ?? name,
		localizations: itemPayload.localizations,
		assets: itemPayload.assets,

		type: "singleItem",
		displayName,
		category: { name: "feature" },
		contentLayout: selectionsConfiguration?.contentLayoutsForCategories?.["feature"]?.[0].key,
		instanceType: "static", // 2019-11-14: Set instanceType static until HEL makes backend use static as default
		position: ordinal,
		platforms: "Web, Mobile, SmartTv",
		version: section.version,

		sectionId: section?.id,
		area: area ?? "main",
		active: active ?? true,
	};
}

function getConfigKey(discoveryType = "SingleProgram", list) {
	const type = discoveryType.toLowerCase().split("program").shift();
	const category = list?.category?.name;
	const layout = list?.contentLayout?.split("/").pop();
	if (category === "showcase" && layout !== "showcase") {
		return `${type}_${layout}`;
	}

	if (["live", "clip"].includes(type)) {
		return "broadcast";
	}
	return type;
}

function getSelectionsTypeForDiscoveryType(discoveryType) {
	switch (discoveryType?.toLowerCase()) {
		case "liveprogram":
			return "event";
		case "clip":
			return "clip";
		case "broadcastprogram":
			return "broadcast";
		default:
			return "program";
	}
}
import React from 'react'
import moment from 'moment'

import Item from '../../../components/cms/item'
import Modals from '../modals/modals'

import * as CMSAPI from '../../../apis/cms'
import useList from '../../../core/hooks/useList'
import { useSessionStorage } from '../../../core/hooks/useStorage'
import GenericListApp from '../../../components/genericApp/listApp'
import { getItems } from '../../../components/genericApp/utils'

import { transformFilters, EVENTPLANNER_DATE_FILTER } from '../shared/utils'
import { getValue } from '../../../core/cms/utils'
import { renderTodayOrDate } from '../../../utils/date'
import { ItemActionsContext, useItemActionsState } from '../../../components/cms/itemActionsContext'
import useCMSTemplate from '../../../core/queries/cms/useCMSTemplate'

import '../events/app.css'

const MODULE = "eventplanner";
const DATASTORE = "events-mcr";
const TEXT_HEADING = "MCR";
const TEXT_BACK_BUTTON = null;
const TEXT_EMPTY = "";

function ListApp(props) {
	const _entity = `mcreventslist`;
	const template = useCMSTemplate(_entity);

	const [universalEventPlannerDate, setUniversalEventPlannerDate] = useSessionStorage(EVENTPLANNER_DATE_FILTER, moment().startOf("day").format());
	const filterConfig = {
		">=ott.start": {
			type: "date",
			alwaysVisible: true,
			onChange: (newValue) => {
				// Clear stored value when selecting today
				const isToday = moment(newValue).isSame(moment(), "day");
				setUniversalEventPlannerDate(isToday ? undefined : moment(newValue).startOf("day").format());
			},
		},
		searchText: {
			type: "search",
			alwaysVisible: true,
		},
		"settings-or": {
			type: "pick",
			title: "Settings",
			alwaysVisible: true,
			options: [
				{ key: "ottTv4", text: "TV4" },
				{ key: "ottMtv", text: "MTV" },
			],
		},
	};

	const FILTER_DEFAULTS = {
		_entity,
		pageSize: 30,
		orderBy: "ott.start asc",
		">=ott.start": universalEventPlannerDate,
		searchText: "",
		"settings-or": "ottTv4,ottMtv",
	};

	const originalSortKeys = React.useRef({});
	const originalGroupKeys = React.useRef({});
	const clearKeys = React.useCallback(() => {
		originalSortKeys.current = {};
		originalGroupKeys.current = {};
	}, []);

	const listState = useList({
		listKey: DATASTORE,
		fetchData: CMSAPI.fetchCMSEntities,
		transformFilters: transformFilters,
		fetchSingle: CMSAPI.fetchCMSEntity,
		patchData: CMSAPI.updateCMSEntity,
		runAction: CMSAPI.runAction,
		entity: _entity,
		updateDataExtraParams: { _entity },
		filterDefaults: FILTER_DEFAULTS,
		filterConfig: filterConfig,
		onFilterChange: clearKeys,
	});

	const [modalData, setModalData] = React.useState(null);
	const closeModal = React.useCallback(
		() => setModalData(null),
		[setModalData]
	);

	const actions = useItemActionsState({
		extraActions: [],
		_entity,
		refetch: listState.refetch,
		setModalData,
		collection: template?.data?.collection,
	});

	const items = getItems(listState.data);
	items.forEach(item => {
		if (originalSortKeys.current?.[item.id] === undefined) {
			originalSortKeys.current[item.id] = getItemSortKey(item);
		}
		if (originalGroupKeys.current?.[item.id] === undefined) {
			originalGroupKeys.current[item.id] = getItemGroupKey(item);
		}
	});

	return (
		<ItemActionsContext.Provider value={actions}>
			<GenericListApp
				{...props}
				module={MODULE}
				datastore={DATASTORE}
				state={listState}
				textHeading={TEXT_HEADING}
				textBackButton={TEXT_BACK_BUTTON}
				textEmpty={TEXT_EMPTY}
				filterConfig={filterConfig}
				collapseFiltersDefault={true}
				groupItemsBy={item => groupItemsBy(item, originalGroupKeys.current)}
				getGroupTitle={getGroupTitle}
				sortItemsBy={item => sortItemsBy(item, originalSortKeys.current)}
				groupItemsWhenSearching={true}
				padding={true}
				virtualize={false}
				items={items}
			>
				<Item
					template={template.data}
					onChange={listState.onChange}
					openModal={setModalData}
					refreshItem={listState.refreshItem}
				/>
			</GenericListApp>
			<Modals
				modalData={modalData}
				onClose={closeModal}
			/>
		</ItemActionsContext.Provider>
	);
}

export default class EventsApp extends React.Component {
	render() {
		return (
			<ListApp
				{...this.props}
			/>
		);
	}
}

function groupItemsBy(item, originalGroupKeys) {
	return originalGroupKeys[item.id] ?? getItemGroupKey(item);
}
function getItemGroupKey(item) {
	const ottstart = getValue(item, "start", "ottstart-start-end-settings-rightmenu.ott");
	const key = ottstart ? moment(ottstart).format("YYYYMMDD") : "";
	return key;
}

function sortItemsBy(item, originalSortKeys) {
	return originalSortKeys[item.id] ?? getItemSortKey(item);
}
function getItemSortKey(item) {
	const ottstart = getValue(item, "start", "ottstart-start-end-settings-rightmenu.ott");
	const key = ottstart ? moment(ottstart).valueOf() : "";
	return key;
}

function getGroupTitle(groupKey) {
	if (groupKey?.length) {
		return <span>{renderTodayOrDate(groupKey, true)}</span>;
	}

	return "Missing OTT start";
}
import React from 'react'

import Editor, { parseUi, withQueryClient } from '../../../components/editor/index'
import { hasAccessToPath } from '../../../core/services/auth'
import ScrapingPreview from './scrapingPreview'
import Scroll from '../../../components/scroll'

import EditorActions from '../../../components/editor/actions'
import EditorStore from '../../../components/editor/store'

import schema from './schema'
import uiSchema from './uiSchema'

import './sourceeditor.css'
import useReviewsLanguages from '../../../core/queries/reviews/useReviewsLanguages'

const updateModel = (model) => {
	EditorActions.mergeModel(model);
};

const SourceEditor = props => {
	const { data: languages } = useReviewsLanguages({
		filter: { orderBy: "name", pageSize: 1000 },
	});

	const [expandScrapingPreview/*, setExpandScrapingPreview*/] = React.useState(true);
	const [scrapingTemplate, setScrapingTemplate] = React.useState(null);
	const [scrapingTestUrl, setScrapingTestUrl] = React.useState("");
	const [modelLoaded, setModelLoaded] = React.useState(false);

	React.useEffect(
		() => {
			const onEditorChange = (state) => {
				if (state.model) {
					setTimeout(() => {
						setModelLoaded(true);
						setScrapingTemplate(state.model.scrapingTemplate ?? {});
						setScrapingTestUrl(state.model.scrapingTestUrl ?? "");
					});
				}
			};
			EditorStore.listen(onEditorChange);
			return () => EditorStore.unlisten(onEditorChange);
		},
		[]
	);

	return (
		<div className={`c6-reviews-source-editor ${expandScrapingPreview ? "expand-scraping" : ""}`}>
			<Editor
				layout="grid"
				api="reviews"
				entity="source"
				getSchema={getSchema.bind(this, languages)}
				getUiSchema={getUiSchema}
				customFields={{}}
				loadPayloadTransform={loadPayloadTransform}
				savePayloadTransform={savePayloadTransform}
				hasEditAccess={hasAccessToPath(props.routes, "editor")}
				{...props}
			/>
			<Scroll
				name="scraping-preview-scroll-wrapper"
				nopadding={true}
				inModal={true}
				secondary={true}
				setOwnHeight={true}
			>
				<ScrapingPreview
					modelLoaded={modelLoaded}
					scrapingTemplate={scrapingTemplate}
					scrapingTestUrl={scrapingTestUrl}
					updateModel={updateModel}
				/>
			</Scroll>
		</div>
	);
};

export default withQueryClient(SourceEditor);

function getSchema(languages, model, isNew, location, route, params) {
	if (!languages?.items.length) {
		return { type: "object", properties: {} };
	}

	schema.properties.languageId = {
		title: "Language",
		type: "number",
		oneOf: languages?.items.map(l => ({
			const: l.id,
			title: l.name,
		})),
	};

	return schema;
}

function getUiSchema(model, isNew, location, route, params) {
	const ui = { ...uiSchema };

	return ui;
}

function loadPayloadTransform({ model }) {
	return {
		...model,
		maxScore: parseInt(model.maxScore),
		status: model.enabled ? "Active" : "Inactive",
	};
}

function savePayloadTransform({ formData, ...rest }) {
	return {
		...rest,
		formData: {
			...formData,
			enabled: formData.status === "Active",
			status: undefined,
		},
	};
}
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import InfiniteScroll from 'react-infinite-scroller'
import Moment from 'moment'
import groupBy from 'lodash/groupBy'

import InlineHeading from '../../../components/ui/inlineHeading'
import { Item, ItemGroup } from '../../../components/list/listItems'
import { decoratorUIActions } from '../../../core/decorators/uiActions'
import itemActions from './listItemActions'
import Empty from '../../../components/list/empty'
import Const from '../../../core/constants'

@decoratorUIActions(itemActions)
export default class SchedulesList extends Component {
	render() {
		const {
			items,
			isLoading,
			loadMore,
			hasMore,
			groupByDate,
			title,
		} = this.props;

		const noResult = isLoading || !(items && items.length);

		return noResult
			? <Empty v2={true} isLoading={isLoading}>Sorry, could not find any changes.</Empty>
			: (
				<InfiniteScroll
					loadMore={loadMore}
					hasMore={hasMore}
					loader={<div className="infinite-loader" key="infinite-loader">Loading ...</div>}
					useWindow={false}
					threshold={700}
					initialLoad={false}>
					{groupByDate
						? this.renderGroups(items)
						: <ItemGroup title={title}>{this.renderItems(items)}</ItemGroup>
					}
				</InfiniteScroll>
		);
	}

	renderGroups(items) {
		const groupedItems = groupItems(items);

		return Object.keys(groupedItems).map(groupKey => (
			<ItemGroup key={groupKey} title={getGroupTitle(groupKey)}>
				{this.renderItems(groupedItems[groupKey], true)}
			</ItemGroup>
		));
	}

	renderItems(items, groupedByDate) {
		return items.map((item) => {
			const modified = Moment(item.modified);
			const isoModified = modified.format(Const.DATE_TIME_FORMAT);

			return (
				<Item
					key={item.id}
					id={item.id}
					actionData={item}
				>
					<div className="flex flex-dyn-1">
						<div className="c6-content">
							{groupedByDate ?
								<h2>{item.channel.name}</h2>
							:
								<h1>{Moment(item.day).format(Const.PERIOD_FORMAT)}</h1>
							}
						</div>
						<div className="c6-content">
							<InlineHeading title="Last modified">
								<time title={isoModified} dateTime={isoModified}>
									{modified.fromNow()}
								</time>
							</InlineHeading>
						</div>
					</div>
				</Item>
			);
		});
	}
}

SchedulesList.propTypes = {
	items: PropTypes.arrayOf(PropTypes.shape({
		day: PropTypes.string.isRequired,
		id: PropTypes.number.isRequired,
		channel: PropTypes.shape({
			name: PropTypes.string.isRequired,
		}),
		major: PropTypes.number,
		minor: PropTypes.number,
	})).isRequired,
	isLoading: PropTypes.bool.isRequired,
	hasMore: PropTypes.bool.isRequired,
	loadMore: PropTypes.func.isRequired,
	groupByDate: PropTypes.bool,
};

SchedulesList.defaultProps = {
	groupByDate: false,
};


// Helpers
function groupItems(items) {
	return groupBy(items, item => (Moment(item.day).format("YYYYMMDD")));
}

function getGroupTitle(group) {
	return group.length === 6
		? Moment(`${group}01`).format("MMMM")
		: Moment(group).format("D MMMM");
}

import React, { PureComponent } from 'react'
import { Link, withRouter } from 'react-router'
import PropTypes from 'prop-types'
import moment from 'moment'

import Preview from '../../../components/assets/preview'
import InlineHeading from '../../../components/ui/inlineHeading'
import Period from '../../../components/ui/period'
import Status from '../../../components/ui/status'
import ContentItem, { Content } from '../../../components/list/contentItem'

import Const from '../../../core/constants'
import { hasAccessToPath } from '../../../core/services/auth'
import { copyTextToClipboard } from '../../../utils/misc'
import { getLinkUrlAndHash } from './utils'

import '../../../components/listItems/standard.css'

const TV4PLAY = "TV4Play";

@withRouter
export default class PackageContent extends PureComponent {

	static propTypes = {
		id: PropTypes.number,
		media: PropTypes.object,
		metadata: PropTypes.object,
		created: PropTypes.string,
		publishWindows: PropTypes.array,
		status: PropTypes.string,
	}

	constructor(props) {
		super(props);
	}

	render() {
		const {
			name,
			metadata,
			publishWindows,
			reference,
			assets,
			service,
			validations,
			lastSent,
			status,
			blockStatus,
		} = this.props;

		const windows = publishWindows.map(({ start, end, site }) => (
			<InlineHeading key={`${site.name}-${start}`} title={site && site.name ? `On ${site.name}` : null}>
				<Period start={start} end={end} format={Const.DATE_TIME_FORMAT}/>
			</InlineHeading>
		));

		const mainImage = metadata && metadata.images && metadata.images.find(i => i.category === "Main");
		const imageGuid = mainImage ? mainImage.id : null;

		const deliveryStatus = getDeliveryStatus(validations);

		const showSeriesLink = this.props.showSeriesLink && metadata && metadata.series;
		const packagesPath = window.location.pathname.includes("-new") ? "packages-new" : "packages";
		const seriesLinkServicePath = (this.props.routes.find(r => r.serviceId !== undefined) || { path: packagesPath }).path;

	    return (
			<ContentItem extraClasses={`package-delivery-${deliveryStatus.status} package-status-${status?.toLowerCase()}`} data={this.props}>
				<Content size="min" className="preview">
					<Preview imageGuid={imageGuid} />
				</Content>
				<Content className="info">
					<h1>{name || "Package name missing"}</h1>
					<p>
						{service?.displayName && (<span>({service.displayName})</span>)}
						{reference && (
							<span
								className="reference"
								onClick={(e) => copyTextToClipboard(reference, "Copied reference to your clipboard!", e)}
								title="Click here to copy reference to your clipboard"
								style={{ marginLeft: "5px" }}
							>
								{reference}<span className="icon-content_copy"></span>
							</span>
						)}
						{showSeriesLink && (
							<Link
								to={{
									pathname: `/vod/${seriesLinkServicePath}/series/${metadata.series.id}`,
									query: { serviceId: service.id }
								}}
								className="c6-link"
								onClick={e => e.stopPropagation()}
							>
								Display series
							</Link>
						)}
					</p>
					{deliveryStatus.message && (
						<p style={{ color: "var(--attention-color)" }}>
							<span className="icon-info">{deliveryStatus.message}</span>
						</p>
					)}
				</Content>
				<Content>
					{windows}
				</Content>
				<Content className="right">
					{renderLastSentAndStatus(lastSent, service, status, blockStatus)}
					{renderValidations(validations, assets, service, metadata)}
				</Content>
			</ContentItem>
		);
  	}
}

// HELPERS
function getDeliveryStatus(validations = []) {
	const deliveryFailedErrors = validations.filter(v => v.type === "Delivery" && v.status === "Failed");
	return {
		status: deliveryFailedErrors.length ? "failed" : "ok",
		message: deliveryFailedErrors.length ? deliveryFailedErrors.map(v => v.message).join("\n") : null,
	}
}

function renderLastSentAndStatus(lastSent, service, status, blockStatus) {
	let statusText = status;
	let statusClass = status?.toLowerCase();

	switch (status?.toLowerCase()) {
		case "undefined": 	statusText = null; 					break;
		case "new": 		statusText = "Waiting for content"; break;
		case "queued": 		statusText = "Transcoding"; 		break;
		case "delivered": 	statusText = "Partially delivered"; break;
		case "published": 	statusText = "Ready"; 				break;
		case "unpublished":
		case "canceled":	statusText = "Cancelled";			break;
		case "expired":
		case "pending":		statusText = "Expired";				break;
	}

	if (blockStatus?.toLowerCase() !== "none") {
		statusText = "Blocked by user: " + blockStatus;
		statusClass = "blocked";
	}

	return (
		<div className="lastsent-status">
			{lastSent && (
				<time dateTime={lastSent} title={lastSent}>
					Last {service.id === TV4PLAY ? "updated" : "sent"} {moment(lastSent).format("D MMM HH:mm")}
				</time>
			)}
			{statusText && (
				<div className={statusClass} title={statusText}>
					{statusText}
				</div>
			)}
		</div>
	);
}

export function renderValidations(validations, assets, service, metadata) {
	if (assets && assets.filter(a => a.status === "Available").length > 1) {
		console.warn("More than one available asset on package. This should not happen!");
	}

	const grouped = groupValidationsByType(validations);
	return Object.keys(grouped).sort(sortGroupedValidations).filter(group => !["License", "Delivery"].includes(group)).map(validationType => {
		const { status, statusText, title, genericOkWithWarnings } = getValidationStatus(grouped[validationType], validationType, assets);

		let linkOrText = statusText;
		if (metadata && validationType === "Metadata") {
			const accessRequirement = [{ module: "metadata", app: "library", access: "reader" }];
			const hasMetadataAccess = hasAccessToPath(accessRequirement);
			linkOrText = hasMetadataAccess
				? <Link onClick={e => e.stopPropagation()} to={getLinkUrlAndHash(service, metadata)}>{statusText}</Link>
				: <span>{statusText}</span>;
		}
		
		return (
			<Status
				key={validationType}
				label={validationType}
				status={status}
				title={title.trim()}
				classNames={genericOkWithWarnings ? "ok-with-warning" : ""}
			>
				{linkOrText}
			</Status>
		);
	});
}

function getValidationStatus(validations, validationType, assets) {
	let statusText = "Ok";
	let status = "Ok";
	const failed = validations.filter(v => v.status === "Failed");
	const warnings = validations.filter(v => v.status === "Warning");
	let title = "";
	if (warnings.length) {
		statusText = status = "Warning";
		title += `Warnings:\n${warnings.map(v => v.message).join("\n")}\n\n`;
	}
	if (failed.length) {
		statusText = status = "Failed";
		title = `Failed:\n${failed.map(v => v.message).join("\n")}\n\n${title}`;
	}

	const genericOk = validations.some(v => v.status === "Ok" && v.versionId === 0);
	const genericOkWithWarnings = genericOk && warnings.length && !failed.length;
	if (genericOkWithWarnings) {
		statusText = status = "Ok";
	}

	const availableAsset = assets.find(a => a.status === "Available");
	const assetFiles = availableAsset && availableAsset.files || [];
	const files = getFilesWithType(assetFiles, validationType);
	files.forEach(f => title += `${getFileHoverText(f)}\n\n`);
	statusText += files.some(f => f._updated) ? " (Updated)" : "";

	if (
		["Video", "Subtitle"].includes(validationType) && (!files.length || files.some(f => f.fileStatus === "Canceled"))
		|| ["Image", "Metadata"].includes(validationType) && failed.length
	) {
		statusText = "Missing";
	}

	return { status, statusText, title, genericOkWithWarnings };
}

function groupValidationsByType(validations) {
	return (validations || []).reduce((acc, curr) => ({
		...acc,
		[curr.type]: acc[curr.type] ? [...acc[curr.type], curr] : [curr],
	}), {});
}

function sortGroupedValidations(groupA, groupB) {
	const order = ["Metadata", "Video", "Subtitle", "Image"];
	return order.indexOf(groupA) - order.indexOf(groupB);
}

function getFileHoverText(file) {
	return `
		${file.fileStatus === "Available" && file._updated ? "(Updated)" : ""}

		id: ${file.id}
		asset id: ${file.assetId}
		name: ${file.name}
		size: ${file.size}
		bitrate: ${file.bitrate}
		created: ${file.created}
		path: ${file.path}
	`.replace(/\t/g, "").trim();
}

function getFilesWithType(files, type) {
	const filesWithType = (files || []).filter(f => f.fileFormat.type === type);
	const availableFilesWithType = filesWithType
		.filter(f => f.fileStatus === "Available")
		.map(f => ({
			...f,
			_updated: filesWithType.some(f2 =>
				f2.versionId === f.versionId
				&& f2.id !== f.id
				&& f2.fileStatus === "Cancelled")
		}));
	return availableFilesWithType;
}
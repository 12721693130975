import React from 'react'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ListSubheader from '@mui/material/ListSubheader'
import { useQuery } from '@tanstack/react-query'

import * as CMSAPI from '../../../apis/cms'

import './checkbox.css'
import './checkboxContainer.css'

import { format } from '../../../utils/stringFormat'
import get from 'lodash/get'

const VALUE_DISABLE = "__DISABLE__";

const CheckboxContainer = (props) => {
	const {
		className,
		displayName,
		name,
		readOnly,
		style,
		onChange,
		children,
		dataType,
		value,
		itemData,
	} = props;
	
	const {
		visible = true, // Handle style.visible in item.jsx instead of per component
	} = style;

	const isSelected = !!(dataType === "association" ? value : children.find(c => c.name === "selected")?.value);
	const associations = dataType === "association" ? [props] : children?.filter(c => c.dataType === "association") ?? [];

	const hasValue = (isSelected/* || requiresValue*/)
		&& (Array.isArray(associations[0].value) ? associations[0].value?.length : associations[0].value?.id);

	const onEnable = React.useCallback(async () => {
		await onChange({ ...value, selected: true });
	}, [onChange, name]);

	const onDisable = React.useCallback(async () => {
		let newValue;
		if (dataType === "association") {
			newValue = null;
		} else {
			newValue = {
				...value,
				selected: false,
			};
			const childAssociations = children.filter(c => c.dataType === "association");
			childAssociations.forEach(a => {
				newValue[a.name] = a.multiValue ? [] : null;
			});
		}
		await onChange(newValue);
	}, [onChange, name]);

	const filter = associations[0] ? children.find(c => c.componentType === "filter" && c.name === `${associations[0].name}-filter`) : null;

	return (
		<div
			className={`
				${className}
				c6-cms-checkbox
				checkbox-${name}
				complex-attribute
				${isSelected ? "enabled" : ""}
				${hasValue ? "has-value" : ""}
				${readOnly ? "disabled" : ""}
				${visible ? "" : "hidden"}
			`.trim().replace(/[\t\n]/g, " ").replace(/\s{2,}/g, " ")}
		>
			<AssociationComponent
				label={displayName ?? name}
				enabled={isSelected}
				association={associations[0]}
				extraAssociations={associations.slice(1)}
				filter={filter}
				parentName={dataType === "association" ? null : name}
				skipEnableDisable={dataType === "association"}
				onEnable={readOnly || dataType === "association" ? null : onEnable}
				onDisable={readOnly ? null : onDisable}
				onChange={onChange}
				hasValue={hasValue}
				componentValue={value}
				itemId={itemData?.id}
				readOnly={readOnly}
			/>
		</div>
	);
};

export default React.memo(CheckboxContainer);


function AssociationComponent(props) {
	const {
		label,
		enabled,
		association,
		extraAssociations,
		filter,
		parentName,
		onChange,
		hasValue,
		componentValue,
		itemId,
		readOnly,
	} = props;

	const { data: associationValues, fetchStatus: optionsFetchStatus, refetch } = useQuery(
		[association.valuesUrl],
		() => {
			return CMSAPI.fetchCMSUrl(
				association.valuesUrl.replaceAll('+', '%2B'), 
				association.options?._module ?? "eventplanner"
			);
		}, {
			staleTime: 5 * 60 * 1000, 
			enabled: (!association.options?.preload || menuOpen) && association.valuesUrl?.length > 0 
		},
	);

	const [menuOpen, setMenuOpen] = React.useState(false);
	const buttonRef = React.useRef();

	const filterUrl = getFilterUrl(filter);
	const { data: unavailableOptions, fetchStatus: unavailableOptionsFetchStatus, refetch: refetchUnavailableOptions } = useQuery(
		[filterUrl],
		() => CMSAPI.fetchCMSUrl(filterUrl),
		// Use a short staleTime so that the list is refetched every time the user opens the menu
		{ staleTime: 1000, enabled: menuOpen && !!filterUrl.length },
	);

	const isLoading = optionsFetchStatus === "fetching" || unavailableOptionsFetchStatus === "fetching";
	const value = Array.isArray(association.value)
		? association.value?.filter(v => !!v).map(v => v.id)
		: association.value?.id;
	
	const onItemClick = async (val, e) => {
		// DISABLE
		if (val === VALUE_DISABLE || Array.isArray(val) && val.includes(VALUE_DISABLE)) {
			await props.onDisable();
			setMenuOpen(false);
			return;
		}

		// MULTI VALUE
		if (association.multiValue) {
			let values = association.value ?? [];
			if (values.some(v => v?.id === val)) {
				values = values.filter(v => v?.id !== val);
			} else {
				const completeItem = associationValues?.items?.find(v => v?.id === val) ?? {};
				values = [
					...values,
					completeItem,
				];
			}
			const data = parentName
				? { ...componentValue, [association.name]: values }
				: values;
			await onChange(data);

			// Don't close since its multiValue? Not sure what's best...
			// setMenuOpen(false);
			return;
		}
		
		// NOT MULTI VALUE
		const completeItem = associationValues?.items?.find(v => v?.id === val) ?? {};
		const value = {
			...completeItem,
			id: val,
		};
		const data = parentName
			? { ...componentValue, [association.name]: value }
			: value;
		await onChange(data);
		setMenuOpen(false);
	};
	
	return (
		<>
			<ButtonElement
				valuePresentation={getValuePresentation(association, extraAssociations, onChange, parentName, componentValue, readOnly)}
				hasValue={hasValue}
				label={label}
				enabled={enabled}
				onMenuOpen={readOnly ? null : () => setMenuOpen(true)}
				onEnable={async e => {
					if (props.onEnable) {
						await props.onEnable(e);
					}
				}}
				skipEnableDisable={props.skipEnableDisable}
				buttonRef={buttonRef}
			/>
			<Menu
				value={value}
				onClose={() => setMenuOpen(false)}
				open={menuOpen}
				anchorEl={buttonRef.current}
			>
				<MenuItem
					value={VALUE_DISABLE}
					style={{ color: "var(--attention-color)" }}
					onClick={onItemClick.bind(this, VALUE_DISABLE)}
				>
					Disable
				</MenuItem>
				{getOptionsForAssociation(
					associationValues?.items, 
					isLoading, 
					unavailableOptions?.items, 
					filter?.allowSelectFilteredItem, 
					value, 
					itemId, 
					onItemClick, 
					association.options?.itemsFormat,
					association.options?.groupItemsBy,
				)}
			</Menu>
		</>
	);
}

const ButtonElement = (props) => {
	const onClick = (e) => {
		e.stopPropagation();
		if (props.enabled || props.requiresValue || props.skipEnableDisable) {
			props.onMenuOpen?.();
		} else if (props.onEnable) {
			props.onEnable(e);
		}
	};

	const valuePresentation = props.requiresValue || props.hasValue
		? (props.enabled || props.requiresValue) && props.valuePresentation
		: props.enabled && "N/S";

	return (
		<div ref={props.buttonRef} onClick={onClick} style={{ flexGrow: 1, display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
			<label>
				<span className="enabled-icon icon-check"></span>
				{props.label}
				{/* {!props.style && props.label} */}
				{/* {props.style && getIconForStyle(props.style, props.enabled)} */}
			</label>
			<div className="complex-attribute-value">{valuePresentation}</div>
		</div>
	);
};

function groupByPropertyFn(list, property) {
	if (!property) {
		return list;
	}

	const group = {};

	list?.forEach(l => {
		let propVal = get(l, property);
		if (!group[propVal]) {
			group[propVal] = [];
		}
		group[propVal].push(l);	
	});

	return group;
}

function getOptionsForAssociation(associationValues, isLoading, unavailableOptions, allowSelectUnavailable, value, itemId, onItemClick, itemsFormat, groupByProperty) {
	if (isLoading) {
		return [<MenuItem disabled={true} key={null} value={null}>Loading options...</MenuItem>];
	}

	if (groupByProperty) {
		const grouped = groupByPropertyFn(associationValues, groupByProperty);
		const items = Object.keys(grouped).reduce((acc, curr) => (
			[...acc, <ListSubheader key={`group-${curr}`}>{curr}</ListSubheader>, 
				...getOptionsForAssociation(
					grouped[curr],
					isLoading,
					unavailableOptions,
					allowSelectUnavailable,
					value,
					itemId,
					onItemClick,
					itemsFormat,
					null
				)]
		), []);
		
		return items;
	}

	return associationValues?.map(v => {
		const isSelected = (Array.isArray(value) ? value?.includes(v.id) : value === v.id);
		const unavailableOption = unavailableOptions?.find(uo => {
			return uo.id === v.id && uo.itemid !== itemId;
		});
		const isUnavailable = !!unavailableOption;
		
		let color = undefined,
			text = v.name;
		if (isSelected && isUnavailable) {
			color = "var(--pending-color)";
			text = `${v.name} (CONFLICT WITH ${unavailableOption.itemname})`;
		} else if (isSelected) {
			color = "var(--action-hl-color)";
		} else if (isUnavailable) {
			color = "var(--attention-color)";
			text = `${v.name} (occupied by ${unavailableOption.itemname})`;
		}

		if (itemsFormat) {
			text = format(itemsFormat, v);
		}

		
		return (
			<MenuItem
				key={v.id}
				value={v.id}
				style={{ color }}
				onClick={onItemClick.bind(this, v.id)}
				// disabled={isUnavailable && !allowSelectUnavailable}
			>
				{text}
			</MenuItem>
		);
	});
}

function getValuePresentation(association, extraAssociations, onChange, parentName, componentValue, readOnly) {
	let values = association.value;
	if (values && !Array.isArray(values)) {
		values = [values];
	}

	return (
		<React.Fragment>
			{values?.filter(v => !!v).map(v => {
				const tooltip = getTooltipForValue(v);
				return (
					<div
						key={v.id ?? v.name}
						className={`value-presentation ${false/*warning*/ ? "value-warning" : ""}`}
						title={tooltip}
					>
						<span>{v.name ?? "Loading..."}</span>
						{extraAssociations.map(ea => (
							<ExtraAssociation
								key={ea.name}
								association={ea}
								onChange={onChange}
								parentName={parentName}
								componentValue={componentValue}
								readOnly={readOnly}
							/>
						))}
					</div>
				);
			})}
		</React.Fragment>
	);
}

function ExtraAssociation({ association, onChange, parentName, componentValue, readOnly }) {
	const { data } = useQuery(
		[association.valuesUrl],
		() => CMSAPI.fetchCMSUrl(association.valuesUrl),
		{ staleTime: 5 * 60 * 1000 },
	);
	const associationValues = data?.items;

	const onExtraAssociationChange = React.useCallback(
		e => {
			const data = {
				...componentValue,
				[association.name]: { id: e.target.value },
			};
			onChange(data);
		},
		[association, parentName]
	);

	return (
		<div className="select-wrapper">
			<select
				value={association.value?.id ?? ""}
				onClick={e => e.stopPropagation()}
				onChange={onExtraAssociationChange}
				disabled={readOnly}
				style={{ textAlign: "center" }}
			>
				{(association.value === null || association.value === undefined) && <option value={null}>N/S</option>}
				{associationValues?.map(v => (
					<option key={v.id} value={v.id}>{v.name}</option>
				))}
			</select>
		</div>
	);
}

function getFilterUrl(filter) {
	if (!filter?.valuesUrl?.length || Object.values(filter.value ?? {}).includes(null)) {
		return "";
	}

	let url = filter.valuesUrl;
	Object.entries(filter.value ?? {}).forEach(([key, value]) => {
		// Encode plus signs (+, used in datetime) here because they are not encoded later on, not sure why :/
		url = url.replace(`$${key}`, value ? `'${value}'` : "null").replace("+", "%2B");
	});
	return url;
}

function getTooltipForValue(value) {
	// if (warning) {
	// 	return "This option is busy at this start time!";
	// }
	return value.tooltip?.se_sv ?? value.tooltip;
}
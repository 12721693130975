import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { ItemGroup } from '../../../../components/list/listItems'
import DraggableItem from '../../../../components/list/draggableItem.jsx'
import PreviewDialog from '../../previewDialog'

import Content from './item'

import ListConstants from '../constants'

import Actions from '../../actions'


export default class List extends Component {

	static propTypes = {
		items: PropTypes.array.isRequired,
		onItemDropOutside: PropTypes.func,
		onShowEpisodes: PropTypes.func,
	}

	state = {
		previewOpen: false,
		previewList: null
	}

	handlePreviewClick = (e, list) => {

		this.setState({
			previewOpen: true,
			previewList: list
		});
	}

	handleClose = () => {
		this.setState({
			previewOpen: false,
			previewList: null
		});
	}

	render() {
		const {
			onShowEpisodes,
			onItemDropOutside,
			items,
			children,
			filters,
		} = this.props;

		const { previewOpen, previewList } = this.state;

		const mode = ["recent-regular", "cometauto"].includes(filters._filter)
			? "selections"
			: "discovery";

		return items && items.length
			? (
				<div>
					{ previewOpen && <PreviewDialog open={previewOpen} list={previewList} onClose={this.handleClose} /> }
					<ItemGroup>{ this.renderItems(items, onItemDropOutside, onShowEpisodes, mode) }</ItemGroup>
				</div>
			)
			: children;
  	}

	renderItems(items, onItemDropOutside, onShowEpisodes, mode) {

		return items.map(item => {
			const isDiscoveryProgram = item.type?.endsWith("Program");
			const referenceIdPrefix = isDiscoveryProgram ? "discoveryprogram" : "discovery";
			// const referenceId = item.contentType && item.contentType.referenceId || item.id;
			const referenceId = item.reference || item.id;
			const referenceIdWithPrefix = `${referenceIdPrefix}-${referenceId}`;

			// const handleType = item.contentType && typeof(item.contentType) === "object"
			const handleType = isDiscoveryProgram
				? "thumbnail"
				: "icon";

			return (
				<DraggableItem
					key={item.id}
					id={item.id}
					handleType={handleType}
					referenceId={referenceIdWithPrefix}
					actionData={item}
					onItemDropOutside={onItemDropOutside}
				>
					<Content
						item={item}
						onPreviewClick={this.handlePreviewClick}
						onShowEpisodes={onShowEpisodes}
						mode={mode}
					/>
				</DraggableItem>
			);
		});
	}
}
import React from 'react'
import PropTypes from 'prop-types'
import TextField from '@mui/material/TextField'
import Avatar from '@mui/material/Avatar'

import ContentItem, { PlaceholderItem, Content } from '../../../../components/list/contentItem'

import Button from '../../../../components/ui/controls/button'

import '../../../../components/list/list.css'

const ENTER_KEY_CODE = 13;

export default class StarRecipientList extends React.Component {
	static propTypes = {
		items: PropTypes.array.isRequired,
		onChange: PropTypes.func,
	}

	constructor(props) {
		super(props);

		this.handleClick = this.handleClick.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleCreateItem = this.handleCreateItem.bind(this);

		this.state = {
			createNew: false,
			newItemName: "",
        };
	}

	handleClick(createNew = true) {
		this.setState({
			createNew,
			newItemName: !createNew ? "" : this.state.newItemName,
		});
	}

	handleChange(e) {
		const newItemName = e.target.value;
		this.setState({ newItemName });
	}

	handleCreateItem() {
		if(!this.state.newItemName) {
			this.setState({ createNew: false });
		}
		else {
			this.props.onChange("add", this.state.newItemName);
			this.setState({ newItemName: "" });
		}
	}

	render() {
		const { items, onChange } = this.props;
		const { newItemName, createNew } = this.state;

		const newPerson = onChange
			? (
				<NewItem
					value={newItemName}
					createNew={createNew}
					onClick={this.handleClick}
					onChange={this.handleChange}
					onCreateItem={this.handleCreateItem} />
			)
			: null;

		return (
			<div className="recipients">
				{ newPerson }
				{ items.map(item => (
					<ContentItem key={item}>
						<Avatar sx={getAvatarStyle(item)}>{getChars(item)}</Avatar>
						<Content>
							<h1>{item}</h1>
						</Content>
						{ getRemoveAction(item, onChange) }
					</ContentItem>
				)) }
			</div>
		);
	}
};

// HELPERS
const getRemoveAction = (item, onChange) => {

	if(!onChange) {
		return null;
	}

	return (
		<div className="c6-actions-inline">
			<button className="inline no-title" onClick={() => { onChange("remove", item); }}>
				<span className="icon-cancel" />
			</button>
		</div>
	);

};

const NewItem = ({ value = "", createNew, onClick, onChange, onCreateItem }) => {
	if(!createNew) {
		return (
			<PlaceholderItem onClick={onClick}>
				<Content>
					<h1 className="icon-add_circle large-icon">Add person...</h1>
				</Content>
			</PlaceholderItem>
		);
	}

	return (
		<PlaceholderItem className="active">
			<Content>
				<TextField
					variant="standard"
					label="Email address"
					value={value}
					inputRef={focus}
					fullWidth={true}
					onChange={onChange}
					onKeyDown={e => { handleKeyDown(e, onCreateItem); }}
				/>
			</Content>
			<Content size="min">
				<Button
					type="approve"
					onClick={onCreateItem}
					noBackground />
				<Button
					type="cancel"
					onClick={onClick.bind(this, false)}
					noBackground />
			</Content>
		</PlaceholderItem>
	);
}

const focus = input => {
	if (input) {
		setTimeout(() => {input.focus()}, 100);
	}
}

const handleKeyDown = (event, onCreateItem) => {
	if(event.keyCode === ENTER_KEY_CODE) {
		onCreateItem();
	}
}

const getChars = item => {
	const email = item.split("@")[0];
	const name = email.split(".");
	return name.length >= 2 ? name[0].charAt(0).toUpperCase() + name[1].charAt(0).toUpperCase() : name[0].charAt(0).toUpperCase();
}

const hashCode = str => {
	let hash = 0;
	for (let i = 0; i < str.length; i++) {
	  hash = str.charCodeAt(i) + ((hash << 5) - hash);
	}
	return hash;
  }

const pickColor = str => `hsl(${hashCode(str) % 360}, 100%, 90%)`;

const getAvatarStyle = item => ({
	marginLeft: "15px",
	backgroundColor: pickColor(item),
	color: "var(--text-color)",
});
import Actions from '../actions'

export default {
	targetComponent: "Item",
	actions: [
		{
			title: "Approve changes",
			method: Actions.showSchedule,
			type: "content",
		},
	],
};

import cloneDeep from 'lodash/cloneDeep'
import moment from 'moment'

import appConfig from 'config'
import alt from '../../core/services/alt'
import Actions from './actions'

import Const from '../../core/constants'

const filterStorageKey = "c6-traffic-store-filter";
const prevFilterStorageKey = "c6-traffic-store-filter-prev";

class TrafficStore {

	constructor() {

		this.isLoading = false;
		this.excelLoading = false;
		this.item = {
			isLoading: false,
		};

		// Items/List
		this.list = {};
		this.initStore("programs", {
			pageSize: 25,
			includeInternal: false,
		});
		this.initStore("threads");
		this.initStore("programversions");
		this.initStore("programversionPackages");
		this.initStore("events");
		this.initStore("exposuretypes", { includeInternal: false, pageSize: 1000 });
		this.initStore("distributors", { includeInternal: false, pageSize: 1000 });
		this.initStore("languages", { pageSize: 1000 });

		const storedFilters = appConfig.features.saveListFiltersInStorage ? JSON.parse(sessionStorage.getItem(filterStorageKey)) : null;
		this.filters = storedFilters ?? {
			searchText: "",
			filter: {
				programTypes: "single,episode",
				owner: "",
				rightVersion: "",
				from: moment().startOf("month").format("YYYY-MM-DD"),
				premiere: "true",
				filter: "",
				orderBy: "",
				distributor: "",
				exposureType: "",
				exposureTypeClasses: "svod,linear,avod",
				searchWithoutFrom: false,
			},
		};

		const storedPrevFilters = appConfig.features.saveListFiltersInStorage ? JSON.parse(sessionStorage.getItem(prevFilterStorageKey)) : null;
		this._prevFilter = storedPrevFilters ?? null;
		setDependentFilters(this.filters, this.filters.searchText);

		this.bindListeners({
			// FILTERS
			onSearch: Actions.SEARCH,
			onFilter: Actions.FILTER,

			// LISTS
			onFetchItems: Actions.FETCH_ITEMS,
			onPageItems: Actions.PAGE_ITEMS,
			onItemsUpdated: Actions.ITEMS_UPDATED,

			// ITEM
			onFetchItem: Actions.FETCH_ITEM,
			onItemLoaded: Actions.ITEM_LOADED,
			onItemSaved: Actions.ITEM_UPDATED,
			onRemoveItem: Actions.REMOVE_ITEM,
			onItemRemoved: Actions.ITEM_REMOVED,
			onRollbackRemoveItem: Actions.ROLLBACK_REMOVE_ITEM,

			// GENERAL
			onUnmount: Actions.UNMOUNT,
			onPersist: Actions.PERSIST,

			//EXCEL
			onExportExcel: Actions.EXPORT_EXCEL,
			onExportExcelFinish: Actions.EXCEL_FINISH,

			onApplySeasonChangesToEpisodes: Actions.APPLY_SEASON_CHANGES_TO_EPISODES,
		});
	}

	// FILTERS
	onSearch({ searchText: text, targetStore }) {
		if (text === "") {
			this.filters.filter = this._prevFilter;
		} else {
			if (!this._prevFilter) {
				this._prevFilter = cloneDeep(this.filters.filter);
			}
			this.filters.filter = {
				searchWithoutFrom: this.filters.filter.searchWithoutFrom,
			};
		}
		this.filters.searchText = text;
		this.list[targetStore || "programs"].items = [];
		setDependentFilters(this.filters, text);
		this.saveFiltersToStorage();
	}

	onFilter(payload) {
		const filterParams = Object.keys(this.filters.filter).length
			? this.filters.filter
			: this._prevFilter;

		const { targetStore, ...filterPayload } = payload;

		this.filters.filter = {
			...filterParams,
			...filterPayload,
		};

		if (payload.searchWithoutFrom === undefined) {
			this.unselectedFilter = false;
			this.filters.searchText = "";
			if (this._prevFilter) {
				this.filters.filter = {
					...this._prevFilter,
					...filterPayload,
					searchWithoutFrom: this.filters.filter.searchWithoutFrom,
				};
				this._prevFilter = null;
			}
		}

		this.list[targetStore || "programs"].items = [];
		setDependentFilters(this.filters, this.filters.searchText);
		this.saveFiltersToStorage();
	}

	// Items
	onFetchItems({ payload, store, requestTime }) {
		const l = this.list[store];
		l.isLoading = true;

		l.nextPageUrl = null;
		l.numberOfItems = 0;
		l.latestRequestTime = requestTime;
		l.latestSearchPayload = payload;
	}

	onFetchPackages() {
		this.list = [];
	}

	onPageItems({ entity, store }) {
		const l = this.list[store || entity];
		l.isLoading = true;
		l.nextPageUrl = null;
	}

	onItemsUpdated({ store, items, appendToExistingItems, numberOfItems, nextPageUrl, requestTime }) {
		const l = this.list[store];

		if (l.latestRequestTime > requestTime) {
			console.log("[%s] Ignoring result with %s items since there have been newer requests.", store, numberOfItems);
		}
		else {
			l.items = appendToExistingItems ? l.items.concat(items) : items;
			l.nextPageUrl = nextPageUrl;
			l.numberOfItems = numberOfItems;

			l.isLoading = false;
		}
	}

	/* Item */
	onFetchItem(entity) {
		this.item[entity] = {};
		this.item.isLoading = true;
	}

	onItemLoaded({ entity, model }) {
		this.item[entity] = model;
		this.item.isLoading = false;
	}

	onItemSaved({ entity, model, originalId, targetStore = null }) {
		const store = targetStore || `${entity}s`;

		// const hasUpdatedStore = this.persistToStore(store, model); // UPDATES OR ADDS
		// const hasUpdatedStore = this.updateStore(store, model); // ONLY UPDATES
		const hasUpdatedStore = this.onPersist({ store, model });

		// We might have several editor stores listening for the same Editor Component
		// action so we need to bail out if this regards another entity.
		if (!hasUpdatedStore) {
			return false;
		}

		this.item.isLoading = false;
	}

	onRemoveItem({ entity, id, targetStore }) {
		this.isLoading = true;

		const item = this.getItem(entity, id, targetStore);
		item._isHidden = true;
	}
	onItemRemoved({ entity, id, targetStore }) {
		this.isLoading = false;

		this.removeItem(entity, id, targetStore);
	}
	onRollbackRemoveItem({ entity, id, targetStore }) {
		this.isLoading = false;

		const item = this.getItem(entity, id, targetStore);
		delete item._isHidden;
	}

	/* =============== */
	/*    GENERAL      */
	/* =============== */
	onUnmount() {
		// this.initFilters();

	}

	initStore(store, filters = null, extras = {}, items = [], ) {
		this.list[store] = {
			items,
			nextPageUrl: null,
			numberOfItems: 0,
			latestRequestTime: null,
			latestSearchPayload: null,
			filters,
			isLoading: false,
			...extras,
		};
	}

	onPersist({ store, model, prepend = false }) {
		const storeItems = this.list[store].items;
		if (!storeItems) {
			return false;
		}

		const index = storeItems.findIndex(si => si.id === model.id);

		if (index >= 0) {
			storeItems[index] = model;
		}
		else if (prepend) {
			storeItems.unshift(model);
		}
		else {
			storeItems.push(model);
		}

		return true;
	}

	onExportExcel() {
		this.excelLoading = true;
	}

	onExportExcelFinish() {
		this.excelLoading = false
	}


	onApplySeasonChangesToEpisodes() {
		this.list.programs.isLoading = true;
	}



	saveFiltersToStorage() {
		sessionStorage.setItem(filterStorageKey, JSON.stringify(this.filters));
		sessionStorage.setItem(prevFilterStorageKey, JSON.stringify(this._prevFilter));
	}
}

export default alt.createStore(TrafficStore, '[Traffic]Store');

/* Helpers */
function setDependentFilters(filters, searchText) {
	// filters.orderBy = "updated";

	// if (searchText && searchText !== "") {
	// 	filters.orderBy = "name";
	// }
}
import React, { Component } from 'react'
import { withRouter } from 'react-router'
import appConfig from 'config'

import withDnDContext from '../../../../core/services/withDnDContext'
import { decoratorUIActions } from '../../../../core/decorators/uiActions'

import App from '../../../../components/app'

import Header from './header'
import Items from '../../shared/items'
import FloatActions from '../../shared/floatactions'

import { getMySpaceAlbum } from '../shared/utils'

import itemActions from './itemActions'
import Actions from '../../actions'
import Store from '../../store'

import './app.css'

const ENTITY = "album";

@withRouter
@decoratorUIActions(itemActions)
class StarAlbum extends Component {
    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);
        this.handleUploadComplete = this.handleUploadComplete.bind(this);
        this.getList = this.getList.bind(this);

        const { params } = this.props;
        this.albumId = parseInt(params.id) || 0;
        this.isMySpace = this.albumId === 0;
        this.searchRef = null;

        this.state = {
            ...Store.getState(),
        };
    }

    componentDidMount() {
        Store.listen(this.onChange);

        // HACK!!!: We use a hard coded albumId: 0 for the private user assets
        // until the API actually suports myspace as an private album (if ever?)
        if(this.isMySpace) {
            Actions.itemLoaded({
                entity: "album",
                model: getMySpaceAlbum(),
            });
            // Actions.fetchItems("assets", { audience: "private", licensing: "public" }, "mySpace");
            Actions.fetchItems("assets", { audience: "private" }, "mySpace");
        }
        else if(this.albumId) {
            Actions.fetchItem(ENTITY, this.albumId);
        }
    }

    componentDidUpdate() {
        // If we have opened a modal, we don't want to mess with the background components
        if (this.props.location?.state?.modal) {
            return true;
        }

        if (this.props.params.id && parseInt(this.props.params.id) !== this.albumId) {
            this.albumId = parseInt(this.props.params.id);
            this.isMySpace = this.albumId === 0;

            if (this.isMySpace) {
                Actions.itemLoaded({
                    entity: "album",
                    model: getMySpaceAlbum(),
                });
                Actions.fetchItems("assets", { audience: "private" }, "mySpace");
            } else if (this.albumId) {
                Actions.fetchItem(ENTITY, this.albumId);
            }
        }
    }

    componentWillUnmount() {
        Store.unlisten(this.onChange);
    }

    onChange(state) {
        this.setState(state);
    }

    handleUploadComplete(model) {
        if (this.isMySpace) {
            Actions.persist(model.asset, "mySpace");
        }
        else if (model.id === this.state.item.album.id) {
            Actions.itemLoaded({ entity: "album", model });
        }
    }

    handleAdd = () => {
        const { album } = this.state.item;
        
        Actions.addSelectedToAlbum(album, this.state.list.selected.items, {});
    }

    handleRemove = () => {
        const { album } = this.state.item;

        const assetIds = this.state.list.selected.items
            .filter(si => album.assets.some(a => a.id === si.id))
            .map(a => a.id);

        Actions.removeAssetsFromAlbum(album.id, { assetIds });
    }

    render() {
        const { selected, mySpace } = this.state.list;
        const { album, isLoading } = this.state.item;

        if(!album) {
            return null;
        }

        const [ list, url ] = this.getList(album, isLoading)

        return (
            <App name="c6-star c6-star-album" isLoading={list.isLoading}>
				<Header title={album.displayName || album.name} data={album} location={this.props.location} />
                <Items
                    uploadUrl={url}
                    onUploadComplete={this.handleUploadComplete}
                    list={list}
                    setInputRef={el => this.searchRef = el}
                    selected={selected}
					uploaderInformationText={`Album: "${album.displayName ?? album.name}"`}
					uploaderIdPrefix={`album-${album.albumGuid}`}
                />
                <FloatActions selectedItems={selected.items} album={album} mySpaceItems={mySpace.items} onAdd={this.handleAdd} onRemove={this.handleRemove} />
            </App>
		);
    }

    // CLASS HELPERS
    getList(album, isLoading) {
        let list, url;
        if(this.isMySpace) {
            list = this.state.list.mySpace;
            url = `${appConfig.api.star}files/upload?audience=private&licensing=internal`;
            // url = `${appConfig.api.star}files/upload?audience=Private&contentSource=${contentSource}`;
        }
        else {
            list = {
                items: album.assets || [],
                isLoading,
            };
            url = `${appConfig.api.star}albums/${album.id}/uploadFile`;
        }

        return [ list, url ];
    }
}

export default withDnDContext(StarAlbum);
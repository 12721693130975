import React, { Component } from 'react'
import PropTypes from 'prop-types'

import InfiniteScroll from 'react-infinite-scroller'

import Empty from '../../../components/list/empty'

// import { decoratorUIActions } from '../../../core/decorators/uiActions'
import { ItemGroup } from '../../../components/list/listItems'
// import itemActions from './listItemActions'
import Item from './listItem'

const ONLY_SEARCH_WHEN_SEARCHTEXT_IS_AT_LEAST = 2;

// @decoratorUIActions(itemActions)
export default class List extends Component {


	static propTypes = {
		items: PropTypes.array.isRequired,
		// isLoading: PropTypes.number.isRequired,
		searchText: PropTypes.string,
		filters: PropTypes.object,
		textEmpty: PropTypes.string,
	}

	constructor(props) {
		super(props);
		this.state = {
			openComments: false,
		}
	}

	render() {
		const {
			items,
			isLoading,
			searchText = "",
			textEmpty = "Sorry, could not find any items.",
			hasMore,
			loadMore,
			languages,
			filters,
			handleFilter,
		} = this.props;

		if (!(items && items.length)) {
			const textTooFewCharacters = searchText && searchText.length < ONLY_SEARCH_WHEN_SEARCHTEXT_IS_AT_LEAST ? `Please type ${ONLY_SEARCH_WHEN_SEARCHTEXT_IS_AT_LEAST} characters or more to search.` : "";
			return (
				<Empty key="empty" v2={true} isLoading={!!isLoading}>
					{textTooFewCharacters || textEmpty}
					{searchText && <span>&nbsp;{getSearchSpecificity(filters, handleFilter)}</span>}
				</Empty>
			);
		}

		return (
			<InfiniteScroll
				loadMore={loadMore}
				hasMore={hasMore}
				loader={<div className="infinite-loader" key="infinite-loader">Loading ...</div>}
				useWindow={false}
				threshold={700}
				initialLoad={false}
			>
				{this.renderGroups(this.props)}
			</InfiniteScroll>
		);
	}

	renderGroups({ searchText, handleFilter, items, filters, isSeasonPackagesList, onApplySeasonChangesToEpisodes }) {
		if (searchText) {
			const title = <span>Items matching "{searchText}" {getSearchSpecificity(filters, handleFilter)}</span>;
			return (
				<ItemGroup title={title}>
					{this.renderItems(items, isSeasonPackagesList)}
				</ItemGroup>
			);
		}

		else if (isSeasonPackagesList) {
			const seasons = items.filter(item => item.type?.toLowerCase() === "season");
			const episodes = items.filter(item => item.type?.toLowerCase() === "episode");

			return (
				<React.Fragment>
					<ItemGroup title="Season">
						{this.renderItems(seasons, isSeasonPackagesList, onApplySeasonChangesToEpisodes)}
					</ItemGroup>
					<ItemGroup title="Episodes">
						{this.renderItems(episodes, isSeasonPackagesList)}
					</ItemGroup>
				</React.Fragment>
			)
		}

		return this.renderItems(items, isSeasonPackagesList);
	}

	renderItems(items, isSeasonPackagesList, onApplySeasonChangesToEpisodes) {
		return items.map(item => (
				<Item
					key={item.id}
					id={item.id}
					actionData={item}
					item={item}
					languages={this.props.languages}
					isSeasonPackagesList={isSeasonPackagesList}
					onApplySeasonChangesToEpisodes={onApplySeasonChangesToEpisodes}
				/>
			));
	}
}

function getSearchSpecificity(filters, handleFilter) {
	const searchWithoutFrom = filters.filter.searchWithoutFrom;
	const currentSearchInfo1 = searchWithoutFrom ? "Also searching for inactive programs." : "Searching only for active programs.";
	const currentSearchInfo2 = searchWithoutFrom ? "search only for active programs" : "also search for inactive programs";
	return (
		<span>
			({currentSearchInfo1} You can&nbsp;
			<a
				className="c6-link"
				onClick={handleFilter.bind(this, "filter", { target: { name: "searchWithoutFrom", value: !searchWithoutFrom } })}
			>
				{currentSearchInfo2}
			</a>
			&nbsp;if you'd like)
		</span>
	);
}
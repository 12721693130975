import * as SportAPI from '../../../apis/sport'
import { useQuery } from '@tanstack/react-query'

const useSportArenas = () => {
    const filters = {
        pageSize: 1000,
    };
    return useQuery(
        ["sport", "arenas", filters],
        () => SportAPI.fetchArenas(filters),
    );
};

export default useSportArenas;
import React from 'react'
import moment from 'moment'

import Item from '../shared/listItem'
import itemActions from './listItemActions'

import * as VodCoreAPI from '../../../apis/vodcore'
import useList from '../../../core/hooks/useList'
import GenericListApp from '../../../components/genericApp/listApp'
import { decoratorUIActions } from '../../../core/decorators/uiActions'
import { renderTodayOrDate } from '../../../utils/date'

import { getPremiere, getStatus, sortServicesAndOperators } from '../shared/utils'
import useVodCoreServices from '../../../core/queries/vodcore/useVodCoreServices'
import useVodCorePackages from '../../../core/queries/vodcore/useVodCorePackages'

import '../packages-classic/app.css'

const MODULE = "vod";
const DATASTORE = "packages";
// const TEXT_HEADING = "Packages";
const TEXT_BACK_BUTTON = null;//"Administration";
const TEXT_EMPTY = null;//"No services? It's about time to create some then!";
const TEXT_CREATE = null;//"Create service";
const ICON_CREATE = null;//"add";
const PATHNAME_CREATE = null;//"vod/admin/services/create";

function ListApp(props) {

	const serviceRoute = props.routes.find(r => r.serviceId !== null && r.serviceId !== undefined) || {};
	const { serviceId: serviceIdFromRoute, serviceName } = serviceRoute;

	const query = new URLSearchParams(window.location.search); // Read from window.location.search because props.location.query from react-router seems to be lagging behind a bit?
	const serviceIdFromQuery = query.has("serviceId") ? parseInt(query.get("serviceId")) : null;

	const { data: services } = useVodCoreServices({
		filter: { status: "enabled", orderBy: "name", pageSize: 1000 },
		enabled: !serviceName?.length,
	});

	const FILTER_DEFAULTS = {
		searchText: "",
		premiere: "lastsent",
		status: "",
		serviceId: serviceIdFromRoute ?? serviceIdFromQuery ?? null,
		_includeExpiredInSearch: false,
	};

	const filterConfig = {
		searchText: {
			type: "search",
			alwaysVisible: true,
		},
		serviceId: {
			type: "dropdown",
			title: "Service",
			alwaysVisible: true,
			options: [
				{ key: null, text: "Any" },
				...services?.items?.sort(sortServicesAndOperators).map(s => ({
					key: s.id,
					text: (s.displayName ?? s.name),
				})) ?? []
			],
			// hidden: (filterValues) => {
			// 	const { searchText } = filterValues;
			// 	return searchText?.length > 0;
			// },
		},
		premiere: {
			type: "switch",
			title: "Show",
			options: getPremiere(),
			disabled: (filterValues) => {
				const { searchText } = filterValues;
				return searchText?.length > 0;
			},
		},
		status: {
			type: "switch",
			title: "Status",
			options: getStatus(),
			disabled: (filterValues) => {
				const { searchText } = filterValues;
				return searchText?.length > 0;
			},
		}
	};

	if (serviceIdFromRoute || serviceIdFromQuery) {
		delete filterConfig.serviceId;
	}

	const listState = useList({
		listKey: DATASTORE,
		fetchData: VodCoreAPI.fetchPackages,
		filterDefaults: FILTER_DEFAULTS,
		transformFilters: transformFilters,
	});

	let textHeading = "Packages";
	if (serviceName) {
		textHeading += ` (${serviceName})`;
	}

	const { data: allPackagesMatchingSearch } = useVodCorePackages({
		filter: {
			...transformFilters(listState.filters.values),
			endAfter: null,
			pageSize: 1,
		},
		enabled: !listState.filters.values._includeExpiredInSearch && listState.filters.values.searchText.length > 0,
	});

	return (
		<GenericListApp
			{...props}
			module={MODULE}
			datastore={DATASTORE}
			state={listState}
			textHeading={textHeading}
			textBackButton={TEXT_BACK_BUTTON}
			textEmpty={TEXT_EMPTY}
			textCreate={TEXT_CREATE}
			iconCreate={ICON_CREATE}
			filterConfig={filterConfig}
			pathnameCreate={PATHNAME_CREATE}
			collapseFiltersDefault={false}
			virtualize={false}
			groupItemsBy={(item) => groupItemsBy(item, listState.filters.values)}
			getGroupTitleAdvanced={(groupItems) => getGroupTitle(groupItems, listState.filters.values)}
			getGroupTitleWhenSearching={() => getGroupTitleWhenSearching(listState.filters.values, listState.filters.updateMethods["_includeExpiredInSearch"], listState.numberOfItems, allPackagesMatchingSearch?.numberOfItems)}
			padding={true}
		>
			<Item
				showSeriesLink={true}
			/>
		</GenericListApp>
	);
}

@decoratorUIActions(itemActions)
export default class PackagesApp extends React.Component {
	render() {
		return (
			<ListApp
				{...this.props}
			/>
		);
	}
}

function transformFilters(filters) {
	const transformed = { ...filters };


	// const serviceIdFilter = searchPackagesAcrossAllServices ? null : filter.serviceId;


	let startBefore, startAfter, endBefore, endAfter, orderBy;
	const now = moment();
	const format = "YYYY-MM-DD HH:mm:ss";
	switch (filters.premiere) {
		case "upcoming":
			startAfter = now.format(format);
			orderBy = "publishStart";
			break;
		case "current":
			startBefore = now.format(format);
			endAfter = now.format(format);
			orderBy = "publishStartDescending";
			break;
		case "past":
			endBefore = now.format(format);
			orderBy = "publishEndDescending";
			break;
		case "lastsent":
			orderBy = "lastsent";
			break;
		default:
			orderBy = "publishStart";
			break;
	}

	if (filters.searchText.length) {
		startBefore = startAfter = endBefore = endAfter = null;
		if (!filters._includeExpiredInSearch) {
			endAfter = now.format(format);
		}
	}

	if (filters.status === "attention") {
		if (filters.attentionPastOrUpcoming === "past") {
			startAfter = null;
			startBefore = now.format(format);
			orderBy = "publishStartDescending";
		} else {
			startAfter = now.clone().add(1, "days").format(format);
			startBefore = null;
			orderBy = "publishStart";
		}
	}

	transformed.startAfter = startAfter;
	transformed.endBefore = endBefore;
	transformed.startBefore = startBefore;
	transformed.endAfter = endAfter;
	transformed.orderBy = orderBy ?? filters.orderBy;

	transformed.premiere = /^\d{4}-\d{2}-\d{2}$/.test(filters.premiere) ? filters.premiere : null;
	if (filters.premiere === "lastsent") {
		transformed.filter = "hasBeenSent";
	} else if (filters.premiere === "current") {
		transformed.filter = "activeWindow";
	}

	if (filters.status === "attention") {
		delete transformed.premiere;
		delete transformed.filter;
		delete transformed.searchText;
		delete transformed.status;
		transformed.validationStatus = "failed,warning";
	}

	if (filters.searchText.length) {
		transformed.orderBy = "name";
		delete transformed.premiere;
		delete transformed.status;
		delete transformed.filter;
	}

	if (typeof filters.serviceId === "string" && filters.serviceId.includes(",")) {
		transformed.serviceIds = filters.serviceId;
		delete transformed.serviceId;
	}

	return transformed;
}


function groupItemsBy(item, filters) {
	return getDateForGroup(item, filters);
}

function getGroupTitle(groupItems, filters) {
	const firstItem = groupItems[0];
	const date = getDateForGroup(firstItem, filters);
	return renderTodayOrDate(date);
}

function getDateForGroup(item, filters) {
	const publishWindows = item?.publishWindows ?? [];
	let date = publishWindows[0]?.start;
	switch (filters.premiere) {
		case "past":
			const lastExpiredWindow = getLastExpiredPublishWindow(publishWindows);
			date = lastExpiredWindow ? lastExpiredWindow.end : date;
			break;
		case "lastsent":
			date = item?.lastSent;
			break;
		case "upcoming":
			const firstUpcomingWindow = getFirstUpcomingPublishWindow(publishWindows);
			date = firstUpcomingWindow ? firstUpcomingWindow.start : date;
			break;
		case "current":
			const mostRecentActiveWindow = getMostRecentActivePublishWindow(publishWindows);
			date = mostRecentActiveWindow ? mostRecentActiveWindow.start : date;
			break;
		default:
			const m = moment(filters.premiere);
			const firstUpcomingWindowAfterOrInMonth = getFirstUpcomingPublishWindowAfterOrInMonth(publishWindows, m);
			date = firstUpcomingWindowAfterOrInMonth ? firstUpcomingWindowAfterOrInMonth.start : date;
			break;
	}
	return moment(date).format("YYYYMMDD");
}

function getFirstUpcomingPublishWindow(publishWindows) {
	const now = moment();
	const sorted = (publishWindows || [])
		.filter(pw => moment(pw.start).isAfter(now))
		.sort((pwA, pwB) => moment(pwA.start).valueOf() - moment(pwB.start).valueOf());
	return sorted.length ? sorted[0] : null;
}

function getLastExpiredPublishWindow(publishWindows) {
	const now = moment();
	const sorted = (publishWindows || [])
		.filter(pw => moment(pw.end).isBefore(now))
		.sort((pwA, pwB) => moment(pwB.end).valueOf() - moment(pwA.end).valueOf());
	return sorted.length ? sorted[0] : null;
}

function getMostRecentActivePublishWindow(publishWindows) {
	const now = moment();
	const sorted = (publishWindows || [])
		.filter(pw => moment(pw.start).isSameOrBefore(now) && moment(pw.end).isAfter(now))
		.sort((pwA, pwB) => moment(pwB.start).valueOf() - moment(pwA.start).valueOf());
	return sorted.length ? sorted[0] : null;
}

function getFirstUpcomingPublishWindowAfterOrInMonth(publishWindows, m) {
	const sorted = (publishWindows || [])
		.filter(pw => moment(pw.start).isSameOrAfter(m))
		.sort((pwA, pwB) => moment(pwA.start).valueOf() - moment(pwB.start).valueOf());
	return sorted.length ? sorted[0] : null;
}

function getGroupTitleWhenSearching(filters, updateFilter, numberOfItems, numberOfItemsMatchingSearch) {
	if (filters._includeExpiredInSearch) {
		return <span>Items matching "{filters.searchText}". (Displaying all packages matching your search but you can <span className="c6-link" onClick={() => updateFilter(false)}>show only active and upcoming packages</span> if you'd like)</span>;
	}

	const diff = numberOfItemsMatchingSearch - numberOfItems;
	if (diff > 0) {
		return <span>Items matching "{filters.searchText}". (<span className="c6-link" onClick={() => updateFilter(true)}>{diff} more expired package{diff > 1 ? "s" : ""} also match your search</span>)</span>;
	}

	return <span>Items matching "{filters.searchText}"</span>;
}
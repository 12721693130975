import * as CMSAPI from '../../../apis/cms'
import { useQuery } from '@tanstack/react-query'

const useCMSSeasons = (seriesId: number | string, enabled: boolean = true) => {
    const filters = {
        _entity: "seasonslist",
        "series.id": seriesId,
        orderBy: "name",
        pageSize: 1000
    };
    return useQuery(
        ["cms", "seasons", filters],
        () => CMSAPI.fetchCMSEntities(filters),
        { enabled }
    );
};

export default useCMSSeasons;



import React from 'react'

import MuiText from '../../../../components/ui/editorWidgets/muiText'
import Button from '../../../../components/ui/controls/button'

const PasswordBox = ({ value, onChange, onClick, invalidPassword }) => (
	<div className="password-box">
		<p>Please enter the password that was sent together with the link to this page</p>
		{invalidPassword && <p style={{ color: "var(--attention-color)" }}>Invalid password</p>}
		<br/>
		<MuiText
			label="Password"
			value={value}
			onChange={onChange}
		/>
		<Button
			title="Open"
			onClick={onClick}
			disabled={!value || value.length == 0}
		/>
	</div>
);

export default PasswordBox;
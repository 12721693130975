import React, { Component } from 'react'

import { decoratorUIActions } from '../../../../core/decorators/uiActions'

import { ItemGroup } from '../../../../components/list/listItems'

import Item from './listItem'
import itemActions from './listItemActions'

@decoratorUIActions(itemActions)
export default class List extends Component {

	render() {
		const {
			items,
			children,
		} = this.props;

		return items && items.length
			? (
				<ItemGroup>
					{this.renderItems(items)}
				</ItemGroup>
			)
			: <div style={{ height: "100%" }}>{ children }</div>;
  	}

	renderItems(items) {
		return items.map(item => {
			return (
				<Item
					key={item.id}
					id={item.id}
					item={item}
				/>
			);
		});
	}
}
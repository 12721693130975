import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import ContentItem, { Content } from '../list/contentItem'

import '../listItems/standard.css'

export default class DefaultItem extends PureComponent {

	static propTypes = {
		id: PropTypes.number,
		name: PropTypes.string,
	}

	render() {
		const {
			id,
			name,
		} = this.props;

		return (
			<ContentItem data={this.props}>
				<Content>
					<h1>{name}</h1>
					<p>ID: {id}</p>
				</Content>
			</ContentItem>
		);
	}
}
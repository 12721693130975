// TODO: Cache datasource response?

import * as CMSAPI from '../../../../apis/cms'

export function getSchemaFromComponent(component, parentDataType, module = "eventplanner") {
	const type = getSchemaTypeFromComponent(component, parentDataType);
	const schema = { type, title: "" };
	if (type === "array") {
		schema.title = component.displayName ?? "";

		if (["list", "checkboxList"].includes(component.componentType)) {
			schema.uniqueItems = true;
			schema.items = {
				type: "object",
				// HACK: If we don't add the enum array, rjsf won't render our checkboxes-widget
				// So we check for __DONT_VALIDATE__ errors in app.jsx->customTransformErrors
				enum: [{ "__DONT_VALIDATE__": true }],
			};
		} else if (component.componentType === "container" && component.children?.length) {
			schema.items = {
				type: "object",
				properties: {},
			};
			const childComponents = component.children.filter(filterRelevantComponents) ?? [];
			childComponents.forEach(c => {
				const componentSchema = getSchemaFromComponent(c, parentDataType ?? component.dataType, module);
				schema.items.properties[c.name] = componentSchema;
			});
		}
	} else if (type === "object") {
		schema.properties = {};
		const childComponents = component.children?.filter(filterRelevantComponents) ?? [];
		childComponents.forEach(c => {
			const componentSchema = getSchemaFromComponent(c, parentDataType ?? component.dataType, module);
			schema.properties[c.name] = componentSchema;
		});

		if (component.componentType === "dropdown") {
			schema.properties.id = {
				title: component.displayName ?? component.name,
				type: "string",
			};
		}
	} else {
		schema.title = component.displayName ?? component.name;
		schema.description = component.displayName ?? component.name;

		if (component.dataType === "enum" && component.options?.values) {
			schema.oneOf = Object.entries(component.options.values).map(([key, value]) => ({
				const: key,
				title: value,
			}));
		}
	}

	return schema;
}

function getSchemaTypeFromComponent(component, parentDataType) {
	if (["list", "checkboxList", "container", "tags"].includes(component.componentType) && component.multiValue) {
		return "array";
	}
	if (parentDataType === "integer" || component.componentType === "textbox" && component.dataType === "integer") {
		return "number";
	}
	if (component.componentType === "container") {
		return "object";
	}
	if (component.componentType === "textbox") {
		return "string";
	}
	switch (component.dataType?.toLowerCase()) {
		case "integer":
			return "number";
		case "association":
			return "object";
		case "bool":
			return "boolean";
		case "enum":
			return "string";
		// default:
		// 	return component.dataType;
	}

	return "string";
}

export function getUiSchemaFromComponent(component, module) {
	let uiSchema = { "ui:classNames": "grid-100" };
	if (component.componentType === "tags") {
		uiSchema = {
			...uiSchema,
			"ui:field": "tags",
			"ui:options": {
				fullWidth: true,
				dataSource: () => {
					return new Promise((resolve, reject) => {
						CMSAPI.fetchCMSUrl(component.valuesUrl, module)
							.then(response => {
								const mappedItems = response.items.map(i => ({
									id: i.id,
									name: i.name,
								}));
								resolve(mappedItems);
							});
					});
				},
			},
		}
	} else if (component.dataType === "association" && component.componentType === "dropdown") {
		uiSchema.id = {
			"ui:widget": "select",
			"ui:options": {
				skipIntCheck: true,
				fullWidth: true,
				dataSource: () => {
					return new Promise((resolve, reject) => {
						CMSAPI.fetchCMSUrl(component.valuesUrl, module).then(resolve, reject);
					});
				},
			}
		}
	} else if (component.dataType === "enum" && component.componentType === "dropdown") {
		uiSchema = {
			...uiSchema,
			"ui:widget": "select",
			"ui:options": {
				skipIntCheck: true,
				fullWidth: true,
			}
		};
	} else if (component.dataType === "association" && component.componentType === "checkboxList") {
		uiSchema = {
			...uiSchema,
			"ui:widget": "checkboxes",
			"ui:options": {
				useObjectsInsteadOfStrings: true,
				dataSource: () => {
					return new Promise((resolve, reject) => {
						CMSAPI.fetchCMSUrl(component.valuesUrl, module)
							.then(response => {
								const mappedItems = response.items.map(i => ({
									value: i,
									label: i.name,
								}));
								resolve(mappedItems);
							});
					});
				},
			},
		}
	}

	if (component.componentType === "container" && component.multiValue) {
		uiSchema.items = {};
		const components = component.children?.filter(filterRelevantComponents) ?? [];
		components.forEach(c => {
			const componentSchema = getUiSchemaFromComponent(c, module);
			uiSchema.items[c.name] = componentSchema;
		});
	}

	return uiSchema;
}

export const filterRelevantComponents = c => (
	c.componentType !== "metadata"
	&& isNaN(parseInt(c.componentType, 10)) // Hide components where componentType is a number, which it sometimes is for brand new componentTypes
	&& !(c.componentType === "container" && !c.children?.length)
	&& c.style?.visible !== false
);
import React from 'react'

import EventPlannerComponent from '../component'

import './container.css'

const Container = ({
	name,
	className,
	href,
	displayName,
	children,
	onChange,
	_onChangeWithoutAddedPath,
	path = [],
	itemData = {},
	otherProps = {},
	matchCustomComponent = null,
	style,
	value,
	readOnly,
	parentReadOnly,
	options = {},
	valueIndex,
}) => {

	const childElements = React.useMemo(
		() => {
			return children.map((c, i) => (
				<EventPlannerComponent
					key={`${c.name}-${i}`}
					templateItem={{
						...c,
						value: c.value ?? value?.[c.name],
					}}
					onChange={onChange}
					_onChangeWithoutAddedPath={_onChangeWithoutAddedPath}
					path={valueIndex !== undefined ? [...path, valueIndex, name] : [...path, name]}
					otherProps={otherProps}
					itemData={itemData}
					matchCustomComponent={matchCustomComponent}
					parentReadOnly={readOnly ?? parentReadOnly}
				/>
			));
		},
		[value, children]
	);

	const onClick = React.useCallback(
		() => {
			if (href && typeof (otherProps.openModal) === "function") {
				const idComponent = children.find(c => c.name === "id");
				otherProps.openModal({
					modalName: href,
					itemData: itemData,
					id: idComponent?.value ?? itemData.id,
				});
			}

			if (options.toggleContainer && typeof otherProps.onToggleContainer === "function") {
				otherProps.onToggleContainer(options.toggleContainer, itemData.id);
			}
		},
		[href, otherProps.openModal, otherProps.onToggleContainer, children, itemData]		
	);

	if (style?.format?.includes("hiddenWhenNull") && value == null) {
		return null;
	}

	return (
		<ContainerElement
			className={className}
			style={{
				cursor: (href || options.toggleContainer) ? "pointer" : undefined,
			}}
			onClick={onClick}
			displayName={displayName}
		>
			{/* {displayName && <span key="displayName" className="displayname">{displayName}</span>} */}
			{childElements}
		</ContainerElement>
	);
};

export default Container;

const ContainerElement = (props) => {
	if (props.displayName) {
		return (
			<fieldset
				className={["labelled-container", "hide-on-empty"].join(" ")}
				style={props.style}
				onClick={props.onClick}
			>
				<legend><span>{props.displayName}</span></legend>
				<div className={[props.className, "field-content"].join(" ")}>
					{props.children}
				</div>
			</fieldset>
		);
	}

	return (
		<div
			className={props.className}
			style={props.style}
			onClick={props.onClick}
		>
			{props.children}
		</div>
	);
}

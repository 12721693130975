import React from 'react'

import ContentItem, { Content } from '../../../../components/list/contentItem'
import InlineHeading from '../../../../components/ui/inlineHeading'

import '../../../../components/listItems/standard.css'

const CropType = (props) => {

    const { id, name, displayName, minWidth, minHeight, xRatio, yRatio } = props;

    return (
        <ContentItem data={props}>
            <Content>
                <h1>{displayName}</h1>
                <p><span title="Internal name">{name}</span> - ID: {id}</p>
            </Content>
            <Content>
                <div className="dimensions">
                    <InlineHeading title="MIN WIDTH"><span className={minWidth ? "" : "dimmed"}>{minWidth || " "}</span></InlineHeading>
                    <InlineHeading title="MIN HEIGHT"><span className={minHeight ? "" : "dimmed"}>{minHeight || " "}</span></InlineHeading>
                </div>
                <InlineHeading title="AR">
                    <span className={xRatio && yRatio ? "" : "dimmed"}>
                        {xRatio && yRatio && `${xRatio}/${yRatio}`}
                        {!(xRatio && yRatio) && "N/A"}
                    </span>
                </InlineHeading>
            </Content>
        </ContentItem>
    );
};

export default CropType;
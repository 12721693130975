import * as SelectionsAPI from '../../../apis/selections'
import { useQuery } from '@tanstack/react-query'

interface args {
    filter?: object;
    enabled?: boolean;
}

const useSelectionsServices = ({ filter = {}, enabled = true }: args = {}) => {
    const filters = {
        ...filter,
    };
    return useQuery(
        ["selections", "services", filters],
        () => SelectionsAPI.fetchServices(filters),
        { enabled }
    );
};

export default useSelectionsServices;

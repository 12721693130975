import { MENU } from '../../../components/actions'

import Actions from '../actions'

const ENTITY = "login";

export default {
	targetComponent: "Content",
	actions: [
		// {
		// 	title: "Edit access",
		// 	method: Actions.showLoginModules,
		// 	type: CONTENT,
		// },
		{
			title: "Edit",
			icon: "create",
			method: (data, sourceProps, location) => {
				Actions.edit({
					id: data.id,
					pathname: `${location.pathname}/${ENTITY}s/${data.id}/edit`,
					returnTo: `${location.pathname}${location.search}`,
				});
			},
			type: MENU,
		},
		{
			title: "Duplicate",
			icon: "group_add",
			method: (data, sourceProps, location) => {
				Actions.edit({
					id: data.id,
					pathname: `${location.pathname}/${ENTITY}s/${data.id}/createcopy`,
					returnTo: `${location.pathname}${location.search}`,
				});
			},
			type: MENU,
		},
		{
			title: "Enable",
			icon: "add_circle",
			method: data => { Actions.enableItem(ENTITY, data); },
			type: MENU,
			hidden: data => data.enabled,
		},
		{
			title: "Disable",
			confirm: true,
			icon: "remove_circle",
			method: data => { Actions.disableItem(ENTITY, data); },
			type: MENU,
			hidden: data => !data.enabled,
		},
		{
			title: "Delete",
			confirm: true,
			dangerous: true,
			icon: "delete",
			method: data => { Actions.removeItem(ENTITY, data); },
			type: MENU,
			hidden: data => data.enabled,
		},
	]
};
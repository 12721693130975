import React, { Component } from 'react'
import Button from '../../../components/ui/controls/button'
import Preview from '../../../components/assets/preview'

import Items from '../shared/items'
import FloatActions from '../shared/floatactions'
import AlertDialog from '../shared/alertDialog'

import { decoratorUIActions } from '../../../core/decorators/uiActions'

import itemActions from './assetActions'
import ContainerActions from './actions'
import Actions from '../actions'

import './assets.css'

import appConfig from 'config'

@decoratorUIActions(itemActions)
class ContainerAssets extends Component {
	constructor(props) {
		super(props);

		this.searchRef = null;

		this.state = {
			removeDialogOpen: false,
			moveCopyDialogOpen: false,
		};
	}

	handleUploadComplete = (model) => {
		if (model.id === this.props.container?.id) {
			ContainerActions.updateContainer(model);
			ContainerActions.updateChildContainer(model); // Updates the folder structure asset count in the container editor
		}
	}

	displayRemoveConfirm = () => {
		this.setState({ removeDialogOpen: true });
	}

	handleRemoveAction = (e) => {
		if (e.target && e.target.innerText === "REMOVE") {
			const { container, selected, assets } = this.props;

			const assetIds = selected.items
				.filter(si => {
					const isInCurrentContainer = assets.find(a => a.id === si.id);
					const isNotInUse = assets.filter(a => a.id === si.id).every(a => a.category === "Undefined");
					return isInCurrentContainer && isNotInUse;
				})
				.map(a => a.id);

			ContainerActions.removeAssetFromContainer2(container.id, {
				assetIds,
			});
		}

		this.setState({ removeDialogOpen: false })
	}

	displayAddConfirm = () => {
		this.setState({ moveCopyDialogOpen: true });
	}

	handleMoveCopyAction = (e) => {
		if (e.target && (e.target.innerText === "MOVE" || e.target.innerText === "COPY")) {
			const { container, selected } = this.props;
			const assetIds = selected.items.map(a => a.type === "Image" ? a.id : a.reference || a.id);
			
			ContainerActions.addAssetsToContainer("parent", container.id, assetIds);

			if (e.target.innerText === "MOVE") {
				const assetsToRemove = selected.items
					.filter(a => a.type !== "Container" && !!a.fromContainerId)
					.map(a => ({
						...a,
						id: a.type === "Image" ? a.id : a.reference || a.id,
						fromContainerId: a.fromContainerId,
					}));
				ContainerActions.removeAssetsFromMultipleContainers(assetsToRemove);
			}
		}

		this.setState({ moveCopyDialogOpen: false });
	}
	
	handleMoveFolders = () => {
		const { container, selected } = this.props;
		const containers = selected.items.filter(i => i.type === "Container");

		ContainerActions.moveContainersToChildContainers(
			container.id,
			containers,
			container => {
				Actions.itemRemoved({ id: container.id, targetStore: "selected" });
				Actions.itemRemoved({ id: container.id, targetStore: "searchItems" });
			},
		);
	}

	render() {
		const {
			assets = [],
			container,
			isLoading,
			selected,
			hasEditAccess,
		} = this.props;

		const list = {
			items: assets.filter(a => a.category === "Undefined"), // Assets with an undefined category are our available container assets.
			isLoading,
		};

		const hasRemovableAssets = selected.items.filter(si => assets.filter(a => a.id === si.id).every(a => a.category === "Undefined")).length > 0;


		const url = `${appConfig.api.star}containers/${container.id}/uploadFile`;

		return (
			<div className="c6-star2-container-assets">

				<Items
					modal={true}
					uploadUrl={url}
					onUploadComplete={this.handleUploadComplete}
					list={list}
					selected={selected}
					setInputRef={el => this.searchRef = el}
					uploaderInformationText={`Files for folder: "${container.displayName ?? container.name}"`}
					uploaderIdPrefix={`container-${container.id}`}
				/>

				<FloatActions
					selectedItems={selected.items}
					container={container}
					onAdd={this.displayAddConfirm}
					onRemove={this.displayRemoveConfirm}
					onMoveFolders={this.handleMoveFolders}
				/>
				
				<AlertDialog
					open={this.state.removeDialogOpen}
					primary={hasRemovableAssets ? "Remove" : null}
					secondary="Cancel"
					onAction={this.handleRemoveAction}
				>
					<RemoveDialogContent assets={assets} selected={selected} />
				</AlertDialog>

				<AlertDialog
					open={this.state.moveCopyDialogOpen}
					primary="Copy,Move"
					secondary="Close"
					onAction={this.handleMoveCopyAction}
				>
					<MoveCopyDialogContent assets={assets} selected={selected} />
				</AlertDialog>
			</div>
		);
	}
}

export default ContainerAssets;

// HELPERS
const RemoveDialogContent = ({ assets, selected }) => {

	let presentationItems = [];
	selected.items.forEach(si => {
		const itemIsUsedForPresentation = assets.filter(a => a.id === si.id && a.category !== "Undefined");
		if(itemIsUsedForPresentation.length) {
			presentationItems.push({
				...si,
				_presentationCategories: itemIsUsedForPresentation.map(i => i.category)
			});
		}
	});

	const SIZE = {
		name: "starAsset",
		width: "min-content",
		height: "75px",
	}

	return (
		<div className="asset-alert-dialog">
			{getLengthText(selected, presentationItems)}
			{getUnremovableText(presentationItems)}
			{
				presentationItems.map(i => (
					<div key={i.id} className="asset-alert-dialog-item" title={i.displayName}>
						<Preview imageGuid={i.assetGuid} size={SIZE} />
						<p>Presentation areas: <strong>{i._presentationCategories.join(", ")}</strong>.</p>
					</div>
				))
			}
		</div>
	);
};

// HELPERS
const getLengthText = (selected, presentationItems) => {

	if(presentationItems.length === selected.items.length) {
		return <p>Sorry, all selected assets are used for presentation and can therefore not be removed.</p>
	}

	let lengthText = selected.items.length > 1
		? `${selected.items.length} selected assets`
		: "selected asset";

	if(presentationItems.length) {
		lengthText = `${selected.items.length - presentationItems.length} removable assets of the ${lengthText}`;
	}

	return <p>Are you sure you want to remove the <strong>{lengthText}</strong> from this folder?</p>
}

const getUnremovableText = presentationItems => {
	return presentationItems.length > 0
		? <p>To remove the following assets from this folder you'll need to remove them from their respective presentation areas first:</p>
		: null
};

const MoveCopyDialogContent = ({ assets, selected }) => {
	return (
		<div className="asset-alert-dialog">
			<p>Would you like to move or copy selected assets? Selecting "Move" will remove assets from their current location.</p>
		</div>
	);
};
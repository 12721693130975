import React from 'react'
import { browserHistory } from 'browserHistory'

import Editor, { parseUi }  from '../../../components/editor/'
import * as API from '../../../apis'

import schema from './schema'
import uiSchema from './uiSchema'

export default class ExposureEditor extends React.PureComponent {

	render() {
		return (
			<Editor
				layout="grid"
				api="traffic"
				entity="program"
				getSchema={getSchema}
				getUiSchema={getUiSchema}
				{...this.props}
			/>
		);
	}
}

function getSchema(model, isNew, location, route, params, routes) {

	return schema;
}

function getUiSchema(model, isNew) {
	let ui = uiSchema;
	
	return parseUi(ui, API);
}


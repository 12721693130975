import React from 'react'

import { Item, ItemGroup } from '../../../components/list/listItems'

export default ({ title, channels, filters, onSelectChannel }) => (
	<div>
		<ItemGroup title={title}>
			{channels.map(channel => (
				<Item
					key={channel.key}
					id={channel.id}
					actionData={channel}
					small={true}
					sel={filters.channel === channel.key || !filters.channel && !channel.key}
					onSelect={() => onSelectChannel(channel)}
				>
					<div className="flex flex-dyn-1">
						<div className="c6-content">
							<h1>{channel.text}</h1>
						</div>
					</div>
				</Item>
			))}
		</ItemGroup>
	</div>
);
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import Label from '../../../components/ui/label'
import InlineHeading from '../../../components/ui/inlineHeading'
import ContentItem, { Content } from '../../../components/list/contentItem'

export default class LoginContent extends PureComponent {

	static propTypes = {
		enabled: PropTypes.bool,
		firstName: PropTypes.string,
		lastName: PropTypes.string,
		name: PropTypes.string,
		email: PropTypes.string,
		// phone: PropTypes.string,
		lastLoginTimestamp: PropTypes.string,
		className: PropTypes.string,
	}

	render() {
		const {
			enabled,
			firstName = "",
			lastName = "",
			name,
			email,
			// phone,
			lastLoginTimestamp,
			className = "",
		} = this.props;

		const lastLogin = lastLoginTimestamp
			? moment(lastLoginTimestamp).format("YYYY-MM-DD HH:mm")
			: "";

	    return (
			<ContentItem data={this.props}>
				<Content>
					<h1>{`${firstName} ${lastName}`} {getLabel(enabled)}</h1>
				</Content>
				<Content>
					<InlineHeading title="Login">{name}</InlineHeading>
				</Content>
				<Content>
					<InlineHeading title="E-mail">{email}</InlineHeading>
				</Content>
				{/* <div className="c6-content">
					<InlineHeading title="Phone">{phone}</InlineHeading>
				</div> */}
				<Content>
					{lastLogin && <InlineHeading title="Last login"><span title={lastLoginTimestamp}>{lastLogin}</span></InlineHeading>}
				</Content>
			</ContentItem>
		);
  	}
}

//<a href={`mailto:${email}`}>{email}</a>

// HELPERS
function getLabel(enabled) {
	return !enabled
		? <Label status="expired">Disabled</Label>
		: null ;
}
import { CONTENT } from '../../../components/actions'

export default {
	targetComponent: "Content",
	actions: [
		{
			title: "Open program dialog",
			method: (program, sourceProps) => {
                if (program.onProgramClick) {
                    program.onProgramClick(program);
                }
            },
			type: CONTENT,
		},
	]
};
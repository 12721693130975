import alt from '../../core/services/alt'
import EditorActions  from '../../components/editor/actions'

import Actions from './actions'

class ShieldStore {

	constructor() {
		this.isLoading = false;

		this.item = {
			login: {},
			module: {},
			service: {},
			resource: {},
			role: {},
		};

		this.logins = [];
		this.modules = [];
		this.services = [];
		this.resources = [];
		this.roles = [];

		this.bindListeners({
      		onRequestFailed: Actions.REQUEST_FAILED,

			onFetchItems: Actions.FETCH_ITEMS,
			onItemsUpdated: Actions.ITEMS_UPDATED,
			onFetchItem: Actions.FETCH_ITEM,
			onItemLoaded: Actions.ITEM_LOADED,
			onItemSaved: [Actions.ITEM_UPDATED,EditorActions.MODEL_SAVED],

			onRemoveItem: Actions.REMOVE_ITEM,
			onItemRemoved: Actions.ITEM_REMOVED,
			onRollbackRemoveItem: Actions.ROLLBACK_REMOVE_ITEM,

			onAddItemEntity: Actions.ADD_ITEM_ENTITY,
			onRemoveItemEntity: Actions.REMOVE_ITEM_ENTITY,

			onSetItemEntityProperty: Actions.SET_ITEM_ENTITY_PROPERTY,

			onUnmount: Actions.UNMOUNT
		});
	}

	onRequestFailed() {
		this.isLoading = false;
	}

	onFetchItems() {
		this.isLoading = true;
	}

	onItemsUpdated({ datastore, items }) {
		this[datastore] = items;
		this.isLoading = false;
	}

	onFetchItem() {
		this.isLoading = true;
	}

	onItemLoaded({entity, model}) {
		this.item[entity] = model;
		this.isLoading = false;
	}

	// TODO: Remove datastore, we're using entities
	// TODO!: Keep item index when rollbacking
	onItemSaved({ datastore, entity, model, originalId }) {
		const store = entity ? `${entity}s` : datastore;

		// We might have several editor stores listening for the same Editor Component
		// action so we need to bail out if this regards another entity.
		if(!this[store]) {
			return false;
		}

		const index = this[store].findIndex(m => (m.id === model.id));

		if(index >= 0) {
			this[store][index] = model;
		} else {
			this[store].push(model);
		}
		this.isLoading = false;
	}

	onRemoveItem({ entity, id }) {
		this.isLoading = true;

		const item = this.getItem(entity, id);
		item._isHidden = true;
	}
	onItemRemoved({ entity, id }) {
		this.isLoading = false;

		this.removeItem(entity, id);
	}
	onRollbackRemoveItem({ entity, id }) {
		this.isLoading = false;

		const item = this.getItem(entity, id);
		delete item._isHidden;
		// item._isHidden = false;
	}

	onAddItemEntity({entities, payload}) {
		this.isLoading = true;

		const { parentEntity, entity } = entities;
		const store = this.item[parentEntity][`${entity}s`];
		store.push(payload.data);

		this.item[parentEntity] = {
			...this.item[parentEntity],
		};
	}

	onRemoveItemEntity({entities, payload}) {
		this.isLoading = true;

		const { parentEntity, entity } = entities;
		const store = this.item[parentEntity][`${entity}s`];
		const index = store.findIndex(e => e.id === payload.data.id);
		if(index >= 0) {
			store.splice(index, 1);
		}

		this.item[parentEntity] = {
			...this.item[parentEntity],
		};
	}

	onSetItemEntityProperty() {
		this.isLoading = true;
	}

	onUnmount() {
		this.item = {
			login: {},
			module: {},
			service: {},
			resource: {},
			role: {},
		};

		this.logins = [];
		this.modules = [];
		this.services = [];
		this.resources = [];
		this.roles = [];

		this.isLoading = false;

	}

	// HELPERS
	getItem(entity, id) {
		const store = this[`${entity}s`];
		return store.find(e => e.id === id);
	}

	removeItem(entity, id) {
		const store = this[`${entity}s`];
		const index = store.findIndex(e => e.id === id);
		store.splice(index, 1);
	}
}
export default alt.createStore(ShieldStore, '[Shield]Store');




import React from 'react'
import './inlineHeading.css'

export default ({ title = "Missing title prop", children, className, hoverTitle }) => {
    if (!children) return null;
    
    return (
        <div className={className} title={hoverTitle}>
            <small className="inline-heading">{title}</small>
            {children}
        </div>
    );
}
import React from 'react'
import PropTypes from 'prop-types'

import Button from '../../../components/ui/controls/button'
import { syncDeepFindGroup, getTopLevelGroup, syncIsGroupInGroups, getGroupLineage } from '../../../utils/groups'

import './groupMenuNew.css'

export default class GroupMenuNew extends React.Component {

	static propTypes = {
		groups: PropTypes.array,
		onSelectGroup: PropTypes.func,
		selectedGroup: PropTypes.object,
		disabled: PropTypes.bool,
	}

	state = {
		menuOpen: false,
		hoveringGroup: null,
	}

	groupMenuContainer = React.createRef()
	hoverTimeout = null
	containerMouseLeaveTimeout = null

	componentWillUnmount() {
		document.removeEventListener("click", this.handleOutsideClick);
		clearTimeout(this.hoverTimeout);
		clearTimeout(this.containerMouseLeaveTimeout);
	}

	handleGroupClick = (group, e) => {
		e.preventDefault();
		e.stopPropagation();
		this.props.onSelectGroup(group, getTopLevelGroup(this.props.groups, group));
		this.closeMenu();
	}

	handleGroupMouseEnter = (group, e) => {
		clearTimeout(this.hoverTimeout);
		this.hoverTimeout = setTimeout(() => {
			this.setState({ hoveringGroup: group });
		}, 200);
	}

	handleContainerMouseEnter = e => {
		e.preventDefault();
		clearTimeout(this.containerMouseLeaveTimeout);
	}

	handleContainerMouseLeave = e => {
		e.preventDefault();
		this.containerMouseLeaveTimeout = setTimeout(() => {
			this.closeMenu();
		}, 500);
	}

	getMenuItem = (group, index, depth) => {
		const children = group.children?.map((c, i) => this.getMenuItem(c, i, depth + 1));
		const isSelected = this.props.selectedGroup?.id === group.id;

		const leftIcon = isSelected ? <span className="icon-check"></span> : null;
		const rightIcon = children?.length > 0 ? <span className="icon-chevron_right children-icon"></span> : null;

		const isHovered = this.state.hoveringGroup?.id === group.id;
		const childIsHovered = this.state.hoveringGroup && syncIsGroupInGroups(this.state.hoveringGroup.id, group.children);
		const open = isHovered || childIsHovered;
		return (
			<div
				className="c6-group-menu-item"
				key={group.id}
				onClick={this.handleGroupClick.bind(this, group)}
				onMouseEnter={this.handleGroupMouseEnter.bind(this, group)}
			>
				{leftIcon}{depth === 1 && renderServiceInfo(group)}{group.displayName || group.name}{renderHiddenInfo(group)}{rightIcon}
				{children?.length > 0 && (
					<div className={`c6-group-menu-list ${open ? "open" : ""}`}>
						{children}
					</div>
				)}
			</div>
		);
	}

	openMenu = (event) => {
		event?.preventDefault();
		this.setState({
			menuOpen: true,
			hoveringGroup: this.props.selectedGroup,
		});
		document.addEventListener("click", this.handleOutsideClick);
	}

	closeMenu = event => {
		event?.preventDefault();
		clearTimeout(this.hoverTimeout);
		document.removeEventListener("click", this.handleOutsideClick);
		this.setState({
			menuOpen: false,
			hoveringGroup: null,
		});
	}

	handleOutsideClick = event => {
		const container = this.groupMenuContainer.current;
		if (!container?.contains(event.target)) {
			this.closeMenu();
		}
	}

	render() {
		const { groups, selectedGroup, disabled = false } = this.props;

		const selectedTopLevelGroup = groups
			? getTopLevelGroup(groups, selectedGroup)
			: null;

		const START_DEPTH = 1;
		const menuItems = groups
			? groups.map((group, i) => this.getMenuItem(group, i, START_DEPTH))
			: [];

		return (
			<div
				className="c6-group-menu-container"
				ref={this.groupMenuContainer}
				onMouseEnter={this.handleContainerMouseEnter}
				onMouseLeave={this.handleContainerMouseLeave}
			>
				<Button
					type="expand_more"
					title={getButtonTitle(selectedGroup, selectedTopLevelGroup, groups)}
					hoverTitle="Click to change section"
					onClick={this.openMenu}
					disabled={disabled || !groups?.length}
				/>
				<div className={`c6-group-menu-list root ${this.state.menuOpen ? "open" : ""}`}>
					{menuItems}
				</div>
			</div>
		);
	}
}

// HELPERS
function getButtonTitle(selectedGroup, selectedTopLevelGroup, groups) {
	const serviceInfo = renderServiceInfo(selectedGroup);
	const hiddenInfo = renderHiddenInfo(selectedGroup);
	const groupLineage = getGroupLineage(selectedGroup, groups);
	
	if (groupLineage) {
		return <span className="button-title">{serviceInfo}{groupLineage}{hiddenInfo}</span>;
	}

	return <span className="button-title">Select section</span>;
}

function renderServiceInfo(group) {
	if (group?.service) {
		return <span className="service-info">{group.service.displayName}</span>;
	}

	return null;
}

function renderHiddenInfo(group) {
	if (group?.status?.toLowerCase() === "hidden") {
		return <span className="hidden-info">Hidden</span>;
	}

	return null;
}
import React from 'react'
import Moment from 'moment'
// import keydown from 'react-keydown'
import debounce from 'lodash/debounce'
import { ScrollContainer } from 'react-router-scroll';

import App from '../../../components/app'
import { Info } from '../../../components/comet'
import Main from '../../../components/ui/main'
import Button from '../../../components/ui/controls/button'

import Header from './header'
import List from '../shared/list'

import Actions from '../actions'
import Store from '../store'

import { getSearchPayload, getInfo } from '../shared/utils'

const MODULE = "acq"
const ENTITY =  "license"
const ENTITYTARGET = { entity: "license", store: "licenseEpisodes" };
const PARENT_ENTITY =  "contract"

const TEXT_EMPTY = "No licenses? Try adjusing your list filters, if possible."
const TEXT_CREATE = "Create license"
const ICON_CREATE = "add"

export default class ListApp extends React.Component {

	constructor(props) {
		super(props);

		this.onChange = this.onChange.bind(this);
		// this.onFilter = this.onFilter.bind(this);
		// this.handleFilter = this.handleFilter.bind(this);
		// this.debouncedFilter = debounce(this.handleFilter, 300);
		this.handleCreateNew = this.handleCreateNew.bind(this);
		this.loadMore = this.loadMore.bind(this);

		this.scroll = null;
		this.contractId = this.props.params.id;
		this.licenseId = this.props.params.licenseId;
		this.parentProgramId = this.props.location.query.parentProgramId;

		this.state = {
			...Store.getState(),
			searchText: "",
			// unselectedFilter: false,
		};
	}

	componentDidMount() {
		Store.listen(this.onChange);
		if (this.contractId) {
			Actions.updateContractId(this.contractId);
			Actions.fetchItem(PARENT_ENTITY, this.contractId);
		}

		Actions.fetchItem(ENTITY, this.licenseId);
		Actions.fetchItems(ENTITYTARGET, {
			parentProgramId: this.parentProgramId,
			orderBy: "activeandupcomingwindowsearlieststartfirst",
			contractId: this.contractId,
		});
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		const nextParentProgramId = nextProps.location.query.parentProgramId;

		if(nextParentProgramId && nextParentProgramId !== this.parentProgramId) {
			this.licenseId = nextProps.params.licenseId;
			Actions.fetchItem(ENTITY, this.licenseId);

			this.parentProgramId = nextParentProgramId;
			Actions.fetchItems(ENTITYTARGET, {
				parentProgramId: this.parentProgramId,
				orderBy: "activeandupcomingwindowsearlieststartfirst",
				contractId: this.contractId,
			});
		}
	}

	// componentWillReceiveProps({ keydown }) {
	// 	if (keydown.event) {
	// 		console.log( keydown.event.which );
	// 		this.onSearch(keydown.event);
	// 	}
	// }

	componentWillUnmount() {
		Actions.unmount();
		Store.unlisten(this.onChange);
	}

	onChange(state) {
		this.setState(state);
	}

	// onFilter(type, e) {
	// 	if(type === "search") {
    // 		e.persist();
    // 		this.debouncedFilter(type, e);
	// 	}
	// 	else {
	// 		this.handleFilter(type, e);
	// 	}
  	// }

	// handleFilter(type, event) {
	// if(this.scroll) {
	// 	this.scroll.scrollTop = 0; // Scroll list to top to prevent automatic paging
	// }

	// 	const { name, value } = event.target;
	// 	Actions[type](value, name);
	// 	Actions.fetchItems(`${ENTITY}s`, getSearchPayload(this.state));

	// 	this.setState({
	// 		searchText: type !== "search" ? "" : value,
	// 		unselectedFilter: type !== "search" ? false : value.length > 0,
	// 	});

	// // Force lazyload to load visible images
	// window.dispatchEvent(window.customLazyEvent);

	loadMore() {
		const url = this.state[`${ENTITY}sNextPageUrl`];
		if(url) {
			Actions.pageItems(ENTITYTARGET, url);
		}
	}

	handleCreateNew() {
		const { contract = {} } = this.state.item;
		const providerId = contract.provider && contract.provider.id;
		Actions.edit({
			pathname: "/acq/licenses/create",
			search: `?contractId=${this.contractId}&providerId=${providerId}`,
			returnTo: `${this.props.location.pathname}${this.props.location.search}`,
			targetStore: this.parentProgramId ? "licenseEpisodes" : undefined,
		});
	}

	shouldUpdateScroll(prevRouterProps, currentRouterProps) {
		return currentRouterProps.location.action === "POP";
	}

	render() {
		const { isLoading, searchText: componentSearchText, filters, unselectedFilter, item, licenses, licenseEpisodes, licenseEpisodesNextPageUrl } = this.state;
		// const searchText = componentSearchText || filters.searchText; // TODO!: We should probably only have one searchText since this will make people go mad.
		const { license } = item;

		return (
			<App
				name={`c6-${MODULE}-${ENTITY}s c6-list`}
				layout="grid"
				isLoading={isLoading}>
				<Header
					title={license.program ? license.program.name : ""}
					filters={filters}
					contractId={this.contractId}
					handleCreateNew={this.handleCreateNew}
					goBackInsteadOfLink={!!licenses.length} />
				<ScrollContainer scrollKey={`c6-${MODULE}-${ENTITY}s`} shouldUpdateScroll={this.shouldUpdateScroll}>
					<Main inputRef={el => this.scroll = el}>
						<List
							parent={license}
							items={licenseEpisodes}
							isLoading={isLoading}
							filters={filters}
							textEmpty={TEXT_EMPTY}
							hasMore={!!licenseEpisodesNextPageUrl}
							loadMore={this.loadMore} />
					</Main>
				</ScrollContainer>
				<Info>{getInfo(this.props.location, this.state, ENTITYTARGET)}</Info>
			</App>
		);
	}
}
import React from 'react'

import {Filter, Left, Right } from '../../../../components/filter'

import Search from '../../../../components/ui/controls/search'
import Switch from '../../../../components/ui/controls/switch'

import { decoratorUIActions } from '../../../../core/decorators/uiActions'

import AlbumList from '../shared/list'

import Actions from '../../actions'
import Store from '../../store'

import dialogActions from './addActions'

import './dialog.css'

const DATASTORE = "albums";

@decoratorUIActions(dialogActions)
export default class AlbumDialog extends React.Component {
	constructor(props) {
		super(props);

		this.onChange = this.onChange.bind(this);
		this.handleSearch = this.handleSearch.bind(this);
		this.handleCreateAlbum = this.handleCreateAlbum.bind(this);

		this.state = {
			...Store.getState(),
			searchText: null,
			onlyMine: "true",
        };
	}

	componentDidMount() {
		Store.listen(this.onChange);

		Actions.fetchItems(DATASTORE, { orderBy: "createdDesc", onlyMine: this.state.onlyMine });
	}

	componentWillUnmount() {
        Store.unlisten(this.onChange);
    }

	onChange(state) {
        this.setState(state);
	}

	handleSearch(e) {
		const searchText = e.target.value;
		this.setState({ searchText });
	}

	handleFilter = e => {
		Actions.fetchItems(DATASTORE, { orderBy: "createdDesc", onlyMine: e.target.value });
		this.setState({ onlyMine: e.target.value });
	}

	handleCreateAlbum(name) {
		const { list } = this.state;
		Actions.createAlbumAndAddAssets(name, list.selected.items, this.props.location);
	}

	render() {
		const { list, searchText } = this.state;

		return (
			<div className="c6-dialog c6-dialog-star">
				<Filter>
					<Left>
						<h1>Add to album</h1>
						<div style={{ display: "inline-block", marginLeft: "20px" }}>
							<Switch
								name="onlyMine"
								onChange={this.handleFilter}
								states={[
									{ key: "true", text: "My albums", description: "" },
									{ key: "false", text: "All albums", description: "" },
								]}
								currentState={this.state.onlyMine}
							/>
						</div>
					</Left>
					<Right>
						<Search onChange={this.handleSearch} />
					</Right>
				</Filter>
				<AlbumList
					list={list}
					searchText={searchText}
					onCreateAlbum={this.handleCreateAlbum}
					modal={true} />
			</div>
		);
	}
};
import React from 'react'

import EventPlannerComponent from '../component'

import './checkbox.css'
import './checkboxContainer.css'

const LinkedComponents = (props) => {
	const {
		className,
		displayName,
		name,
		readOnly,
		_onChangeWithoutAddedPath,
		style,
		onChange,
		children,
		dataType,
		path,
		value,
		itemData,
		otherProps,
	} = props;

	const onComponentChange = (event, component) => {
		componentUpdatedValue(component, event.data);
		onChange(event);
	};

	const [componentLinks, setComponentLinks] = React.useState({});
	const [depenciesSetUp, setDepenciesSetUp] = React.useState(false);

	const [childrenState, setChildrenState] = React.useState(children);
	
	const onComponentChangeWithoutAddedPath = (event, component) => {
		componentUpdatedValue(component, event.data);
		_onChangeWithoutAddedPath(event);
	}

	const componentUpdatedValue = (component, value) => {
		extractUpdatedValues(component, value);
	}

	const extractUpdatedValues = (component, value) => {
		if(componentLinks[component]?.value) {
			componentLinks[component].value.forEach(link => {
				link.value = value;
				refreshComponentValues(link.component);
				refreshChildState(link.component);
			});
		}
		if(!value) {
			return;
		}
		if(Array.isArray(value)) {
			value.forEach((v, i) => {
				extractUpdatedValues(`${component}.${i}`, v);
			});
		} else if(typeof value === "object") {
			Object.entries(value).forEach(([key, v]) => {
				extractUpdatedValues(`${component}.${key}`, v);
			});
		}
	}


	const refreshChildState = (component) => {
		const newChildren = [...childrenState];
		const index = newChildren.findIndex(c => c.name === component.name);
		if(index >= 0) {
			newChildren[index] = component;
			setChildrenState(newChildren);
		}
	};

	React.useEffect(() => {
		setChildrenState(children);
	}, [value]);


	React.useEffect(() => {
		const m = componentLinks;
		extractChildDependencies(children, m);
		
		setComponentLinks(m);
		setDepenciesSetUp(true);

	}, [children]);

	const extractChildDependencies = (componentChildren, links, path) => {
		/**
		 * This function will match dependencies (${<property>}) and static parts of the string using Regex and will add all of them to the dependentProps object on the component. 
		 * Static parts will be added with a value property and dependencies will be added with a dynamic property that is also added to the componentLinks state object.
		 * When a value is updated in any childcomponent we check if any component depends on that value using the componentLinks state object and update the value of the dependentProps.
		 * If a dependentProp is updated we update the value of the component by building a new string from the dependentProps object on the element.
		 * */
		const dependencyRegex = /(\${[\w-\.]*})/g;

		componentChildren.forEach(element => {
			Object.entries(element).forEach(([key, value]) => {
				if (typeof value === "string") {
					const matches = value.split(dependencyRegex);

					if(matches.length > 1) {

						if(!element.dependentProps) {
							element.dependentProps = {};
						}

						element.dependentProps[key] = [];

						matches.forEach(match => {
							 
							if(match.startsWith("${")) {
								const tokens = match.substring(2, match.length - 1).split(".");
								const propertyMatch = tokens.pop();
								const componentMatch = tokens.join(".");
								
								const replacePath = {
									key,
									token: match,
									component: element,
									value: null
								}

								element.dependentProps[key].push(replacePath);
								
								if (!links[componentMatch]) {
									links[componentMatch] = {};
								}
								if(!links[componentMatch][propertyMatch]) {
									links[componentMatch][propertyMatch] = [];
								}
								links[componentMatch][propertyMatch].push(replacePath);

							} else {
								element.dependentProps[key].push({value: match});
							}
						});
					}
				}
			});
			if(element.value) {
				componentUpdatedValue([path, element.name].filter(f=>f).join("."), element.value);
			}
			if(element.children?.length) {
				extractChildDependencies(element.children, links, [path, element.name].filter(f=>f).join("."));
			}
		});
	}

	
	/**
	 * Refreshes the values of a component's dependent properties.
	 * @param {Object} component - The component to refresh.
	 */
	const refreshComponentValues = (component) => {
		const properties = component.dependentProps;
		if (properties) {
			Object.entries(properties).forEach(([key, value]) => {
				const newValue = value.map((v) => v.value).join("");
				component[key] = newValue;
			});
		}
	};

	return (
		<div
			key={name}
			className={className}
			style={{
				margin: "0px",
				padding: "0px",
			}}
		>
			{
				depenciesSetUp && childrenState.map(c => (
					<EventPlannerComponent
						key={c.name}
						templateItem={{
							...c,
							value: c.value ?? value?.[c.name],
						}}
						valuesUrl={c.valuesUrl}
						onChange={(event) => {onComponentChange(event, c.name)}}
						_onChangeWithoutAddedPath={(event) => {onComponentChangeWithoutAddedPath(event, c.name)}}
						path={[...path, name]}
						otherProps={otherProps}
						itemData={itemData}
						parentReadOnly={readOnly ?? parentReadOnly}
					/>
				))
			}
		</div>
	);
};

export default LinkedComponents;
import { browserHistory } from 'browserHistory'
import moment from 'moment-timezone'

import alt from '../../../../core/services/alt'

import * as SelectionsData from '../../../../apis/selections'
// import * as MetadataData from '../../../../apis/metadata'
// import * as CmoreData from '../../../../apis/cmore'
// import * as DiscoveryData from '../../../../apis/discovery'

import { handleRequestFailed } from '../../../../core/services/errorhandling'

import SelectionsActions from '../../actions'

import Const, { Regions } from '../../../../core/constants'

const MAX_SYNOPSIS_LENGTH = 180;

class Actions {

	// FILTER ACTIONS
	navNextInstance({ listId, filters }) {
		return (dispatch) => {
			dispatch(filters.date);
			const date = moment(filters.date).format(Const.DATE_TIME_FORMAT_TZ);
			SelectionsActions.fetchItems("listItems", { listId, filters: { date } }, { clearList: true });
			SelectionsActions.fetchItem("listInstances", { listId, filters: { date} });
		};
	}

	navPrevInstance({ listId, filters }) {
		return (dispatch) => {
			dispatch(filters.date);
			const date = moment(filters.date).format(Const.DATE_TIME_FORMAT_TZ);
			SelectionsActions.fetchItems("listItems", { listId, filters: { date } }, { clearList: true });
			SelectionsActions.fetchItem("listInstances", { listId, filters: { date } });
		};
	}

  	navNextPeriod({ listId, filters }) {
		return (dispatch) => {
			dispatch(filters.date);
			const date = moment(filters.date).format(Const.DATE_TIME_FORMAT_TZ);
			SelectionsActions.fetchItems("listItems", { listId, filters: { date } }, { clearList: true });
			SelectionsActions.fetchItem("listInstances", { listId, filters: { date } });
		};
	}

	navPrevPeriod({ listId, filters }) {
		return (dispatch) => {
			dispatch(filters.date);
			const date = moment(filters.date).format(Const.DATE_TIME_FORMAT_TZ);
			SelectionsActions.fetchItems("listItems", { listId, filters: { date } }, { clearList: true });
			SelectionsActions.fetchItem("listInstances", { listId, filters: { date } });
		};
	}

	navListCreated({ listId, listCreated, filters }) {
		return (dispatch) => {
			dispatch({ date: filters.date, listCreated });
			const date = moment(filters.date).format(Const.DATE_TIME_FORMAT_TZ);
			SelectionsActions.fetchItems("listItems", { listId, filters: { date } }, { clearList: true });
			SelectionsActions.fetchItem("listInstances", { listId, filters: { date } });
		};
	}

	navDate({ value, listId, filters }) {
		return (dispatch) => {
			dispatch({ date: value });
			const date = moment(value).format(Const.DATE_TIME_FORMAT_TZ);
			SelectionsActions.fetchItems("listItems", { listId, filters: { date } }, { clearList: true });
			SelectionsActions.fetchItem("listInstances", { listId, filters: { date } });
		};
	}

	removeListInstance({ listId, filters }) {
		return (dispatch) => {

			const instanceId = filters.instances.current.listInstanceId;

			SelectionsData.removeListInstance(listId, instanceId)
				.then(response => {
					const date = moment(filters.date).format(Const.DATE_TIME_FORMAT_TZ);
					SelectionsActions.fetchItems("listItems", { listId, filters: { date } }, { clearList: true });
					SelectionsActions.fetchItem("listInstances", { listId, filters: { date } });
				}, this.requestFailed);
		};
	}

	// GROUPS
	fetchGroups() {
		return (dispatch) => {
			dispatch();

			SelectionsData.fetchGroups({ returnAsTree: true, groupTypeId: 2 })
				.then(response => {
					this.updateGroups(response.items);
				}, this.requestFailed);
		};
	}

	updateGroups(groups) {
		return groups;
	}

	// LIST ITEM ACTIONS
	edit({id, pathname = `/selections/service/items/${id}/edit`, listId, returnTo}) {

		const route = {
			pathname,
			search: `?listId=${listId}`,
			state: {
				modal: true,
				returnTo
			}
		};

		browserHistory.push(route)

		return route;
	}

	// API
	// fetchListReferences(listId) {
	// 	return (dispatch) => {
	// 		dispatch();

	// 		const filters = {
	// 			sourceListId: listId
	// 		};

	// 		SelectionsData.fetchLists(filters)
	// 			.then(response => {
	// 				this.listReferencesLoaded(response.items);
	// 			}, this.requestFailed);
	// 	}
	// }

	// fetchListItems({listId, filters, refreshList = false}) {
	// 	return (dispatch) => {
	// 		dispatch(refreshList); // we dispatch an event here so we can have "loading" state.

	// 		const date = filters.date;
	// 		var payload = {
	// 			date: typeof(date.format) === "function" ? date.format(Const.DATE_FORMAT_TZ) : date
	// 		}

	// 		if(filters.instanceType == "Hourly") {
	// 			payload.date = date.format(Const.DATE_TIME_FORMAT_TZ);
	// 		} else {
	// 			payload = null;
	// 		}

	// 		SelectionsData.fetchListItems(listId, payload)
	// 			.then(response => {
	// 				this.updateItems({items: response.items, date}); // we can access other actions within our action through `this.actions`
	// 			}, this.requestFailed);
	// 	};
	// }

	// deleteListItems({ listId, filters }) {
	// 	return (dispatch) => {
	// 		dispatch();

	// 		let date = filters.date;
	// 		date = typeof(date.format) === "function" ? date.format(Const.DATE_FORMAT_TZ) : date;

	// 		SelectionsData.deleteItems(listId, date)
	// 			.then(response => {
	// 				this.fetchList({ listId, filters });
	// 				this.fetchListItems({ listId, filters });
	// 			}, this.requestFailed);
	// 	};
	// }

	// copyItems({ listId, inheritedList, serviceId }) {
	// 	return (dispatch) => {
	// 		dispatch();

	// 		SelectionsData.copyList(listId, inheritedList)
	// 			.then(response => {
	// 				this.listLoaded(response);
	// 				this.showItems({ id: listId, serviceId });
	// 			}, this.requestFailed);
	// 	};
	// }

	instancesLoaded(data) { return data }
	listLoaded(data) { return data }
	listReferencesLoaded(items) { return items }
	inheritedListLoaded(data) { return data }
	updateItems({items, date}) { return {items, date} }
	unmount() { return true }

	// // DnD
	// updateListItem({ payload, originalItem }) {
	// 	const { listId, filters, body } = payload;
	// 	return (dispatch) => {
	// 		dispatch(body); // We dispatch an event here so we can have "loading" state.

	// 		const date = body.date;
	// 		const payload = {
	// 			date: typeof(date.format) === "function" ? date.format(Const.DATE_FORMAT) : date,
	// 			...body
	// 		};

	// 		if(filters.instanceType === "Hourly") {
	// 			payload.date = date.format(Const.DATE_TIME_FORMAT_TZ);
	// 		}

	// 		SelectionsData.updateListItem(listId, payload)
	// 			.then(response => {
	// 				SelectionsActions.fetchItems("listItems", { listId, filters });
	// 				// this.fetchListItems({ listId, filters });

	// 				if(filters.instanceType === "Hourly") {
	// 					this.fetchListInstances({ listId, filters });
	// 				}
	// 			}, (error, request) => {
	// 				this.rollbackUpdateListItem(originalItem);
	// 				this.requestFailed({ error, request });
	// 			});
	// 	}
	// }
	// rollbackUpdateListItem(originalItem) {
	// 	return originalItem;
	// }

	// insertListItem(payload) {
	// 	const { listId, filters, body, droppedItem } = payload;
	// 	const { date, id, referenceId, position } = body;

	// 	return (dispatch) => {
	// 		dispatch(payload);

	// 		const discoveryId = id.split("-")[1]; // We get something like "discovery-234234" as an ID.
	// 		if(!referenceId || parseInt(referenceId, 10) === NaN || !discoveryId || parseInt(discoveryId, 10) === NaN) {
	// 			this.requestFailed({date, api, body});
	// 		}
	// 		else {

	// 			// 1) Fetch program metadata from metadata module
	// 			// const program = MetadataData.fetchProgram({ id: referenceId }).then(program => {
	// 			// 	console.log(program);
	// 			// });

	// 			// We should have program metadata directly in discovery
	// 			const { json, ...discoveryData } = droppedItem.data;
	// 			const program = JSON.parse(json);

	// 			// But we need to fetch primary assets from STAR
	// 			const payload = transformResponse(discoveryData, program);


	// 			// Add item metadata to selections api (it will handle insert vs update logic)
	// 			SelectionsData.insertItem(payload)
	// 				.then(response => {
	// 					const payload = {
	// 						id: response.id,
	// 						position,
	// 						date: typeof(date.format) === "function" ? date.format(Const.DATE_FORMAT_TZ) : date
	// 					}
	// 					if (filters.instanceType && ["hourly", "minutely"].includes(filters.instanceType.toLowerCase())){
	// 						payload.date = date.format(Const.DATE_TIME_FORMAT_TZ);
	// 					}

	// 					// Add the item to the current list and date
	// 					return SelectionsData.updateListItem(listId, payload)
	// 				}, (error, api) => {
	// 					this.rollbackInsertListItem(id);
	// 					this.requestFailed(error, api);
	// 				})
	// 				.then(response => {
						
	// 					// 5) Refresh the list
	// 					// this.fetchListItems({ listId, filters });
	// 					SelectionsActions.fetchItems("listItems", { listId, filters });
	// 					// if(filters.instanceType === "Hourly") {
	// 					// 	this.fetchListInstances({ listId, filters });
	// 					// }
	// 				}, (error, api) => {
	// 					this.rollbackInsertListItem(id);
	// 					this.requestFailed(error, api);
	// 				})
	// 				.catch((error, api) => {
	// 					console.error("Something failed! Probably not the API.");
	// 					this.rollbackInsertListItem(id);
	// 					this.requestFailed(error, api);
	// 				});

	// 			// // 1) Fetch program data for all regions
	// 			// const fetchRegions = Regions.map(({country, language}) => CmoreData.fetchProgram(referenceId, country, language));

	// 			// Promise.all(fetchRegions)
	// 			// 	.then(results => {
	// 			// 		programVersions = results;

	// 			// 		// 2) Fetch discovery data for the program so we can get some license periods and start dates
	// 			// 		//    (they differ slightly from the program API, else we could skip this step)
	// 			// 		DiscoveryData.fetchItem(discoveryId)

	// 			// 			.then(response => {
	// 			// 				const payload = transformResponse(response, programVersions);

	// 			// 				// 3) Add item metadata to selections api (it will handle insert vs update logic)
	// 			// 				return SelectionsData.insertItem(payload)
	// 			// 			}, (error, api) => {
	// 			// 				this.rollbackInsertListItem(id);
	// 			// 				this.requestFailed(error, api);
	// 			// 			})


	// 			// 			.then(response => {
	// 			// 				const payload = {
	// 			// 					id: response.id,
	// 			// 					position,
	// 			// 					date: typeof(date.format) === "function" ? date.format(Const.DATE_FORMAT) : date
	// 			// 				}
	// 			// 				if(filters.instanceType === "Hourly") {
	// 			// 					payload.date = date.format(Const.DATE_TIME_FORMAT_TZ);
	// 			// 				}

	// 			// 				// 4) Add the item to the current list and date
	// 			// 				return SelectionsData.updateListItem(listId, payload)
	// 			// 			}, (error, api) => {
	// 			// 				this.rollbackInsertListItem(id);
	// 			// 				this.requestFailed(error, api);
	// 			// 			})


	// 			// 			.then(response => {

	// 			// 				// 5) Refresh the list
	// 			// 				this.fetchListItems({ listId, filters });
	// 			// 				if(filters.instanceType === "Hourly") {
	// 			// 					this.fetchListInstances({ listId, filters });
	// 			// 				}
	// 			// 			}, (error, api) => {
	// 			// 				this.rollbackInsertListItem(id);
	// 			// 				this.requestFailed(error, api);
	// 			// 			})
	// 			// 			.catch((error, api) => {
	// 			// 				console.error("Something failed! Probably not the API.");
	// 			// 				this.rollbackInsertListItem(id);
	// 			// 				this.requestFailed(error, api);
	// 			// 			});


	// 			// 	}, (error, api) => {
	// 			// 		this.rollbackInsertListItem(id);
	// 			// 		this.requestFailed(error, api);
	// 			// 	})
	// 			// 	.catch((error, api) => {
	// 			// 		console.error("Something failed! Probably not the API.");
	// 			// 		this.rollbackInsertListItem(id);
	// 			// 		this.requestFailed(error, api);
	// 			// 	});
	// 		}
	// 	}
	// }
	// rollbackInsertListItem(id) {
	// 	return id;
	// }

	requestFailed(error) {
		handleRequestFailed(error);
		return true;
	}
}

export default alt.createActions(Actions);

// Helpers
function transformResponse(discoveryData, program) {
	const {
		reference,
		name,
		type: mainType,
		subType,
		activeDate,
		validFrom,
		validUntil,
		provider,
	} = discoveryData;

	const { programVersions } = program;

	// const type = contentType && contentType.name === "LiveSport" ? "LiveSport" : "BroadcastProgram"; // JAS
	const type = mainType === "LiveSport" ? "LiveSport" : "Program"; // JAS
	const assets = getPrimaryAssets(programVersions[0].assets);
	const localizations = getLocalizations(programVersions, type);
	// const formattedDisplayName = formatDisplayName(displayName, type, date, EpisodeTitle, ComputedEpisodeTitle);
	const formattedDisplayName = formatDisplayName(name, type, activeDate);
	// const period = getDatePeriod(type, program, programVersions[0]);
	const period = getDatePeriod(validFrom, validUntil);

	let payload = {
		reference,
		name,
		displayName: formattedDisplayName,
		type,
		inputSource: "Batch",
		localizations,
		provider,
		...period,
		// start: activeFrom,
		// end: activeUntil,
	}

	if(assets.length) {
		payload.assets = assets;
	}

	return payload;
}
// function transformResponse(discoveryProgram, programVersions) {
// 	const {
// 		name,
// 		displayName,
// 		date,
// 		contentType,
// 	} = discoveryProgram;

// 	const {
// 		Id: referenceId,
// 		Title,
// 		OriginalTitle,
// 		EpisodeTitle = "",
// 		ComputedEpisodeTitle,
// 		Resources,
// 		ComputedSeasonTitle,
// 		VodStart,
// 		VodEnd
// 	} = programVersions[0];

// 	const type = contentType && contentType.name === "LiveSport" ? "LiveSport" : "BroadcastProgram";
// 	const assets = getPrimaryAssets(Resources);
// 	const localizations = getLocalizations(programVersions, type);
// 	const formattedDisplayName = formatDisplayName(displayName, type, date, EpisodeTitle, ComputedEpisodeTitle);
// 	const period = getDatePeriod(type, discoveryProgram, programVersions[0]);

// 	let payload = {
// 		referenceId,
// 		name,
// 		displayName: formattedDisplayName,
// 		type,
// 		inputSource: "Batch",
// 		localizations,
// 		...period,
// 		// start: activeFrom,
// 		// end: activeUntil,
// 	}

// 	if(assets.length) {
// 		payload.assets = assets;
// 	}

// 	return payload;
// }

// TODO: Should add all assets, not just main image
function getPrimaryAssets(resources) {
	let assets = [];
	const mainImage = resources.find(resource => resource.category === "Main"); // Also check for type?, but type is number instead of string
	if (mainImage) {
		assets.push({ guid: mainImage.id, type: "Image", category: "Main" });
	}

	// const primaryVideo = resources.filter(resource => resource.Category === "Clips" && resource.Index === "1"); // 1 = 15 second clip. Also, 1 is a string in the program API
	// if(primaryVideo.length) {
	// 	assets.push({ guid: primaryVideo[0].Id, type: "Video" });
	// }

	return assets;
}

function getLocalizations(programVersions, type) {
	return programVersions
		.filter(v => Regions.find(r => r.language === v.language))
		.map(v => {
			if (v.language === "nb") v.language = "no"; // HACK: Change norsk bokmål to norwegian because we use norsk bokmål in C70??
			const region = Regions.find(r => r.language === v.language);
			const description = getMaxLengthSynopsis(v.Synopsis, MAX_SYNOPSIS_LENGTH);

			return {
				title: ["LiveSport", "SportClipHighlights", "SportClipSummary"].includes(type) ? v.epgTitle || v.title : v.title,
				subtitle: "",
				version: { id: region.id, name: region.languageDisplayName },
				description: {
					short: description,
				}
			};
		});
}

function getMaxLengthSynopsis(synopsis, maxLength) {
	if(!synopsis) {
		return "";
	}

	const { short = "", brief = "", medium = "", long = "" } = synopsis;
	if(long.length <= maxLength) {
		return long;
	}
	else if(medium.length <= maxLength) {
		return medium;
	}
	else if(brief.length <= maxLength) {
		return brief;
	}
	else if(short.length <= maxLength) {
		return short;
	}
	else {
		return "";
	}
}

function formatDisplayName(displayName, type, date) {
	return ["LiveSport", "SportClipHighlights", "SportClipSummary"].includes(type) && date // TODO!: Get livesport from JAS
		? `${displayName} (${moment(date).format(Const.SHORT_TIME_FORMAT)})`
		: displayName.trim();
		// : `${displayName} ${computedEpisodeTitle || episodeTitle}`.trim();
}

function getDatePeriod(validFrom, validUntil) {
	let start = "", end = ""; // We need to send empty string to not have the API return year one (like when we send null)
	const startMoment = moment(validFrom);
	if(startMoment.year() > 1) {
		start = startMoment.format(Const.DATE_TIME_FORMAT_TZ);
		end = moment(validUntil).format(Const.DATE_TIME_FORMAT_TZ);
	}

	return { start, end };
}

// // Use the Discovery API period for sports, otherwise just the Vod-period from the Program API (since we're working with VOD-titles)
// function getDatePeriod(type, discoveryProgram, programVersion) {
// 	let start = "", end = ""; // We need to send empty string to not have the API return year one (like when we send null)
// 	if(["LiveSport", "SportClipHighlights", "SportClipSummary"].includes(type)) {
// 		start = discoveryProgram.activeFrom;
// 		end = discoveryProgram.activeUntil;
// 	}
// 	else {
// 		const startMoment = moment(programVersion.VodStart);
// 		if(startMoment.year() > 1) {
// 			start = startMoment.format(Const.DATE_TIME_FORMAT_TZ);
// 			end = moment(programVersion.VodEnd).format(Const.DATE_TIME_FORMAT_TZ);
// 		}
// 	}

// 	return { start, end };
// }
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import { Content } from '../../../../components/list/contentItem'
import SortableContentItem from '../../../../components/list/contentItemSortable'
import Label from '../../../../components/ui/label'
import Preview from '../../../../components/assets/preview'

import Const from '../../../../core/constants'
import { getPeriod } from '../../shared/utils'

import styles from '../../../../components/listItems/standard.css'

const enablePrepublishForListCategories = ["regular", "showcase"];

export default class ItemContent extends Component {

	static propTypes = {
		// title: PropTypes.string.isRequired,
		// assets: PropTypes.array,
		// description: PropTypes.object,
		// className: PropTypes.string,
		// label: PropTypes.object
	}

	constructor(props, context) {
		super(props);

	}

	render() {
		const {
			item: itemData, // NEW
			listCategory,

			// id,
			// title,
			// description,
			// autoGenerated,
			// active,
			// itemAssets,
			// schedule,
			// category,
			// assets,
			// itemsLoaded,

			// version,
			// audience,
			// selectedGroup,

			// DND
			isPlaceholder,
			findItem,
			moveItem,
			handleOutsideDrop,
			handleItemManipulation,
			onDragStateChanged,
		} = this.props;

		const {
			displayName,
			name,
			reference,
			// description,
			// autoGenerated,
			// active,
			// itemAssets,
			// schedule,
			// category,
			assets,
			// itemsLoaded,
			// version,
			// audience,
			// selectedGroup,
			start,
			end,
			mediaType,
			position,
		} = itemData;

		const { period, isExpired, isUpcoming } = getPeriod({ from: start, until: end, prepublishDate: position?.activeDate });

		let status = null;
		if (isExpired) {
			status = <Label status="expired">Expired</Label>;
		} else if (isUpcoming) {
			status = <Label status="disabled">Upcoming</Label>;
		}

		let prepublish = null;
		if (enablePrepublishForListCategories.includes(listCategory?.name) && start && position?.activeDate) {
			const text = moment(position.activeDate).isAfter(moment(start))
				? "Delayed publish"
				: "Prepublish";
			prepublish = (
				<Label status="upcoming">
					{text}
				</Label>
			);
		}

		const tooltip = `${name ?? ""} (${reference})`;

		return (
			<SortableContentItem
				data={itemData}
				handleType="icon"

				// DND
				isPlaceholder={isPlaceholder}
				findItem={findItem}
				moveItem={moveItem}
				onItemDropOutside={handleOutsideDrop}
				insertCard={handleItemManipulation}
				onDragStateChanged={onDragStateChanged}
			>
				<Preview imageGuid={assets && assets[0] && assets[0].guid} />
				<Content>
					<h1 title={tooltip}>{displayName}</h1>
					<p>
						{status}
						{prepublish}
						{mediaType === "regular" && <Label status="undefined">Original</Label>}
						{mediaType === "dubbed" && <Label status="headsup">Dubbed</Label>}
					</p>
				</Content>
				<Content>
					{period}
				</Content>
			</SortableContentItem>
		);
  	}
}
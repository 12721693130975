import React from 'react'

import ContentItem, { Content } from '../../../../components/list/contentItem'

import '../../../../components/listItems/standard.css'

const SeriesItem = (props) => {

	const {
		title,
		name,
		type,
	} = props;

	return (
		<ContentItem data={props}>
			<Content>
				<h1>{title ?? name}</h1>
				<p>{type}</p>
			</Content>
		</ContentItem>
	);
};

export default SeriesItem;
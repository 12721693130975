import React from 'react'

import Source from './source'
import itemActions from './itemActions'

import * as API from '../../../apis/reviews'
import useListState from '../../../core/hooks/useList'
import GenericListApp from '../../../components/genericApp/listApp'
import { decoratorUIActions } from '../../../core/decorators/uiActions'

import './sources.css'
import useReviewsLanguages from '../../../core/queries/reviews/useReviewsLanguages'

const MODULE = "reviews"
const DATASTORE = "sources"
const TEXT_HEADING = "Sources"
const TEXT_EMPTY = "No sources? It's about time to create some then!"
const TEXT_CREATE = "Create source"
const ICON_CREATE = "add"

function ListApp(props) {

	const { data: languages } = useReviewsLanguages({
		filter: { orderBy: "name", pageSize: 1000, onlyLanguagesWithSources: true },
	});

	const filterConfig = {
		searchText: {
			type: "search",
			alwaysVisible: true,
		},
		language: {
			type: "dropdown",
			title: "Language",
			options: [
				{ key: null, text: "Any" },
				...languages?.items?.map(l => ({
					key: l.id,
					text: l.name,
				})) ?? [],
			],
		},
		enabled: {
			type: "switch",
			title: "Status",
			options: [
				{ key: "", text: "Any" },
				{ key: "true", text: "Active" },
				{ key: "false", text: "Inactive" },
			],
		},
		missing: {
			type: "pick",
			title: "Missing",
			options: [
				{ key: "hasLogoImage", text: "Logo" },
				{ key: "hasScoreImage", text: "Score image" },
			],
		},
		orderBy: {
			type: "switch",
			title: "Order",
			options: [
				{ key: "name", text: "Alphabetical" },
				{ key: "rank", text: "Priority" },
			],
		},
		scrapingEnabled: {
			type: "switch",
			title: "Scraping",
			options: [
				{ key: "", text: "Any status" },
				{ key: "true", text: "Enabled" },
				{ key: "false", text: "Disabled" },
			]
		},
	};

	const FILTER_DEFAULTS = {
		searchText: "",
		language: null,
		enabled: "",
		missing: "",
		orderBy: "name",
		scrapingEnabled: "",
	};

	const listState = useListState({
		listKey: DATASTORE,
		fetchData: API.fetchSources,
		filterDefaults: FILTER_DEFAULTS,
		transformFilters: transformFilters,
	});

	return (
		<GenericListApp
			{...props}
			module={MODULE}
			datastore={DATASTORE}
			state={listState}
			textHeading={TEXT_HEADING}
			textEmpty={TEXT_EMPTY}
			textCreate={TEXT_CREATE}
			iconCreate={ICON_CREATE}
			filterConfig={filterConfig}
			collapseFiltersDefault={false}
			showInfoBar={true}
		>
			<Source />
		</GenericListApp>
	);
}

@decoratorUIActions(itemActions)
export default class SourcesApp extends React.Component {
	render() {
		return (
			<ListApp
				{...this.props}
			/>
		);
	}
}

function transformFilters(filters) {
	const transformed = { ...filters };

	if (filters.missing?.length) {
		filters.missing.split(",").forEach(f => transformed[f] = false);
	}
	delete transformed.missing;

	return transformed;
}
import React from 'react'

import Item from './item'
// import itemActions from './listItemActions'

import * as API from '../../../../apis/sport'
import useList from '../../../../core/hooks/useList'
import GenericListApp from '../../../../components/genericApp/listApp'
import useEditorNavigation from '../../../../core/hooks/useEditorNavigation'
// import { decoratorUIActions } from '../../../../core/decorators/uiActions'

const MODULE = "eventplanner";
const DATASTORE = "sports";
const TEXT_HEADING = "Sports";
const TEXT_BACK_BUTTON = "Administration";
const TEXT_EMPTY = "No sports? It's about time to create some then!";
const TEXT_CREATE = "Create sport";
const ICON_CREATE = "add";
const PATHNAME_CREATE = "/eventplanner/admin/sports/create";

const FILTER_DEFAULTS = {
	searchText: "",
	orderBy: "unspecified-first-then-by-title",
};

function ListApp(props) {
	const filterConfig = {
		searchText: {
			type: "search",
		},
	};

	const listState = useList({
		listKey: DATASTORE,
		fetchData: API.fetchGenres,
		filterDefaults: FILTER_DEFAULTS,
	});

	useEditorNavigation(listState);

	return (
		<GenericListApp
			{...props}
			module={MODULE}
			datastore={DATASTORE}
			state={listState}
			textHeading={TEXT_HEADING}
			textBackButton={TEXT_BACK_BUTTON}
			textEmpty={TEXT_EMPTY}
			textCreate={TEXT_CREATE}
			iconCreate={ICON_CREATE}
			filterConfig={filterConfig}
			pathnameCreate={PATHNAME_CREATE}
			collapseFiltersDefault={false}
			showInfoBar={true}
		>
			<Item />
		</GenericListApp>
	);
}

// @decoratorUIActions(itemActions)
export default class SportsApp extends React.Component {
	render() {
		return (
			<ListApp
				{...this.props}
			/>
		);
	}
}
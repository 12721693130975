import React from 'react'

import Item from '../../../../components/cms/item'
import Modals from '../../modals/modals'

import * as CMSAPI from '../../../../apis/cms'
import useList from '../../../../core/hooks/useList'
import GenericListApp from '../../../../components/genericApp/listApp'
import { ItemActionsContext, useItemActionsState } from '../../../../components/cms/itemActionsContext'

import '../../events/app.css'
import useEditorNavigation from '../../../../core/hooks/useEditorNavigation'
import useCMSTemplate from '../../../../core/queries/cms/useCMSTemplate'
import useCMSSeries from '../../../../core/queries/cms/useCMSSeries'

const MODULE = "eventplanner";
const DATASTORE = "seasons";
const TEXT_HEADING = "Seasons";
const TEXT_BACK_BUTTON = "Administration";
const TEXT_EMPTY = "No seasons? It's about time to create some then!";
const TEXT_CREATE = "Create event";
const ICON_CREATE = "add";
const PATHNAME_CREATE = null;//"eventplanner/cms-events/create";

function ListApp(props) {
	const _entity = `seasonslist`;
	const template = useCMSTemplate(_entity, MODULE);

	const seriesIdFromQuery = props.location?.query?.seriesId?.length >= 0 ? props.location.query.seriesId : null;
	const { data: series } = useCMSSeries(seriesIdFromQuery);
	
	const seasonIdFromQuery = props.location?.query?.id?.length >= 0 ? props.location.query.id : null;

	const filterConfig = {
		searchText: {
			type: "search",
		},
	};

	const FILTER_DEFAULTS = {
		_entity,
		pageSize: 30,
		searchText: "",
		"series.id": seriesIdFromQuery,
		id: seasonIdFromQuery,
	};

	const listState = useList({
		listKey: DATASTORE,
		fetchData: CMSAPI.fetchCMSEntities,
		fetchSingle: CMSAPI.fetchCMSEntity,
		patchData: CMSAPI.updateCMSEntity,
		runAction: CMSAPI.runAction,
		entity: _entity,
		updateDataExtraParams: { _entity },
		filterDefaults: FILTER_DEFAULTS,
	});

	useEditorNavigation(listState);

	let season;
	if (seasonIdFromQuery) {
		season = listState.data?.pages[0]?.items?.[0];
	}

	let heading = TEXT_HEADING;
	if (series) {
		heading = `${TEXT_HEADING} in series "${series.title ?? series.name}"`;
	} else if (season) {
		heading = `Showing season "${season.title ?? season.name}" in series "${season.info?.generic?.series?.name}"`;
	}

	const [modalData, setModalData] = React.useState(null);
	const closeModal = React.useCallback(
		() => setModalData(null),
		[setModalData]
	);

	const actions = useItemActionsState({
		extraActions: [],
		_entity,
		refetch: listState.refetch,
		setModalData,
		collection: template?.data?.collection,
	});
	
	return (
		<ItemActionsContext.Provider value={actions}>
			<GenericListApp
				{...props}
				module={MODULE}
				datastore={DATASTORE}
				state={listState}
				textHeading={heading}
				textBackButton={TEXT_BACK_BUTTON}
				textEmpty={TEXT_EMPTY}
				textCreate={TEXT_CREATE}
				iconCreate={ICON_CREATE}
				filterConfig={series || season ? {} : filterConfig}
				pathnameCreate={PATHNAME_CREATE}
				collapseFiltersDefault={false}
				padding={false}
				virtualize={true}
			>
				<Item
					template={template.data}
					onChange={listState.onChange}
					closeModal={closeModal}
				/>
			</GenericListApp>
			<Modals
				modalData={modalData}
				onClose={closeModal}
			/>
		</ItemActionsContext.Provider>
	);
}

export default class SeasonsApp extends React.Component {
	render() {
		return (
			<ListApp
				{...this.props}
			/>
		);
	}
}

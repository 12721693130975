import React from 'react'
import PropTypes from 'prop-types'
import { Link, withRouter } from 'react-router'
import { browserHistory } from 'browserHistory'
// import uniqBy from 'lodash/uniqBy'
import moment from 'moment-timezone'
import Flatpickr from 'react-flatpickr'

import { Filter, Left, Right } from '../../../../components/filter'
import Button from '../../../../components/ui/controls/button'
import DateTimePicker from '../../../../components/ui/controls/pickers/datetimepicker'
import LinksToSectionsContainingList from '../../shared/linksToSectionsContainingList'

import { getReturnTo } from '../../../../core'
import Actions from './actions'

const FLATPICKR_FORMAT = "ddd D MMM YYYY HH:mm";

@withRouter
export default class ListFilter extends React.Component {

	static propTypes = {
		title: PropTypes.oneOfType([
			PropTypes.object,
			PropTypes.string,
		]),
		currentInstancePeriodInfo: PropTypes.string,
		onDeleteItems: PropTypes.func,
		onCreateNew: PropTypes.func,
		readyToLoadFilters: PropTypes.bool,
		listTimezone: PropTypes.string,
	}

	constructor(props) {
		super(props);

		this.listId = this.props.params.id;
	}

	handleNavPrevInstance = () => {
		Actions.navPrevInstance({
			listId: this.listId,
			filters: this.props.filters,
		});
	}

	handleNavNextInstance = () => {
		Actions.navNextInstance({
			listId: this.listId,
			filters: this.props.filters,
		});
	}

	handleRemoveListInstance = () => {
		Actions.removeListInstance({
			listId: this.listId,
			filters: this.props.filters,
		});
	}

	handleNavPrevPeriod = () => {
		const date = moment(this.props.filters.date);
		const listCreatedMoment = moment(this.props.listCreated);
		const navCreatedInsteadOfPrevPeriod = listCreatedMoment.isBetween(date.clone().subtract(1, "days"), date);
		if (navCreatedInsteadOfPrevPeriod) {
			Actions.navListCreated({
				listId: this.listId,
				listCreated: listCreatedMoment,
				filters: this.props.filters,
			});
		} else {
			Actions.navPrevPeriod({
				listId: this.listId,
				filters: this.props.filters,
			});
		}
	}

	handleNavNextPeriod = () => {
		Actions.navNextPeriod({
			listId: this.listId,
			filters: this.props.filters,
		});
	}

	handleDateChange = (dateObj, value) => {
		const parsedValue = moment(value, FLATPICKR_FORMAT).format();
		Actions.navDate({
			value: parsedValue,
			listId: this.listId,
			filters: this.props.filters,
		});
	}

	render() {
		const {
			filters,
			onDeleteItems,
			onCreateNew,
			hasNavigation,
			location,
			routes,
			params,
			title = null,

			groups,
			section,
			sectionsContainingList,
		} = this.props;

		const pressBackButton = this.getPressBackButton(location, routes, params);

		return (
			<Filter>
				<Left>
					<h1>
						{pressBackButton}
						{pressBackButton ? "/ " : ""}
						{title}
					</h1>
					<LinksToSectionsContainingList
						currentSection={section}
						sectionsContainingList={sectionsContainingList}
						allSections={groups}
					/>
					{hasNavigation && this.renderListNav()}
				</Left>
				<Right>
					{onCreateNew && (
						<Button
							type="art_track"
							title="Item"
							onClick={onCreateNew}
						/>
					)}

					{hasNavigation && (
						<Button
							type="delete"
							title="Remove instance"
							onClick={this.handleRemoveListInstance}
							disabled={
								!filters.instances?.current
								|| !filters.instances?.previous
								|| !isFutureInstance(filters.instances?.current)
							}
							confirm="Remove this instance?"
							dangerous={true}
						/>
					)}
					{/*<Button
						type="delete"
						title="Clear list"
						confirm="Clear this list?"
						dangerous={true}
						onClick={onDeleteItems} />*/}
				</Right>
			</Filter>
		);
	}

	getPressBackButton = (location, routes, params) => {
		const returnTo = getReturnTo(location, routes, params);
		// if (!returnTo || returnTo === "/") {
		if (!returnTo?.includes("/selections/press")) {
			return null;
		}

		function handleClick(e) {
			e.preventDefault();
			browserHistory.replace(returnTo);

			// if(this.props.goBackInsteadOfLink) {
			// 	e.preventDefault();
			// 	browserHistory.goBack();
			// }
		}

		return (
			<Link to={"/selections/press"} onClick={handleClick}>
				Press site administration
			</Link>
		);
	}

	renderListNav = () => {
		const { filters, onDeleteItems, onCreateNew, todayButtonTitle, listCreated } = this.props;

		const filtersDate = moment(filters.date);
		const listCreatedMoment = moment(listCreated);
		const disableStepBack = filtersDate.isSameOrBefore(listCreated, "hour");

		const offset = filters.listTimezone ? moment().tz(filters.listTimezone).utcOffset() : 0;
		filtersDate.utcOffset(offset);

		let minDate = filters.instances?.current?.activeFrom
			? moment(filters.instances?.current?.activeFrom)
			: listCreatedMoment;
		minDate = moment.min(minDate, moment());

		let value = filtersDate.format();
		if (filtersDate.isSame(minDate)) {
			// HACK!: If current value is same as minDate, Flatpickr will clear the input.
			// So this is a workaround that adds 1 second to current value (which is not visible to the user)
			value = filtersDate.clone().add(1, "second").format();
		}

		return (
			<div className="filter-buttons-wrapper">
				<Button
					type="first_page"
					title=""
					onClick={this.handleNavPrevInstance}
					disabled={!filters.instances?.previous}
				/>
				<Button
					type="prev"
					title=""
					onClick={this.handleNavPrevPeriod}
					disabled={disableStepBack}
				/>

				<Flatpickr
					options={{
						altInput: true,
						altFormat: "D j M H:i",
						locale: {
							firstDayOfWeek: 1
						},
						enableTime: true,
						time_24hr: true,
						minDate: minDate?.format(),
						// parseDate & formatDate will handle timezones since flatpickr does not
						parseDate: (dateString, format) => {
							const timezonedDate = moment(dateString);
							timezonedDate.utcOffset(offset);

							const date = new Date(
								timezonedDate.year(),
								timezonedDate.month(),
								timezonedDate.date(),
								timezonedDate.hour(),
								timezonedDate.minute(),
								timezonedDate.second()
							);

							return date;
						},
						// parseDate & formatDate will handle timezones since flatpickr does not
						formatDate: (date, format) => {
							const m = moment([
								date.getFullYear(),
								date.getMonth(),
								date.getDate(),
								date.getHours(),
								date.getMinutes(),
								date.getSeconds()
							]);
							return m.format(FLATPICKR_FORMAT);
						},
					}}
					value={value}
					onChange={this.handleDateChange}
				/>

				<Button
					type="next"
					title=""
					onClick={this.handleNavNextPeriod}
				/>
				<Button
					type="last_page"
					title=""
					onClick={this.handleNavNextInstance}
					disabled={!filters.instances?.next}
				/>
				{renderCurrentInstancePeriod(filters)}
			</div>
		);
	}
}

// HELPERS
const INSTANCE_PERIOD_FORMAT = "ddd D MMM HH:mm";
function renderCurrentInstancePeriod(filters) {
	const { instances, listTimezone } = filters;
	const { current } = instances;

	const timezoneText = listTimezone
		? `${listTimezone}`
		: "Local time";
	const timezoneDescription = listTimezone
		? `Timestamps seen here are displayed in the ${listTimezone} timezone.`
		: "Timestamps seen here are displayed in your local timezone.";

	const activeFromText = current?.activeFrom ? formatDate(current.activeFrom, listTimezone, INSTANCE_PERIOD_FORMAT) : "";
	const activeUntilText = current?.activeFrom && current?.activeUntil ? formatDate(current.activeUntil, listTimezone, INSTANCE_PERIOD_FORMAT) : "";
	return (
		<div className="current-instance-info" title={timezoneDescription}>
			<p>
				{activeFromText}
				{activeUntilText && ` - ${activeUntilText}`}
				{activeFromText && !activeUntilText && " -> ∞"}
			</p>
			<p className="timezone-description">{timezoneText}</p>
		</div>
	);
}

function formatDate(date, timezone, format) {
	return timezone
		? moment(date).tz(timezone).format(format)
		: moment(date).format(format);
}

function isFutureInstance(instance) {
	const now = moment();
	const activeFrom = instance?.activeFrom;
	return activeFrom && moment(activeFrom).isAfter(now);
}
import React from 'react'
import PropTypes from 'prop-types'

import ContentItem, { Content } from '../../../../components/list/contentItem'
import Label from '../../../../components/ui/label'

import styles from '../../../../components/listItems/standard.css'

const ServiceItem = (props) => {
	const {
		itemData,
	} = props;
	
	const {
		id,
		displayName,
		name,
		operator,
		status,
	} = itemData;

	return (
		<ContentItem data={props}>
			<Content>
				<h1>{displayName ?? name}</h1>
				{/* <p>{operator?.name ?? "<No operator>"}</p> */}
				{status !== "Enabled" && <Label status="inactive">Disabled</Label>}
			</Content>
		</ContentItem>
	);
};

export default ServiceItem;
import { browserHistory } from 'browserHistory'
import moment from 'moment'

import alt from '../../core/services/alt'
import * as SchedulesData from '../../apis/schedules'
import { handleRequestFailed } from '../../core/services/errorhandling'

let fetchInterval = null;
class Actions {
	// *** Changes ***
	fetchSchedules(payload) {
		return dispatch => {
			dispatch(payload);
			const requestTime = Date.now();

			SchedulesData.fetchSchedules(payload)
				.then(response => {
					const { pageIndex, numberOfItems, items, links } = response;
					const appendToExistingItems = pageIndex > 0;

					const nextPageUrl = getNextPageUrl(links);
					this.schedulesUpdated(
						items,
						appendToExistingItems,
						numberOfItems,
						nextPageUrl,
						requestTime,
					);
				}, this.requestFailed);
		};
	}

	pageSchedules(url) {
		return dispatch => {
			dispatch(url);
			SchedulesData.fetchUrl(url)
				.then(response => {
					const { pageIndex, numberOfItems, items, links } = response;
					const appendToExistingItems = pageIndex > 0;

					const nextPageUrl = getNextPageUrl(links);
					this.schedulesUpdated(items, appendToExistingItems, numberOfItems, nextPageUrl);
				}, this.requestFailed);
		};
	}

	fetchSchedule(id, failedApprove) {
		return dispatch => {
			dispatch(id);
			SchedulesData.fetchSchedule(id)
				.then(response => {
					this.scheduleUpdated([response], failedApprove);
				}, this.requestFailed);
		};
	}

	approveSchedule(ignore, { id, major, broadcasts }) {
		return dispatch => {
			dispatch();

			const payload = {
				id,
				major,
				broadcasts: ignore ? [] : getSelectedBroadcasts(broadcasts),
			};
			SchedulesData.approveSchedule(payload)
				.then(response => {
					this.scheduleUpdated([response]);
				}, (error, api) => {
					if (error.exceptionMessage.includes("has been updated")) {
						this.fetchSchedule(payload.id, true);
					} else {
						this.requestFailed(error, api);
					}
				});
		};
	}

	approveMany(ignore, scheduleList) {
		return dispatch => {
			dispatch();

			const payload = scheduleList.map(schedule => ({
				...schedule,
				broadcasts: ignore ? [] : getSelectedBroadcasts(schedule.broadcasts),
			}));
			SchedulesData.approveMany(payload)
				.then(response => {
					this.scheduleUpdated(response);
				}, (error, api) => {
					if (error.exceptionMessage.includes("has been updated")) {
						const rangePayload = {
							channel: scheduleList[0].channel.reference,
							from: scheduleList[0].day,
							until: scheduleList[scheduleList.length - 1].day,
						};
						this.fetchRange(rangePayload, true);
					} else {
						this.requestFailed(error, api);
					}
				});
		};
	}

	scheduleUpdated(data, failed) {
		return {
			data,
			approveFailed: failed,
		};
	}

  	schedulesUpdated(items, appendToExistingItems, numberOfItems, nextPageUrl, requestTime) {
		return {
			items,
			appendToExistingItems,
			numberOfItems,
			nextPageUrl,
			requestTime,
		};
	}

	showSchedule({ id }) {
		browserHistory.push(`/schedules/changes/${id}`);
		return id;
	}

	// Days is an array containing the days of the week
	fetchFromLouise(dates, channelName, days) {
		const allDaysInRange = days.includes(7); // 7 = "All" om the day picker, since 0=Sunday, 1=Monday, ... , 6= Saturday
		
		return dispatch => {
			dispatch();
			const payload = {
				from: allDaysInRange ? moment(dates[0]).format("YYYY-MM-DD") : null,
				until: allDaysInRange ? moment(dates[1]).format("YYYY-MM-DD") : null,
				days: !allDaysInRange ? getAllMatchingDates(days, dates[0], dates[1]) : null,
				channel: channelName,
			};

			SchedulesData.updateSchedule(payload)
				.then(response => {
					browserHistory.push({
						pathname: allDaysInRange ? `/schedules/changes/${channelName}/${payload.from}/${payload.until}` : `/schedules/changes/${channelName}/${payload.days}`
					});
				}, this.requestFailed);
		}
	}

	fetchRange(payload, failedApprove) {
		return dispatch => {
			dispatch();

			let errorCount = 0;
			const maxErrors = 5;

			const onError = error => {
				errorCount += 1;

				if (errorCount === maxErrors) {
					clearInterval(fetchInterval);
					this.requestFailed(error);	
				}
			};

			const fetchFunc = () => {
				SchedulesData.fetchSchedules({ include: "broadcasts", ...payload })
					.then(response => {
						const updateInProgress = response.items.length && response.items.some(schedule => schedule.status === "UpdateRequested");
						if (!updateInProgress) {
							clearInterval(fetchInterval);
						}

						this.scheduleUpdated(response.items, failedApprove);
						errorCount = 0;
					}, onError)
					.catch(onError);
			};

			fetchFunc();
			if (!failedApprove) {
				fetchInterval = setInterval(() => {
					fetchFunc();
				}, 10 * 1000);
			}
		}
	}

	selectItem(id) {
		return id;
	}
	selectAll() {
		return true;
	}

	// *** Channels ****
	fetchChannels() {
		return dispatch => {
			dispatch();

			SchedulesData.fetchChannels()
				.then(response => {
					const { items } = response;
					this.channelsUpdated(items);
				}, this.requestFailed);
			};
	}

	channelsUpdated(items) {
		return items
			.filter(item => item.provider?.toLowerCase() === "louise")
			.map(item => ({ // Transform channels to fit the channel switch
				key: item.reference,
				id: item.id,
				text: item.name,
			}));
	}

	// *** Filters ***
	setChannelFilter(channel) {
		return { channel };
	}

	navDate(selectedDates) {
		return selectedDates;
	}

	setDays(selectedDays) {
		return selectedDays;
	}

	// *** Misc ***
	requestFailed(error) {
		handleRequestFailed(error);
		return true;
	}

	unmountSchedule() {
		clearInterval(fetchInterval);
		return true;
	}
}

export default alt.createActions(Actions);

// Helpers
function getNextPageUrl(links = []) {
	const nextLink = links.find(l => l.rel === "next" || l.Rel === "next");

	return nextLink
		? nextLink.href || nextLink.Href
		: null;
}

const getSelectedBroadcasts = broadcasts => broadcasts
	.filter(broadcast => broadcast.selected)
	.map(broadcast => ({ id: broadcast.id }));

function getAllMatchingDates(daysOfTheWeek, from, until) {
	const current = moment(from).startOf('day');
	const end = moment(until).startOf('day');

	const dates = [];
	while (current.diff(end) <= 0) {
		if (daysOfTheWeek.includes(current.day())) {
			dates.push(current.clone().format("YYYY-MM-DD"));
		}
		current.add(1, 'days')
    }
	return dates.join(",");
}
import React from 'react'
import throttle from 'lodash/throttle'
import { browserHistory } from 'browserHistory'

import { CometLogo } from '../../components/comet'
import { isLoggedIn } from '../services/auth'
import { useLocalStorage } from '../hooks/useStorage'
import { getModuleStructure, waitForModules } from '../modules'

import appConfig from 'config'
import './sideNavigation.css'

const initialModuleStructure = getModuleStructure();

const SideNavigation = (props) => {
    const {
        user,
    } = props;

    const ref = React.useRef(null);
    const [pinned, setPinned] = useLocalStorage("c6-side-nav-pinned", false);
    const [expand, setExpand] = React.useState(pinned);

    const [moduleStructure, setModuleStructure] = React.useState(initialModuleStructure);
    const loggedIn = isLoggedIn();

    React.useEffect(
        // You can add a initial setup to modules that returns a promise, this function will wait until all modules with initialSetup are done and then update the nav structure
        async () => {
            await waitForModules();
            setModuleStructure(getModuleStructure());
        },
        [loggedIn]
    );

    const currentVersion = document.querySelector("html").getAttribute("data-version");
    const version = currentVersion !== "#version#"
        ? `v${currentVersion}`
        : "local dev server";

    useOpenAndCloseOnMouseOver(ref, expand, setExpand, pinned);

    const onPinExpandClick = React.useCallback(
        (e) => {
            e.stopPropagation();
            if (pinned) {
                setPinned(false);
                setExpand(false);
            } else if (expand) {
                setPinned(true);
            }
        },
        [pinned, expand, setPinned, setExpand]
    );

    const onHomeClick = React.useCallback(
        (e) => {
            e.preventDefault();
            e.stopPropagation();
            if (window.location.pathname !== "/") {
                browserHistory.push({ pathname: "/" });
            }
        },
        []
    );

    const pinExpandButtonIcon = pinned ? "icon-expand_less" : "icon-pin";

    return (
        <div
            className={`c6-side-navigation ${expand ? "expand" : ""} ${pinned ? "pinned" : ""}`}
            ref={ref}
            onClick={() => {
                if (!expand) {
                    setExpand(true);
                }
            }}
        >
            <div
                className="section comet"
                onClick={onHomeClick}
            >
                <CometLogo fill={appConfig.features.headerHighlightedTextBackgroundColor} />
                <div className="info">
                    <div className="title">{appConfig.product ?? "Comet"}</div>
                    <div className="version">{version}</div>
                </div>
                <div className="buttons">
                    <button
                        className={`pin-expand-button ${pinExpandButtonIcon} ${pinned ? "pinned" : ""}`}
                        onClick={onPinExpandClick}
                    />
                </div>
            </div>
            <div className="section new-version">
                <span className="newVersionAvailable hide">(please <a className="c6-link" onClick={reloadBrowser}>reload your browser</a><br/> for a newer version)</span>
            </div>
            <div className="section modules">
                <Modules
                    {...props}
                    navIsExpanded={expand}
                    openNav={() => setExpand(true)}
                    moduleStructure={moduleStructure}
                />
            </div>
            <div className="section account">
                {appConfig.features.enableSupportBeacon && (
                    <div className="help" onClick={openBeacon}>
                        <div className="icon-help"><span>Help</span></div>
                    </div>
                )}

                <div
                    className="user"
                    onClick={(e) => {
                        e.stopPropagation();
                        props.onToggleUserSettingsOpen();
                    }}>
                    <div className="icon-person"><span>{user.name}</span></div>
                </div>
            </div>
        </div>
    );
};

export default SideNavigation;

const Modules = ({ navIsExpanded, openNav, moduleStructure }) => {
    return (
        <React.Fragment>
            {moduleStructure.map((node) => (
                <Module
                    key={node.key ?? node.displayName}
                    node={node}
                    navIsExpanded={navIsExpanded}
                    openNav={openNav}
                    isRoot={true}
                />
            ))}
        </React.Fragment>
    );
};

function Module(props) {
    const { node, navIsExpanded, openNav, isRoot } = props;
    const isActive = isModuleActive(node);
    const isChildActive = isModuleChildActive(node);
    let icon = "";
    if (isActive && !isRoot) {
        // icon = "icon-arrow_right_alt";
    } else if (node.icon) {
        icon = `icon-${node.icon}`;
    } else if (node.url) {
        // icon = "icon-circle-small";
    }

    const [expand, setExpand] = React.useState(!isRoot || isActive);

    let className = "node";
    className += isActive ? " active" : "";
    className += isChildActive ? " active-child" : "";
    className += isRoot ? " root" : "";
    className += expand ? " expand" : "";
    className += node.url === "/" ? " home" : "";
    className += node.children && !isRoot ? " folder" : "";

    return (
        <div
            key={node.key ?? node.displayName}
            className={className}
        >
            {/* We use <a> instead of <Link> from react-router because their logic for active routes does not work well with nested routes */}
            <a
                className={`node-header ${icon}`}
                onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (node.url) {
                        browserHistory.push({ pathname: node.url });
                    }

                    if (node.children?.length && isRoot && navIsExpanded) {
                        setExpand(!expand);
                    }

                    if (!navIsExpanded) {
                        openNav();
                        setExpand(true);
                    }

                    if (!expand) {
                        const nodeElement = e.target.closest(".node");
                        setTimeout(
                            () => {
                                nodeElement?.scrollIntoView({ behavior: "smooth", block: "center" });
                            },
                            100
                        );
                    }
                }}
                href={node.url}
            >
                <span>{node.displayName}</span>
                {node.children?.length > 0 && isRoot && (
                    <div
                        onClick={(e) => {
                            e.stopPropagation();
                            setExpand(!expand);
                        }}
                        className={`expand ${expand ? "icon-expand_less" : "icon-expand_more"}`}
                    />
                )}
            </a>
            {node.children && expand && (
                <div className="node-children">
                    {node.children.map(child => <Module {...props} key={child.key ?? child.displayName} node={child} isRoot={false} />)}
                </div>
            )}
        </div>
    );
}

function reloadBrowser(e) {
    e.preventDefault();
    e.stopPropagation();
    window.location.reload(true);
}

function openBeacon(e) {
    e.stopPropagation();
    if (window.Beacon) {
        window.Beacon("toggle");
    } else {
        console.warn("No window.Beacon :(");
    }
}

function isModuleActive(module) {
    const pathname = location.pathname.replace(appConfig.app.basePath ?? "", "").replace("//", "/");
    if (module.url === "/") {
        return pathname === "/";
    }

    return (pathname + "/").startsWith("/" + module.url + "/");
    //     || module.children?.some(child => isModuleActive(child));
}

function isModuleChildActive(module) {
    return module.children?.some(child => isModuleActive(child) || isModuleChildActive(child));
}

let mouseOutsideTimeout = null;
const mouseOutsideTimeoutDuration = 50;
let mouseInsideTimeout = null;
const mouseInsideTimeoutDuration = 150;
function useOpenAndCloseOnMouseOver(ref, expand, setExpand, pinned) {
    const mouseOutsideTimeoutCallback = React.useCallback(
        () => {
            setExpand(false);
            clearTimeout(mouseOutsideTimeout);
            mouseOutsideTimeout = null;
        },
        [setExpand]
    );
    const mouseOutsideTimeoutSet = React.useCallback(
        throttle(
            (e) => {
                const hoverTarget = e.target;
                const isChildOfRef = ref.current?.contains(hoverTarget);
                if (!isChildOfRef && mouseOutsideTimeout === null) {
                    mouseOutsideTimeout = setTimeout(mouseOutsideTimeoutCallback, mouseOutsideTimeoutDuration);
                } else if (isChildOfRef && mouseOutsideTimeout !== null) {
                    clearTimeout(mouseOutsideTimeout);
                    mouseOutsideTimeout = null;
                }
            },
            50,
            { leading: false, trailing: true }
        ),
        [mouseOutsideTimeoutCallback]
    );
    const mouseInsideTimeoutCallback = React.useCallback(
        () => {
            setExpand(true);
            clearTimeout(mouseInsideTimeout);
            mouseInsideTimeout = null;
        },
        [setExpand]
    );
    const mouseInsideTimeoutSet = React.useCallback(
        throttle(
            (e) => {
                const hoverTarget = e.target;
                const isChildOfRef = ref.current?.contains(hoverTarget);
                if (isChildOfRef && mouseInsideTimeout === null) {
                    mouseInsideTimeout = setTimeout(mouseInsideTimeoutCallback, mouseInsideTimeoutDuration);
                } else if (!isChildOfRef && mouseInsideTimeout !== null) {
                    clearTimeout(mouseInsideTimeout);
                    mouseInsideTimeout = null;
                }
            },
            50,
            { leading: false, trailing: true }
        ),
        [mouseInsideTimeoutCallback]
    );

    const mouseOutsideDocumentListener = React.useCallback(
        () => {
            clearTimeout(mouseInsideTimeout);
            mouseInsideTimeout = null;
            mouseInsideTimeoutSet.cancel(); // cancel throttled calls

            clearTimeout(mouseOutsideTimeout);
            mouseOutsideTimeout = null;
            mouseOutsideTimeoutSet.cancel(); // cancel throttled calls

            if (!pinned) {
                setExpand(false);
            }
        },
        [pinned, setExpand]
    );

    React.useEffect(
        () => {
            if (expand && !pinned) {
                window.removeEventListener("mousemove", mouseOutsideTimeoutSet);
                window.addEventListener("mousemove", mouseOutsideTimeoutSet);
            } else {
                window.removeEventListener("mousemove", mouseOutsideTimeoutSet);
                window.removeEventListener("mousemove", mouseInsideTimeoutSet);
                window.addEventListener("mousemove", mouseInsideTimeoutSet);
                clearTimeout(mouseOutsideTimeout);
                mouseOutsideTimeout = null;
            }

            if (expand || pinned) {
                window.removeEventListener("mousemove", mouseInsideTimeoutSet);
                clearTimeout(mouseInsideTimeout);
                mouseInsideTimeout = null;
            }
        },
        [expand, pinned, mouseOutsideTimeoutSet, mouseInsideTimeoutSet]
    );

    React.useEffect(
        () => {
            document.addEventListener("mouseleave", mouseOutsideDocumentListener);

            return () => {
                document.removeEventListener("mouseleave", mouseOutsideDocumentListener);
                window.removeEventListener("mousemove", mouseOutsideTimeoutSet);
                window.removeEventListener("mousemove", mouseInsideTimeoutSet);
                clearTimeout(mouseInsideTimeout);
                mouseInsideTimeout = null;
                clearTimeout(mouseOutsideTimeout);
                mouseOutsideTimeout = null;
            };
        },
        [pinned]
    );
}

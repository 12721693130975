import ContentItem, { Content } from '../../../components/list/contentItem'
import Preview from '../../../components/assets/preview'

import '../../../components/listItems/standard.css'

const ListItem = (props) => {

    const {
		programGuid,
		productionYear,
		name,
	} = props;

    return (
        <ContentItem extraClasses={``} data={props}>
            <Preview programId={programGuid} />
            <Content>
                <h1>
					{name}
					{productionYear && <span>({productionYear})</span>}
                </h1>
            </Content>
        </ContentItem>
    );
};

export default ListItem;

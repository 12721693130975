import React from 'react'
import Editor from '../../../components/editor/'

import schema from './schema'
import ui from './ui'

export default props => (
	<Editor
		layout="grid"
		api="shield"
		entity="module"
		getSchema={getSchema}
		getUiSchema={getUiSchema}
		savePayloadTransform={savePayloadTransform}
		{...props}
	/>
);

function getSchema() {
	return schema;
}

function getUiSchema(model, isNew) {
	ui.name["ui:readonly"] = !isNew;
	return ui;
}

function savePayloadTransform({ formData, entity, location, route, ...rest }) {
	const { parentModuleId } = location.query;

	return {
		...rest,
		location,
		formData: {
			...formData,
			parentModuleId: parseInt(parentModuleId),
		},
		entity,
	}
}
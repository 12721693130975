import React from 'react'

import Editor, { parseUi, withQueryClient } from '../../../components/editor/index'
import { hasAccessToPath } from '../../../core/services/auth'
import * as ReviewsAPI from '../../../apis/reviews'

import MuiText from '../../../components/ui/editorWidgets/muiText'

import schema from './schema'
import uiSchema from './uiSchema'

const ProgramEditor = props => {
	return (
		<Editor
			className="c6-reviews-program-editor"
			layout="grid"
			api="reviews"
			entity="program"
			getSchema={getSchema}
			getUiSchema={getUiSchema}
			customFields={{ localTitle: LocalTitle }}
			loadPayloadTransform={loadPayloadTransform}
			savePayloadTransform={savePayloadTransform}
			hasEditAccess={hasAccessToPath(props.routes, "editor")}
			{...props}
		/>
	);
};

export default withQueryClient(ProgramEditor);

function getSchema(model, isNew, location, route, params) {
	return schema;
}

function getUiSchema(model, isNew, location, route, params) {
	const ui = { ...uiSchema };

	ui.seasonNumber["ui:widget"] = model.type === "season" ? "text" : "hidden";

	return ui;
}

function loadPayloadTransform({ model }) {
	return {
		...model,
	};
}

function savePayloadTransform({ formData, ...rest }) {
	return {
		...rest,
		formData: {
			...formData,
		},
	};
}

function LocalTitle(props) {
	const muiTextProps = {
		...props,
		label: props.formData.language,
		value: props.formData.title,
		onChange: (newValue) => {
			props.onChange({
				language: props.formData.language,
				title: newValue,
			});
		},
		required: false,
	};
	return <MuiText {...muiTextProps} />;
}
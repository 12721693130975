import { browserHistory } from 'browserHistory'

import { CONTENT, MENU } from '../../../../components/actions'

export default {
	targetComponent: "Content",
	actions: [
		{
			title: "Edit",
			type: CONTENT,
			method: ({ id }) => {
				browserHistory.push({
					pathname: `/star/admin/croptypes/${id}/edit`,
					state: {
						modal: true,
						returnTo: window.location.pathname,
					},
				});
			},
		},
		{
			title: "Edit",
			type: MENU,
			icon: "create",
			method: ({ id }) => {
				browserHistory.push({
					pathname: `/star/admin/croptypes/${id}/edit`,
					state: {
						modal: true,
						returnTo: window.location.pathname,
					},
				});
			},
		},
	]
};
import { CONTENT, INLINE, CLICK_OPT, CLICK_SHIFT } from '../../../components/actions'
import EditorActions from './actions'
import StarActions from '../actions'

export default {
	targetComponent: "Item",
	actions: [
		{
			type: CONTENT,
			title: "Display asset",
			method: (data, sourceProps, location, params, itemProps, e, listState) => {
				const list = sourceProps.assets;
				EditorActions.openAsset(data, sourceProps, location, params, itemProps, e, list);
			},
		},
		{
			// title: "Select item",
			type: INLINE,
			icon: "star_border",
			method: (data, sourceProps, location, params, itemProps) => StarActions.select({ ...data, fromContainerId: sourceProps.container.id }),
			hidden: (data, sourceProps, location, params, itemProps) => itemProps.selected,
		},
		{
			// title: "Unselect item",
			type: INLINE,
			icon: "star",
			method: StarActions.unselect,
			hidden: (data, sourceProps, location, params, itemProps) => !itemProps.selected,
		},
		{
			type: CLICK_OPT,
			method: StarActions.toggleSelect,
		},
		{
			type: CLICK_SHIFT,
			method: StarActions.toggleMultipleSelect,
		},
	]
};

// HELPERS
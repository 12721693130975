import React from 'react'
import { browserHistory } from 'browserHistory'
import { withRouter } from 'react-router'

import App from '../../../../components/app'
import { StandardInfo } from '../../../../components/comet'
import Empty from '../../../../components/list/empty'
import Main from '../../../../components/ui/main'
import { Filter, Left, Right } from '../../../../components/filter'
import Button from '../../../../components/ui/controls/button'

import Store from '../../store'
import Actions from '../../actions'

import List from './list'

const DATASTORE = "areas";

@withRouter
export default class AreasApp extends React.Component {
	state = {
		...Store.getState(),
	}

	componentDidMount() {
		Store.listen(this.onChange);
		Actions.fetchItems(DATASTORE, this.state.list.areas.filters);
	}

	componentWillUnmount() {
		// Actions.unmount();
		Store.unlisten(this.onChange);
	}

	onChange = (state) => {
		this.setState(state);
	}

	render() {
		const { areas } = this.state.list;
		const { items, filters, isLoading } = areas;

		return (
			<App name="c6-selections-admin-areas" layout="grid" isLoading={isLoading}>
				<Filter>
					<Left>
						<h1>Areas</h1>
					</Left>
					<Right>
						<Button
							type="create"
							title="Add area"
							onClick={() => {
								Actions.edit({
									pathname: `/selections/admin/areas/create`,
									returnTo: this.props.location.pathname,
								});
							}}
						/>
					</Right>
				</Filter>
				<Main>
					<List
						items={items}
						filters={filters}
					>
						<Empty v2={true} isLoading={isLoading}>
							There are no areas. You should add some!
						</Empty>
					</List>
				</Main>
				<StandardInfo location={this.props.location} />
			</App>
		);
	}
}
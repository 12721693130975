import React from 'react'
import moment from 'moment'

import Editor, { parseUi } from '../../../components/editor/index'
import { hasAccessToPath } from '../../../core/services/auth'
import { withQueryClient } from '../../../components/editor/index'

import * as SchedulesAPI from '../../../apis/schedules' // HMM?
import useSchedulesChannelBundles from '../../../core/queries/schedules/useSchedulesChannelBundles'

import schema from './schema'
import uiSchema from './uiSchema'
import appConfig from 'config'

import './app.css'

const ScheduleReleasesEditor = props => {
	const bundleNames = appConfig.features.scheduleReleasesBundleFilter ?? "TV4_ScheduleRelease,CMore_ScheduleRelease,CMoreSport_ScheduleRelease";
	const { data: bundles } = useSchedulesChannelBundles({
		filter: { bundleNames },
	});

	return (
		<Editor
			layout="grid"
			api="schedules"
			entity="scheduleRelease"
			className="c6-schedules-releaseseditor"
			getSchema={getSchema.bind(this, bundles)}
			getUiSchema={getUiSchema}
			getCommands={getCommands}
			savePayloadTransform={savePayloadTransform.bind(this, bundles)}
			hasEditAccess={hasAccessToPath(props.routes, "editor")}
			{...props}
		/>
	);
};

export default withQueryClient(ScheduleReleasesEditor);

function getCommands() {
	return {
		createItem: payload => {
			return new Promise((resolve, reject) => {
				const { releaseDay, until } = payload;

				const areDatesValid = validateDates(releaseDay, until);
				if (!areDatesValid) {
					reject(new Error("Those dates are not valid!")); // Results in a red alert
					return;
				}
				
				SchedulesAPI.createScheduleRelease(payload)
					.then(result => {
						resolve(result);
					}, error => reject(error));
			});
		}
	};
}

function getSchema(bundles, model, isNew, location, route, params) {
	if (!bundles || (!isNew && !model?.id)) {
		return { type: "object", properties: {} };
	}

	schema.properties.bundles.items.oneOf = bundles.items.map(bundle => ({
		const: bundle.id.toString(),
		title: bundle.displayName,
	}));
	schema.properties.bundles.items.descriptions = bundles.items.map(bundle => {
		const description = bundle.channels.map(c => c.displayName ?? c.name).join("\n");
		return description;
	});

	// HACK: perform loadPayloadTransform here since that requires bundles to be loaded first
	model.bundles = model.bundles ?? (isNew ? schema.properties.bundles.items.enum : []);
	bundles.items.forEach(bundle => {
		const bundleIsSelected = bundle.channels.every(bc => model.channels?.some(c => c.channelId === bc.id));
		if (bundleIsSelected) {
			model.bundles.push(bundle.id.toString());
			model.bundles = model.bundles.filter((b, i, bs) => bs.indexOf(b) === i);
		}
	});

	return schema;
}

function getUiSchema(model, isNew, location, route, params) {
	return uiSchema;
}

function savePayloadTransform(bundles, { formData, ...rest }) {

	const channels = bundles.items.reduce((arr, bundle) => {
		const bundleIsSelected = formData.bundles.includes(bundle.id.toString());
		if (bundleIsSelected) {
			const channels = bundle.channels.map(c => ({ channelId: c.id }));
			return [...arr, ...channels];
		} else {
			return arr;
		}
	}, []);

	delete formData.bundles;

	return {
		...rest,
		formData: {
			...formData,
			channels,
		},
	};
}

function validateDates(releaseDay, until) {
	if (
		!releaseDay // releaseDay required
		|| !until // until required
		|| moment(releaseDay).isAfter(moment(until)) // releaseDay can not be after until
	) {
		return false;
	}

	return true;
}
export default {
    CATEGORY_IDS: {
        UNDEFINED: 0, // Temporary (these lists will have category: Regular later on I think)
        REGULAR: 1,
        SHOWCASE: 2,
        FEATURE: 3,
        JSON: 4,
        BANNER: 5,
        EDITORIAL_ITEM: 6
    },
	LIST_TYPES: {
		UNDEFINED: "undefined",
		CURATED: "curated",
		WATCHNEXT: "watchNext",
		SINGLEITEM: "singleItem",
		WIDGET: "widget",
		AUTOMATIC: "automatic",
		EDITORIAL: "editorial",
		CHANNEL: "channel",
		PRESSRELEASE: "pressrelease",
		ALPHABETICAL: "alphabetical",
	},
    ITEM_TYPES: {
        EDITORIAL: "Editorial",
    },
    // CONTENT_LAYOUTS: [
    //     { id: 0, name: "Editorial 3 Columns", value: "cmore_3/carousel/editorial_column_3" },
    //     { id: 1, name: "Editorial 4 Columns", value: "cmore_3/carousel/editorial_column_4" },
    //     { id: 2, name: "Grid list", value: "cmore_3/grid/column_list" },
    //     { id: 3, name: "Highlight covers list", value: "cmore_3/carousel/highlight_list" },
    //     { id: 4, name: "Highlight Branded covers list", value: "cmore_3/carousel/tv4_branded_highlight_list" },
    //     { id: 5, name: "Subgenre covers list (default)", value: "cmore_3/carousel/subgenre_list" },
    //     { id: 6, name: "Sports highlights", value: "cmore_3/carousel/sport_highlights" }
    // ],
    // SHOWCASE_CONTENT_LAYOUT: "cmore_3/carousel/showcase",
    // FEATURE_CONTENT_LAYOUT: "cmore_3/featured/single_item",
    // BANNER_CONTENT_LAYOUTS: [
    //     { id: 0, name: "Sports", value: "cmore_3/sports/banner" },
    //     { id: 1, name: "Film & Series", value: "cmore_3/film_series/banner" },
    //     { id: 2, name: "Neutral (default)", value: "cmore_3/neutral/banner" }
    // ],
    // EDITORIAL_ITEM_CONTENT_LAYOUTS: [
    //     { id: 0, name: "Sports", value: "cmore_3/sports/editorial_item" },
    //     { id: 1, name: "Film & Series", value: "cmore_3/film_series/editorial_item" },
    //     { id: 2, name: "Neutral (default)", value: "cmore_3/neutral/editorial_item" }
    // ],
    // DEFAULT_SECTION_ID: 1,
    VERSIONS: [
        { id: 1, name: "Swedish" },
        { id: 2, name: "Norwegian" },
        { id: 3, name: "Danish" },
        { id: 4, name: "Finnish" }
    ],
    PLATFORMS: [
        { key: "web", name: "Web" },
        { key: "mobile", name: "Mobile" },
        { key: "smarttv", name: "SmartTv" },
        { key: "appletv", name: "AppleTv" }
    ]
}
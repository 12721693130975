import React from 'react'
// import PropTypes from 'prop-types'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListSubheader from '@mui/material/ListSubheader'
import isEqual from 'lodash/isEqual'
import isEqualWith from 'lodash/isEqualWith'
import get from 'lodash/get'

import './switch.css'

const DropDown = ({
	states = [],
	onChange,
	currentState = "",
	title = "",
	description,
	disabled = false,
	// unselected = false,
	name = "status",
	// value = "key",
	// label = "text",
	groupByProperty,
	style = {},
	buttonTextMode = "short",
	multiValue = false,
	emptyStateText,
	filterable = false,
	filterFn = (s, filter) => s.text?.toLowerCase().includes(filter.toLowerCase()),
	isItemCheckedFn = (s, currentValue) => isEqual(s, currentValue),
}) => {

	const buttonRef = React.useRef();
	const [open, setOpen] = React.useState(false);
	const [filter, setFilter] = React.useState("");
	const [filteredMenuItems, setFilteredMenuItems] = React.useState(getMenuItems(states, groupByProperty, currentState, onItemClick, isItemCheckedFn));
	const [filterFocused, setFilterFocused] = React.useState(false);

	const onItemClick = React.useCallback(
		(e, value) => {
			onChange({ target: { value, name } }, value);
			if (!multiValue) {
				setOpen(false);
			}
		},
		[onChange]
	);

	React.useEffect(() => {
		if (multiValue) {
			filterMenuItems();
		} else {
			setFilteredMenuItems(getMenuItems(states, groupByProperty, currentState, onItemClick, isItemCheckedFn));
		}
	}, [states]);

	const toggleOpen = React.useCallback(
		(e) => {
			e.preventDefault();
			e.stopPropagation();
			setOpen(currentOpen => !currentOpen);
		},
		[setOpen]
	);

	React.useEffect(() => {
		if (filterable) {
			filterMenuItems();
		}
	}, [filter, currentState]);

	const filterMenuItems = () => {
		let filtered = states;
		if (filter == "#") {
			filtered = states.filter(s => {
				if (Array.isArray(currentState)) {
					return currentState.find(f => isEqualWith(s.val ?? s.key, f, isItemCheckedFn))
				} else {
					return isEqualWith(s.val ?? s.key, currentState, isItemCheckedFn);
				}
			});
		} else {
			filtered = states.filter(s => filterFn(s, filter));
		}
		setFilteredMenuItems(getMenuItems(filtered, groupByProperty, currentState, onItemClick, isItemCheckedFn));
	}

	const onFilterChange = (e) => {
		setFilter(e.target.value);
	}

	const onFilterFocus = () => {
		setFilterFocused(true);
	}

	const onFilterBlur = () => {
		setFilterFocused(false);
	}

	return (
		<React.Fragment>
			<ButtonElement
				elRef={buttonRef}
				title={title}
				description={description}
				states={states}
				currentState={currentState}
				disabled={disabled || !states?.length}
				name={name}
				buttonTextMode={buttonTextMode}
				emptyStateText={emptyStateText}
				onClick={toggleOpen}
				style={style}
				isItemCheckedFn={isItemCheckedFn}
			/>
			{buttonRef?.current && (states?.length > 0) && (
				<Menu
					anchorEl={buttonRef?.current}
					open={open}
					onClose={toggleOpen}
					sx={{
						maxHeight: "500px"
					}}
				>
					{filterable && (	
						<div
							className="c6-dropdown-filter-container"
							onKeyUp={e => e.stopPropagation()}
							onKeyDown={e => e.stopPropagation()}
						>
							<TextField 
								fullWidth 
								variant="filled"
								size="small"
								label="Filter"
								helperText={filterFocused ? "Type # to show selected" : undefined}
								value={filter}
								onFocus={onFilterFocus}
								onBlur={onFilterBlur}
								onChange={onFilterChange}
							/>
						</div>
					)}
					{filteredMenuItems}
				</Menu>
			)}
		</React.Fragment>
	);
};

export default DropDown;

// DropDown.propTypes = {
// 	states: PropTypes.array.isRequired,
// 	onChange: PropTypes.func.isRequired,
// 	currentState: PropTypes.oneOfType([
// 		PropTypes.string,
// 		PropTypes.number,
// 		PropTypes.arrayOf(PropTypes.string),
// 		PropTypes.arrayOf(PropTypes.number),
// 		PropTypes.arrayOf(PropTypes.object)
// 	]),
// 	title: PropTypes.string,
// 	disabled: PropTypes.bool,
// 	// unselected: PropTypes.bool,
// 	name: PropTypes.string,
// 	// value: PropTypes.string,
// 	// label: PropTypes.string,
// 	groupByProperty: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
// 	anchorOrigin: PropTypes.object,
// 	targetOrigin: PropTypes.object,
// 	style: PropTypes.object,
// 	emptyStateText: PropTypes.string,
// 	buttonTextMode: PropTypes.oneOf(["short", "long"]),
// };

const ButtonElement = (props) => {
	const {
		title,
		description,
		states = [],
		currentState = "",
		disabled,
		name,
		emptyStateText = "None",
		buttonTextMode,
		elRef,
		onClick,
		style = {},
		isItemCheckedFn
	} = props;

	const buttonText = getButtonText(currentState, states, emptyStateText, buttonTextMode, isItemCheckedFn);

	return (
		<div className={`c6-switch ${disabled ? "disabled" : ""}`} ref={elRef} style={style}>
			{title ? <span>{title}</span> : null}
			<label onClick={onClick} title={description}>
				<input
					type="radio"
					name={name}
					checked={true}
					value={true}
					onChange={() => {}}
				/>
				{/* 160 = nbsp, 9662 = arrow down */}
				<span>{`${buttonText} ${String.fromCharCode(160)}${String.fromCharCode(9662)}`}</span>
			</label>
		</div>
	);
}

function groupByPropertyFn(list, property) {
	if (!property) {
		return list;
	}

	const group = {};

	const propList = list.map(l => {
		let propVal = get(l, property);
		if (!group[propVal]) {
			group[propVal] = [];
		}
		group[propVal].push(l);	
	});

	return propList;
}

export function getMenuItems(states, groupByProperty, currentState, onItemClick, isItemCheckedFn, titleFn) {
	if (groupByProperty) {
		const grouped = groupByPropertyFn(states, groupByProperty);
		const items = Object.keys(grouped).reduce((acc, curr) => (
			[...acc, <ListSubheader key={`group-${curr}`}>{curr}</ListSubheader>, ...getMenuItems(grouped[curr])]
		), []);
		
		return items;
	}
	
	const isChecked = (state) => {
		if (Array.isArray(currentState)) {
			return !!currentState.find(f => isEqualWith(state.val ?? state.key, f, isItemCheckedFn));
		}
		
		return isEqualWith(state.val ?? state.key, currentState, isItemCheckedFn);
	}
	
	states.map(s => {
		s.checked = isChecked(s);
	});

	return (
		states.map(s => {
			return (
				<DropdownItem
					key={`item-${s.reactKey ?? s.key}`}
					state={s}
					onItemClick={onItemClick}
					titleFn={titleFn}
				/>
			);
		})
	);
}

function DropdownItem({ state, onItemClick, titleFn }) {

	let icon = state.icon;
	if (state.checked) {
		icon = "check";
	}

	let iconEl = <ListItemIcon></ListItemIcon>;
	if (icon && typeof icon === "string") {
		iconEl = <ListItemIcon><span className={`icon-${icon}`} style={{ color: "var(--action-color)" }}></span></ListItemIcon>;
	} else if (icon) {
		iconEl = icon;
	}

	let title = titleFn ? titleFn(state) : state.longText || state.text;

	return (
		<MenuItem
			title={state.description}
			disabled={state.disabled}
			onClick={(e) => onItemClick(e, state.val ?? state.key)}
			sx={{
				fontWeight: state.strong ? "bold" : undefined,
				color: state.checked ? "var(--action-color)" : undefined,
			}}
		>
			{iconEl}
			<span style={{ paddingLeft: state.inset ? "30px" : undefined }}>{title}</span>
		</MenuItem>
	);
}

function getButtonText(currentState, states, emptyStateText, buttonTextMode, isItemCheckedFn) {
	const state = Array.isArray(currentState)
		? states.filter(s => !!currentState.find(cs =>  isEqualWith(s.val ?? s.key, cs, isItemCheckedFn)))
		: states.find(s => isEqualWith(s.val ?? s.key, currentState, isItemCheckedFn));


	if (Array.isArray(state)) {
		if (!state.length)
			return emptyStateText;
		
		if (buttonTextMode === "long") {
			return state.map(s => s.text).join(", ");
		}

		return `${state.length} selected`;
	}

	return state?.text ?? emptyStateText ?? "";
}
import alt from '../../../core/services/alt'
//import { datasource } from 'alt-utils/lib/decorators'
import Actions from './actions'

import appConfig from 'config'

// const EditorSource = {
// 	createProgram: {
// 		// remotely fetch something (required)
// 		remote(state) {
// 			return axios.get(`/search/q/${state.value}`);
// 		},

// 		// // this function checks in our local cache first
// 		// // if the value is present it'll use that instead (optional).
// 		// local(state) {
// 		//   return state.results[state.value] ? state.results : null;
// 		// },

// 		// here we setup some actions to handle our response
// 		// loading: Actions.loadingResults, // (optional)
// 		success: Actions.receivedResults, // (required)
// 		error: Actions.fetchingResultsFailed, // (required)

// 		// // should fetch has precedence over the value returned by local in determining whether remote should be called
// 		// // in this particular example if the value is present locally it would return but still fire off the remote request (optional)
// 		// shouldFetch(state) {
// 		//   return true
// 		// }
// 	}
// };

// @datasource(EditorSource)
class EditorStore {
	constructor() {
		this.model = {};

		this.programs = [];
		this.countries = [];
		this.genres = [];

		this.isDirty = false;
		this.isLoading = false;

		this.bindListeners({
			onRequestFailed: Actions.REQUEST_FAILED,

			onFetchLicense: Actions.FETCH_LICENSE,
			onUpdateLicense: Actions.UPDATE_LICENSE,
			onCreateLicense: Actions.CREATE_LICENSE,

			onModelChanged: Actions.MODEL_CHANGED,
			onModelUpdated: [Actions.MODEL_UPDATED, Actions.MODEL_SAVED],

			onProgramsUpdated: Actions.PROGRAMS_UPDATED,
			onSelectProgram: Actions.SELECT_PROGRAM,
			onRemoveProgram: Actions.REMOVE_PROGRAM,
			onProgramCreated: Actions.PROGRAM_CREATED,

			onRemoveParentProgram: Actions.REMOVE_PARENT_PROGRAM,

			onCountriesUpdated: Actions.COUNTRIES_UPDATED,
			onClearCountry: Actions.CLEAR_COUNTRY,
			onGenresUpdated: Actions.GENRES_UPDATED,
			onClearGenre: Actions.CLEAR_GENRE,

			onUnmount: Actions.UNMOUNT,
		});
	}

	onRequestFailed() {
		this.isLoading = false;
	}

	onFetchLicense() {
		this.isLoading = true;
	}

	onUpdateLicense() {
		this.isLoading = true;
	}

	onCreateLicense() {
		this.isLoading = true;
	}

	onModelChanged({ model }) {
		this.model = model;
		this.isDirty = true;
	}

	onModelUpdated({ model }) {
		this.model = model;
		if(model.program.id) {
			this.model.programId = model.program.id;
		}
		this.isLoading = false;
		this.isDirty = false;
	}

	onProgramsUpdated(data) {
		this.programs = data;
	}

	// Check title against datasource in case the user didn't select anything before pressing enter

	// If not, update the datasource with the new program and flag the program metadata field for rendering
	// Or render a program editor on top/before the license editor

	// On save, break out the program metadata and save to the program api

	// Use the returned programId when saving the license to the license api

	// Update the datasource (if needed) with the new program api

	onSelectProgram({ name, id = -1, providerId = null }) {

		if(id === -1) {

			// Do we have the program in the autocomplete dropdown already or is it a new one?
			const autocompleteProgram = this.programs.filter(p => p.name === name);

			if(autocompleteProgram.length === 1) {
				id = autocompleteProgram[0].id;
			}
			else if(autocompleteProgram.length > 1) {
				alert("More than one program matches the name, please select one in the dropdown menu.");
			}
		}

		if(this.model.program && name !== this.model.program.name || id !== this.model.programId) {

			// Fill or update the datasource for our autocomplete dropdown
			// const newProgramExists = !!this.programs.filter(p => p.id === program.id).length;
			// if(newProgramExists) {
			// 	//debugger;
			// 	this.programs.name = program.name;
			// }
			// else {
			// 	this.programs.push(program);
			// }

			this.model.programId = id;
			this.model.program = {
				...this.model.program || {},
				id,
				name,
				providerId: id === -1 ? providerId : undefined,
			};
		}
	}

	onRemoveProgram() {
		this.model.programId = null;
		delete this.model.program;
	}

	onRemoveParentProgram() {
		this.model.program.parentId = null;
	}

	onProgramCreated({id}) {
		this.model.programId = id;
	}

	onCountriesUpdated(data) {
		this.countries = data;
	}

	onClearCountry() {
		this.model.program.country.id = null;
	}

	onGenresUpdated(data) {
		this.genres = data;
	}

	onClearGenre() {
		if(this.model.program && this.model.program.genre) {
			this.model.program.genre.id = null;
		}
	}

	onUnmount() {
		this.model = {};
		this.programs = [];
		this.countries = [];

		this.isLoading = false;
		this.isDirty = false;
	}
}

export default alt.createStore(EditorStore, '[ACQ]TemporaryLicenseEditorStore');
import React from 'react'
import moment from 'moment'
import TextField from '@mui/material/TextField'

import Editor, { parseUi, withQueryClient } from '../../../../components/editor/index'
import { hasAccessToPath } from '../../../../core/services/auth'
import * as VodCoreAPI from '../../../../apis/vodcore'

import schema from './schema'
import uiSchema from './uiSchema'

import './app.css'
import useVodCoreOperators from '../../../../core/queries/vodcore/useVodCoreOperators'
import useVodCoreLibraries from '../../../../core/queries/vodcore/useVodCoreLibraries'
import useVodCoreVersions from '../../../../core/queries/vodcore/useVodCoreVersions'
import useVodCoreSites from '../../../../core/queries/vodcore/useVodCoreSites'

const ServiceEditor = props => {

	const { data: operators } = useVodCoreOperators({
		filter: { orderBy: "name" },
	});
	const { data: libraries } = useVodCoreLibraries({
		filter: { orderBy: "name", statuses: "public" },
	});
	const { data: sites } = useVodCoreSites();
	const { data: versions } = useVodCoreVersions();

	return (
		<Editor
			className="c6-vod-service-editor"
			layout="grid"
			api="vod"
			entity="admin-service"
			customFields={{ daysAhead: DaysAhead, keepsVideo: KeepsVideo }}
			getSchema={getSchema.bind(this, operators, versions, sites, libraries)}
			getUiSchema={getUiSchema.bind(this, versions, sites, libraries)}
			getCommands={getCommands}
			loadPayloadTransform={loadPayloadTransform}
			savePayloadTransform={savePayloadTransform}
			hasEditAccess={hasAccessToPath(props.routes, "editor")}
			{...props}
		/>
	);
};

export default withQueryClient(ServiceEditor);

function getCommands() {
	const createOrUpdate = (payload) => {
		const {
			destinations = [],
			...servicePayload
		} = payload;

		return new Promise(async (resolve, reject) => {
			try {
				const updatedService = await VodCoreAPI.createOrUpdateService(servicePayload);
				destinations.forEach(d => {
					d.itemType = "Container";
					d.name = d.name ?? `${payload.name}-destination-${moment().format("YYYYMMDD-HHmmss")}`;
					d.serviceId = updatedService.id;
					d.deliveryMethod = d.deliveryMethod === "null" ? null : d.deliveryMethod; // "null" = null because muiSelect does not handle null? :(
				});
				const destinationRequests = destinations.map(d => VodCoreAPI.createOrUpdateDestination(d));
				const updatedDestinations = destinationRequests.length ? await Promise.all(destinationRequests) : [];
				resolve({
					...updatedService,
					destinations: updatedDestinations,
				});
			} catch (e) {
				reject(e);
			}
		});
	};

	return {
		fetchItem: VodCoreAPI.fetchService,
		updateItem: ({}, payload) => createOrUpdate(payload),
		createItem: createOrUpdate,
	};
}

function getSchema(operators, versions, sites, libraries) {
	if (!operators?.items?.length || !libraries?.items?.length || !sites?.items?.length || !versions?.items?.length) {
		return { type: "object", properties: {} };
	}

	schema.properties.operator.properties.name.oneOf = operators.items.map(o => ({
		const: o.displayName ?? o.name,
		title: o.displayName ?? o.name,
	}));
	schema.properties.operator.properties.name.default = operators.items[1]?.name ?? operators.items[0]?.name;

	return schema;
}

function getUiSchema(versions, sites, libraries, model) {
	if (libraries?.items?.length) {
		uiSchema.libraries["ui:options"].dataSource = libraries.items.map(l => ({
			id: l.id,
			name: l.displayName ?? l.name,
		}));
	}
	if (sites?.items?.length) {
		uiSchema.sites["ui:options"].dataSource = sites.items.map(l => ({
			id: l.id,
			name: l.displayName ?? l.name,
		}));
	}
	if (versions?.items?.length) {
		uiSchema.versions["ui:options"].dataSource = versions.items.map(l => ({
			id: l.id,
			name: l.displayName ?? l.name,
		}));
	}

	uiSchema.destinations.items.mode["ui:widget"] = (model.destinations?.[0]?.deliveryMethod === "aws_s3")  ? "text" : "hidden";

	return uiSchema;
}

function loadPayloadTransform({ model }) {
	return {
		...model,
		continuousDelivery: model.deliveryPolicy === "Push",
	};
}

function savePayloadTransform({ formData, ...rest }) {
	const isNew = !formData?.id;
	
	return {
		...rest,
		formData: {
			...formData,
			type: isNew ? "SvodOperator" : formData.type,
			container: isNew ? "Folder" : formData.container,
			packagePolicy: isNew ? "Resend" : formData.packagePolicy,
			deliveryPolicy: formData.continuousDelivery ? "Push" : "Pull",
			// keepsVideo: isNew ? -1 : formData.keepsVideo,
			// daysAhead: isNew ? 14 : formData.daysAhead,
		},
	};
}

function DaysAhead({ formData, onChange }) {
	return (
		<fieldset className="field service-custom-field">
			<div className="row">
				<span>Deliver video files</span>
				<TextField
					id="daysAhead"
					variant="standard"
					className="c6-mui"
					value={formData}
					onChange={(e) => onChange(e.target.value?.length ? parseInt(e.target.value) : 0)}
					style={{ width: "80px", margin: "-6px 10px 10px" }}
					type="number"
					min={0}
					max={100}
				/>
				<span>days ahead of their premiere if they are available</span>
			</div>
		</fieldset>
	);
}

function KeepsVideo({ formData, onChange }) {
	return (
		<fieldset className="field service-custom-field">
			<div className="row">
				<span>This service keeps the video files</span>
				<TextField
					variant="standard"
					id="keepsVideo"
					className="c6-mui"
					value={formData}
					onChange={(e) => onChange(e.target.value?.length ? parseInt(e.target.value) : 0)}
					type="number"
					style={{ width: "80px", margin: "-6px 10px 10px" }}
					min={0}
					max={100}
				/>
				<span>days after they have gone out of license, after that they have to be redelivered (seen as new files by Comet)</span>
			</div>
		</fieldset>
	);
}
import React from 'react'
import appConfig from 'config'

import App from '../../../../components/app'
import Button from '../../../../components/ui/controls/button'
import withDnDContext from '../../../../core/services/withDnDContext'
import { decoratorUIActions } from '../../../../core/decorators/uiActions'

import Actions from '../../actions'
import Store from '../../store'
import PasswordBox from './passwordBox'
import Header from './header'
import itemActions from './itemActions'
import Items from '../../shared/items'
import FloatActions from '../../shared/floatactions'
import './app.css'

const ENTITY = "album";

@decoratorUIActions(itemActions)
class StarSharedAlbum extends React.Component {

	constructor(props) {
		super(props);

		this.albumGuid = props.params.guid;

		this.uploaderRef = null;
		this.state = {
			password: "",
			...Store.getState()
		};
	}

	componentDidMount() {
		Store.listen(this.onChange);
		if (this.albumGuid) {
			Actions.fetchSharedAlbum(this.albumGuid);
		}
	}

	componentWillUnmount() {
		Store.unlisten(this.onChange);
	}

	onChange = (state) => {
		this.setState(state);
	}

	handlePasswordSubmit = () => {
		Actions.fetchSharedAlbum(this.albumGuid, this.state.password);
	}

	handlePasswordChange = (val) => {
		this.setState({ password: val });
	}

	// startUpload = () => {
	// 	if (this.uploaderRef) {
	// 		this.uploaderRef.methods.uploadStoredFiles();
	// 	}
	// }

	handleUploadComplete = (model) => {
        Actions.itemLoaded({ entity: "album", model });
    }

	render() {
		const { item, password } = this.state;
		const { album, isLoading } = item;

		let content = <h3 className="loading">Sorry! Could not find the album! Please verify that you copied the entire link from your email.</h3>;
		if (isLoading) {
			content = <h3 className="loading">Loading album...</h3>;
		} else if (album && album.authError != null) {
			content = getContentForAuthError(album.authError, password, this.handlePasswordChange, this.handlePasswordSubmit);
		} else if (album && album.id) {
			const list = {
				items: album.assets || [],
				isLoading,
			};
			const selected = this.state.list.selected;

			const passwordParam = password ? `?password=${password}` : null;
			const url = `${appConfig.api.star}albums/shared/${album.albumGuid}/uploadFile${passwordParam}`;
			
			content = [
				<Header
					key="header"
					data={album}
					title={album.displayName}
				/>,
				<Items
					key="items"
					userCanEdit={album.type.includes("Upload")}
                    uploadUrl={url}
                    onUploadComplete={this.handleUploadComplete}
                    list={list}
					setInputRef={el => this.searchRef = el}
					skipUploadAuth={true}
					selected={selected}
					uploaderInformationText={`Files for album: "${album.displayName ?? album.name}"`}
					uploaderIdPrefix={`album-${album.albumGuid}`}
				/>,
				album.type.includes("Download") && <FloatActions key="float-actions" selectedItems={selected.items}>
					<Button
						key="download"
						type="download"
						title="Download"
						onClick={() => Actions.downloadFromSharedAlbum(album.albumGuid, password, selected.items)}
						noBackground
					/>,
					<Button
						key="cancel"
						type="cancel"
						title="Clear selection"
						onClick={Actions.clearSelected}
						noBackground
					/>
				</FloatActions>,
			];
		}

		return (
			<App name="c6-star c6-star-sharedalbum" isLoading={isLoading}>
				{content}
			</App>
		);
	}
}

export default withDnDContext(StarSharedAlbum);


function getContentForAuthError(authError, password, handlePasswordChange, handlePasswordSubmit) {
	let invalidPassword = false;
	switch (authError) {
		case "Album requires password":
			invalidPassword = false;
			break;
		case "Invalid password":
			invalidPassword = true;
			break;
		case "Link expired":
			return <h3 className="loading">This album is no longer shared.</h3>;
		default:
			return <h3 className="loading">This album is not shared.</h3>;
	}

	return (
		<PasswordBox
			value={password}
			onChange={handlePasswordChange}
			onClick={handlePasswordSubmit}
			invalidPassword={invalidPassword}
		/> 
	);
}
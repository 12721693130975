import React from 'react'
import { browserHistory } from 'browserHistory'
import moment from 'moment'

import Review from './review'
import itemActions from './itemActions'

import * as API from '../../../apis/reviews'
import useListState from '../../../core/hooks/useList'
import GenericListApp from '../../../components/genericApp/listApp'
import { decoratorUIActions } from '../../../core/decorators/uiActions'
import { getLanguages } from '../utils'
import { getUser, getUserAccessLevelForModule } from '../../../core/services/auth'

import './reviews.css'
import useReviewsLanguages from '../../../core/queries/reviews/useReviewsLanguages'
import useReviewsSources from '../../../core/queries/reviews/useReviewsSources'
import useReviewsProgram from '../../../core/queries/reviews/useReviewsProgram'
import useReviewsCustomers from '../../../core/queries/reviews/useReviewsCustomers'
import useShieldLogins from '../../../core/queries/shield/useShieldLogins'
import usePrevious from '../../../core/hooks/usePrevious'

const MODULE = "reviews"
const DATASTORE = "reviews"
const TEXT_HEADING = "Reviews"

const HIGH_PRIORITY_SOURCES = "_highPriority";
const MISSING_QUOTES = "_missingquotes";
const MOST_RECENT_QUOTE = "mostrecentquote";
const CUSTOMER_PUBLISH_DATE_DESCENDING = "customerpublishdatedescending";

function ListApp(props) {
	const customer = props.route?.customer;
	
	const languageNameFromQuery = props.location?.query?.language ? props.location.query.language : null;
	const programIdFromQuery = props.location?.query?.programId ? props.location.query.programId : null;

	const [orderByQuoteDisabled, setOrderByQuoteDisabled] = React.useState(false);
	const [missingQuotesDisabled, setMissingQuotesDisabled] = React.useState(false);

	const { data: languagesData } = useReviewsLanguages({
		filter: { orderBy: "name", pageSize: 1000, onlyLanguagesWithSources: true },
		enabled: !customer,
	});
	const { data: sources } = useReviewsSources({
		filter: { orderBy: "name", pageSize: 1000 },
	});
	const { data: program } = useReviewsProgram(programIdFromQuery);
	const { data: customers } = useReviewsCustomers({
		enabled: !customer,
	});

	const { data: users } = useShieldLogins({
		filter: { type: "user" },
		enabled: !customer,
	});

	const languages = getLanguages(customer, languagesData);

	const noCustomerFilterConfig = {
		filter: {
			type: "pick",
			title: "Missing",
			options: [
				{ key: "missingimdb", text: "IMDb" },
				{ key: "missingopinion", text: "Opinion" },
				{ key: "missingscore", text: "Score" },
				{ key: MISSING_QUOTES, text: "Quotes", disabled: missingQuotesDisabled },
				{ key: "missingseasonnumber", text: "Season number" },
			],
		},
		quoteAdded: {
			type: "dropdown",
			title: "Quotes made",
			options: getMonths(),
		},
		quoteAddedBy: {
			type: "dropdown",
			title: "Quotes by",
			options: [
				{ key: null, text: "Any user" },
				...users?.items?.filter(u => u.name !== "sysadmin").map(u => ({
					key: u.name,
					text: u.name,
				})) ?? [],
			]
		},
		orderBy: {
			type: "switch",
			title: "Order by recent",
			alwaysVisible: true,
			options: [
				{ key: MOST_RECENT_QUOTE, text: "Quote", disabled: orderByQuoteDisabled },
				{ key: "publisheddescending", text: "Review" },
				{ key: CUSTOMER_PUBLISH_DATE_DESCENDING, text: "Program" },
			],
		},
	};

	const customerFilterConfig = {
		quotes: {
			type: "switch", // "switch" = radiobutton, "pick" = checkbox
			title: "Quotes",
			options: [
				{ key: "<3", text: "<3 quotes" },
				{ key: "", text: "Any nr of quotes" },
			],
		},
		quoteAdded: {
			type: "dropdown",
			title: "Quotes made",
			options: getMonths(),
		},
		filter: {
			type: "pick",
			title: "Missing",
			options: [
				{ key: MISSING_QUOTES, text: "Quotes" },
				{ key: "missingseasonnumber", text: "Season number" },
			],
		},
	};

	const filterConfig = {
		searchText: {
			type: "search",
			alwaysVisible: true,
		},
		...(!customer ? {
			customer: {
				type: "dropdown",
				title: "Customer",
				options: [
					{ key: null, text: "Any" },
					...customers?.items?.map(c => ({
						key: c.name,
						text: c.name,
					})) ?? [],
				],
			},
		} : {}),
		language: {
			type: "dropdown",
			title: "Language",
			options: [
				// { key: null, text: "Any" },
				...languages,
			],
		},
		sourceId: {
			type: "dropdown",
			title: "Source",
			options: [
				{ key: null, text: "All sources" },
				{ key: HIGH_PRIORITY_SOURCES, text: "High priority sources" },
				...sources?.items?.map(s => ({
					key: s.id,
					text: s.name,
				})) ?? [],
			],
		},
		programTypes: {
			type: "switch",
			title: "Type",
			options: [
				{ key: "", text: "Any" },
				{ key: "single", text: "Movies" },
				{ key: "season", text: "Series" },
			],
		},
		minimumNormalizedScore: {
			type: "dropdown",
			title: "Score",
			options: [
				{ key: null, text: "Any" },
				...[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(s => ({
					key: s,
					text: `> ${s}`,
				})),
			],
		},
		...(customer ? customerFilterConfig : noCustomerFilterConfig),
	};

	let customerFilterDefaults = {};
	if (customer && !programIdFromQuery) {
		customerFilterDefaults = {
			customer,
			programTypes: "",
			sourceId: HIGH_PRIORITY_SOURCES,
			orderBy: CUSTOMER_PUBLISH_DATE_DESCENDING,
			language: languages[0]?.key ?? "",
			quotes: "<3",
			minimumNormalizedScore: 5,
			filter: MISSING_QUOTES,
			quoteAddedBy: getUserAccessLevelForModule("reviews") === "admin" ? "" : getUser().username,
		};
	} else if (customer && programIdFromQuery) {
		customerFilterDefaults = {
			customer,
			orderBy: CUSTOMER_PUBLISH_DATE_DESCENDING,
			quoteAddedBy: getUserAccessLevelForModule("reviews") === "admin" ? "" : getUser().username,
			language: "",
		};
	}

	const FILTER_DEFAULTS = {
		searchText: "",
		language: languageNameFromQuery,
		orderBy: MOST_RECENT_QUOTE,
		sourceId: null,
		minimumNormalizedScore: null,
		programId: programIdFromQuery,
		programTypes: "",
		filter: "",
		customer: null,
		quoteAdded: null,
		quoteAddedBy: null,

		...customerFilterDefaults,
	};

	const listKey = `${DATASTORE}${programIdFromQuery ? `-${programIdFromQuery}` : ""}${customer ? `-${customer}` : ""}`;
	const listState = useListState({
		listKey,
		fetchData: API.fetchReviews,
		filterDefaults: FILTER_DEFAULTS,
		transformFilters: transformFilters,
		getEnabled: ({ filters }) => {
			return !!filters.values.programId || filters.values.language?.length > 0;
		},
	});

	const prevProgramIdFromQuery = usePrevious(programIdFromQuery);
	React.useEffect(
		() => {
			// TEMP fix since we remove "Any" and some users might have that stored in their saved filters
			// When languages have been loaded, check if the stored language is still valid. If not, reset it to the first available language
			if (
				!prevProgramIdFromQuery && !programIdFromQuery
				&& languages?.length > 0
				&& !filterConfig.language?.options.find(l => l.key === listState.filters.values.language)
			) {
				listState.filters.updateMethods.language(languages[0].key);
			}

			// Don't use language filter when listing reviews for a specific program
			else if (programIdFromQuery && listState.filters.values.language) {
				listState.filters.updateMethods.language("");
			}
		},
		[languages, filterConfig.language, listState.filters.values.language, programIdFromQuery, prevProgramIdFromQuery]
	);

	React.useEffect(
		() => {
			if (!programIdFromQuery !== prevProgramIdFromQuery) {
				listState.filters.updateMethods.programId(programIdFromQuery);
			}
		},
		[prevProgramIdFromQuery, programIdFromQuery]
	);

	const onCreateClick = React.useCallback(
		() => {
			browserHistory.push({
				pathname: "/reviews/reviews/create",
				query: { programId: program.id },
				state: {
					modal: true,
					returnTo: `${props.location.pathname}${props.location.search}`,
				},
			});
		},
		[program]
	);

	// When selecting "Quotes" in the "Missing" filter, disable the "Quote" option in the "Order by recent" filter, and vice versa
	// (because it doesn't make sense to order by quote when you're only showing missing quotes. Also, the API becomes super slow)
	React.useEffect(
		() => {
			if (listState.filters.values.filter?.includes(MISSING_QUOTES)) {
				setOrderByQuoteDisabled(true);
			} else {
				setOrderByQuoteDisabled(false);
			}

			if (listState.filters.values.orderBy === MOST_RECENT_QUOTE) {
				setMissingQuotesDisabled(true);
			} else {
				setMissingQuotesDisabled(false);
			}
		},
		[listState.filters.values.filter, listState.filters.values.orderBy]
	);

	return (
		<GenericListApp
			{...props}
			module={MODULE}
			datastore={DATASTORE}
			state={listState}
			textHeading={program ? `Reviews for "${program.title}"` : TEXT_HEADING}
			textEmpty={program ? "No reviews? It's about time to create some then!" : "No reviews found."}
			textCreate={program ? "Create review" : null}
			iconCreate={program ? "add" : null}
			onCreateClick={program ? onCreateClick : null}
			filterConfig={programIdFromQuery === null ? filterConfig : {}}
			collapseFiltersDefault={false}
			showInfoBar={true}
		>
			<Review customer={customer} location={props.location} />
		</GenericListApp>
	);
}

@decoratorUIActions(itemActions)
export default class ReviewsApp extends React.Component {
	render() {
		return (
			<ListApp
				{...this.props}
			/>
		);
	}
}


function transformFilters(filters) {
	const transformed = { ...filters };
	
	if (filters.quotes === "<3") {
		transformed.maxNumberOfQuotes = 3;
	}
	delete transformed.quotes;

	if (filters.sourceId === HIGH_PRIORITY_SOURCES) {
		delete transformed.sourceId;
		transformed.minimumSourceRank = "70";
	}

	if (filters.filter?.includes(MISSING_QUOTES)) {
		transformed.filter = transformed.filter.replace(`,${MISSING_QUOTES}`, "").replace(`${MISSING_QUOTES},`, "").replace(MISSING_QUOTES, "");
		transformed.hasQuotes = false;
	}

	if (filters.quoteAdded) {
		transformed.quoteAddedFrom = filters.quoteAdded;
		transformed.quoteAddedUntil = moment(filters.quoteAdded).endOf("month").format("YYYY-MM-DD");
		delete transformed.quoteAdded;
	}

	return transformed;
}

function getMonths() {
	const months = [
		{ key: null, text: "Any time", description: "" },
	];

	let month = moment().startOf("month");
	for (let i = 0; i <= 3; i++) {
		months.push({
			key: month.format("YYYY-MM-DD"),
			text: month.format("MMMM"),
			description: "Quotes made in " + month.format("MMMM"),
		})
		month = month.add(-1, "months").startOf("month");
	}
	return months;
}
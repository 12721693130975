import React from 'react'
import Editor, { parseUi } from '../../../components/editor/'

import Approval from '../../../components/ui/editorFields/approval'
import Crew from '../../../components/ui/editorFields/crew'

import * as API from '../../../apis/'

import schemaSingle from './singleSchema'
import uiSingle     from './singleUI'


export default props => (
	<Editor
		layout="grid"
		submitAsPatch={true}
		api="metadata"
		entity="program"
		getSchema={getSchema}
		getUiSchema={getUiSchema}
		customFields={{ approval: Approval, crew: Crew }}
		{...props}
	>
	</Editor>
);

function getSchema(model, isNew, location, route, params) {
	let schema;
	const type = model.type || params.type || "";
	switch(type.toLowerCase()) {
		default:
			schema = schemaSingle;
			break;
	}
	return schema;
}

function getUiSchema(model, isNew, location, route, params) {
	let ui;
	const type = model.type || params.type || "";
	switch(type.toLowerCase()) {
		default:
			ui = uiSingle;
			break;
	}

	ui = editableInternalNameWhenCreatedInComet(ui, isNew, model);

	return {
		"ui:readonly": model.approval && model.approval.status && model.approval.status.toLowerCase() === "approved",
		...parseUi(ui, API),
	};
}

// HELPERS
function editableInternalNameWhenCreatedInComet(ui, isNew, {provider}) {
	if(isNew || provider && provider.name === "Comet") {
		return {
			...ui,
			name: {
				...ui.name,
				"ui:readonly": false,
			}
		};
	}

	return ui;
}
import React from 'react'
import Editor from '../../../components/editor/'

import schema from './schema'
import ui from './ui'

export default props => (
	<Editor
		layout="grid"
		api="shield"
		entity="service"
		getSchema={getSchema}
		getUiSchema={getUiSchema}
		enableEditorNavigation={true}
		{...props}
	/>
);

function getSchema() {
	return schema;
}

function getUiSchema(model, isNew) {
	ui.name["ui:readonly"] = !isNew;
	return ui;
}
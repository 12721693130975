import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'
import Status from '../../../components/ui/status'

import Actions from '../actions'
import Store from '../store'
import Const from '../../../core/constants'

import { Content } from '../../../components/list/contentItem'
import ContentItem from '../../../components/list/contentItem'
import InlineHeading from '../../../components/ui/inlineHeading'
import Period from '../../../components/ui/period'

import './packages.css'

export default class Packages extends React.Component {

	static propTypes = {
		id: PropTypes.number,
		programVersions: PropTypes.string
	}

	constructor(props) {
		super(props);

		this.state = {
			...Store.getState(),
		}
	}

	componentDidMount() {
		Store.listen(this.onChange);
		const { id } = this.props.params;
		Actions.fetchItems("programversionPackages", { id });
	}

	componentWillUnmount() {
		Actions.unmount();
		Store.unlisten(this.onChange);
	}

	onChange = (state) => {
		this.setState(state);
	}

	render() {
		const { } = this.props;

		const id = this.props.params.id
		const items = this.state.list.programversionPackages.items;
		return (
				<div className="packages">
					{items.slice(0, 1).map((item, k) =>
						<h1 key={k} style={{ fontSize: "17px", marginLeft: "10px" }}>{item.name}</h1>
					)}
					{items.map((item, i) =>
						<div key={i} style={{display: "flex", justifyContent: "space-around", marginBottom: "10px"}}>
							<div style={{}}>
								<InlineHeading title={item.service}>
									&nbsp;
								</InlineHeading>
							</div>
							<div>
								<InlineHeading title="Last Sent">
									<Period start={item.lastSent} format={Const.DATE_TIME_FORMAT} />
								</InlineHeading>
							</div>
							<div>
								<Status
									status={item.status === "Delivered" ? "ok" : "failed"}
								>
									<Link to={`vod/packages/${item.refernece}/deliveries`}>{item.status.toUpperCase()}</Link>
								</Status>
							</div>
						</div>
					)}
				</div>
		);

	}
}

import React from 'react'
import Moment from 'moment'
// import keydown from 'react-keydown'
import debounce from 'lodash/debounce'
import { ScrollContainer } from 'react-router-scroll'

import { getRouteName } from '../../../components/comet'
import App from '../../../components/app'
import Header from '../../../components/headers/header'
import Main from '../../../components/ui/main'
import { Info } from '../../../components/comet'

// import Header from './header'
import List from './list'

import Actions from '../actions'
import Store from '../store'

import { getSearchPayload } from '../shared/utils'

import './deliveries.css'


const MODULE = "vod"
const DATASTORE = "deliveries"
const ENTITY = "delivery"
const PARENT_ENTITY = "package"
const TEXT_HEADING = "Events"

export default class ListApp extends React.Component {

	constructor(props) {
		super(props);

		this.onChange = this.onChange.bind(this);
		this.onFilter = this.onFilter.bind(this);
		this.handleFilter = this.handleFilter.bind(this);
		this.debouncedFilter = debounce(this.handleFilter, 500);
		this.loadMore = this.loadMore.bind(this);

		this.scroll = null;

		this.packageId = this.props.params.id;

		this.state = {
			...Store.getState(),
			searchText: "",
			unselectedFilter: false,
		};
	}

	componentDidMount() {
		Store.listen(this.onChange);
		if(this.packageId) {
			Actions.fetchItems(DATASTORE, { packageId: this.packageId, orderBy: "createdDescending" });
			Actions.fetchItem(PARENT_ENTITY, this.packageId);
		}
	}

	// componentWillReceiveProps({ keydown }) {
	// 	if (keydown.event) {
	// 		console.log( keydown.event.which );
	// 		this.onSearch(keydown.event);
	// 	}
	// }

	componentWillUnmount() {
		Actions.unmount();
		Store.unlisten(this.onChange);
	}

	onChange(state) {
		this.setState(state);
	}

	onFilter(type, e) {
		if(type === "search") {
    		e.persist();
    		this.debouncedFilter(type, e);
		}
		else {
			this.handleFilter(type, e);
		}
  	}

	handleFilter(type, event) {
		if(this.scroll) {
			this.scroll.scrollTop = 0; // Scroll list to top to prevent automatic paging
		}

		const { name, value } = event.target;
		Actions[type](value, name);
		Actions.fetchItems(DATASTORE, getSearchPayload(this.state));

		this.setState({
			searchText: type !== "search" ? "" : value,
			unselectedFilter: type !== "search" ? false : value.length > 0,
		});

		// Force lazyload to load visible images
		window.dispatchEvent(window.customLazyEvent);
	}

	loadMore() {
		const l = this.state.list[DATASTORE];
		if(l.nextPageUrl) {
			Actions.pageItems(DATASTORE, l.nextPageUrl);
		}
	}

	shouldUpdateScroll(prevRouterProps, currentRouterProps) {
		return currentRouterProps.location.action === "POP";
	}

	render() {
		const list = this.state.list[DATASTORE];
		const { isLoading, searchText: componentSearchText, filters, nextPageUrl, unselectedFilter, item } = this.state;
		const searchText = componentSearchText || filters.searchText; // TODO!: We should probably only have one searchText since this will make people go mad.

		const parentItem = item[PARENT_ENTITY];
		const title = parentItem ? `${parentItem.name || ""} / ${TEXT_HEADING}` : `${TEXT_HEADING}`;

	    return (
			<App
				name={`c6-vod-packages c6-${MODULE}-${DATASTORE} c6-list`}
				layout="grid"
				isLoading={!!isLoading}>
				<Header title={title} backToTitle="Packages" />
				<ScrollContainer scrollKey={`c6-${MODULE}-${DATASTORE}`} shouldUpdateScroll={this.shouldUpdateScroll}>
					<Main inputRef={el => this.scroll = el} padding={false}>
						<List
							items={list.items}
							isLoading={isLoading}
							searchText={searchText}
							filters={filters}
							hasMore={!!list.nextPageUrl}
							loadMore={this.loadMore}
						/>
					</Main>
				</ScrollContainer>
				<Info>
					{getInfo(this.props.location, list)}
				</Info>
			</App>
		);
	}
}

// HELPERS
function getInfo(location, list) {
	const items = list.items.length ? `- displaying ${list.items.length} of ${list.numberOfItems}` : "";

	return `${getRouteName(location)} ${items}`;
}
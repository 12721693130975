import React from 'react'

import { getIconForStyle } from '../utils_new'

import './checkbox.css'

const Checkbox = (props) => {
	const {
		className,
		onChange,
		displayName,
		name,
		readOnly,
		style,
		value,
	} = props;

	const {
		visible = true, // Handle style.visible in item.jsx instead of per component?
	} = style;
	
	return (
		<div
			className={`${className} c6-cms-checkbox checkbox-${name} ${value ? "enabled" : ""} ${visible ? "" : "hidden"}`}
			onClick={readOnly ? null : () => onChange(!value)}
		>
			<label>
				<span className="enabled-icon icon-check"></span>
				{displayName}
				{style && getIconForStyle(style, value)}
			</label>
		</div>
	);
};

// React.memo everything? Hmm...
export default React.memo(Checkbox);
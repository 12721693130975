import * as ReviewsAPI from '../../../apis/reviews'
import { useQuery } from '@tanstack/react-query'

interface args {
    filter?: object;
    enabled?: boolean;
}

const useReviewsCustomers = ({ filter = {}, enabled = true }: args = {}) => {
    const filters = {
        ...filter,
    };
    return useQuery(
        ["reviews", "customers", filters],
        () => ReviewsAPI.fetchCustomers(filters),
        { enabled }
    );
};

export default useReviewsCustomers;

import React from 'react'
import Editor from '../../../components/editor/'

import * as AcqAPI from '../../../apis/acq'
import { hasRole } from '../../../core/services/auth'

import { DownloadRightsAttributes } from '../shared/utils'
import schema from './schema'
import ui from './ui'

import appConfig from 'config'


export default props => (
	<Editor
		layout="grid"
		api="acq"
		entity="contract"
		getSchema={() => schema}
		getUiSchema={getUiSchema}
		getCommands={getCommands}
		savePayloadTransform={savePayloadTransform}
		{...props}
	/>
);

const getCommands = () => ({
	fetchItem: ({id}) => AcqAPI.fetchContract(id),
	updateItem: ({id}, payload) => AcqAPI.updateContract(id, payload),
});

function getUiSchema(model, isNew, location, route, params, routes) {
	ui.name["ui:readonly"] = !hasRole("acq.downloadrightseditor");
	ui.adsAllowed["ui:widget"] = appConfig.features.acqAdsAllowed ? "checkbox" : "hidden";
	return ui;
}

function savePayloadTransform({ formData, entity, location, route, ...rest }) {
	const { ...payload } = formData;

	const downloadRights = DownloadRightsAttributes.reduce((acc, attribute) => {
		if (payload.hasOwnProperty(attribute.key)) {
			acc[attribute.key] = payload[attribute.key] === 0 ? -2147483648 : payload[attribute.key];
		}
		return acc;
	}, {});

	return {
		...rest,
		formData: {
			...payload,
			...downloadRights,
		},
		entity,
		location
	};
}
import React from 'react'

import EventPlannerComponent from '../component'

const Header = ({
	className,
	value,
	children,
	onChange,
	_onChangeWithoutAddedPath,
	path = [],
	otherProps = {},
	itemData = {},
	matchCustomComponent = null,
	name,
}) => {
	return (
		<div className={className}>
			<h1>{value?.length ? value : <span>&nbsp;</span>}</h1>
			{children?.map(c => (
				<EventPlannerComponent
					key={c.name}
					templateItem={c}
					onChange={onChange}
					_onChangeWithoutAddedPath={_onChangeWithoutAddedPath}
					path={[...path, name]}
					otherProps={otherProps}
					itemData={itemData}
					matchCustomComponent={matchCustomComponent}
				/>
			))}
		</div>
	);
}

export default Header;
import React from 'react'

import userSchema from './userSchema'
import userUI from './userUI'
import apikeySchema from './apikeySchema'
import apikeyUI from './apikeyUI'

import Editor from '../../../components/editor/'
import { generateKey } from '../../../components/ui/editorFields/index'

export default props => (
	<Editor
		api="shield"
		entity="login"
		layout="grid"
		getSchema={getSchema}
		getUiSchema={getUiSchema}
		loadPayloadTransform={loadPayloadTransform}
		savePayloadTransform={savePayloadTransform}
		enableEditorNavigation={true}
		{...props}
	/>
);

function getSchema(model, isNew, location) {
	let schema;
	const type = isNew ? location.query.type : model.type;
	switch ((type ?? "user").toLowerCase()) {
		case "apikey":
			schema = apikeySchema;
			// Generate a GUID as default API key
			if (isNew && !model.name) {
				model.name = generateKey().toUpperCase();
			}
			break;
		case "user":
		default:
			schema = userSchema;
			break;
	}

	return schema;
}

function getUiSchema(model, isNew, location, route) {
	let ui;

	const createCopy = route.path.includes("createcopy");

	const type = isNew ? location.query.type : model.type;
	switch ((type ?? "user").toLowerCase()) {
		case "apikey":
			ui = apikeyUI;
			break;
		case "user":
		default:
			ui = userUI;
			ui.password["ui:options"].hideValueBehindLock = !isNew;
			break;
	}

	ui.name["ui:readonly"] = !isNew && !createCopy;
	return ui;
}

function loadPayloadTransform({ model, entity, location, route }) {
	const createCopy = route.path.includes("createcopy");

	if (createCopy) {
		model.email = "";
		model.name = "";
		model.lastName += " (Copy)";
	}

	return model;
}

function savePayloadTransform({ formData, entity, location, route, ...rest }) {
	const saveAsCopy = route.path.includes("createcopy");
	const data = { ...formData };

	const type = data.type ?? location.query.type ?? "user";

	return {
		...rest,
		formData: {
			...data,
			type,
		},
		entity,
		location,
		saveAsCopy,
	};
}
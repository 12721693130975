import React from 'react'
import Moment from 'moment'
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';

import EditorCore from '../../../core/ui/editor'
import { Period } from './editorFields'
import Metadata from './metadata'

import Store from './store'
import Actions from './actions'
import Schema from './schema'

import appConfig from 'config'

const ENTITY = "license";

export default class EditorApp extends React.Component {

	constructor(props) {
		super(props);
		this.onChange = this.onChange.bind(this);
		this.handleModelChange = this.handleModelChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleError = this.handleError.bind(this);

		this.id = this.props.params.id;
		this.contractId = this.props.location.query.contractId;

		this.state = {
			...Store.getState(),
			liveValidate: false, // Live validation will activate after first erroneous submit
		};
	}

	componentDidMount() {
		Store.listen(this.onChange);
		// Actions.fetchGenres();

		if(this.id) {
			Actions.fetchLicense(this.id);
		}
	}

	componentWillUnmount() {
		Actions.unmount();
		Store.unlisten(this.onChange);
	}

	onChange(state) {
		this.setState(state);
	}

	handleModelChange(data) {
		Actions.modelChanged(data.formData);
	}

	handleSubmit(data) {
		const { location } = this.props;
		const { program, ...license } = data.formData;
		const isCreateAction = !license.id;

		// Create the program first, then create/update the license
		if(program && program.id === -1) {
			// HACK: This should be handled by altJS's async datasources and not with multiple fat Actions. :(

			// Series should not have licenses, just create the series program
			// if(program.type === 4) {
			// 	Actions.createProgram({
			// 		program,
			// 		contractId: this.contractId,
			// 		returnTo: this.props.location.state.returnTo
			// 	});
			// }
			if(isCreateAction) {
				Actions.createProgramAndCreateLicense({
					location,
					entity: ENTITY,
					program,
					payload: addEditorData(license, isCreateAction, this.contractId),
					returnTo: location.state.returnTo,
				});
			}
			else {
				Actions.createProgramAndUpdateLicense({
					location,
					entity: ENTITY,
					program,
					id: license.id,
					payload: addEditorData(license),
				});
			}

		}
		else {
			if(isCreateAction) {
				Actions.createLicense({
					location,
					entity: ENTITY,
					program,
					payload: addEditorData(license, isCreateAction, this.contractId),
					returnTo: location.state.returnTo,
				});
			}
			else {
				Actions.updateLicense({
					location,
					entity: ENTITY,
					id: license.id,
					payload: addEditorData(data.formData),
				});
			}
		}
	}

	handleError(data) {
		console.log(data);
		console.log("Error!");
		if(!this.state.liveValidate) {
			this.setState({ liveValidate: true }); // We turn on live validation after the first from submit validation error
		}
	}

	render() {
		const {
			isDirty,
			isLoading,
			model,
			programs,
			liveValidate,
		} = this.state;

		const isNew = !this.id;
		const modelLoaded = model && model.id;
		// const infoArea = !isNew && modelLoaded && model.status !== "Unspecified"
		// 	? <Metadata status={model.status} />
		// 	: null ;

		const infoArea = null;

		const { location, route } = this.props;
		const programReference = model.program && model.program.reference;
		const providerId = location.query.providerId ? parseInt(location.query.providerId) : null;

		return (
			<EditorCore
				isDirty={isDirty}
				formContext={{ programReference }}
				route={route}
				liveValidate={liveValidate}
				isModal={location.state && location.state.modal}
				renderForm={isNew || modelLoaded}
				model={getModel(model)}
				schema={getSchema(model, Schema, providerId)}
				uiSchema={getUiSchema(model, programs, providerId)}
				customFields={getFields()}
				onChange={this.handleModelChange}
				onSubmit={this.handleSubmit}
				onError={this.handleError}
				disableActions={isLoading}>
				{infoArea}
			</EditorCore>
		);
	}
}

function getModel(model) {

	// Add a default media reference to new license windows
	const reference = model && model.program && model.program.reference;
	if(reference && model.internalWindows) {
		model.internalWindows.forEach(w => w.mediaReference = w.id || w.mediaReference || w.mediaReference === "" ? w.mediaReference : reference);
	}

	return model;
}

function getSchema(model, schema, providerId) {

	// Set internal window types and start+end times based on provider
	schema = initInternalWindows(model, schema, providerId);

	const hasNewProgram = model && model.programId !== -1;
	// const isSeasonOrEpisode = model && model.program && model.program.type && ["Season","Episode",3,2].includes(model.program.type);
	const isEpisode = model && model.program && model.program.type && ["Episode",2].includes(model.program.type);


	if(hasNewProgram) { // Only render the program fields when we have a new program
		const { program, ...properties } = schema.properties;
		return  { ...schema, ...{ properties } };
	}
	// else if(isSeasonOrEpisode) {
	else if(isEpisode) {
		if(!schema.properties.program.required.includes("parentId")) {
			schema.properties.program.required.push("parentId");
		}
	}

	return schema;
}

// TODO!!!: Parsa ui-schemat efter dynamiska actionsträngar och ersätt dem med riktiga actions
// TOOD!!!: Detta för att vi ska kunna ha ui-schemas helt i JSON
// TODO!!!: Undersök "reviver"-funktionen i JSON.parse; http://ovaraksin.blogspot.se/2013/10/pass-javascript-function-via-json.html
// https://basecamp.com/1774085/projects/12460278/todos/268052481
function getUiSchema(model, dataPrograms, providerId) {

	const programsWithType = dataPrograms.map(({ id, name = "", provider = {}, type = null }) => ({
		id,
		name,
		text: name,
		value: <MenuItem><ListItemText primary={name} secondary={type} /></MenuItem>,
	}));

	const schema = {
		programId: {
			//"ui:classNames": "separator-bottom",
			"ui:placeholder": "Start typing a program title",
			"ui:widget": "autocomplete",
			"ui:readonly": model.id > 0, // Don't allow for changing the license program link
			"ui:options": {
				defaultText: model.program && model.program.name,
				dataSource: programsWithType,
				dataSourceConfig: false,
				fetch: Actions.fetchPrograms,
				select: (name, id) => { Actions.selectProgram(name, id, providerId) },
				remove: Actions.removeProgram,
				"fullWidth": true,
			},
		},

		program: {
			originalTitle: {
				"ui:classNames": "cols-2",
				"ui:widget": "text",
				"ui:options": { "fullWidth": true, }
			},

			reference: {
				"ui:classNames": "cols-2",
				"ui:widget": "text",
				"ui:options": { "fullWidth": true, }
			},

			class: {
				"ui:classNames": "space-above cols-2",
				"ui:widget": "radio",
			},

			type: {
				"ui:classNames": "space-above cols-2",
				"ui:widget": "radio",
			},

			productionYear: {
				"ui:widget": "text",
				"ui:classNames": "cols-2",
			}
		},

		internalWindows: {
			"ui:options": {
				orderable: false,
			},
			"ui:classNames": "separator",
			items: {
				"ui:classNames": "separator",
				"ui:field": "period"
			},
		}
	}

	if(model.externalWindows && model.externalWindows.length) {
		schema.externalWindows = {
			"ui:classNames": "hide-editor-actions",
			"ui:options": {
				orderable: false,
				addable: false,
				removable: false,
			},
			items: {
				"ui:field": "period"
			},
		};
	}
	else {
		schema.externalWindows = {
			"ui:classNames": "hide",
			items: {
				"ui:widget": "hidden"
			}
		};
	}

	const isEpisode = model.program && model.program.type === 2;
	const isSeason = model.program && model.program.type === 3;

	// Episode
	if(isEpisode) {
		schema.program.episodeNumber = {
			"ui:classNames": "cols-2",
			"ui:placeholder": "Episode number",
			"ui:widget": "text",
		};
	}
	else {
		schema.program.episodeNumber = { "ui:widget": "hidden" };
	}

	// Season
	if(isSeason) {
		schema.program.seasonNumber = {
			"ui:classNames": "cols-2",
			"ui:placeholder": "Season number",
			"ui:widget": "text",
		};

		schema.program.totalEpisodes = {
			"ui:classNames": "cols-2",
			"ui:placeholder": "Total number of episodes",
			"ui:widget": "text",
		};
	}
	else {
		schema.program.seasonNumber = { "ui:widget": "hidden" };
		schema.program.totalEpisodes = { "ui:widget": "hidden" };
	}

	// Episodes and Seasons
	if(isSeason || isEpisode) {
		const programTypeId = isSeason ? 4 : 3; // Search series programs when we're creating a season or seasons when we're creating an episode
		const preventAdd = true; // Don't let the user add new parent programs to the autocomplete

		schema.program.parentId = {
			"ui:placeholder": "Start typing a program title",
			"ui:widget": "autocomplete",
			"ui:options": {
				key: programTypeId,
				dataSource: programsWithType,
				dataSourceConfig: false,
				fetch: searchText => { Actions.fetchPrograms(searchText, programTypeId, preventAdd); },
				remove: Actions.removeParentProgram,
				"fullWidth": true,
				preventAdd,
			},
		};
	}
	else {
		schema.program.parentId = { "ui:widget": "hidden" };
	}

	return schema;
}

function getFields() {
	return {
		period: Period,
	}
}

function addEditorData(data, isCreateAction, contractId, providerId) {
	// NOTE: THIS IS NOW HANDLED BY config.acq.internalWindowTypes.provider.windowEndTime
	// Make sure all end dates end at 23:59:59
	// if(data.windows) {
	// 	data.windows.forEach(instance => {
	// 		instance.end = instance.end
	// 			? Moment(instance.end).format("YYYY-MM-DDT23:59:59")
	// 			: null ;
	// 	});
	// }

	// if(data.internalWindows) {
	// 	data.internalWindows.forEach(instance => {
	// 		instance.end = instance.end
	// 			? Moment(instance.end).format("YYYY-MM-DDT23:59:59")
	// 			: null ;
	// 	});
	// }

	// Set contractId if available
	if(contractId) {
		data.contract = {
			id: contractId,
		};
	}

	return {
		inputSource: "Manual",
		...data
	};
}

function initInternalWindows(model, schema, providerId) {
	// HACK: Since Octopus does not handle JSON values nicely, try parsing internalWindowTypes from a string
	let wt = appConfig.acq && appConfig.acq.internalWindowTypes || [];
	try {
		if (!Array.isArray(wt)) {
			wt = JSON.parse(wt);
		}
	} catch (e) {
		console.error("Could not parse JSON value in appConfig.acq.internalWindowTypes. Setting wt = []");
		wt = [];
	}
	
	if(wt) {
		const pid = model.contract && model.contract.provider && model.contract.provider.id || providerId;
		if(pid) {
			const items = schema.properties.internalWindows.items.properties;

			const enums = wt.filter(t => t.provider.id === pid);
			if(enums.length === 1) {

				// Set default window types
				const ref = items.type.properties.reference;
				ref.oneOf = enums[0].reference.map(r => ({
					const: r.key,
					title: r.name,
				}));

				// Set default start and end dates if available in the config
				const { windowStartTime, windowEndTime } = enums[0].provider;
				if(windowStartTime) {
					items.start.default = Moment().format(`YYYY-MM-DDT${windowStartTime}`);
				}

				if(windowEndTime) {
					const [ endTime, addValue, unit ] = windowEndTime.split("+");
					if(addValue && unit) {
						items.end.default = Moment().add(addValue, unit).format(`YYYY-MM-DDT${endTime}`);
					}
					else if(endTime) {
						items.end.default = Moment().format(`YYYY-MM-DDT${endTime}`);
					}
				}
			}
			else {
				if(enums.length === 0) {
					console.warn("Filtering config.acq.internalWindowTypes on providerId %s returned nothing.", pid);
				}
				else {
					console.warn("Filtering config.acq.internalWindowTypes on providerId %s returned too many results.", pid);
				}
			}
		}
	}

	return schema;
}
import React from 'react'
import { Link } from 'react-router'
import { browserHistory } from 'browserHistory'

import Button from '../../../../components/ui/controls/button'
import { MuiRadio } from '../../../../components/ui/editorWidgets'
import EditableText from '../../../../components/ui/controls/editableText'

import { Filter, Left, Right, Center } from '../../../../components/filter'

import { getAlbumStatus } from '../shared/utils'
import { hasStarEditAccess } from '../../shared/utils'

import Actions from '../../actions'

export default ({ title, data, location }) => {

	const isMySpace = data.id === 0;
	const disableEdit = !hasStarEditAccess() || isMySpace;

	const { albumIsShared, albumExpires, href, password } = getAlbumStatus(data);
	let albumSharedInfo = null, shareTitle = "Share this album...";
	if(albumIsShared) {
		albumSharedInfo = <span>This album is <a className="c6-link" href={href} target="_blank">shared here</a> {password ? `with the password: ` : "without any password protection."}<strong>{password}</strong> ({albumExpires.fromNow(true)} left).</span>
		shareTitle = "Sharing options...";
	}
	const createdBy = data.createdByUser ? <span style={{ marginRight: "5px" }}>Created by {data.createdByUser}.</span> : null;
	const albumInfo = <p>{createdBy}{albumSharedInfo}</p>;


	const editableTitle = (
		<EditableText
			onChange={displayName => { Actions.updateAlbum(data.id, { displayName });}}
			hintText="Please type a folder name"
			disableEdit={disableEdit} >
			{title}
		</EditableText>
	);

	return (
		<Filter>
			<Left>
				<h1>
					<Link to="/star/albums" onClick={handleClick.bind(this, location)}>Albums</Link> / {editableTitle}
				</h1>
				{albumInfo}
			</Left>
			<Center>
				{!isMySpace && <MuiRadio
					inline
					readonly={disableEdit}
					options={getAlbumAudienceOptions()}
					onChange={audience => { Actions.updateAlbum(data.id, { audience }); }}
					value={data.audience} />}
			</Center>
			<Right>
				{!isMySpace && <Button
					disabled={disableEdit}
					type="sharewithpeople"
					title={shareTitle}
					onClick={() => { Actions.openDialog("share"); }}
				/>}
			</Right>
		</Filter>
	);
};

// HELPERS
// We need to go back instead of doing a normal navigation to allow for
// react-router-scroll to restore the scroll position of the library list.
function handleClick(location, e) {
	if(location && location.state && location.state.returnTo) {
		e.preventDefault();
		browserHistory.goBack();
	}
}

function getAlbumAudienceOptions() {
	return {
		dataSource: [
			{ value: "Private", label: "Only visible to you" },
			{ value: "Admin", label: "Visible to editors in STAR" },
			{ value: "All", label: "Visible to everyone in STAR" },
		]
	}
}
import React from 'react'
import moment from 'moment'
import cloneDeep from 'lodash/cloneDeep'

import Editor, { parseUi, withQueryClient } from '../../../components/editor/'
import { hasAccessToPath } from '../../../core/services/auth'
import Approval from '../../../components/ui/editorFields/approval'
import Crew from '../../../components/ui/editorFields/crew'

import ProgramPreview from '../shared/preview'
import SynopsisEmbargoController from './synopsisEmbargoController'
import TranslationAPIController from './translationAPIController'
import { ChatGPTSynopsisMetadataController } from '../shared/chatGPTController'

import * as API from '../../../apis/'
import appConfig from 'config'

import schemaSingle from './singleSchema'
import schemaEpisode from './episodeSchema'
import schemaSeason from './seasonSchema'
import schemaSeries from './seriesSchema'

import uiSingle from './singleUI'
import uiEpisode from './episodeUI'
import uiSeason from './seasonUI'
import uiSeries from './seriesUI'

import {
	getTranslateSchema,
	getTranslateUiSchema,
	getTranslatePatchData,
	getTranslateModel,
	getFilteredVersions,
	setWideModalForTranslationEditors,
} from './translateUtils'
import { displayAlert } from '../../../core/services/alert'
import MetadataModule from '../module'
import { enableChatGPTForUser } from '../shared/utils'

const EMBARGO_DATE_STORAGE_KEY = "c6-synopsis-embargo-program-date";
let embargoAttributes;
let isEmbargoData = false;

const LocaleEditor = props => {
	return (
		<Editor
			layout="grid"
			submitAsPatch={true}
			api="metadata"
			entity="program"
			className="c6-metadata-localeeditor"
			getSchema={getSchema}
			getUiSchema={getUiSchema}
			getValidation={getValidation}
			customFields={{
				approval: Approval,
				crew: Crew,
				embargoDate: SynopsisEmbargoController,
				apiTranslation: TranslationAPIController,
				chatGPTSynopsisMetadataController: ChatGPTSynopsisMetadataController,
			}}
			loadPayloadTransform={loadPayloadTransform}
			savePayloadTransform={savePayloadTransform}
			changePayloadTransform={changePayloadTransform}
			hasEditAccess={hasAccessToPath(props.routes, "editor", props.params.versionId)}
			getCommands={getCommands}
			enableEditorNavigation={true}
			{...props}
		>
			<ProgramPreview />
		</Editor>
	);
};

export default withQueryClient(LocaleEditor);

function getCommands() {
	return {
		fetchItem: async (...args) => {
			await MetadataModule.initialSetup();

			if (appConfig.features.metadataEnableSynopsisEmbargo) {
				return new Promise((resolve) => {
					const attributesRequest = API.metadata.fetchEmbargoAttributes();
					const programRequest = API.metadata.fetchProgramEmbargo(...args);
					Promise.all([attributesRequest, programRequest])
						.then(([attributes, program]) => {
							embargoAttributes = attributes.items.map(a => a.name);
							resolve(program);
						});
				});
			}

			return API.metadata.fetchProgram(...args);
		},
		updateItem: (...args) => {
			if (isEmbargoData) {
				return API.metadata.patchProgramEmbargo(...args);
			}
			
			return API.metadata.patchProgram(...args);
		},
	};
}

function getValidation(params, location, formData, addError) {
	// Local series and single program titles needs to be available for approval /HEL
	if (formData.type === "Series" || formData.type === "Single") {
		// Translation editor
		const filteredVersions = getFilteredVersions(formData, params, location);
		if (filteredVersions.length > 1 && !(params.catalogue && params.catalogue.startsWith("mtv"))) {
			const [ { title: translateTitle }, isTranslationApproved ] = getTranslationLocale(formData); // Needed because of the hacky tranlation formData object
			validateTitleRequiredWhenApproved(isTranslationApproved, translateTitle, addError, "title-1");
		}
		// Regular locale editor
		else {
			const [ { title }, isApproved ] = getLocale(formData, params.versionId);
			validateTitleRequiredWhenApproved(isApproved, title, addError, "versions[0].title");
		}
	}
}

function validateTitleRequiredWhenApproved(approved, title, addError, field) {
	if (approved && (!title || title.trim() === "")) {
		addError(field, "Local title is required for approval!");
	}
}

function getSchema(model, isNew, location, route, params, routes) {
	// const [ localeVersion, isApproved ] = getLocale(model, params.versionId);
	if (appConfig.features.metadataEnableSynopsisEmbargo && embargoAttributes === undefined) {
		return { type: "object", properties: {} };
	}

	let schema;
	switch (model.type) {
		case "Series":
			schema = cloneDeep(schemaSeries);
			break;
		case "Season":
			schema = cloneDeep(schemaSeason);
			break;
		case "Episode":
			schema = cloneDeep(schemaEpisode);
			break;
		default:
			schema = cloneDeep(schemaSingle);
			break;
	}

	// Special for MTV: Only show medium synopsis
	// HACK: This needs to become a separate MTV JSON Schema
	if (appConfig.features && appConfig.features.vodServiceName === "MTV") {
		delete schema.properties.versions.items.properties.shortSynopsis;
		delete schema.properties.versions.items.properties.briefSynopsis;
		delete schema.properties.versions.items.properties.longSynopsis;
		delete schema.properties.versions.items.properties.extendedSynopsis;
	}

	if (
		!appConfig.features.metadataEnableLocalAgeRatingInHayuNorwegianEditor
		|| params.versionId !== "2"
		|| model.provider?.name?.toLowerCase() !== "hayu"
	) {
		delete schema.properties.versions.items.properties.localRatingAge;
	}

	// Translate editor
	const filteredVersions = getFilteredVersions(model, params, location);
	if (filteredVersions.length > 1) {
		if (schema.properties.versions?.items?.properties?._chatGPTSynopsisMetadataController) {
			delete schema.properties.versions.items.properties._chatGPTSynopsisMetadataController;
		}
		const translateSchema = getTranslateSchema(schema, params, model);
		if (appConfig.features.metadataCognitiveServicesTranslation) {
			translateSchema.properties = {
				_apiTranslation: {
					type: "object",
				},
				...translateSchema.properties,
			};
		}
		return translateSchema;
	}

	if (appConfig.features.metadataEnableSynopsisEmbargo) {
		schema.properties = {
			_embargoDate: {
				type: "object",
				title: "Embargo date",
				default: {
					current: "now",
					all: [],
				},
			},
			...schema.properties,
		};
	}
	
	if (schema.properties.versions?.items?.properties?._chatGPTSynopsisMetadataController && !enableChatGPTForUser()) {
		delete schema.properties.versions.items.properties._chatGPTSynopsisMetadataController;
	}

	return schema;
	// return parseSchema(schema, model, { isApproved });
}

function getUiSchema(model, isNew, location, route, params, routes) {
	const { versionId = null, catalogue } = params;
	const [ localeVersion, localeIsApproved ] = getLocale(model, versionId);

	let ui;
	switch (model.type) {
		case "Series":
			ui = cloneDeep(uiSeries);
			break;
		case "Season":
			ui = cloneDeep(uiSeason);
			break;
		case "Episode":
			ui = cloneDeep(uiEpisode);
			break;
		default:
			ui = cloneDeep(uiSingle);
			break;
	}

	// If the EPG title is empty, let the user edit it anyway (it's only available for Single and Episode programs) /HEL
	if ((model.type === "Single" || model.type === "Episode") && localeVersion) {
		ui.versions.items.epgTitle["ui:readonly"] = !localeVersion.length && !!localeVersion.epgTitle || localeVersion.length && !!localeVersion[0].epgTitle;
	}

	// Only show press synopsis in Finnish editor for singles & episodes (Bonnier Comet)
	if (["Single", "Episode"].includes(model.type) && ["cmore", "tv4", "mtvfi"].includes(catalogue) && versionId == 4 && ui.versions.items.pressSynopsis) {
		ui.versions.items.pressSynopsis["ui:widget"] = "textarea";
	} else if (appConfig.features.metadataPressSynopsisWYSIWYG && ui.versions.items.pressSynopsis) {
		ui.versions.items.pressSynopsis["ui:widget"] = "wysiwyg";
	} else if (ui.versions.items.pressSynopsis) {
		ui.versions.items.pressSynopsis["ui:widget"] = "hidden";
	}

	// Only show EPG title for configured catalogues
	const displayPressTitleForCatalogue = appConfig.features.metadataDisplayPressTitleForCatalogues?.includes(catalogue);
	if (!displayPressTitleForCatalogue && ui.versions.items.epgTitle) {
		ui.versions.items.epgTitle["ui:widget"] = "hidden";
	}

	// Translation editor
	const filteredVersions = getFilteredVersions(model, params, location);
	if (filteredVersions.length > 1) {
		ui = getTranslateUiSchema(ui, model, routes, versionId, params);
	}

	if (appConfig.features.metadataEnableSynopsisEmbargo) {
		ui._embargoDate = { "ui:field": "embargoDate", "ui:classNames": "grid-100 synopsis-embargo" };
		const now = moment();
		const isDefaultEmbargoVersion = model?._embargoDate?.current === "default"
			|| model?._embargoDate?.current === "now"
			|| moment(model?._embargoDate?.current).isSameOrBefore(now);

		if (!isDefaultEmbargoVersion) {
			Object.entries(ui.versions?.items ?? {}).forEach(([key, property]) => {
				const isEmbargo = embargoAttributes?.some(a => a.toLowerCase() === key.toLowerCase());
				if (!isEmbargo && !isDefaultEmbargoVersion && !property["ui:readonly"]) {
					property["ui:readonly"] = true;
					property["ui:hoverTitle"] = "This field does not support scheduling, please edit the default/original version instead.";
				}
			});
		}
	}
	if (appConfig.features.metadataCognitiveServicesTranslation) {
		ui._apiTranslation = { "ui:field": "apiTranslation", "ui:classNames": "api-translation" };
	}

	if (enableChatGPTForUser() && filteredVersions.length === 1) {
		ui.versions.items._chatGPTSynopsisMetadataController["ui:field"] = "chatGPTSynopsisMetadataController";
	}

	return {
		"ui:readonly": localeIsApproved,
		...parseUi(ui, API),
	};
}

// Only display the original locale and the selected locale version
function loadPayloadTransform({ model, location, params }) {
	let versions = getFilteredVersions(model, params, location);

	setWideModalForTranslationEditors(params);
	
	if (appConfig.features.metadataEditorWarningMessageOnOpen?.length) {
		displayAlert("warning", null, null, null, null, appConfig.features.metadataEditorWarningMessageOnOpen);
	}
	const _embargoDate = { ...(model._embargoDate ?? {}) };

	if (appConfig.features.metadataEnableSynopsisEmbargo) {
		let when = model._embargoDate?.current ?? "now";
		const savedEmbargoProgramDate = sessionStorage.getItem(EMBARGO_DATE_STORAGE_KEY);
		if (savedEmbargoProgramDate) {
			const [programId, date] = savedEmbargoProgramDate.split("|");
			if (Number(programId) === Number(model.id)) {
				when = date;
			}

			sessionStorage.removeItem(EMBARGO_DATE_STORAGE_KEY);
		}

		versions = versions.map(v => transformEmbargo(v, when, null));

		const embargoDates = getEmbargoDates(versions);
		versions.forEach(v => addMissingDates(v, embargoDates));
		_embargoDate.all = embargoDates;
		_embargoDate.current = when;
	}

	if (versions.length > 1) {
		model = getTranslateModel(versions, model);

		const originalIsApproved = model["approval-0"]?.status?.toLowerCase() === "approved";
		if (!originalIsApproved) {
			displayAlert("warning", "Please note that the original language version has not been approved yet.");
		}
	}

	return {
		...model,
		versions,
		_embargoDate,
	};
}

function changePayloadTransform({ formData, changeFieldId, location, params }) {
	let versions = getFilteredVersions(formData, params, location);

	const changeWasEmbargoDate = changeFieldId === "root__embargoDate";
	const _embargoDate = { ...formData._embargoDate ?? {} };

	const changeWasTranslationFromAPI = changeFieldId === "root__apiTranslation";
	const _apiTranslation = { ...formData._apiTranslation ?? {} };

	const changeWasChatGPT = changeFieldId?.includes("_chatGPTSynopsisMetadataController");
	const _chatGPTSynopsisMetadataController = { ...formData.versions[0]._chatGPTSynopsisMetadataController ?? {} };

	if (appConfig.features.metadataEnableSynopsisEmbargo) {
		const embargoDates = getEmbargoDates(versions);

		// If reschedule is set, we will update any existing embargo values where publishFrom matches "current"
		// And then reset reschedule
		if (changeWasEmbargoDate && _embargoDate.reschedule && _embargoDate?.current) {
			updateEmbargoDate(versions[0], _embargoDate.current, _embargoDate.reschedule);
			_embargoDate.current = _embargoDate.reschedule;
			delete _embargoDate.reschedule;
		}

		// If delete is set, we will delete any embargo values where publishFrom matches "current"
		// And then reset delete
		else if (changeWasEmbargoDate && _embargoDate.delete && _embargoDate?.current) {
			removeEmbargoDate(versions[0], _embargoDate.current);
			const index = _embargoDate.all.findIndex(d => d !== "default" && moment(d).isSame(_embargoDate.current));
			_embargoDate.current = _embargoDate.all[index - 1] ?? "default";
			delete _embargoDate.delete;
		}

		// If reschedule and delete were not set, and the value of current is a new value, we will create a copy of the latest version
		else if (changeWasEmbargoDate && !embargoDates.includes(_embargoDate?.current)) {
			addNewEmbargoDate(versions[0], _embargoDate?.current);
		}

		versions = versions.map(v => transformEmbargo(v, _embargoDate.current, changeWasEmbargoDate));
		_embargoDate.all = getEmbargoDates(versions);
		versions.forEach(v => addMissingDates(v, _embargoDate.all));
	}
	
	if (appConfig.features.metadataCognitiveServicesTranslation && changeWasTranslationFromAPI) {
		const fieldsToUpdate = _apiTranslation.fieldsToUpdate;
		fieldsToUpdate.forEach(field => {
			// Only update formData[field.key] if that key exists in the target object
			// Otherwise we might save a longSynopsis in an editor which doesn't show longSynopsis
			if (formData.hasOwnProperty(field.key)) {
				formData[field.key] = field.value;
			}
		});
		delete _apiTranslation.fieldsToUpdate;
	}

	if (changeFieldId?.includes("versionName") && versions.length > 1) {
		const changeWasSourceLanguage = true;
		formData = getTranslateModel(versions, formData, changeWasSourceLanguage);
	}

	if (appConfig.features.metadataChatGPT && changeWasChatGPT) {
		const fieldsToUpdate = _chatGPTSynopsisMetadataController.fieldsToUpdate;
		Object.entries(fieldsToUpdate).forEach(([key, value]) => {
			versions[0][key] = value;
		});
		delete _chatGPTSynopsisMetadataController.fieldsToUpdate;
		if (appConfig.features.metadataEnableSynopsisEmbargo) {
			versions = versions.map(v => transformEmbargo(v, _embargoDate.current, changeWasEmbargoDate));
		}
	}

	return {
		...formData,
		versions,
		_embargoDate,
		_apiTranslation,
		_chatGPTSynopsisMetadataController,
	};
}

function savePayloadTransform({ formData, patchData, entity, location, routes, params }) {
	const { versionId = null } = params;

	const filteredVersions = getFilteredVersions(formData, params, location);
	if (filteredVersions.length > 1) {
		patchData = getTranslatePatchData(patchData, parseInt(versionId));
	}

	if (appConfig.features.metadataEnableSynopsisEmbargo && patchData.versions) {
		isEmbargoData = true;
		patchData.versions.forEach(v => {
			Object.keys(v).forEach(key => {
				if (key.startsWith("_embargo_")) {
					const keyWithoutEmbargo = key.replace("_embargo_", "");
					v[keyWithoutEmbargo] = v[key];
					delete v[key];
				}
			});
		});

		// Store the current date in sessionStorage so that we can read it again in loadPayloadTransform
		// after the editor has been saved.
		// Otherwise, the user will always be sent back to the default version after saving.
		sessionStorage.setItem(EMBARGO_DATE_STORAGE_KEY, `${formData.id}|${formData._embargoDate.current}`);
	}
	
	isEmbargoData = appConfig.features.metadataEnableSynopsisEmbargo && filteredVersions.length === 1;

	return {
		formData,
		patchData,
		entity,
		location,
		routes,
		params,
	};
}

function getLocale(model, versionId = null) {
	const localeVersion = model.versions && model.versions.find(v => v.versionId === parseInt(versionId));
	const localeIsApproved = localeVersion && localeVersion.approval && localeVersion.approval.status && localeVersion.approval.status.toLowerCase() === "approved";

	return [ localeVersion, localeIsApproved ];
}

function getTranslationLocale(model) {
	return [ { title: model["title-1"] }, model["approval-1"].status && model["approval-1"].status.toLowerCase() === "approved" ];
}

function transformEmbargo(version = {}, when, changeWasEmbargoDate) {
	const transformed = { ...version };
	const now = moment();

	Object.keys(version).filter(key => !key.startsWith("_embargo_")).forEach(key => {
		let values = version[`_embargo_${key}`] ?? version[key] ?? [];
		const isEmbargo = embargoAttributes?.some(a => a.toLowerCase() === key.toLowerCase());
		if (!isEmbargo) {
			return;
		}

		if (!Array.isArray(values)) {
			values = [{ value: values }];
		}
		if (!values.find(v => !v.publishFrom)) {
			values.push({ publishFrom: null });
		}

		const sortedDesc = values.sort((a, b) => {
			if (!b.publishFrom) {
				return -1;
			}
			if (!a.publishFrom) {
				return 1;
			}
			return moment(b.publishFrom).diff(a.publishFrom);
		});
		let whenMoment;
		if (when === "now") {
			whenMoment = now;
		} else if (when === "default") {
			whenMoment = moment("1970-01-01");
		} else {
			whenMoment = moment(when);
		}
		const activeObject = sortedDesc.find((attribute) => {
			return moment(attribute.publishFrom ?? now).isSameOrBefore(whenMoment);
		}) ?? sortedDesc[sortedDesc.length - 1];

		const value = version[key];
		if (!Array.isArray(value) && activeObject && !changeWasEmbargoDate) {
			activeObject.value = value;
		}
		transformed[key] = activeObject?.value;
		transformed[`_embargo_${key}`] = values;
	});

	return transformed;
}

function updateEmbargoDate(version = {}, oldDate, newDate) {
	embargoAttributes.forEach(key => {
		const values = version[`_embargo_${lowerCaseFirst(key)}`] ?? [];
		values.forEach(v => {
			if (moment(v.publishFrom).isSame(oldDate)) {
				v.publishFrom = newDate;
			}
		});
	});
}

function getEmbargoDates(versions = []) {
	let embargoDates = [
		"default",
	];

	versions.forEach(version => {
		Object.keys(version).filter(key => key.startsWith("_embargo")).forEach(key => {
			const values = version[key] ?? [];
			values.forEach(v => {
				let date = "default";

				if (v.publishFrom) {
					if (!embargoDates.find(d => d !== "default" && moment(d).isSame(v.publishFrom))) {
						embargoDates.push(v.publishFrom);
						return;
					}
				}
				
				if (!embargoDates.includes(date)) {
					embargoDates.push(date);
				}
			});
		});
	});

	return embargoDates;
}


function addNewEmbargoDate(version, newDate) {
	const newMoment = moment(newDate);
	embargoAttributes.forEach(key => {
		const values = version[`_embargo_${lowerCaseFirst(key)}`] ?? [];
		const sortedDesc = values.sort((a, b) => moment(b.publishFrom).diff(a.publishFrom));
		const activeObject = sortedDesc.find((o) => {
			return moment(o.publishFrom ?? moment()).isSameOrBefore(newMoment);
		}) ?? sortedDesc[sortedDesc.length - 1];

		const newObject = {
			value: activeObject?.value,
			publishFrom: newDate,
		};
		values.push(newObject);
	});
}

const lowerCaseFirst = (str) => str.substring(0, 1).toLowerCase() + str.substring(1);


function removeEmbargoDate(version, date) {
	embargoAttributes.forEach(key => {
		const keyWithEmbargo = `_embargo_${lowerCaseFirst(key)}`;
		version[keyWithEmbargo] = version[keyWithEmbargo]?.filter(v => !moment(v.publishFrom).isSame(date)) ?? [];
	});
}

function addMissingDates(version, embargoDates) {
	embargoAttributes.forEach(key => {
		if (!version[`_embargo_${lowerCaseFirst(key)}`]) {
			version[`_embargo_${lowerCaseFirst(key)}`] = [];
		}
		const values = version[`_embargo_${lowerCaseFirst(key)}`];
		embargoDates.forEach(date => {
			if (date === "default") {
				return;
			}

			const existingObject = values.find(v => v.publishFrom && moment(v.publishFrom).isSame(date));
			if (!existingObject) {
				values.push({ publishFrom: date });
			}
		});
	});
}
import React, { Component } from 'react'
import PropTypes from 'prop-types'

import {Filter, Left, Right} from '../../../components/filter'
import Label from '../../../components/ui/label'

const Metadata = ({ status }) => {

	const statusLabel = status ? getStatusLabel(status) : null;

	return (
		<Filter className="c6-metadata">
			<Right>License status {statusLabel}</Right>
		</Filter>
	);
};

Metadata.propTypes = {
	status: PropTypes.string,
};

export default Metadata;

// HELPERS
function getStatusLabel(status) {
	let type = null;
	switch(status) {
		case "OnHold":
			type = "inactive";
			break;
		case "Cancelled":
			type = "inactive";
			break;
		case "Pending":
			type = "pending";
			break;
		case "ToBeConfirmed":
			type = "tobeconfirmed";
			break;
		case "Confirmed":
			type = "confirmed";
			break;
		default:
			return null;
	}

	return type ? <Label status={type} size="large">{status}</Label> : null;
}
import { browserHistory } from 'browserHistory'

import { CONTENT, MENU } from '../../../components/actions'

export default {
	targetComponent: "Content",
	actions: [
		{
			title: "Edit",
			icon: "create",
			type: CONTENT,
			method: (data, sourceProps) => {
				browserHistory.push({
					pathname: `/reviews/sources/${data.actionData.id}/edit`,
					state: {
						modal: true,
						returnTo: `${sourceProps.location.pathname}${sourceProps.location.search}`,
					},
				});
			},
			hidden: (data, sourceProps) => false,
			disabled: (data, sourceProps) => false,
		},
	]
}
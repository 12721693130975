import * as CMSAPI from '../../../apis/cms'
import { useQuery } from '@tanstack/react-query'

const providerFilters = {
	"mtv": "(eventType == 'sport' or eventType == null) and platform == 'mtv'",
	"default": "(eventType == 'sport' or eventType == null) and (platform==null or platform=='tv4')"
};

const useCMSSportSeries = (
    {
        provider = "default"
    } = {}
) => {
    const filters = {
        _entity: "serieslist",
        _customFilter: providerFilters[provider] ?? providerFilters.default,
        // _customFilter: "(eventType == 'sport' or eventType == null) and (hasActiveEvents == null or hasActiveEvents == true)",
        pageSize: 500,
    };

    return useQuery(
        ["cms", "series", provider],
        () => CMSAPI.fetchCMSEntities(filters),
    );
};

export default useCMSSportSeries;
import React, { Component } from 'react'
import { withRouter } from 'react-router'
import { browserHistory } from 'browserHistory'

import { decoratorUIActions } from '../../../core/decorators/uiActions'
import App from '../../../components/app'
import Button from '../../../components/ui/controls/button'

import Items from '../shared/items'
import FloatActions from '../shared/floatactions'
import { hasStarEditAccess } from '../shared/utils'

import Actions from '../actions'
import Store from '../store'

import './app.css'

import itemActions from './itemActions'

const ENTITY = "searchItems";
const DATASTORE = "trashed";

@withRouter
@decoratorUIActions(itemActions)
export default class StarTrashcan extends Component {
    
    state = {
        ...Store.getState(),
    }

    componentDidMount() {
        Store.listen(this.onChange);

        Actions.fetchItems(ENTITY, this.state.list.trashed.filters, DATASTORE);
    }

    componentWillUnmount() {
        Store.unlisten(this.onChange);
    }

    onChange = (state) => {
        this.setState(state);
    }

    handleLoadMore = () => {
        const nextPageUrl = this.state.list.trashed.nextPageUrl;
        if (nextPageUrl) {
            Actions.pageItems(ENTITY, nextPageUrl, DATASTORE);
        }
    }

    render() {
        const { trashed, selectedTrashed } = this.state.list;
        return (
            <App name="c6-star c6-star-trashcan" isLoading={trashed.isLoading}>
                <Items
                    list={trashed}
                    selected={selectedTrashed}
                    loadMore={this.handleLoadMore}
                    hideRecentSearches
                />
                <FloatActions selectedItems={selectedTrashed.items}>
                    <Button
                        disabled={!hasStarEditAccess()}
                        type="delete_forever"
                        title="Delete (forever)"
                        confirm="Are you sure you want to delete these items?"
                        dangerous={true}
                        onClick={() => Actions.deleteItems(selectedTrashed.items, "trashed")}
                        noBackground
                    />
                    <Button
                        disabled={!hasStarEditAccess()}
                        type="undo"
                        title="Untrash"
                        onClick={() => Actions.untrashItems(selectedTrashed.items, "trashed")}
                        noBackground
                    />
                </FloatActions>
            </App>
        );
	}
}
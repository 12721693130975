import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Button from '@mui/material/Button'

import { CONTAINER_TYPE_OPTIONS } from '../shared/utils'

import Actions from '../actions'

@withRouter
export default class Create extends React.Component {
	static propTypes = {
		visible: PropTypes.bool,
		location: PropTypes.object, // from  @withRouter
	}

	buttonRef = null;
	state = {
		menuOpen: false,
	};


	handleClick(event, containerType) {
		this.setState({ menuOpen: false });
		event.preventDefault();
		Actions.create({
			id: -1,
			pathname: `/star/container/create`,
			returnTo: `${location.pathname}${location.search}`,
			query: {
				containerType,
			},
		});
	}

	render() {
		const { visible = false } = this.props;
		if(!visible) {
			return null;
		}

		const options = CONTAINER_TYPE_OPTIONS.filter(option => option.parentContainerTypeId === null || option.parentContainerTypeId === -1);

		return (
			<React.Fragment>
				<Button
					type="button"
					variant="text"
					onClick={() => this.setState({ menuOpen: true })}
					startIcon={<span className="icon-add_circle_outline"></span>}
					color="primary"
					sx={{
						color: "white",
						"&:hover": {
							color: "var(--action-hl-color)",
						}
					}}
					ref={ref => this.buttonRef = ref}
				>
					New folder
				</Button>
				<Menu
					anchorEl={this.buttonRef}
					open={this.state.menuOpen}
					onClose={() => this.setState({ menuOpen: false })}
				>
					{options.map(o => (
						<MenuItem
							key={o.key}
							onClick={(e) => this.handleClick(e, o.name)}
						>
							{o.text}
						</MenuItem>
					))}
				</Menu>
			</React.Fragment>
		);
	}
}

import * as SportAPI from '../../../apis/sport'
import { useQuery } from '@tanstack/react-query'

const useSportGenres = (enabled: boolean = true) => {
    const filters = {
        orderBy: "unspecified-first-then-by-title",
        pageSize: 1000,
    };
    return useQuery(
        ["sport", "genres", filters],
        () => SportAPI.fetchGenres(filters),
        { enabled }
    );
};

export default useSportGenres;
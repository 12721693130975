import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Moment from 'moment'
import groupBy from 'lodash/groupBy'
import forEach from 'lodash/forEach'
import InfiniteScroll from 'react-infinite-scroller'

import { decoratorUIActions } from '../../../core/decorators/uiActions'

import Empty from '../../../components/list/empty'

import Item from './listItem'
import SubItem from './subListItem'
import contentActions from './listItemActions'

@decoratorUIActions(contentActions)
export default class List extends Component {

	static propTypes = {
		items: PropTypes.array.isRequired,
		isLoading: PropTypes.bool.isRequired,
		searchText: PropTypes.string,
		filters: PropTypes.object,
		textEmpty: PropTypes.string,
	}

	constructor(props) {
		super(props);
	}

	render() {
		const {
			parent = {},
			items,
			isLoading,
			searchText = "",
			filters,
			textEmpty = "Sorry, could not find any items.",
			hasMore,
			loadMore,
		} = this.props;

		const noResult = !(items && items.length);

		return noResult
			? <Empty key="empty" v2={true} isLoading={isLoading}>{textEmpty}</Empty>
			: (
				<InfiniteScroll
					loadMore={loadMore}
					hasMore={hasMore}
					loader={<div key="infinite-loader" className="infinite-loader">Loading ...</div>}
					useWindow={false}
					threshold={700}
					initialLoad={false}>
					{/* {this.renderGroups(items, searchText, filters, parent)} */}
					{this.renderItems(items, filters, parent)}
				</InfiniteScroll>
			);
	}

	// renderGroups(items, searchText, filters, parent) {
	// 	if(searchText) {
	// 		return (
	// 			<ItemGroup title={`Items matching "${searchText}"`}>
	// 				{this.renderItems(items)}
	// 			</ItemGroup>
	// 		);
	// 	}

	// 	const groupedItems = groupItems(items, filters);
	// 	return Object.keys(groupedItems).map(groupKey => (
	// 		<ItemGroup
	// 			key={groupKey}
	// 			title={getGroupTitle(groupKey)}
	// 			className={getClassName(groupKey)}>
	// 				{this.renderItems(groupedItems[groupKey])}
	// 		</ItemGroup>
	// 	));
	// }

	renderItems(items, filters, parent) {
		return items.map(item => (
			<React.Fragment key={item.id}>
				<Item parent={parent} listFilters={filters} {...item} />
				<SubItem parent={parent} listFilters={filters} {...item} />
			</React.Fragment>
		));
	}
}

// HELPERS
// function groupItems(items, filters) {

// 	// Month filtering => group by date
// 	if(filters && filters.filter && filters.filter.premiere && filters.filter.premiere !== "upcoming") {
// 		return groupBy(items, item => {
// 			const firstPremiere = item.windows && item.windows.length && new Date(item.windows[0].start);
// 			return Moment(firstPremiere).format("YYYYMMDD");
// 		});
// 	}

// 	// Upcoming filtering => group dynamically
// 	return groupBy(items, item => {
// 		const firstPremiere = item.windows && item.windows.length && new Date(item.windows[0].start);

// 		const now = Moment();
// 		const prem = Moment(firstPremiere);

// 		const day = prem.format("YYYYMMDD");
// 		if(day < now.format("YYYYMMDD")) { // ALREADY PREMIERED
// 			return -1;
// 		}
// 		if(day === now.format("YYYYMMDD")) { // TODAY
// 			return 0;
// 		}
// 		if(day === now.add(1, "d").format("YYYYMMDD")) { // TOMORROW
// 			return 1;
// 		}

// 		const week = prem.format("YYYYww");
// 		if(week === now.format("YYYYww")) { // THIS WEEK
// 			return 2;
// 		}
// 		if(week === now.add(1, "w").format("YYYYww")) { // NEXT WEEK
// 			return 3;
// 		}

// 		const month = prem.format("YYYYMM");
// 		if(month === now.format("YYYYMM")) { // THIS MONTH
// 			return 4;
// 		}
// 		if(month === now.add(1, "m").format("YYYYMM")) { // NEXT MONTH
// 			return 5;
// 		}

// 		return month;
// 	});
// }

// function getGroupTitle(group) {
// 	switch(parseInt(group)) {
// 		case -1:
// 			return "Already premiered";
// 		case 0:
// 			return "Today";
// 		case 1:
// 			return "Tomorrow";
// 		case 2:
// 			return "This week";
// 		case 3:
// 			return "Next week";
// 		case 4:
// 			return "This month";
// 		case 5:
// 			return "Next month";
// 		default:
// 			return group.length === 6
// 				? Moment(`${group}01`).format("MMMM")
// 				: Moment(group).format("D MMMM") ;
// 	}
// }

// function getClassName(group) {
// 	return parseInt(group) === -1 ? "fg-red" : "";
// }
import { MENU } from '../../../components/actions'

import Actions from '../actions'

const ENTITY = "resource";

export default {
	targetComponent: "Content",
	actions: [
		{
			title: "Edit",
			icon: "create",
			method: (data, sourceProps, location) => {
				Actions.edit({
					id: data.id,
					pathname: `/shield/admin/${ENTITY}s/${data.id}/edit`,
					search: `?serviceId=${data.serviceId}`,
					returnTo: `${location.pathname}${location.search}`,
				});
			},
			type: MENU,
		},
		{
			title: "Delete",
			confirm: true,
			dangerous: true,
			icon: "delete",
			method: data => { Actions.removeItem(ENTITY, data); },
			type: MENU,
		},
	]
};
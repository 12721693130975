import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'

import { Filter, Left, Right, Group } from '../../../../components/filter'
import GroupMenuNew from '../../../../components/ui/controls/groupMenuNew'
import Switch from '../../../../components/ui/controls/switch'
import Button from '../../../../components/ui/controls/button'

import { getUnique } from '../../shared/utils'
import { syncDeepFindGroup } from '../../../../utils/groups'
import { isEmpty } from '../../../../utils/misc'
import { getUserAccessLevelForModule } from '../../../../core/services/auth'

import Actions from '../../actions'

const VERSION = "version", AUDIENCE = "audience";

@withRouter
export default class ListFilter extends React.Component {

	static propTypes = {
		conditions: PropTypes.object.isRequired,
		groups: PropTypes.array,
		selectedGroup: PropTypes.object,
	}

	handleCreate = ({ type = "lists", category = null }) => {
		const { selectedGroup, location, params } = this.props;  // location comes from @withRouter
		const serviceId = params?.serviceId ?? 1;

		Actions.edit({
			pathname: `/selections/service/${serviceId}/${type}/create`,
			// search: `?groupId=${selectedGroup.id}`,
			returnTo: `${location.pathname}${location.search}`,
			category,
			groupId: selectedGroup.id,
		});
	}

	// TODO!: Make the handling of subGroups with either audience or version much tighter
	handleSubGroupSelection(group, selectedAudienceId, selectedVersionId, e) {
		const { name, value } = e.target;

		let subGroup = null;
		switch(name) {
			case VERSION:
				subGroup = group.children.find(sg => (sg.version.id === parseInt(value, 10) && (sg.audience && sg.audience.id === selectedAudienceId || !sg.audience)));
				break;
			case AUDIENCE:
				subGroup = group.children.find(sg => ((sg.version && sg.version.id === selectedVersionId || !sg.version) && sg.audience.id === parseInt(value, 10)));
				break;
		}

		handleSelectGroup(subGroup);
	}

	render() {
		const {
			groups,
			selectedGroup,
			selectedTopLevelGroup,
			location,
			selectionsConfiguration,
			serviceId,
		} = this.props;

		// // HACK: If URL is hacked to include showCreateJSON
		// const createJSONButton = location.query.showCreateJSON
		// 	? <Button type="json" title="JSON" onClick={this.handleCreate.bind(this, { type: "json" })} disabled={listActionsDisabled} />
		// 	: null;

		const listActionsDisabled = !selectedGroup || isEmpty(selectedGroup);

		// Make sure we always operate on the sub group parent
		const isSubGroup = false//selectedGroup ? selectedGroup.hasOwnProperty(VERSION) || selectedGroup.hasOwnProperty(AUDIENCE) : false;
		const group = isSubGroup ? syncDeepFindGroup(selectedGroup.parentGroupId, groups) : selectedGroup;

		// Extract the unique versions and audiences from selectedGroup children for our Switches
		const uniqueVersions = getUnique(group, VERSION);
		const uniqueAudiences = getUnique(group, AUDIENCE);

		// Set the selected item in each sub group switch
		const selectedVersionId  = isSubGroup && selectedGroup.version ? selectedGroup.version.id : 1; // TODO: Default to the first available item instead of id = 1
		const selectedAudienceId = isSubGroup && selectedGroup.audience ? selectedGroup.audience.id : 1; // TODO: Default to the first available item instead of id = 1

		// TODO!: Make the handling of subGroups with either audience or version much tighter
		let versionSwitch = null, audienceSwitch = null;

		// Show versions (and switches) only when there's more than one
		if (uniqueVersions.length > 1) {
			versionSwitch = (
				<Group>
					<Switch
						name={VERSION}
						onChange={this.handleSubGroupSelection.bind(this, group, selectedAudienceId, selectedVersionId)}
						states={uniqueVersions}
						currentState={selectedVersionId}
						value="id"
						label="name" />
				</Group>
			);
		}

		if (uniqueAudiences?.length > 1) {
			audienceSwitch = (
				<Group>
					<Switch
						name={AUDIENCE}
						onChange={this.handleSubGroupSelection.bind(this, group, selectedAudienceId, selectedVersionId)}
						states={uniqueAudiences}
						currentState={selectedAudienceId}
						value="id"
						label="name" />
				</Group>
			);
		}

		const listCategoryPermissions = getListCategoryPermissions(selectionsConfiguration, serviceId);

		return (
			<Filter>
				<Left>
					<Group>
						<GroupMenuNew
							groups={groups}
							onSelectGroup={handleSelectGroup}
							selectedGroup={group}
							selectedTopLevelGroup={selectedTopLevelGroup}
						/>
					</Group>
					{ versionSwitch }
					{ audienceSwitch }
				</Left>
				<Right>
					{/* {createJSONButton} */}
					{listCategoryPermissions.regular && (
						<Button
							type="list"
							title="Selection"
							onClick={this.handleCreate.bind(this, { category: "regular" })}
							disabled={listActionsDisabled}
						/>
					)}
					{listCategoryPermissions.showcase && (
						<Button
							type="view_carousel"
							title="Showcase"
							onClick={this.handleCreate.bind(this, { category: "showcase" })}
							disabled={listActionsDisabled}
						/>
					)}
					{listCategoryPermissions.editorial && (
						<Button
							type="art_track"
							title="Item"
							onClick={this.handleCreate.bind(this, { category: "editorial" })}
							disabled={listActionsDisabled}
						/>
					)}
					{listCategoryPermissions.widget && (
						<Button
							type="extension"
							title="Widget"
							onClick={this.handleCreate.bind(this, { category: "widget" })}
							disabled={listActionsDisabled}
						/>
					)}
				</Right>
			</Filter>
		);
	}
};

// HELPERS
import { hasSubGroups, getFirstSubGroup } from '../../shared/utils'
function handleSelectGroup(group) {
	// if(hasSubGroups(group)) {
	// 	const firstSubGroup = getFirstSubGroup(group);
	// 	Actions.selectGroup(firstSubGroup);
	// }
	// else {
		Actions.selectGroup(group);
	// }
}

// Admins can create anything on any section
// Non-admins can only create lists with the categories present in allowNonAdminsToCreateCategories
// on the servicePermission matching the current serviceId
function getListCategoryPermissions(configuration, serviceId) {
	if (!serviceId) return {};

	const serviceConfiguration = configuration?.serviceConfigurations?.find(s => parseInt(s.serviceId) === parseInt(serviceId));
	if (!serviceConfiguration) return {};

	const accessLevel = getUserAccessLevelForModule("selections");
	const isAdmin = accessLevel === "admin";

	return ["regular", "showcase", "editorial", "widget"]
		.reduce((permissions, category) => ({
			...permissions,
			[category]: isAdmin || serviceConfiguration.allowNonAdminsToCreateCategories.includes(category),
		}), {});
}
import React from 'react'
import { useQuery } from '@tanstack/react-query'
import moment from 'moment'

import GenericApp from '../../../components/genericApp'

import * as CMSAPI from '../../../apis/cms'
import useList from '../../../core/hooks/useList'
import Calendar from './calendar'
import { getItems } from '../../../components/genericApp/utils'
import { useSessionStorage } from '../../../core/hooks/useStorage'
import { EVENTPLANNER_DATE_FILTER } from '../shared/utils'

const MODULE = "eventplanner";
const DATASTORE = "resources";
const TEXT_HEADING = null;//"Events";
const TEXT_BACK_BUTTON = null;//"Administration"
const TEXT_EMPTY = null;//"No events? It's about time to create some then!";
const TEXT_CREATE = null;//"Create event";
const ICON_CREATE = null;//"add";

const START_FILTER_KEY = ">=ott.start";

const providerFilters = {
	"mtv": {
		_entity: "mtv-eventslist",
		ottUrl: "views/encoders?filter=platform=='mtv'&orderby=ordinal",
	},
	"tv4": {
		_entity: "tv4-eventslist",
		ottUrl: "views/encoders?filter=platform==null or platform=='tv4'&orderby=ordinal",
	},
	"default": {
		_entity: "tv4-eventslist",
	}
};

function ListApp(props) {

	
	// const linearUrl = "views/channels?filter=contentowner=='cmore' or contentowner=='tv4'&orderby=ordinal";
	// const { data: linearResources } = useQuery(
	// 	[linearUrl],
	// 	() => CMSAPI.fetchCMSUrl(linearUrl),
	// );

	const [_entity, setEntity] = React.useState(providerFilters[props.params?.provider]?._entity ?? providerFilters["default"]._entity);
	const [ottUrl, setOttUrl] = React.useState(providerFilters[props.params?.provider]?.ottUrl ?? providerFilters["default"].ottUrl);
	React.useEffect(
		() => {
			setEntity(providerFilters[props.params?.provider]?._entity ?? providerFilters["default"]._entity);
			setOttUrl(providerFilters[props.params?.provider]?.ottUrl ?? providerFilters["default"].ottUrl);
		},
		[props.params?.provider]
	);

	const { data: ottResources } = useQuery(
		[ottUrl],
		() => CMSAPI.fetchCMSUrl(ottUrl),
	);


	const [universalEventPlannerDate, setUniversalEventPlannerDate] = useSessionStorage(EVENTPLANNER_DATE_FILTER, moment().startOf("day").format());
	const dateFromQuery = props.location.query?.date;
	const FILTER_DEFAULTS = {
		_entity,
		[START_FILTER_KEY]: dateFromQuery ? moment(dateFromQuery, "YYYY-MM-DD").startOf("day").format() : universalEventPlannerDate,
		// platform: "ottCMore",
		hideEmptyResources: "yes",
	};

	const listState = useList({
		listKey: DATASTORE,
		fetchData: (filters) => CMSAPI.fetchCMSUrl(getFetchUrl(_entity, filters)),
		fetchSingle: CMSAPI.fetchCMSEntity,
		patchData: CMSAPI.updateCMSEntity,
		runAction: CMSAPI.runAction,
		entity: _entity,
		updateDataExtraParams: { _entity },
		filterDefaults: FILTER_DEFAULTS,
	});

	const updateStart = React.useCallback(
		(inc) => {
			const updateMethod = listState.filters.updateMethods[START_FILTER_KEY];
			const newStart = moment(listState.filters.values[START_FILTER_KEY]).add(inc, "days");
			updateMethod(newStart.format());
		},
		[listState.filters.values[START_FILTER_KEY], listState.filters.updateMethods[START_FILTER_KEY]]
	);
	const goPrevDay = React.useCallback(() => updateStart(-1), [updateStart]);
	const goNextDay = React.useCallback(() => updateStart(1), [updateStart]);

	const filterConfig = {
		[START_FILTER_KEY]: {
			type: "date",
			alwaysVisible: true,
			onChange: (newValue) => {
				// Clear stored value when selecting today
				const isToday = moment(newValue).isSame(moment(), "day");
				setUniversalEventPlannerDate(isToday ? undefined : moment(newValue).startOf("day").format());
			},
		},
		"prev": {
			type: "button",
			buttonType: "prev",
			alwaysVisible: true,
			onClick: goPrevDay,
		},
		"next": {
			type: "button",
			buttonType: "next",
			alwaysVisible: true,
			onClick: goNextDay,
		},
		// "platform": {
		// 	type: "switch",
		// 	title: "Platform",
		// 	alwaysVisible: true,
		// 	options: [
		// 		{ key: "ottCMore", text: "C More" },
		// 		{ key: "ottTv4", text: "TV4 Play" },
		// 		{ key: "ottMtv", text: "MTV Katsomo" },
		// 	],
		// },
		"hideEmptyResources": {
			onlyUi: true,
			type: "switch",
			title: "Hide empty resources",
			alwaysVisible: true,
			options: [
				{ key: "yes", text: "Yes" },
				{ key: "no", text: "No" },
			],
		},
	};

	return (
		<React.Fragment>
			<GenericApp
				{...props}
				module={MODULE}
				datastore={DATASTORE}
				state={listState}
				textHeading={TEXT_HEADING}
				textBackButton={TEXT_BACK_BUTTON}
				textEmpty={TEXT_EMPTY}
				textCreate={TEXT_CREATE}
				iconCreate={ICON_CREATE}
				filterConfig={filterConfig}
				collapseFiltersDefault={true}
				className="c6-eventplanner-resources"
			>
				<Calendar
					date={listState.filters.values[START_FILTER_KEY]}
					events={getItems(listState.data)}
					ottResources={ottResources?.items ?? []}
					// linearResources={linearResources?.items ?? []}
					onChange={listState.onChange}
					hideEmptyResources={listState.filters.values.hideEmptyResources === "yes"}
				/>
			</GenericApp>
		</React.Fragment>
	);
}

export default class ResourcesApp extends React.Component {
	render() {
		return (
			<ListApp
				{...this.props}
			/>
		);
	}
}

// Custom function for generating filter URL since the default filter builder joins the different parts with "and"
function getFetchUrl(_entity, filters) {
	const min = `'${moment(filters[START_FILTER_KEY]).format("YYYY-MM-DD")}'`;
	const max = `'${moment(filters[START_FILTER_KEY]).add(36, "hours").format("YYYY-MM-DD HH:mm")}'`;

	// const ottOrLinear = "(ottCMore.selected == true or ottTv4.selected == true or linear.selected == true)";
	// const ottPlatforms = `(${filters.platform.split(",").map(p => p + ".selected == true").join(" or ")})`;
	const ottStart = `(ott.start >= ${min} and ott.start < ${max})`;
	const ottEnd = `(ott.end >= ${min} and ott.end < ${max})`;
	// const filterString = `${ottOrLinear} and (${ottStart} or ${ottEnd})`;
	// const filterString = `${ottPlatforms} and (${ottStart} or ${ottEnd})`;
	const filterString = `(${ottStart} or ${ottEnd})`;
	return `views/${_entity}?filter=${filterString}`;
}
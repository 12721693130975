import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Moment from 'moment'

import { decoratorUIActions } from '../../../../core/decorators/uiActions'

import { DroppableItemGroup, ItemGroup } from '../../../../components/list/listItems'
import SortableList from '../../../../components/list/sortableList'
import SortableItem from '../../../../components/list/sortableItem'
import ItemTypes from '../../../../core/itemTypes'

import Actions from '../../actions'
import Item from './listItem'
import itemActions from './listItemActions'

import Constants from '../../../../core/constants'

@SortableList({
	onItemMoved: ({ payload, originalItem, targetType, sourceProps }) => {
		const { listId, filters } = sourceProps;
		const { id, ordinal } = payload;
		const { referenceId, mediaType } = originalItem;

		payload = {
			id,
			referenceId,
			date: filters.date,
			position: {
				ordinal,
			},
			mediaType,
		};

		Actions.moveItemInList({ listId, filters, payload });
	},
	generateNewItem: ({ payload, droppedItem, targetType, sourceProps }) => {
		const { listId, filters, list } = sourceProps;
		const { id, ordinal } = payload;
		const { referenceId } = droppedItem;

		return {
			listId,
			list,
			filters,
			body: {
				id,
				referenceId,
				date: filters.date,
				position: {
					ordinal,
				}
			},
			droppedItem, // So we can optimistically insert a temporary item before the list is refreshed
		};
	},
	onItemAdded: ({ payload }) => Actions.insertListItem(payload),
})
@decoratorUIActions(itemActions)
export default class List extends Component {

	static propTypes = {
		listId: PropTypes.number.isRequired,
		items: PropTypes.array.isRequired,
		filters: PropTypes.object,
		readonly: PropTypes.bool,
	}
	
	state = { isDraggingItem: false };

	handleDragStateChanged = (isDraggingItem) => {
		this.setState({ isDraggingItem });
	}

	render() {
		const {
			readonly,
			children,

			// Injected by the SortableList HOC ReactDnD
			connectDropTarget,
			isOver,

			// Injected by the SortableList HOC
			items,
			handleItemDrop,
			handleTargetHover,
		} = this.props;

		const isDraggingClass = this.state.isDraggingItem ? " is-dragging-item" : "";
		// const isDraggingClass = isOver ? " is-dragging-item" : ""; // TODO: Doesn't activate before the browser makes it's drag copy image (so it still has active actions)
		const itemGroup = items && items.length
			// ? <div className={isDraggingClass}><ItemGroup>{ this.renderItems(items, readonly) }</ItemGroup></div>
			? (
				<DroppableItemGroup
					type={ItemTypes.ITEM}
					onTargetHover={handleTargetHover}
					onItemDrop={handleItemDrop}
					useCustomScrollWhileDragging={true}
				>
					{this.renderItems(items, readonly)}
				</DroppableItemGroup>
			)
			: <div style={{height: "100%"}}>{ children }</div>;

		return readonly ? itemGroup : connectDropTarget(<div className={isDraggingClass}>{itemGroup}</div>);
  	}

	renderItems(items, readonly) {
		const {
			// Injected by the SortableList HOC
			findItem,
			moveItem,
			handleOutsideDrop,
			handleItemManipulation,
		} = this.props;

		return items.map(item => {
			return (
				<Item
					key={item.id}
					id={item.id}
					item={item}
					listCategory={this.props.list?.category}

					// DND
					isPlaceholder={item.isPlaceholder || !!item.isOver}
					findItem={findItem}
					moveItem={moveItem}
					handleOutsideDrop={handleOutsideDrop}
					handleItemManipulation={handleItemManipulation}
					onDragStateChanged={this.handleDragStateChanged}
				/>
			);

			// const itemElement = (
			// 	<ItemContent
			// 		assets={item.assets}
			// 		title={item.displayName || "Unknown title !!!"}
			// 		label={getLabel(item)}
			// 		description={getPeriod(item)} />
			// );

			// if(readonly) {
			// 	return <Item key={item.id} id={item.id}>{itemElement}</Item>;
			// }

			// return (
			// 	<SortableItem
			// 		key={item.id}
			// 		id={item.id}
			// 		handleType="thumbnail"
			// 		// actionData={item}
			// 		item={item}

			// 		isPlaceholder={item.isPlaceholder}
			// 		moveItem={moveItem}
			// 		onItemDropOutside={handleOutsideDrop}
            //       	findItem={findItem}
			// 		insertCard={handleItemManipulation}

			// 		onDragStart={this.handleDragStart}
			// 		onDragEnd={this.handleDragEnd}
			// 	>
			// 		{itemElement}
			// 	</SortableItem>
			// );
		});
	}
}

// HELPERS
// function getPeriod(item) {
// 	const start = item.start
// 		? <time dateTime={item.start}>{Moment(item.start).format(Constants.PERIOD_FORMAT)}</time>
// 		: null;

// 	const end = item.end
// 		? <time dateTime={item.end}>{Moment(item.end).format(Constants.PERIOD_FORMAT)}</time>
// 		: null;

// 	if(start || end) {
// 		return <p>{start}{end}</p>;
// 	}

// 	return null;
// }

// function getLabel(item) {
// 	if(item.start && Moment().isBefore(item.start)) {
// 		return {
// 			text: "Upcoming",
// 			status: "upcoming"
// 		};
// 	} else if(item.end && Moment().isAfter(item.end)) {
// 		return {
// 			text: "Expired",
// 			status: "expired"
// 		};
// 	}

// 	return null;
// }
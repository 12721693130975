import React from 'react'
import Dialog from '@mui/material/Dialog'

import useList from '../../../core/hooks/useList'
import { getTemplateWithValues, getValue } from '../../../core/cms/utils'
import { getItems } from '../../../components/genericApp/utils'
import EventPlannerComponent from '../../../components/cms/component'
import useCMSTemplate from '../../../core/queries/cms/useCMSTemplate'

import * as CMSAPI from '../../../apis/cms'
import './asset.css'

const _entity = "eventassetslist";

function AssetModal(props) {
	const { id } = props;

	const [logs, setLogs] = React.useState(null);

	function matchCustomComponent({ templateItem, className, itemData, path }) {
		switch (templateItem.componentType) {
			case "link":
				const vimondId = getValue(itemData, "vimondId", path.join("."));
				return (
					<a
						disabled={!vimondId}
						onClick={() => {
							CMSAPI.fetchCMSUrl(`storages/assetlogs/items?path=${id}/${vimondId}`)
								.then(response => {
									const sortedLogs = response.sort().reverse();//(((a, b) => a.modified)
									setLogs(sortedLogs);
								});
						}}
						className={`${className} c6-link`}
					>
						{templateItem.displayName ?? templateItem.name}
					</a>
				)
		}

		return null;
	}

	const listState = useList({
		listKey: ["event-assets", { id }],
		fetchData: CMSAPI.fetchCMSEntities,
		fetchSingle: CMSAPI.fetchCMSEntity,
		patchData: CMSAPI.updateCMSEntity,
		runAction: CMSAPI.runAction,
		entity: _entity,
		updateDataExtraParams: { _entity },
		filterDefaults: {
			_entity,
			id,
		},
	});
	const items = getItems(listState.data);
	const itemData = items[0] ?? {};

	// HACK: For some reason I was not able to simply use itemData in onChange below, it was always empty. Or maybe I just forgot how to React :(
	const itemDataRef = React.useRef({});
	if (itemData?.id) {
		itemDataRef.current = itemData;
	}

	const { data: template } = useCMSTemplate(_entity);

	const rootNodes = getTemplateWithValues(template?.children ?? [], itemData);
	const onChange = async (onChangeData) => {
		await listState.onChange({
			...onChangeData,
			id: id,
			originalItem: itemDataRef.current,
		});
	};

	return (
		<Dialog
			open={props.open ?? true}
			onClose={props.onClose}
			className="c6-modal asset-modal"
			classes={{
				container: "c6-modal-content",
				paper: "c6-modal-body"
			}}
		>
			{logs?.length > 0 && (
				<React.Fragment>
					<p>
						<a className="c6-link icon-chevron_left" onClick={() => setLogs(null)}>Back</a>
					</p>
					<table>
						<thead>
							<tr>
								<th>File name</th>
								{/* <th>Modified</th> */}
							</tr>
						</thead>
						<tbody>
							{logs?.map(log => (
								<tr key={log.name}>
									<td>
										<a
											className="c6-link"
											onClick={() => {
												CMSAPI.fetchCMSUrl(`storages/assetlogs/url?path=${log.path}`)
													.then(response => {
														window.open(response, "_blank");
													});
											}}
										>
											{log.name}
										</a>
									</td>
									{/* <td>{log.modified && moment(log.modified).format("YYYY-MM-DD HH:mm:ss")}</td> */}
								</tr>
							))}
						</tbody>
					</table>
				</React.Fragment>
			)}
			{logs !== null && !logs.length && (
				<p>
					No logs found. <a className="c6-link icon-chevron_left" onClick={() => setLogs(null)}>Back</a>
				</p>
			)}
			{logs === null && (
				rootNodes?.map(child => (
					<EventPlannerComponent
						key={child.name}
						templateItem={child}
						onChange={onChange}
						matchCustomComponent={matchCustomComponent}
						itemData={itemData}
					/>
				))
			)}
		</Dialog>
	);
}

export default AssetModal;
import React from 'react'

import EventPlannerComponent from '../component'

import './container.css'
import { Box, Fade, Tab, Tabs } from '@mui/material';

const TabbedContainer = ({
	name,
	// href,
	children,
	onChange,
	_onChangeWithoutAddedPath,
	path = [],
	itemData = {},
	otherProps = {},
	matchCustomComponent = null,
	style,
	value,
	multiValue,
	readOnly,
	parentReadOnly,
	// options = {},
}) => {

    const [tabIndex, setTabIndex] = React.useState(0);

	const childElements = React.useMemo(
		() => {
			if (multiValue && Array.isArray(value)) {
				return value.map((v, i) => (		
					<React.Fragment key={i}>
						{children.map(c => {
                            return (
                                <EventPlannerComponent
                                    key={`${c.name}-${i}`}
                                    templateItem={{
                                        ...c,
                                        value: value[i]?.[c.name],
                                    }}
                                    onChange={onChange}
                                    _onChangeWithoutAddedPath={_onChangeWithoutAddedPath}
                                    path={[...path, name, i]}
                                    otherProps={otherProps}
                                    itemData={itemData}
                                    matchCustomComponent={matchCustomComponent}
                                    parentReadOnly={readOnly ?? parentReadOnly}
                                />
                            )
                        }
							
						)}
					</React.Fragment>
				));
			}
			
			return children.map((c, i) => (
                <TabPanel key={`${c.name}-${i}`} value={tabIndex} index={i} name={name}>
                    <EventPlannerComponent
                        templateItem={{
                            ...c,
                            value: c.value ?? value?.[c.name],
                            displayName: ""
                        }}
                        onChange={onChange}
                        _onChangeWithoutAddedPath={_onChangeWithoutAddedPath}
                        path={[...path, name]}
                        otherProps={otherProps}
                        itemData={itemData}
                        matchCustomComponent={matchCustomComponent}
                        parentReadOnly={readOnly ?? parentReadOnly}
                    />
                </TabPanel>
			));
		},
		[multiValue, value, children, tabIndex]
	);

	// const onClick = React.useCallback(
	// 	() => {
	// 		if (href && typeof (otherProps.openModal) === "function") {
	// 			const idComponent = children.find(c => c.name === "id");
	// 			otherProps.openModal({
	// 				modalName: href,
	// 				itemData: itemData,
	// 				id: idComponent?.value ?? itemData.id,
	// 			});
	// 		}

	// 		if (options.toggleContainer && typeof otherProps.onToggleContainer === "function") {
	// 			otherProps.onToggleContainer(options.toggleContainer, itemData.id);
	// 		}
	// 	},
	// 	[href, otherProps.openModal, children, itemData]		
	// );

	if (style?.format?.includes("hiddenWhenNull") && value === null) {
		return null;
	}

    const handleChange = (event, newValue) => {
        setTabIndex(newValue);
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${name}-${index}`,
            'aria-controls': `simple-tabpanel-${name}-${index}`,
            key: `simple-tab-${name}-${index}`,
        };
      }

	return (
        <>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={tabIndex} onChange={handleChange} aria-label="basic tabs example">
                    {children.map((c, i) => (
                        // eslint-disable-next-line react/jsx-key
                        <Tab label={c.displayName} value={i} {...a11yProps(i)} />
                    ))}
                </Tabs>
            </Box>
            {childElements}
        </>

		// <div
		// 	key={name}
		// 	className={className}
		// 	style={{
		// 		...dynamicStyle,
		// 		cursor: (href || options.toggleContainer) ? "pointer" : undefined,
		// 	}}
		// 	onClick={onClick}
		// >
		// 	{displayName && <span key="displayName" className="displayname">{displayName}</span>}
		// 	{childElements}
		// </div>
	);
};

function TabPanel(props) {
    const { children, name, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${name}-${index}`}
        aria-labelledby={`simple-tab-${name}-${index}`}
        {...other}
      >
        {value === index && (
            <Fade in={true}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    {children}
                </Box>
            </Fade>
        )}
      </div>
    );
}

export default TabbedContainer;
import React from 'react'
import { Route, IndexRoute } from 'react-router'

import Module         from '../app'

import LicensesList   from './licenses/app'
import LicenseEditor  from './licenseeditor/app'
import TemporaryLicenseEditor  from './licenseeditortemp/app'

import LicenseEpisodesList   from './licenseEpisodes/app'

import ContractsList  from './contracts/app'
import ContractEditor from './contracteditor/app'

import DownloadRightsEditor from './downloadrightseditor/app'

export default class AcqModule {
	static routes() {
		return (
			<Route path="acq" component={Module} module="acq">

				<Route path="contracts" app="contracts">
					<IndexRoute component={ContractsList} />
					<Route path="(:id)/edit" component={ContractEditor} access="editor" />
					<Route path="create" component={ContractEditor} access="editor" />
					<Route path="(:id)/downloadrights" component={DownloadRightsEditor} access="editor" />
					<Route path=":id/licenses" component={LicensesList} />
					<Route path=":id/licenses/:licenseId" component={LicenseEpisodesList} />
				</Route>
				<Route path="contracts-linear" app="contracts">
					<IndexRoute component={ContractsList} />
					<Route path="(:id)/edit" component={ContractEditor} access="editor" />
					<Route path="create" component={ContractEditor} access="editor" />
					<Route path="(:id)/downloadrights" component={DownloadRightsEditor} access="editor" />
					<Route path=":id/licenses" component={LicensesList} />
					<Route path=":id/licenses/:licenseId" component={LicenseEpisodesList} />
				</Route>
				<Route path="contracts-vod" app="contracts">
					<IndexRoute component={ContractsList} />
					<Route path="(:id)/edit" component={ContractEditor} access="editor" />
					<Route path="create" component={ContractEditor} access="editor" />
					<Route path="(:id)/downloadrights" component={DownloadRightsEditor} access="editor" />
					<Route path=":id/licenses" component={LicensesList} />
					<Route path=":id/licenses/:licenseId" component={LicenseEpisodesList} />
				</Route>

				<Route path="licenses" app="licenses">
					<IndexRoute component={LicensesList} />
					<Route path="(:id)/edit" component={TemporaryLicenseEditor} access="editor" modal={true} />
					<Route path="create" component={TemporaryLicenseEditor} access="editor" modal={true} />
					<Route path="(:licenseId)" component={LicenseEpisodesList} />
				</Route>
				<Route path="licenses-linear" app="licenses">
					<IndexRoute component={LicensesList} />
					<Route path="(:id)/edit" component={TemporaryLicenseEditor} access="editor" modal={true} />
					<Route path="create" component={TemporaryLicenseEditor} access="editor" modal={true} />
					<Route path="(:licenseId)" component={LicenseEpisodesList} />
				</Route>
				<Route path="licenses-vod" app="licenses">
					<IndexRoute component={LicensesList} />
					<Route path="(:id)/edit" component={TemporaryLicenseEditor} access="editor" modal={true} />
					<Route path="create" component={TemporaryLicenseEditor} access="editor" modal={true} />
					<Route path="(:licenseId)" component={LicenseEpisodesList} />
				</Route>
			</Route>
		);
	}
}

import React from 'react'

import { Link } from 'react-router'
import { Filter, Left, Right } from '../../../components/filter'
import Switch from '../../../components/ui/controls/switch'
import Search from '../../../components/ui/controls/search'
import Dropdown from '../../../components/ui/controls/dropdown'

import Button from '../../../components/ui/controls/button'

import { getApproval, getRight, getClass, getPremiere, getTypes, getProvider } from '../utils'

export default ({
	searchText,
	filters,
	unselectedFilters,
	handleFilter,
}) => {
	return (
		<Filter>
			<Left>
				<Link
					to={"traffic/programs"}
					className="c6 c6-button c6-text icon-chevron_left">
					<span>Go back</span>
				</Link>
				<h1>
					Event History
				</h1>
			</Left>

		</Filter>
	);
}
import { CONTENT, INLINE, CLICK_OPT } from '../../../../components/actions'
import Actions from '../../actions'

import { hasStarEditAccess } from '../../shared/utils'

export default {
	targetComponent: "Item",
	actions: [
		{
			type: CONTENT,
			title: "Open album",
			method: (data, sourceProps, location) => {
				Actions.openAlbum(data, location);
			},
		},
		{
			type: INLINE,
			icon: "cancel",
			// title: "Delete",
			confirm: true,
			confirmationText: "Are you sure you want to delete this album?",
			method: (data, sourceProps, location) => {
				Actions.removeItem("album", data);
			},
			hidden: data => data.id === 0 || !hasStarEditAccess(),
		},
	]
};
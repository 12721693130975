import { browserHistory } from 'browserHistory'
import Moment from 'moment'

import alt from '../../core/services/alt'

import * as APIS from '../../apis/'

import { handleRequestFailed } from '../../core/services/errorhandling'

import Const from '../../core/constants'
import { saveBlobToDisc } from '../../utils/save'

import { getStore, getEntityAndStore } from './shared/utils'

const API = "acq"

class Actions {

	/* **************** Actions **************** */
	edit({id, pathname = `/acq/licenses/${id}/edit`, search, returnTo, wideModal, typeId, query, modal = true, targetStore = null}) {
		const route = {
			pathname,
			search,
			state: {
				modal,
				wideModal,
				typeId,
				returnTo,
				targetStore,
			}
		};

		browserHistory.push(route)

		return route;
	}

	create({ pathname, search, returnTo }) {

		const route = {
			pathname,
			search,
			state: {
				modal: true,
				returnTo,
			}
		};

		browserHistory.push(route);

		return route;
	}

	search(searchText) {
		return searchText;
	}

	filter(filterValue, filterName) {
		return (dispatch) => {
			dispatch({[filterName]: filterValue});
		};
	}

	/* **************** Items **************** */
	fetchItems(entityOrEntityTarget, payload, targetStore = null) {
		const multiple = true;
		const { entity, store } = getEntityAndStore(entityOrEntityTarget, multiple, targetStore);

		return (dispatch) => {
			const requestTime = Date.now();
			dispatch({ entity, store, payload, requestTime });

			const command = getCommand("fetch", entity, multiple);
			APIS[API][command](payload)
				.then(response => {
					const { pageIndex, numberOfItems, items, links } = response;
					const appendToExistingItems = pageIndex > 0;
					const nextPageUrl = getNextPageUrl(links);
					this.itemsUpdated(store, items, appendToExistingItems, numberOfItems, nextPageUrl, requestTime);

				}, this.requestFailed);
		};
	}
	pageItems(entityOrEntityTarget, url, targetStore = null) {
		const multiple = true;
		const { store } = getEntityAndStore(entityOrEntityTarget, multiple, targetStore);

		return (dispatch) => {
			dispatch({url, store});

			APIS[API].fetchUrl(url)
				.then(response => {
					const { pageIndex, numberOfItems, items, links } = response;
					const appendToExistingItems = pageIndex > 0;

					const nextPageUrl = getNextPageUrl(links);
					this.itemsUpdated(store, items, appendToExistingItems, numberOfItems, nextPageUrl);

				}, this.requestFailed);
		};
	}
	itemsUpdated(datastore, items, appendToExistingItems, numberOfItems, nextPageUrl, requestTime) {
		return {
			datastore,
			items,
			appendToExistingItems,
			numberOfItems,
			nextPageUrl,
			requestTime,
		};
	}

	/* **************** Item **************** */
	clearItem(entity) {
		return entity;
	}
	fetchItem(entity, payload) {
		return (dispatch) => {
			dispatch();

			const command = getCommand("fetch", entity);
			APIS[API][command](payload)
				.then(model => {
					this.itemLoaded({entity, model});
				}, this.requestFailed);
		};
	}
	itemLoaded(payload) { return payload; }

	updateItem(entity, data, payload, type = "update", targetStore) {
		return (dispatch) => {
			dispatch();

			const command = getCommand(type, entity);
			APIS[API][command](data, payload)
				.then(model => {
					this.itemUpdated({ entity, model, originalId: data.id, targetStore });
				}, this.requestFailed);
		}
	}

	enableItem(entity, data, targetStore) {
		return (dispatch) => {
			dispatch(data.id);

			const command = getCommand("update", entity);
			APIS[API][command](data, { enabled: true })
				.then(model => {
					this.itemUpdated({ entity, model, originalId: data.id, targetStore });
				}, this.requestFailed);
		};
	}
	disableItem(entity, data, targetStore) {
		return (dispatch) => {
			dispatch(data.id);

			const command = getCommand("update", entity);
			APIS[API][command](data, { enabled: false })
				.then(model => {
					this.itemUpdated({ entity, model, originalId: data.id, targetStore });
				}, this.requestFailed);
		};
	}
	itemUpdated(payload) { return payload; }

	removeItem(entity, data, targetStore) {
		return (dispatch) => {
			dispatch({ entity, id: data.id, targetStore });

			const command = getCommand("delete", entity);
			APIS[API][command](data)
				.then(() => {
					this.itemRemoved({ entity, id: data.id, targetStore });
				}, (error, request) => {
					// TODO!: Keep item index when rollbacking
					this.rollbackRemoveItem({ entity, id: data.id, targetStore });
					this.requestFailed({ error, request });
				});
		};
	}
	itemRemoved(payload) { return payload; }
	rollbackRemoveItem(payload) { return payload};

	/* **************** Misc **************** */
	unmount() { return true; }

	requestFailed(error) {
		handleRequestFailed(error);
		return true;
	}

	/* **************** Custom **************** */
	updateContractId(id) {
		return (dispatch) => {
			dispatch(id);
		}
	}
	setWindowStatus(window, licenseId, isActive, autoconfirm = false, targetStore) {
		return (dispatch) => {
			const payload = { id: window.id, licenseId, isActive };
			dispatch(payload);

			const command = isActive
				? "activateLicenseWindow"
				: "inactivateLicenseWindow" ;

			APIS[API][command](payload)
				.then(model => {
					if(autoconfirm) {
						APIS[API].confirmLicenseWindow(payload)
							.then(model => {
								this.itemUpdated({ entity: "license", model, targetStore });
							}, this.requestFailed);
					}
					else {
						this.itemUpdated({ entity: "license", model, targetStore });
					}
				}, this.requestFailed);
		};
	}
	removeLicenseWindow(id, licenseId, autoconfirm = false, targetStore) {
		return (dispatch) => {
			const payload = { id, licenseId };
			dispatch(payload);

			APIS[API].removeLicenseWindow(payload)
				.then(model => {
					if(autoconfirm) {
						APIS[API].confirmLicenseWindow(payload)
							.then(model => {
								this.itemUpdated({ entity: "license", model, targetStore });
							}, this.requestFailed);
					}
					else {
						this.itemUpdated({ entity: "license", model });
					}
				}, this.requestFailed);
		};
	}
	updateLicenseWindow(id, licenseId, payload, autoconfirm = false, targetStore) {
		return (dispatch) => {
			const data = { id, licenseId };
			dispatch(payload);

			APIS[API].updateLicenseWindow(data, payload)
				.then(model => {
					if(autoconfirm) {
						APIS[API].confirmLicenseWindow(data)
							.then(model => {
								this.itemUpdated({ entity: "license", model, targetStore });
							}, this.requestFailed);
					}
					else {
						this.itemUpdated({ entity: "license", model, targetStore });
					}
				}, this.requestFailed);
		};
	}

	toggleLicenseDownloadRights(licenseId, excludeDownloadRights) {
		return dispatch => {
			dispatch();

			const payload = { excludeDownloadRights: !excludeDownloadRights }
			APIS[API].updateLicense(licenseId, payload)
				.then(model => {
					this.itemUpdated({ entity: "license", model });
				}, this.requestFailed);
		}
	}

	/* **************** Excel export **************** */
	ExportExcel() {
		return dispatch => {
			dispatch();
			APIS[API].fetchAdsAllowedExcel()
				.then(response => {
					saveBlobToDisc(response, `AdsAllowed_ProgramLicenseWindows_${Moment().format('YYYY-MM-DD')}`);
					this.excelFinish()
				})
		}
	}

	excelFinish(excelLoading) {
		return !excelLoading
	}
}

export default alt.createActions(Actions);

// Helpers
function getCommand(command, entity, multiple = false) {
	const extra = multiple ? "s" : "";
	if(typeof(entity) === "object") {
		if(entity.parentEntity) {
			return command + entity.parentEntity.substr(0, 1).toUpperCase() + entity.parentEntity.substr(1) + entity.entity.substr(0, 1).toUpperCase() + entity.entity.substr(1) + extra;
		}
		entity = `${entity.entity}${extra}`;
	}
	else {
		entity = `${entity}${extra}`;
	}

	return command + entity.substr(0, 1).toUpperCase() + entity.substr(1);
}

function getNextPageUrl(links = []) {
	const nextLink = links.find(l => l.rel === "next");
	return nextLink ? nextLink.href	: null;
}
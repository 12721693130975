import { browserHistory } from 'browserHistory'
import moment from 'moment'

import alt from '../../core/services/alt'
import * as APIS from '../../apis/'
import * as Alert from '../../core/services/alert'
import { saveBlobToDisc } from '../../utils/save'

import Const from '../../core/constants'

const API = "traffic"

class Actions {

    search(searchText, filterName, targetStore) {
        return (dispatch) => {
            dispatch({
                searchText,
                targetStore,
            });
        }
    }

    filter(filterValue, filterName, targetStore) {
        return (dispatch) => {
            dispatch({
                [filterName]: filterValue,
                targetStore,
            });
        };
    }

    /* **************** Items **************** */
    fetchItems(entity, payload, options = {}) {

        return (dispatch) => {
            const {
                targetStore = null,
                callback = null,
                api = API,
            } = options;

            const requestTime = Date.now();
            const multiple = true;
            const command = getCommand("fetch", entity, multiple);
            const store = targetStore || getStore(entity, multiple);

            dispatch({ payload, store, requestTime });

            APIS[api][command](payload)
                .then(response => {
                    // C60
                    const { pageIndex, numberOfItems, items, links } = response;
                    const nextPageUrl = getNextPageUrl(links);

                    // C70
                    // const { result: items, meta } = response;
                    // const { pageIndex, totalItems: numberOfItems, next: nextPageUrl } = meta;

                    const appendToExistingItems = pageIndex > 0;

                    this.itemsUpdated({
                        store,
                        items,
                        appendToExistingItems,
                        numberOfItems,
                        nextPageUrl,
                        requestTime,
                    });

                    if (callback) {
                        callback(items);
                    }

                }, this.requestFailed);

        };
    }
    pageItems(entity, url, options = {}) {
        return (dispatch) => {
            const {
                targetStore = null,
                callback = null,
                api = API,
            } = options;

            const requestTime = Date.now();
            const multiple = true;
            const store = targetStore || getStore(entity, multiple);

            dispatch({ entity, url, store });

            APIS[api].fetchUrl(url)
                .then(response => {
                    // C60
                    const { pageIndex, numberOfItems, items, links } = response;
                    const nextPageUrl = getNextPageUrl(links);

                    // C70
                    // const { result: items, meta } = response;
                    // const { pageIndex, totalItems: numberOfItems, next: nextPageUrl } = meta;

                    const appendToExistingItems = pageIndex > 0;

                    this.itemsUpdated({
                        store,
                        items,
                        appendToExistingItems,
                        numberOfItems,
                        nextPageUrl,
                        requestTime,
                    });

                    if (callback) {
                        callback(items);
                    }

                }, this.requestFailed);
        };
    }
    itemsUpdated(payload) {
        return payload;
    }

    /* **************** Item **************** */
    fetchItem(entity, payload, options = {}) {
        return (dispatch) => {
            const {
                targetStore = entity,
                // callback = null,
                api = API,
            } = options;

            dispatch(targetStore);
            console.log(targetStore)
            const command = getCommand("fetch", entity);
            APIS[api][command](payload)
                .then(model => {
                    this.itemLoaded({ entity: targetStore, model });
                }, this.requestFailed);
        };
    }

    itemLoaded(payload) { return payload; }

    updateItem(entity, data, payload, type = "update", targetStore) {
        return (dispatch) => {
            dispatch();

            const command = getCommand(type, entity);
            APIS[API][command](data, payload)
                .then(model => {
                    this.itemUpdated({ entity, model, originalId: data.id, targetStore });
                }, this.requestFailed);
        }
    }
    itemUpdated(payload) { return payload; }

    removeItem(entity, data, targetStore) {
        return (dispatch) => {
            dispatch({ entity, id: data.id, targetStore });

            const command = getCommand("delete", entity);
            APIS[API][command](data)
                .then(() => {
                    this.itemRemoved({ entity, id: data.id, targetStore });
                }, (error, request) => {
                    // TODO!: Keep item index when rollbacking
                    this.rollbackRemoveItem({ entity, id: data.id, targetStore });
                    // this.requestFailed({ error, request });
                    this.requestFailed(error);
                });
        };
    }

    itemRemoved(payload) { return payload; }

    rollbackRemoveItem(payload) { return payload };

    /* ================ */
    /*      GENERAL     */
    /* ================ */
    unmount() { return true }

    persist(model, store, prepend) {
        return { store, model, prepend };
    }

    requestFailed(error) {
        Alert.displayAlert("error", error.exceptionMessage);
        return true;
    }


    toggleDownloadRights(exposure, version, program) {
        return dispatch => {
            dispatch();

            const payload = { excludeDownloadRights: !exposure.excludeDownloadRights }
            APIS[API].updateExposure({ id: exposure.id }, payload)
                .then(model => {
                    const exposureIndex = version.exposures.findIndex(e => e.id === exposure.id);
                    const newExposures = [...version.exposures];
                    newExposures[exposureIndex] = model;
                    version.exposures = newExposures;

                    const versionIndex = program.versions.findIndex(v => v.id === version.id);
                    const newVersions = [...program.versions];
                    newVersions[versionIndex] = version;
                    this.itemUpdated({
                        entity: "program",
                        model: {
                            ...program,
                            versions: newVersions,
                        }
                    });
                }, this.requestFailed);
        }
    }

    distributionDataUpdate(version, program, payload) {
        return dispatch => {
            dispatch();

            // const payload = { status: "onHold", onHoldUntil: value }
            APIS[API].updateDistributionData({ id: version.id }, payload)
                .then(model => {
                    const versionIndex = program.versions.findIndex(v => v.id === version.id);
                    const newVersions = [...program.versions];
                    newVersions[versionIndex] = model;
                    this.itemUpdated({
                        entity: "program",
                        model: {
                            ...program,
                            versions: newVersions,
                        }
                    });
                }, this.requestFailed);
        }
    }

    enableVideoWatermark(programId) {
        return dispatch => {
            dispatch();

            APIS[API].enableVideoWatermark({ id: programId })
                .then(model => {
                }, this.requestFailed);
        }
    }

    disableVideoWatermark(programId) {
        return dispatch => {
            dispatch();

            APIS[API].disableVideoWatermark({ id: programId })
                .then(model => {
                }, this.requestFailed);
        }
    }

    EarliestDerlivery(version, distribution, value, program) {
        return dispatch => {
            dispatch();

            const payload = { status: "onHold", onHoldUntil: value }
            APIS[API].updateDistributionData({ id: version.id }, payload)
                .then(model => {
                    const versionIndex = program.versions.findIndex(v => v.id === version.id);
                    const newVersions = [...program.versions];
                    newVersions[versionIndex] = model;
                    this.itemUpdated({
                        entity: "program",
                        model: {
                            ...program,
                            versions: newVersions,
                        }
                    });
                }, this.requestFailed);
        }
    }

    packageStatus(version, program, payload) {
        return dispatch => {
            dispatch();
            // const payload = { status: "recalled" }
            APIS[API].updateDistributionData({ id: version.id }, payload)
                .then(model => {
                    const versionIndex = program.versions.findIndex(v => v.id === version.id);
                    const newVersions = [...program.versions];
                    newVersions[versionIndex] = model;
                    this.itemUpdated({
                        entity: "program",
                        model: {
                            ...program,
                            versions: newVersions,
                        }
                    });
                }, this.requestFailed);
        }
    }

    Recall(version, program, payload) {
        return dispatch => {
            dispatch();
            // const payload = { status: "recalled" }
            APIS[API].updateDistributionData({ id: version.id }, payload)
                .then(model => {
                    const versionIndex = program.versions.findIndex(v => v.id === version.id);
                    const newVersions = [...program.versions];
                    newVersions[versionIndex] = model;
                    this.itemUpdated({
                        entity: "program",
                        model: {
                            ...program,
                            versions: newVersions,
                        }
                    });
                }, this.requestFailed);
        }
    }

    Send(version, program) {
        return dispatch => {
            dispatch();

            const payload = { status: "normal" }
            APIS[API].updateDistributionData({ id: version.id }, payload)
                .then(model => {

                    const versionIndex = program.versions.findIndex(v => v.id === version.id);
                    const newVersions = [...program.versions];
                    newVersions[versionIndex] = model;
                    this.itemUpdated({
                        entity: "program",
                        model: {
                            ...program,
                            versions: newVersions,
                        }
                    });
                }, this.requestFailed);
        }
    }

    ExportExcel(payload, service) {
        return dispatch => {
            dispatch();
            APIS[API].fetchExcel(payload)
                .then(response => {
					const filename = `VOD_Content_${moment().format("YYYYMMDD")}`;
                    saveBlobToDisc(response, filename);
                    this.excelFinish()
                })     
                console.log("service: " + service)   
                console.log("payload: " + JSON.stringify(payload))         
        }
    }
    excelFinish(excelLoading) {return !excelLoading}

    updateAssetStatus(asset, version, program, name) {
        return dispatch => {
            dispatch();

            const payload = { status: name }
            APIS[API].updateAsset({ id: asset.id }, payload)
                .then(model => {

                    const assetIndex = version.assets.findIndex(a => a.id === asset.id);
                    const newAsset = [...version.assets];
                    newAsset[assetIndex] = model;
                    version.assets = newAsset;

                    const versionIndex = program.versions.findIndex(v => v.id === version.id);
                    const newVersions = [...program.versions];
                    newVersions[versionIndex] = version;
                    this.itemUpdated({
                        entity: "program",
                        model: {
                            ...program,
                            versions: newVersions,
                        }
                    });
                }, this.requestFailed);
        }
    }

    updateSubStatus(version, asset, childAsset, program, value) {
        return dispatch => {
            dispatch();

            const payload = { status: value }
            APIS[API].updateAsset({ id: childAsset.id }, payload)
                .then(model => {
                    const childAssetIndex = asset.childAssets.findIndex(c => c.id === childAsset.id);
                    const newChildAsset = [...asset.childAssets];
                    newChildAsset[childAssetIndex] = model;
                    asset.childAssets = newChildAsset;

                    const versionIndex = program.versions.findIndex(v => v.id === version.id);
                    const newVersions = [...program.versions];
                    newVersions[versionIndex] = version;
                    this.itemUpdated({
                        entity: "program",
                        model: {
                            ...program,
                            versions: newVersions,
                        }
                    });
                }, this.requestFailed);
        }
    }
    
    // Should make a generalized method and merge with the above method
    updateAudioLanguage(version, asset, childAsset, program, value) {
        return dispatch => {
            dispatch();

            const payload = { language: value }
            APIS[API].updateAsset({ id: childAsset.id }, payload)
                .then(model => {
                    const childAssetIndex = asset.childAssets.findIndex(c => c.id === childAsset.id);
                    const newChildAssets = [...asset.childAssets];
                    newChildAssets[childAssetIndex] = {
                        ...model,
                        canBeIgnored: newChildAssets[childAssetIndex].canBeIgnored
                    };
                    asset.childAssets = newChildAssets;

                    const versionIndex = program.versions.findIndex(v => v.id === version.id);
                    const newVersions = [...program.versions];
                    newVersions[versionIndex] = version;
                    this.itemUpdated({
                        entity: "program",
                        model: {
                            ...program,
                            versions: newVersions,
                        }
                    });
                }, this.requestFailed);
        }
    }

    exposureUpdate(exposure, version, program, payload) {
        {
            return dispatch => {
                dispatch();
               
                APIS[API].updateExposure({ id: exposure.id }, payload)
                    .then(model => {
                        const exposureIndex = version.exposures.findIndex(e => e.id === exposure.id);
                        const newExposures = [...version.exposures];
                        newExposures[exposureIndex] = model;
                        version.exposures = newExposures;

                        const versionIndex = program.versions.findIndex(v => v.id === version.id);
                        const newVersions = [...program.versions];
                        newVersions[versionIndex] = version;
                        this.itemUpdated({
                            entity: "program",
                            model: {
                                ...program,
                                versions: newVersions,
                            }
                        });
                    }, this.requestFailed);
            }
        }
    }

    setRegions(version, exposure, regions, program) {
        return dispatch => {
            dispatch();

            const payload = { regions };
            APIS[API].updateExposure({ id: exposure.id }, payload)
                .then(model => {
                    const exposureIndex = version.exposures.findIndex(e => e.id === exposure.id);
                    const newExposures = [...version.exposures];
                    newExposures[exposureIndex] = model;
                    version.exposures = newExposures;

                    const versionIndex = program.versions.findIndex(v => v.id === version.id);
                    const newVersions = [...program.versions];
                    newVersions[versionIndex] = version;
                    this.itemUpdated({
                        entity: "program",
                        model: {
                            ...program,
                            versions: newVersions,
                        }
                    });
                }, this.requestFailed);
        }
    }

    postComment(threadId, objectId, text, callback) {
        return dispatch => {
            dispatch();

            const payload = { text };
            APIS[API].postComment({ threadId, objectId }, payload)
                .then(response => {
                    const { pageIndex, numberOfItems, items, links } = response;
                    const appendToExistingItems = pageIndex > 0;
                    const nextPageUrl = getNextPageUrl(links);
                    this.itemsUpdated({ store: "threads", items, appendToExistingItems, numberOfItems, nextPageUrl });
                    if (callback && typeof callback === "function") {
                        callback();
                    }
                }, this.requestFailed);
        };
    }

    putComment(threadId, objectId, text, callback) {
        return dispatch => {
            dispatch();
            const payload = { text };
            APIS[API].putComment({ threadId, objectId }, payload)
                .then(response => {
                    const { pageIndex, numberOfItems, items, links } = response;
                    const appendToExistingItems = pageIndex > 0;
                    const nextPageUrl = getNextPageUrl(links);
                    this.itemsUpdated({ store: "threads", items, appendToExistingItems, numberOfItems, nextPageUrl });
                    if (callback && typeof callback === "function") {
                        callback();
                    }
                }, this.requestFailed);
        };
    }

    fetchComment(threadId) {
        return dispatch => {
            dispatch();

            if (threadId > 0) {
                APIS[API].fetchThreads({ id: threadId })
                    .then(comments => {
                        const { pageIndex, numberOfItems, items, links } = comments;
                        const appendToExistingItems = pageIndex > 0;

                        const nextPageUrl = getNextPageUrl(links);
                        this.itemsUpdated("threads", items, appendToExistingItems, numberOfItems, nextPageUrl);
                    });
            } else {
                this.itemsUpdated("threads", [], false, 0, null);
            }
        }, this.requestFailed
    };

    updateDistributionData(version, value) {
        return dispatch => {
            dispatch();

            const payload = { [JSON.stringify(version)]: { onHoldUntil: value } }

            APIS[API].updateDistributionData({ id: version.id }, payload)
                .then(model => {

                    const versionIndex = program.versions.findIndex(v => v.id === version.id);
                    const newVersions = [...program.versions];

                    newVersions[versionIndex] = model;
                    this.itemUpdated({
                        entity: "program",
                        model: {
                            ...program,
                            versions: newVersions,
                        }
                    });
                }, this.requestFailed)
        }
    }
    
    viewEventHistory(id, pathname = `traffic/programversions/${id}/events`, targetStore = null) {
        const route = {
            pathname,
        };
    }

    edit({ id, pathname = `traffic/programversions/${id}/packages`, search, returnTo, wideModal, typeId, query, modal = true, targetStore = null }) {
        const route = {
            pathname,
            search,
            query,
            state: {
                modal,
                wideModal,
                typeId,
                returnTo,
                targetStore,
            }
        };

        browserHistory.push(route)

        return route;
    }
    

    applySeasonChangesToEpisodes(season, version, applyTo, callback) {
        return (dispatch) => {
            dispatch();

            const payload = {
                seasonReference: season.seasonReference,
                seasonProgramId: season.id,
                seasonProgramVersionId: version.id,
                applyTo: applyTo,
            };

            APIS[API].applySeasonChangesToEpisodes(payload)
                .then(() => { // No content
                    if (typeof callback === "function") {
                        callback();
                    }
                });
        };
    }
}

export default alt.createActions(Actions);

// Helpers
function getCommand(command, entity, multiple = false) {
    if (typeof (entity) === "object") {
        const extra = multiple ? "s" : "";

        if (entity.parentEntity) {
            return command + entity.parentEntity.substr(0, 1).toUpperCase() + entity.parentEntity.substr(1) + entity.entity.substr(0, 1).toUpperCase() + entity.entity.substr(1) + extra;
        }
        entity = `${entity.entity}${extra}`;
    }
    return command + entity.substr(0, 1).toUpperCase() + entity.substr(1);
}

function getStore(entity, multiple = false) {
    if (typeof (entity) === "object") {
        const extra = multiple ? "s" : "";
        return `${entity.entity}${extra}`;
    }
    return entity;
}

function getNextPageUrl(links = []) {
    const nextLink = links.find(l => l.rel === "next" || l.Rel === "next");

    return nextLink
        ? nextLink.href || nextLink.Href
        : null;
}
import React from 'react'
import Menu from '@mui/material/Menu'

import EventPlannerComponent from '../component'
import { ItemActionsContext } from '../itemActionsContext'
import Button from '../../ui/controls/button'

const MenuComponent = ({
	className,
	children,
	name,
	onChange,
	_onChangeWithoutAddedPath,
	path = [],
	otherProps = {},
	itemData = {},
	matchCustomComponent = null,
}) => {

	const buttonRef = React.useRef();
	const [open, setOpen] = React.useState(false);

	const toggleOpen = React.useCallback(
		(e) => {
			e.preventDefault();
			e.stopPropagation();
			setOpen(currentOpen => !currentOpen);
		},
		[setOpen]
	);

	const links = children.map(c => (
		<EventPlannerComponent
			key={c.name}
			templateItem={c}
			onChange={onChange}
			_onChangeWithoutAddedPath={_onChangeWithoutAddedPath}
			path={[...path, name]}
			otherProps={{
				...otherProps,
				// We use onLinkClick because material-ui does not close the Menu when a MenuItem is clicked :(
				// It's used in the link component
				onLinkClick: () => {
					setOpen(false);
				},
			}}
			itemData={itemData}
			matchCustomComponent={matchCustomComponent}
		/>
	));

	const extraActions = React.useContext(ItemActionsContext);
	extraActions?.forEach(action => {
		links.push(
			<EventPlannerComponent
				key={action.key}
				templateItem={{
					componentType: "link",
					displayName: action.text,
				}}
				otherProps={{
					...otherProps,
					isExtraAction: true,
					onLinkClick: () => {
						if (action.confirm) {
							const confirm = window.confirm(action.confirmText ?? "Are you sure?");
							if (confirm) {
								action.onClick(itemData);
								setOpen(false);		
							}
						} else {
							action.onClick(itemData);
							setOpen(false);
						}
					},
				}}
				itemData={itemData}
			/>
		)
	});

	return (
		<div className={className}>
			<Button
				buttonRef={buttonRef}
				onClick={toggleOpen}
				type="more_vert large-icon"
				noBackground	
				style={{ display: "inline-block", width: "50px", height: "50px" }}
			/>
			<Menu
				className="c6-actions-menu"
				anchorEl={buttonRef?.current}
				open={open}
				onClose={toggleOpen}
			>
				{links}
			</Menu>
		</div>
	);
};

export default MenuComponent;
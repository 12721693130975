import * as MetadataAPI from '../../../apis/metadata'
import { useQuery } from '@tanstack/react-query'

interface args {
    filter?: object;
    enabled?: boolean;
}

const useMetadataPrograms = ({ filter = {}, enabled = true }: args = {}) => {
    const filters = {
        ...filter,
    };
    return useQuery(
        ["metadata", "programs", filters],
        () => MetadataAPI.fetchPrograms(filters),
        { enabled }
    );
};

export default useMetadataPrograms;

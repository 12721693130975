import React from 'react'
import { useQuery } from '@tanstack/react-query'
import Qs from 'qs'

import Item from '../../../components/cms/item'

import { browserHistory } from 'browserHistory'
import * as CMSAPI from '../../../apis/cms'
import useList from '../../../core/hooks/useList'
import GenericListApp from '../../../components/genericApp/listApp'

import '../events/app.css'

const MODULE = "eventplanner";
const DATASTORE = "events-custom";
const TEXT_HEADING = null;
const TEXT_BACK_BUTTON = null;
const TEXT_EMPTY = "No items? It's about time to create some then!";

function ListApp(props) {

	const api = props.route.api ?? "cms";

	const FILTER_DEFAULTS = {
		api,
		_entity: props.location.query?.template ?? props.route.defaultTemplate ?? "eventslist",
		_module: props.location.query?.module ?? props.route.defaultModule ?? "eventplanner",
		filter: props.location.query?.filter ?? props.route.defaultFilter ?? "",
		pageSize: 30,
	};

	const listState = useList({
		api,
		listKey: DATASTORE,
		fetchData: (filters) => CMSAPI.fetchCMSUrl(getFetchUrl(filters), filters._module, api),
		fetchSingle: CMSAPI.fetchCMSEntity,
		patchData: CMSAPI.updateCMSEntity,
		runAction: CMSAPI.runAction,
		updateDataExtraParams: {
			api: api,
			_entity: listState?.filters.values._entity ?? props.location.query?.template ?? "eventslist",
			_module: listState?.filters.values._module ?? props.location.query?.module ?? "eventplanner",
		},
		filterDefaults: FILTER_DEFAULTS,
	});

	const template = useQuery(
		["template-test", { api, _entity: listState.filters.values._entity, filter: listState.filters.values.filter, _module: listState.filters.values._module }],
		() => {
			setTimeout(() => {
				browserHistory.replace({
					...props.location,
					query: {
						template: listState.filters.values._entity,
						filter: listState.filters.values.filter,
						module: listState.filters.values._module,
					},
				});
			});
			return CMSAPI.fetchCMSTemplate({ api, _entity: listState.filters.values._entity, _module: listState.filters.values._module });
		},
	);

	const [templateString, setTemplateString] = React.useState({});

	const filterConfig = {
		_entity: {
			title: "Template",
			type: "text",
			alwaysVisible: true,
			style: {
				marginRight: "20px",
			},
			onChange: () => {},
			onKeyDown: (e) => {
				if (e.key === "Enter") {
					listState.filters.updateMethods._entity(e.target.value);
				}
			},
		},
		_module: {
			title: "Module",
			type: "text",
			alwaysVisible: true,
			style: {
				marginRight: "20px",
			},
			onChange: () => {},
			onKeyDown: (e) => {
				if (e.key === "Enter") {
					listState.filters.updateMethods._module(e.target.value);
				}
			},
		},
		templateString: {
			title: "Template String",
			type: "text",
			alwaysVisible: true,
			onChange: () => {},
			onKeyDown: (e) => {
				if (e.key === "Enter") {
					try {
						setTemplateString(JSON.parse(e.target.value));
					} catch(e) {
						console.error(e);
					}
				}
			},
		},
		filter: {
			type: "text",
			placeholder: "Filter",
			alwaysVisible: true,
			style: {
				display: "block",
				width: "100%",
			},
			onChange: () => {},
			onKeyDown: (e) => {
				if (e.key === "Enter") {
					listState.filters.updateMethods.filter(e.target.value);
				}
			},
		}
	};

	return (
		<GenericListApp
			{...props}
			module={MODULE}
			datastore={DATASTORE}
			state={listState}
			textHeading={TEXT_HEADING}
			textBackButton={TEXT_BACK_BUTTON}
			textEmpty={TEXT_EMPTY}
			filterConfig={filterConfig}
			collapseFiltersDefault={false}
			virtualize={false}
			className="c6-eventplanner-events"
		>
			<Item
				template={Object.keys(templateString).length ? templateString : template.data}
				onChange={listState.onChange}
			/>
		</GenericListApp>
	);
}

export default class TemplateApp extends React.Component {
	render() {
		return (
			<ListApp
				{...this.props}
			/>
		);
	}
}


function getFetchUrl(filters) {
	const { pageSize, pageIndex, _entity, filter } = filters;
	const query = Qs.stringify({ pageSize, pageIndex, filter }, { skipNulls: true });
	return `views/${_entity}?${query}`;
}
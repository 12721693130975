import React from 'react'
import moment from 'moment'

import Item from '../../../../components/cms/item'
import Modals from '../../modals/modals'

import * as CMSAPI from '../../../../apis/cms'
import useList from '../../../../core/hooks/useList'
import GenericListApp from '../../../../components/genericApp/listApp'
import { ItemActionsContext, useItemActionsState } from '../../../../components/cms/itemActionsContext'

import '../../events/app.css'
import useEditorNavigation from '../../../../core/hooks/useEditorNavigation'
import useCMSTemplate from '../../../../core/queries/cms/useCMSTemplate'

const MODULE = "eventplanner";
const DATASTORE = "series";
const TEXT_HEADING = "Series";
const TEXT_BACK_BUTTON = "Administration";
const TEXT_EMPTY = "No series? It's about time to create some then!";
const TEXT_CREATE = "Create event";
const ICON_CREATE = "add";
const PATHNAME_CREATE = null;//"eventplanner/cms-events/create";

function ListApp(props) {
	const _entity = `serieslist`;
	const template = useCMSTemplate(_entity);

	const filterConfig = {
		searchText: {
			type: "search",
		},
	};

	const FILTER_DEFAULTS = {
		_entity,
		pageSize: 30,
		searchText: "",
	};

	const listState = useList({
		listKey: DATASTORE,
		fetchData: CMSAPI.fetchCMSEntities,
		fetchSingle: CMSAPI.fetchCMSEntity,
		updateData: CMSAPI.updateCMSEntity,
		patchData: CMSAPI.updateCMSEntity,
		runAction: CMSAPI.runAction,
		entity: _entity,
		updateDataExtraParams: { _entity },
		filterDefaults: FILTER_DEFAULTS,
	});

	const [modalData, setModalData] = React.useState(null);
	const closeModal = React.useCallback(
		() => setModalData(null),
		[setModalData]
	);

	const actions = useItemActionsState({
		extraActions: [],
		_entity,
		refetch: listState.refetch,
		setModalData,
		collection: template?.data?.collection,
	});

	useEditorNavigation(listState);

	return (
		<ItemActionsContext.Provider value={actions}>
			<GenericListApp
				{...props}
				module={MODULE}
				datastore={DATASTORE}
				state={listState}
				textHeading={TEXT_HEADING}
				textBackButton={TEXT_BACK_BUTTON}
				textEmpty={TEXT_EMPTY}
				textCreate={TEXT_CREATE}
				iconCreate={ICON_CREATE}
				filterConfig={filterConfig}
				pathnameCreate={PATHNAME_CREATE}
				collapseFiltersDefault={true}
				padding={false}
				virtualize={false}
			>
				<Item
					template={template.data}
					onChange={listState.onChange}
					closeModal={closeModal}
				/>
			</GenericListApp>
			<Modals
				modalData={modalData}
				onClose={closeModal}
			/>
		</ItemActionsContext.Provider>
	);
}

export default class CMSSeriesApp extends React.Component {
	render() {
		return (
			<ListApp
				{...this.props}
			/>
		);
	}
}

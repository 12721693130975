import React from 'react'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import TextField from '@mui/material/TextField'

import GroupMenuNew from '../controls/groupMenuNew'
import MuiSelect from '../editorWidgets/muiSelect'

import * as Alert from '../../../core/services/alert'
import * as SelectionsData from '../../../apis/selections'
import { syncDeepFindGroup } from '../../../utils/groups'

import './linksNew.css'

export default class Links extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			links: [...props.formData],
			isLoadingGroups: false,
			groups: [],
			isLoadingLists: false,
			lists: [],
		};
	}
	
	componentDidMount() {
		this.fetchGroups();
	}

	fetchGroups = () => {
		this.setState({ isLoadingGroups: true });
		SelectionsData.fetchGroups({ returnAsTree: true, groupTypeId: 2, includeHidden: true })
			.then(response => {
				this.setState({ groups: response.items, isLoadingGroups: false });
			}, error => {
				this.setState({ isLoadingGroups: false });
				Alert.displayAlert("error", error.exceptionMessage);
			});
	}

	fetchLists = (sectionId) => {
		this.setState({ isLoadingLists: true, lists: [] });
		SelectionsData.fetchLists({ sectionId })
			.then(response => {
				this.setState({ lists: response.items, isLoadingLists: false });
			}, error => {
				this.setState({ isLoadingLists: false });
				Alert.displayAlert("error", error.exceptionMessage);
			});
	}

	// All of these update-methods could be made prettier if we just ignored the index and only handled a single link...
	handleTypeChange = (index, e) => this.updateLink(index, { type: e.target.value });
	handleUrlChange = (index, e) => this.updateLink(index, { ...this.state.links[index], url: e.target.value });
	handleGroupChange = (index, group) => this.updateLink(index, { ...this.state.links[index], id: group.id });
	handleListChange = (index, listId) => this.updateLink(index, { ...this.state.links[index], id: listId });

	handleListGroupChange = (index, group) => {
		this.fetchLists(group.id);
		this.updateLink(index, { ...this.state.links[index], groupId: group.id });
	}

	handleClearList = (index, e) => {
		e.preventDefault();
		this.updateLink(index, { ...this.state.links[index], id: undefined });
	}

	updateLink = (index, newLink) => {
		const links = [...this.state.links];
		links.splice(index, 1, newLink);
		this.setState({ links });
		this.props.onChange(links);
	}

	render() {
		const { schema } = this.props;
		const { title, description } = schema;

		// Extracts the configured link types
		// const types = schema.items[0].properties.type;
		// const linkTypes = types.enum.map((type, index) => ({
		// 	value: type,
		// 	label: types.enumNames[index]
		// }));
		const linkTypes = schema.items[0].properties.type.oneOf;

		return (
			<fieldset className="field field-array c6-links-new">
				<legend>{title}</legend>
				<p className="field-description">{description}</p>
				{this.state.links.map((l, index) => (
					<div
						key={`link-${l.type}-${l.id}`}
						style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}
					>
						<RadioGroup
							className="link-types"
							name="linkType"
							value={l.type}
							onChange={this.handleTypeChange.bind(this, index)}
						>
							{linkTypes.map(({ const: value, title: label }) => (
								<FormControlLabel
									key={`rb-${value}`}
									value={value}
									label={label}
									control={<Radio />}
									style={{ height: "2rem" }}
								/>
							))}
						</RadioGroup>
						<div className="link-value">
							{l.type === "Url" && renderUrlController(l, index, this.handleUrlChange)}
							{l.type === "Section" && renderSectionController(l, index, this.handleGroupChange, this.state.groups)}
							{l.type === "List" && renderListController(l, index, this.handleListChange, this.handleListGroupChange, this.handleClearList, this.state.groups, this.state.lists)}
						</div>
					</div>
				))}
			</fieldset>
		);
	}
}

function renderUrlController(link, index, onChange) {
	return (
		<TextField
			variant="standard"
			value={link.url ?? ""}
			onChange={onChange.bind(this, index)}
			label="Enter a URL"
			fullWidth={true}
		/>
	);
}

function renderSectionController(link, index, onChange, groups) {
	return (
		<GroupMenuNew
			groups={groups}
			onSelectGroup={onChange.bind(this, index)}
			selectedGroup={syncDeepFindGroup(link.id, groups)}
		/>
	);
}

function renderListController(link, index, onChange, onGroupChange, onClearList, groups, lists) {
	return (
		<React.Fragment>
			{link.id && !link.groupId && (
				<p>{link.displayName} <button onClick={onClearList.bind(this, index)}>Change</button></p>
			)}
			{!(link.id && !link.groupId) && (
				<div style={{ display: "flex", alignItems: "center" }}>
					<div style={{ paddingTop: "20px" }}>
						<GroupMenuNew
							groups={groups}
							onSelectGroup={onGroupChange.bind(this, index)}
							selectedGroup={syncDeepFindGroup(link.groupId, groups)}
						/>
					</div>
					<MuiSelect
						value={link.id}
						label={link.groupId ? "Select a list" : "First select a section"}
						disabled={!link.groupId}
						onChange={onChange.bind(this, index)}
						options={{
							dataSource: lists ?? []
						}}
					/>
				</div>
			)}
		</React.Fragment>
	);
}
import debounce from 'lodash/debounce'
import { CONTENT, LINK } from '../../../components/actions'

import Actions from '../actions'

export default {
	targetComponent: "Item",
	actions: [
		{
			title: "Display delivery items",
			method: Actions.toggleDeliveryItems,
			type: CONTENT,
			hidden: (data) => data.status !== "Complete"
		},
	]
};
import { get, put, post, patch, del } from '../core/c6'

const API = "vodcore";

/* **************** Generic ***************** */
export const fetchUrl = url => get(API, url);

/* **************** Packages ***************** */
export const fetchPackages 	    = filters 	=> get(API, "packages", filters);
export const fetchPackage 	    = ({id}) 	=> get(API, `packages/${id}`);
export const blockPackage     	= ({id})	=> put(API, `packages/${id}/block`);
export const unblockPackage   	= ({id})	=> put(API, `packages/${id}/unblock`);
export const unpublishPackage 	= ({id})	=> put(API, `packages/${id}/unpublish`);
export const republishPackage 	= ({id})	=> put(API, `packages/${id}/republish`);
export const resendPackage    	= ({id})	=> put(API, `packages/${id}/resend`);
export const updatePackage		= ({id})	=> put(API, `packages/${id}/update`);

/* **************** Deliveries ***************** */
export const fetchDeliveries = filters => get(API, "deliveries", filters);

/* **************** DeliveryItems ***************** */
export const fetchDeliveryItems = filters => get(API, "deliveryItems", filters);
export const fetchDeliveryItem  = filters => get(API, `deliveryItems/${id}`);

/* **************** Services ***************** */
export const fetchServices 	        = filters 	=> get(API, "services", filters);
export const fetchService 	        = ({id}) 	=> get(API, `services/${id}`);
export const createOrUpdateService  = (payload) => put(API, "services", payload);

/* **************** Destinations ***************** */
export const createOrUpdateDestination = (payload) => put(API, "destinations", payload);

/* **************** Operators ***************** */
export const fetchOperators = (filters) => get(API, "operators", filters);

/* **************** Libraries ***************** */
export const fetchLibraries = (filters) => get(API, "libraries", filters);

/* **************** Versions ***************** */
export const fetchVersions 	= filters 	=> get(API, "versions", filters);

/* **************** Sites ***************** */
export const fetchSites 	= filters 	=> get(API, "sites", filters);
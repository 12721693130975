import React from 'react'
import moment from 'moment'

import ContentItem, { Content } from '../../../components/list/contentItem'
import InlineHeading from '../../../components/ui/inlineHeading'
import Label from '../../../components/ui/label'

import '../../../components/listItems/standard.css'
import { getProgramTitle, renderImdbIdOrLinks } from '../utils'

const Program = (props) => {

	const {
		publishDate,
		publishNotes,
		reviews,
		id,
		imdb,
		title,

		customer,
		location,
	} = props;

	return (
		<ContentItem data={props}>
			<Content>
				<h1>{getProgramTitle(props)}</h1>
				<p>
					{renderImdbIdOrLinks(imdb, title, id, customer, location)}
				</p>
			</Content>
			<Content>
				{reviews?.length === 1 && <p>1 review</p>}
				{reviews?.length > 1 && <p>{reviews.length} reviews</p>}
			</Content>
			<Content>
				{publishDate && <InlineHeading title="Publish date">{moment(publishDate).format("YYYY-MM-DD")}</InlineHeading>}
			</Content>
			<Content>
				{publishNotes && <InlineHeading title="Notes">{publishNotes}</InlineHeading>}
			</Content>
		</ContentItem>
	);
};

export default Program;
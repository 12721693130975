import React from 'react'
import { Route, IndexRoute } from 'react-router'

import Module         from '../app'

import Dashboard	  from './dashboard/app'

import Logins         from './logins/app'
import LoginEditor    from './logineditor/app'
import LoginModules   from './loginmodules/app'

import Modules        from './modules/app'
import ModuleEditor   from './moduleeditor/app'
import ModuleServices from './moduleservices/app'

import Services       from './services/app'
import ServiceEditor  from './serviceeditor/app'

import Resources      from './resources/app'
import ResourceEditor from './resourceeditor/app'

import RoleEditor     from './roleeditor/app'
import RoleResources  from './roleresources/app'

import ExternalLogins from './externallogins/app'
import ExternalEditor from './externaleditor/app'

export default class ShieldModule {
	static routes() {
		return (
			<Route path="shield" component={Module} module="shield">
				<Route path="admin" app="modules">
					<IndexRoute component={Dashboard} />

					<Route path="modules" app="modules">
						<IndexRoute component={Modules} />
						<Route path="(:id)/edit" component={ModuleEditor} access="editor" modal={true} />
						<Route path="create" component={ModuleEditor} access="editor" modal={true} />

						<Route path="(:id)/services" component={ModuleServices} />

						<Route path="(:moduleId)/roles/(:roleId)/resources" component={RoleResources} />
					</Route>

					<Route path="roles" app="modules">
						<Route path="(:id)/edit" component={RoleEditor} access="editor" modal={true} />
						<Route path="create" component={RoleEditor} access="editor" modal={true} />
					</Route>

					<Route path="services" app="services">
						<IndexRoute component={Services} />
						<Route path="(:id)/edit" component={ServiceEditor} access="editor" modal={true} />
						<Route path="create" component={ServiceEditor} access="editor" modal={true} />

						<Route path="(:serviceId)/resources" component={Resources} />
					</Route>

					<Route path="resources" app="services">
						<Route path="(:id)/edit" component={ResourceEditor} access="editor" modal={true} />
						<Route path="create" component={ResourceEditor} access="editor" modal={true} />
					</Route>
				</Route>

				<Route path="logins" app="users">
					<IndexRoute component={Logins} />
					<Route path="(:id)/edit" component={LoginEditor} access="editor" modal={true} />
					<Route path="create" component={LoginEditor} access="editor" modal={true} />
					<Route path="(:id)/createcopy" component={LoginEditor} access="editor" modal={true} />

					<Route path="(:id)/modules" component={LoginModules} />
				</Route>
				<Route path="logins-users" app="users" typeFilter="user">
					<IndexRoute component={Logins} />
					<Route path="(:id)/edit" component={LoginEditor} access="editor" modal={true} />
					<Route path="create" component={LoginEditor} access="editor" modal={true} />
					<Route path="(:id)/createcopy" component={LoginEditor} access="editor" modal={true} />

					<Route path="(:id)/modules" component={LoginModules} />
				</Route>
				<Route path="logins-apikeys" app="users" typeFilter="apikey">
					<IndexRoute component={Logins} />
					<Route path="(:id)/edit" component={LoginEditor} access="editor" modal={true} />
					<Route path="create" component={LoginEditor} access="editor" modal={true} />
					<Route path="(:id)/createcopy" component={LoginEditor} access="editor" modal={true} />

					<Route path="(:id)/modules" component={LoginModules} />
				</Route>

				<Route path=":externalName">
					<IndexRoute component={ExternalLogins} />
					<Route path="logins/(:id)/edit" component={ExternalEditor} access="editor" modal={true} />
					<Route path="logins/create" component={ExternalEditor} access="editor" modal={true} />
					<Route path="logins/(:id)/createcopy" component={ExternalEditor} access="editor" modal={true} />
				</Route>
			</Route>
		);
	}
}
import React, { PureComponent } from 'react'
import { Link } from 'react-router'
import PropTypes from 'prop-types'
import moment from 'moment'
import upperFirst from 'lodash/upperFirst'

import Preview from '../../../components/assets/preview'
import InlineHeading from '../../../components/ui/inlineHeading'
import Period from '../../../components/ui/period'
import Status from '../../../components/ui/status'

import Actions from '../actions'
import { renderValidations } from '../shared/listItem'
import Const from '../../../core/constants'

import appConfig from 'config'

import styles from '../../../components/listItems/standard.css'

export default class DeliveryContent extends PureComponent {

	static propTypes = {
		id: PropTypes.number,
		changeType: PropTypes.string,
		created: PropTypes.string,
		handled: PropTypes.string,
		status: PropTypes.string,
	}

	constructor(props) {
		super(props);
	}

	render() {
		const {
			id,
			changeType,
			created,
			handled,
			status,
			statusDescription,
			assets,
			package: deliveryPackage,
			metadata,
			validations,
		} = this.props;

		const handledMoment = handled && moment(handled);
		const isoHandled = handledMoment && handledMoment.format(Const.DATE_TIME_FORMAT);
		const createdMoment = moment(created);
		const isoCreated = createdMoment.format(Const.DATE_TIME_FORMAT);

		const { title, icon } = getTitleAndIcon(changeType, status);
		const isDelivery = status === "Complete";

	    return (
			<div className={`flex flex-dyn-1`}>
				<div className={`c6-icon-container large-icon ${icon}`}></div>
				<div className="c6-content">
					<h1>{title}</h1>
					<p className={isoHandled ? "handled" : ""}>
						{isoHandled && <time title={`Event was handled ${isoHandled} (created ${isoCreated})`} dateTime={isoHandled}>{handledMoment.format("D MMM HH:mm")}</time>}
						{!isoHandled && <time title={`Event is not yet handled. Event was created ${isoCreated}`} dateTime={isoCreated}>Not yet handled.</time>}
					</p>
				</div>
				<div className="c6-content right">
					{renderValidations(validations, assets, deliveryPackage.service, metadata)}
				</div>
			</div>
		);
  	}
}

// HELPERS
export function getTitleAndIcon(changeType, status) {
	const icon = status === "Complete" && "icon-cube-send" || changeType === "Created" && "icon-fiber_new" || null;
	let title = changeType;
	switch (changeType) {
		case "Created": 				title = "Content created / First delivery"; break;
		case "MetadataChanged": 		title = "Metadata updated"; 				break;
		case "PublishWindowChanged": 	title = "License period changed"; 			break;
		case "AssetChanged": 			title = "Video / subs updated"; 			break;
		case "Unpublished": 			title = "Content recalled"; 				break;
		case "Canceled": 				title = "Content recalled"; 				break;
		case "AssetMetadataChanged": 	title = "End credits timestamp provided"; 	break;
		case "Resend": 					title = "Forced delivery by a user"; 		break;
		case "Update": 					title = "Forced delivery by a user"; 		break;
	}
	
	return { title, icon };
}

function getFormattedStatusDescription(statusDescription) {
	if (!statusDescription || !statusDescription.length) {
		return null;
	}

	const reasons = statusDescription
		.replace(/\.$/, "")
		.split(".")
		.map(str => str.replace(/Missing/g, "").trim())
		.map(upperFirst)
		.join(", ");

	return (
		<InlineHeading title="REASON">
			{reasons}
		</InlineHeading>
	);
}
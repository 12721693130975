import React from 'react'

import { Filter, Left, Right } from '../../../components/filter'
import Switch from '../../../components/ui/controls/switch'
import Search from '../../../components/ui/controls/search'
import Dropdown from '../../../components/ui/controls/dropdown'
import Button from '../../../components/ui/controls/button'

import Actions from '../actions'
import { getSearchPayload, getRegion, getAttention, getFrom, getPremiere, getTypes, getProvider, getPriority, getDistributor, getOrder, getFilter } from '../utils'

export default ({
	searchText,
	filters,
	unselectedFilters,
	handleFilter,
	exposureTypes,
	distroTypes,
	services = [],
	serviceId,
	isLoading
}) => {
	const service = serviceId && services.find(s => s.id == serviceId);
	return (
		<Filter>
			<Left>
				<h1>
					Programs
				</h1>
				<Search
					onChange={handleFilter.bind(this, "search")}
					placeholder="Search"
					searchText={searchText}
				/>
			</Left>
			<Right>
				<Switch
					name="programTypes"
					title="Type"
					unselected={unselectedFilters}
					onChange={handleFilter.bind(this, "filter")}
					states={getTypes()}
					currentState={filters.filter.programTypes}
				/>
				<Dropdown
					name="from"
					title="Period"
					unselected={unselectedFilters}
					onChange={handleFilter.bind(this, "filter")}
					states={getFrom()}
					currentState={filters.filter.from}
				/>
				<Switch
					name="premiere"
					title="Premieres"
					unselected={unselectedFilters}
					onChange={handleFilter.bind(this, "filter")}
					states={getPremiere()}
					currentState={filters.filter.premiere}
				/>
				<Dropdown
					name="exposureType"
					title="Exposure Type"
					unselectedFilters={unselectedFilters}
					onChange={handleFilter.bind(this, "filter")}
					states={expType(exposureTypes)}
					currentState={filters.filter.exposureType}
				/>
				<Dropdown
					name="distributor"
					title="Distributor"
					unselectedFilters={unselectedFilters}
					onChange={handleFilter.bind(this, "filter")}
					states={distro(distroTypes)}
					currentState={filters.filter.distributor}
				/>
				<Dropdown
					name="region"
					title="Region"
					unselectedFilters={unselectedFilters}
					onChange={handleFilter.bind(this, "filter")}
					states={getRegion()}
					currentState={filters.filter.region}
				/>
				<Dropdown
					name="priority"
					title="Priority"
					unselectedFilters={unselectedFilters}
					onChange={handleFilter.bind(this, "filter")}
					states={getPriority()}
					currentState={filters.filter.priority}
				/>
				{/* <Switch
					name="owner"
					title="Owner"
					unselected={unselectedFilters}
					onChange={handleFilter.bind(this, "filter")}
					states={getProvider()}
					currentState={filters.filter.owner}
				/> */}
				<Switch
					name="isPlanned"
					title="Filter"
					unselected={unselectedFilters}
					onChange={handleFilter.bind(this, "filter")}
					states={getFilter()}
					currentState={filters.filter.isPlanned}
				/>
				<Switch
					name="needsAttention"
					title="Needs attention"
					unselected={unselectedFilters}
					onChange={handleFilter.bind(this, "filter")}
					states={getAttention()}
					currentState={filters.filter.needsAttention}
				/>
				<Switch
					name="orderBy"
					title="Order by"
					unselected={unselectedFilters}
					onChange={handleFilter.bind(this, "filter")}
					states={getOrder()}
					currentState={filters.filter.orderBy}
				/>
				{!isLoading && (
					<Button
						title="Export"
						type="file-excel"
						onClick={() => {
							Actions.ExportExcel(getSearchPayload({ filters }, serviceId))
						}}
					/>
				)}
			</Right>
		</Filter>
	);
}

const expType = (exposureTypes) => {
	return [
		{
			key: "",
			text: "Any",
			description: ""
		},
		...exposureTypes.map(et => ({
			key: et.reference,
			text: et.name,
			description: "",
		}))
	];
}

const distro = (distroTypes) => {
	return [
		{
			key: "",
			text: "Any",
			description: ""
		},
		...distroTypes.map(di => ({
			key: di.reference,
			text: di.name,
			description: "",
		}))
	];
}
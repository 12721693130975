import React from 'react'
import { Link } from 'react-router'
import { syncDeepFindGroup, getGroupLineage } from '../../../utils/groups'

import './linksToSectionsContainingList.css'

export default function LinksToSectionsContainingList({ currentSection, sectionsContainingList, allSections, onLinkClick }) {
	const [expand, setExpand] = React.useState(false);

	if (sectionsContainingList.isLoading) {
		return <div className="links-to-sections"></div>;
	}

	const otherSections = sectionsContainingList.items.filter(s => currentSection === null || s.id !== currentSection.id);

	const description = (
		<span>
			{currentSection === null && sectionsContainingList.items.length === 0 && "This list is currently not published at a section."}
			{sectionsContainingList.items.length > 0 && (
				<span>
					The content from this list is available&nbsp;
					{currentSection !== null && `on the section "${getGroupLineage(currentSection, allSections)}"`} {/*${currentSection.displayName ?? currentSection.reference}*/}
					{currentSection !== null && otherSections.length > 0 && " and "}
					{otherSections.length > 0 && <a className="c6-link" onClick={() => setExpand(!expand)}>here...</a>}
				</span>
			)}
		</span>
	);

	const links = otherSections.map(section => {
		const parentSection = section.parentSectionId > 0 && syncDeepFindGroup(section.parentSectionId, allSections);
		return (
			<p key={section.id}>
				{parentSection && getGroupLineage(parentSection, allSections)}
				{parentSection && " / "}
				<Link
					className="c6-link"
					to={{ pathname: `/selections/service/${section.serviceId}`, query: { groupId: section.id } }}
					onClick={onLinkClick}
				>
					{section.displayName ?? section.reference}
				</Link>
			</p>
		);
	});

	return (
		<div className={`links-to-sections ${expand ? "expand" : ""}`}>
			<div className="wrapper">
				<p className="description">{description}</p>
				{expand && (
					<div className="links">
						{links}
						<p className="close"><a className="c6-link icon-expand_less" onClick={() => setExpand(false)}>Close list</a></p>
					</div>
				)}
			</div>
		</div>
	);
}
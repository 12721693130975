import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'

import styles from '../../../components/listItems/standard.css'
// import Label from '../../../components/ui/label'
import Preview from '../../../components/assets/preview'
import ContentItem, { Content } from '../../../components/list/contentItem'

// import { AllRegions } from '../../../core/constants'
import Const from '../../../core/constants'

export default class ListContent extends React.Component {

	render() {
		const {
			// list,
			data,
		} = this.props;

		const {
			// assets = [],
			// start,
			// type,

			displayName,
			mediaType,
			start,
			end,
			assets = [],
		} = data;

		// const title = getTitle(this.props, list);
		// const timezone = list.version && AllRegions.find(r => r.id === list.version.id)?.momentTimezone;

	    return (
			<ContentItem>
                {/* {type === "program" && <Preview imageGuid={assets[0]?.guid} />} */}
				{/* <Content>
					<h1 dangerouslySetInnerHTML={{ __html: title }}></h1>
					{timezone && <p>{moment(start).tz(timezone).format("DD.MM.YYYY, HH:mm")}</p>}
				</Content> */}

				<Preview imageGuid={assets[0]?.guid} />
				<Content>
					<h1>{displayName}</h1>
					<p>
						{mediaType === "regular" && <Label status="undefined">Original</Label>}
						{mediaType === "dubbed" && <Label status="headsup">Dubbed</Label>}
					</p>
				</Content>
				<Content>
					{getPeriod(start, end)}
				</Content>
			</ContentItem>
		);
  	}
}

// function getTitle({ localizations, displayName }, { version: listVersion }) {
// 	const localization = listVersion && localizations.find(l => l.version.id === listVersion.id);
// 	return localization?.title;
// }

function getPeriod(start, end) {
	const now = moment();
	let status = "", hoverTitle;
	if (start && now.isBefore(start)) {
		status = "status-upcoming";
		hoverTitle = "Upcoming";
	}
	if (end && now.isAfter(end)) {
		status = "status-expired";
		hoverTitle = "Expired";
	}
	return (
		<p className={status} title={hoverTitle}>
			{start && <time dateTime={start}>{moment(start).format(Const.PERIOD_FORMAT)}</time>}
			{end && <time dateTime={end}>{moment(end).format(Const.PERIOD_FORMAT)}</time>}
		</p>
	);
}
import React from 'react'
import { useQuery } from '@tanstack/react-query'

import * as CMSAPI from '../../../apis/cms'

import './checkbox.css'
import './checkboxContainer.css'

const CheckboxContainerMultiple = (props) => {
	const {
		className,
		displayName,
		name,
		readOnly,
		style,
		onChange,
		children,
		dataType,
		value,
		itemData,
	} = props;

	const isSelected = !!(dataType === "association" ? value : children.find(c => c.name === "selected")?.value);
	const associations = dataType === "association" ? [props] : children.filter(c => c.dataType === "association");
	const filters = children.filter(c => c.componentType === "filter");

	const onEnable = React.useCallback(async () => {
        if (!readOnly) {
            await onChange({ ...value, selected: true });
        }
	}, [onChange, name, readOnly]);

	const onDisable = React.useCallback(async () => {
        if (!readOnly) {
            let newValue;
            if (dataType === "association") {
                newValue = null;
            } else {
                newValue = {
                    ...value,
                    selected: false,
                };
                const childAssociations = children.filter(c => c.dataType === "association");
                childAssociations.forEach(a => {
                    newValue[a.name] = a.multiValue ? [] : null;
                });
            }
            await onChange(newValue);
        }
	}, [onChange, name, readOnly]);

    const onClick = (e) => {
		e.stopPropagation();
		if (isSelected) {
            onDisable();
		} else {
			onEnable();
		}
	};

	const [menuOpen, setMenuOpen] = React.useState(null);

	return (
		<div
			className={`
				${className}
				c6-cms-checkbox
				checkbox-${name}
				complex-attribute
				${isSelected ? "enabled has-value" : ""}
				${readOnly ? "disabled" : ""}
				${style?.visible ?? true ? "" : "hidden"}
			`.trim().replace(/[\t\n]/g, " ").replace(/\s{2,}/g, " ")}
		>
            <div onClick={onClick}>
                <label>
                    <span className="enabled-icon icon-check"></span>
                    {displayName ?? name}
                </label>
                {isSelected && (
					<div className="complex-attribute-value">
						<div className="value-presentation">
							{associations.map(ea => (
								<Association
									key={ea.name}
									association={ea}
									onChange={onChange}
									parentName={dataType === "association" ? null : name}
									componentValue={value}
									readOnly={readOnly}
									filter={filters?.find(f => f.name === `${ea.name}-filter`)}
									itemId={itemData?.id}
									menuOpen={menuOpen}
									setMenuOpen={setMenuOpen}
								/>
							))}
						</div>
					</div>
				)}
            </div>
		</div>
	);
};

export default React.memo(CheckboxContainerMultiple);

function Association({ association, filter, onChange, parentName, componentValue, readOnly, itemId, menuOpen, setMenuOpen }) {
	const isOpen = menuOpen === association.name;
	const useNativeSelect = association.options?.useNativeSelect;

	const { data, fetchStatus: valueFetchStatus } = useQuery(
		[association.valuesUrl],
		() => CMSAPI.fetchCMSUrl(association.valuesUrl),
		{ staleTime: 5 * 60 * 1000, enabled: !!association.valuesUrl?.length },
	);
	const associationValues = data?.items;

    const filterUrl = getFilterUrl(filter);
    const { data: unavailableOptions, fetchStatus: filterFetchStatus } = useQuery(
        [filterUrl],
        () => CMSAPI.fetchCMSUrl(filterUrl),
        // Use a short staleTime so that the list is refetched every time the user opens the menu
        { staleTime: 1000, enabled: isOpen && !!filterUrl.length },
    );

	const isLoading = menuOpen && (valueFetchStatus === "fetching" || filterFetchStatus === "fetching");

	const selectRef = React.useRef();
	const onSelectClick = React.useCallback(
		(e) => {
			e.stopPropagation();
			e.preventDefault();
			if (!readOnly) {
				setMenuOpen(association.name);
			}
		},
		[readOnly, setMenuOpen]
	);
	
	const closeSelect = React.useCallback(
		() => setMenuOpen(null),
		[setMenuOpen]
	);

	React.useEffect(() => {
		if (useNativeSelect) {
			return;
		}

		if (isOpen) {
			window.addEventListener("click", closeSelect);
			if (selectRef.current) {
				checkSelectPosition(selectRef.current);
			}
		} else {
			window.removeEventListener("click", closeSelect);
		}

		return () => window.removeEventListener("click", closeSelect);
	}, [isOpen, useNativeSelect]);

	const onAssociationChange = React.useCallback(
		async id => {
			if (id === association.value?.id) {
				setMenuOpen(null);
				return;
			}

			if (!readOnly) {
				const data = {
					...componentValue,
					[association.name]: { id },
				};
				await onChange(data);
				setMenuOpen(null);
			}
		},
		[readOnly, association, parentName, setMenuOpen]
	);

	const options = getOptions(associationValues, association, unavailableOptions, itemId, isOpen);
	const selectedOption = options.find(o => o.isSelected);

	return (
		<div className={`select ${isOpen ? "open" : ""}`} onClick={onSelectClick}>
			{!useNativeSelect && (
				<div className="options-wrapper" ref={selectRef}>
					{isLoading && (
						<div className="option disabled">Loading...</div>
					)}
					{!isLoading && options.map(o => (
						<div className="option" key={o.id}>
							<input
								type="radio"
								id={o.id}
								checked={o.isSelected}
								onChange={() => {}}
							/>
							<label
								htmlFor={o.id}
								className={o.className}
								title={o.hoverTitle}
								onClick={(e) => {
									if (isOpen) {
										e.stopPropagation();
										e.preventDefault();
										onAssociationChange(o.id);
									}
								}}
							>
								{o.text}
							</label>
						</div>
					))}
				</div>
			)}
			{useNativeSelect && (
				<div className="select-wrapper">
					{!isLoading && (
						<select
							value={association.value?.id ?? ""}
							onClick={e => e.stopPropagation()}
							onChange={(e) => {
								e.stopPropagation();
								e.preventDefault();
								onAssociationChange(e.target.value);
							}}
							disabled={readOnly}
							title={getTooltip(false, association, selectedOption?.text)}
						>
							{options?.map(o => (
								<option key={o.id} value={o.id}>{o.text}</option>
							))}
						</select>
					)}
				</div>
			)}
		</div>
	);
}

function getFilterUrl(filter) {
	if (!filter?.valuesUrl?.length || Object.values(filter.value ?? {}).includes(null)) {
		return "";
	}

	let url = filter.valuesUrl;
	Object.entries(filter.value ?? {}).forEach(([key, value]) => {
		// Encode plus signs (+, used in datetime) here because they are not encoded later on, not sure why :/
		url = url.replace(`$${key}`, value ? `'${value}'` : "null").replace("+", "%2B");
	});
	return url;
}

function getTooltip(isOpen, association, text) {
	const tooltipValue = association.value?.tooltip?.se_sv ?? association.value?.tooltip;
	if (isOpen) {
		return tooltipValue;
	}

	return tooltipValue ?? `${association.displayName ?? association.name}${text ? ": " + text : ""}`;
}

function checkSelectPosition(element) {
	element.style.top = 0;
	element.style.bottom = "unset";
	setTimeout(() => {
		const rect = element.getBoundingClientRect();
		if (rect.bottom >= window.innerHeight - 20) {
			element.style.top = "unset";
			element.style.bottom = 0;
		}
	});
}

function getOptions(associationValues, association, unavailableOptions, itemId, isOpen) {
	const options = associationValues?.map(v => {
		const isSelected = (Array.isArray(association.value) ? association.value.includes(v.id) : association.value?.id === v.id);
		const unavailableOption = unavailableOptions?.items.find(uo => {
			return uo.id === v.id && uo.itemid !== itemId;
		});
		const isUnavailable = /*isOpen && */!!unavailableOption;

		const name = v.name ?? "???";
		let className = "",
			text = name;
		if (isSelected && isUnavailable) {
			className = "selected-unavailable";
			text = `${name} (CONFLICT WITH ${unavailableOption.itemname})`;
		} else if (isSelected) {
			className = "selected";
		} else if (isUnavailable) {
			className = "unavailable";
			text = `${name} (occupied by ${unavailableOption.itemname})`;
		}
		
		const hoverTitle = getTooltip(isOpen, association, text);
		return { id: v.id, isSelected, text, className, hoverTitle };
	}) ?? [];

	if (association.value === null || association.value === undefined) {
		options.unshift({ id: null, isSelected: !association.value, text: "N/S", hoverTitle: `${association.displayName ?? association.name}: Not selected` });
	}

	return options;
}
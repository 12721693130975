import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import Switch from '@mui/material/Switch'
import FormControlLabel from '@mui/material/FormControlLabel'

export default class ResourceContent extends PureComponent {

	static propTypes = {
		item: PropTypes.object.isRequired,
		role: PropTypes.object.isRequired,
		handleResourceToggle: PropTypes.func.isRequired,
	}

	render() {
		const {
			item: resource,
			role = {},
			handleResourceToggle,
		} = this.props;

		const {
			id: resourceId,
			displayName,
			name,
			path,
			methods,
			description,
		} = resource;

		const toggleStyles = {
			display: "inline-table",
			width: "auto",
			marginRight: "1rem",
			whiteSpace: "nowrap",
			top: "2px",
		};

		const active = resourceActive(role, resourceId)

	    return (
			<div className={`flex flex-1-1-1-1`}>
				<div className="c6-content">
					<FormControlLabel
						control={
							<Switch
								checked={active}
								onChange={handleResourceToggle.bind(this, resource)}
							/>
						}
						label={displayName}
						labelPlacement="end"
					/>
					<p style={{ marginLeft: "47px", marginTop: "-5px" }}>{description}</p>
				</div>
				<div className="c6-content">
					{name}
				</div>
				<div className="c6-content">
					<code>{path}</code>
				</div>
				<div className="c6-content">
					{methods.map(m => <code key={m}> {m}</code>)}
				</div>
			</div>
		);
  	}
}

// HELPERS
function resourceActive(role, resourceId) {
	return role.resources ? role.resources.findIndex(r => r.id === resourceId) >= 0 : false;
}
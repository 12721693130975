import React from 'react'
// import keydown from 'react-keydown'
import debounce from 'lodash/debounce'
import { ScrollContainer } from 'react-router-scroll'
import { withRouter } from 'react-router'

import { getRouteName } from '../../../components/comet'
import App from '../../../components/app'
import { Info } from '../../../components/comet'
import Main from '../../../components/ui/main'

import Header from './header'
import List from '../shared/list'

import Actions from '../actions'
import Store from '../store'

import { getSearchPayload } from '../shared/utils'

// import './app.css'

const MODULE = "vod"
const DATASTORE = "seriesPackages"
// const ENTITY = "package"

@withRouter
export default class SeriesApp extends React.Component {

	constructor(props) {
		super(props);

		this.scroll = null;

		this.state = {
			...Store.getState(),
		};
	}

	componentDidMount() {
		Store.listen(this.onChange);
		const firstItem = this.state.list[DATASTORE].items[0];
		if (
			!firstItem
			|| !!firstItem && parseInt(this.props.params.id) !== parseInt(firstItem.metadata.series.id)
			|| !!firstItem && parseInt(this.props.location.query.serviceId) !== parseInt(firstItem.service.id)
		) {
			this.fetchSeriesPackages(this.props);
		}
	}

	componentDidUpdate(prevProps, prevState) {
		const firstItem = this.state.list[DATASTORE].items[0];
		if (
			parseInt(this.props.params.id) !== parseInt(prevProps.params.id)
			|| parseInt(this.props.location.query.serviceId) !== parseInt(prevProps.location.query.serviceId)
			|| !!firstItem && parseInt(this.props.params.id) !== parseInt(firstItem.metadata.series.id)
			|| !!firstItem && parseInt(this.props.location.query.serviceId) !== parseInt(firstItem.service.id)
		) {
			this.fetchSeriesPackages(this.props);
		}
	}

	componentWillUnmount() {
		Actions.unmount();
		Store.unlisten(this.onChange);
	}

	fetchSeriesPackages = ({ params, location }) => {
		const payload = {
			seriesReference: params.id,
			pageSize: 50,
			serviceId: location.query.serviceId,
			orderBy: "name",
		};
		Actions.fetchSeriesPackages(DATASTORE, payload);
	}

	onChange = (state) => {
		this.setState(state);
	}

	loadMore = () => {
		const l = this.state.list[DATASTORE];
		if (l.nextPageUrl) {
			Actions.pageItems(DATASTORE, l.nextPageUrl);
		}
	}

	shouldUpdateScroll(prevRouterProps, currentRouterProps) {
		return currentRouterProps.location.action === "POP";
	}

	render() {
		const list = this.state.list[DATASTORE];
		const { isLoading, searchText: componentSearchText, filters, nextPageUrl, unselectedFilter } = this.state;
		const searchText = componentSearchText || filters.searchText; // TODO!: We should probably only have one searchText since this will make people go mad.

	    return (
			<App
				name={`c6-${MODULE}-${DATASTORE} c6-vod-packages c6-list`}
				layout="grid"
				isLoading={!!isLoading}
			>
				<Header
					filters={filters}
					serviceId={this.serviceId}
					serviceName={this.serviceName}
					items={list.items}
					location={this.props.location}
					routes={this.props.routes}
					// goBackInsteadOfLink={!!list.items.length}
				/>
				<ScrollContainer scrollKey={`c6-${MODULE}-${DATASTORE}`} shouldUpdateScroll={this.shouldUpdateScroll}>
					<Main inputRef={el => this.scroll = el} padding={false}>
						<List
							items={list.items}
							isLoading={isLoading}
							filters={filters}
							hasMore={!!list.nextPageUrl}
							loadMore={this.loadMore}
							isSeriesPackagesList={true}
						/>
					</Main>
				</ScrollContainer>
				<Info>
					{getInfo(this.props.location, list)}
				</Info>
			</App>
		);
	}
}

// HELPERS
function getInfo(location, list) {
	const items = list.items.length ? `- displaying ${list.items.length} of ${list.numberOfItems}` : "";

	return `${getRouteName(location)} ${items}`;
}
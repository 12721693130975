import * as SportAPI from '../../../apis/sport'
import { useQuery } from '@tanstack/react-query'

const useSportGenre = (genreId: string | number) => {
    return useQuery(
        ["sport", "genre", { id: genreId }],
        () => SportAPI.fetchSeries({ id: genreId }),
        { enabled: genreId !== null }
    );
};

export default useSportGenre;
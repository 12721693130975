import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Switch from '@mui/material/Switch'

import { useLocalStorage } from '../hooks/useStorage'
import { getUser, getUserAccessLevelForModule } from '../services/auth'
import DropDown from '../../components/ui/controls/dropdown'
import { useDarkMode } from '../hooks/useDarkMode'

import appConfig from 'config'

import './login.css'

const UserControls = (props) => {

	const {
		onLogOut,
		user,
	} = props;

	const [firstName, ...lastName] = user.name?.split(" ") ?? ["", ""];
	const email = user.email ?? user.username;
	const accountSettingsChanged = true;

	const cmsStageSelect = <CmsStageSelect />;

	const [darkMode, setDarkMode] = useDarkMode();
		
	return (
		<div className="c6-authentication-form user-controls">
			<header>
				<div className="icon-person" />
				<h1>{firstName} {lastName}</h1>
			</header>
			<div className="form-body">
				<div className="account-settings" disabled>
					<h2>Account settings</h2>
					<div>
						<TextField
							variant="standard"
							color="primary"
							label="First name"
							value={firstName}
							onChange={() => {}} // TODO
							fullWidth
							className="c6-authentication-input"
						/>
					</div>
					<div>
						<TextField
							variant="standard"
							color="primary"
							label="Last name"
							value={lastName}
							onChange={() => {}} // TODO
							fullWidth
							className="c6-authentication-input"
						/>
					</div>
					<div>
						<TextField
							variant="standard"
							color="primary"
							label="E-mail"
							value={email}
							onChange={() => {}} // TODO
							fullWidth
							className="c6-authentication-input"
						/>
					</div>
					<div>
						<Button
							type="button"
							variant="outlined"
							onClick={() => {}} // TODO
							color="primary"
							style={{
								transition: "opacity 0.3s ease",
								opacity: 0,//accountSettingsChanged ? 1 : 0,
							}}
							disabled={!accountSettingsChanged}
						>
							Save changes
						</Button>
					</div>
				</div>
				<div className="preferences">
					<h2>Preferences</h2>
					{appConfig.features.enableDarkModeSwitch && (
						<div>
							<span>Dark mode</span>
							<Switch
								checked={darkMode}
								onChange={(e) => setDarkMode(e.target.checked)}
							/>
						</div>
					)}
					{appConfig.features.newCometNavigation && (
						<div>
							<span>Use new left side menu</span>
							<Switch
								checked={props.newNavigation}
								onChange={() => props.onToggleNewNavigation()}
							/>
						</div>
					)}
					{/* eslint-disable-next-line no-undef */}
					{__BUILD_PRODUCT__ === "comet" && (
						<div>
							<span>Enable uploader popup</span>
							<Switch
								checked={!props.hideGlobalUploader}
								onChange={() => props.onToggleGlobalUploader()}
							/>
						</div>
					)}
					{cmsStageSelect && (
						<div>
							<span>CMS stage</span>
							<CmsStageSelect />
						</div>
					)}
				</div>
			</div>
			<div className="form-bottom">
				<Button
					type="button"
					variant="contained"
					onClick={onLogOut}
					color="primary"
					startIcon={<span className="icon-logout" />}
				>
					Log out
				</Button>
			</div>
		</div>
	);
}

export default UserControls;

const stages = [
	{ key: "null", text: "Not selected" },
	{ key: "private", text: "Private" },
	{ key: "preview", text: "Preview" },
	{ key: "acceptance", text: "Acceptance" },
	{ key: "released", text: "Released" },
];

function CmsStageSelect() {
	const [cmsStage, setCmsStage] = useLocalStorage("x-cms-stage", "null", true);

	const user = getUser();
	const isSysadmin = user.username === "sysadmin";
	const cmsAccessLevel = getUserAccessLevelForModule("eventplanner");
	const showCmsStageSelect = isSysadmin || cmsAccessLevel === "admin";

	if (!showCmsStageSelect) {
		return null;
	}

	return (
		<DropDown
			onChange={e => setCmsStage(e.target.value)}
			states={stages}
			currentState={cmsStage}
		/>
	);
}
import React from 'react'

import Editor, { parseUi, withQueryClient } from '../../../../components/editor/index'
import { hasAccessToPath } from '../../../../core/services/auth'
import { filterRelevantComponents, getSchemaFromComponent, getUiSchemaFromComponent } from './utils'
import { useCMSConfig } from '../../shared/config'

import * as CMSAPI from '../../../../apis/cms'
import { findApp } from '../dynamic/app'
import useCMSTemplate from '../../../../core/queries/cms/useCMSTemplate'

import './app.css'

const DynamicEditor = props => {
    const appKey = props.params.appKey;
	const cmsConfig = useCMSConfig();
	const app = findApp(appKey, cmsConfig);

	const _module = app?.module ?? "eventplanner";
    const _entity = app?.editorEntity ?? appKey;
	const template = useCMSTemplate(_entity, _module, !!app);

	return (
		<Editor
			disabled={!app}
			className="c6-dynamic-editor"
			layout="grid"
			api="eventplanner"
			entity={appKey.slice(0, appKey.length - 1)}
			rfc6902={true}
			customTransformErrors={customTransformErrors}
			getSchema={getSchema.bind(this, template.data, _module)}
			getUiSchema={getUiSchema.bind(this, template.data, _module)}
			getCommands={getCommands.bind(this, _entity, _module)}
			loadPayloadTransform={loadPayloadTransform}
			hasEditAccess={hasAccessToPath(props.routes, "editor")}
			enableEditorNavigation={true}
			{...props}
		/>
	);
};

export default withQueryClient(DynamicEditor);

function getCommands(_entity, _module) {
	return {
		fetchItem: ({ id }) => CMSAPI.fetchCMSEntity({ id, _entity, _module }),
		createItem: (payload) => CMSAPI.createCMSEntityAndReturnContent({ _entity, _module, payload }),
		updateItem: ({ id }, patch) => CMSAPI.updateCMSEntityAndReturnContent({ id, _entity, _module, patch }),
	};
}

function customTransformErrors(errors) {
	const res = errors.filter(e => {
		const dontValidateEnum = e.params?.allowedValues?.some(v => v.hasOwnProperty("__DONT_VALIDATE__"));
		const skipTypeCheck = e.name === "type";
		return !dontValidateEnum && !skipTypeCheck;
	});
	return res;
}

function getSchema(template, module, model, isNew, location, route, params) {
	const schema = { type: "object", properties: {} };
	if (!template) {
		return schema;
	}

	const components = template.children.filter(filterRelevantComponents);
	components.forEach(c => {
		const componentSchema = getSchemaFromComponent(c, null, module);
		schema.properties[c.name] = componentSchema;
	});
	
    return schema;
}

function getUiSchema(template, module, model, isNew, location, route, params) {
	const uiSchema = {};

	if (!template) {
		return uiSchema;
	}

	const components = template.children.filter(filterRelevantComponents);
	components.forEach(c => {
		const componentSchema = getUiSchemaFromComponent(c, module);
		uiSchema[c.name] = componentSchema;
	});

    return uiSchema;
}

function loadPayloadTransform({ model, entity, location, route, params }) {
	// Remove null values because RJSF does not like null values for object fields
	return Object.entries(model ?? {}).reduce((result, [key, value]) => {
		if (value !== null) {
			return { ...result, [key]: value };
		}
		return result;
	}, {});
}
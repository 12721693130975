import React from 'react'
import Editor, { withQueryClient } from '../../../components/editor/'

import schema from './schema'
import ui from './ui'

const ResourceEditor = props => (
	<Editor
		layout="grid"
		api="shield"
		entity="resource"
		getSchema={getSchema}
		getUiSchema={getUiSchema}
		savePayloadTransform={savePayloadTransform}
		{...props}
	/>
);

export default withQueryClient(ResourceEditor);

function getSchema() {
	return schema;
}

function getUiSchema(model, isNew) {
	ui.name["ui:readonly"] = !isNew;
	return ui;
}

function savePayloadTransform({ formData, entity, location, route, ...rest }) {
	const { serviceId } = location.query;

	return {
		...rest,
		location,
		formData: {
			...formData,
			serviceId,
		},
		entity,
		queryString: `?serviceId=${serviceId}`,
	}
}
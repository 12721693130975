import React from 'react'

import Editor, { parseUi } from '../../../../components/editor/index'

import { hasAccessToPath } from '../../../../core/services/auth'

import schema from './schema'
import uiSchema from './uiSchema'

export default props => (
	<Editor
		layout="grid"
		api="selections"
		entity="template"
		getSchema={getSchema}
		getUiSchema={getUiSchema}
		hasEditAccess={hasAccessToPath(props.routes, "editor")}
		{...props}
	/>
);

function getSchema(model, isNew, location, route, params) {
	return schema;
}

function getUiSchema(model, isNew, location, route, params) {
	return uiSchema;
}
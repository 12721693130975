import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import Label from '../../../components/ui/label'
import ContentItem, { Content } from '../../../components/list/contentItem'

export default class LoginContent extends PureComponent {

	static propTypes = {
		enabled: PropTypes.bool,
		displayName: PropTypes.string,
		endpoint: PropTypes.string,
		className: PropTypes.string,
	}

	render() {
		const {
			enabled,
			displayName,
			name,
			description,
			endpoint,
		} = this.props;

	    return (
			<ContentItem data={this.props}>
				<Content>
					<h1>{`${displayName}`} {getLabel(enabled)}</h1>
					<p>{description}</p>
				</Content>
				<Content>
					{name}
				</Content>
				<Content>
					<code>{endpoint}</code>
				</Content>
			</ContentItem>
		);
  	}
}

// HELPERS
function getLabel(enabled) {
	return !enabled
		? <Label status="expired">Disabled</Label>
		: null ;
}
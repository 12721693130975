import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import groupBy from 'lodash/groupBy'
import sortBy from 'lodash/sortBy'
import InfiniteScroll from 'react-infinite-scroller'

import { decoratorUIActions } from '../../../core/decorators/uiActions'

import { ItemGroup } from '../../../components/list/listItems'
import ContentItem from '../../../components/list/contentItem'
import Empty from '../../../components/list/empty'

import Item from './listItem'
import contentActions from './listItemActions'

const ONLY_SEARCH_WHEN_SEARCHTEXT_IS_AT_LEAST = 2;
const GROUP_DATE_FORMAT = "YYYYMMDD";

@decoratorUIActions(contentActions)
export default class List extends Component {

	static propTypes = {
		items: PropTypes.array.isRequired,
		isLoading: PropTypes.number.isRequired,
		searchText: PropTypes.string,
		filters: PropTypes.object,
		textEmpty: PropTypes.string,
	}

	constructor(props) {
		super(props);
	}

	render() {
		const {
			items,
			isLoading,
			searchText = "",
			textEmpty = "Sorry, could not find any items.",
			hasMore,
			loadMore,
			filters,
			handleFilter,
			pastNeedingAttention,
		} = this.props;

		if (!(items && items.length)) {
			const textTooFewCharacters = searchText && searchText.length < ONLY_SEARCH_WHEN_SEARCHTEXT_IS_AT_LEAST ? `Please type ${ONLY_SEARCH_WHEN_SEARCHTEXT_IS_AT_LEAST} characters or more to search.` : false;
			return (
				<Empty
					key="empty"
					v2={true}
					isLoading={!!isLoading}
				>
					{textTooFewCharacters || textEmpty} {searchText && !textTooFewCharacters && getSearchSpecificity(filters, handleFilter)} {!textTooFewCharacters && getPastNeedingAttention(pastNeedingAttention, filters, handleFilter)}
				</Empty>
			);
		}

		return (
			<InfiniteScroll
				loadMore={loadMore}
				hasMore={hasMore}
				loader={<div className="infinite-loader" key="infinite-loader">Loading ...</div>}
				useWindow={false}
				threshold={700}
				initialLoad={false}>
				{this.renderGroups(this.props)}
			</InfiniteScroll>
		);
	}

	renderGroups({ items, searchText, filters, handleFilter, isSeriesPackagesList = false, pastNeedingAttention }) {
		if (searchText) {
			const title = <span>Items matching "{searchText}" {getSearchSpecificity(filters, handleFilter)}</span>;
			return (
				<ItemGroup title={title}>
					{this.renderItems(items, filters)}
				</ItemGroup>
			);
		}

		if (isSeriesPackagesList) {
			return <ItemGroup>{this.renderItems(items, filters, isSeriesPackagesList)}</ItemGroup>;
		}

		const groupedItems = groupItems(items, filters);
		return Object.keys(groupedItems).map((groupKey, index) => (
			<ItemGroup
				key={groupKey}
				title={getGroupTitle(groupedItems, groupKey, index, filters, handleFilter, pastNeedingAttention)}
			>
				{this.renderItems(groupedItems[groupKey], filters, isSeriesPackagesList)}
			</ItemGroup>
		));
	}

	renderItems(items, filters, isSeriesPackagesList) {
		return items.map(item => (
			<Item
				key={item.id}
				showSeriesLink={!isSeriesPackagesList}
				{...item}
			/>
		));
	}
}

// HELPERS
function groupItems(items, filters) {
	const groupedItems = groupBy(items, item => {
		return getDateForGroup(item, filters);
	});
	return sortBy(groupedItems, group => items.indexOf(group[0]));
}

function getGroupTitle(groupedItems, group, index, filters, handleFilter, pastNeedingAttention) {
	const firstItem = groupedItems[group][0];
	const date = getDateForGroup(firstItem, filters);
	return index === 0
		? <span>{renderDate(date)} {getPastNeedingAttention(pastNeedingAttention, filters, handleFilter)}</span>
		: renderDate(date);
}

function renderDate(date) {
	const now = moment().format(GROUP_DATE_FORMAT);
	const prem = moment(date);
	const day = prem.format(GROUP_DATE_FORMAT);

	if (now.substr(0, 4) !== day.substr(0, 4)) {
		return prem.format("D MMMM YYYY");
	}

	return prem.format("D MMMM");
}

function getDateForGroup(item, filters) {
	const publishWindows = item && item.publishWindows || [];
	let date = publishWindows[0] && publishWindows[0].start;
	switch (filters.filter.premiere) {
		case "past":
			const lastExpiredWindow = getLastExpiredPublishWindow(publishWindows);
			date = lastExpiredWindow ? lastExpiredWindow.end : date;
			break;
		case "lastsent":
			date = item && item.lastSent;
			break;
		case "upcoming":
			const firstUpcomingWindow = getFirstUpcomingPublishWindow(publishWindows);
			date = firstUpcomingWindow ? firstUpcomingWindow.start : date;
			break;
		case "current":
			const mostRecentActiveWindow = getMostRecentActivePublishWindow(publishWindows);
			date = mostRecentActiveWindow ? mostRecentActiveWindow.start : date;
			break;
		default:
			const m = moment(filters.filter.premiere);
			const firstUpcomingWindowAfterOrInMonth = getFirstUpcomingPublishWindowAfterOrInMonth(publishWindows, m);
			date = firstUpcomingWindowAfterOrInMonth ? firstUpcomingWindowAfterOrInMonth.start : date;
			break;
	}
	return moment(date).format(GROUP_DATE_FORMAT);
}

function getFirstUpcomingPublishWindow(publishWindows) {
	const now = moment();
	const sorted = (publishWindows || [])
		.filter(pw => moment(pw.start).isAfter(now))
		.sort((pwA, pwB) => moment(pwA.start).valueOf() - moment(pwB.start).valueOf());
	return sorted.length ? sorted[0] : null;
}

function getLastExpiredPublishWindow(publishWindows) {
	const now = moment();
	const sorted = (publishWindows || [])
		.filter(pw => moment(pw.end).isBefore(now))
		.sort((pwA, pwB) => moment(pwB.end).valueOf() - moment(pwA.end).valueOf());
	return sorted.length ? sorted[0] : null;
}

function getMostRecentActivePublishWindow(publishWindows) {
	const now = moment();
	const sorted = (publishWindows || [])
		.filter(pw => moment(pw.start).isSameOrBefore(now) && moment(pw.end).isAfter(now))
		.sort((pwA, pwB) => moment(pwB.start).valueOf() - moment(pwA.start).valueOf());
	return sorted.length ? sorted[0] : null;
}

function getFirstUpcomingPublishWindowAfterOrInMonth(publishWindows, m) {
	const sorted = (publishWindows || [])
		.filter(pw => moment(pw.start).isSameOrAfter(m))
		.sort((pwA, pwB) => moment(pwA.start).valueOf() - moment(pwB.start).valueOf());
	return sorted.length ? sorted[0] : null;
}

function getSearchSpecificity(filters, handleFilter) {
	if (filters.filter.searchPackagesAcrossAllServices) {
		return <span>(Searching across all services. You can also <span className="c6-link" onClick={handleFilter.bind(this, "filter", { target: { name: "searchPackagesAcrossAllServices", value: false }})}>search only in the selected service</span> if you'd like)</span>;
	} else {
		return <span>(Searching only in the selected service. You can also <span className="c6-link" onClick={handleFilter.bind(this, "filter", { target: { name: "searchPackagesAcrossAllServices", value: true }})}>search across all services</span> if you'd like)</span>;
	}
}

function getPastNeedingAttention(pastNeedingAttention, filters, handleFilter) {
	if (filters && filters.filter.status === "attention" && filters.filter.attentionPastOrUpcoming === "past") {
		return <span>(Displaying titles that already premiered but you can also <span className="c6-link" onClick={handleFilter.bind(this, "filter", { target: { name: "attentionPastOrUpcoming", value: "upcoming" }})}>show upcoming titles</span> if you'd like)</span>;
	}

	if (pastNeedingAttention && pastNeedingAttention.numberOfItems > 0) {
		return <span>(There are {pastNeedingAttention.numberOfItems} titles that already should have premiered in the service that <span className="c6-link" onClick={handleFilter.bind(this, "filter", { target: { name: "attentionPastOrUpcoming", value: "past" }})}>needs your attention</span> as well)</span>;
	}

	return null;
}
import React from 'react'
import { Link } from 'react-router'
import { browserHistory } from 'browserHistory'

import { Filter, Left, Right } from '../../../../components/filter'

import { getAlbumStatus } from '../shared/utils'

import Actions from '../../actions'

export default ({ title, data }) => {

	const { albumIsShared, albumExpires, href, password } = getAlbumStatus(data);
	const albumInfo = albumIsShared && (
		<p>This shared album is available for another {albumExpires.fromNow(true)}. {getTypeText(data)}</p>
	);

	return (
		<Filter>
			<Left>
				<h1>{title}</h1>
				{albumInfo}
			</Left>
		</Filter>
	);
};

const getTypeText = ({ type }) => {
	if (type === "UploadAndDownload") {
		return "You can upload to and download from this album.";
	}
	if (type === "Upload") {
		return "You can upload to (but not download from) this album.";
	}
	if (type === "Download") {
		return "You can download from (but not upload to) this album.";
	}
	return null;

 	// return type && type !== "Undefined"
	// 	? `You can ${type.includes("Upload") ? "upload to" : ""} ${type.includes("And") ? " and " : ""} ${type.includes("Download") ? "download from" : ""} this album.`
	// 	: null;
}
import React from 'react'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'

const ALL_WEEK_VALUE = 127;

export default class daySchedule extends React.Component {
	constructor(props) {
		super(props);

		const value = props.value > 0 ? props.value : ALL_WEEK_VALUE;

		this.state = {
			value,
			specificity: value === ALL_WEEK_VALUE ? "all" : "custom",
			days: convertValueToDays(value)
		}
	}

	handleSpecifityChange = (event, specificity) => {
		const value = specificity === "all"
			? ALL_WEEK_VALUE
			: convertDaysToValue(this.state.days);
		this.setState({
			value,
			specificity
		});
		this.props.onChange(value);
	}

	handleDayChange= (event, checked) => {
		const day = parseInt(event.target.value, 10);
		let newDays = this.state.days;

		if(checked && !newDays.includes(day)) {
			newDays.push(day);
		}
		else if(!checked && newDays.includes(day)) {
			const index = newDays.findIndex(i => i === day);
			newDays.splice(index, 1);
		}

		const value = convertDaysToValue(newDays);

		this.setState({
			value,
			days: newDays
		});

		// TODO: Debounce this call for performance?
		this.props.onChange(value);
	}

	render() {
		const { specificity, value, days } = this.state;
		const dayOrder = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

		let dayPicker = null;
		if (specificity === "custom") {
			dayPicker = dayOrder.map((day, index) => (
				<FormControlLabel
					key={day}
					style={{ width: "5rem", marginRight: "2rem" }}
					control={
						<Checkbox
							value={`${index + 1}`}
							checked={days.includes(index + 1)}
							onChange={this.handleDayChange}
						/>
					}
					label={day}
				/>
			));
		}

		return (
			<div className="daySchedule">
				<RadioGroup
					row={true}
					name="days"
					value={specificity}
					onChange={this.handleSpecifityChange}
					style={{ marginTop: "7px", marginBottom: "3px" }}
				>
					<FormControlLabel
						value={"all"}
						label={"All week"}
						control={<Radio />}
					/>
					<FormControlLabel
						value={"custom"}
						label={"On specific days"}
						control={<Radio />}
					/>
				</RadioGroup>
				{dayPicker}
			</div>
		);
	}
};

function convertDaysToValue(days) {
	let binaryFlags = "";
	let i = 1;
	for(i; i<8; i++) {
		binaryFlags += days.includes(i) ? 1 : 0;
	}
	return parseInt(binaryFlags, 2);
}

function convertValueToDays(value) {
	const binaryFlags = value.toString(2).padStart(7, "0").split("");
	let days = [];
	binaryFlags.forEach((day, index) => {
		if(parseInt(day)) {
			days.push(index + 1);
		}
	});
	return days;
}
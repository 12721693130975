import React from 'react'

export const VisibilityContext = React.createContext();

export const useVisibilityState = (showValues, id) => {
    return React.useMemo(
        () => {
            const newStates = {};
            if (showValues?.length) {
                const values = showValues.split(",");
                values.forEach(key => newStates[key] = true);
            }

            if (id) {
                newStates._all = true;
            }
            return newStates;
        },
        [showValues, id]
    );
};
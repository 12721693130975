import React from 'react'

import Preview from '../../assets/preview'

const Image = ({
	className,
	name,
	value,
	style,
}) => {
	const size = style?.format?.includes("cover") ? "smallCover" : "small";

	return (
		<div className={className}>
			<Preview
				imageGuid={value}
				size={size}
				extension="png"
			/>
		</div>
	);
};

export default Image;
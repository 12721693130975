import React from 'react'
import PropTypes from 'prop-types'
import Moment from 'moment'
import TextField from '@mui/material/TextField'
import Highlight from 'react-highlighter'

import Main from '../../../../components/ui/main'
import ContentItem, { PlaceholderItem, Content } from '../../../../components/list/contentItem'

import Label from '../../../../components/ui/label'
import Button from '../../../../components/ui/controls/button'
import Preview from '../../../../components/assets/preview'

import { getMySpaceAlbum } from './utils'
import { hasStarEditAccess } from '../../shared/utils'

import '../../../../components/list/list.css'

const ENTER_KEY_CODE = 13;

export default class StarAlbumList extends React.Component {
	static propTypes = {
		list: PropTypes.object.isRequired,
		onCreateAlbum: PropTypes.func.isRequired,
		modal: PropTypes.bool,
		searchText: PropTypes.string,
	}

	constructor(props) {
		super(props);

		this.handleClick = this.handleClick.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleCreateAlbum = this.handleCreateAlbum.bind(this);

		this.state = {
			createNewAlbum: false,
			newAlbumName: "",
        };
	}

	handleClick(createNewAlbum = true) {
		this.setState({
			createNewAlbum,
			newAlbumName: !createNewAlbum ? "" : this.state.newAlbumName,
		});
	}

	handleChange(e) {
		const newAlbumName = e.target.value;
		this.setState({ newAlbumName });
	}

	handleCreateAlbum() {
		this.setState({ createNewAlbum: false }, () => {
			this.props.onCreateAlbum(this.state.newAlbumName);
		});
	}

	render() {
		const { list, searchText, modal = false } = this.props;
		const { items } = list.albums;

		const canEdit = hasStarEditAccess();


		return (
			<Main modal={modal}>
				<div>
					<NewAlbumItem
						visible={canEdit}
						createNewAlbum={this.state.createNewAlbum}
						onClick={this.handleClick}
						onChange={this.handleChange}
						onCreateAlbum={this.handleCreateAlbum}
						newAlbumName={this.state.newAlbumName}
					/>

					{ (canEdit && !modal) && renderUserPrivateFilesAsAlbum(list.mySpace) }

					{
						items
						.filter(item => searchText ? matchSearch(item, searchText) && !item._isHidden : !item._isHidden)
						.map(item => getItem(item, searchText, list.selected.items))
					}
				</div>
			</Main>
		);
	}
};

// HELPERS
const NewAlbumItem = ({ newAlbumName, createNewAlbum, onClick, onChange, onCreateAlbum, visible = false }) => {
	if(!visible) {
		return null;
	}

	if(!createNewAlbum) {
		return (
			<PlaceholderItem onClick={onClick}>
				<Content>
					<h1 className="icon-add_circle large-icon">New album...</h1>
				</Content>
			</PlaceholderItem>
		);
	}

	return (
		<PlaceholderItem className="active">
			<Content>
				<TextField
					inputRef={focus}
					variant="standard"
					value={newAlbumName}
					onChange={onChange}
					label="Album title"
					fullWidth
				/>
			</Content>
			<Content size="min">
				<Button
					type="approve"
					onClick={onCreateAlbum}
					noBackground />
				<Button
					type="cancel"
					onClick={onClick.bind(this, false)}
					noBackground />
			</Content>
		</PlaceholderItem>
	);
}

const renderUserPrivateFilesAsAlbum = mySpace => {
	const item = {
		...getMySpaceAlbum(),
		assets: mySpace.items,
	};

	return getItem(item);
}

const getItem = (item, searchText, selectedItems) => {
	const expires = Moment(item.expires);
	let shared = null;
	if(expires.year() !== 1 && item.status === "Shared") {
		const diff = expires.diff(new Moment());
		let status = "disabled", text = "Expired";

		if(diff > 0) {
			const diffHours = diff/3600000;
			text = "Expires";
			status = diffHours < 24 ? "attention" : diffHours < 72 ? "headsup": "active";
		 }

		 shared = <Label status={status} className="icon-sharewithpeople">{`${text} ${Moment.duration(diff).humanize(true)}`}</Label>;
	}

	const audience = item.audience !== "All" ? <Label className="icon-star">{item.audience === "Admin" ? "Editors" : item.audience}</Label> : null;

	const labels = shared || audience
		? <Content size="min">{audience}{shared}</Content>
		: null;

	const createdByUserStyle = { color: "var(--text-light-color)", marginLeft: "5px" };
	const createdBy = item.createdByUser && <span style={createdByUserStyle}>(<Highlight matchElement="mark" search={searchText || false}>{item.createdByUser}</Highlight>)</span>;

	return (
		<ContentItem key={item.id} data={item} selectedItems={selectedItems}>
			<Preview imageGuid={getGuid(item)} />
			<Content>
				<h1>
					<Highlight matchElement="mark" search={searchText || false}>{item.displayName || item.name}</Highlight>
					{createdBy}
				</h1>
				{getNumberOfAssets(item.assets)}
			</Content>
			{labels}
		</ContentItem>
	);
};

const getGuid = ({ assets = [] }) => {
	// Prioritize thumbnails for an image
	const assetTypePriority = ["Image", "Clip", "Document"];
	let asset = null;
	assetTypePriority.find(assetType => {
		asset = assets.find(a => a.type === assetType);
		return !!asset;
	});
	return asset ? asset.assetGuid : null;
}

const focus = input => {
	if(input) {
		setTimeout(() => {input.focus()}, 100);
	}
}

const getNumberOfAssets = (assets = []) => {
	switch(assets.length) {
		case 0:
			return null;
		case 1:
			return <p>1 asset</p>;
		default:
			return <p>{`${assets.length} assets`}</p>;
	}
}

const matchSearch = (item, searchText) => {
	return item.name.includes(searchText) || item.createdByUser && item.createdByUser.includes(searchText);
}
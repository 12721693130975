import React from 'react'

import StarUpload from '../../ui/editorWidgets/starUpload'

import * as CMSAPI from '../../../apis/cms'

const AssetContainer = ({
	value,
	displayName,
	className,
	onChange,
	options = {},
}) => {

	const uploadOptions = React.useMemo(
		() => ({
			onComplete: async ({ asset }) => {
				let newValue;
				if (!asset) {
					// No starAsset = remove
					newValue = null;
				} else {
					const cmsAssetPayload = {
						name: asset.displayName,
						assetType: asset.type,
					};
					// Creates or updates asset in CMS
					const cmsAsset = await CMSAPI.createOrUpdateCMSEntity({ _entity: "assets", id: asset.assetGuid, payload: cmsAssetPayload });
					newValue = cmsAsset;
				}
	
				onChange(newValue);
			},
			onRemove: () => onChange(null),
			needsCrop: false,
			...options,
		}),
		[onChange]
	);

	const schema = React.useMemo(() => ({ title: displayName }), [displayName]);

	return (
		<div className={className}>
			<StarUpload
				value={value?.id}
				options={uploadOptions}
				onChange={onUploadChange}
				schema={schema}
			/>
		</div>
	);
}

export default AssetContainer;

const onUploadChange = () => {};

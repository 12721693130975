import React from 'react'
import debounce from 'lodash/debounce'

import App from '../../../components/app'
import { StandardInfo } from '../../../components/comet'
import Main from '../../../components/ui/main'
import {Filter, Left, Right} from '../../../components/filter'
import Button from '../../../components/ui/controls/button'
import Search from '../../../components/ui/controls/search'

import List from './list'

import Actions from '../actions'
import Store from '../store'
import EditorNavigationController from '../../../core/ui/editorNavigationController'

const MODULE = "shield"
const DATASTORE =  "services"
const TEXT_HEADING = "Services"
const TEXT_EMPTY = "No services? It's about time to create some then!"
const TEXT_CREATE = "Create service"
const ICON_CREATE = "add"

export default class ListApp extends React.Component {

	constructor(props) {
		super(props);

		this.onChange = this.onChange.bind(this);

		this.handleSearch = this.handleSearch.bind(this);
		this.onSearch = this.onSearch.bind(this);
		this.debouncedSearch = debounce(this.handleSearch, 300);

		this.state = {
			...Store.getState(),
			searchText: "",
		};
	}

	componentDidMount() {
		Store.listen(this.onChange);
		Actions.fetchItems(DATASTORE);
	}

	componentWillUnmount() {
		Actions.unmount();
		Store.unlisten(this.onChange);
	}

	onChange(state) {
		this.setState(state);

		EditorNavigationController.setItems(this.state[DATASTORE]);
	}

	onSearch(e) {
    	e.persist();
    	this.debouncedSearch(e);
  	}

	handleSearch(event) {
		const { value: searchText } = event.target;
		Actions.fetchItems(DATASTORE, { searchText });
		this.setState({searchText});
	}

	handleCreateNew() {
		Actions.create({
			pathname: `/shield/admin/services/create`,
			returnTo: this.props.location.pathname,
		});
	}

	render() {
		const { isLoading, searchText } = this.state;

	    return (
			<App
				name={`c6-${MODULE}-${DATASTORE}`}
				layout="grid"
				isLoading={isLoading}>
				<Filter>
					<Left>
						<h1>{TEXT_HEADING}</h1>
						<Search
							onChange={this.onSearch}
							placeholder="Search"
							searchText={searchText} />
					</Left>
					<Right>
						<Button
							type={ICON_CREATE}
							title={TEXT_CREATE}
							onClick={this.handleCreateNew.bind(this)} />
					</Right>
				</Filter>
				<Main>
					<List
						items={this.state[DATASTORE]}
						isLoading={this.state.isLoading}
						textEmpty={TEXT_EMPTY} />
				</Main>
				<StandardInfo location={this.props.location} />
			</App>
		);
	}
}
import React from 'react'

import App from '../../../../components/app'
import Dashboard from '../../../../components/dashboard/dashboard'
import { StandardInfo } from '../../../../components/comet'

import { useCMSConfig } from '../../shared/config'

import appConfig from 'config'

function AdminDashboardApp(props) {
	const cmsConfig = useCMSConfig();

	const adminPathPart = location.pathname.split("/").find(p => {
		return p === "admin"
			|| p.includes("admin") && p !== appConfig.app?.basePath;
	});

	const SHORTCUTS = [
		{
			displayName: "Sports",
			key: "selections.admin-templates",
			links: [
				{
					key: "sports",
					url: `/eventplanner/${adminPathPart}/sports`,
					ui: "React",
					description: "",
					displayName: "> Sports",
				},
				{
					key: "series",
					url: `/eventplanner/${adminPathPart}/series-sport`,
					ui: "React",
					description: "",
					displayName: "> Series",
				},
				{
					key: "leagues",
					url: `/eventplanner/${adminPathPart}/leagues`,
					ui: "React",
					description: "",
					displayName: "> Leagues",
				},
				// {
				// 	key: "arenas",
				// 	url: `/eventplanner/${adminPathPart}/arenas`,
				// 	ui: "React",
				// 	description: "",
				// 	displayName: "> Arenas",
				// },
				{
					key: "participants",
					url: `/eventplanner/${adminPathPart}/participants`,
					ui: "React",
					description: "",
					displayName: "> Teams/Individuals"
				},
			],
		},
		...cmsConfig?.blocks.map(block => ({
			...block,
			links: block.apps?.map(app => ({
				key: app.key,
				url: `/eventplanner/${adminPathPart}/${app.key}`,
				ui: "React",
				description: "",
				displayName: `> ${app.displayName}`,
			})),
		})) ?? []
	];

	return (
		<App name="c6-eventplanner-admin" layout="grid">
			<Dashboard title="Event Planner administration" data={SHORTCUTS} />
			<StandardInfo location={props.location} />
		</App>
	);
}

export default AdminDashboardApp;
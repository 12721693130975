import React from 'react'

import Editor, { parseUi } from '../../../../components/editor/index'
import useStarFileFormats from '../../../../core/queries/star/useStarFileFormats'
import { hasAccessToPath } from '../../../../core/services/auth'

import schema from './schema'
import uiSchema from './uiSchema'

const FileFormatEditor = props => {

	const { data: fileFormats } = useStarFileFormats();
	return (
		<Editor
			layout="grid"
			api="star"
			entity="fileformat"
			getSchema={getSchema.bind(this, fileFormats)}
			getUiSchema={getUiSchema.bind(this, fileFormats)}
			getValidation={getValidation.bind(this, fileFormats)}
			hasEditAccess={hasAccessToPath(props.routes, "admin")}
			enableEditorNavigation={true}
			{...props}
		/>
	);
};

export default FileFormatEditor;

function getSchema(fileFormats, model, isNew, location, route, params) {
	if (!fileFormats?.items?.length) {
		return { type: "object", properties: {} };
	}
	return schema;
}

function getUiSchema(fileFormats, model, isNew, location, route, params) {
	const fileFormatsDataSource = (fileFormats?.items ?? [])
		.sort((a, b) => a.name.localeCompare(b.name))	
		.map(ff => ({
			...ff,
			name: ff.name,
			rightText: `${ff.maxWidth ? `MW: ${ff.maxWidth},` : ""} ${ff.maxHeight ? `MH: ${ff.maxHeight},` : ""} PG: ${ff.preGenerate ? "YES" : "NO"}`,
		}));
	fileFormatsDataSource.unshift({ id: 0, name: "No parent format" });
	uiSchema.parentId["ui:options"].dataSource = fileFormatsDataSource;
	return uiSchema;
}

function getValidation(fileFormats, params, location, formData, addError) {
	if (fileFormats?.items?.length && formData.parentId !== 0) {
		const parentFormat = fileFormats.items.find(ff => ff.id === formData.parentId);
		if (parentFormat && (
			formData.maxWidth !== undefined && parentFormat.maxWidth !== 0 && parentFormat.maxWidth < formData.maxWidth
			|| formData.maxHeight !== undefined && parentFormat.maxHeight !== 0 && parentFormat.maxHeight < formData.maxHeight
		)) {
			addError("parentId", "Parent format is smaller than this format!");
		}
	}
}
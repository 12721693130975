import React from 'react'
import AssetModal from './asset'
import AuditModal from './audit'
import GenericModal from './generic'

export default function Modals(props) {
	const { modalData, onClose } = props;
	const { modalName, itemData, id, collection, module } = modalData ?? {};

	if (!modalName) {
		return null;
	}

	if (modalName === "assets") {
		return (
			<AssetModal
				onClose={onClose}
				itemData={itemData}
				id={id}
			/>
		);
	}

	if (modalName === "audit") {
		return (
			<AuditModal
				onClose={onClose}
				itemData={itemData}
				id={id}
				collection={collection}
				module={module}
			/>
		);
	}

	return (
		<GenericModal
			onClose={onClose}
			itemData={itemData}
			id={id}
			template={modalName}
		/>
	);
}
import { browserHistory } from 'browserHistory'

import { CONTENT, CLICK_CTRL_OR_OPT, MENU } from '../../../components/actions'
import { hasAccessToPath } from '../../../core/services/auth'
import { getLinkUrlAndHash } from '../shared/utils'
import { updateItemInMatchingQueries } from '../../../core/hooks/useList'

import * as VodCoreAPI from '../../../apis/vodcore'

const TV4PLAY = "tv4play";

export default {
	targetComponent: "Content",
	actions: [
		{
			title: "Display deliveries",
			type: CONTENT,
			method: ({ id }, { routes }) => {
				const packagesPath = window.location.pathname.includes("-new") ? "packages-new" : "packages";
				const servicePath = (routes.find(r => r.serviceId !== undefined) || { path: packagesPath }).path;
				const pathname = `/vod/${servicePath}/${id}/deliveries`;
				browserHistory.push({
					pathname,
				});
			},
		},
		{
			title: "Display deliveries (in new tab)",
			type: CLICK_CTRL_OR_OPT,
			method: ({ id }, { routes }) => {
				const packagesPath = window.location.pathname.includes("-new") ? "packages-new" : "packages";
				const servicePath = (routes.find(r => r.serviceId !== undefined) || { path: packagesPath }).path;
				const pathname = `vod/${servicePath}/${id}/deliveries`;
				window.open(pathname, "_blank");
			},
		},
		{
			title: "View in Metadata module",
			icon: "movie",
			method: ({ service, metadata }) => {
				browserHistory.push(getLinkUrlAndHash(service, metadata));
			},
			hidden: () => {
				const accessRequirement = [{ module: "metadata", app: "library", access: "reader" }];
				const hasMetadataAccess = hasAccessToPath(accessRequirement);
				return !hasMetadataAccess;
			},
			type: MENU,
		},
		{
			title: "Resend",
			icon: "sync",
			method: async ({ id }) => {
				const updatedItem = await VodCoreAPI.resendPackage({ id });
				updateItemInMatchingQueries({
					store: "packages",
					item: updatedItem,
				});
			},
			disabled: ({ service, status }) => {
				const disableActionsForTV4 = service.name?.toLowerCase() === TV4PLAY;
				return disableActionsForTV4 || !["Delivered", "Published"].includes(status);
			},
			type: MENU,
		},
		{
			title: "Update metadata",
			icon: "sync",
			method: async ({ id }) => {
				const updatedItem = await VodCoreAPI.updatePackage({ id });
				updateItemInMatchingQueries({
					store: "packages",
					item: updatedItem,
				});
			},
			disabled: ({ service, status }) => {
				const disableActionsForTV4 = service.name?.toLowerCase() === TV4PLAY;
				return disableActionsForTV4 || !["Delivered", "Published", "Unpublished", "Canceled"].includes(status);
			},
			type: MENU,
		},
		{
			title: "Add",
			icon: "add_circle",
			method: async ({ id }) => {
				const updatedItem = await VodCoreAPI.republishPackage({ id });
				updateItemInMatchingQueries({
					store: "packages",
					item: updatedItem,
				});
			},
			disabled: ({ service }) => {
				const disableActionsForTV4 = service.name?.toLowerCase() === TV4PLAY;
				return disableActionsForTV4;
			},
			hidden: ({ status }) => status !== "Unpublished",
			type: MENU,
		},
		{
			title: "Remove",
			icon: "remove_circle",
			method: async ({ id }) => {
				const updatedItem = await VodCoreAPI.unpublishPackage({ id });
				updateItemInMatchingQueries({
					store: "packages",
					item: updatedItem,
				});
			},
			disabled: ({ service }) => {
				const disableActionsForTV4 = service.name?.toLowerCase() === TV4PLAY;
				return disableActionsForTV4;
			},
			hidden: ({ status }) => !(status === "Published" || status === "Delivered"),
			type: MENU,
		}
	]
};
import React from 'react'

import ContentItem, { Content } from '../../../../components/list/contentItem'
import InlineHeading from '../../../../components/ui/inlineHeading'

import '../../../../components/listItems/standard.css'

const FileFormat = (props) => {

    const { id, parentId, name, maxWidth, maxHeight, preGenerate } = props;

    return (
        <ContentItem data={props}>
            <Content>
                <h1>{name}</h1>
                <p>ID: {id}{parentId ? ` - Parent ID: ${parentId}` : ""}</p>
            </Content>
            <Content>
                <div className="dimensions">
                    <InlineHeading title="MAX WIDTH"><span className={maxWidth ? "" : "dimmed"}>{maxWidth || " "}</span></InlineHeading>
                    <InlineHeading title="MAX HEIGHT"><span className={maxHeight ? "" : "dimmed"}>{maxHeight || " "}</span></InlineHeading>
                </div>
                <InlineHeading title="PG"><span className={preGenerate ? "" : "dimmed"}>{preGenerate ? "YES" : "NO"}</span></InlineHeading>
            </Content>
        </ContentItem>
    );
};

export default FileFormat;
import React from 'react'

import App from '../../../components/app'
import Dashboard from '../../../components/dashboard/dashboard'
import { StandardInfo } from '../../../components/comet'

function AdminDashboardApp(props) {

	const SHORTCUTS = [
		{
			displayName: "Formats & types",
			key: "selections.admin-templates",
			links: [
				{
					key: "fileformats",
					url: "/star/admin/fileformats",
					ui: "React",
					description: "",
					displayName: "> File formats",
				},
				{
					key: "filetypes",
					url: "/star/admin/filetypes",
					ui: "React",
					description: "",
					displayName: "> Media formats",
				},
				{
					key: "croptypes",
					url: "/star/admin/croptypes",
					ui: "React",
					description: "",
					displayName: "> Crop types",
				},
			],
		},
	];

	return (
		<App name="c6-star-admin" layout="grid">
			<Dashboard title="Star administration" data={SHORTCUTS} />
			<StandardInfo location={props.location} />
		</App>
	);
}

export default AdminDashboardApp;